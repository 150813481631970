import { createAsyncThunk } from '@reduxjs/toolkit';
import { getServiceTypesApi } from '@services/WebApiService';

const serviceTypesAsync = createAsyncThunk(
  'ToolsRequestHistory/serviceTypes',
  async (paramsList) => {
    try {
      const data = await getServiceTypesApi(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = serviceTypesAsync;

const serviceTypesReducers = {
  [pending]: (state) => {
    state.serviceTypesState.fetching = true;
  },
  [rejected]: (state) => {
    state.serviceTypesState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.serviceTypesState.fetching = false;

    if (action?.payload?.error) {
      state.serviceTypesState.errorMessage = action.payload.error?.data.message;
      state.serviceTypesState.statusCode = action.payload.error?.status;

      if (action?.payload?.error?.status === 403) {
        state.serviceTypesState.forbiddenState = true;
        state.serviceTypesState.forbiddenErrorMessage = {
          heading: 'Forbidden Error',
          caption: 'You are not authorize to access this resource',
        };
      }
    }

    if (!action?.payload?.error && !action?.payload?.data) {
      state.serviceTypesState.errorMessage = 'Something went wrong while processing your request.';
    }

    if (action?.payload?.data?.data) {
      state.serviceTypesState.forbiddenState = false;
      state.serviceTypesState.forbiddenErrorMessage = {};
      state.serviceTypesState.statusCode = action.payload?.status;
      state.serviceTypesState.data = action?.payload?.data?.data;
    }
  },
};

export { serviceTypesAsync, serviceTypesReducers };
