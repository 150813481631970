import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Pagination } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

import BaseSetting, { constant } from '@config/BaseSetting';
import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import { ITBox, QboAlert, QboButton, QboCard, QboModal, QboSimpleDataTable } from '@ui/Components';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import ViewTenantAuthErrorsFormSection from '@ui/Sections/ViewTenantAuthErrorsPage/ViewTenantAuthErrorsFormSection';
import { resetCreateTenantAuthErrorsState } from '@features/tenantAuthErrors/tenantAuthErrorsSlice';
import useLocalStorage from '@hooks/useLocalStorage';
import useTenantAuthErrors from './pageHooks/useTenantAuthErrors';
import useForm from './pageHooks/useForm';

function ViewTenantAuthErrorsPage() {
  const [page, setPage] = useState(1);
  const { organisationIdAttributes, resetForm, onSubmit } = useForm();
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const [regionValue] = useLocalStorage(constant.REGIONS_LIST);
  const [showModal, setShowModal] = useState(false);
  const [tenantAuthErrorsData, setTenantAuthErrorsData] = useState([]);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { getTenantAuthErrors, getTenantAuthErrorsData } = useTenantAuthErrors();
  const { getTenantAuthErrorsState } = useSelector((state) => state.getTenantAuthErrors);
  const {
    fetching,
    tenantAuthErrorsList,
    paginationList,
    success,
    forbiddenState,
    forbiddenErrorMessage,
    tenantAuthErrorsCorsErrorMessage,
  } = getTenantAuthErrorsState;
  const [noStatusFlag, setNoStatusFlag] = useState(false);

  useEffect(() => {
    if (!fetching) {
      getTenantAuthErrors({ accessToken: accessTokenSet, reset: true, pageNumber: page });
    }
  }, [page]);

  useEffect(() => {
    getTenantAuthErrorsData(tenantAuthErrorsList, setTenantAuthErrorsData);
  }, [tenantAuthErrorsList, page]);

  const handleRefrshTenantAuthErrors = () => {
    if (!fetching) {
      getTenantAuthErrors({ accessToken: accessTokenSet, reset: true, pageNumber: page });
    }
  };
  const handleChange = (e, value) => {
    setPage(value);
  };

  useEffect(() => {
    if (!success || tenantAuthErrorsData?.length === 0) {
      setNoStatusFlag(true);
    } else if (success && tenantAuthErrorsData?.length !== 0) {
      setNoStatusFlag(false);
    }
  }, [success, tenantAuthErrorsData?.length]);

  return (
    <>
      <HeaderPageBlock>{t('view_auth_errors_page.header.text')}</HeaderPageBlock>
      {tenantAuthErrorsCorsErrorMessage && (
        <QboAlert type="error" style={{ fontSize: '1.15em' }}>
          {tenantAuthErrorsCorsErrorMessage}
        </QboAlert>
      )}
      {!fetching && (
        <QboCard>
          {!forbiddenState && (
            <ITBox className="SystemStatusSection__wrapper" direction="column">
              <ITBox className="SystemStatusSection__create-button">
                <IconButton
                  sx={{
                    position: 'relative',
                    right: '0.7rem',
                  }}
                  onClick={handleRefrshTenantAuthErrors}>
                  <RefreshIcon
                    sx={{
                      width: '2rem',
                      height: '2rem',
                    }}
                  />
                </IconButton>
                &nbsp;
                <QboButton variant="contained" onClick={() => setShowModal(true)}>
                  {t('mailbox_change.new_request')}
                </QboButton>
              </ITBox>
            </ITBox>
          )}

          <QboCard noPadding isMainWrapper>
            {success && tenantAuthErrorsData && (
              <>
                <QboSimpleDataTable
                  sx={{ width: '100%' }}
                  rows={tenantAuthErrorsData}
                  loading={fetching}
                  header={BaseSetting.viewAutoDiscoveryTableHeader}
                  onClickRow={(row) => {}}
                />
                {paginationList?.TotalPages && (
                  <Pagination
                    style={{ display: 'flex', justifyContent: 'end', marginTop: '1em  ' }}
                    count={paginationList?.TotalPages}
                    page={page}
                    onChange={handleChange}
                  />
                )}
              </>
            )}
            {(noStatusFlag || forbiddenState) && !fetching && (
              <NoStatusSection forbiddenErrorMessage={forbiddenErrorMessage} />
            )}
          </QboCard>
          <QboModal
            onClose={() => {
              setShowModal(false);
              resetForm();
              dispatch(resetCreateTenantAuthErrorsState());
              handleRefrshTenantAuthErrors();
            }}
            open={showModal}
            title={t('view_auth_errors_page.modal.title')}
            showCloseIcon
            classNameStyle="view_excluded_mailbox_modal"
            preventOutsideClick>
            <ViewTenantAuthErrorsFormSection
              organisationIdAttributes={organisationIdAttributes}
              onSubmit={onSubmit}
            />
          </QboModal>
        </QboCard>
      )}
    </>
  );
}

export default ViewTenantAuthErrorsPage;
