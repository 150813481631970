import { createSlice } from '@reduxjs/toolkit';
import {
  fetchMailboxChangeDetailAsync,
  mailboxChangeDetailReducers,
} from './serviceActions/fetchMailboxChangeDetailAsync';

const initialState = {
  mailboxChangeDetailState: {
    fetching: false,
    list: [],
    errorMessage: null,
    pagination: [],
    statusCode: null,
    forbiddenState: false,
    forbiddenErrorMessage: {},
    mailboxChangeDetailCorsErrorMessage: null,
  },
};

const mailboxChangeDetailSlice = createSlice({
  name: 'MailboxChangeDetail',
  initialState,
  reducers: {
    resetMailboxChangeDetail(state) {
      state.mailboxChangeDetailState = initialState.mailboxChangeDetailState;
    },
    resetListMailboxChangeDetail(state) {
      state.mailboxChangeDetailState.list = initialState.mailboxChangeDetailState.list;
    },
  },
  extraReducers: {
    ...mailboxChangeDetailReducers,
  },
});

export { fetchMailboxChangeDetailAsync };
export const { resetMailboxChangeDetail, resetListMailboxChangeDetail } =
  mailboxChangeDetailSlice.actions;
export default mailboxChangeDetailSlice.reducer;
