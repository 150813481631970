export default function useEmailFromCSV() {
  const readTextEmails = (emails) => {
    const mailList = emails.replaceAll(',\r', '').replaceAll('\r', '').split('\n');

    return mailList;
  };

  const readFileContent = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => resolve(e.target.result);
      reader.onerror = (e) => reject(e);
      reader.readAsText(file);
    });
  };

  const extractEmails = (content, headerText) => {
    const lines = content.split('\n');
    const headers = lines[0];
    const obj = { email: [] };

    const indexOfEmailColumn = headers.split(',').indexOf(headerText);

    if (indexOfEmailColumn === -1) {
      const emails = readTextEmails(content);
      emails.forEach((mail) => obj.email.push(mail));
    }

    if (lines.length > 0 && indexOfEmailColumn >= 0) {
      lines.shift();
      const emails = lines
        .map((item) => item?.split(',')[indexOfEmailColumn])
        .filter((item) => item !== '' && item !== undefined);

      emails.forEach((mail) => obj.email.push(mail));
    }

    return obj;
  };

  const handleOnFileUpload = async (event, setEmailLists) => {
    const headerText = 'emails';
    const file = event.target.files[0];

    if (file) {
      const content = await readFileContent(file);
      const obj = extractEmails(content, headerText);

      if (obj?.email) {
        setEmailLists(obj.email.filter((item) => item !== ''));
      }
    }
  };

  const convertListOfMailsToArray = (formData) => {
    const emailList = formData.emailIds?.split(',')?.map((item) => item?.trim());
    return {
      'support-ticket': formData.supportTicket,
      'escalation-ticket': formData.escalationTicket,
      description: formData.description,
      'org-owner-id': Number(formData.accountId),
      'mail-accounts': emailList,
    };
  };

  return { handleOnFileUpload, convertListOfMailsToArray };
}
