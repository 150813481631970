import { createAsyncThunk } from '@reduxjs/toolkit';
import { createMailBoxSyncRequestAPI } from '@services/WebApiService';

const getMailBoxSyncAsync = createAsyncThunk('Get/mailboxSyncRequest', async (paramsList) => {
  try {
    const data = await createMailBoxSyncRequestAPI(paramsList);
    return data;
  } catch (error) {
    return error.response;
  }
});

const { pending, fulfilled, rejected } = getMailBoxSyncAsync;

const getMailboxSyncReducers = {
  [pending]: (state) => {
    state.mailSyncRequestState.fetching = true;
  },
  [rejected]: (state) => {
    state.mailSyncRequestState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.mailSyncRequestState.fetching = false;

    if (action?.payload?.data?.message) {
      state.mailSyncRequestState.errorMessage = action?.payload?.data?.message;
    }

    if (action?.payload?.status === 403) {
      state.mailSyncRequestState.forbiddenState = true;
      state.mailSyncRequestState.forbiddenErrorMessage = {
        heading: 'Forbidden Error',
        caption: 'You are not authorize to access this resource',
      };
    }

    if (action?.payload?.data?.data) {
      state.mailSyncRequestState.data = action?.payload?.data?.data;
      state.mailSyncRequestState.success = true;
    }

    if (action?.payload?.data?.pagination) {
      state.mailSyncRequestState.pagination = JSON.parse(action?.payload?.data?.pagination);
    }

    if (!action?.payload) {
      state.mailSyncRequestState.mailSyncCorsErrorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.code) {
      state.mailSyncRequestState.error = action.payload.code;
    }
  },
};

export { getMailBoxSyncAsync, getMailboxSyncReducers };

export default getMailBoxSyncAsync;
