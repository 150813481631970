import { createAsyncThunk } from '@reduxjs/toolkit';
import { purgeGWSTenantAPI } from '@services/WebApiService';

const purgeGWSTenantAsync = createAsyncThunk(
  'PurgeGWSTenant/purgeGWSTenant',
  async (paramsList) => {
    try {
      const data = await purgeGWSTenantAPI(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = purgeGWSTenantAsync;

const purgeGWSTenantExtraReducers = {
  [pending]: (state) => {
    state.purgeGWSTenantState.fetching = true;
  },
  [rejected]: (state) => {
    state.purgeGWSTenantState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.purgeGWSTenantState.fetching = false;

    if (action?.payload && !action?.payload?.error) {
      state.purgeGWSTenantState.status = action?.payload?.status;
      state.purgeGWSTenantState.message = action?.payload?.data?.message;
    }

    if (action?.payload?.status === 403) {
      state.purgeGWSTenantState.message = 'You are not authorize to access this resource';
    }

    if (!action?.payload) {
      state.purgeGWSTenantState.message = 'Something went wrong while processing your request.';
    }

    if (action?.payload?.error) {
      const statusMessage = action?.payload?.error?.data;
      state.purgeGWSTenantState.status = action?.payload?.error?.status;
      state.purgeGWSTenantState.message = statusMessage?.title || statusMessage?.message;
      state.purgeGWSTenantState.error = action.payload.error;
    } else {
      state.purgeGWSTenantState.success = true;
    }
  },
};

export { purgeGWSTenantAsync, purgeGWSTenantExtraReducers };
