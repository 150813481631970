import { createAsyncThunk } from '@reduxjs/toolkit';
import { createSwitchCredentialAPI } from '@services/WebApiService';

const createSwitchCredentialAsync = createAsyncThunk(
  'SwitchCredential/CreateSwitchCredential',
  async (paramsList) => {
    try {
      const data = await createSwitchCredentialAPI(paramsList);
      return data;
    } catch (error) {
      return error.response;
    }
  }
);

const { pending, fulfilled, rejected } = createSwitchCredentialAsync;

const createSwitchCredentialReducers = {
  [pending]: (state) => {
    state.createSwitchCredentialState.fetching = true;
  },
  [rejected]: (state) => {
    state.createSwitchCredentialState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.createSwitchCredentialState.fetching = false;
    state.createSwitchCredentialState.statusCode = action?.payload?.status;
    state.createSwitchCredentialState.errorMessage = action?.payload?.response?.data?.message;

    if (action?.payload?.data?.title) {
      state.createSwitchCredentialState.errorMessage = action?.payload?.data?.title;
    }

    if (action?.payload?.status === 403) {
      state.createSwitchCredentialState.errorMessage =
        'You are not authorize to access this resource';
    }

    if (action?.payload?.status === 500) {
      state.createSwitchCredentialState.errorMessage = action?.payload?.data?.message;
    }

    if (!action?.payload) {
      state.createSwitchCredentialState.errorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.code) {
      state.createSwitchCredentialState.error = action.payload.code;
    } else {
      state.createSwitchCredentialState.success = true;
    }
  },
};

export { createSwitchCredentialAsync, createSwitchCredentialReducers };

export default createSwitchCredentialAsync;
