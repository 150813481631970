import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@mui/material';

import { QboButton, QboTypography } from '@ui/Components';

export default function QboFormGroup({
  children,
  className,
  onSubmit,
  title,
  withSubmitButton,
  buttonLabel,
  buttonDisabled,
  withGenerateAndResetButton,
  firstButtonLabel,
  secondButtonLabel,
  buttonWidth,
  handleOnResetButton,
  buttonStyle,
  buttonPostion,
  loadingButton,
  additionalButtons,
  ...rest
}) {
  return (
    <form onSubmit={onSubmit} className="QboFormGroup__default">
      {title && <QboTypography variant="h5">{title}</QboTypography>}
      <Box {...rest} className={`QboFormGroup__wrapper ${className}`}>
        {children}
      </Box>
      {withSubmitButton ? (
        <QboButton
          disabled={buttonDisabled}
          variant="contained"
          type="submit"
          loading={loadingButton}>
          {buttonLabel}
        </QboButton>
      ) : null}

      {withGenerateAndResetButton ? (
        <div style={{ display: 'flex', justifyContent: `${buttonPostion}` }}>
          {additionalButtons}
          <QboButton
            style={{ marginRight: 25, width: buttonWidth }}
            disabled={buttonDisabled}
            variant="contained"
            type="submit"
            loading={loadingButton}>
            {firstButtonLabel}
          </QboButton>
          <Button
            onClick={handleOnResetButton}
            style={{ width: buttonWidth }}
            sx={{ fontSize: '1.4rem', textTransform: 'none', ...buttonStyle }}
            disabled={buttonDisabled}
            type="reset">
            {secondButtonLabel}
          </Button>
        </div>
      ) : null}
    </form>
  );
}

QboFormGroup.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
  onSubmit: PropTypes.func,
  handleOnResetButton: PropTypes.func,
  title: PropTypes.string,
  buttonPostion: PropTypes.string,
  withSubmitButton: PropTypes.bool,
  withGenerateAndResetButton: PropTypes.bool,
  buttonDisabled: PropTypes.bool,
  buttonLabel: PropTypes.string,
  firstButtonLabel: PropTypes.string,
  secondButtonLabel: PropTypes.string,
  buttonWidth: PropTypes.number,
  buttonStyle: PropTypes.object,
  loadingButton: PropTypes.bool,
  additionalButtons: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

QboFormGroup.defaultProps = {
  className: '',
  onSubmit: () => {},
  handleOnResetButton: () => {},
  title: '',
  buttonPostion: 'end',
  buttonStyle: {},
  withSubmitButton: false,
  withGenerateAndResetButton: false,
  buttonDisabled: false,
  buttonLabel: 'Submit',
  firstButtonLabel: 'Generate',
  secondButtonLabel: 'Reset',
  buttonWidth: 120,
  loadingButton: false,
  additionalButtons: [],
};
