import React from 'react';
import PropTypes from 'prop-types';
import { Alert, AlertTitle } from '@mui/material';

export default function QboFixedAlert({ show, descriptionHtml, severity }) {
  if (!show) return null;

  if (severity === 'success') {
    return (
      <div className="MainAlert__Wrapper" id="hideMe">
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          <div
            dangerouslySetInnerHTML={{
              __html: descriptionHtml,
            }}
          />
        </Alert>
      </div>
    );
  }

  if (severity === 'error') {
    return (
      <div className="MainAlert__Wrapper" id="hideMe">
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          <div
            dangerouslySetInnerHTML={{
              __html: descriptionHtml,
            }}
          />
        </Alert>
      </div>
    );
  }
}

QboFixedAlert.propTypes = {
  descriptionHtml: PropTypes.string,
  show: PropTypes.bool,
  severity: PropTypes.string,
};

QboFixedAlert.defaultProps = {
  descriptionHtml: null,
  show: false,
  severity: 'success',
};
