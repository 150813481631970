import React from 'react';
import { useTranslation } from 'react-i18next';
import { QboCard, QboFormGroup, QboTextField, QboLink } from '@ui/Components';
import QboSortByButton from '@ui/Components/QboSortByButton';
import useUserBackupFilter from '@pages/UserBackupPage/pageHooks/useUserBackupFilter';
import PropTypes from 'prop-types';
import { EMAIL } from '@pages/UserBackupPage/pageHooks/constants/emailConstants';
import { DRIVE } from '@pages/UserBackupPage/pageHooks/constants/driveConstants';
import { SHAREPOINT } from '@pages/UserBackupPage/pageHooks/constants/sharepointConstants';
import { MS_GROUP } from '@pages/UserBackupPage/pageHooks/constants/msGroupConstants';
import { MS_CHAT } from '@pages/UserBackupPage/pageHooks/constants/msChatConstants';
import PartnerFilterSection from '../Shared/PartnerFilterSection';
import OrganizationFilterSection from '../Shared/OrganizationFilterSection';

export default function FilterSection(props) {
  const { t } = useTranslation();
  const { backupType, setOnSearch, organizationOwnerId, resellerId } = props;
  const {
    handleOnSearchClick,
    handleOnResetButtonClick,
    handleSearchFieldChange,
    handleOnAdvanceSearchClick,
    filterState,
    advanceClick,
    buttonStyle,
    protocolItems,
    booleanItemList,
    statusItems,
  } = useUserBackupFilter(setOnSearch);

  let defaultPlaceholder = 'ID / Email(Full)';
  if (backupType === SHAREPOINT) {
    defaultPlaceholder = 'ID / Site Name / Domain Name';
  } else if (backupType === MS_GROUP) {
    defaultPlaceholder = 'ID / Group Name / Domain Name';
  }

  return (
    <QboCard isMainWrapper>
      <QboFormGroup
        onSubmit={(e) => handleOnSearchClick(e)}
        buttonPostion="start"
        buttonStyle={buttonStyle}
        handleOnResetButton={handleOnResetButtonClick}
        firstButtonLabel={t('user_details_page.button.search')}
        withGenerateAndResetButton
        buttonLabel="Search">
        <QboTextField
          id="searchById"
          value={filterState.searchById}
          isWidthSetExplicit
          placeholder={
            organizationOwnerId
              ? `${defaultPlaceholder}`
              : `${defaultPlaceholder} / Organization Name / Partner Name / Parent Name`
          }
          inputProps={{ maxLength: 225 }}
          onChange={(e) => {
            handleSearchFieldChange(e.target.value, 'searchById');
          }}
        />
        <div>
          {(!resellerId || !organizationOwnerId) && (
            <PartnerFilterSection
              label="Partner:"
              value={filterState.resellerId}
              marginRightValue={2}
              inputSize={180}
              handleChange={(value) => {
                handleSearchFieldChange(value, 'resellerId');
              }}
            />
          )}
          {!organizationOwnerId && (
            <OrganizationFilterSection
              label="Organization:"
              partnerId={filterState.resellerId ?? ''}
              value={filterState.organizationOwnerId}
              inputSize={180}
              handleChange={(value) => {
                handleSearchFieldChange(value, 'organizationOwnerId');
              }}
            />
          )}
        </div>
        <QboLink
          style={{ fontSize: '0.875em' }}
          onClick={() => {
            handleOnAdvanceSearchClick();
          }}>
          {advanceClick
            ? t('partner_details_page.button.hide_advance_search')
            : t('partner_details_page.button.show_advance_search')}
        </QboLink>
        {advanceClick && (
          <div>
            <QboSortByButton
              defaultValue="All"
              marginRightValue={2}
              value={filterState.status}
              buttonWidth={220}
              startAdornmentTextWidth={70}
              startAdornmentText="Status"
              handleChange={(e) => {
                handleSearchFieldChange(e.target.value, 'status');
              }}
              menuItemList={statusItems}
            />
            {![SHAREPOINT, MS_GROUP].includes(backupType) && (
              <QboSortByButton
                defaultValue="All"
                marginRightValue={2}
                marginButtonValue={1}
                value={filterState.protocol}
                buttonWidth={220}
                startAdornmentTextWidth={100}
                startAdornmentText="Protocol"
                handleChange={(e) => {
                  handleSearchFieldChange(e.target.value, 'protocol');
                }}
                menuItemList={protocolItems}
              />
            )}
            {backupType === EMAIL && (
              <QboSortByButton
                defaultValue="All"
                marginRightValue={2}
                marginButtonValue={1}
                value={filterState.hasCct}
                buttonWidth={220}
                startAdornmentTextWidth={100}
                startAdornmentText="Has CCT"
                handleChange={(e) => {
                  handleSearchFieldChange(e.target.value, 'hasCct');
                }}
                menuItemList={booleanItemList}
              />
            )}
            {backupType === EMAIL && (
              <QboSortByButton
                defaultValue="All"
                marginRightValue={2}
                marginButtonValue={1}
                value={filterState.completedSetup}
                buttonWidth={220}
                startAdornmentTextWidth={200}
                startAdornmentText="Setup Completed"
                handleChange={(e) => {
                  handleSearchFieldChange(e.target.value, 'completedSetup');
                }}
                menuItemList={booleanItemList}
              />
            )}
            {![DRIVE, SHAREPOINT, MS_GROUP, MS_CHAT].includes(backupType) && (
              <QboTextField
                id="m365Id"
                value={filterState.m365Id}
                marginRightValue={2}
                startAdornmentTextWidth={100}
                placeholder="M365 ID / GWS ID"
                inputProps={{
                  maxLength: 225,
                  style: { width: '193px', height: '23px', 'margin-right': '16px' },
                }}
                onChange={(e) => {
                  handleSearchFieldChange(e.target.value, 'm365Id');
                }}
              />
            )}
            {![SHAREPOINT, MS_GROUP].includes(backupType) && (
              <QboTextField
                id="tenantId"
                value={filterState.tenantId}
                marginRightValue={2}
                startAdornmentTextWidth={100}
                placeholder="Tenant Id"
                inputProps={{ maxLength: 225, style: { width: '193px', height: '23px' } }}
                onChange={(e) => {
                  handleSearchFieldChange(e.target.value, 'tenantId');
                }}
              />
            )}
            {[SHAREPOINT, MS_GROUP].includes(backupType) && (
              <QboSortByButton
                defaultValue="All"
                marginRightValue={2}
                marginButtonValue={1}
                value={filterState.isActive}
                buttonWidth={220}
                startAdornmentTextWidth={100}
                startAdornmentText="Active"
                handleChange={(e) => {
                  handleSearchFieldChange(e.target.value, 'isActive');
                }}
                menuItemList={booleanItemList}
              />
            )}
            {[SHAREPOINT].includes(backupType) && (
              <QboSortByButton
                defaultValue="All"
                marginRightValue={2}
                marginButtonValue={1}
                value={filterState.hasGroup}
                buttonWidth={220}
                startAdornmentTextWidth={200}
                startAdornmentText="Has MS Group"
                handleChange={(e) => {
                  handleSearchFieldChange(e.target.value, 'hasGroup');
                }}
                menuItemList={booleanItemList}
              />
            )}
          </div>
        )}
      </QboFormGroup>
    </QboCard>
  );
}

FilterSection.propTypes = {
  backupType: PropTypes.string,
  organizationOwnerId: PropTypes.string,
  resellerId: PropTypes.string,
  setOnSearch: PropTypes.func,
};

FilterSection.defaultProps = {
  backupType: 'email',
  organizationOwnerId: null,
  resellerId: null,
  setOnSearch: () => {},
};
