import { createAsyncThunk } from '@reduxjs/toolkit';
import { getDetailsOfDataIngestionAPI } from '@services/WebApiService';

const getDetailsOfDataIngestionAsync = createAsyncThunk(
  'Ingestion/getIngestionDetails',
  async (paramsList) => {
    try {
      const data = await getDetailsOfDataIngestionAPI(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = getDetailsOfDataIngestionAsync;

const getDetailsOfDataIngestionExtraReducers = {
  [pending]: (state) => {
    state.getDetailsOfIngestionDataState.fetching = true;
  },
  [rejected]: (state) => {
    state.getDetailsOfIngestionDataState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.getDetailsOfIngestionDataState.fetching = false;

    if (action?.payload?.data) {
      state.getDetailsOfIngestionDataState.detailsOfIngestionList = action?.payload?.data?.data;
      state.getDetailsOfIngestionDataState.detailsOfPaginationList = JSON.parse(
        action?.payload?.data?.pagination
      );
      state.getDetailsOfIngestionDataState.success = true;
    }

    if (action?.payload?.error) {
      state.getDetailsOfIngestionDataState.error = action.payload.error;
    }

    if (!action?.payload?.data && !action.payload?.error) {
      state.getDetailsOfIngestionDataState.corsErrorState =
        'Something went wrong while processing your request.';
    }
  },
};

export { getDetailsOfDataIngestionAsync, getDetailsOfDataIngestionExtraReducers };
