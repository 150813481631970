import { createAsyncThunk } from '@reduxjs/toolkit';
import { removeDataIngestionItemsAPI } from '@services/WebApiService';

const removeDataIngestionItemsAsync = createAsyncThunk(
  'Ingestion/RemoveItems',
  async (paramsList) => {
    try {
      const data = await removeDataIngestionItemsAPI(paramsList);
      return data;
    } catch (error) {
      return error.response;
    }
  }
);

const { pending, fulfilled, rejected } = removeDataIngestionItemsAsync;

const getRemoveDataIngestionItemsExtraReducers = {
  [pending]: (state) => {
    state.getRemoveDataIngestionItemsState.fetching = true;
  },
  [rejected]: (state) => {
    state.getRemoveDataIngestionItemsState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.getRemoveDataIngestionItemsState.fetching = false;

    if (action?.payload?.status) {
      state.getRemoveDataIngestionItemsState.statusCode = action?.payload?.status;
      state.getRemoveDataIngestionItemsState.success = true;
    }

    if (action?.payload?.data) {
      state.getRemoveDataIngestionItemsState.removeDataIngestionItemsResult =
        action?.payload?.data?.data;
      state.getRemoveDataIngestionItemsState.success = true;
    }

    if (action?.payload?.error) {
      state.getRemoveDataIngestionItemsState.error = action.payload.error;
    }
  },
};

export { removeDataIngestionItemsAsync, getRemoveDataIngestionItemsExtraReducers };
