import { createAsyncThunk } from '@reduxjs/toolkit';
import { submitFormApi } from '@services/WebApiService';
import HttpResponse from '@libs/HttpResponse';

const submitFormAsync = createAsyncThunk('Form/submitForm', async (payload) => {
  const data = await submitFormApi(payload);
  return data;
});

const { pending, fulfilled, rejected } = submitFormAsync;

const submitFormExtraReducers = {
  [pending]: (state) => {
    state.submitForm.submitting = true;
  },
  [rejected]: (state) => {
    state.submitForm.submitting = false;
  },
  [fulfilled]: (state, action) => {
    state.submitForm.submitting = false;

    if (action?.payload?.error) {
      state.submitForm.error = action.payload.error;
    } else {
      state.submitForm.success = true;
    }
  },
};

export { submitFormAsync, submitFormExtraReducers };
