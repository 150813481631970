import { createAsyncThunk } from '@reduxjs/toolkit';
import { getTransferSubscriptionAPI } from '@services/WebApiService';

const getTransferSubscriptionAsync = createAsyncThunk(
  'TransferSubscription/getTransferSubscription',
  async (paramsList) => {
    try {
      const data = await getTransferSubscriptionAPI(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = getTransferSubscriptionAsync;

const getTransferSubscriptionExtraReducers = {
  [pending]: (state) => {
    state.getTransferSubscriptionState.fetching = true;
  },
  [rejected]: (state) => {
    state.getTransferSubscriptionState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.getTransferSubscriptionState.fetching = false;

    if (action?.payload?.error) {
      state.getTransferSubscriptionState.errorMessage = action.payload.error?.data.message;
      state.getTransferSubscriptionState.status = action.payload.error?.status;

      if (action?.payload?.error?.status === 403) {
        state.getTransferSubscriptionState.forbiddenState = true;
        state.getTransferSubscriptionState.forbiddenErrorMessage = {
          heading: 'Forbidden Error',
          caption: 'You are not authorize to access this resource',
        };
      }
    }

    if (!action?.payload?.error && !action?.payload?.data) {
      state.getTransferSubscriptionState.transferSubscriptionCorsErrorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.data?.data) {
      state.getTransferSubscriptionState.forbiddenState = false;
      state.getTransferSubscriptionState.forbiddenErrorMessage = {};
      state.getTransferSubscriptionState.status = action.payload?.status;
      state.getTransferSubscriptionState.transferSubscriptionList = action?.payload?.data?.data;

      if (action?.payload?.data?.pagination) {
        state.getTransferSubscriptionState.paginations = JSON.parse(
          action?.payload?.data?.pagination
        );
      }
    }
  },
};

export { getTransferSubscriptionAsync, getTransferSubscriptionExtraReducers };
