import { createSlice } from '@reduxjs/toolkit';

import {
  getPurgeM365TenantsAsync,
  getPurgeM365TenantsExtraReducers,
} from './serviceActions/fetchPurgeM365TenantsAsync';

const initialState = {
  getPurgeM365TenantsState: {
    fetching: false,
    status: null,
    purgeM365TenantsList: [],
    errorMessage: null,
    paginations: {},
    forbiddenState: false,
    forbiddenErrorMessage: null,
    purgeM365TenantsCorsErrorMessage: null,
  },
};

const purgeM365TenantsSlice = createSlice({
  name: 'purgeM365Tenants',
  initialState,
  reducers: {
    resetPurgeM365TenantsState(state) {
      state.getPurgeM365TenantsState = initialState.getPurgeM365TenantsState;
    },
  },
  extraReducers: {
    ...getPurgeM365TenantsExtraReducers,
  },
});

export { getPurgeM365TenantsAsync };

export const { resetPurgeM365TenantsState } = purgeM365TenantsSlice.actions;
export default purgeM365TenantsSlice.reducer;
