import { createAsyncThunk } from '@reduxjs/toolkit';
import InvoiceService from '@services/WebApiService/InvoiceService';

const runXeroSyncAsync = createAsyncThunk('RunXeroSync/runXeroSync', async (paramsList) => {
  try {
    const data = await InvoiceService.runXeroSyncApi(paramsList);
    return data;
  } catch (error) {
    return { error: error.response };
  }
});

const { pending, fulfilled, rejected } = runXeroSyncAsync;

const runXeroSyncExtraReducers = {
  [pending]: (state) => {
    state.runXeroSyncState.fetching = true;
  },
  [rejected]: (state) => {
    state.runXeroSyncState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.runXeroSyncState.fetching = false;

    if (action?.payload && !action?.payload?.error) {
      state.runXeroSyncState.status = action?.payload?.status;
      state.runXeroSyncState.message = action?.payload?.data?.message;
    }

    if (action?.payload?.status === 403) {
      state.runXeroSyncState.message = 'You are not authorize to access this resource';
    }

    if (!action?.payload) {
      state.runXeroSyncState.message = 'Something went wrong while processing your request.';
    }

    if (action?.payload?.error) {
      const statusMessage = action?.payload?.error?.data;
      state.runXeroSyncState.status = action?.payload?.error?.status;
      state.runXeroSyncState.message = statusMessage?.title || statusMessage?.message;
      state.runXeroSyncState.error = action.payload.error;
    } else {
      state.runXeroSyncState.success = true;
      state.runXeroSyncState.serviceType = action.payload.data.data.serviceTypeCode;
    }
  },
};

export { runXeroSyncAsync, runXeroSyncExtraReducers };
