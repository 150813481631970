import { createAsyncThunk } from '@reduxjs/toolkit';
import { credentilasSPAinfoApi } from '@services/WebApiService';

const credentilasSPAinfoAsync = createAsyncThunk(
  'switcSPAType/credentilasSPAinfoApi',
  async (paramsList) => {
    try {
      const data = await credentilasSPAinfoApi(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = credentilasSPAinfoAsync;

const credentilasSPAinfoExtraReducers = {
  [pending]: (state) => {
    state.credentialsSPAinfoState.submitting = true;
  },
  [rejected]: (state) => {
    state.credentialsSPAinfoState.submitting = false;
  },
  [fulfilled]: (state, action) => {
    state.credentialsSPAinfoState.submitting = false;

    if (action?.payload?.error) {
      state.credentialsSPAinfoState.error = action.payload.error;
      state.credentialsSPAinfoState.validateErrorMessage = action.payload.error?.data?.message;
    } else {
      state.credentialsSPAinfoState.success = true;
      state.credentialsSPAinfoState.validateErrorMessage = null;
      state.credentialsSPAinfoState.data = action?.payload?.data;
    }
  },
};

export { credentilasSPAinfoAsync, credentilasSPAinfoExtraReducers };
