import { useDispatch } from 'react-redux';

import { getTenantAuthErrorsAsync } from '@features/tenantAuthErrors/getTenantAuthErrorsSlice';

export default function useTenantAuthErrors() {
  const dispatch = useDispatch();

  const getTenantAuthErrors = async (paramsList) => {
    dispatch(getTenantAuthErrorsAsync(paramsList));
  };

  const getTenantAuthErrorsData = (data, setTenantAuthErrorsData) => {
    const modifiedData = data?.map((item) => {
      const payloadObj = JSON.parse(item.payload);
      const modifiedPayloadObj = {
        'owner-id': payloadObj['org-owner-id'],
        'executed-on': item['executed-on'],
        'status-description': item['status-description'],
      };
      return modifiedPayloadObj;
    });
    return setTenantAuthErrorsData(modifiedData);
  };

  const helperToConvertString = (data) => {
    const dataCovertList = data?.map((value) => ({
      'tenant-Id': value['tenant-Id'],
      'error-message': value['error-message'],
    }));

    return dataCovertList;
  };

  return { getTenantAuthErrors, getTenantAuthErrorsData, helperToConvertString };
}
