const { createSlice } = require('@reduxjs/toolkit');
const {
  toggleEnforceSSOExtraReducers,
  toggleEnforceSSOAsync,
} = require('./serviceActions/toggleEnforceSSOAsync');

const initialState = {
  toggleEnforceSSOState: {
    fetching: false,
    status: null,
    error: null,
    message: null,
    errorMessage: null,
    forbiddenState: false,
    forbiddenErrorMessage: null,
    corsErrorMessage: null,
    jobIds: [],
    serviceType: null,
  },
};

const toggleEnforceSSOSlice = createSlice({
  name: 'toggleEnforceSSO',
  initialState,
  reducers: {
    resetToggleEnforceSSOState(state) {
      state.toggleEnforceSSOState = initialState.toggleEnforceSSOState;
    },
  },
  extraReducers: {
    ...toggleEnforceSSOExtraReducers,
  },
});

export { toggleEnforceSSOAsync };
export const { resetToggleEnforceSSOState } = toggleEnforceSSOSlice.actions;
export default toggleEnforceSSOSlice.reducer;
