import { useDispatch } from 'react-redux';
import useLocalStorage from '@hooks/useLocalStorage';
import { constant } from '@config/BaseSetting';
import { getPlansListAsync } from '@features/plansList/plansListSlice';
import { getPartnersFilterListAsync } from '@features/partnerList/partnersFilterListSlice';

export default function usePlansService(
  setAdvanceClick,
  advanceClick,
  setSearchByID,
  setFullTextSearch,
  setSearchFieldValue,
  setSearchTerm,
  setSelectedPartner,
  handleUpdateFilters,
  searchTerm
) {
  const [accessToken] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const dispatch = useDispatch();

  const handleOnAdvanceSearchClick = () => {
    setAdvanceClick(!advanceClick);
  };

  const handleOnPlansAsync = (
    resellerID,
    searchID,
    searchText,
    columnSelect,
    paginationList,
    adFilter
  ) => {
    dispatch(
      getPlansListAsync({
        accessToken,
        reset: true,
        ...{
          reseller: resellerID,
          searchById: searchID || 0,
          fullTextSearch: searchText,
          pageNumber: paginationList.pageIndex + 1,
          pageSize: paginationList.pageSize,
          orderBy: columnSelect || 'PlanId',
          filter: adFilter,
        },
      })
    );
  };

  const handleOnSerchFieldChange = (e) => {
    const input = e.target.value;
    setSearchFieldValue(input);
    if (/^\d+$/.test(input)) {
      setSearchByID(input);
      setFullTextSearch(input);
    } else {
      setSearchByID('');
      setFullTextSearch(input);
    }
  };

  const handlePartnerFilterSearch = (search) => {
    dispatch(
      getPartnersFilterListAsync({
        accessToken,
        reset: true,
        ...{ searchTerm: search, isOnlyParent: false },
      })
    );
  };

  const handlePartnerNameChange = (e) => {
    setSelectedPartner(e.target.value);
    handleUpdateFilters('filter_partnerName', e.target.value);
  };

  const handleOnCloseClick = () => {
    setSearchTerm('');
    setSelectedPartner('');
    handleUpdateFilters('filter_partnerName', '');

    handlePartnerFilterSearch(searchTerm);
  };

  const matchingText = (
    searchByID,
    searchText,
    planType,
    partnerName,
    statusChange,
    licenceTypeState,
    setMatchText
  ) => {
    if (
      !searchByID &&
      !searchText &&
      !planType &&
      !partnerName &&
      !statusChange &&
      !licenceTypeState
    ) {
      return '';
    }

    let textData = 'Matching';

    if (searchByID) {
      textData += ` ID = ${searchByID}`;
    }

    if (searchText) {
      textData += ` Partner Name like '%${searchText}%' `;
    }

    if (planType) {
      if (textData !== 'Matching') {
        textData += ', ';
      }
      textData += ` Plan Type = ${planType}`;
    }

    if (partnerName) {
      if (textData !== 'Matching') {
        textData += ', ';
      }
      textData += ` Partner Name = ${partnerName}`;
    }

    if (licenceTypeState) {
      if (textData !== 'Matching') {
        textData += ', ';
      }
      textData += ` Licence Type = ${licenceTypeState}`;
    }

    if (statusChange) {
      if (textData !== 'Matching') {
        textData += ', ';
      }
      textData += ` Plan Status = ${statusChange}`;
    }

    setMatchText(textData);
    return textData;
  };

  return {
    handleOnAdvanceSearchClick,
    handleOnPlansAsync,
    handleOnSerchFieldChange,
    matchingText,
    handleOnCloseClick,
    handlePartnerNameChange,
    handlePartnerFilterSearch,
  };
}
