import { createSlice } from '@reduxjs/toolkit';

import { partnerDetailAsync, partnerDetailReducers } from './serviceActions/partnerDetailAsync';

const initialState = {
  partnerDetailState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    paginationList: null,
    data: {},
    forbiddenState: false,
    forbiddenErrorMessage: null,
    partnerDetailCorsErrorMessage: null,
  },
};

const partnerDetailSlice = createSlice({
  name: 'partnerDetail',
  initialState,
  reducers: {
    resetPartnerDetailState(state) {
      state.partnerDetailState = initialState.partnerDetailState;
    },
    resetPartnerDetailStatusCodeState(state) {
      state.partnerDetailState.statusCode = initialState.partnerDetailState.statusCode;
    },
  },
  extraReducers: {
    ...partnerDetailReducers,
  },
});

export { partnerDetailAsync };
export const { resetPartnerDetailState, resetPartnerDetailStatusCodeState } =
  partnerDetailSlice.actions;
export default partnerDetailSlice.reducer;
