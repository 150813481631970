import { IconButton } from '@mui/material';
import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import {
  QboAdvanceTable,
  QboCard,
  QboFormGroup,
  QboTextField,
  QboTypography,
} from '@ui/Components';
import QboSortByButton from '@ui/Components/QboSortByButton';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import RefreshIcon from '@mui/icons-material/Refresh';
import ToolsRequestDetailsSection from '@ui/Sections/ToolsRequestHistoryPage/ToolsRequestDetailsSection';
import useToolsRequestHistory from './pageHooks/useToolsRequestHistory';
import useToolsRequestDetails from './pageHooks/useToolsRequestDetails';
import useDataService from './pageHooks/useDataService';

export default function ToolsRequestHistory() {
  const { t } = useTranslation();

  const {
    searchFieldValue,
    matchText,
    pagination,
    serviceTypes,
    serviceTypeId,
    serviceTypeSearch,
    setPagination,
    handleOnSearchFieldChange,
    handleOnSearchClick,
    handleOnResetClick,
    handleOnRefreshClick,
    handleOnServiceTypeChanged,
    handleOnServiceTypeSearchChanged,
    handleOnClearSearchServiceType,
  } = useToolsRequestHistory();

  const { jobId, jobType, clickableColumnsList, handleOnModalClosed } = useToolsRequestDetails();

  const { toolsRequestHistoryHeaders } = useDataService();

  const { toolsRequestHistoryState } = useSelector((state) => state.toolsRequestHistory);

  const transformedData = toolsRequestHistoryState.data.map((e) => {
    return {
      ...e,
      details: 'Details',
    };
  });

  const refreshButton = (
    <IconButton style={{ marginRight: 25 }} onClick={handleOnRefreshClick}>
      <RefreshIcon
        sx={{
          width: '2rem',
          height: '2rem',
        }}
      />
    </IconButton>
  );

  return (
    <>
      <HeaderPageBlock>{t('drawer.main_menu.tools_request_status')}</HeaderPageBlock>
      <QboCard>
        <QboCard isMainWrapper>
          <QboFormGroup
            onSubmit={handleOnSearchClick}
            buttonPostion="start"
            handleOnResetButton={handleOnResetClick}
            firstButtonLabel={t('partner_details_page.button.search')}
            withGenerateAndResetButton
            additionalButtons={refreshButton}>
            <QboTextField
              id="payloadSearch"
              value={searchFieldValue}
              isWidthSetExplicit
              placeholder={t('tools_request_status.search_placeholder')}
              inputProps={{ maxLength: 255 }}
              onChange={handleOnSearchFieldChange}
            />

            <QboSortByButton
              isSearchEnable
              forceUpdate
              handleOnSearchChange={handleOnServiceTypeSearchChanged}
              handleCloseClick={handleOnClearSearchServiceType}
              searchState={serviceTypeSearch}
              defaultValue="All"
              handleChange={handleOnServiceTypeChanged}
              marginRightValue={2}
              marginTopValue={2}
              value={serviceTypeId}
              buttonWidth={220}
              startAdornmentTextWidth={180}
              startAdornmentText="Service Type:"
              menuItemList={serviceTypes}
            />
          </QboFormGroup>
        </QboCard>

        {matchText && (
          <QboTypography
            sx={{
              marginLeft: 2,
              marginTop: 2,
              marginBottom: 3,
              fontSize: '15px !important',
              color: '#42526e',
              fontWeight: 600,
            }}>
            {matchText}
          </QboTypography>
        )}

        {(toolsRequestHistoryState?.data.length > 0 || toolsRequestHistoryState.fetching) && (
          <QboAdvanceTable
            enableColumnClickable={!false}
            columnsClickableList={clickableColumnsList}
            isLoading={toolsRequestHistoryState.fetching}
            enableTopToolBar={false}
            headers={toolsRequestHistoryHeaders}
            rows={transformedData}
            pagination={pagination}
            setPagination={setPagination}
            rowsCount={toolsRequestHistoryState.pagination?.TotalCount}
          />
        )}
        {toolsRequestHistoryState?.data.length === 0 && !toolsRequestHistoryState.fetching && (
          <NoStatusSection />
        )}
      </QboCard>
      {!!jobId && !!jobType && (
        <ToolsRequestDetailsSection
          jobId={jobId}
          jobType={jobType}
          handleOnClose={handleOnModalClosed}
        />
      )}
    </>
  );
}
