import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useLocalStorage from '@hooks/useLocalStorage';
import { constant } from '@config/BaseSetting';
import { getPartnersFilterListAsync } from '@features/partnerList/partnersFilterListSlice';
import { setPartnerFilterState } from '@features/application/applicationSlice';

export default function usePartnerFilterHook(parentId, externalValue) {
  const dispatch = useDispatch();
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const { getPartnersFilterListState } = useSelector((state) => state.partnersFilterList);
  const { partnersFilterList } = getPartnersFilterListState;
  const { partnerFilterState } = useSelector((stateList) => stateList.application);

  const [partnerList, setPartnerList] = useState([]);

  const handlePartnerFilterSearch = (search, parent) => {
    dispatch(
      getPartnersFilterListAsync({
        accessToken: accessTokenSet,
        reset: true,
        ...{ searchTerm: search, isOnlyParent: false, parentId: parent },
      })
    );
  };

  const responseConverter = (data) => {
    return data.map((item) => ({
      id: item?.id,
      value: item?.id,
      title: `${item?.id} - ${item.partnerName}`,
    }));
  };

  const resetPartnerFilter = () => {
    dispatch(
      setPartnerFilterState({
        forceUpdate: !partnerFilterState.forceUpdate,
        searchState: '',
        selectedPartner: '',
      })
    );
    handlePartnerFilterSearch('', parentId);
  };

  const UpdateFilters = (value, properties) => {
    const newValue = value;
    const newState = { ...partnerFilterState };
    properties.forEach((property) => {
      newState[property] = newValue;
    });
    dispatch(setPartnerFilterState(newState));
  };

  useEffect(() => {
    if (partnerFilterState.searchState.length === 0 || partnerFilterState.searchState.length > 2)
      handlePartnerFilterSearch(partnerFilterState.searchState, parentId);
  }, [partnerFilterState.searchState]);

  useEffect(() => {
    setPartnerList(responseConverter(partnersFilterList));
  }, [partnersFilterList]);

  useEffect(() => {
    UpdateFilters('', ['selectedPartner']);
    handlePartnerFilterSearch('', parentId);
  }, [parentId]);

  useEffect(() => {
    if (externalValue === partnerFilterState.selectedPartner) return;
    UpdateFilters(externalValue || '', ['selectedPartner']);
  }, [externalValue]);

  return {
    partnerFilterState,
    resetPartnerFilter,
    UpdateFilters,
    partnerList,
  };
}
