import { useDispatch } from 'react-redux';
import { getAccessTokenAsync } from '@features/auth/msalSlice';
import { constant } from '@config/BaseSetting';
import useLocalStorage from '@hooks/useLocalStorage';

export default function useAccessToken() {
  const dispatch = useDispatch();
  const [storeAccessToken, setStoreAccessToken] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);

  const getAccessToken = async (paramsList) => {
    const data = await dispatch(getAccessTokenAsync(paramsList));

    if (data?.payload?.status === 200) {
      const { accessToken } = data.payload.data;
      setStoreAccessToken(accessToken);
    }

    return data;
  };
  return { getAccessToken };
}
