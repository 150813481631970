import { createAsyncThunk } from '@reduxjs/toolkit';
import { getIngestionReportListAPI } from '@services/WebApiService';

const getIngestionReportListAsync = createAsyncThunk(
  'Ingestion/getIngestionReports',
  async (paramsList) => {
    try {
      const data = await getIngestionReportListAPI(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = getIngestionReportListAsync;

const getIngestionReportListExtraReducers = {
  [pending]: (state) => {
    state.getIngestionReportListState.fetching = true;
  },
  [rejected]: (state) => {
    state.getIngestionReportListState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.getIngestionReportListState.fetching = false;

    if (action?.payload?.error) {
      state.getIngestionReportListState.errorMessage = action.payload.error?.data.message;
      state.getIngestionReportListState.status = action.payload.error?.status;

      if (action?.payload?.error?.status === 403) {
        state.getIngestionReportListState.forbiddenState = true;
        state.getIngestionReportListState.forbiddenErrorMessage = {
          heading: 'Forbidden Error',
          caption: 'You are not authorize to access this resource',
        };
      }
    }

    if (!action?.payload?.error && !action?.payload?.data) {
      state.getIngestionReportListState.ingestionReportsCorsErrorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.data?.data) {
      state.getIngestionReportListState.forbiddenState = false;
      state.getIngestionReportListState.forbiddenErrorMessage = {};
      state.getIngestionReportListState.status = action.payload?.status;
      state.getIngestionReportListState.ingestionReportsList = action?.payload?.data?.data;

      if (action?.payload?.data?.pagination) {
        state.getIngestionReportListState.reportsPaginationList = JSON.parse(
          action?.payload?.data?.pagination
        );
      }
    }
  },
};

export { getIngestionReportListAsync, getIngestionReportListExtraReducers };
