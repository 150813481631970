import { createSlice } from '@reduxjs/toolkit';

import {
  exportM365TenantAsync,
  exportM365TenantExtraReducers,
} from './serviceActions/exportM365TenantAsync';

const initialState = {
  exportM365TenantState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    m365TenantExportData: null,
    forbiddenState: false,
    forbiddenErrorMessage: null,
    m365TenantCorsErrorMessage: null,
  },
  m365TenantRowInfo: {},
};

const exportM365TenantSlice = createSlice({
  name: 'M365Tenant',
  initialState,
  reducers: {
    resetExportM365TenantState(state) {
      state.exportM365TenantState = initialState.exportM365TenantState;
    },
  },
  extraReducers: {
    ...exportM365TenantExtraReducers,
  },
});

export { exportM365TenantAsync };
export const { resetExportM365TenantState } = exportM365TenantSlice.actions;
export default exportM365TenantSlice.reducer;
