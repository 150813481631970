import { useDispatch } from 'react-redux';
import { getRefreshTokenAsync } from '@features/auth/msalSlice';
import { constant } from '@config/BaseSetting';
import useLocalStorage from '@hooks/useLocalStorage';

export default function useRefreshToken() {
  const dispatch = useDispatch();
  const [token, setToken] = useLocalStorage(constant.TOKEN_STORAGE);
  const [accessTokenSet, setAccessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);

  const getRefreshToken = async () => {
    const response = await dispatch(
      getRefreshTokenAsync({ accessToken: accessTokenSet, reset: false })
    );
    return response;
  };

  return { getRefreshToken };
}
