import { createAsyncThunk } from '@reduxjs/toolkit';
import { getGWSTenantDetailsAPI } from '@services/WebApiService';

const getGWSTenantDetailsInfoAsync = createAsyncThunk(
  'GWSTenantDetailsInfo/getGWSTenantDetailsInfo',
  async (paramsList) => {
    try {
      const data = await getGWSTenantDetailsAPI(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = getGWSTenantDetailsInfoAsync;

const getGWSTenantDetailsInfoReducers = {
  [pending]: (state) => {
    state.getGWSTenantDetailsInfoState.fetching = true;
  },
  [rejected]: (state) => {
    state.getGWSTenantDetailsInfoState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.getGWSTenantDetailsInfoState.fetching = false;

    if (action?.payload?.status === 403) {
      state.getGWSTenantDetailsInfoState.forbiddenState = true;
      state.getGWSTenantDetailsInfoState.forbiddenErrorMessage = {
        heading: 'Forbidden Error',
        caption: 'You are not authorize to access this resource',
      };
    }

    if (!action?.payload) {
      state.getGWSTenantDetailsInfoState.gwsTenantDetailsInfoCorsErrorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.data?.data) {
      state.getGWSTenantDetailsInfoState.gwsTenantDetailsInformation = action?.payload?.data?.data;
      state.getGWSTenantDetailsInfoState.success = true;
      state.getGWSTenantDetailsInfoState.statusCode = action?.payload?.data['status-code'];
    }

    if (action?.payload?.error) {
      state.getGWSTenantDetailsInfoState.error = action.payload.error;
    }
  },
};

export { getGWSTenantDetailsInfoAsync, getGWSTenantDetailsInfoReducers };
