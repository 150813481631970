import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMailboxChangeStatusAPI } from '@services/WebApiService';

const fetchMailboxChangeStatus = createAsyncThunk('MailboxChangeStatus', async (paramsList) => {
  try {
    const data = await getMailboxChangeStatusAPI(paramsList);
    return data;
  } catch (error) {
    return { error: error.response };
  }
});

const { pending, fulfilled, rejected } = fetchMailboxChangeStatus;

const mailboxChangeStatusReducers = {
  [pending]: (state) => {
    state.mailboxChangeStatusState.fetching = true;
  },
  [rejected]: (state) => {
    state.mailboxChangeStatusState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.mailboxChangeStatusState.fetching = false;

    if (action?.payload?.error) {
      state.mailboxChangeStatusState.errorMessage = action.payload.error?.data.message;
      state.mailboxChangeStatusState.statusCode = action.payload.error?.status;

      if (action?.payload?.error?.status === 403) {
        state.mailboxChangeStatusState.forbiddenState = true;
        state.mailboxChangeStatusState.forbiddenErrorMessage = {
          heading: 'Forbidden Error',
          caption: 'You are not authorize to access this resource',
        };
      }
    }

    if (!action?.payload?.data && !action.payload?.error) {
      state.mailboxChangeStatusState.mailboxChangeStatusCorsErrorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.data) {
      state.mailboxChangeStatusState.forbiddenState = false;
      state.mailboxChangeStatusState.forbiddenErrorMessage = {};
      state.mailboxChangeStatusState.statusCode = action.payload?.status;
      state.mailboxChangeStatusState.data = action?.payload?.data;
    }
  },
};

export { fetchMailboxChangeStatus, mailboxChangeStatusReducers };

export default fetchMailboxChangeStatus;
