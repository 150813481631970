import { QboButton } from '@ui/Components';
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  exportTenantSizeReportAsync,
  resetExportTenantSizeReportState,
} from '@features/tenantSizeReport/exportTenantSizeReportSlice';
import useLocalStorage from '@hooks/useLocalStorage';
import { constant } from '@config/BaseSetting';
import useDownloadExcel from '@hooks/useDownloadExcel';

export default function TenantSizeReportDownloadSection(props) {
  const { jobId, jobType, data, setSnackbarState } = props;
  const [accessToken] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const dispatch = useDispatch();
  const { exportTenantSizeReportState } = useSelector((state) => state.exportTenantSizeReport);

  const DownloadData = () => {
    if (!jobId || !jobType) return;
    dispatch(
      exportTenantSizeReportAsync({
        accessToken,
        reset: true,
        workerId: jobId,
      })
    );
  };

  const handleResetExport = () => {
    dispatch(resetExportTenantSizeReportState());
  };

  const getExportFileName = () => {
    if (!Object.keys(data).length) {
      return 'Tenant Size Report.xlsx';
    }

    return `Tenant Size Report - email[${JSON.parse(data.payload)?.upn}].xlsx`;
  };

  useDownloadExcel(
    exportTenantSizeReportState.data,
    exportTenantSizeReportState.contentType,
    getExportFileName(),
    handleResetExport,
    () => setSnackbarState({ open: true, type: 'success', message: 'Successfully downloaded' }),
    (err) => setSnackbarState({ open: true, type: 'error', message: err })
  );

  return (
    <QboButton style={{ marginBottom: '1rem' }} variant="contained" onClick={DownloadData}>
      Download
    </QboButton>
  );
}

TenantSizeReportDownloadSection.propTypes = {
  jobId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  jobType: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  data: PropTypes.object,
  setSnackbarState: PropTypes.func,
};

TenantSizeReportDownloadSection.defaultProps = {
  data: {},
  setSnackbarState: () => {},
};
