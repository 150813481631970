import React from 'react';
import PropTypes from 'prop-types';
import { QboCard, QboCardCaption } from '@ui/Components';
import NoStatus from '@images/generals/NoStatus.svg';
import { useTranslation } from 'react-i18next';

export default function NoStatusSection(props) {
  const { t } = useTranslation();
  const { forbiddenErrorMessage } = props;

  return (
    <QboCard noPadding isMainWrapper>
      <QboCardCaption
        imageUrl={NoStatus}
        heading={
          forbiddenErrorMessage?.heading ||
          t('system_status_section.no_status_section.heading_text')
        }
        caption={
          forbiddenErrorMessage?.caption ||
          t('system_status_section.no_status_section.caption_text')
        }
      />
    </QboCard>
  );
}

NoStatusSection.propTypes = {
  forbiddenErrorMessage: PropTypes.object,
};

NoStatusSection.defaultProps = {
  forbiddenErrorMessage: {},
};
