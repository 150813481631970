import React, { useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import { ITBox, QboAdvanceTable, QboAlert, QboButton, QboCard, QboModal } from '@ui/Components';
import { resetSeatCountChangeFormSubmit } from '@features/seatCountChange/seatCountChangeSlice';
import SeatCountChangeFormSection from '@ui/Sections/SeatCountChangePage/SeatCountChangeFormSection';
import useLocalStorage from '@hooks/useLocalStorage';
import BaseSetting, { constant } from '@config/BaseSetting';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import useForm from './pageHooks/useForm';

export default function SeatCountChange() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [pagesSet, setPagesSet] = useState({});
  const [pageNum, setPageNum] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const { getSeatCountChangeState } = useSelector((state) => state.getSeatCountChangeState);
  const [noStatusFlag, setNoStatusFlag] = useState(false);

  const {
    data,
    fetching,
    success,
    forbiddenState,
    forbiddenErrorMessage,
    seatCountChangeCorsErrorMessage,
  } = getSeatCountChangeState;

  const {
    supportTicketAttributes,
    escalationTicketAttributes,
    descriptionAttributes,
    organizationOwnerIdAttributes,
    changeOfSeatsAttributes,
    isValid,
    resetForm,
    formData,
    onSubmit,
    getSeatCountChangeAPIHandler,
    getSeatCountChangeServices,
  } = useForm();

  const { submitSeatCountChangeForm } = useSelector((state) => state.submitSeatCountChangeState);

  const [sharedDriveData, setSeatCountChangeData] = useState([]);
  const [value] = useLocalStorage(constant.REGIONS_LIST);
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);

  const { errorMessage, statusCode } = submitSeatCountChangeForm;

  useEffect(() => {
    getSeatCountChangeServices(setSeatCountChangeData, setPagesSet);
  }, [data]);

  const callApiHandler = async () => {
    if (accessTokenSet) {
      getSeatCountChangeAPIHandler(pagination.pageIndex + 1);
    }
  };

  const refreshHandler = () => {
    setRefresh(true);
  };

  useEffect(() => {
    setPagination({ pageIndex: 0, pageSize: pagesSet.PageSize });
  }, [pagesSet.pageIndex, refresh]);
  useEffect(() => {
    callApiHandler();
    setRefresh(false);
  }, [pagination.pageIndex, refresh]);

  const stateResetHandler = () => {
    resetForm();
    dispatch(resetSeatCountChangeFormSubmit());
  };

  const clickOnClose = () => {
    setOpen(false);
    refreshHandler();
    stateResetHandler();
  };

  useEffect(() => {
    if (!success || sharedDriveData?.length === 0) {
      setNoStatusFlag(true);
    } else if (success && sharedDriveData?.length !== 0) {
      setNoStatusFlag(false);
    }
  }, [success, sharedDriveData?.length]);

  return (
    <>
      <HeaderPageBlock>{t('seat_count_change.header.text')}</HeaderPageBlock>
      {seatCountChangeCorsErrorMessage && (
        <QboAlert type="error" style={{ fontSize: '1.15em' }}>
          {seatCountChangeCorsErrorMessage}
        </QboAlert>
      )}
      <QboCard>
        {!forbiddenState && (
          <ITBox className="SystemStatusSection__wrapper" direction="column">
            <ITBox className="SystemStatusSection__create-button">
              <IconButton sx={{ position: 'relative', right: '0.7rem' }}>
                <RefreshIcon
                  sx={{ width: '2rem', height: '2rem' }}
                  onClick={() => refreshHandler()}
                />
              </IconButton>
              &nbsp;
              <QboButton variant="contained" onClick={() => setOpen(true)}>
                {t('mailbox_change.new_request')}
              </QboButton>
            </ITBox>
          </ITBox>
        )}

        <QboCard noPadding isMainWrapper>
          {success && sharedDriveData?.length > 0 && value && !forbiddenState && (
            <QboAdvanceTable
              enableSorting
              headers={BaseSetting.SeatCountChanges}
              rows={sharedDriveData}
              pagination={pagination}
              setPagination={setPagination}
              rowsCount={Number(pagesSet.TotalCount)}
              isLoading={fetching}
            />
          )}
          {(noStatusFlag || !value || forbiddenState) && !fetching && (
            <NoStatusSection forbiddenErrorMessage={forbiddenErrorMessage} />
          )}
          <QboModal
            showCloseIcon
            classNameStyle="mail_modal"
            preventOutsideClick
            title={t('seat_count_change.form.title_text')}
            open={open}
            onClose={() => clickOnClose()}>
            <SeatCountChangeFormSection
              supportTicketAttributes={supportTicketAttributes}
              escalationTicketAttributes={escalationTicketAttributes}
              descriptionAttributes={descriptionAttributes}
              organizationOwnerIdAttributes={organizationOwnerIdAttributes}
              changeOfSeatsAttributes={changeOfSeatsAttributes}
              isValid={isValid}
              resetForm={resetForm}
              formData={formData}
              onSubmit={onSubmit}
            />
          </QboModal>
        </QboCard>
      </QboCard>
    </>
  );
}
