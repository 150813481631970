import { createSlice } from '@reduxjs/toolkit';
import {
  getM365TenantDetailsInfoAsync,
  getM365TenantDetailsInfoReducers,
} from './serviceActions/getM365TenantDetailsInfoAsync';

const initialState = {
  getM365TenantDetailsInfoState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    m365TenantDetailsInformation: [],
    forbiddenState: false,
    forbiddenErrorMessage: null,
    m365TenantDetailsInfoCorsErrorMessage: null,
  },
};

const m365TenantDetailsInfoSlice = createSlice({
  name: 'M365TenantDetailsInfo',
  initialState,
  reducers: {
    resetM365TenantDetailsInfoState(state) {
      state.getM365TenantDetailsInfoState = initialState.getM365TenantDetailsInfoState;
    },
    resetM365TenantDetailsInfoStatusCodeState(state) {
      state.getM365TenantDetailsInfoState.statusCode =
        initialState.getM365TenantDetailsInfoState.statusCode;
    },
  },
  extraReducers: {
    ...getM365TenantDetailsInfoReducers,
  },
});

export { getM365TenantDetailsInfoAsync };
export const { resetM365TenantDetailsInfoState, resetM365TenantDetailsInfoStatusCodeState } =
  m365TenantDetailsInfoSlice.actions;
export default m365TenantDetailsInfoSlice.reducer;
