import { createSlice } from '@reduxjs/toolkit';
import { getGWSTenantAsync, getGWSTenantReducers } from './serviceActions/getGWSTenantAsync';

const initialState = {
  getGWSTenantState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    paginationList: null,
    gwsTenantInformation: [],
    forbiddenState: false,
    forbiddenErrorMessage: null,
    gwsTenantCorsErrorMessage: null,
  },
};

const gwsTenantSlice = createSlice({
  name: 'GWSTenant',
  initialState,
  reducers: {
    resetGWSTenantState(state) {
      state.getGWSTenantState = initialState.getGWSTenantState;
    },
    resetGWSTenantStatusCodeState(state) {
      state.getGWSTenantState.statusCode = initialState.getGWSTenantState.statusCode;
    },
  },
  extraReducers: {
    ...getGWSTenantReducers,
  },
});

export { getGWSTenantAsync };
export const { resetGWSTenantState, resetGWSTenantStatusCodeState } = gwsTenantSlice.actions;
export default gwsTenantSlice.reducer;
