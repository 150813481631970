import {
  gwsTenantDetailPath,
  organizationInformationPath,
  resellersListPath,
} from '@config/Routes/WebClientRoutes';

export default function useDataService() {
  const gwsTenantHeaders = [
    {
      id: 1,
      label: 'Credential ID',
      columnName: 'id',
      sx: { paddingLeft: 2 },
      size: 100,
    },
    {
      id: 2,
      label: 'Organization Owner ID',
      columnName: 'organizationOwnerId',
      sx: { paddingLeft: 2 },
      size: 100,
    },
    {
      id: 3,
      label: 'Partner ID',
      columnName: 'partnerId',
      sx: { paddingLeft: 2 },
      size: 50,
    },
    { id: 4, label: 'Tenant ID', columnName: 'tenant', sx: { paddingLeft: 2 }, size: 100 },
    {
      id: 5,
      label: 'Email',
      columnName: 'encryptedEmail',
      sx: { paddingLeft: 2 },
    },
    {
      id: 6,
      label: 'App Auth Status',
      columnName: 'appAuthStatus',
      sx: { paddingLeft: 2 },
      size: 100,
    },
    {
      id: 7,
      label: 'Domain',
      columnName: 'domain',
      sx: { paddingLeft: 2 },
      size: 120,
    },
    { label: 'Partner Name', columnName: 'tenantPartnerName', sx: { paddingLeft: 2 } },
    { label: 'Organization Name', columnName: 'orgName', sx: { paddingLeft: 2 } },
    { label: 'Parent', columnName: 'tenantParent', sx: { paddingLeft: 2 } },
    {
      id: 8,
      label: 'User Autodiscover Enabled At',
      columnName: 'lastAutodiscoverActivatedAt',
      sx: { paddingLeft: 2 },
    },
    { id: 9, label: 'Created At', columnName: 'createdAt', sx: { paddingLeft: 2 } },
    { id: 10, label: 'Last Updated At', columnName: 'updatedAt', sx: { paddingLeft: 2 } },
    {
      id: 11,
      label: 'Last Added Backup Email At',
      columnName: 'lastAddedBackupEmailAt',
      sx: { paddingLeft: 2 },
    },
    { id: 12, label: 'Total User Numbers', columnName: 'totalUsers', sx: { paddingLeft: 2 } },
    {
      id: 13,
      label: 'Last Fetch Users At',
      columnName: 'lastScheduleGSuiteRequestAt',
      sx: { paddingLeft: 2 },
    },
    {
      id: 14,
      label: 'Last Fetch Users ID',
      columnName: 'lastRequestId',
      sx: { paddingLeft: 2 },
      size: 100,
    },
    {
      id: 15,
      label: 'Sd Drive Autodiscover Enabled At',
      columnName: 'sdDriveAutodiscoverActivatedAt',
      sx: { paddingLeft: 2 },
    },
    {
      id: 16,
      label: 'Autodiscover Setup Completed',
      columnName: 'completedAutodiscoverSetup',
      sx: { paddingLeft: 2 },
    },
    {
      id: 17,
      label: 'Last Added Sd Drive At',
      columnName: 'lastAddedBackupSdDriveAt',
      sx: { paddingLeft: 2 },
    },
    {
      id: 18,
      label: 'Credential Setup Completed',
      columnName: 'completedSetup',
      sx: { paddingLeft: 2 },
      size: 130,
    },

    {
      id: 19,
      label: 'Endpoint',
      columnName: 'url',
      sx: { paddingLeft: 2 },
    },
    {
      id: 20,
      label: 'Gws Created At',
      columnName: 'gwsCreatedAt',
      sx: { paddingLeft: 2 },
    },
    {
      id: 21,
      label: 'Gws Updated At',
      columnName: 'gwsUpdatedAt',
      sx: { paddingLeft: 2 },
    },
    {
      id: 22,
      label: 'Available Users Number',
      columnName: 'availableUsersNumber',
      sx: { paddingLeft: 2 },
      size: 130,
    },
    {
      id: 23,
      label: 'Added Users Number',
      columnName: 'addedUsersNumber',
      sx: { paddingLeft: 2 },
      size: 130,
    },
    {
      id: 24,
      label: 'Excluded Users Number',
      columnName: 'excludedUsersNumber',
      sx: { paddingLeft: 2 },
      size: 130,
    },
    {
      id: 25,
      label: 'Not Available Users Number',
      columnName: 'notAvailableUsersNumber',
      sx: { paddingLeft: 2 },
      size: 130,
    },
    {
      id: 26,
      label: 'All User Numbers',
      columnName: 'allUserNumbers',
      sx: { paddingLeft: 2 },
      size: 130,
    },
  ];

  const buttonStyle = {
    height: 36,
    backgroundColor: '#a5a5a5',
    textDecoration: 'none',
    color: 'black',
    '&:hover': {
      backgroundColor: '#a5a5a5',
    },
    textTransform: 'unset',
    fontSize: 14,
  };
  const authStatus = [
    { id: 1, value: 'Not Ready', title: 'Not Ready' },
    { id: 2, value: 'Completed', title: 'Completed' },
    { id: 3, value: 'Error', title: 'Error' },
  ];
  const autoDiscoverSetup = [
    { id: 1, value: 'true', title: 'True' },
    { id: 2, value: 'false', title: 'False' },
  ];
  const credentialSetup = [
    { id: 1, value: 'true', title: 'True' },
    { id: 2, value: 'false', title: 'False' },
  ];
  const sortColumnNames = [
    { id: 1, value: 'id', title: 'Credential ID' },
    { id: 2, value: 'domain', title: 'Domain' },
  ];

  const clickableCoulmnList = [
    {
      id: 1,
      column: 'tenant',
      path: gwsTenantDetailPath,
    },
    {
      id: 2,
      column: 'tenantPartnerName',
      path: resellersListPath,
    },
    {
      id: 3,
      column: 'orgName',
      path: organizationInformationPath,
      tab: 0,
    },
    {
      id: 4,
      column: 'tenantParent',
      path: resellersListPath,
    },
  ];
  return {
    gwsTenantHeaders,
    buttonStyle,
    authStatus,
    autoDiscoverSetup,
    credentialSetup,
    sortColumnNames,
    clickableCoulmnList,
  };
}
