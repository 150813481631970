import { createSlice } from '@reduxjs/toolkit';
import {
  submitPurgeExternalAccountAsync,
  submitPurgeExternalAccountFormExtraReducers,
} from './serviceActions/submitPurgeExternalAccountAsync';

const initialState = {
  submitPurgeExternalAccountForm: {
    submitting: false,
    success: false,
    error: null,
    statusCode: null,
    errorMessage: null,
  },
};

const purgeExternalAccountSlice = createSlice({
  name: 'PurgeExternalAccountForm',
  initialState,
  reducers: {
    resetPurgeExternalAccountFormSubmit(state) {
      state.submitPurgeExternalAccountForm = initialState.submitPurgeExternalAccountForm;
    },
  },
  extraReducers: {
    ...submitPurgeExternalAccountFormExtraReducers,
  },
});

export { submitPurgeExternalAccountAsync };
export const { resetPurgeExternalAccountFormSubmit } = purgeExternalAccountSlice.actions;
export default purgeExternalAccountSlice.reducer;
