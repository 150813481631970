import React, { useState } from 'react';
import { constant } from '@config/BaseSetting';
import { getUsersListAsync } from '@features/usersList/usersListSlice';
import useLocalStorage from '@hooks/useLocalStorage';
import { MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { showFixedAlert } from '@features/application/applicationSlice';
import { useTranslation } from 'react-i18next';
import useFeatureFlag from '@hooks/useFeatureFlag';

export default function useUserListService(
  setAdvanceClick,
  advanceClick,
  setSelectedColumn,
  searchIDFieldValue,
  fullTextSearchValue,
  resellerId,
  pagination,
  adFilter,
  selectedColumn
) {
  const dispatch = useDispatch();
  const [accessToken] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const { t } = useTranslation();
  const [toggleEnforceSSOModal, setToggleEnforceSSOModal] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const { featureFlags, hasPermission } = useFeatureFlag();

  const emptyFilterState = [
    { field: 'filter_2faStatus', value: '' },
    { field: 'filter_sso', value: '' },
    { field: 'filter_role', value: '' },
    { field: 'filter_partner', value: '' },
  ];

  const handleFetchUsersListAsync = (flag) => {
    dispatch(
      getUsersListAsync({
        accessToken,
        reset: true,
        ...{
          searchById: flag ? 0 : searchIDFieldValue || 0,
          fullTextSearch: flag ? '' : fullTextSearchValue,
          pageNumber: pagination.pageIndex + 1,
          pageSize: pagination.pageSize,
          reseller: resellerId,
          filter: flag ? emptyFilterState : adFilter,
          orderBy: selectedColumn || 'ID',
        },
      })
    );
  };

  const { getUsersListState } = useSelector((state) => state.usersList);
  const { fetching } = getUsersListState;

  const handleOnAdvanceSearchClick = () => {
    setAdvanceClick(!advanceClick);
  };

  const handleOnSearchClick = (e) => {
    e.preventDefault();
    if (!fetching) {
      handleFetchUsersListAsync({});
    }
  };

  const handleOnSortByChange = (event) => {
    setSelectedColumn(String(event.target.value));
  };

  const matchingText = (setMatchText) => {
    if (!searchIDFieldValue && !fullTextSearchValue) {
      return '';
    }
    let textData = 'Matching';

    if (searchIDFieldValue || fullTextSearchValue) {
      if (searchIDFieldValue && fullTextSearchValue) {
        textData += ` id = '${searchIDFieldValue}' and email/name like '%${fullTextSearchValue}%', `;
      } else if (searchIDFieldValue) {
        textData += ` id = ${searchIDFieldValue},`;
      } else if (fullTextSearchValue) {
        textData += ` email/name like '%${fullTextSearchValue}%', `;
      }
    }
    adFilter.forEach((filter, index) => {
      if (filter.value) {
        if (filter.field === 'filter_partner') {
          textData += 'Partner Type = ';
        } else if (filter.field === 'filter_2faStatus') {
          textData += '2FAStatus = ';
        } else if (filter.field === 'filter_sso') {
          textData += 'SSO = ';
        } else if (filter.field === 'filter_role') {
          textData += 'Role = ';
        }
        textData += filter.value;
        textData += ', ';
      }
    });
    textData = textData.replace(/,\s*$/, '');
    setMatchText(textData);
    return textData;
  };

  const showToggleEnforceSSOModal = (row) => {
    const user = row.original;
    if (user.deletedOn || user.role === 'user') {
      dispatch(
        showFixedAlert({
          descriptionHtml: t('user_page.toggle_enforce_sso.validations.main_and_active'),
          severity: 'error',
        })
      );
    } else {
      setToggleEnforceSSOModal(true);
      setSelectedAccount({
        sso: user.sso,
        id: user.userID,
        type: 1,
        name: user.name,
        email: user.email,
        partnerId: user.id,
        partnerName: user.partnerName,
      });
    }
  };

  const menuActions = ({ row, closeMenu }) => {
    const i = 1;
    const items = [];
    if (hasPermission(featureFlags.UpdateEnforceSSO)) {
      items.push(
        <MenuItem
          key={i}
          onClick={() => {
            showToggleEnforceSSOModal(row);
            setTimeout(() => {
              closeMenu();
            }, 1000);
          }}>
          {t('user_page.toggle_enforce_sso.title')}
        </MenuItem>
      );
    }
    return items;
  };

  const hasMenuActions = hasPermission(featureFlags.UpdateEnforceSSO);

  return {
    handleOnAdvanceSearchClick,
    handleOnSearchClick,
    handleOnSortByChange,
    handleFetchUsersListAsync,
    matchingText,
    selectedAccount,
    setSelectedAccount,
    menuActions,
    hasMenuActions,
    toggleEnforceSSOModal,
    setToggleEnforceSSOModal,
  };
}
