import { createSlice } from '@reduxjs/toolkit';
import {
  fetchSeatUsageReportsAsync,
  seatUsageReportsReducers,
} from './serviceActions/fetchSeatUsageReportsAsync';

const initialState = {
  seatUsageReportsState: {
    fetching: false,
    seatUsageData: [],
    errorMessage: null,
    pagination: [],
    statusCode: null,
    forbiddenState: false,
    forbiddenErrorMessage: {},
    corsErrorMessage: null,
  },
};

const seatUsageReportsSlice = createSlice({
  name: 'SeatUsageReportsList',
  initialState,
  reducers: {
    resetSeatUsageReportsStatus(state) {
      state.seatUsageReportsState = initialState.seatUsageReportsState;
    },
    resetSeatUsageReportsListStatus(state) {
      state.seatUsageReportsState.seatUsageData = initialState.seatUsageReportsState.seatUsageData;
    },
    resetSeatUsageReportsErrorStatus(state) {
      state.seatUsageReportsState.errorMessage = initialState.seatUsageReportsState.errorMessage;
    },
  },
  extraReducers: {
    ...seatUsageReportsReducers,
  },
});

export { fetchSeatUsageReportsAsync };
export const {
  resetSeatUsageReportsStatus,
  resetSeatUsageReportsListStatus,
  resetSeatUsageReportsErrorStatus,
} = seatUsageReportsSlice.actions;
export default seatUsageReportsSlice.reducer;
