import { createSlice } from '@reduxjs/toolkit';
import {
  reRunDataIngestionListItemAsync,
  getReRunDataIngestionListItemExtraReducers,
} from './serviceActions/reRunIngestionListAsync';

const initialState = {
  getReRunDataIngestionListItemState: {
    submitting: false,
    success: false,
    error: null,
    reRunIngestionListResult: null,
    statusCode: null,
    errorMessage: null,
  },
};

const ingestionReRunSlice = createSlice({
  name: 'IngestionReRun',
  initialState,
  reducers: {
    resetIngestionReRunState(state) {
      state.getReRunDataIngestionListItemState = initialState.getReRunDataIngestionListItemState;
    },
  },
  extraReducers: {
    ...getReRunDataIngestionListItemExtraReducers,
  },
});

export { reRunDataIngestionListItemAsync };
export const { resetIngestionReRunState } = ingestionReRunSlice.actions;
export default ingestionReRunSlice.reducer;
