import { createAsyncThunk } from '@reduxjs/toolkit';
import { getM365UsersTenantAPI } from '@services/WebApiService';

const getM365UsersTenantAsync = createAsyncThunk(
  'M365UsersTenant/getM365UsersTenant',
  async (paramsList) => {
    try {
      const data = await getM365UsersTenantAPI(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = getM365UsersTenantAsync;

const getM365UsersTenantReducers = {
  [pending]: (state) => {
    state.getM365UsersTenantState.fetching = true;
  },
  [rejected]: (state) => {
    state.getM365UsersTenantState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.getM365UsersTenantState.fetching = false;

    if (action?.payload?.status === 403) {
      state.getM365UsersTenantState.forbiddenState = true;
      state.getM365UsersTenantState.forbiddenErrorMessage = {
        heading: 'Forbidden Error',
        caption: 'You are not authorize to access this resource',
      };
    }

    if (!action?.payload) {
      state.getM365UsersTenantState.m365UsersTenantCorsErrorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.data?.data) {
      state.getM365UsersTenantState.m365UsersTenantInformation = action?.payload?.data?.data;
      if (action?.payload?.data?.pagination) {
        state.getM365UsersTenantState.paginationList = JSON.parse(
          action?.payload?.data?.pagination
        );
      }
      state.getM365UsersTenantState.success = true;
      state.getM365UsersTenantState.statusCode = action?.payload?.data['status-code'];
    }

    if (action?.payload?.error) {
      state.getM365UsersTenantState.error = action.payload.error;
    }
  },
};

export { getM365UsersTenantAsync, getM365UsersTenantReducers };
