import { createSlice } from '@reduxjs/toolkit';
import { mailBoxSyncAsync, mailboxSyncReducers } from './serviceActions/mailBoxSyncAsync';

const initialState = {
  mailSyncState: {
    fetching: false,
    success: false,
    error: null,
    stausCode: null,
    data: [],
    errorMessage: null,
  },
};

const mailSyncSlice = createSlice({
  name: 'MailSync',
  initialState,
  reducers: {
    resetMailSyncStatus(state) {
      state.mailSyncState = initialState.mailSyncState;
    },
    resetStatusCodeMailSync(state) {
      state.mailSyncState.stausCode = null;
      state.mailSyncState.errorMessage = null;
    },
  },
  extraReducers: {
    ...mailboxSyncReducers,
  },
});

export { mailBoxSyncAsync };
export const { resetMailSyncStatus, resetStatusCodeMailSync } = mailSyncSlice.actions;
export default mailSyncSlice.reducer;
