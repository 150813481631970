import { useEffect, useRef } from 'react';
import { isEqual } from 'lodash';

import useFormValidation from './useFormValidation';
import useTransferSubscriptionInitiate from './useTransferSubscriptionInitiate';

export default function useForm() {
  const {
    formData,
    supportTicketAttributes,
    descriptionAttributes,
    escalationTicketAttributes,
    targetPartnerIDAttributes,
    organizationOwnerIDAttributes,
    validateAll,
    isValid,
    formSchema,
    resetForm,
  } = useFormValidation();
  const { handleCreateTransferSubscriptionInitiate, checkValueExistance } =
    useTransferSubscriptionInitiate();
  const previousPropsRef = useRef(formData);

  const handleSubmitRequest = () => {
    if (isValid && checkValueExistance(formSchema, formData)) {
      handleCreateTransferSubscriptionInitiate(formData);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    validateAll();
    const previousProps = previousPropsRef.current;
    if (!isEqual(previousProps, formData)) {
      handleSubmitRequest();
      previousPropsRef.current = formData;
    }
  };

  useEffect(() => {
    handleSubmitRequest();
  }, [isValid]);

  return {
    formData,
    supportTicketAttributes,
    descriptionAttributes,
    escalationTicketAttributes,
    targetPartnerIDAttributes,
    organizationOwnerIDAttributes,
    validateAll,
    isValid,
    resetForm,
    onSubmit,
  };
}
