import React, { useEffect, useState } from 'react';
import {
  ITBox,
  QboAdvanceTable,
  QboAlert,
  QboBreadcrumbs,
  QboCard,
  QboCardCaption,
  QboTypography,
} from '@ui/Components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import { IconButton } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

import { constant } from '@config/BaseSetting';
import NoStatus from '@images/generals/NoStatus.svg';
import useLocalStorage from '@hooks/useLocalStorage';
import { switchCredentialsPath } from '@config/Routes/WebClientRoutes';
import { resetSwitchCredentialItemsState } from '@features/switchCredential/getSwitchCredentialItemsSlice';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import useDetailSwitchCredential from './pageHooks/useDetailSwitchCredential';

export default function DetailSwitchCredentialPage() {
  const { t } = useTranslation();
  const { state } = useLocation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { getListAsync, getStatusAsync } = useDetailSwitchCredential();
  const [regionValue] = useLocalStorage(constant.REGIONS_LIST);
  const [paginations, setPaginations] = useState({ pageIndex: 0, pageSize: 10 });
  const row = state?.row;

  const { switchCredentialItemsState } = useSelector((s) => s.getSwitchCredentialItems);
  const {
    fetching,
    list,
    pagination,
    forbiddenState,
    forbiddenErrorMessage,
    switchCredentialItemsCorsErrorMessage: corsError,
  } = switchCredentialItemsState;

  const { switchCredentialStatusState } = useSelector((s) => s.getSwitchCredentialStatus);
  const { data, switchCredentialStatusCorsErrorMessage } = switchCredentialStatusState;

  const fetchStatusAsync = () => {
    if (!switchCredentialStatusState?.fetching) {
      getStatusAsync(id);
    }
  };

  const refreshStatus = () => {
    fetchStatusAsync();
  };

  useEffect(() => {
    fetchStatusAsync();
  }, [id]);

  const fetchDetailAsync = () => {
    if (!fetching) {
      dispatch(resetSwitchCredentialItemsState());
      getListAsync(paginations.pageSize, paginations.pageIndex + 1, id);
    }
  };

  const refreshHandler = () => {
    fetchDetailAsync();
  };

  useEffect(() => {
    fetchDetailAsync();
  }, [paginations.pageIndex, paginations.pageSize, id]);

  const tableHeaders = [
    { id: 1, label: 'ID', columnName: 'id', sx: { paddingLeft: 2 } },
    {
      id: 2,
      label: 'Org Owner Id',
      columnName: 'organization-owner-id',
      sx: { paddingLeft: 2 },
    },
    {
      id: 3,
      label: 'Email',
      columnName: 'mailbox-name',
      sx: { paddingLeft: 2 },
    },
    {
      id: 4,
      label: 'Status',
      columnName: 'status-description',
      sx: { paddingLeft: 2 },
    },
    {
      id: 5,
      label: 'Last Updated At',
      columnName: 'last-updated-on',
      sx: { paddingLeft: 2 },
    },
    {
      id: 6,
      label: 'Retry Count',
      columnName: 'retry-count',
      sx: { paddingLeft: 2 },
    },
    {
      id: 7,
      label: 'Last Retry At',
      columnName: 'last-retried-on',
      sx: { paddingLeft: 2 },
    },
  ];

  const tableStatusHeaders = [
    { id: 1, label: 'In Progress', columnName: 'in-progress-count', sx: { paddingLeft: 2 } },
    { id: 2, label: 'Success', columnName: 'success-count', sx: { paddingLeft: 2 } },
    { id: 3, label: 'Error', columnName: 'error-count', sx: { paddingLeft: 2 } },
  ];

  return (
    <>
      <span style={{ padding: '2.2rem 1rem 1.8rem 1rem' }}>
        {' '}
        <QboBreadcrumbs>
          <Link
            to={switchCredentialsPath}
            style={{ color: '#1c63d5', fontSize: '1.5rem', textDecoration: 'underline' }}>
            Switch Credential
          </Link>
          <QboTypography color="text.primary" style={{ fontSize: '1.5rem' }}>
            Details
          </QboTypography>
        </QboBreadcrumbs>
      </span>
      {(corsError || switchCredentialStatusCorsErrorMessage) && (
        <QboAlert type="error" style={{ fontSize: '1.15em' }}>
          {corsError || switchCredentialStatusCorsErrorMessage}
        </QboAlert>
      )}
      <QboCard>
        <ITBox className="SystemStatusSection__wrapper" direction="row">
          <QboTypography
            className="HeaderPageBlock__heading"
            variant="h4"
            sx={{ display: 'flex', alignItems: 'center', paddingLeft: 2 }}>
            Status Summary
          </QboTypography>
          <ITBox className="SystemStatusSection__create-button">
            <IconButton
              sx={{ position: 'relative', right: '0.7rem' }}
              onClick={() => refreshStatus()}>
              <RefreshIcon sx={{ width: '2rem', height: '2rem' }} />
            </IconButton>
          </ITBox>
        </ITBox>

        {Object.keys(data).length > 0 && (
          <QboAdvanceTable
            enableTopToolBar={false}
            enableBottomToolbar={false}
            rows={[data]}
            isLoading={switchCredentialStatusState?.fetching}
            enableSorting={Boolean(true)}
            rowsCount={5}
            headers={tableStatusHeaders}
          />
        )}
        {Object.keys(data).length === 0 && (
          <QboCardCaption
            imageSx={{ height: 120 }}
            sx={{ width: '100%', height: '220px' }}
            imageUrl={NoStatus}
            heading={t('system_status_section.no_status_section.no_record_text')}
          />
        )}
      </QboCard>
      &nbsp;
      <QboCard>
        <ITBox className="SystemStatusSection__wrapper" direction="row">
          <QboTypography
            className="HeaderPageBlock__heading"
            variant="h4"
            sx={{ display: 'flex', alignItems: 'center', paddingLeft: 2 }}>
            Switch Credential for ID: &nbsp;
            {id}
          </QboTypography>
          <ITBox className="SystemStatusSection__create-button">
            <IconButton
              sx={{ position: 'relative', right: '0.7rem' }}
              onClick={() => refreshHandler()}>
              <RefreshIcon sx={{ width: '2rem', height: '2rem' }} />
            </IconButton>
          </ITBox>
        </ITBox>

        {(list.length > 0 || fetching) && (
          <QboAdvanceTable
            rows={list}
            isLoading={fetching}
            enableSorting={Boolean(true)}
            pagination={paginations}
            setPagination={setPaginations}
            rowsCount={pagination?.TotalCount || 0}
            headers={tableHeaders}
          />
        )}
        {((!fetching && list.length === 0) || !regionValue || forbiddenState) && (
          <NoStatusSection forbiddenErrorMessage={forbiddenErrorMessage} />
        )}
      </QboCard>
    </>
  );
}
