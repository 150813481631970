import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMailboxChangeDetailAPI } from '@services/WebApiService';

const fetchMailboxChangeDetailAsync = createAsyncThunk(
  'ChangeMailboxDetail/changeMailboxDetail',
  async (paramsList) => {
    try {
      const data = await getMailboxChangeDetailAPI(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = fetchMailboxChangeDetailAsync;

const mailboxChangeDetailReducers = {
  [pending]: (state) => {
    state.mailboxChangeDetailState.fetching = true;
  },
  [rejected]: (state) => {
    state.mailboxChangeDetailState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.mailboxChangeDetailState.fetching = false;

    if (action?.payload?.error) {
      state.mailboxChangeDetailState.errorMessage = action.payload.error?.data.message;
      state.mailboxChangeDetailState.statusCode = action.payload.error?.status;

      if (action?.payload?.error?.status === 403) {
        state.mailboxChangeDetailState.forbiddenState = true;
        state.mailboxChangeDetailState.forbiddenErrorMessage = {
          heading: 'Forbidden Error',
          caption: 'You are not authorize to access this resource',
        };
      }
    }

    if (!action?.payload?.data && !action.payload?.error) {
      state.mailboxChangeDetailState.mailboxChangeDetailCorsErrorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.data?.data) {
      state.mailboxChangeDetailState.forbiddenState = false;
      state.mailboxChangeDetailState.forbiddenErrorMessage = {};
      state.mailboxChangeDetailState.statusCode = action.payload?.status;
      let list = action?.payload?.data?.data;
      try {
        list = list.map((data) => ({
          ...data,
          'change-request-type-desc': data['change-request-type'] ? 'Yes' : 'No',
        }));
      } catch (err) {
        console.info(err);
      }
      state.mailboxChangeDetailState.list = list;

      if (action?.payload?.data?.pagination) {
        state.mailboxChangeDetailState.pagination = JSON.parse(action?.payload?.data?.pagination);
      }
    }
  },
};

export { fetchMailboxChangeDetailAsync, mailboxChangeDetailReducers };

export default fetchMailboxChangeDetailAsync;
