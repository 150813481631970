import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchTodosApi } from '@services/WebApiService';

const fetchTodosAysnc = createAsyncThunk('Todos/fetchTodos', async () => {
  const data = await fetchTodosApi();
  return data;
});

const { fulfilled } = fetchTodosAysnc;

const fetchTodosExtraReducers = {
  [fulfilled]: (state, action) => {
    if (!action.payload.error) {
      state.todos = action.payload.data;
    }
  },
};

export { fetchTodosAysnc, fetchTodosExtraReducers };
