import { createAsyncThunk } from '@reduxjs/toolkit';
import { bulkCreateSubResellerApi } from '@services/WebApiService';

const bulkCreateSubResellerAsync = createAsyncThunk(
  'BulkCreateSubReseller/bulkCreateSubReseller',
  async (paramsList) => {
    try {
      const data = await bulkCreateSubResellerApi(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = bulkCreateSubResellerAsync;

const bulkCreateSubResellerExtraReducers = {
  [pending]: (state) => {
    state.bulkCreateSubResellerState.fetching = true;
  },
  [rejected]: (state) => {
    state.bulkCreateSubResellerState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.bulkCreateSubResellerState.fetching = false;

    if (action?.payload && !action?.payload?.error) {
      state.bulkCreateSubResellerState.statusCode = action?.payload?.status;
      state.bulkCreateSubResellerState.message = action?.payload?.data?.message;
    }

    if (action?.payload?.status === 403) {
      state.bulkCreateSubResellerState.message = 'You are not authorize to access this resource';
    }

    if (!action?.payload) {
      state.bulkCreateSubResellerState.message =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.error) {
      const statusMessage = action?.payload?.error?.data;
      state.bulkCreateSubResellerState.statusCode = action?.payload?.error?.status;
      state.bulkCreateSubResellerState.message = statusMessage?.title || statusMessage?.message;
      state.bulkCreateSubResellerState.error = action.payload.error;
    } else {
      state.bulkCreateSubResellerState.success = true;
      state.bulkCreateSubResellerState.serviceType = action.payload.data.data.serviceTypeCode;
    }
  },
};

export { bulkCreateSubResellerAsync, bulkCreateSubResellerExtraReducers };
