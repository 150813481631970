import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ErrorIcon from '@mui/icons-material/Error';
import dayjs from 'dayjs';
// eslint-disable-next-line no-restricted-imports
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// eslint-disable-next-line no-restricted-imports
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { Checkbox, FormControl, MenuItem, TextField } from '@mui/material';
import {
  ITBox,
  QboAlert,
  QboCheckbox,
  QboFormGroup,
  QboTextField,
  QboTypography,
} from '@ui/Components';
import useTransferSubresellerNewService from '@pages/TransferSubresellerPage/pageHooks/useTransferSubresellerNewService';
import { resetSubmitTransferPartnerFormSubmit } from '@features/transferPartner/submitTransferPartnerSlice';

export default function TransferSubresellerFormSectionPage({
  supportTicketAttributes,
  escalationTicketAttributes,
  descriptionAttributes,
  transferTypeAttributes,
  resellerIDToBeTransferredAttributes,
  targetDistributorIDAttributes,
  effectiveTrasferDateAttributes,
  targetOwnerAttributes,
  targetChannelAttributes,
  sourceDistributorIDAttributes,
  newPriceArchiverAttributes,
  newPriceBackupAttributes,
  newPriceQboAttributes,
  newPriceNfpArchiverAttributes,
  newPriceNfpBackupAttributes,
  targetCurrencyAttributes,
  billingTypeAttributes,
  wireTransferAttributes,
  paypalTypeAttributes,
  stripeTypeAttributes,
  formData,
  onSubmit,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const transferType = {
    transferSubResellerBetweenDistributor: 1,
    transferDirectResellerToDistributorAsSubReseller: 2,
    transferSubResellerFromDistributorToMakeDirectReseller: 3,
    transferSubResellerBetweenDistributorTitle: 'Transfer sub-reseller between distributor',
    transferDirectResellerToDistributorAsSubResellerTitle:
      'Transfer direct reseller to distributor as sub-reseller',
    transferSubResellerFromDistributorToMakeDirectResellerTitle:
      'Transfer sub-reseller from distributor to make direct-reseller',
  };

  const [selectedDate, setSelectedDate] = useState(dayjs(formData.effectiveTrasferDate));

  const handleDateChange = (date) => {
    effectiveTrasferDateAttributes.handleChange(date);
    setSelectedDate(date);
  };

  const currencyTypes = ['USD', 'EUR', 'GBP', 'AUD', 'SGD', 'CAD', 'BHD'];

  const billingType = {
    monthly: 1,
    proratedBilling: 2,
    monthlyTitle: 'Monthly',
    proratedBillingTitle: 'Proration',
  };

  const paymentType = {
    paypal: 1,
    wireTransfer: 2,
    paypalTitle: 'Paypal',
    wireTransferTitle: 'Wire Transfer',
  };

  const isAstericRequired = true;

  const { submitTransferPartnerForm } = useSelector((state) => state.submitTransferPartnerState);

  const { statusCode, errorMessage } = submitTransferPartnerForm;

  useEffect(() => {
    const {
      billingType: bT,
      paymentType: pT,
      transferType: tT,
      targetCurrency: tC,
      effectiveTrasferDate,
      paypal,
      stripe,
      wireTransfer,
      ...rest
    } = formData;

    const isAnyPropertyValuePresent = Object.values(rest).some(
      (value) => value !== undefined && value !== null && value !== ''
    );
    if (statusCode && isAnyPropertyValuePresent) {
      dispatch(resetSubmitTransferPartnerFormSubmit());
    }
  }, [formData]);

  useEffect(() => {
    if (
      transferTypeAttributes.value === transferType.transferDirectResellerToDistributorAsSubReseller
    ) {
      sourceDistributorIDAttributes.handleChange('');
    } else if (
      transferTypeAttributes.value ===
      transferType.transferSubResellerFromDistributorToMakeDirectReseller
    ) {
      targetDistributorIDAttributes.handleChange('');
    }
  }, [transferTypeAttributes.value]);

  return (
    <ITBox className="TransferSubscriptionFormPage__wrapper" direction="column">
      <QboFormGroup onSubmit={(e) => onSubmit(e)} withSubmitButton buttonLabel="Validate Transfer">
        {(errorMessage || statusCode) && (
          <QboAlert type={statusCode === 200 ? 'success' : 'error'} style={{ fontSize: '1.15em' }}>
            {errorMessage || (statusCode === 200 && 'Successfully submitted')}
          </QboAlert>
        )}
        <QboTextField
          id="supportTicket"
          required={isAstericRequired}
          placeholder={t('transfer_partner_page.modal.form.support_ticket')}
          label={t('transfer_partner_page.modal.form.support_ticket')}
          fullWidth
          value={supportTicketAttributes.value}
          inputProps={{ maxLength: 225 }}
          onChange={(e) => {
            supportTicketAttributes.handleChange(e.target.value);
          }}
          errorMessage={supportTicketAttributes.errorMessage}
        />
        <QboTextField
          id="escalationTicket"
          placeholder={t('transfer_partner_page.modal.form.escalation_ticket')}
          label={t('transfer_partner_page.modal.form.escalation_ticket')}
          fullWidth
          value={escalationTicketAttributes.value}
          onChange={(e) => escalationTicketAttributes.handleChange(e.target.value)}
          inputProps={{ maxLength: 225 }}
        />
        <QboTextField
          id="description"
          placeholder={t('transfer_partner_page.modal.form.description')}
          label={t('transfer_partner_page.modal.form.description')}
          fullWidth
          value={descriptionAttributes.value}
          onChange={(e) => descriptionAttributes.handleChange(e.target.value)}
          inputProps={{ maxLength: 225 }}
        />
        <FormControl className="FormControl__default-wrapper FormControl__full-width-wrapper">
          <div className="QboTextField__label" id="transferType">
            {t('transfer_partner_page.modal.form.transfer_type')}
          </div>
          <TextField
            value={transferTypeAttributes.value}
            select
            onChange={(e) => transferTypeAttributes.handleChange(e.target.value)}>
            {[
              transferType.transferSubResellerBetweenDistributor,
              transferType.transferDirectResellerToDistributorAsSubReseller,
              transferType.transferSubResellerFromDistributorToMakeDirectReseller,
            ].map((option) => (
              <MenuItem key={option} value={option}>
                {(option === transferType.transferSubResellerBetweenDistributor &&
                  transferType.transferSubResellerBetweenDistributorTitle) ||
                  (option === transferType.transferDirectResellerToDistributorAsSubReseller &&
                    transferType.transferDirectResellerToDistributorAsSubResellerTitle) ||
                  (option === transferType.transferSubResellerFromDistributorToMakeDirectReseller &&
                    transferType.transferSubResellerFromDistributorToMakeDirectResellerTitle)}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
        {transferTypeAttributes.value !==
          transferType.transferDirectResellerToDistributorAsSubReseller && (
          <QboTextField
            id="sourceDistributorId"
            required={isAstericRequired}
            placeholder={t('transfer_partner_page.modal.form.source_distributor_id')}
            label={t('transfer_partner_page.modal.form.source_distributor_id')}
            fullWidth
            inputProps={{ maxLength: 225 }}
            value={sourceDistributorIDAttributes.value}
            onChange={(e) => {
              sourceDistributorIDAttributes.handleChange(e.target.value);
            }}
            errorMessage={sourceDistributorIDAttributes.errorMessage}
          />
        )}
        <QboTextField
          id="resellerIdToBeTransferred"
          required={isAstericRequired}
          placeholder={t('transfer_partner_page.modal.form.reseller_id_to_be_transferred')}
          label={t('transfer_partner_page.modal.form.reseller_id_to_be_transferred')}
          fullWidth
          inputProps={{ maxLength: 225 }}
          value={resellerIDToBeTransferredAttributes.value}
          onChange={(e) => {
            resellerIDToBeTransferredAttributes.handleChange(e.target.value);
          }}
          errorMessage={resellerIDToBeTransferredAttributes.errorMessage}
        />
        {transferTypeAttributes.value !==
          transferType.transferSubResellerFromDistributorToMakeDirectReseller && (
          <QboTextField
            id="targetDistributorId"
            required={isAstericRequired}
            placeholder={t('transfer_partner_page.modal.form.target_distributor_id')}
            label={t('transfer_partner_page.modal.form.target_distributor_id')}
            fullWidth
            inputProps={{ maxLength: 225 }}
            value={targetDistributorIDAttributes.value}
            onChange={(e) => {
              targetDistributorIDAttributes.handleChange(e.target.value);
            }}
            errorMessage={targetDistributorIDAttributes.errorMessage}
          />
        )}
        <FormControl className="FormControl__default-wrapper FormControl__full-width-wrapper">
          <QboTypography sx={{ fontSize: 12 }}>
            {t('transfer_partner_page.modal.form.effective_trasfer_data')}
            <span style={{ color: 'red' }}> *</span>
            <span style={{ height: '15px !important', color: '#f97d09' }}>
              &nbsp;&nbsp;(Support for scheduled transfers is coming soon!)
            </span>
          </QboTypography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['MobileDatePicker']}>
              <DemoItem>
                <MobileDatePicker
                  disabled
                  slotProps={{
                    dialog: { style: { width: '100%', fontSize: 14 } },
                    textField: {
                      size: 'small',
                      error: !!effectiveTrasferDateAttributes?.errorMessage,
                    },
                  }}
                  value={selectedDate}
                  onChange={handleDateChange}
                  renderInput={(params) => <TextField size="medium" {...params} />}
                />
              </DemoItem>
            </DemoContainer>
          </LocalizationProvider>
          {effectiveTrasferDateAttributes.errorMessage && (
            <span className="QboTextField__error-text">
              <ErrorIcon />
              {` ${effectiveTrasferDateAttributes.errorMessage}`}
            </span>
          )}
        </FormControl>
        <QboTextField
          id="targetOwner"
          required={isAstericRequired}
          placeholder={t('transfer_partner_page.modal.form.target_owner')}
          label={t('transfer_partner_page.modal.form.target_owner')}
          fullWidth
          inputProps={{ maxLength: 225 }}
          value={targetOwnerAttributes.value}
          onChange={(e) => {
            targetOwnerAttributes.handleChange(e.target.value);
          }}
          errorMessage={targetOwnerAttributes.errorMessage}
        />
        <QboTextField
          id="targetChannel"
          required={isAstericRequired}
          placeholder={t('transfer_partner_page.modal.form.target_Channel')}
          label={t('transfer_partner_page.modal.form.target_Channel')}
          fullWidth
          inputProps={{ maxLength: 225 }}
          value={targetChannelAttributes.value}
          onChange={(e) => {
            targetChannelAttributes.handleChange(e.target.value);
          }}
          errorMessage={targetChannelAttributes.errorMessage}
        />
        <QboTypography style={{ marginTop: 2 }}>
          {t('transfer_partner_page.modal.form.new_price_label')}
        </QboTypography>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 2,
          }}>
          <QboTextField
            id="newPriceArchiver"
            required={isAstericRequired}
            explicitStyleClass="FormControl__width_set-wrapper"
            placeholder={t('transfer_partner_page.modal.form.new_price_archiver')}
            label={t('transfer_partner_page.modal.form.new_price_archiver')}
            inputProps={{ maxLength: 225 }}
            value={newPriceArchiverAttributes.value}
            onChange={(e) => {
              newPriceArchiverAttributes.handleChange(e.target.value);
            }}
            errorMessage={newPriceArchiverAttributes.errorMessage}
          />
          <QboTextField
            id="newPriceBackup"
            required={isAstericRequired}
            explicitStyleClass="FormControl__width_set-wrapper"
            placeholder={t('transfer_partner_page.modal.form.new_price_backup')}
            label={t('transfer_partner_page.modal.form.new_price_backup')}
            inputProps={{ maxLength: 225 }}
            value={newPriceBackupAttributes.value}
            onChange={(e) => {
              newPriceBackupAttributes.handleChange(e.target.value);
            }}
            errorMessage={newPriceBackupAttributes.errorMessage}
          />
          <QboTextField
            id="newPriceQbo"
            explicitStyleClass="FormControl__width_set-wrapper"
            placeholder={t('transfer_partner_page.modal.form.new_price_qbo')}
            label={t('transfer_partner_page.modal.form.new_price_qbo')}
            inputProps={{ maxLength: 225 }}
            value={newPriceQboAttributes.value}
            onChange={(e) => {
              newPriceQboAttributes.handleChange(e.target.value);
            }}
            errorMessage={newPriceQboAttributes.errorMessage}
          />
          <QboTextField
            id="newPriceNfpArchiver"
            explicitStyleClass="FormControl__width_set-wrapper"
            placeholder={t('transfer_partner_page.modal.form.new_price_nfp_archiver')}
            label={t('transfer_partner_page.modal.form.new_price_nfp_archiver')}
            inputProps={{ maxLength: 225 }}
            value={newPriceNfpArchiverAttributes.value}
            onChange={(e) => {
              newPriceNfpArchiverAttributes.handleChange(e.target.value);
            }}
            errorMessage={newPriceNfpArchiverAttributes.errorMessage}
          />
          <QboTextField
            id="newPriceNfpBackup"
            explicitStyleClass="FormControl__width_set-wrapper"
            placeholder={t('transfer_partner_page.modal.form.new_price_nfp_backup')}
            label={t('transfer_partner_page.modal.form.new_price_nfp_backup')}
            inputProps={{ maxLength: 225 }}
            value={newPriceNfpBackupAttributes.value}
            onChange={(e) => {
              newPriceNfpBackupAttributes.handleChange(e.target.value);
            }}
            errorMessage={newPriceNfpBackupAttributes.errorMessage}
          />
        </div>
        <FormControl className="FormControl__default-wrapper FormControl__full-width-wrapper">
          <div className="QboTextField__label" id="targetCurrency">
            {t('transfer_partner_page.modal.form.target_currency')}
          </div>
          <TextField
            value={targetCurrencyAttributes.value}
            select
            onChange={(e) => targetCurrencyAttributes.handleChange(e.target.value)}>
            {currencyTypes.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
        <FormControl className="FormControl__default-wrapper FormControl__full-width-wrapper">
          <div className="QboTextField__label" id="billingType">
            {t('transfer_partner_page.modal.form.billing_type')}
          </div>
          <TextField
            value={billingTypeAttributes.value}
            select
            onChange={(e) => billingTypeAttributes.handleChange(e.target.value)}>
            {[billingType.monthly, billingType.proratedBilling].map((option) => (
              <MenuItem key={option} value={option}>
                {(option === billingType.monthly && billingType.monthlyTitle) ||
                  (option === billingType.proratedBilling && billingType.proratedBillingTitle)}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>

        <FormControl className="FormControl__default-wrapper FormControl__full-width-wrapper">
          <QboTypography sx={{ fontSize: 12, fontWeight: 500 }}>
            {t('transfer_partner_page.modal.form.payment_type')}
          </QboTypography>
          <div style={{ display: 'flex' }}>
            <div style={{ display: 'flex' }}>
              <Checkbox
                disabled
                label="Wire Transfer"
                checked={wireTransferAttributes.value}
                onChange={(e) => wireTransferAttributes.handleChange(e.target.checked)}
              />
              <QboTypography
                sx={{ fontSize: 12, fontWeight: 500, paddingTop: 1.2, marginRight: 2 }}>
                Wire Transfer
              </QboTypography>
            </div>
            <QboCheckbox
              label="Paypal"
              checked={paypalTypeAttributes.value}
              onChange={(e) => paypalTypeAttributes.handleChange(e.target.checked)}
            />
            <QboCheckbox
              label="Stripe"
              checked={stripeTypeAttributes.value}
              onChange={(e) => stripeTypeAttributes.handleChange(e.target.checked)}
            />
          </div>
        </FormControl>
      </QboFormGroup>
    </ITBox>
  );
}

TransferSubresellerFormSectionPage.propTypes = {
  supportTicketAttributes: PropTypes.object,
  escalationTicketAttributes: PropTypes.object,
  descriptionAttributes: PropTypes.object,
  transferTypeAttributes: PropTypes.object,
  resellerIDToBeTransferredAttributes: PropTypes.object,
  targetDistributorIDAttributes: PropTypes.object,
  sourceDistributorIDAttributes: PropTypes.object,
  effectiveTrasferDateAttributes: PropTypes.object,
  targetOwnerAttributes: PropTypes.object,
  targetChannelAttributes: PropTypes.object,
  newPriceArchiverAttributes: PropTypes.object,
  newPriceBackupAttributes: PropTypes.object,
  newPriceQboAttributes: PropTypes.object,
  newPriceNfpArchiverAttributes: PropTypes.object,
  newPriceNfpBackupAttributes: PropTypes.object,
  targetCurrencyAttributes: PropTypes.object,
  billingTypeAttributes: PropTypes.object,
  wireTransferAttributes: PropTypes.object,
  paypalTypeAttributes: PropTypes.object,
  stripeTypeAttributes: PropTypes.object,
  confirmingTheAboveInfoAttributes: PropTypes.object,
  onSubmit: PropTypes.func,
  formData: PropTypes.object,
};

TransferSubresellerFormSectionPage.defaultProps = {
  supportTicketAttributes: {},
  escalationTicketAttributes: {},
  descriptionAttributes: {},
  transferTypeAttributes: {},
  resellerIDToBeTransferredAttributes: {},
  targetDistributorIDAttributes: {},
  sourceDistributorIDAttributes: {},
  effectiveTrasferDateAttributes: {},
  targetOwnerAttributes: {},
  targetChannelAttributes: {},
  newPriceArchiverAttributes: {},
  newPriceBackupAttributes: {},
  newPriceQboAttributes: {},
  newPriceNfpArchiverAttributes: {},
  newPriceNfpBackupAttributes: {},
  targetCurrencyAttributes: {},
  billingTypeAttributes: {},
  wireTransferAttributes: {},
  paypalTypeAttributes: {},
  stripeTypeAttributes: {},
  confirmingTheAboveInfoAttributes: {},
  formData: {},
  onSubmit: () => {},
};
