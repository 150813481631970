import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@mui/material';
import { isEmpty } from 'lodash';
import RefreshIcon from '@mui/icons-material/Refresh';
import SettingsIcon from '@mui/icons-material/Settings';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import { ITBox, QboButton, QboModal, QboCard, QboAlert, QboAdvanceTable } from '@ui/Components';
import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import StepperBlock from '@ui/Blocks/Shared/StepperBlock';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import TransferSubresellerFormSectionPage from '@ui/Sections/TransferSubresellerPage/TransferSubresellerFormSectionPage';
import TransferSubresellerFormConfirmSectionPage from '@ui/Sections/TransferSubresellerPage/TransferSubresellerFormConfirmSectionPage';
import { resetSubmitTransferPartnerFormSubmit } from '@features/transferPartner/submitTransferPartnerSlice';
import { resetTransferSubresellerState } from '@features/transferSubresellerService/getTransferSubresellerSlice';
import { resetValidateTransferPartnerFormSubmit } from '@features/transferPartner/validateTransferPartnerSlice';
import useStepper from '@pages/sharedHooks/useStepper';
import BaseSetting from '@config/BaseSetting';
import useForm from './pageHooks/useForm';
import useTransferSubresellerNewService from './pageHooks/useTransferSubresellerNewService';
import useTransferSubresellerService from './pageHooks/useTransferSubresellerService';

export default function TransferSubreseller() {
  const {
    supportTicketAttributes,
    escalationTicketAttributes,
    descriptionAttributes,
    transferTypeAttributes,
    resellerIDToBeTransferredAttributes,
    targetDistributorIDAttributes,
    sourceDistributorIDAttributes,
    effectiveTrasferDateAttributes,
    targetOwnerAttributes,
    targetChannelAttributes,
    newPriceArchiverAttributes,
    newPriceBackupAttributes,
    newPriceQboAttributes,
    newPriceNfpArchiverAttributes,
    newPriceNfpBackupAttributes,
    targetCurrencyAttributes,
    billingTypeAttributes,
    paymentTypeAttributes,
    wireTransferAttributes,
    paypalTypeAttributes,
    stripeTypeAttributes,
    confirmingTheAboveInfoAttributes,
    formData,
    validateAll,
    resetForm,
    resetFormConfirmation,
    onSubmit,
  } = useForm();
  const { handleValidateTransferPartnerAsync } = useTransferSubresellerNewService();

  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const steps = ['1', '2'];

  const icons = {
    1: <SettingsIcon />,
    2: <VideoLabelIcon />,
  };

  const { activeStep, isStepOptional, handleNext, handleBack } = useStepper({});

  const transferType = {
    transferSubResellerBetweenDistributor: 1,
    transferDirectResellerToDistributorAsSubReseller: 2,
    transferSubResellerFromDistributorToMakeDirectReseller: 3,
  };

  const handleOnValidateTransferClick = async (e) => {
    e.preventDefault();
    dispatch(resetSubmitTransferPartnerFormSubmit());
    const flag = await validateAll();
    if (flag) {
      handleNext();
      const { transferType: transferTypeState } = formData;
      const newFormData = {
        ...formData,
        newPriceArchiver: Number(formData.newPriceArchiver),
        newPriceBackup: Number(formData.newPriceBackup),
        newPriceQbo: Number(formData.newPriceQbo === '' ? 0 : +formData.newPriceQbo),
        newPriceNfpArchiver: Number(
          formData.newPriceNfpArchiver === '' ? 0 : +formData.newPriceNfpArchiver
        ),
        newPriceNfpBackup: Number(
          formData.newPriceNfpBackup === '' ? 0 : +formData.newPriceNfpBackup
        ),
        sourceDistributorID: isEmpty(formData?.sourceDistributorID)
          ? 0
          : formData?.sourceDistributorID,
        resellerIDToBeTransferred: isEmpty(formData?.resellerIDToBeTransferred)
          ? 0
          : formData?.resellerIDToBeTransferred,
        targetDistributorID: isEmpty(formData?.targetDistributorID)
          ? 0
          : formData?.targetDistributorID,
      };

      if (transferTypeState === transferType.transferSubResellerBetweenDistributor) {
        handleValidateTransferPartnerAsync(newFormData);
      } else if (
        transferTypeState === transferType.transferDirectResellerToDistributorAsSubReseller
      ) {
        const { sourceDistributorID, ...rest } = newFormData;
        sourceDistributorIDAttributes.handleChange('');
        handleValidateTransferPartnerAsync(rest);
      } else if (
        transferTypeState === transferType.transferSubResellerFromDistributorToMakeDirectReseller
      ) {
        const { targetDistributorID, ...rest } = newFormData;
        targetDistributorIDAttributes.handleChange('');
        handleValidateTransferPartnerAsync(rest);
      }
    }
  };
  const onBack = () => {
    resetFormConfirmation();
    dispatch(resetValidateTransferPartnerFormSubmit());
    handleBack();
  };

  const { handleTransferSubresellerAsync, processDataTransferSubreseller } =
    useTransferSubresellerService();

  const [transferData, setTransferData] = useState([]);
  const { getTransferSubresellerState } = useSelector((state) => state.getTransferSubresellerState);
  const {
    fetching,
    transferSubresellerList,
    paginations,
    forbiddenState,
    transferSubresellerCorsErrorMessage,
  } = getTransferSubresellerState;

  const fetchHandler = () => {
    if (!fetching) {
      handleTransferSubresellerAsync(pagination.pageIndex + 1, pagination.pageSize);
    }
  };

  useEffect(() => {
    fetchHandler();
  }, [pagination.pageIndex, pagination.pageSize]);

  useEffect(() => {
    if (transferSubresellerList?.length) {
      processDataTransferSubreseller(transferSubresellerList, setTransferData);
    }
  }, [transferSubresellerList]);

  const handleRefreshClick = () => {
    dispatch(resetTransferSubresellerState());
    fetchHandler();
  };

  return (
    <>
      <HeaderPageBlock>{t('transfer_partner_page.header.text')}</HeaderPageBlock>

      {transferSubresellerCorsErrorMessage && (
        <QboAlert type="error" style={{ fontSize: '1.15em' }}>
          {transferSubresellerCorsErrorMessage}
        </QboAlert>
      )}

      <QboCard>
        <ITBox className="SystemStatusSection__wrapper" direction="column">
          {!forbiddenState && (
            <ITBox className="SystemStatusSection__create-button">
              <IconButton sx={{ position: 'relative', right: '0.7rem' }}>
                <RefreshIcon sx={{ width: '2rem', height: '2rem' }} onClick={handleRefreshClick} />
              </IconButton>
              &nbsp;
              <QboButton variant="contained" onClick={() => setShowModal(true)}>
                {t('mailbox_change.new_request')}
              </QboButton>
            </ITBox>
          )}
        </ITBox>

        <QboCard noPadding isMainWrapper>
          {(transferData?.length > 0 || fetching) && (
            <QboAdvanceTable
              isLoading={fetching}
              enableSorting
              rows={transferData}
              headers={BaseSetting.transferSubresellerHeaders}
              rowsCount={Number(paginations?.TotalCount)}
              pagination={pagination}
              setPagination={setPagination}
            />
          )}
          {transferData?.length === 0 && !fetching && <NoStatusSection />}
        </QboCard>
        <QboModal
          onClose={() => {
            setShowModal(false);
            resetForm();
            dispatch(resetSubmitTransferPartnerFormSubmit());
            fetchHandler();
          }}
          open={showModal}
          title={t('transfer_partner_page.modal.title_text')}
          showCloseIcon
          classNameStyle="DataIngestion_modal"
          preventOutsideClick>
          <StepperBlock
            steps={steps}
            icons={icons}
            hideLabel
            activeStepAlt={activeStep}
            isStepOptionalAlt={isStepOptional}
            isStepSkippedAlt={isStepOptional}>
            <TransferSubresellerFormSectionPage
              supportTicketAttributes={supportTicketAttributes}
              escalationTicketAttributes={escalationTicketAttributes}
              descriptionAttributes={descriptionAttributes}
              transferTypeAttributes={transferTypeAttributes}
              resellerIDToBeTransferredAttributes={resellerIDToBeTransferredAttributes}
              targetDistributorIDAttributes={targetDistributorIDAttributes}
              sourceDistributorIDAttributes={sourceDistributorIDAttributes}
              effectiveTrasferDateAttributes={effectiveTrasferDateAttributes}
              targetOwnerAttributes={targetOwnerAttributes}
              targetChannelAttributes={targetChannelAttributes}
              newPriceArchiverAttributes={newPriceArchiverAttributes}
              newPriceBackupAttributes={newPriceBackupAttributes}
              newPriceQboAttributes={newPriceQboAttributes}
              newPriceNfpArchiverAttributes={newPriceNfpArchiverAttributes}
              newPriceNfpBackupAttributes={newPriceNfpBackupAttributes}
              targetCurrencyAttributes={targetCurrencyAttributes}
              billingTypeAttributes={billingTypeAttributes}
              paymentTypeAttributes={paymentTypeAttributes}
              wireTransferAttributes={wireTransferAttributes}
              paypalTypeAttributes={paypalTypeAttributes}
              stripeTypeAttributes={stripeTypeAttributes}
              confirmingTheAboveInfoAttributes={confirmingTheAboveInfoAttributes}
              onSubmit={handleOnValidateTransferClick}
              formData={formData}
            />
            <TransferSubresellerFormConfirmSectionPage
              confirmingTheAboveInfoAttributes={confirmingTheAboveInfoAttributes}
              onSubmit={onSubmit}
              formData={formData}
              onBack={onBack}
              resetForm={resetForm}
            />
          </StepperBlock>
        </QboModal>
      </QboCard>
    </>
  );
}
