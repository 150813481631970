import { createSlice } from '@reduxjs/toolkit';

import { getM365TenantAsync, getM365TenantReducers } from './serviceActions/getM365TenantAsync';

const initialState = {
  getM365TenantState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    paginationList: null,
    m365TenantInformation: [],
    forbiddenState: false,
    forbiddenErrorMessage: null,
    m365TenantCorsErrorMessage: null,
  },
  m365TenantRowInfo: {},
};

const m365TenantSlice = createSlice({
  name: 'M365Tenant',
  initialState,
  reducers: {
    resetM365TenantState(state) {
      state.getM365TenantState = initialState.getM365TenantState;
    },
    setM365TenantRowState(state, action) {
      state.m365TenantRowInfo = action?.payload;
    },
    resetM365TenantStatusCodeState(state) {
      state.getM365TenantState.statusCode = initialState.getM365TenantState.statusCode;
    },
  },
  extraReducers: {
    ...getM365TenantReducers,
  },
});

export { getM365TenantAsync };
export const { resetM365TenantState, resetM365TenantStatusCodeState, setM365TenantRowState } =
  m365TenantSlice.actions;
export default m365TenantSlice.reducer;
