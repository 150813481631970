import useFeatureFlag from '@hooks/useFeatureFlag';
import useCheckboxTable from '@pages/sharedHooks/useCheckboxTable';
import { EMAIL } from '@pages/UserBackupPage/pageHooks/constants/emailConstants';

export default function useUserSelections({ backupType, list, columns }) {
  const extendList = list.map((item) => ({ id: item.userBackupId, ...item }));
  const extendColumns = columns;
  let withCheckbox = false;

  const useCheckbox = useCheckboxTable({
    listIdOnPage: extendList.map((item) => item.id),
    selectAllPageOnly: true,
  });

  const { hasPermission, featureFlags } = useFeatureFlag();

  if (backupType === EMAIL && hasPermission(featureFlags.RunBackup)) {
    withCheckbox = true;
  }

  return {
    extendList,
    extendColumns,
    useCheckbox,
    withCheckbox,
  };
}
