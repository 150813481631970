import React, { useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ITBox, QboAlert, QboButton, QboCard, QboModal } from '@ui/Components';
import BaseSetting from '@config/BaseSetting';
import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import QboAdvanceTable from '@ui/Components/QboAdvanceTable';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import TransferSubscriptionInitiateForm from '@ui/Sections/TransferSubscriptionInitiatePage/TransferSubscriptionInitiateForm';
import { resetCreateTransferSubscriptionInitiateState } from '@features/transferSubscriptionInitiatePageService/createTransferSubscriptionInitiateSlice';
import useTransferSubscriptionInitiate from './pageHooks/useTransferSubscriptionInitiate';
import useForm from './pageHooks/useForm';

export default function TransferSubscriptionInitiatePage() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { handleTransferSubscriptionInitiateAsync, processDataTransferSubscriptionInitiate } =
    useTransferSubscriptionInitiate();
  const [paginations, setPaginations] = useState({ pageIndex: 0, pageSize: 10 });
  const [initiateListState, setInitiateListState] = useState([]);
  const { getTransferSubscriptionInitiateState } = useSelector((state) => state.initiateState);
  const {
    fetching,
    transferSubscriptionInitiateList,
    paginations: paginationsList,
    forbiddenState,
    forbiddenErrorMessage,
    transferSubscriptionInitiateCorsErrorMessage,
  } = getTransferSubscriptionInitiateState;

  const [showModal, setShowModal] = useState(false);

  const fetchHandler = () => {
    if (!fetching) {
      handleTransferSubscriptionInitiateAsync(paginations.pageIndex + 1, paginations.pageSize);
    }
  };

  useEffect(() => {
    if (transferSubscriptionInitiateList?.length) {
      processDataTransferSubscriptionInitiate(
        transferSubscriptionInitiateList,
        setInitiateListState
      );
    }
  }, [transferSubscriptionInitiateList]);

  useEffect(() => {
    fetchHandler();
  }, [paginations.pageIndex, paginations.pageSize]);

  const {
    supportTicketAttributes,
    descriptionAttributes,
    escalationTicketAttributes,
    targetPartnerIDAttributes,
    organizationOwnerIDAttributes,
    resetForm,
    onSubmit,
    formData,
  } = useForm();

  const { transferSubscriptionInitiateState } = useSelector((state) => state.initiateRequestState);
  const { status } = transferSubscriptionInitiateState;

  useEffect(() => {
    if (status === 200) {
      resetForm();
    }
  }, [status]);

  return (
    <>
      <HeaderPageBlock>{t('initiate_page.header.text')}</HeaderPageBlock>
      {transferSubscriptionInitiateCorsErrorMessage && (
        <QboAlert type="error" style={{ fontSize: '1.15em' }}>
          {transferSubscriptionInitiateCorsErrorMessage}
        </QboAlert>
      )}
      <QboCard>
        <ITBox className="SystemStatusSection__wrapper" direction="column">
          {!forbiddenState && (
            <ITBox className="SystemStatusSection__create-button">
              <IconButton sx={{ position: 'relative', right: '0.7rem' }} onClick={fetchHandler}>
                <RefreshIcon sx={{ width: '2rem', height: '2rem' }} />
              </IconButton>
              &nbsp;
              <QboButton variant="contained" onClick={() => setShowModal(true)}>
                {t('mailbox_change.new_request')}
              </QboButton>
            </ITBox>
          )}
        </ITBox>

        <QboCard noPadding isMainWrapper>
          <>
            {(initiateListState?.length || fetching) && (
              <QboAdvanceTable
                rows={initiateListState}
                isLoading={fetching}
                pagination={paginations}
                setPagination={setPaginations}
                headers={BaseSetting.transferSubscriptionInitiatePageHeaders}
                enableSorting={Boolean(true)}
                rowsCount={Number(paginationsList?.TotalCount)}
              />
            )}
            {!initiateListState?.length && !fetching && (
              <NoStatusSection forbiddenErrorMessage={forbiddenErrorMessage} />
            )}
          </>
        </QboCard>
        <QboModal
          onClose={() => {
            setShowModal(false);
            resetForm();
            fetchHandler();
            dispatch(resetCreateTransferSubscriptionInitiateState());
          }}
          open={showModal}
          title={t('initiate_page.header.text')}
          showCloseIcon
          preventOutsideClick>
          <TransferSubscriptionInitiateForm
            supportTicketAttributes={supportTicketAttributes}
            descriptionAttributes={descriptionAttributes}
            escalationTicketAttributes={escalationTicketAttributes}
            targetPartnerIDAttributes={targetPartnerIDAttributes}
            organizationOwnerIDAttributes={organizationOwnerIDAttributes}
            formData={formData}
            onSubmit={onSubmit}
          />
        </QboModal>
      </QboCard>
    </>
  );
}
