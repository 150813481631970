import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { ITBox, QboAlert, QboFormGroup, QboTextField } from '@ui/Components';
import { Grid } from '@mui/material';

export default function RequestFormSection({ onSubmit, formData, detailState }) {
  const { t } = useTranslation();
  const { statusCode: status, errorMessage, submitting } = detailState;
  const isAstericRequired = true;

  return (
    <ITBox className="TransferSubscriptionFormPage__wrapper" direction="column">
      <QboFormGroup loadingButton={submitting} onSubmit={onSubmit} withSubmitButton>
        {errorMessage && status !== 200 && (
          <QboAlert type="error" style={{ fontSize: '1.15em' }}>
            {errorMessage}
          </QboAlert>
        )}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <QboTextField
              id="supportTicket"
              required={isAstericRequired}
              placeholder={t('change_backup_admin.labels.support_ticket')}
              label={t('change_backup_admin.labels.support_ticket')}
              fullWidth
              value={formData.supportTicketAttributes.value}
              errorMessage={formData.supportTicketAttributes.errorMessage}
              inputProps={{ maxLength: 225 }}
              onChange={(e) => {
                formData.supportTicketAttributes.handleChange(e.currentTarget.value);
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <QboTextField
              id="escalationTicket"
              placeholder={t('change_backup_admin.labels.escalation_ticket')}
              label={t('change_backup_admin.labels.escalation_ticket')}
              fullWidth
              value={formData.escalationTicketAttributes.value}
              onChange={(e) =>
                formData.escalationTicketAttributes.handleChange(e.currentTarget.value)
              }
              inputProps={{ maxLength: 225 }}
            />
          </Grid>
        </Grid>

        <QboTextField
          id="description"
          placeholder={t('change_backup_admin.labels.description')}
          label={t('change_backup_admin.labels.description')}
          fullWidth
          value={formData.descriptionAttributes.value}
          onChange={(e) => formData.descriptionAttributes.handleChange(e.currentTarget.value)}
          inputProps={{ maxLength: 225 }}
        />

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <QboTextField
              id="organization-owner-id"
              required={isAstericRequired}
              placeholder={t('change_backup_admin.labels.organization_owner_id')}
              label={t('change_backup_admin.labels.organization_owner_id')}
              fullWidth
              value={formData.organizationOwnerIdAttributes.value}
              errorMessage={formData.organizationOwnerIdAttributes.errorMessage}
              onChange={(e) =>
                formData.organizationOwnerIdAttributes.handleChange(e.currentTarget.value)
              }
              inputProps={{ maxLength: 225 }}
              type="number"
            />
          </Grid>
          <Grid item xs={6}>
            <QboTextField
              id="credential-id"
              required={isAstericRequired}
              placeholder={t('change_backup_admin.labels.credential_id')}
              label={t('change_backup_admin.labels.credential_id')}
              fullWidth
              value={formData.credentialIdAttributes.value}
              errorMessage={formData.credentialIdAttributes.errorMessage}
              onChange={(e) => formData.credentialIdAttributes.handleChange(e.currentTarget.value)}
              inputProps={{ maxLength: 225 }}
              type="number"
            />
          </Grid>
        </Grid>

        <QboTextField
          id="current-email-address"
          required={isAstericRequired}
          placeholder={t('change_backup_admin.labels.current_email_address')}
          label={t('change_backup_admin.labels.current_email_address')}
          fullWidth
          value={formData.currentEmailAddressAttributes.value}
          errorMessage={formData.currentEmailAddressAttributes.errorMessage}
          onChange={(e) =>
            formData.currentEmailAddressAttributes.handleChange(e.currentTarget.value)
          }
          inputProps={{ maxLength: 225 }}
          type="text"
        />
        <QboTextField
          id="new-email-address"
          required={isAstericRequired}
          placeholder={t('change_backup_admin.labels.new_email_address')}
          label={t('change_backup_admin.labels.new_email_address')}
          fullWidth
          value={formData.newEmailAddressAttributes.value}
          errorMessage={formData.newEmailAddressAttributes.errorMessage}
          onChange={(e) => formData.newEmailAddressAttributes.handleChange(e.currentTarget.value)}
          inputProps={{ maxLength: 225 }}
          type="text"
          helperText="The new email address should exist on M365 side with the Global Administrator Role."
        />
      </QboFormGroup>
    </ITBox>
  );
}

RequestFormSection.propTypes = {
  formData: PropTypes.object,
  onSubmit: PropTypes.func,
  detailState: PropTypes.object,
};

RequestFormSection.defaultProps = {
  formData: {
    supportTicketAttributes: {},
    escalationTicketAttributes: {},
    organizationOwnerIdAttributes: {},
    credentialIdAttributes: {},
    descriptionAttributes: {},
    newEmailAddressAttributes: {},
    currentEmailAddressAttributes: {},
  },
  onSubmit: () => {},
  detailState: {},
};
