import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ITBox, QboTypography, QboCardListItem, QboLink } from '@ui/Components';
import WorkHistoryOutlinedIcon from '@mui/icons-material/WorkHistoryOutlined';
import CopyIcon from '@mui/icons-material/ContentCopyOutlined';
import { useDateTime } from '@hooks';
import { copyToClipboard } from '@utilities';

export default function StatusCardItemBlock({ data }) {
  const { t } = useTranslation();
  const { dateToString, dateFormat } = useDateTime();
  const [hover, setHover] = useState(false);
  const formattedPayload = JSON.stringify(JSON.parse(data?.payload), null, 4);

  return (
    <QboCardListItem
      key={data.id}
      className={`StatusCardItemBlock__item-list ${
        hover ? 'StatusCardItemBlock__item-list-hover' : ''
      }`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      leftContent={<WorkHistoryOutlinedIcon alt="IntuitQboLogo" className="Img__fluid" />}
      rightContent={
        <ITBox direction="column" className="StatusCardItemBlock__item-right-content">
          <QboTypography variant="subtitle2">{data?.status}</QboTypography>
          <QboTypography variant="caption">
            {dateToString(dateFormat.DEFAULT_DATE_TIME, data['executed-on'])}
          </QboTypography>
        </ITBox>
      }>
      <ITBox direction="column">
        <QboTypography variant="subtitle2">
          {t('system_status_section.id_label')}
          {data?.id}
        </QboTypography>
        <QboTypography variant="caption">
          {t('system_status_section.description_label')}
          {data['status-description'] ? data['status-description'] : '-'}
        </QboTypography>
        <QboLink
          onClick={() => copyToClipboard(formattedPayload)}
          className="StatusCardItemBlock__show-detail"
          withTitle
          title={<pre>{formattedPayload}</pre>}>
          View Detail
          <CopyIcon />
        </QboLink>
      </ITBox>
    </QboCardListItem>
  );
}

StatusCardItemBlock.propTypes = {
  data: PropTypes.object,
};

StatusCardItemBlock.defaultProps = {
  data: {},
};
