import React, { useEffect, useState } from 'react';
import {
  ITBox,
  QboAdvanceTable,
  QboAlert,
  QboBreadcrumbs,
  QboCard,
  QboCardCaption,
  QboTypography,
} from '@ui/Components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { IconButton } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

import BaseSetting, { constant } from '@config/BaseSetting';
import NoStatus from '@images/generals/NoStatus.svg';
import useLocalStorage from '@hooks/useLocalStorage';
import { bulkPurgePath } from '@config/Routes/WebClientRoutes';
import { resetMailboxBulkPurgeEachListStatusData } from '@features/mailboxBulkPurge/getMailboxBulkPurgeListSlice';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import useBulkPurgeEachList from './pageHooks/useBulkPurgeEachList';

export default function BulkPurgeListPage() {
  const { t } = useTranslation();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { fetchBulkPurgeEachListAsync, fetchBulkPurgeStatusItemAsync } = useBulkPurgeEachList();
  const [regionValue] = useLocalStorage(constant.REGIONS_LIST);
  const [paginations, setPaginations] = useState({ pageIndex: 0, pageSize: 10 });
  const row = state?.row;

  const { mailboxBulkPurgeEachListState } = useSelector(
    (stateList) => stateList.bulkPurgeListState
  );
  const {
    fetching,
    bulkPurgeListData,
    pagination,
    forbiddenState,
    forbiddenErrorMessage,
    bulkPurgeCorsErrorMessage: corsError,
  } = mailboxBulkPurgeEachListState;

  const { mailboxBulkPurgeStatus } = useSelector((stateList) => stateList.bulkPurgeStatus);
  const { bulkPurgeStatusData, bulkPurgeCorsErrorMessage } = mailboxBulkPurgeStatus;

  const fetchBulkPurgeStatusAsync = () => {
    if (!mailboxBulkPurgeStatus?.fetching) {
      fetchBulkPurgeStatusItemAsync(state?.row?.id);
    }
  };

  const refreshHandlerForBulkPurgeStatus = () => {
    fetchBulkPurgeStatusAsync();
  };

  useEffect(() => {
    fetchBulkPurgeStatusAsync();
  }, [row]);

  const fetchListOfBulkPurgeListHandler = () => {
    if (!fetching) {
      dispatch(resetMailboxBulkPurgeEachListStatusData());
      fetchBulkPurgeEachListAsync(paginations.pageSize, paginations.pageIndex + 1, state?.row?.id);
    }
  };

  const refreshHandler = () => {
    fetchListOfBulkPurgeListHandler();
  };

  useEffect(() => {
    fetchListOfBulkPurgeListHandler();
  }, [paginations.pageIndex, paginations.pageSize, row]);

  return (
    <>
      <span style={{ padding: '2.2rem 1rem 1.8rem 1rem' }}>
        {' '}
        <QboBreadcrumbs>
          <Link
            to={bulkPurgePath}
            style={{ color: '#1c63d5', fontSize: '1.5rem', textDecoration: 'underline' }}>
            {t('bulk_purge.header.text')}
          </Link>
          <QboTypography color="text.primary" style={{ fontSize: '1.5rem' }}>
            {t('bulk_purge_list_page.bulk_purge_list')}
          </QboTypography>
        </QboBreadcrumbs>
      </span>
      {(corsError || bulkPurgeCorsErrorMessage) && (
        <QboAlert type="error" style={{ fontSize: '1.15em' }}>
          {corsError || bulkPurgeCorsErrorMessage}
        </QboAlert>
      )}
      <QboCard>
        <ITBox className="SystemStatusSection__wrapper" direction="row">
          <QboTypography
            className="HeaderPageBlock__heading"
            variant="h4"
            sx={{ display: 'flex', alignItems: 'center', paddingLeft: 2 }}>
            {t('bulk_purge_list_page.header.status_text')}
          </QboTypography>
          <ITBox className="SystemStatusSection__create-button">
            <IconButton
              sx={{ position: 'relative', right: '0.7rem' }}
              onClick={() => refreshHandlerForBulkPurgeStatus()}>
              <RefreshIcon sx={{ width: '2rem', height: '2rem' }} />
            </IconButton>
          </ITBox>
        </ITBox>

        {bulkPurgeStatusData.length > 0 && (
          <QboAdvanceTable
            enableTopToolBar={false}
            enableBottomToolbar={false}
            rows={bulkPurgeStatusData}
            isLoading={mailboxBulkPurgeStatus?.fetching}
            enableSorting={Boolean(true)}
            rowsCount={5}
            headers={BaseSetting.mailboxBulkPurgeStatusHeaders}
          />
        )}
        {bulkPurgeStatusData.length === 0 && (
          <QboCardCaption
            imageSx={{ height: 120 }}
            sx={{ width: '100%', height: '220px' }}
            imageUrl={NoStatus}
            heading={t('system_status_section.no_status_section.no_record_text')}
          />
        )}
      </QboCard>
      &nbsp;
      <QboCard>
        <ITBox className="SystemStatusSection__wrapper" direction="row">
          <QboTypography
            className="HeaderPageBlock__heading"
            variant="h4"
            sx={{ display: 'flex', alignItems: 'center', paddingLeft: 2 }}>
            {t('bulk_purge_list_page.header.text')}&nbsp;
            {state?.row?.id}
          </QboTypography>
          <ITBox className="SystemStatusSection__create-button">
            <IconButton
              sx={{ position: 'relative', right: '0.7rem' }}
              onClick={() => refreshHandler()}>
              <RefreshIcon sx={{ width: '2rem', height: '2rem' }} />
            </IconButton>
          </ITBox>
        </ITBox>

        {(bulkPurgeListData.length > 0 || fetching) && (
          <QboAdvanceTable
            rows={bulkPurgeListData}
            isLoading={fetching}
            enableSorting={Boolean(true)}
            pagination={paginations}
            setPagination={setPaginations}
            rowsCount={pagination?.TotalCount || 0}
            headers={BaseSetting.mailboxBulkPurgeListTableHeaders}
          />
        )}
        {((!fetching && bulkPurgeListData.length === 0) || !regionValue || forbiddenState) && (
          <NoStatusSection forbiddenErrorMessage={forbiddenErrorMessage} />
        )}
      </QboCard>
    </>
  );
}
