import { useState } from 'react';
import SchemaBuilder from '@libs/SchemaBuilder';
import { pascalToTitleCase } from '@utilities/stringUtils';

const supportTicketName = 'supportTicket';
const escalationTicketName = 'escalationTicket';
const descriptionName = 'description';
const sourceSubresellerIdName = 'sourceSubresellerId';
const targetResellerIdName = 'targetResellerId';

const InitialForm = {
  [supportTicketName]: '',
  [escalationTicketName]: '',
  [descriptionName]: '',
  [sourceSubresellerIdName]: '',
  [targetResellerIdName]: '',
};

export default function useTransferSubresellerFormValidation() {
  // initialize
  const schemaBuilder = new SchemaBuilder({}, 'yup').create();

  const [isValid, setIsValid] = useState(true);
  const [errors, setErrors] = useState('');
  const [formData, setFormData] = useState(InitialForm);

  //
  // define schemas
  //
  const formSchema = schemaBuilder.object().shape({
    [supportTicketName]: schemaBuilder.string().required(),
    [escalationTicketName]: schemaBuilder.string(),
    [descriptionName]: schemaBuilder.string(),
    [sourceSubresellerIdName]: schemaBuilder.number().required(),
    [targetResellerIdName]: schemaBuilder.number().required(),
  });

  //
  // validate input
  //
  const validate = async (value) => {
    // eslint-disable-next-line func-names
    await formSchema.validate(value, { abortEarly: false }).catch(function (err) {
      const errorLogs = err.inner.reduce((acc, error) => {
        return {
          ...acc,
          [error.path]: pascalToTitleCase(error.message.split(',')[0]),
        };
      }, {});
      setErrors(errorLogs);
      return errorLogs;
    });
    const validation = await formSchema.isValid(value);
    if (validation) {
      setErrors({});
    }
    setIsValid(validation);
    return validation;
  };

  const validateAll = () => {
    return validate(formData);
  };

  // Update form data
  const updateFormData = (newData) => {
    setFormData((prev) => {
      return { ...prev, ...newData };
    });
    // validate(newData);
  };

  //
  // construct the attributes
  //
  const supportTicketAttributes = {
    handleChange: async (value) => {
      updateFormData({ [supportTicketName]: value });
    },
    errorMessage: errors[supportTicketName],
    value: formData[supportTicketName],
  };
  const escalationTicketAttributes = {
    handleChange: async (value) => {
      updateFormData({ [escalationTicketName]: value });
    },
    errorMessage: errors[escalationTicketName],
    value: formData[escalationTicketName],
  };
  const descriptionAttributes = {
    handleChange: async (value) => {
      updateFormData({ [descriptionName]: value });
    },
    errorMessage: errors[descriptionName],
    value: formData[descriptionName],
  };
  const sourceSubresellerIdAttributes = {
    handleChange: async (value) => {
      updateFormData({ [sourceSubresellerIdName]: value });
    },
    errorMessage: errors[sourceSubresellerIdName],
    value: formData[sourceSubresellerIdName],
  };
  const targetResellerIdAttributes = {
    handleChange: async (value) => {
      updateFormData({ [targetResellerIdName]: value });
    },
    errorMessage: errors[targetResellerIdName],
    value: formData[targetResellerIdName],
  };

  const resetForm = () => {
    setErrors({});
    setFormData(InitialForm);
  };

  return {
    formData,
    supportTicketAttributes,
    escalationTicketAttributes,
    descriptionAttributes,
    sourceSubresellerIdAttributes,
    targetResellerIdAttributes,
    validateAll,
    isValid,
    resetForm,
  };
}
