import { useDispatch } from 'react-redux';
import useLocalStorage from '@hooks/useLocalStorage';
import { constant } from '@config/BaseSetting';
import { getRecreateBackupAdminsAsync } from '@features/recreateBackupAdmins/serviceActions/fetchRecreateBackupAdminsAsync';
import { recreateBackupAdminsAsync } from '@features/recreateBackupAdmins/recreateBackupAdminsSlice';
import { m365TenantDetailAsync } from '@features/m365TenantDetail/m365TenantDetailSlice';

export default function useRecreateBackupAdmins() {
  const dispatch = useDispatch();
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);

  const handleFetchRecreateBackupAdminsList = (pageIndex, pageSize) => {
    dispatch(
      getRecreateBackupAdminsAsync({
        accessToken: accessTokenSet,
        reset: true,
        pageNumber: pageIndex,
        pageSize,
      })
    );
  };

  const handleGetTenantDetail = (credentialId, organizationOwnerId) => {
    dispatch(
      m365TenantDetailAsync({
        accessToken: accessTokenSet,
        parameters: { credentialId, organizationOwnerId },
      })
    );
  };

  const getFilterdDataFromResponse = (data, setRecreateBackupAdminsListState) => {
    const modifiedData = data?.map((item) => {
      const payloadObj = JSON.parse(item.payload);
      const modifiedPayloadObj = {
        id: item?.id,
        'status-description': item['status-description'],
        description: payloadObj.description,
        domain: payloadObj.domain,
        'org-owner-id': payloadObj['org-owner-id'],
        'support-ticket': payloadObj['support-ticket'],
        'escalation-ticket': payloadObj['escalation-ticket'],
        'executed-on': item['created-on'],
      };
      return modifiedPayloadObj;
    });
    return setRecreateBackupAdminsListState(modifiedData);
  };

  const handleCreateBackupsRequests = (parameters) => {
    dispatch(
      recreateBackupAdminsAsync({
        accessToken: accessTokenSet,
        reset: true,
        parameters,
      })
    );
  };

  const tableColumns = [
    {
      id: 0,
      label: 'Request ID',
      columnName: 'id',
      align: 'center',
      sx: { paddingLeft: 2 },
      size: 190,
    },
    {
      id: 1,
      label: 'Organization Owner ID',
      columnName: 'org-owner-id',
      align: 'center',
      sx: { paddingLeft: 2 },
      size: 190,
    },
    { id: 2, label: 'Support Ticket', columnName: 'support-ticket', sx: { paddingLeft: 2 } },
    { id: 3, label: 'Escalation Ticket', columnName: 'escalation-ticket', sx: { paddingLeft: 2 } },
    { id: 4, label: 'Description', columnName: 'description', sx: { paddingLeft: 2 } },
    { id: 5, label: 'Credential ID', columnName: 'credential-id', sx: { paddingLeft: 2 } },
    { id: 6, label: 'Status', columnName: 'status-description', sx: { paddingLeft: 2 } },
    { id: 7, label: 'Executed On', columnName: 'executed-on', sx: { paddingLeft: 2 } },
  ];

  return {
    handleFetchRecreateBackupAdminsList,
    getFilterdDataFromResponse,
    handleCreateBackupsRequests,
    tableColumns,
    handleGetTenantDetail,
  };
}
