import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  tenantSizeReportAsync,
  resetTenantSizeReportState,
} from '@features/tenantSizeReport/tenantSizeReportSlice';
import useLocalStorage from '@hooks/useLocalStorage';
import { constant } from '@config/BaseSetting';
import { showFixedAlert } from '@features/application/applicationSlice';
import ClientRoutes from '@config/Routes/WebClientRoutes';
import { EMAIL } from '@pages/UserBackupPage/pageHooks/constants/emailConstants';
import useForm from './useForm';

export default function useTenantSizeReport({
  selectedCredential,
  selectedTenant,
  onSuccessRequest,
}) {
  const dispatch = useDispatch();
  const form = useForm();
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const { tenantSizeReportState } = useSelector((state) => state.tenantSizeReport);
  const [reportType, setReportType] = useState(EMAIL);
  const { t } = useTranslation();

  const handleTenantSizeReportRequestAsync = (parameters) => {
    dispatch(
      tenantSizeReportAsync({
        accessToken: accessTokenSet,
        reset: true,
        parameters,
      })
    );
  };

  const submitRequest = () => {
    if (form.isValid) {
      handleTenantSizeReportRequestAsync([
        {
          'support-ticket': form.attributes.supportTicketAttributes.value,
          description: form.attributes.descriptionAttributes.value,
          'escalation-ticket': form.attributes.escalationTicketAttributes.value,
          'credential-id': selectedCredential.id,
          'org-owner-id': selectedCredential.organizationOwnerId,
          upn: selectedTenant.upn,
          'is-mailbox': selectedTenant.mailbox === 'true',
          'report-type': 'email',
        },
      ]);
    }
  };

  const onValidateData = (e) => {
    form.onValidateData(e);
    submitRequest();
  };

  useEffect(() => {
    submitRequest();
  }, [form.isValid]);

  useEffect(() => {
    if (tenantSizeReportState.status === 200) {
      form.resetForm();
      dispatch(resetTenantSizeReportState());
      if (tenantSizeReportState.serviceType) {
        const link = ClientRoutes.toolsRequestStatusPath({
          'service-type': tenantSizeReportState.serviceType,
        });

        dispatch(
          showFixedAlert({
            descriptionHtml: t('tenant_page.tenant_size_report.success_submitted_with_link', {
              link,
            }),
          })
        );
      } else {
        dispatch(
          showFixedAlert({
            descriptionHtml: t('tenant_page.tenant_size_report.success_submitted'),
          })
        );
      }
      if (onSuccessRequest) onSuccessRequest();
    }
  }, [tenantSizeReportState.status]);

  return {
    form,
    onValidateData,
    tenantSizeReportState,
    reportType,
    setReportType,
  };
}
