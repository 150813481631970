import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import useFormValidation from './useFormValidation';

export default function useForm() {
  const {
    formData,
    organizationOwnerIdAttributes,
    protocolAttributes,
    credentialIdAttributes,
    validateAll,
    isValid,
    resetForm,
  } = useFormValidation();
  const [orgOwnerId, setOrgOwnerId] = useState(0);
  const [credentialId, setCredentialId] = useState(0);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });

  const handleOnSubmitClick = () => {
    if (isValid && !isEmpty(formData?.organizationOwnerId)) {
      setOrgOwnerId(formData?.organizationOwnerId);
      setCredentialId(formData?.credentialId);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    validateAll();
    handleOnSubmitClick();
  };

  useEffect(() => {
    if (!isEmpty(formData?.organizationOwnerId)) {
      setOrgOwnerId(0);
      setPagination({ pageIndex: 0, pageSize: 10 });
    }
  }, [formData]);

  useEffect(() => {
    handleOnSubmitClick();
  }, [isValid]);

  return {
    formData,
    organizationOwnerIdAttributes,
    protocolAttributes,
    credentialIdAttributes,
    validateAll,
    isValid,
    resetForm,
    onSubmit,
    orgOwnerId,
    setOrgOwnerId,
    credentialId,
    setCredentialId,
    pagination,
    setPagination,
  };
}
