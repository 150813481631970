import { createSlice } from '@reduxjs/toolkit';

import {
  switchSPACommandListAsync,
  switchSPACommandListExtraReducers,
} from './serviceActions/fetchSwitchSPATypeAsync';
import {
  switchSPATypeAsync,
  switchSPATypeExtraReducers,
} from './serviceActions/switchSPATypeAsync';

const initialState = {
  switchSPATypeState: {
    fetching: false,
    status: null,
    switchSPATypeList: [],
    errorMessage: null,
    paginations: { pageIndex: 0, pageSize: 10 },
    forbiddenState: false,
    forbiddenErrorMessage: null,
    switchSPATyperCorsErrorMessage: null,
  },
  updateSPATypeState: {
    submitting: false,
    success: false,
    error: null,
    errorMessage: null,
  },
};

const switchSPATypeSlice = createSlice({
  name: 'switchSPAType',
  initialState,
  reducers: {
    resetSwitchSPATypeState(state) {
      state.switchSPATypeState = initialState.switchSPATypeState;
    },
    resetUpdateSPATypeState(state) {
      state.updateSPATypeState = initialState.updateSPATypeState;
    },
  },
  extraReducers: {
    ...switchSPACommandListExtraReducers,
    ...switchSPATypeExtraReducers,
  },
});

export { switchSPACommandListAsync, switchSPATypeAsync };

export const { resetSwitchSPATypeState, resetUpdateSPATypeState } = switchSPATypeSlice.actions;
export default switchSPATypeSlice.reducer;
