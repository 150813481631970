import React from 'react';
import PropTypes from 'prop-types';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ErrorIcon from '@mui/icons-material/Error';

export default function QboCheckbox({
  className,
  checked,
  onChange,
  label,
  errorMessage,
  withoutErrorIcon,
  ...rest
}) {
  return (
    <FormGroup
      className={`QboCheckbox__wrapper ${
        label === '' && 'QboCheckbox__without-label'
      } ${className}`}>
      <FormControlLabel
        control={
          <>
            <Checkbox {...rest} checked={checked} onChange={onChange} />{' '}
            {errorMessage && (
              <span className="QboTextField__error-text">
                {!withoutErrorIcon && <ErrorIcon />}
                {` ${errorMessage}`}
              </span>
            )}
          </>
        }
        label={label}
      />
    </FormGroup>
  );
}

QboCheckbox.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
  errorMessage: PropTypes.string,
  withoutErrorIcon: PropTypes.string,
};

QboCheckbox.defaultProps = {
  className: '',
  onChange: () => {},
  checked: false,
  label: '',
  errorMessage: '',
  withoutErrorIcon: '',
};
