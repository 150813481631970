import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { QboAlert, QboCard, QboModal } from '@ui/Components';
import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import TransferSubscriptionSection from '@ui/Sections/TransferSubscriptionPage/TransferSubscriptionSection';
import TransferSubscriptionFormSection from '@ui/Sections/TransferSubscriptionPage/TransferSubscriptionFormSection';
import ConfirmationBlock from '@ui/Blocks/Shared/ConfirmationBlock';
import SystemStatusSection from '@ui/Sections/SystemStatusPage/SystemStatusSection';
import StepperBlock from '@ui/Blocks/Shared/StepperBlock';
import SettingsIcon from '@mui/icons-material/Settings';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import AlertPageSection from '@ui/Sections/Shared/AlertPageSection';
import TransferSubscriptionFormPage from '@ui/Sections/TransferSubscriptionPage/TransferSubscriptionFormPage';
import useTransferSubscription from './pageHooks/useTransferSubscription';

const steps = ['1', '2', '3'];

const icons = {
  1: <SettingsIcon />,
  2: <VideoLabelIcon />,
  3: <CheckCircleOutlineOutlinedIcon />,
};

export default function TransferSubscriptionPage() {
  const { t } = useTranslation();
  const { systemStatusCorsErrorMessage } = useSelector((state) => state.SystemStatus);
  const { getTransferSubscriptionState } = useSelector(
    (state) => state.getTransferSubscriptionState
  );
  const { transferSubscriptionCorsErrorMessage } = getTransferSubscriptionState;

  const {
    supportTicketAttributes,
    escalationTicketAttributes,
    descriptionAttributes,
    resellerRequestIdAttributes,
    onSubmit,
    onBack,
    activeStep,
    isStepOptional,
    isStepSkipped,
    transferSubscriptionData,
    onTransfer,
    loading,
    showModal,
    onOpenModal,
    onCloseModal,
    onOpenConfirmation,
    collection,
    isLoadingSystemStatus,
  } = useTransferSubscription();

  return (
    <>
      <HeaderPageBlock>{t('transfer_subscription_page.header.text')}</HeaderPageBlock>
      {transferSubscriptionCorsErrorMessage && (
        <QboAlert type="error" style={{ fontSize: '1.15em' }}>
          {transferSubscriptionCorsErrorMessage}
        </QboAlert>
      )}
      {/* <AlertPageSection /> */}
      <QboCard noPadding isMainWrapper>
        <QboModal
          className="TransferSubscriptionPage_default-modal"
          onClose={onCloseModal}
          open={showModal}
          title={t('transfer_subscription_page.modal.confirmation.title_text')}
          showCloseIcon
          preventOutsideClick>
          <AlertPageSection />
          <StepperBlock
            steps={steps}
            icons={icons}
            hideLabel
            activeStepAlt={activeStep}
            isStepOptionalAlt={isStepOptional}
            isStepSkippedAlt={isStepSkipped}>
            <TransferSubscriptionFormSection
              supportTicketAttributes={supportTicketAttributes}
              escalationTicketAttributes={escalationTicketAttributes}
              descriptionAttributes={descriptionAttributes}
              resellerRequestIdAttributes={resellerRequestIdAttributes}
              onSubmit={onSubmit}
            />
            <TransferSubscriptionSection
              transferSubscriptionData={transferSubscriptionData}
              onBack={onBack}
              onTransfer={onOpenConfirmation}
              loading={loading}
              showConfirmationModal={showModal}
            />
            <ConfirmationBlock onBack={onBack} onNext={onTransfer} loading={loading}>
              {t('transfer_subscription_page.modal.confirmation.content_text')}
            </ConfirmationBlock>
          </StepperBlock>
        </QboModal>
        <TransferSubscriptionFormPage onCreateTicket={onOpenModal} />
        {/* <SystemStatusSection
          collection={collection}
          onCreateTicket={onOpenModal}
          loading={isLoadingSystemStatus}
        /> */}
      </QboCard>
    </>
  );
}
