import { useDispatch } from 'react-redux';
import useLocalStorage from '@hooks/useLocalStorage';
import { constant } from '@config/BaseSetting';
import { getDomainBackupsAsync } from '@features/domainBackups/serviceActions/fetchDomainBackupsAsync';
import { createDomainBackupsAsync } from '@features/domainBackups/createDomainBackupsSlice';

export default function useDomainBackups() {
  const dispatch = useDispatch();
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);

  const handleFetchuseDomainBackupsList = (pageIndex, pageSize) => {
    dispatch(
      getDomainBackupsAsync({
        accessToken: accessTokenSet,
        reset: true,
        pageNumber: pageIndex,
        pageSize,
      })
    );
  };

  const getFilterdDataFromResponse = (data, setDomainBackupsListState) => {
    const modifiedData = data?.map((item) => {
      const payloadObj = JSON.parse(item.payload);
      const modifiedPayloadObj = {
        id: item?.id,
        'status-description': item['status-description'],
        description: payloadObj.description,
        domain: payloadObj.domain,
        'org-owner-id': payloadObj['org-owner-id'],
        'support-ticket': payloadObj['support-ticket'],
        'escalation-ticket': payloadObj['escalation-ticket'],
        'executed-on': item['created-on'],
      };
      return modifiedPayloadObj;
    });
    return setDomainBackupsListState(modifiedData);
  };

  const handleCreateBackupsRequests = (parameters) => {
    dispatch(
      createDomainBackupsAsync({
        accessToken: accessTokenSet,
        reset: true,
        parameters,
      })
    );
  };

  return {
    handleFetchuseDomainBackupsList,
    getFilterdDataFromResponse,
    handleCreateBackupsRequests,
  };
}
