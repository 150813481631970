import { createSlice } from '@reduxjs/toolkit';
import {
  getHEXTenantDetailsInfoAsync,
  getHEXTenantDetailsInfoReducers,
} from './serviceActions/getHEXTenantDetailsInfoAsync';

const initialState = {
  getHEXTenantDetailsInfoState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    hexTenantDetailsInformation: [],
    forbiddenState: false,
    forbiddenErrorMessage: null,
    hexTenantDetailsInfoCorsErrorMessage: null,
  },
  hexTenantDetailState: [],
};

const hexTenantDetailsInfoSlice = createSlice({
  name: 'HEXTenantDetailsInfo',
  initialState,
  reducers: {
    resetHEXTenantDetailsInfoState(state) {
      state.getHEXTenantDetailsInfoState = initialState.getHEXTenantDetailsInfoState;
    },
    setHEXTenantDetailState(state, action) {
      state.hexTenantDetailState = action?.payload;
    },
  },
  extraReducers: {
    ...getHEXTenantDetailsInfoReducers,
  },
});

export { getHEXTenantDetailsInfoAsync };
export const { resetHEXTenantDetailsInfoState, setHEXTenantDetailState } =
  hexTenantDetailsInfoSlice.actions;
export default hexTenantDetailsInfoSlice.reducer;
