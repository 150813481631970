import { constant } from '@config/BaseSetting';
import { getHEXTenantDetailsInfoAsync } from '@features/hexTenantDetails/hexTenantDetailsInfoSlice';
import useLocalStorage from '@hooks/useLocalStorage';
import { useDispatch } from 'react-redux';

export default function useHEXTenantDetailInformationSerice() {
  const [accessToken] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);

  const dispatch = useDispatch();

  const handleFetchHEXTenantDetailInformationAsync = (hexTenantCredentialId) => {
    dispatch(
      getHEXTenantDetailsInfoAsync({
        accessToken,
        reset: true,
        hexTenantId: hexTenantCredentialId,
      })
    );
  };

  return { handleFetchHEXTenantDetailInformationAsync };
}
