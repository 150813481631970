import { createAsyncThunk } from '@reduxjs/toolkit';
import { gwsTenantDetailAPI } from '@services/WebApiService';

const gwsTenantDetailAsync = createAsyncThunk(
  'GWSTenantDetail/gwsTenantDetail',
  async (paramsList) => {
    try {
      const data = await gwsTenantDetailAPI(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = gwsTenantDetailAsync;

const gwsTenantDetailExtraReducers = {
  [pending]: (state) => {
    state.gwsTenantDetailState.submitting = true;
  },
  [rejected]: (state) => {
    state.gwsTenantDetailState.submitting = false;
  },
  [fulfilled]: (state, action) => {
    state.gwsTenantDetailState.submitting = false;
    let statusCode = 200;
    const data = action?.payload?.data;

    try {
      statusCode = action?.payload?.data['status-code'];
    } catch (err) {
      console.info(err);
    }

    if (data && statusCode) {
      state.gwsTenantDetailState.statusCode = statusCode;
    }

    if (data && statusCode !== 200) {
      state.gwsTenantDetailState.errorMessage = action.payload.data.message;
    }

    if (action?.payload?.status === 403) {
      state.gwsTenantDetailState.errorMessage = 'You are not authorize to access this resource';
    }

    if (action?.payload?.error?.status === 404) {
      state.gwsTenantDetailState.errorMessage = 'Invalid Endpoint';
    }

    if (!action?.payload && !action?.payload?.error) {
      state.gwsTenantDetailState.errorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.data) {
      state.gwsTenantDetailState.data = action.payload.data.data;
      state.gwsTenantDetailState.success = true;
    }
  },
};

export { gwsTenantDetailAsync, gwsTenantDetailExtraReducers };
