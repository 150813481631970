import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { isEmpty } from 'lodash';
import { IconButton } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

import { ITBox, QboAlert, QboButton, QboCard, QboModal } from '@ui/Components';
import { constant } from '@config/BaseSetting';
import useLocalStorage from '@hooks/useLocalStorage';
import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import QboAdvanceTable from '@ui/Components/QboAdvanceTable';
import { resetSwitchCredentialRequestState } from '@features/switchCredential/getSwitchCredentialRequestSlice';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import SwitchCredentialFormSection from '@ui/Sections/SwitchCredentialPage/SwitchCredentialFormSection';
import { resetCreateSwitchCredentialState } from '@features/switchCredential/createSwitchCredentialSlice';
import { setSwitchCredentialPaginations } from '@features/application/applicationSlice';
import ClientRoutes from '@config/Routes/WebClientRoutes';
import useSwitchCredentials from './pageHooks/useSwitchCredentials';

function SwitchCredentialPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { fetchListAsync, listData, form, headerTable, switchTypeOptions } = useSwitchCredentials();
  const [regionValue] = useLocalStorage(constant.REGIONS_LIST);

  const [list, setList] = useState([]);
  const [paginations, setPaginations] = useState({ pageIndex: 0, pageSize: 10 });
  const [showModal, setShowModal] = useState(false);

  const { paginationState } = useSelector((stateList) => stateList.application);
  const { switchCredentialRequestState } = useSelector((state) => state.getSwitchCredentialRequest);
  const { createSwitchCredentialState } = useSelector((state) => state.createSwitchCredential);
  const {
    fetching,
    data,
    statusCode,
    pagination,
    forbiddenState,
    forbiddenErrorMessage,
    switchCredentialRequestCorsErrorMessage,
    errorMessage,
  } = switchCredentialRequestState;

  useEffect(() => {
    listData(data, setList);
  }, [statusCode, data?.length]);

  const handleListRequest = () => {
    dispatch(resetSwitchCredentialRequestState());
    if (!isEmpty(paginationState?.switchCredentialPaginations)) {
      const paginationData = paginationState?.switchCredentialPaginations;
      fetchListAsync(paginationData.pageSize, paginationData.pageIndex + 1);
      dispatch(setSwitchCredentialPaginations({}));
    } else {
      fetchListAsync(paginations.pageSize, paginations.pageIndex + 1);
    }
  };

  const handelOnRefresh = () => {
    handleListRequest();
  };

  const closeHandler = () => {
    setShowModal(false);
    form.resetForm();
    if (createSwitchCredentialState?.statusCode) {
      handleListRequest();
    }
    dispatch(resetCreateSwitchCredentialState());
  };

  useEffect(() => {
    if (!isEmpty(paginationState?.switchCredentialPaginations)) {
      setPaginations(paginationState?.switchCredentialPaginations);
    }

    if (!fetching && statusCode) {
      handleListRequest();
    }
  }, [paginations.pageSize, paginations.pageIndex]);

  useEffect(() => {
    if (!fetching && data?.length === 0 && !statusCode) {
      handleListRequest();
    }
  }, []);

  return (
    <>
      <HeaderPageBlock>{t('switch_credential.header.text')}</HeaderPageBlock>
      {switchCredentialRequestCorsErrorMessage && (
        <QboAlert type="error" style={{ fontSize: '1.15em' }}>
          {switchCredentialRequestCorsErrorMessage}
        </QboAlert>
      )}
      <QboCard>
        <ITBox className="SystemStatusSection__wrapper" direction="column">
          <ITBox className="SystemStatusSection__create-button">
            <IconButton onClick={handelOnRefresh} sx={{ position: 'relative', right: '0.7rem' }}>
              <RefreshIcon sx={{ width: '2rem', height: '2rem' }} />
            </IconButton>
            &nbsp;
            <QboButton variant="contained" onClick={() => setShowModal(true)}>
              {t('mailbox_change.new_request')}
            </QboButton>
          </ITBox>
        </ITBox>

        <QboCard noPadding isMainWrapper>
          <>
            {(list.length !== 0 || fetching) && (
              <QboAdvanceTable
                rows={list}
                headers={headerTable}
                enableSorting={Boolean(true)}
                pagination={paginations}
                setPagination={setPaginations}
                rowsCount={pagination?.TotalCount || 0}
                isLoading={fetching}
                onClickRow={(row) => {
                  dispatch(setSwitchCredentialPaginations(paginations));
                  navigate(ClientRoutes.switchCredentialsDetailPath({ id: row.id }), {
                    state: { row },
                  });
                }}
                onClickRowSx={{ cursor: 'pointer' }}
              />
            )}
            {((!fetching && list.length === 0) || !regionValue || forbiddenState) && (
              <NoStatusSection forbiddenErrorMessage={forbiddenErrorMessage} />
            )}
          </>
        </QboCard>

        <QboModal
          onClose={() => {
            closeHandler();
            fetchListAsync(paginations.pageSize, paginations.pageIndex + 1);
          }}
          open={showModal}
          title={t('switch_credential.modal.confirmation.title_text')}
          showCloseIcon
          classNameStyle="DataIngestion_modal"
          preventOutsideClick>
          <SwitchCredentialFormSection
            orgOwnerIdAttributes={form.orgOwnerIdAttributes}
            mappingFileAttributes={form.mappingFileAttributes}
            supportTicketAttributes={form.supportTicketAttributes}
            escalationTicketAttributes={form.escalationTicketAttributes}
            emailIdsAttributes={form.emailIdsAttributes}
            descriptionAttributes={form.descriptionAttributes}
            changeRequestTypeAttributes={form.changeRequestTypeAttributes}
            fromCredentialIdAttributes={form.fromCredentialIdAttributes}
            toCredentialIdAttributes={form.toCredentialIdAttributes}
            onSubmit={form.onSubmit}
            resetForm={form.resetForm}
            formData={form.formData}
            isValid={form.isValid}
            validate={form.validate}
            submitting={createSwitchCredentialState.fetching}
            switchTypeOptions={switchTypeOptions}
          />
        </QboModal>
      </QboCard>
    </>
  );
}

export default SwitchCredentialPage;
