import { createAsyncThunk } from '@reduxjs/toolkit';
import { lastBackupInfoAPI } from '@services/WebApiService';

const fecthLastBackupAsync = createAsyncThunk('LastBackup/getLastBackup', async (paramsList) => {
  try {
    const data = await lastBackupInfoAPI(paramsList);
    return data;
  } catch (error) {
    return { error: error.response };
  }
});

const { pending, fulfilled, rejected } = fecthLastBackupAsync;

const getLastBackupExtraReducers = {
  [pending]: (state) => {
    state.lastBackupState.fetching = true;
  },
  [rejected]: (state) => {
    state.lastBackupState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.lastBackupState.fetching = false;

    if (action?.payload?.status === 403) {
      state.lastBackupState.forbiddenState = true;
      state.lastBackupState.forbiddenErrorMessage = {
        heading: 'Forbidden Error',
        caption: 'You are not authorize to access this resource',
      };
    }

    if (!action?.payload) {
      state.lastBackupState.UsersDetailCorsErrorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.data) {
      state.lastBackupState.data = action?.payload?.data;
      state.lastBackupState.success = true;
      state.lastBackupState.statusCode = action?.payload?.status;
    }

    if (action?.payload?.error) {
      state.lastBackupState.error = action.payload.error;
    }
  },
};

export { fecthLastBackupAsync, getLastBackupExtraReducers };
