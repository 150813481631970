import React from 'react';
import { Link } from 'react-router-dom';
import { HomeOutlined as HomeOutlinedIcon } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { QboCard, QboSimpleDataTable } from '@ui/Components';
import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import useCheckboxTable from '@pages/sharedHooks/useCheckboxTable';
import QboBreadcrums from '@ui/Components/QboBreadcrumbs';
import useFetchListing from './pageHooks/useFetchListing';

export default function FormPage() {
  const { t } = useTranslation();
  const { isLoading, tableRows, tableHeaders, listIdItem } = useFetchListing();
  const { selected, setSelected, listSortOptions, selectedDropdown, onDropdownSelect } =
    useCheckboxTable({ listIdItem });

  return (
    <>
      <HeaderPageBlock>{t('listing_page.header.text')}</HeaderPageBlock>
      <QboBreadcrums className="map-link">
        <Link
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
          style={{ color: 'black', fontSize: 14, display: 'flex', alignItems: 'center' }}
          to="/dashboard">
          <HomeOutlinedIcon sx={{ mr: 0.5 }} fontSize="medium" />
          {t('breadcrums.text')}
        </Link>
        <Typography style={{ fontSize: 14, color: 'black', fontFamily: 'inter' }}>
          {t('breadcrums.mailbox_change.view_history')}
        </Typography>
      </QboBreadcrums>

      <QboCard noPadding isMainWrapper>
        <QboSimpleDataTable
          rows={tableRows}
          header={tableHeaders}
          loading={isLoading}
          onClickRow={(row) => {}}
          selected={selected}
          setSelected={setSelected}
          listSortOptions={listSortOptions}
          selectedDropdown={selectedDropdown}
          onDropdownSelect={onDropdownSelect}
          withCheckboxSelection
        />
      </QboCard>
    </>
  );
}
