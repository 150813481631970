import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import {
  QboAdvanceTable,
  QboCard,
  QboTextField,
  QboFormGroup,
  QboTypography,
} from '@ui/Components';
import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import QboSortByButton from '@ui/Components/QboSortByButton';
import BaseSetting from '@config/BaseSetting';
import { setSubResellerTabState } from '@features/application/applicationSlice';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import useSubResellerService from './pageHooks/useSubResellerService';
import useDataService from './pageHooks/useDataService';

export default function SubResellerPage(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { resellerID, tabFlag, selectTab, resellerIDProp } = props;
  const {
    buttonStyle,
    names,
    statusMenuItems,
    salesOwnersList,
    integrationTypes,
    columnNames,
    clickableCoulmnList,
  } = useDataService();
  const { getSubResellerListState } = useSelector((state) => state.subResellerListState);
  const { selectedTabState } = useSelector((state) => state.application);
  const {
    fetching,
    subResellerListData,
    pagination: paginationList,
    statusCode,
  } = getSubResellerListState;
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const previousPropsRef = useRef(pagination);

  const filterStates = {
    forceUpdate: false,
    searchState: '',
    textFieldValue: '',
    searchFieldValueText: '',
    searchFieldValueID: '',
    advanceClick: false,
    selected: [],
    filterdState: names,
    selectStatus: '',
    selectSalesOwner: '',
    integrationTypeState: '',
    selectedColumn: '',
    matchText: '',
    resetClicked: false,
  };

  const [filterDataState, setFilterDataState] = useState(filterStates);

  const isAllSelected = names.length > 0 && filterDataState.selected.length === names.length;

  const {
    handleGetSubResellerListAsync,
    handleStateUpdate,
    handleOnTextFiledChange,
    handleOnResetButtonClick,
    handleCheckBoxChange,
    matchTextHandler,
    handleOnHideAdvanceClick,
  } = useSubResellerService(filterDataState, setFilterDataState, filterStates, pagination);

  const handleOnSearchClick = (e) => {
    e.preventDefault();
    matchTextHandler();
    handleGetSubResellerListAsync(resellerID);
  };

  useEffect(() => {
    if ((selectTab === 0 || selectTab) && selectedTabState?.subResellerTabState !== selectTab) {
      dispatch(setSubResellerTabState(selectTab));
    }
  }, [selectTab]);

  useEffect(() => {
    if (!filterDataState.searchState) {
      setFilterDataState((prevState) => ({
        ...prevState,
        forceUpdate: false,
      }));
    }
  }, [filterDataState.searchState]);

  useEffect(() => {
    if (!fetching && resellerID && resellerID !== resellerIDProp.current) {
      resellerIDProp.current = resellerID;
      handleGetSubResellerListAsync(resellerID);
    }
  }, [resellerID]);

  useEffect(() => {
    const previousProps = previousPropsRef.current;

    if (
      statusCode &&
      (selectTab === null ||
        selectedTabState?.subResellerTabState !== selectTab ||
        !isEqual(previousProps, pagination))
    ) {
      previousPropsRef.current = pagination;
      if (!fetching) {
        handleGetSubResellerListAsync(resellerID);
      }
    }
  }, [pagination]);

  return (
    <>
      {!resellerID && <HeaderPageBlock>Sub-Reseller List</HeaderPageBlock>}
      <QboCard isMainWrapper>
        <QboFormGroup
          onSubmit={(e) => handleOnSearchClick(e)}
          buttonPostion="start"
          buttonStyle={buttonStyle}
          handleOnResetButton={() => {
            handleOnResetButtonClick(resellerID);
          }}
          firstButtonLabel={t('partner_details_page.button.search')}
          withGenerateAndResetButton
          buttonLabel="Validate Transfer">
          <QboTextField
            id="fullTextSearch"
            value={filterDataState.textFieldValue}
            isWidthSetExplicit
            placeholder="Name/Owner Email"
            inputProps={{ maxLength: 225 }}
            onChange={(e) => {
              handleOnTextFiledChange(e);
            }}
          />
          <div style={{ marginTop: 8 }}>
            <Button
              disableRipple
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
                textTransform: 'none',
              }}
              onClick={() => {
                handleStateUpdate('advanceClick', !filterDataState.advanceClick);
                handleOnHideAdvanceClick();
                // if (!filterDataState.advanceClick) {
                //   setFilterDataState(filterStates);
                // }
              }}>
              {filterDataState.advanceClick
                ? t('partner_details_page.button.hide_advance_search')
                : t('partner_details_page.button.show_advance_search')}
            </Button>
          </div>
          {filterDataState.advanceClick && (
            <div>
              {/* <QboSortByButton
                forceUpdate={filterDataState.forceUpdate}
                searchState={filterDataState.searchState}
                isAllSelected={isAllSelected}
                handleCloseClick={() => {
                  handleStateUpdate('forceUpdate', true);
                  handleStateUpdate('searchState', '');
                }}
                isSearchEnable={!false}
                handleOnSearchChange={(e) => {
                  handleStateUpdate('searchState', e.target.value);
                }}
                isCheckBoxColumnFilter
                marginRightValue={2}
                marginTopValue={2}
                selected={filterDataState.selected}
                buttonWidth={220}
                startAdornmentTextWidth={180}
                startAdornmentText={
                  filterDataState.selected.length > 0 ? 'Partner Type:' : 'Partner Type:  All'
                }
                handleCheckBoxChange={(event) => handleCheckBoxChange(event, names)}
                menuListCheckboxItems={filterDataState.filterdState}
              /> */}
              <QboSortByButton
                defaultValue="All"
                marginTopValue={2}
                marginRightValue={2}
                value={filterDataState.selectStatus}
                buttonWidth={220}
                startAdornmentTextWidth={70}
                startAdornmentText="Status:"
                handleChange={(e) => handleStateUpdate('selectStatus', e.target.value)}
                menuItemList={statusMenuItems}
              />
              {/* <QboSortByButton
            defaultValue="All"
            marginRightValue={2}
            marginTopValue={2}
            value={age}
            buttonWidth={220}
            startAdornmentTextWidth={70}
            startAdornmentText="Parent:"
            handleChange={handleChange}
            menuItemList={menuItemList}
          /> */}
              <QboSortByButton
                defaultValue="All"
                marginRightValue={2}
                marginTopValue={2}
                value={filterDataState.selectSalesOwner}
                buttonWidth={220}
                startAdornmentTextWidth={140}
                startAdornmentText="Sales Owner:"
                handleChange={(e) => handleStateUpdate('selectSalesOwner', e.target.value)}
                menuItemList={salesOwnersList}
              />
              <QboSortByButton
                defaultValue="All"
                marginRightValue={2}
                marginTopValue={2}
                value={filterDataState.integrationTypeState}
                buttonWidth={220}
                startAdornmentTextWidth={200}
                startAdornmentText="Integration Type:"
                handleChange={(e) => handleStateUpdate('integrationTypeState', e.target.value)}
                menuItemList={integrationTypes}
              />
              <div>
                <QboSortByButton
                  defaultValue="None"
                  marginTopValue={2}
                  marginRightValue={2}
                  value={filterDataState.selectedColumn}
                  buttonWidth={220}
                  startAdornmentTextWidth={70}
                  startAdornmentText="Sort by:"
                  handleChange={(e) => handleStateUpdate('selectedColumn', e.target.value)}
                  menuItemList={columnNames}
                />
              </div>
            </div>
          )}
        </QboFormGroup>
      </QboCard>
      <hr />
      {resellerID ? null : (
        <QboTypography
          sx={{
            marginLeft: 2,
            marginBottom: 3,
            fontSize: 18,
            color: '#000000DE',
            fontWeight: 600,
          }}>
          Partners List
        </QboTypography>
      )}
      {!filterDataState.resetClicked && filterDataState.matchText ? (
        <QboTypography
          sx={{
            marginLeft: 2,
            marginTop: 2,
            marginBottom: 3,
            fontSize: '15px !important',
            color: '#42526e',
            fontWeight: 600,
            overflow: 'hidden',
            whiteSpace: 'pre-wrap',
          }}>
          {!filterDataState.resetClicked && filterDataState.matchText}
        </QboTypography>
      ) : null}
      <QboAdvanceTable
        isLoading={fetching}
        enableTopToolBar={false}
        enableSorting
        rows={subResellerListData}
        headers={BaseSetting.subResellerHeaders}
        rowsCount={Number(paginationList?.TotalCount)}
        pagination={pagination}
        setPagination={setPagination}
        columnsClickableList={clickableCoulmnList}
        enableColumnClickable={!false}
      />
      {/* <NoStatusSection /> */}
      {/* </QboCard> */}
    </>
  );
}

SubResellerPage.propTypes = {
  resellerID: PropTypes.number,
  tabFlag: PropTypes.bool,
  selectTab: PropTypes.number,
  resellerIDProp: PropTypes.object,
};

SubResellerPage.defaultProps = {
  resellerID: null,
  tabFlag: false,
  selectTab: null,
  resellerIDProp: {},
};
