import { createAsyncThunk } from '@reduxjs/toolkit';
import { getChangeBackupAdminsAPI } from '@services/WebApiService';

const getChangeBackupAdminsAsync = createAsyncThunk(
  'ChangeBackupAdmins/getChangeBackupAdmins',
  async (paramsList) => {
    try {
      const data = await getChangeBackupAdminsAPI(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = getChangeBackupAdminsAsync;

const getChangeBackupAdminsExtraReducers = {
  [pending]: (state) => {
    state.getChangeBackupAdminsState.fetching = true;
  },
  [rejected]: (state) => {
    state.getChangeBackupAdminsState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.getChangeBackupAdminsState.fetching = false;

    if (action?.payload?.error) {
      state.getChangeBackupAdminsState.errorMessage = action.payload.error?.data.message;
      state.getChangeBackupAdminsState.status = action.payload.error?.status;

      if (action?.payload?.error?.status === 403) {
        state.getChangeBackupAdminsState.forbiddenState = true;
        state.getChangeBackupAdminsState.forbiddenErrorMessage = {
          heading: 'Forbidden Error',
          caption: 'You are not authorize to access this resource',
        };
      }
    }

    if (!action?.payload?.error && !action?.payload?.data) {
      state.getChangeBackupAdminsState.changeBackupAdminsCorsErrorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.data?.data) {
      state.getChangeBackupAdminsState.forbiddenState = false;
      state.getChangeBackupAdminsState.forbiddenErrorMessage = {};
      state.getChangeBackupAdminsState.status = action.payload?.status;
      let list = [];

      if (action?.payload?.data?.data && action?.payload?.data?.data.length > 0) {
        list = action?.payload?.data?.data.map((item) => {
          const detailPayload = JSON.parse(item.payload);
          let statusDescription = '';

          try {
            statusDescription = item['status-description'].substring(
              item['status-description'],
              100
            );
          } catch (err) {
            statusDescription = item['status-description'];
          }

          return {
            id: item.id,
            'org-owner-id': detailPayload['org-owner-id'],
            'support-ticket': detailPayload['support-ticket'],
            'escalation-ticket': detailPayload['escalation-ticket'],
            description: detailPayload.description,
            'credential-id': detailPayload['credential-id'],
            'status-description': statusDescription,
            'executed-on': item['executed-on'],
            'current-email-address': detailPayload['current-email-address'],
            'new-email-address': detailPayload['new-email-address'],
            status: item.status,
            errorMessage: item['status-description'],
          };
        });
      }

      state.getChangeBackupAdminsState.list = list;

      if (action?.payload?.data?.pagination) {
        state.getChangeBackupAdminsState.paginations = JSON.parse(
          action?.payload?.data?.pagination
        );
      }
    }
  },
};

export { getChangeBackupAdminsAsync, getChangeBackupAdminsExtraReducers };
