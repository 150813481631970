import { createSlice } from '@reduxjs/toolkit';

import {
  getRefreshTokenAsync,
  getRefreshTokenReducers,
} from './serviceActions/getRefreshTokenAsync';

const initialState = {
  getRefToken: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    refreshErrorMessage: null,
  },
};

const refTokenSlice = createSlice({
  name: 'RefreshToken',
  initialState,
  reducers: {
    resetGetRefTokenStatus(state) {
      state.getRefToken = initialState.getRefToken;
    },
    resetRefreshStatusCode(state) {
      state.getRefToken.statusCode = null;
    },
  },
  extraReducers: {
    ...getRefreshTokenReducers,
  },
});

export { getRefreshTokenAsync };
export const { resetGetRefTokenStatus, resetRefreshStatusCode } = refTokenSlice.actions;
export default refTokenSlice.reducer;
