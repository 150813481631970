import React from 'react';
import PropTypes from 'prop-types';
import { QboTypography } from '@ui/Components';

export default function QboLabelGroup({ title, description, color }) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: 7,
        borderBottom: '1px solid #d7dbe3',
        paddingBottom: '10px',
      }}>
      <QboTypography sx={{ fontSize: 13, fontWeight: 700 }}>{title}</QboTypography>
      <QboTypography wrap sx={{ fontSize: 13, color: { color } }}>
        &nbsp;{description}
      </QboTypography>
    </div>
  );
}

QboLabelGroup.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  color: PropTypes.string,
};

QboLabelGroup.defaultProps = {
  title: '',
  description: '',
  color: '',
};
