import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteDataIngestionJobAPI } from '@services/WebApiService';

const removeDataIngestionJobAsync = createAsyncThunk('Ingestion/DeleteJobs', async (paramsList) => {
  try {
    const data = await deleteDataIngestionJobAPI(paramsList);
    return data;
  } catch (error) {
    return error.response;
  }
});

const { pending, fulfilled, rejected } = removeDataIngestionJobAsync;

const getRemoveDataIngestionJobsExtraReducers = {
  [pending]: (state) => {
    state.getRemoveDataIngestionJobsState.fetching = true;
  },
  [rejected]: (state) => {
    state.getRemoveDataIngestionJobsState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.getRemoveDataIngestionJobsState.fetching = false;

    if (action?.payload?.status) {
      state.getRemoveDataIngestionJobsState.statusCode = action?.payload?.status;
      state.getRemoveDataIngestionJobsState.success = true;
    }

    if (action?.payload?.data) {
      state.getRemoveDataIngestionJobsState.removeDataIngestionJobsResult =
        action?.payload?.data?.data;
      state.getRemoveDataIngestionJobsState.success = true;
    }

    if (action?.payload?.error) {
      state.getRemoveDataIngestionJobsState.error = action.payload.error;
    }
  },
};

export { removeDataIngestionJobAsync, getRemoveDataIngestionJobsExtraReducers };
