import { useDispatch, useSelector } from 'react-redux';
import useLocalStorage from '@hooks/useLocalStorage';
import { constant } from '@config/BaseSetting';
import { useTranslation } from 'react-i18next';
import {
  resetToggleEnforceSSOState,
  toggleEnforceSSOAsync,
} from '@features/updateEnforceSSO/toggleEnforceSSOSlice';
import { useEffect } from 'react';
import { showFixedAlert } from '@features/application/applicationSlice';
import ClientRoutes from '@config/Routes/WebClientRoutes';
import useForm from './useForm';

export default function useToggleEnforceSSO({ selectedAccount, onSuccessRequest }) {
  const dispatch = useDispatch();
  const form = useForm();
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const { toggleEnforceSSOState } = useSelector((state) => state.toggleEnforceSSO);
  const { t } = useTranslation();

  const handleToggleEnforceSSORequestAsync = (parameters) => {
    dispatch(
      toggleEnforceSSOAsync({
        accessTokenSet,
        reset: true,
        parameters,
      })
    );
  };

  const submitRequest = () => {
    if (form.isValid) {
      handleToggleEnforceSSORequestAsync([
        {
          'support-ticket': form.attributes.supportTicketAttributes.value,
          description: form.attributes.descriptionAttributes.value,
          'escalation-ticket': form.attributes.escalationTicketAttributes.value,
          'force-reset-oauth': form.attributes.forceResetOAuthAttributes.value,
          'account-id': selectedAccount.id,
          'change-request-type': selectedAccount.type,
          'org-owner-id': selectedAccount.organizationOwnerId,
          'partner-id': selectedAccount.partnerId,
        },
      ]);
    }
  };

  const onValidateData = (e) => {
    form.onValidateData(e);
    submitRequest();
  };

  useEffect(() => {
    submitRequest();
  }, [form.isValid]);

  useEffect(() => {
    if (toggleEnforceSSOState.status === 200) {
      if (!toggleEnforceSSOState.jobIds || toggleEnforceSSOAsync.jobIds?.length > 1) {
        if (toggleEnforceSSOState.serviceType) {
          const link = ClientRoutes.toolsRequestStatusPath({
            'service-type': toggleEnforceSSOState.serviceType,
          });
          dispatch(
            showFixedAlert({
              descriptionHtml: t('user_page.toggle_enforce_sso.success_submitted_with_link', {
                link,
              }),
            })
          );
        } else {
          dispatch(
            showFixedAlert({
              descriptionHtml: t('user_page.toggle_enforce_sso.success_submitted'),
            })
          );
        }
      } else {
        const link = ClientRoutes.toolsRequestStatusPath({
          'service-type': 24,
        });
        dispatch(
          showFixedAlert({
            descriptionHtml: t('user_page.toggle_enforce_sso.success_submitted_with_link', {
              link,
            }),
          })
        );
      }
      if (onSuccessRequest) onSuccessRequest();
    }
    form.resetForm();
    dispatch(resetToggleEnforceSSOState());
  }, [toggleEnforceSSOState.status]);

  return {
    form,
    onValidateData,
    toggleEnforceSSOState,
  };
}
