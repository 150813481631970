import { useDispatch } from 'react-redux';
import { submitDataIngestionFormAsync } from '@features/ingestion/serviceActions/submitIngestionFormAsync';

export default function useIngestionSubmit() {
  const dispatch = useDispatch();

  const handleSubmitIngestion = (paramsList) => {
    dispatch(submitDataIngestionFormAsync(paramsList));
  };

  const getFormData = (formData) => {
    const headerData = new FormData();

    headerData.append('accountId', formData.organizationOwnerId);
    headerData.append('escalationTicket', formData.escalationTicket);
    headerData.append('supportTicket', formData.supportTicket);
    headerData.append('description', formData.description);
    headerData.append('ftpUrl', formData.ftpUrl);
    headerData.append('ftpUsername', formData.ftpUsername);
    headerData.append('ftpPassword', formData.ftpPassword);
    headerData.append('s3Key', formData.s3Key);
    headerData.append('s3bucketname', formData.s3bucketname);
    headerData.append('folder', formData.folder);
    headerData.append('zipFilePassword', formData.zipFilePassword);
    headerData.append('storageType', formData.storageType);
    headerData.append('retainFolderStructure', formData.retainFolderStructure);
    headerData.append('mappingFile', formData.mappingFile);
    headerData.append('ingestionType', formData.ingestionType);
    headerData.append('catchAllEmailAccount', formData.catchAllEmailAccount);
    headerData.append('s3StorageRegion', formData.s3StorageRegion);

    return headerData;
  };

  const storageType = {
    FTP: 1101,
    S3TYPE: 1102,
    PROSERV: 1103,
    PROSERV_S3: 1104,
  };

  const checkValueExistance = (formData) => {
    let isValid = false;

    if (!!formData?.organizationOwnerId && !!formData?.storageType && !!formData?.mappingFile) {
      if (formData.storageType === storageType.FTP) {
        isValid = !!formData?.ftpUrl && !!formData?.ftpUsername && !!formData?.ftpPassword;
      } else if (formData.storageType === storageType.S3TYPE) {
        isValid = !!formData?.s3bucketname && !!formData?.s3Key;
      } else if (formData.storageType === storageType.PROSERV) {
        isValid = true;
      } else if (formData.storageType === storageType.PROSERV_S3) {
        isValid = !!formData?.s3bucketname && !!formData?.s3Key;
      }
    }

    return isValid;
  };

  return {
    handleSubmitIngestion,
    getFormData,
    checkValueExistance,
  };
}
