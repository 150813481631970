import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { isEqual } from 'lodash';

import { resetIngestionJobFormSubmit } from '@features/ingestion/ingestionJobSlice';
import useFormValidation from './useFormValidation';
import useDetailsOfIngestionList from './useDetailsOfIngestionList';

export default function useForm() {
  const {
    formData,
    organizationOwnerIdAttributes,
    mappingFileAttributes,
    oneItemStructureAttributes,
    multipleItemStructureAttributes,
    emailAccountStructureAttributes,
    fileNameStructureAttributes,
    validateAll,
    isValid,
    resetForm,
  } = useFormValidation();

  const dispatch = useDispatch();
  const { handleIngestionJobForm, getFormData, checkValuesPresence } = useDetailsOfIngestionList();

  const previousPropsRef = useRef(formData);

  const handleSubmitIngestionJobRequest = () => {
    if (isValid && checkValuesPresence(formData)) {
      const data = getFormData(formData);
      handleIngestionJobForm({ parameters: data, ingestionJobID: formData?.organizationOwnerId });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    validateAll();
    const previousProps = previousPropsRef.current;

    if (!isEqual(previousProps, formData)) {
      dispatch(resetIngestionJobFormSubmit());
      handleSubmitIngestionJobRequest();
      previousPropsRef.current = formData;
    }
  };

  useEffect(() => {
    handleSubmitIngestionJobRequest();
  }, [isValid]);

  return {
    formData,
    organizationOwnerIdAttributes,
    mappingFileAttributes,
    oneItemStructureAttributes,
    multipleItemStructureAttributes,
    emailAccountStructureAttributes,
    fileNameStructureAttributes,
    validateAll,
    isValid,
    resetForm,
    onSubmit,
  };
}
