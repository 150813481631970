import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSystemStatusAysnc, resetState } from '@features/systemStatus/systemStatusSlice';
import useLocalStorage from '@hooks/useLocalStorage';
import { constant } from '@config/BaseSetting';

export default function useSystemStatus(props = {}) {
  const { payload = {} } = props;
  const dispatch = useDispatch();
  const { systemStatus, fetchSystemStatus } = useSelector((state) => state.SystemStatus);
  const { isApplicationReady } = useSelector((state) => state.application);
  const { value } = systemStatus;
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);

  const handleFetchSystemStatus = () => {
    dispatch(
      fetchSystemStatusAysnc({ accessToken: accessTokenSet, reset: true, parameters: payload })
    );
  };

  // useEffect(() => {
  //   dispatch(
  //     fetchSystemStatusAysnc({ accessToken: accessTokenSet, reset: true, parameters: payload })
  //   );

  //   return () => {
  //     dispatch(resetState());
  //   };
  // }, [isApplicationReady]);

  return {
    systemStatusList: value,
    loading: fetchSystemStatus,
    handleFetchSystemStatus,
  };
}
