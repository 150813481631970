import React, { useEffect, useState } from 'react';
import { Skeleton } from '@mui/material';
import { QboCard } from '@ui/Components';
import QboCardSingleList from '@ui/Components/QboCardSingleList';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import { useSelector } from 'react-redux';
import useM365TenantDetailInformationService from './pagehooks/useM365TenantDetailInformationService';
import useDataService from './pagehooks/useDataService';

export default function M365TenantDetailsPage(props) {
  const { m365TenantCredentialID } = props;
  const [m365TenantDetails, setM365TenantDetails] = useState([]);
  const { getM365TenantDetailsInfoState } = useSelector((state) => state.m365TenantDetailsInfo);
  const { m365TenantDetailsInformation, fetching } = getM365TenantDetailsInfoState;
  const { handleFetchM365TenantDetailInformationAsync, getM365TenantDetailsDataFromResponse } =
    useM365TenantDetailInformationService();
  const { m365TenantInfoDetails } = useDataService();

  useEffect(() => {
    if (m365TenantDetailsInformation.length === 0) {
      handleFetchM365TenantDetailInformationAsync(m365TenantCredentialID);
    }
  }, [m365TenantCredentialID]);

  useEffect(() => {
    getM365TenantDetailsDataFromResponse(m365TenantDetailsInformation, setM365TenantDetails);
  }, [m365TenantDetailsInformation]);

  const valueHandler = (properties) => {
    const responseData = m365TenantDetails[0];
    let value = ' ';

    if (responseData && Object.keys(responseData).length > 0) {
      value = responseData[properties] || '-';

      if (responseData[properties] === 0) {
        value = '0';
      }
    }

    return value;
  };

  const renderLoading = () => {
    return (
      <div style={{ display: 'contents' }}>
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="70%" />
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="70%" />
      </div>
    );
  };

  const componentHandler = () => {
    let content = null;

    if (fetching) {
      content = renderLoading();
    } else if (m365TenantDetails[0] && Object.keys(m365TenantDetails[0]).length > 0) {
      content = (
        <QboCard
          className="QboCard__wrapper_search_text_highlight"
          sx={{
            scrollbarWidth: 'none !important',
            overflowY: 'scroll !important',
            height: '65vh',
          }}>
          <QboCard sx={{ marginTop: 2, marginBottom: 2 }}>
            {m365TenantInfoDetails?.map((notification) => (
              <QboCardSingleList
                key={notification?.label}
                subHeadingText={notification?.subHeadingText}
                leftContent={notification?.label}
                rightContent={valueHandler(notification?.properties)}
              />
            ))}
          </QboCard>
        </QboCard>
      );
    } else if (!fetching && !m365TenantDetails[0]) {
      content = <NoStatusSection />;
    }

    return content;
  };

  return componentHandler();
}
