import { useDispatch, useSelector } from 'react-redux';
import { constant } from '@config/BaseSetting';
import useLocalStorage from '@hooks/useLocalStorage';
import {
  bulkCreateOrganizationAsync,
  resetBulkCreateOrganizationState,
} from '@features/bulkCreateOrganization/bulkCreateOrganizationSlice';
import { useEffect, useState } from 'react';
import useCsvParser from '@hooks/useCsvParser';
import { showFixedAlert } from '@features/application/applicationSlice';
import { useTranslation } from 'react-i18next';
import ClientRoutes from '@config/Routes/WebClientRoutes';
import useBulkCreateOrganizationForm from './useBulkCreateOrganizationForm';

export default function useBulkCreateOrganization({
  selectedPartner,
  onSuccessRequest,
  onFailedRequest,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const form = useBulkCreateOrganizationForm();
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const { bulkCreateOrganizationState } = useSelector((state) => state.bulkCreateOrganization);
  const csvParser = useCsvParser([
    'email',
    'first_name',
    'last_name',
    'organization_name',
    'seats',
    'auto_license',
    'plan',
  ]);

  const submitRequest = () => {
    dispatch(
      bulkCreateOrganizationAsync({
        accessTokenSet,
        reset: true,
        parameters: {
          'support-ticket': form.supportTicketAttributes.value,
          description: form.descriptionAttributes.value,
          'escalation-ticket': form.escalationTicketAttributes.value,
          'reseller-id': selectedPartner.id,
          'create-organization-requests-csv': csvParser.originalCsv,
        },
      })
    );
  };

  const handleOnValidateData = (e) => {
    form.onValidateData(e);
  };

  useEffect(() => {
    if (form.isValid) {
      submitRequest();
    }
  }, [form.isValid]);

  useEffect(() => {
    form.csvValidAttributes.handleChange(csvParser.result.isValid);
  }, [csvParser.result]);

  const resetAll = () => {
    form.resetForm();
    csvParser.reset();
  };

  useEffect(() => {
    if (bulkCreateOrganizationState.statusCode === 200) {
      if (bulkCreateOrganizationState.serviceType) {
        const link = ClientRoutes.toolsRequestStatusPath({
          'service-type': bulkCreateOrganizationState.serviceType,
        });
        dispatch(
          showFixedAlert({
            descriptionHtml: t(
              'partner_list_page.bulk_create_organization.success_submitted_with_link',
              {
                link,
              }
            ),
          })
        );
      } else {
        dispatch(
          showFixedAlert({
            descriptionHtml: t('partner_list_page.bulk_create_organization.success_submitted'),
          })
        );
      }
      if (onSuccessRequest) onSuccessRequest();
    } else if (bulkCreateOrganizationState.error && onFailedRequest) {
      onFailedRequest(bulkCreateOrganizationState.error?.data?.message);
    }
    resetAll();
    dispatch(resetBulkCreateOrganizationState());
  }, [bulkCreateOrganizationState.statusCode]);

  return {
    form,
    bulkCreateOrganizationState,
    parseResult: csvParser.result,
    handleOnValidateData,
    handleOnFileUpload: csvParser.handleOnFileUpload,
    resetAll,
  };
}
