import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  ITBox,
  QboAlert,
  QboCard,
  QboCardCaption,
  QboFormGroup,
  QboSimpleDataTable,
  QboTextField,
} from '@ui/Components';
import NoStatus from '@images/generals/NoStatus.svg';
import BaseSetting from '@config/BaseSetting';
import { useSelector } from 'react-redux';
import useAutoDiscovery from '@pages/ViewAutoDiscovery/pageHooks/useAutoDiscovery';

function ViewAutoDiscoveryFormSection({ organisationIdAttributes, onSubmit }) {
  const { t } = useTranslation();
  const { helperToConvertString } = useAutoDiscovery();

  const { autoDiscoveryState } = useSelector((state) => state.autoDiscoveryForm);
  const { autoDiscoveryFormList, statusCode, message, fetching } = autoDiscoveryState;

  useEffect(() => {
    if (statusCode === 200 || statusCode === 403) {
      organisationIdAttributes.handleChange('');
    }
  }, [statusCode]);

  return (
    <ITBox className="TransferSubscriptionFormPage__wrapper" direction="column">
      <QboFormGroup onSubmit={onSubmit} withSubmitButton>
        {(statusCode || message) && (
          <QboAlert
            type={
              statusCode === 200 && autoDiscoveryFormList && autoDiscoveryFormList?.length !== 0
                ? 'success'
                : 'error'
            }
            style={{ fontSize: '1.15em' }}>
            {message}
          </QboAlert>
        )}
        <QboTextField
          id="organisationID"
          required={Boolean(true)}
          placeholder={t('view_auto_discovery_page.form.organisation_id')}
          label={t('view_auto_discovery_page.form.organisation_id')}
          value={organisationIdAttributes.value}
          errorMessage={organisationIdAttributes.errorMessage}
          inputProps={{ maxLength: 225 }}
          onChange={(e) => {
            organisationIdAttributes.handleChange(e.currentTarget.value);
          }}
          fullWidth
        />
      </QboFormGroup>
      {autoDiscoveryFormList?.length > 0 ? (
        <QboCard noPadding isMainWrapper sx={{ bgColor: 'blue' }}>
          <QboSimpleDataTable
            classNameHeaderStyle="view_auto_discovery_header"
            minWidth={false}
            loading={fetching}
            rows={helperToConvertString(autoDiscoveryFormList)}
            className="view_excluded_mailbox"
            header={BaseSetting.viewAutoDiscoveryFormTableHeader}
            onClickRow={(row) => {}}
          />
        </QboCard>
      ) : (
        statusCode &&
        message &&
        statusCode !== 403 && (
          <QboCardCaption
            sx={{ width: '100%', height: '220px', border: '1px solid black' }}
            imageUrl={NoStatus}
            heading={t('system_status_section.no_status_section.no_record_text')}
          />
        )
      )}
      <br />
    </ITBox>
  );
}

export default ViewAutoDiscoveryFormSection;

ViewAutoDiscoveryFormSection.propTypes = {
  organisationIdAttributes: PropTypes.object,
  onSubmit: PropTypes.func,
};

ViewAutoDiscoveryFormSection.defaultProps = {
  organisationIdAttributes: {},
  onSubmit: () => {},
};
