import { constant } from '@config/BaseSetting';
import useLocalStorage from './useLocalStorage';

function getClaimsFromAccessToken(accessToken) {
  if (!accessToken) return null;
  const segments = accessToken.split('.');
  if (segments.length < 2) return null;

  try {
    return JSON.parse(atob(segments[1]));
  } catch {
    return null;
  }
}

export default function useUserClaims() {
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  return getClaimsFromAccessToken(accessTokenSet);
}
