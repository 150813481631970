import React, { useState, useEffect } from 'react';
import { pushSpliceEntry, sliceArrayWithArray } from '@utilities';
import { QboTypography } from '@ui/Components';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

const dropdownCheckboxSelectAll = 'selectAll';
const dropdownCheckboxSelectAllOnPage = 'selectAllOnPage';
const dropdownCheckboxUnselectAll = 'unselectAll';
const dropdownCheckboxUnselectAllOnPage = 'unselectAllOnPage';

const selectAllConst = 'select_all';

const iconHeaderChecked = {
  icon: <IndeterminateCheckBoxIcon />,
  color: 'primary',
};

const iconHeaderUnchecked = {
  icon: <CheckBoxOutlineBlankIcon />,
  color: 'inherit',
};

const useCheckboxTable = (props = {}) => {
  const { listIdOnPage, totalRow, selectAllPageOnly } = props;
  const { t } = useTranslation();
  const [selected, setSelected] = useState([]);
  const [selectedDropdown, setSelectedDropdown] = useState({
    ...iconHeaderUnchecked,
    type: '',
  });

  const isSelectedAll = selected.includes(selectAllConst);
  const isSelectedAllByRow = selected?.length === totalRow;
  const isSelectedAllInPage = listIdOnPage?.every((r) => selected?.includes(r)) || false;
  const isSomeSelectedInPage = listIdOnPage?.some((r) => selected?.includes(r)) || false;

  const changeSelect = (select) => {
    const newSelect = pushSpliceEntry(selected, select);
    if (isSelectedAll && newSelect?.length > totalRow) {
      setSelected([]);
    } else {
      setSelected(newSelect);
    }
  };

  const resetSelect = () => {
    setSelected([]);
  };

  const totalSelected = () => {
    let rowSelected = 0;
    if (isSelectedAll && selected?.length > 1) {
      rowSelected = totalRow - (selected.length - 1);
    } else if (isSelectedAll && selected?.length === 1) {
      rowSelected = totalRow;
    } else {
      rowSelected = selected.length;
    }
    return rowSelected;
  };

  const listSortType = {
    selectAll: {
      label: 'table_component.checkbox.dropdown_select_all',
      type: dropdownCheckboxSelectAll,
    },
    selectAllOnPage: {
      label: 'table_component.checkbox.dropdown_select_all_on_page',
      type: dropdownCheckboxSelectAllOnPage,
    },
    unselectAll: {
      label: 'table_component.checkbox.dropdown_unselect_all',
      type: dropdownCheckboxUnselectAll,
    },
    unselectAllOnPage: {
      label: 'table_component.checkbox.dropdown_unselect_all_on_page',
      type: dropdownCheckboxUnselectAllOnPage,
    },
  };

  let listSort = [];

  if (isSelectedAll || isSelectedAllByRow) {
    if (selected.length === 1 || isSelectedAllByRow) {
      listSort = [listSortType.unselectAllOnPage];
    } else if (isSelectedAllInPage) {
      listSort = [listSortType.selectAll, listSortType.selectAllOnPage];
    } else if (selected.length < 1 || !isSomeSelectedInPage) {
      listSort = [listSortType.selectAll, listSortType.unselectAllOnPage];
    } else {
      listSort = [
        listSortType.selectAll,
        listSortType.selectAllOnPage,
        listSortType.unselectAllOnPage,
      ];
    }
  } else if (isSelectedAllInPage) {
    listSort = [listSortType.selectAll, listSortType.unselectAllOnPage];
  } else if (selected.length < 1 || !isSomeSelectedInPage) {
    listSort = [listSortType.selectAll, listSortType.selectAllOnPage];
  } else {
    listSort = [
      listSortType.selectAll,
      listSortType.selectAllOnPage,
      listSortType.unselectAllOnPage,
    ];
  }

  if (selected?.length >= 1) {
    if (listSort[listSort.length - 1] === listSortType.unselectAllOnPage) {
      listSort.splice(-1, 0, listSortType.unselectAll);
    } else {
      listSort.push(listSortType.unselectAll);
    }
  }

  if (selectAllPageOnly) {
    listSort = listSort.filter((item) =>
      [listSortType.selectAllOnPage.type, listSortType.unselectAllOnPage.type].includes(item.type)
    );
  }

  const listSortOptions = listSort.map((item) => {
    return [
      item,
      <Box>
        <QboTypography>{t(item.label)}</QboTypography>
      </Box>,
    ];
  });

  const onDropdownSelect = (select, listId) => {
    setSelectedDropdown(select);
    switch (select.type) {
      case dropdownCheckboxSelectAll:
        setSelected([selectAllConst]);
        break;
      case dropdownCheckboxSelectAllOnPage: {
        let newSelect = [...sliceArrayWithArray(selected, listId), ...listId];
        if (newSelect.includes(selectAllConst)) {
          newSelect = pushSpliceEntry(newSelect, selectAllConst);
        }
        setSelected(newSelect);
        break;
      }
      case dropdownCheckboxUnselectAll:
        setSelected([]);
        break;
      case dropdownCheckboxUnselectAllOnPage: {
        if (isSelectedAll) {
          const newSelect = [...sliceArrayWithArray(selected, listId), ...listId];
          setSelected(newSelect?.length > totalRow ? [] : newSelect);
        } else {
          const newSelect = sliceArrayWithArray(selected, listId);
          setSelected(newSelect);
        }
        break;
      }
      default:
        setSelected([]);
    }
  };

  useEffect(() => {
    let newIconHeader = {};
    if (isSelectedAll) {
      if (isSelectedAllInPage) {
        newIconHeader = totalRow > 0 ? iconHeaderUnchecked : iconHeaderChecked;
      } else {
        newIconHeader = iconHeaderChecked;
      }
    } else if (isSomeSelectedInPage) {
      newIconHeader = iconHeaderChecked;
    } else {
      newIconHeader = iconHeaderUnchecked;
    }
    const newSelectedDropdown = { ...selectedDropdown, ...newIconHeader };
    setSelectedDropdown(newSelectedDropdown);
  }, [selected, isSomeSelectedInPage]);

  return {
    selected,
    setSelected: changeSelect,
    listSortOptions,
    selectedDropdown,
    setSelectedDropdown,
    onDropdownSelect,
    selectAllConst,
    totalSelected: totalSelected(),
    resetSelect,
  };
};

export default useCheckboxTable;
