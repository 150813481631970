import { useDispatch } from 'react-redux';
import { constant } from '@config/BaseSetting';
import useLocalStorage from '@hooks/useLocalStorage';
import { fetchMailboxBulkPurgeStatusItem } from '@features/mailboxBulkPurge/getMailboxBulkPurgeStatusSlice';
import { fetchMailboxBulkPurgeListAsync } from '@features/mailboxBulkPurge/serviceActions/fetchMailboxBulkPurgeListAsync';

export default function useBulkPurgeEachList() {
  const dispatch = useDispatch();
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);

  const fetchBulkPurgeEachListAsync = (pageSize, pageIndex, organizationID) => {
    dispatch(
      fetchMailboxBulkPurgeListAsync({
        accessToken: accessTokenSet,
        reset: true,
        organizationID,
        pageSize,
        pageNumber: pageIndex,
      })
    );
  };

  const fetchBulkPurgeStatusItemAsync = (organizationID) => {
    dispatch(
      fetchMailboxBulkPurgeStatusItem({ accessToken: accessTokenSet, reset: true, organizationID })
    );
  };

  return { fetchBulkPurgeEachListAsync, fetchBulkPurgeStatusItemAsync };
}
