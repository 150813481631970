import { useDispatch } from 'react-redux';
import { getIngestionReportListItemAsync } from '@features/ingestion/ingestionReportsListItemSlice';
import { getIngestionReportListAsync } from '@features/ingestion/ingestionReportsSlice';
import useLocalStorage from '@hooks/useLocalStorage';
import { constant } from '@config/BaseSetting';
import { getIngestionFailedEmlItemAsync } from '@features/ingestion/ingestionFailedEmlSlice';

export default function useIngestionReportsSection() {
  const dispatch = useDispatch();
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);

  const handleFetchIngestionReportList = (ingestionID, pageNumber, pageSize) => {
    dispatch(
      getIngestionReportListAsync({
        accessToken: accessTokenSet,
        reset: true,
        ingestionID,
        pageNumber,
        pageSize,
      })
    );
  };

  const handleFetchIngestionReportListItem = (ingestionID, pageNumber, pageSize) => {
    dispatch(
      getIngestionReportListItemAsync({
        accessToken: accessTokenSet,
        reset: true,
        ingestionID,
        pageNumber,
        pageSize,
      })
    );
  };

  const handleFetchIngestionFailedEmlItemAsync = (ingestionID, pageNumber, pageSize) => {
    dispatch(
      getIngestionFailedEmlItemAsync({
        accessToken: accessTokenSet,
        reset: true,
        ingestionID,
        pageNumber,
        pageSize,
      })
    );
  };

  const secondsToMinutes = (seconds) => {
    const minutes = seconds / 60;
    return minutes.toFixed(2);
  };

  const checkLessThanMB = (bytes) => {
    let columnData;
    const megabytes = bytes / (1024 * 1024);
    if (megabytes < 1) {
      columnData = 'Less than a MB';
    } else {
      columnData = megabytes.toFixed(2);
    }

    return columnData;
  };

  const checkLessThanMinutes = (seconds) => {
    let statusMessage;
    if (seconds < 60) {
      statusMessage = 'Less than a minute';
    } else {
      statusMessage = secondsToMinutes(seconds);
    }
    return statusMessage;
  };

  const getConvertedDataFromResponse = (data, setReportsData) => {
    const modifiedData = data?.map((item) => {
      const modifiedPayloadObj = {
        'ingestion-job-item-id': item['ingestion-job-item-id'],
        size: checkLessThanMB(item?.size),
        'total-count': item['total-count'],
        'success-count': item['success-count'],
        'failure-count': item['failure-count'],
        'time-taken': checkLessThanMinutes(item['time-taken']),
        'download-time-taken': checkLessThanMinutes(item['download-time-taken']),
        'download-size': item['download-size'],
        'ingested-data-size': checkLessThanMB(item['ingested-data-size']),
        'total-time-taken': checkLessThanMinutes(item['total-time-taken']),
        'indexed-items': item['indexed-items'],
        'duplicate-items': item['duplicate-items'],
      };
      return modifiedPayloadObj;
    });
    return setReportsData(modifiedData);
  };

  const convertIngestionFailedEmlData = (data, setReportsData) => {
    const modifiedData = data?.map((item) => {
      const modifiedPayloadObj = {
        'file-name': item['file-name'],
        'failure-message': item['failure-message'],
      };
      return modifiedPayloadObj;
    });
    return setReportsData(modifiedData);
  };

  return {
    handleFetchIngestionReportList,
    handleFetchIngestionReportListItem,
    handleFetchIngestionFailedEmlItemAsync,
    getConvertedDataFromResponse,
    convertIngestionFailedEmlData,
  };
}
