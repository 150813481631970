import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { submitFormAsync, resetFormSubmit } from '@features/form/formSlice';
import useChangePasswordValidation from './useFormValidation';

export default function useChangePasswordForm() {
  const { t } = useTranslation();

  const {
    formData,
    usernameAttributes,
    emailAttributes,
    genderAttributes,
    addressAttributes,
    phoneAttributes,
    pinCodeAttributes,
    validateAll,
    isValid,
    resetForm,
  } = useChangePasswordValidation();

  const dispatch = useDispatch();
  const { submitForm } = useSelector((state) => state.form);
  const { success, error, submitting } = submitForm;

  const onSubmit = (e) => {
    e.preventDefault();

    validateAll();
    if (isValid && !submitting) {
      dispatch(submitFormAsync(formData));
    }
  };

  useEffect(() => {
    if (success === true) {
      alert('Success');
      dispatch(resetFormSubmit());
      resetForm();
    } else if (error) {
      alert('Error');
    }
  }, [success, error]);

  return {
    usernameAttributes,
    emailAttributes,
    genderAttributes,
    addressAttributes,
    phoneAttributes,
    pinCodeAttributes,
    isValid,
    success,
    onSubmit,
  };
}
