import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { QboTypography, QboButton, ITBox } from '@ui/Components';

export default function ConfirmationBlock({
  children,
  onBack,
  backButtonLabel,
  onNext,
  nextButtonLabel,
  loading,
}) {
  return (
    <Box className="ConfirmationBlock__wrapper">
      <ITBox className="ConfirmationBlock__content">
        <QboTypography id="modal-modal-body">{children}</QboTypography>
      </ITBox>
      <ITBox className="ConfirmationBlock__button">
        <QboButton variant="outlined" color="secondary" onClick={() => onBack()} loading={loading}>
          {backButtonLabel}
        </QboButton>
        <QboButton variant="contained" onClick={() => onNext()} loading={loading}>
          {nextButtonLabel}
        </QboButton>
      </ITBox>
    </Box>
  );
}

ConfirmationBlock.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onBack: PropTypes.func,
  backButtonLabel: PropTypes.string,
  onNext: PropTypes.func,
  nextButtonLabel: PropTypes.string,
  loading: PropTypes.bool,
};

ConfirmationBlock.defaultProps = {
  children: null,
  onBack: () => {},
  backButtonLabel: 'No',
  onNext: () => {},
  nextButtonLabel: 'Yes',
  loading: true,
};
