import { constant } from '@config/BaseSetting';
import { showFixedAlert } from '@features/application/applicationSlice';
import {
  checkXeroSyncRunningAsync,
  resetCheckXeroSyncRunningState,
} from '@features/invoice/checkXeroSyncRunningSlice';
import useLocalStorage from '@hooks/useLocalStorage';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

export default function useXeroSyncValidation({ showModal, onFailed }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isValid, setIsValid] = useState(false);
  const { checkXeroSyncRunningState } = useSelector((state) => state.checkXeroSyncRunning);
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);

  const reset = () => {
    setIsValid(false);
    dispatch(resetCheckXeroSyncRunningState());
  };

  useEffect(() => {
    if (!showModal) return;
    reset();
    dispatch(
      checkXeroSyncRunningAsync({
        accessTokenSet,
        reset: true,
      })
    );
  }, [showModal]);

  useEffect(() => {
    const result = checkXeroSyncRunningState.isRunning;
    if (result === false) {
      setIsValid(true);
    } else if (result === true) {
      dispatch(
        showFixedAlert({
          descriptionHtml: t('invoices_page.xero_sync.validations.is_running'),
          severity: 'error',
        })
      );
      reset();
      onFailed();
    }
  }, [checkXeroSyncRunningState]);

  return {
    isValid,
    validating: checkXeroSyncRunningState.fetching,
    reset,
  };
}
