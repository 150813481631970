import { useState } from 'react';
import SchemaBuilder from '@libs/SchemaBuilder';

const usernameName = 'username';
const emailName = 'email';
const genderName = 'gender';
const addressName = 'address';
const phoneName = 'phone';
const pinCodeName = 'pinCode';

const InitialForm = {
  [usernameName]: '',
  [emailName]: '',
  [genderName]: '',
  [addressName]: '',
  [phoneName]: '',
  [pinCodeName]: '',
};

export default function useFormValidation() {
  // initialize
  const schemaBuilder = new SchemaBuilder({}, 'yup').create();

  const [isValid, setIsValid] = useState(true);
  const [errors, setErrors] = useState('');
  const [formData, setFormData] = useState(InitialForm);

  //
  // define schemas
  //
  const formSchema = schemaBuilder.object().shape({
    [usernameName]: schemaBuilder.string().required(),
    [emailName]: schemaBuilder.string().email('value must be an email').required(),
    [genderName]: schemaBuilder.string().required(),
    [addressName]: schemaBuilder.string().required(),
    [phoneName]: schemaBuilder.number().required(),
    [pinCodeName]: schemaBuilder.number().required(),
  });

  //
  // validate input
  //
  const validate = async (value) => {
    // eslint-disable-next-line func-names
    await formSchema.validate(value, { abortEarly: false }).catch(function (err) {
      const errorLogs = err.inner.reduce((acc, error) => {
        return {
          ...acc,
          [error.path]: error.message,
        };
      }, {});
      setErrors(errorLogs);
      return errorLogs;
    });
    const validation = await formSchema.isValid(value);
    if (validation) {
      setErrors({});
    }
    setIsValid(validation);
  };

  const validateAll = () => {
    validate(formData);
  };

  // Update form data
  const updateFormData = (newData) => {
    setFormData((prev) => {
      return { ...prev, ...newData };
    });
    // validate(newData);
  };

  //
  // construct the attributes
  //
  const usernameAttributes = {
    handleChange: async (value) => {
      updateFormData({ [usernameName]: value });
    },
    errorMessage: errors[usernameName],
    value: formData[usernameName],
  };
  const emailAttributes = {
    handleChange: async (value) => {
      updateFormData({ [emailName]: value });
    },
    errorMessage: errors[emailName],
    value: formData[emailName],
  };
  const genderAttributes = {
    handleChange: async (value) => {
      updateFormData({ [genderName]: value });
    },
    errorMessage: errors[genderName],
    value: formData[genderName],
  };
  const addressAttributes = {
    handleChange: async (value) => {
      updateFormData({ [addressName]: value });
    },
    errorMessage: errors[addressName],
    value: formData[addressName],
  };
  const phoneAttributes = {
    handleChange: async (value) => {
      updateFormData({ [phoneName]: value });
    },
    errorMessage: errors[phoneName],
    value: formData[phoneName],
  };
  const pinCodeAttributes = {
    handleChange: async (value) => {
      updateFormData({ [pinCodeName]: value });
    },
    errorMessage: errors[pinCodeName],
    value: formData[pinCodeName],
  };

  const resetForm = () => {
    setFormData(InitialForm);
  };

  return {
    formData,
    usernameAttributes,
    emailAttributes,
    genderAttributes,
    addressAttributes,
    phoneAttributes,
    pinCodeAttributes,
    validateAll,
    isValid,
    resetForm,
  };
}
