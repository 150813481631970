import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import useUserBackupFecthing from '@pages/UserBackupPage/pageHooks/useUserBackupFecthing';
import { Skeleton } from '@mui/material';
import { QboButton, QboCard } from '@ui/Components';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import QboCardSingleList from '@ui/Components/QboCardSingleList';
import RefreshIcon from '@mui/icons-material/Refresh';

export default function LastBackupSection(props) {
  const { lastBackupId, backupType } = props;
  const { lastBackupState, userBackupDetailState } = useSelector((state) => state.userBackupState);
  const { fetching, data } = lastBackupState;
  const { fetching: userDetailFetching } = userBackupDetailState;

  const { handleFetcLastBackupAsync, lastBackupRows, handleFetchUserBackupDetailAsync } =
    useUserBackupFecthing();

  const fetchHandler = () => {
    if (!fetching) {
      const reloadLastBackupId = userBackupDetailState?.data?.lastBackupId || lastBackupId;
      handleFetcLastBackupAsync(reloadLastBackupId, backupType);
    }
  };

  const onClickRefresh = () => {
    handleFetchUserBackupDetailAsync(userBackupDetailState?.data?.userBackupId, backupType);
  };

  useEffect(() => {
    if (lastBackupId) {
      fetchHandler();
    }
  }, [lastBackupId, userBackupDetailState?.data?.lastBackupId]);

  const renderLoading = () => {
    return (
      <div style={{ display: 'contents' }}>
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="70%" />
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="70%" />
      </div>
    );
  };

  const renderList = () => {
    const elmList = [];
    const items = lastBackupRows(backupType);
    Object.keys(items).forEach((key, item) => {
      elmList.push(
        <QboCardSingleList
          key={key}
          subHeadingText={items[key]?.sub}
          leftContent={items[key]?.title || items[key]}
          rightContent={data[key]?.toString() || '-'}
        />
      );
    });
    return elmList;
  };

  const componentHandler = () => {
    if (fetching || userDetailFetching) return renderLoading();

    if (data) {
      return (
        <>
          <div>
            <QboButton
              variant="contained"
              onClick={onClickRefresh}
              disabled={!userBackupDetailState?.data?.userBackupId}>
              Refresh
            </QboButton>
          </div>

          <QboCard
            className="QboCard__wrapper_search_text_highlight"
            sx={{
              scrollbarWidth: 'none !important',
              overflowY: 'scroll !important',
              height: '65vh',
            }}>
            <QboCard noPadding>{renderList()}</QboCard>
          </QboCard>
        </>
      );
    }

    return <NoStatusSection />;
  };

  return componentHandler();
}

LastBackupSection.propTypes = {
  lastBackupId: PropTypes.number,
  backupType: PropTypes.string,
};

LastBackupSection.defaultProps = {
  lastBackupId: null,
  backupType: 'email',
};
