import { createSlice } from '@reduxjs/toolkit';
import {
  getBulkCreateOrganizationTemplateAsync,
  getBulkCreateOrganizationTemplateExtraReducers,
} from './serviceActions/getBulkCreateOrganizationTemplateAsync';

const initialState = {
  getBulkCreateOrganizationTemplateState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    exportData: null,
    forbiddenState: false,
    forbiddenErrorMessage: null,
    corsErrorMessage: null,
  },
};

const getBulkCreateOrganizationTemplateTenantSlice = createSlice({
  name: 'getBulkCreateOrganizationTemplate',
  initialState,
  reducers: {
    resetGetBulkCreateOrganizationTemplateState(state) {
      state.getBulkCreateOrganizationTemplateState =
        initialState.getBulkCreateOrganizationTemplateState;
    },
  },
  extraReducers: {
    ...getBulkCreateOrganizationTemplateExtraReducers,
  },
});

export { getBulkCreateOrganizationTemplateAsync };
export const { resetGetBulkCreateOrganizationTemplateState } =
  getBulkCreateOrganizationTemplateTenantSlice.actions;
export default getBulkCreateOrganizationTemplateTenantSlice.reducer;
