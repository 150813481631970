import { constant } from '@config/BaseSetting';
import { getOrganizationInformationAsync } from '@features/organizationInformation/organizationInformationSlice';
import useLocalStorage from '@hooks/useLocalStorage';
import { useDispatch } from 'react-redux';

export default function useOrganizationInformationService() {
  const [accessToken] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);

  const dispatch = useDispatch();

  const handleFetchOrganizationInformationAsync = (resellerID) => {
    dispatch(
      getOrganizationInformationAsync({
        accessToken,
        reset: true,
        reseller: resellerID,
      })
    );
  };

  return { handleFetchOrganizationInformationAsync };
}
