import { showPartnerListPath } from '@config/Routes/WebClientRoutes';
import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import { QboBreadcrumbs, QboCard, QboTypography } from '@ui/Components';
import React from 'react';
import { Link } from 'react-router-dom';

export default function NumberOfOrganizationList() {
  return (
    <>
      <HeaderPageBlock>Organization List</HeaderPageBlock>
      <QboBreadcrumbs>
        <Link
          to={showPartnerListPath}
          style={{ color: '#1c63d5', fontSize: '1.5rem', textDecoration: 'underline' }}>
          Partners
        </Link>
        <QboTypography color="text.primary" style={{ fontSize: '1.5rem' }}>
          Organization List
        </QboTypography>
      </QboBreadcrumbs>
    </>
  );
}
