//
// create slice
// as centeralize between actions and reducers
//
// serviceActions module contains reducers and actions that
// created by createAsyncThunk from redux-toolkit
//
// for standard actions, that require other business logic
// can be put inside extraActions module
//
// export default will be reducers it self that processed by store
// other exports will contains actions that's dispatched from ReactComponent
//

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isUserSignedIn: false,
};

const authSlice = createSlice({
  name: 'Auth',
  initialState,
  reducers: {
    setUserSignedIn(state, action) {
      state.isUserSignedIn = action.payload;
    },
    resetAuth(state) {
      state.isUserSignedIn = initialState.isUserSignedIn;
    },
  },
});

export const { setUserSignedIn, resetAuth } = authSlice.actions;
export default authSlice.reducer;
