import { createSlice } from '@reduxjs/toolkit';
import {
  getSubResellerListAsync,
  getSubResellerListReducers,
} from './serviceActions/getSubResellerListAsync';

const initialState = {
  getSubResellerListState: {
    fetching: false,
    success: false,
    subResellerListData: [],
    error: null,
    errorMessage: null,
    pagination: [],
    statusCode: null,
    forbiddenState: false,
    forbiddenErrorMessage: {},
    getSubResellerListCorsErrorMessage: null,
  },
};

const getSubResellerListSlice = createSlice({
  name: 'GetSubResellerList',
  initialState,
  reducers: {
    resetSubResellerList(state) {
      state.getSubResellerListState = initialState.getSubResellerListState;
    },
  },
  extraReducers: {
    ...getSubResellerListReducers,
  },
});

export { getSubResellerListAsync };
export const { resetSubResellerList } = getSubResellerListSlice.actions;
export default getSubResellerListSlice.reducer;
