import { useState } from 'react';
import * as yup from 'yup';

const supportTicketName = 'support-ticket';
const escalationTicketName = 'escalation-ticket';
const descriptionName = 'description';
const targetPartnerIDName = 'target-partner-id';
const organizationOwnerIDName = 'org-owner-id';
const resellerIDName = 'request-id';

const InitialForm = {
  [supportTicketName]: '',
  [escalationTicketName]: '',
  [descriptionName]: '',
  [targetPartnerIDName]: '',
  [organizationOwnerIDName]: '',
};

export default function useFormValidation() {
  const [isValid, setIsValid] = useState(false);
  const [errors, setErrors] = useState('');
  const [formData, setFormData] = useState(InitialForm);

  const formSchema = yup.object().shape({
    [supportTicketName]: yup.string().required('Support Ticket is required field'),
    // [escalationTicketName]: yup.string().required('Escalation Ticket is required field'),
    // [descriptionName]: yup.string().required('Description is required field'),
    [targetPartnerIDName]: yup.string().required('Target Partner is required field'),
    [organizationOwnerIDName]: yup.string().required('Organization Owner ID is required field'),
  });
  const validate = async (value) => {
    await formSchema.validate(value, { abortEarly: false }).catch(function (err) {
      const errorLogs = err.inner.reduce((acc, error) => {
        return {
          ...acc,
          [error.path]: error.message,
        };
      }, {});
      setErrors(errorLogs);
      return errorLogs;
    });
    const validation = await formSchema.isValid(value);
    if (validation) {
      setErrors({});
    }
    setIsValid(validation);
  };

  const validateAll = () => {
    validate(formData);
  };

  const updateFormData = (newData) => {
    setFormData((prev) => {
      return { ...prev, ...newData };
    });
  };

  const supportTicketAttributes = {
    handleChange: async (value) => {
      updateFormData({ [supportTicketName]: value });
    },
    errorMessage: errors[supportTicketName],
    value: formData[supportTicketName],
  };
  const escalationTicketAttributes = {
    handleChange: async (value) => {
      updateFormData({ [escalationTicketName]: value });
    },
    errorMessage: errors[escalationTicketName],
    value: formData[escalationTicketName],
  };
  const descriptionAttributes = {
    handleChange: async (value) => {
      updateFormData({ [descriptionName]: value });
    },
    errorMessage: errors[descriptionName],
    value: formData[descriptionName],
  };
  const targetPartnerIDAttributes = {
    handleChange: async (value) => {
      updateFormData({ [targetPartnerIDName]: value });
    },
    errorMessage: errors[targetPartnerIDName],
    value: formData[targetPartnerIDName],
  };
  const organizationOwnerIDAttributes = {
    handleChange: async (value) => {
      updateFormData({ [organizationOwnerIDName]: value });
    },
    errorMessage: errors[organizationOwnerIDName],
    value: formData[organizationOwnerIDName],
  };

  const resetForm = () => {
    setFormData(InitialForm);
    setErrors({});
    setIsValid(false);
  };

  return {
    formData,
    supportTicketAttributes,
    descriptionAttributes,
    escalationTicketAttributes,
    targetPartnerIDAttributes,
    organizationOwnerIDAttributes,
    validateAll,
    isValid,
    resetForm,
    formSchema,
  };
}
