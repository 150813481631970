import { createSlice } from '@reduxjs/toolkit';
import {
  getSwitchCredentialRequestAsync,
  getSwitchCredentialRequestReducers,
} from './serviceActions/fetchSwitchCredentialRequestAsync';

const initialState = {
  switchCredentialRequestState: {
    fetching: false,
    success: false,
    data: [],
    error: null,
    errorMessage: null,
    pagination: [],
    forbiddenState: false,
    forbiddenErrorMessage: {},
    switchCredentialRequestCorsErrorMessage: null,
  },
};

const getSwitchCredentialRequestSlice = createSlice({
  name: 'SwitchCredentialRequest',
  initialState,
  reducers: {
    resetSwitchCredentialRequestState(state) {
      state.switchCredentialRequestState = initialState.switchCredentialRequestState;
    },
  },
  extraReducers: {
    ...getSwitchCredentialRequestReducers,
  },
});

export { getSwitchCredentialRequestAsync };
export const { resetSwitchCredentialRequestState } = getSwitchCredentialRequestSlice.actions;
export default getSwitchCredentialRequestSlice.reducer;
