import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEqual } from 'lodash';
import useLocalStorage from '@hooks/useLocalStorage';
import { constant } from '@config/BaseSetting';
import { resetIngestionFormSubmit } from '@features/ingestion/ingestionSlice';
import useIngestionSubmit from './useIngestionSubmit';
import useFormValidation from './useFormValidation';

export default function useForm() {
  const { handleSubmitIngestion, getFormData, checkValueExistance } = useIngestionSubmit();
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const [formDataObject, setFormDataObject] = useState({});

  const {
    formData,
    organizationOwnerIdAttributes,
    ingestionTypeAttributes,
    catchAllEmailAccountAttributes,
    storageTypeAttributes,
    mappingFileAttributes,
    ftpUrlAttributes,
    ftpPasswordAttributes,
    validateAll,
    ftpUserNameAttributes,
    s3bucketnameAttributes,
    s3KeyAttributes,
    folderAttributes,
    supportTicketAttributes,
    descriptionAttributes,
    escalationTicketAttributes,
    isValid,
    zipFilePasswordAttributes,
    retainFolderStructureAttributes,
    s3StorageRegionAttributes,
    resetForm,
  } = useFormValidation();
  const dispatch = useDispatch();

  useEffect(() => {
    const { ingestionType, storageType, ...rest } = formData;
    setFormDataObject(rest);
  }, [formData]);

  const previousPropsRef = useRef(formDataObject);

  const handleSubmitIngestionRequest = () => {
    if (isValid && checkValueExistance(formData)) {
      const data = getFormData(formData);

      handleSubmitIngestion({
        accessToken: accessTokenSet,
        reset: true,
        parameters: data,
      });
    }
  };
  const onSubmit = (e) => {
    e.preventDefault();
    validateAll();
    const previousProps = previousPropsRef.current;
    const { ingestionType, storageType, ...rest } = formData;

    if (!isEqual(previousProps, rest)) {
      dispatch(resetIngestionFormSubmit());
      handleSubmitIngestionRequest();
      previousPropsRef.current = rest;
    }
  };

  useEffect(() => {
    handleSubmitIngestionRequest();
  }, [isValid]);

  return {
    formData,
    organizationOwnerIdAttributes,
    ingestionTypeAttributes,
    catchAllEmailAccountAttributes,
    storageTypeAttributes,
    mappingFileAttributes,
    ftpUrlAttributes,
    ftpPasswordAttributes,
    ftpUserNameAttributes,
    s3bucketnameAttributes,
    s3KeyAttributes,
    folderAttributes,
    supportTicketAttributes,
    descriptionAttributes,
    escalationTicketAttributes,
    zipFilePasswordAttributes,
    retainFolderStructureAttributes,
    s3StorageRegionAttributes,
    validateAll,
    isValid,
    resetForm,
    onSubmit,
  };
}
