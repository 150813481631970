import useUrlQuery from '@hooks/useUrlQuery';

export default function useToolsRequestDetails() {
  const [jobId, setJobId] = useUrlQuery('id');
  const [jobType, setJobType] = useUrlQuery('job-type');

  const handleOnColumnClicked = (r) => {
    setJobId(r.id);
    setJobType(r['job-type-id']);
  };

  const handleOnModalClosed = () => {
    setJobId('');
    setJobType('');
  };
  const clickableColumnsList = [{ id: 1, column: 'details', handler: handleOnColumnClicked }];
  return {
    jobId,
    jobType,
    clickableColumnsList,
    handleOnModalClosed,
  };
}
