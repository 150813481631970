import { createSlice } from '@reduxjs/toolkit';

import {
  recreateBackupAdminsAsync,
  recreateBackupAdminsExtraReducers,
} from './serviceActions/recreateBackupAdminsAsync';

const initialState = {
  recreateBackupAdminsState: {
    fetching: false,
    status: null,
    error: null,
    message: null,
    errorMessage: null,
    forbiddenState: false,
    forbiddenErrorMessage: null,
    recreateBackupAdminsCorsErrorMessage: null,
  },
};

const recreateRecreateBackupAdminsSlice = createSlice({
  name: 'recreateBackupAdmins',
  initialState,
  reducers: {
    resetRecreateBackupAdminsState(state) {
      state.recreateBackupAdminsState = initialState.recreateBackupAdminsState;
    },
  },
  extraReducers: {
    ...recreateBackupAdminsExtraReducers,
  },
});

export { recreateBackupAdminsAsync };

export const { resetRecreateBackupAdminsState } = recreateRecreateBackupAdminsSlice.actions;
export default recreateRecreateBackupAdminsSlice.reducer;
