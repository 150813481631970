import moment from 'moment';
import DateTimeError from './DateTimeError';
import DateTime from './DateTime';

/* eslint-disable no-console */
class DateTimeMoment extends DateTime {
  TIME_UNITS = {
    hours: 'hours',
    minutes: 'minutes',
    seconds: 'seconds',
  };

  constructor({ parseFormat }) {
    super();

    this.client = moment;
    this.parseFormat = parseFormat;
  }

  getClient() {
    return this.client;
  }

  parseDate(date) {
    if (!date) throw new DateTimeError('Date arg is required');
    if (!this.parseFormat) throw new DateTimeError('Parse format is required');

    let parseResult;

    try {
      let dateParse = date;
      if (typeof date === 'object') dateParse = date.toString();

      parseResult = this.client(dateParse, this.parseFormat);
    } catch (err) {
      console.info(err.message);
    }

    return parseResult;
  }

  toString(date, format) {
    return this.parseDate(date).format(format);
  }

  //
  // Params:
  // date1 => in String
  // date2 => in String
  //
  // Returns:
  // Array of Numbers
  // [NumberInHour, NumberInMinutes, NumberIn]
  //
  dateDistance(date1, date2) {
    try {
      let parseDate1 = this.parseDate(date1);
      const parseDate2 = this.parseDate(date2);

      const distances = [];

      Object.keys(this.TIME_UNITS).forEach((unit) => {
        const diffNumber = parseDate2.diff(parseDate1, unit);
        distances.push(diffNumber);
        parseDate1 = parseDate1.add(diffNumber, unit);
      });

      return distances;
    } catch (err) {
      console.info(err.message);
      return [0, 0, 0];
    }
  }

  //
  // return current date time
  // in string and backend format
  //
  dateTimeNow() {
    return this.client().format(this.parseFormat);
  }

  //
  // Params:
  // date => in object moment
  // time => in object moment
  //
  // Func:
  // Merge date and time to single date time
  // used on DatePicker and TimePicker
  //
  // Returns:
  // object moment
  //
  mergeDateAndTime(date, time) {
    if (!date || !time) throw new DateTimeError('Date arg is required');
    const dateTime = this.client({
      y: date?.year(),
      M: date?.month(),
      d: date?.date(),
      h: time?.hour(),
      m: time?.minute(),
      s: time?.second(),
      ms: 0,
    });
    return dateTime;
  }

  //
  // Params:
  // date => in object moment
  // time => in object moment
  //
  // Func:
  // validate that data1 and data2 are the same
  //
  // Returns:
  // boolean
  //
  equalDateValidation(date1, date2) {
    this.client();
    if (!date1 || !date2) throw new DateTimeError('Date arg is required');
    let isEqual = false;
    if (
      date1?.year() === date2?.year() &&
      date1?.month() === date2?.month() &&
      date1?.date() === date2?.date()
    ) {
      isEqual = true;
    }
    return isEqual;
  }

  //
  // Params:
  // date => in object moment
  //
  // Func:
  // formating object moment to iso format
  //
  // Returns:
  // return date time in iso format
  //
  toISOFormat(date) {
    if (!date) throw new DateTimeError('Date arg is required');
    return this.client(date).toISOString();
  }

  //
  // return current date time
  // in object format
  //
  dateTimeNowObject() {
    return this.client();
  }

  //
  // Params:
  // date => in string base backend format
  //
  // Func:
  // formating string to object
  //
  // Returns:
  // return object date time
  //
  stringToDate(date) {
    if (!date) throw new DateTimeError('Date arg is required');
    return this.parseDate(date);
  }

  //
  // Params:
  // date => in string base backend format
  //
  // Func:
  // sorting array date
  //
  // Returns:
  // return sorted array
  //
  sortArrayDate(date) {
    if (!date?.length) return [];
    const sortedArray = date.sort((a, b) => this.stringToDate(a).diff(this.stringToDate(b)));
    return sortedArray;
  }
}

export default DateTimeMoment;
