// eslint-disable-next-line import/prefer-default-export
export const CCT_STATUS_COLUMNS = [
  { label: 'ID', columnName: 'backupId' },
  { label: 'Email Account ID', columnName: 'userBackupId' },
  {
    label: 'Email',
    columnName: 'email',
    size: 'sm',
  },
  {
    label: 'Organization Name',
    columnName: 'organizationName',
    size: 'sm',
  },
  {
    label: 'Partner Name',
    columnName: 'partnerName',
    size: 'sm',
  },
  { label: 'Backup Status', columnName: 'status', size: 'sm' },
  { label: 'Start Backup Time', columnName: 'startTime', size: 'sm' },
  { label: 'End Backup Time', columnName: 'endTime', size: 'sm' },
  { label: 'Email Account Deactivated Since', columnName: 'deactivatedSince', size: 'sm' },
  { label: 'Email Account Deactivated By', columnName: 'deactivatedBy', size: 'sm' },
  { label: 'Backup Storage KB', columnName: 'backupStorage', size: 'sm' },
  { label: 'Current Message Numbers', columnName: 'currentMessagesCount', size: 'sm' },
  { label: 'Total Message Numbers', columnName: 'totalMessagesCount', size: 'sm' },
  { label: 'Retries Time', columnName: 'retries', size: 'sm' },
  { label: 'Error Messages', columnName: 'errorMessage', size: 'sm' },
  { label: 'Run on Machine IP', columnName: 'machineIP', size: 'sm' },
  { label: 'Created At', columnName: 'createdAt', size: 'sm' },
  { label: 'Last Data Updated At', columnName: 'updatedAt', size: 'sm' },
];
