import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchListingAysnc, resetListing } from '@features/listing/listingSlice';
import { QboTypography } from '@ui/Components';
import { pascalToTitleCase } from '@utilities/stringUtils';

const defaultOrder = 'asc';
const defaultOrderColumn = 'ObjectName';
const defaultRowPerPage = 200;

const idColumn = 'id';
const titleColumn = 'title';
const bodyColumn = 'body';
const folderIconColumn = 'folderIcon';

const createHeader = (id, label, columnName, orderable, size, align) => {
  return {
    id,
    label,
    columnName,
    orderable,
    size,
    noDivider: id === 1,
    align,
    noPadding: columnName === folderIconColumn,
  };
};

const createData = (id, title, body) => {
  return {
    id,
    title: (
      <QboTypography variant="subtitle2" color="secondary" withTitle>
        {title}
      </QboTypography>
    ),
    body: (
      <QboTypography variant="subtitle2" color="secondary" withTitle>
        {body}
      </QboTypography>
    ),
  };
};

export default function useFetchListing() {
  const dispatch = useDispatch();
  const { listing, fetchListing } = useSelector((state) => state.list);
  const { value } = listing;

  const tableHeaders = [
    createHeader(1, pascalToTitleCase(idColumn), idColumn, false, 'xxs', 'center'),
    createHeader(2, pascalToTitleCase(titleColumn), titleColumn, true, 'sm', 'left'),
    createHeader(3, pascalToTitleCase(bodyColumn), bodyColumn, true, 'md', 'left'),
  ];

  const tableRows = listing.value.map((item) => createData(item.id, item.title, item.body));

  const listIdItem = listing.value.map((item) => item.id);

  useEffect(() => {
    dispatch(fetchListingAysnc());

    return () => {
      dispatch(resetListing());
    };
  }, []);

  return {
    isLoading: fetchListing,
    listData: value,
    tableHeaders,
    tableRows,
    listIdItem,
  };
}
