import { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { isEqual } from 'lodash';
import { constant } from '@config/BaseSetting';
import useLocalStorage from '@hooks/useLocalStorage';
import useEmailFromCSV from '@hooks/useEmailFromCSV';
import { submitMailboxBulkPurgeFormAsync } from '@features/mailboxBulkPurge/mailboxBulkPurgeSlice';
import useFormValidation from './useFormValidation';

export default function useForm() {
  const {
    formData,
    accountIDAttributes,
    supportTicketAttributes,
    escalationTicketAttributes,
    mappingFileAttributes,
    emailIdsAttributes,
    descriptionAttributes,
    validateAll,
    isValid,
    resetForm,
    validate,
  } = useFormValidation();

  const dispatch = useDispatch();
  const { convertListOfMailsToArray } = useEmailFromCSV();
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);

  const previousPropsRef = useRef(formData);

  const handleSubmitBulkPurgeRequest = () => {
    const paramData = convertListOfMailsToArray(formData);
    if (isValid) {
      dispatch(
        submitMailboxBulkPurgeFormAsync({
          accessToken: accessTokenSet,
          reset: true,
          parameters: paramData,
        })
      );
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    validateAll();

    const previousProps = previousPropsRef.current;
    if (!isEqual(previousProps, formData)) {
      previousPropsRef.current = formData;
      handleSubmitBulkPurgeRequest();
    }
  };

  useEffect(() => {
    handleSubmitBulkPurgeRequest();
  }, [isValid]);

  return {
    formData,
    accountIDAttributes,
    mappingFileAttributes,
    supportTicketAttributes,
    escalationTicketAttributes,
    emailIdsAttributes,
    descriptionAttributes,
    validateAll,
    isValid,
    resetForm,
    validate,
    onSubmit,
  };
}
