import { createAsyncThunk } from '@reduxjs/toolkit';
import { getOrganizationUsersListAPI } from '@services/WebApiService';

const getOrganizationUsersList = createAsyncThunk(
  'UsersList/getOrganizationUsersList',
  async (paramsList) => {
    try {
      const data = await getOrganizationUsersListAPI(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = getOrganizationUsersList;

const getOrganizationUsersListExtraReducers = {
  [pending]: (state) => {
    state.getOrganizationUsersListState.fetching = true;
  },
  [rejected]: (state) => {
    state.getOrganizationUsersListState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.getOrganizationUsersListState.fetching = false;

    if (action?.payload?.status === 403) {
      state.getOrganizationUsersListState.forbiddenState = true;
      state.getOrganizationUsersListState.forbiddenErrorMessage = {
        heading: 'Forbidden Error',
        caption: 'You are not authorize to access this resource',
      };
    }

    if (!action?.payload) {
      state.getOrganizationUsersListState.UsersListCorsErrorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.data?.data) {
      state.getOrganizationUsersListState.usersList = action?.payload?.data?.data;
      state.getOrganizationUsersListState.paginationList = JSON.parse(
        action?.payload?.data?.pagination
      );
      state.getOrganizationUsersListState.success = true;
      state.getOrganizationUsersListState.statusCode = action?.payload?.data['status-code'];
    }

    if (action?.payload?.error) {
      state.getOrganizationUsersListState.error = action.payload.error;
    }
  },
};

export { getOrganizationUsersList, getOrganizationUsersListExtraReducers };
