import { useState } from 'react';
import * as yup from 'yup';

const organizationOwnerIdName = 'organizationOwnerId';
const oneItemName = 'oneItemName';
const emailAddressName = 'emailAddressName';
const multipleItemName = 'multipleItemName';
const fileName = 'fileName';
const mappingFileName = 'mappingFile';

const InitialForm = {
  [oneItemName]: '',
  [multipleItemName]: '',
  [emailAddressName]: '',
  [fileName]: '',
  [mappingFileName]: '',
};

const SUPPORTED_FORMATS = ['txt', 'csv'];

const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

export default function useFormValidation() {
  const [isValid, setIsValid] = useState(false);
  const [errors, setErrors] = useState('');
  const [formData, setFormData] = useState(InitialForm);

  const formSchema = yup.object().shape({
    [organizationOwnerIdName]: yup.number(),
    [oneItemName]: yup.string(),
    [multipleItemName]: yup.string(),

    [emailAddressName]: yup.string().when(oneItemName, {
      is: (val) => val === 'one',
      then: () =>
        yup
          .string()
          .matches(emailRegex, 'Invalid email format')
          .required('Email Address is a required field'),
    }),
    [fileName]: yup.string().when(oneItemName, {
      is: (val) => val === 'one',
      then: () => yup.string().required('File name is a required field'),
    }),
  });

  const validate = async (value) => {
    await formSchema.validate(value, { abortEarly: false }).catch(function (err) {
      const errorLogs = err.inner.reduce((acc, error) => {
        return {
          ...acc,
          [error.path]: error.message,
        };
      }, {});
      setErrors(errorLogs);
      return errorLogs;
    });
    const validation = await formSchema.isValid(value);
    if (validation) {
      setErrors({});
    }
    setIsValid(validation);
  };

  const validateFile = async (value) => {
    const mappingFile = value?.mappingFile;
    const multipleItemNameState = value?.multipleItemName;
    const imageType = mappingFile?.name?.split('.')?.pop();

    if (multipleItemNameState === 'multi') {
      if (!mappingFile) {
        setErrors({ mappingFile: 'Mapping file is a required field' });
        setIsValid(false);
      } else if (!SUPPORTED_FORMATS?.includes(imageType)) {
        setErrors({ mappingFile: 'Not a valid type' });
        setIsValid(false);
      } else {
        setErrors({});
        setIsValid(true);
      }
    }
  };

  const validateAll = () => {
    const objectOne = {
      mappingFile: formData?.mappingFile,
      multipleItemName: formData?.multipleItemName,
      organizationOwnerId: formData?.organizationOwnerId,
    };
    const objectTwo = {
      oneItemName: formData?.oneItemName,
      organizationOwnerId: formData?.organizationOwnerId,
      emailAddressName: formData?.emailAddressName,
      fileName: formData?.fileName,
    };

    if (formData?.multipleItemName) {
      validateFile(objectOne);
    } else if (formData?.oneItemName) {
      validate(objectTwo);
    }
  };

  const updateFormData = (newData) => {
    setFormData((prev) => {
      return { ...prev, ...newData };
    });
  };

  const organizationOwnerIdAttributes = {
    handleChange: async (value) => {
      updateFormData({ [organizationOwnerIdName]: value });
    },
    errorMessage: errors[organizationOwnerIdName],
    value: formData[organizationOwnerIdName],
  };

  const mappingFileAttributes = {
    handleChange: async (value) => {
      updateFormData({ [mappingFileName]: value });
    },
    errorMessage: errors[mappingFileName],
    value: formData[mappingFileName],
  };

  const oneItemStructureAttributes = {
    handleChange: async (value) => {
      updateFormData({ [oneItemName]: value });
    },
    errorMessage: errors[oneItemName],
    value: formData[oneItemName],
  };
  const multipleItemStructureAttributes = {
    handleChange: async (value) => {
      updateFormData({ [multipleItemName]: value });
    },
    errorMessage: errors[multipleItemName],
    value: formData[multipleItemName],
  };
  const emailAccountStructureAttributes = {
    handleChange: async (value) => {
      updateFormData({ [emailAddressName]: value });
    },
    errorMessage: errors[emailAddressName],
    value: formData[emailAddressName],
  };

  const fileNameStructureAttributes = {
    handleChange: async (value) => {
      updateFormData({ [fileName]: value });
    },
    errorMessage: errors[fileName],
    value: formData[fileName],
  };

  const resetForm = () => {
    setFormData(InitialForm);
    setErrors({});
    setIsValid(false);
  };

  return {
    formData,
    organizationOwnerIdAttributes,
    oneItemStructureAttributes,
    multipleItemStructureAttributes,
    emailAccountStructureAttributes,
    fileNameStructureAttributes,
    mappingFileAttributes,
    validateAll,
    isValid,
    resetForm,
  };
}
