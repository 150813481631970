import { useDispatch } from 'react-redux';
import { constant } from '@config/BaseSetting';
import useLocalStorage from '@hooks/useLocalStorage';
import { validateTransferPartnerAsync } from '@features/transferPartner/validateTransferPartnerSlice';
import { submitTransferPartnerAsync } from '@features/transferPartner/submitTransferPartnerSlice';

export default function useTransferSubresellerNewService() {
  const dispatch = useDispatch();
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);

  const checkValueExists = (data) => {
    const {
      transferType,
      effectiveTrasferDate,
      newPriceArchiver,
      newPriceBackup,
      resellerIDToBeTransferred,
      targetCurrency,
      targetDistributorID,
      sourceDistributorID,
      targetOwner,
      targetChannel,
    } = data;

    const requiredProperties = {
      newPriceArchiver,
      newPriceBackup,
      resellerIDToBeTransferred,
      targetCurrency,
      targetDistributorID,
      sourceDistributorID,
      targetOwner,
      targetChannel,
      effectiveTrasferDate,
    };

    return Object.keys(requiredProperties).every((property) => {
      if (property === 'targetDistributorID' && transferType === 3) {
        return true;
      }

      if (property === 'sourceDistributorID' && transferType === 2) {
        return true;
      }

      return !!requiredProperties[property];
    });
  };

  const handleValidateTransferPartnerAsync = (parameters) => {
    dispatch(
      validateTransferPartnerAsync({
        accessToken: accessTokenSet,
        reset: true,
        parameters,
      })
    );
  };

  const handleSubmitTransferPartnerAsync = (parameters) => {
    dispatch(
      submitTransferPartnerAsync({
        accessToken: accessTokenSet,
        reset: true,
        parameters,
      })
    );
  };

  return { checkValueExists, handleValidateTransferPartnerAsync, handleSubmitTransferPartnerAsync };
}
