import { createAsyncThunk } from '@reduxjs/toolkit';
import { createMailBoxSyncAPI } from '@services/WebApiService';

const mailBoxSyncAsync = createAsyncThunk('Change/mailboxSync', async (paramsList) => {
  try {
    const data = await createMailBoxSyncAPI(paramsList);
    return data;
  } catch (error) {
    return error.response;
  }
});

const { pending, fulfilled, rejected } = mailBoxSyncAsync;

const mailboxSyncReducers = {
  [pending]: (state) => {
    state.mailSyncState.fetching = true;
  },
  [rejected]: (state) => {
    state.mailSyncState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.mailSyncState.fetching = false;
    state.mailSyncState.statusCode = action?.payload?.status;
    state.mailSyncState.errorMessage = action?.payload?.response?.data?.message;

    if (action?.payload?.data?.title) {
      state.mailSyncState.errorMessage = action?.payload?.data?.title;
    }

    if (action?.payload?.status === 403) {
      state.mailSyncState.errorMessage = 'You are not authorize to access this resource';
    }

    if (!action?.payload) {
      state.mailSyncState.errorMessage = 'Something went wrong while processing your request.';
    }

    if (action?.payload?.code) {
      state.mailSyncState.error = action.payload.code;
    } else {
      state.mailSyncState.success = true;
    }
  },
};

export { mailBoxSyncAsync, mailboxSyncReducers };

export default mailBoxSyncAsync;
