import { createSlice } from '@reduxjs/toolkit';
import {
  m365TenantDetailAsync,
  m365TenantDetailExtraReducers,
} from './serviceActions/m365TenantDetailAsync';

const initialState = {
  m365TenantDetailState: {
    submitting: false,
    success: false,
    data: {},
    error: null,
    statusCode: null,
    errorMessage: null,
  },
};

const m365TenantDetailSlice = createSlice({
  name: 'M365TenantDetail',
  initialState,
  reducers: {
    resetM365TenantDetail(state) {
      state.m365TenantDetailState = initialState.m365TenantDetailState;
    },
  },
  extraReducers: {
    ...m365TenantDetailExtraReducers,
  },
});

export { m365TenantDetailAsync };
export const { resetM365TenantDetail } = m365TenantDetailSlice.actions;
export default m365TenantDetailSlice.reducer;
