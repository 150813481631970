import * as yup from 'yup';
import SchemaBuilder from './SchemaBuilder';

class YupSchemaBuilder extends SchemaBuilder {
  /* eslint-disable class-methods-use-this */
  // client of service
  client = yup;

  getClient() {
    return this.client;
  }

  object() {
    return this.client.object();
  }

  string() {
    return this.client.string();
  }

  number() {
    return this.client.number();
  }

  date() {
    return this.client.date();
  }

  array() {
    return this.client.array();
  }
}

export default YupSchemaBuilder;
