import { createSlice } from '@reduxjs/toolkit';
import {
  fetchMailboxBulkPurgeAsync,
  mailboxBulkPurgeReducers,
} from './serviceActions/fetchMailboxBulkPurgeAsync';

const initialState = {
  mailboxBulkPurgeListState: {
    fetching: false,
    bulkPurgeListData: [],
    errorMessage: null,
    pagination: [],
    statusCode: null,
    forbiddenState: false,
    forbiddenErrorMessage: {},
    bulkPurgeListCorsErrMessage: null,
  },
};

const mailboxBulkPurgeListSlice = createSlice({
  name: 'MailboxBulkPurge',
  initialState,
  reducers: {
    resetMailboxBulkPurgeStatus(state) {
      state.mailboxBulkPurgeListState = initialState.mailboxBulkPurgeListState;
    },
    resetMailboxBulkPurgeStatusData(state) {
      state.mailboxBulkPurgeListState.bulkPurgeListData =
        initialState.mailboxBulkPurgeListState.bulkPurgeListData;
    },
  },
  extraReducers: {
    ...mailboxBulkPurgeReducers,
  },
});

export { fetchMailboxBulkPurgeAsync };
export const { resetMailboxBulkPurgeStatus, resetMailboxBulkPurgeStatusData } =
  mailboxBulkPurgeListSlice.actions;
export default mailboxBulkPurgeListSlice.reducer;
