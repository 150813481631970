import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ITBox, QboAlert, QboLabelGroup, QboTypography } from '@ui/Components';

export default function BulkCreateSubResellerConfirmation({ selectedPartner }) {
  if (!selectedPartner) return null;
  const { t } = useTranslation();

  return (
    <>
      <QboAlert
        variant="outlined"
        type="info"
        style={{
          border: '1px solid #ebebeb',
          background: '#f3f3f3',
          fontSize: '10px !important',
          marginTop: '20px',
        }}>
        <QboTypography
          noWrap={false}
          style={{ fontSize: '13px', color: '#767676', whiteSpace: 'pre-line' }}>
          {t('partner_list_page.bulk_create_subreseller.confirmation')}
          <br />
          <strong>{t('partner_list_page.bulk_create_subreseller.template_info')}</strong>
          <br />
          <strong>{t('partner_list_page.bulk_create_subreseller.additional_info')}</strong>
        </QboTypography>
      </QboAlert>
      <ITBox
        className="TransferSubresellerPage__information-box"
        direction="column"
        sx={{ marginBottom: '20px' }}>
        <QboLabelGroup title="Partner ID" description={selectedPartner?.id?.toString() || '-'} />
        <QboLabelGroup
          title="Partner Name"
          description={selectedPartner?.name?.toString() || '-'}
        />
      </ITBox>
    </>
  );
}

BulkCreateSubResellerConfirmation.propTypes = {
  selectedPartner: PropTypes.object,
};

BulkCreateSubResellerConfirmation.defaultProps = {
  selectedPartner: null,
};
