import { useState } from 'react';
import * as yup from 'yup';

const organizationOwnerIdName = 'organizationOwnerId';
const protocolName = 'protocol';
const credentialIdName = 'credentialId';

const InitialForm = {
  [organizationOwnerIdName]: '',
  [protocolName]: '',
  [credentialIdName]: null,
};

export default function useFormValidation() {
  const [isValid, setIsValid] = useState(false);
  const [errors, setErrors] = useState('');
  const [formData, setFormData] = useState(InitialForm);

  const formSchema = yup.object().shape({
    [organizationOwnerIdName]: yup
      .number()
      .typeError('Organization Owner Id is a required field and must be a number type')
      .required(),
  });

  const validate = async (value) => {
    // eslint-disable-next-line func-names
    await formSchema.validate(value, { abortEarly: false }).catch(function (err) {
      const errorLogs = err.inner.reduce((acc, error) => {
        return {
          ...acc,
          [error.path]: error.message,
        };
      }, {});
      setErrors(errorLogs);
      return errorLogs;
    });
    const validation = await formSchema.isValid(value);
    if (validation) {
      setErrors({});
    }
    setIsValid(validation);
  };

  const validateAll = () => {
    validate(formData);
  };

  const updateFormData = (newData) => {
    setFormData((prev) => {
      return { ...prev, ...newData };
    });
  };

  const organizationOwnerIdAttributes = {
    handleChange: async (value) => {
      updateFormData({ [organizationOwnerIdName]: value });
    },
    errorMessage: errors[organizationOwnerIdName],
    value: formData[organizationOwnerIdName],
  };

  const credentialIdAttributes = {
    handleChange: async (value) => {
      updateFormData({ [credentialIdName]: value });
    },
    errorMessage: errors[credentialIdName],
    value: formData[credentialIdName] ? formData[credentialIdName] : '',
  };

  const protocolAttributes = {
    handleChange: async (value) => {
      updateFormData({ [protocolName]: value });
      if (value === '0') {
        credentialIdAttributes.handleChange(null);
        setErrors({ credentialIdName: '' });
      }
    },
    errorMessage: errors[protocolName],
    value: formData[protocolName],
  };

  const resetForm = () => {
    setFormData(InitialForm);
    setErrors({});
    setIsValid(false);
  };

  return {
    formData,
    organizationOwnerIdAttributes,
    protocolAttributes,
    credentialIdAttributes,
    validateAll,
    isValid,
    resetForm,
  };
}
