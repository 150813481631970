//
// create slice
// as centeralize between actions and reducers
//
// serviceActions module contains reducers and actions that
// created by createAsyncThunk from redux-toolkit
//
// for standard actions, that require other business logic
// can be put inside extraActions module
//
// export default will be reducers it self that processed by store
// other exports will contains actions that's dispatched from ReactComponent
//

import { createSlice } from '@reduxjs/toolkit';

import { fetchTodosAysnc, fetchTodosExtraReducers } from './serviceActions/fetchTodosAysnc';

const initialState = {
  todos: [],
};

const todoSlice = createSlice({
  name: 'Account',
  initialState,
  reducers: {
    resetTodos(state) {
      state.todos = initialState.todos;
    },
  },
  extraReducers: {
    ...fetchTodosExtraReducers,
  },
});

export { fetchTodosAysnc };
export const { resetTodos } = todoSlice.actions;
export default todoSlice.reducer;
