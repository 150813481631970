import { createSlice } from '@reduxjs/toolkit';

import {
  getExcludedMailboxesAsync,
  getExcludedMailboxesExtraReducers,
} from './serviceActions/fetchExcludedMailboxesAsync';

const initialState = {
  getExcludedMailboxesState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    excludedMailboxesList: [],
    paginationList: {},
    forbiddenState: false,
    forbiddenErrorMessage: null,
    excludedCorsErrorMessage: null,
  },
};

const getExcludedMailboxesSlice = createSlice({
  name: 'ExcludedMailboxes',
  initialState,
  reducers: {
    resetExcludedMailboxesState(state) {
      state.getExcludedMailboxesState = initialState.getExcludedMailboxesState;
    },
  },
  extraReducers: {
    ...getExcludedMailboxesExtraReducers,
  },
});

export { getExcludedMailboxesAsync };
export const { resetExcludedMailboxesState } = getExcludedMailboxesSlice.actions;
export default getExcludedMailboxesSlice.reducer;
