/* eslint-disable import/prefer-default-export */
export const CREATE_ORGANIZATION_HEADERS = [
  { label: 'Email', columnName: 'email' },
  { label: 'First Name', columnName: 'first_name' },
  { label: 'Last Name', columnName: 'last_name' },
  { label: 'Organization Name', columnName: 'organization_name' },
  { label: 'Seats', columnName: 'seats' },
  { label: 'Auto License', columnName: 'auto_license' },
  { label: 'Plan', columnName: 'plan' },
];
