import { constant } from '@config/BaseSetting';
import useLocalStorage from '@hooks/useLocalStorage';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { authenticateAPI } from '@services/WebApiService';

const getAccessTokenAsync = createAsyncThunk('Token/getAccessToken', async (paramsList) => {
  try {
    const data = await authenticateAPI(paramsList);
    return data;
  } catch (error) {
    return error.response;
  }
});

const [storeAccessToken, setStoreAccessToken] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
const { pending, fulfilled, rejected } = getAccessTokenAsync;

const getAccessTokenReducers = {
  [pending]: (state) => {
    state.getAccessTokenState.fetching = true;
  },
  [rejected]: (state) => {
    state.getAccessTokenState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.getAccessTokenState.fetching = false;

    if (!action?.payload || action?.payload?.status === 500) {
      state.getAccessTokenState.tokenErrorMessage = 'Internal Server Error';
    }

    if (action?.payload?.data && action?.payload?.status !== 500) {
      state.getAccessTokenState.currentAccountStatus =
        action.payload.data['current-account-status'];
      state.getAccessTokenState.tokenErrorMessage = null;
      state.getAccessTokenState.tokenState = action?.payload?.data?.accessToken;
      setStoreAccessToken(action?.payload?.data?.accessToken);
      state.getAccessTokenState.statusCode = action.payload?.status;
      state.getAccessTokenState.success = true;
    }
  },
};

export { getAccessTokenAsync, getAccessTokenReducers };

export default getAccessTokenAsync;
