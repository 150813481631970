import { createSlice } from '@reduxjs/toolkit';

import {
  getTransferPartnerAsync,
  getTransferPartnerExtraReducers,
} from './serviceActions/getTransferPartnerAsync';

const initialState = {
  getTransferPartnerState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    paginationList: null,
    transferPartnerList: [],
    forbiddenState: false,
    forbiddenErrorMessage: null,
    transferPartnerCorsErrorMessage: null,
  },
};

const gettransferPartnerSlice = createSlice({
  name: 'transferPartnerList',
  initialState,
  reducers: {
    resettransferPartnerSubmit(state) {
      state.getTransferPartnerState = initialState.getTransferPartnerState;
    },
  },
  extraReducers: {
    ...getTransferPartnerExtraReducers,
  },
});

export { getTransferPartnerAsync };
export const { resettransferPartnerSubmit } = gettransferPartnerSlice.actions;
export default gettransferPartnerSlice.reducer;
