import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPartnerDetailAPI } from '@services/WebApiService';

const partnerDetailAsync = createAsyncThunk(
  'getPartnerDetail/partnerDetail',
  async (paramsList) => {
    try {
      const data = await getPartnerDetailAPI(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = partnerDetailAsync;

const partnerDetailReducers = {
  [pending]: (state) => {
    state.partnerDetailState.fetching = true;
  },
  [rejected]: (state) => {
    state.partnerDetailState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.partnerDetailState.fetching = false;

    if (action?.payload?.status === 403) {
      state.partnerDetailState.forbiddenState = true;
      state.partnerDetailState.forbiddenErrorMessage = {
        heading: 'Forbidden Error',
        caption: 'You are not authorize to access this resource',
      };
    }

    if (!action?.payload) {
      state.partnerDetailState.partnerDetailCorsErrorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.data?.data) {
      state.partnerDetailState.data = action?.payload?.data?.data;
      state.partnerDetailState.success = true;
      state.partnerDetailState.statusCode = action?.payload?.data['status-code'];
    }

    if (action?.payload?.error) {
      state.partnerDetailState.error = action.payload.error;
    }
  },
};

export { partnerDetailAsync, partnerDetailReducers };
