import { createSlice } from '@reduxjs/toolkit';

import { getAccessTokenAsync, getAccessTokenReducers } from './serviceActions/getAccessTokenAsync';

const initialState = {
  getAccessTokenState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    tokenState: null,
    tokenErrorMessage: null,
    currentAccountStatus: null,
  },
};

const accessTokenSlice = createSlice({
  name: 'AccessToken',
  initialState,
  reducers: {
    resetGetAccessTokenStatus(state) {
      state.getAccessTokenState = initialState.getAccessTokenState;
    },
  },
  extraReducers: {
    ...getAccessTokenReducers,
  },
});

export { getAccessTokenAsync };
export const { resetGetAccessTokenStatus } = accessTokenSlice.actions;
export default accessTokenSlice.reducer;
