import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMailboxBulkPurgeAPI } from '@services/WebApiService';

const fetchMailboxBulkPurgeAsync = createAsyncThunk(
  'BulkPurge/mailboxBulkPurge',
  async (paramsList) => {
    try {
      const data = await getMailboxBulkPurgeAPI(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = fetchMailboxBulkPurgeAsync;

const mailboxBulkPurgeReducers = {
  [pending]: (state) => {
    state.mailboxBulkPurgeListState.fetching = true;
  },
  [rejected]: (state) => {
    state.mailboxBulkPurgeListState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.mailboxBulkPurgeListState.fetching = false;

    if (action?.payload?.error) {
      state.mailboxBulkPurgeListState.errorMessage = action.payload.error?.data.message;
      state.mailboxBulkPurgeListState.statusCode = action.payload.error?.status;

      if (action?.payload?.error?.status === 403) {
        state.mailboxBulkPurgeListState.forbiddenState = true;
        state.mailboxBulkPurgeListState.forbiddenErrorMessage = {
          heading: 'Forbidden Error',
          caption: 'You are not authorize to access this resource',
        };
      }
    }

    if (!action?.payload?.error && !action?.payload?.data) {
      state.mailboxBulkPurgeListState.bulkPurgeCorsErrorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.data?.data) {
      state.mailboxBulkPurgeListState.forbiddenState = false;
      state.mailboxBulkPurgeListState.forbiddenErrorMessage = {};
      state.mailboxBulkPurgeListState.statusCode = action.payload?.status;
      state.mailboxBulkPurgeListState.bulkPurgeListData = action?.payload?.data?.data;

      if (action?.payload?.data?.pagination) {
        state.mailboxBulkPurgeListState.pagination = JSON.parse(action?.payload?.data?.pagination);
      }
    }
  },
};

export { fetchMailboxBulkPurgeAsync, mailboxBulkPurgeReducers };

export default fetchMailboxBulkPurgeAsync;
