import { createAsyncThunk } from '@reduxjs/toolkit';
import { exportUserDiscrepancyReportAPI } from '@services/WebApiService';

const exportUserDiscrepancyReportAsync = createAsyncThunk(
  'userDiscrepancyReport/exportUserDiscrepancyReport',
  async (paramsList) => {
    try {
      const data = await exportUserDiscrepancyReportAPI(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = exportUserDiscrepancyReportAsync;

const exportUserDiscrepancyReportExtraReducers = {
  [pending]: (state) => {
    state.exportUserDiscrepancyReportState.fetching = true;
  },
  [rejected]: (state) => {
    state.exportUserDiscrepancyReportState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.exportUserDiscrepancyReportState.fetching = false;

    if (action?.payload?.status === 403) {
      state.exportUserDiscrepancyReportState.forbiddenState = true;
      state.exportUserDiscrepancyReportState.forbiddenErrorMessage = {
        heading: 'Forbidden Error',
        caption: 'You are not authorize to access this resource',
      };
    }

    if (!action?.payload) {
      state.exportUserDiscrepancyReportState.extportUserDiscrepancyReportCorsErrorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.data) {
      state.exportUserDiscrepancyReportState.data = action?.payload?.data;
      state.exportUserDiscrepancyReportState.contentType = action?.payload?.headers['content-type'];
      state.exportUserDiscrepancyReportState.success = true;
      state.exportUserDiscrepancyReportState.statusCode = action?.payload?.data['status-code'];
    }

    if (action?.payload?.error) {
      state.exportUserDiscrepancyReportState.error = action.payload.error;
    }
  },
};

export { exportUserDiscrepancyReportAsync, exportUserDiscrepancyReportExtraReducers };
