import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { QboTypography } from '@ui/Components';
import { dataIngestionPath } from '@config/Routes/WebClientRoutes';
import BaseSetting from '@config/BaseSetting';
// import useIngestionReportsSection from './pageHooks/useIngestionReportsSection';
import ReportsPage from './ReportsPage';

function IngestionReportsPageSection() {
  const { state } = useLocation();
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  // const { handleFetchIngestionReportList } = useIngestionReportsSection();
  const { getIngestionReportListState } = useSelector(
    (stateList) => stateList.ingestionReportsState
  );

  const { fetching, reportsPaginationList, ingestionReportsList } = getIngestionReportListState;

  const fetchHandler = () => {
    if (!fetching) {
      // handleFetchIngestionReportList(state?.row, pagination.pageIndex + 1, pagination.pageSize);
    }
  };

  const populateTitle = () => {
    return (
      <QboTypography sx={{ color: '#42526E' }} className="HeaderPageBlock__heading" variant="h4">
        {t('reports_page.header.text')}({t('reports_page.header.ingestion_id')}
        {state?.row})
      </QboTypography>
    );
  };

  useEffect(() => {
    fetchHandler();
  }, [pagination.pageIndex, pagination.pageSize]);

  useEffect(() => {
    if (state) {
      setShowModal(true);
    }
  }, [state]);

  return (
    <ReportsPage
      showModal={showModal}
      setShowModal={setShowModal}
      pageTitle={populateTitle()}
      reportsData={ingestionReportsList}
      fetching={fetching}
      headersList={BaseSetting.ingestionReportsListHeaders}
      pagination={pagination}
      setPagination={setPagination}
      paginationList={reportsPaginationList}
      ingestionID={state?.row}
      path={dataIngestionPath}
    />
  );
}

export default IngestionReportsPageSection;
