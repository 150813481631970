import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import EditNoteIcon from '@mui/icons-material/EditNote';
import AddIcon from '@mui/icons-material/Add';

import { QboTooltip, QboTypography } from '.';

export default function QboTitleWithCollapsableButton(props) {
  const {
    flagType,
    title,
    handleOnIconButtonClick,
    open,
    variant,
    isEditEnable,
    iconHeight,
    iconWidth,
    style,
    collapseVisibility,
    sx,
    marginRightIconButton,
  } = props;

  const hw = { width: iconWidth, height: iconHeight };

  return (
    <div
      style={{
        backgroundColor: '#dee9ed',
        alignItems: 'center',
        ...style,
      }}>
      <QboTypography variant={variant} sx={{ marginLeft: 1, ...sx }}>
        {title}
      </QboTypography>
      <div style={{ marginRight: marginRightIconButton }}>
        {/* <QboTooltip title={open ? 'Collapse' : 'open'}> */}
        <IconButton
          sx={{ visibility: collapseVisibility }}
          onClick={() => handleOnIconButtonClick(flagType)}
          style={hw}>
          {open ? <RemoveIcon /> : <AddIcon />}
        </IconButton>
        {/* </QboTooltip> */}
        {isEditEnable && (
          <QboTooltip title="Edit">
            <IconButton style={hw}>
              <EditNoteIcon />
            </IconButton>
          </QboTooltip>
        )}
      </div>
    </div>
  );
}

QboTitleWithCollapsableButton.propTypes = {
  title: PropTypes.string,
  marginRightIconButton: PropTypes.string,
  flagType: PropTypes.string,
  handleOnIconButtonClick: PropTypes.func,
  open: PropTypes.bool,
  collapseVisibility: PropTypes.string,
  variant: PropTypes.string,
  isEditEnable: PropTypes.bool,
  iconHeight: PropTypes.number,
  iconWidth: PropTypes.number,
  style: PropTypes.object,
  sx: PropTypes.object,
};

QboTitleWithCollapsableButton.defaultProps = {
  title: '',
  marginRightIconButton: '3%',
  flagType: '',
  handleOnIconButtonClick: () => {},
  open: false,
  collapseVisibility: '',
  variant: '',
  isEditEnable: false,
  iconHeight: 50,
  iconWidth: 50,
  style: {},
  sx: {},
};
