import { createSlice } from '@reduxjs/toolkit';
import {
  getResellerNotificationsConfigAsync,
  getResellerNotificationsConfigReducers,
} from './serviceActions/getResellerNotificationsConfigAsync';

const initialState = {
  getResellerNotificationsConfigState: {
    fetching: false,
    success: false,
    resellerNotificationsConfigData: {},
    error: null,
    errorMessage: null,
    forbiddenState: false,
    forbiddenErrorMessage: {},
    getResellerNotificationsConfigCorsErrorMessage: null,
  },
};

const getResellerNotificationsConfigSlice = createSlice({
  name: 'GetResellerNotificationsConfig',
  initialState,
  reducers: {
    resetResellerNotificationsConfig(state) {
      state.getResellerNotificationsConfigState = initialState.getResellerNotificationsConfigState;
    },
  },
  extraReducers: {
    ...getResellerNotificationsConfigReducers,
  },
});

export { getResellerNotificationsConfigAsync };
export const { resetResellerNotificationsConfig } = getResellerNotificationsConfigSlice.actions;
export default getResellerNotificationsConfigSlice.reducer;
