import { createSlice } from '@reduxjs/toolkit';
import {
  fetchSeatUsageReportsSummaryAsync,
  seatUsageReportsSummaryReducers,
} from './serviceActions/fetchSeatUsageReportsSummaryAsync';

const initialState = {
  seatUsageSummaryState: {
    fetching: false,
    seatUsageSummaryData: [],
    errorMessage: null,
    summaryStatusCode: null,
    forbiddenState: false,
    forbiddenErrorMessage: {},
    corsErrorMessage: null,
  },
};

const seatUsageSummarySlice = createSlice({
  name: 'SeatUsageSummaryList',
  initialState,
  reducers: {
    resetSeatUsageSummaryStatus(state) {
      state.seatUsageSummaryState = initialState.seatUsageSummaryState;
    },
    resetSeatUsageSummaryListStatus(state) {
      state.seatUsageSummaryState.seatUsageSummaryData =
        initialState.seatUsageSummaryState.seatUsageSummaryData;
    },
    resetSeatUsageSummaryErrorStatus(state) {
      state.seatUsageSummaryState.errorMessage = initialState.seatUsageSummaryState.errorMessage;
    },
  },
  extraReducers: {
    ...seatUsageReportsSummaryReducers,
  },
});

export { fetchSeatUsageReportsSummaryAsync };
export const {
  resetSeatUsageSummaryStatus,
  resetSeatUsageSummaryListStatus,
  resetSeatUsageSummaryErrorStatus,
} = seatUsageSummarySlice.actions;
export default seatUsageSummarySlice.reducer;
