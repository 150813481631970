import { createSlice } from '@reduxjs/toolkit';
import {
  getBulkCreateOrganizationItemsAsync,
  getBulkCreateOrganizationItemsExtraReducers,
} from './serviceActions/getBulkCreateOrganizationItemsAsync';

const initialState = {
  getBulkCreateOrganizationItemsState: {
    fetching: false,
    statusCode: null,
    list: [],
    errorMessage: null,
    pagination: {},
    forbiddenState: false,
    forbiddenErrorMessage: null,
    corsErrorMessage: null,
  },
};

const getBulkCreateOrganizationItemsSlice = createSlice({
  name: 'getBulkCreateOrganizationItems',
  initialState,
  reducers: {
    resetGetBulkCreateOrganizationItemsState(state) {
      state.getBulkCreateOrganizationItemsState = initialState.getBulkCreateOrganizationItemsState;
    },
  },
  extraReducers: {
    ...getBulkCreateOrganizationItemsExtraReducers,
  },
});

export { getBulkCreateOrganizationItemsAsync };

export const { resetGetBulkCreateOrganizationItemsState } =
  getBulkCreateOrganizationItemsSlice.actions;
export default getBulkCreateOrganizationItemsSlice.reducer;
