export const CONTACT = 'contact';
export const CONTACT_COLUMNS = [
  { label: 'ID', columnName: 'userBackupId' },
  {
    label: 'Email',
    columnName: 'email',
    size: 'sm',
  },
  {
    label: 'Mailbox Display Name',
    columnName: 'generatedName',
    size: 'sm',
  },
  {
    label: 'Organization Name',
    columnName: 'organizationName',
    size: 'sm',
  },
  {
    label: 'Partner Name',
    columnName: 'partnerName',
    size: 'sm',
  },
  {
    label: 'Parent Name',
    columnName: 'distributorName',
    size: 'sm',
  },
  { label: 'Host', columnName: 'host', size: 'sm' },
  { label: 'Protocol', columnName: 'protocol', size: 'sm' },
  { label: 'Last Backup At', columnName: 'lastBackupAt', size: 'sm' },
  { label: 'Last Backup Status', columnName: 'lastBackupStatus', size: 'sm' },
  {
    label: 'Last Backup Successful At',
    columnName: 'lastBackupSuccessfulAt',
    size: 'sm',
  },
  { label: 'Next CCT Backup At', columnName: 'nextBackupAt', size: 'sm' },
  { label: 'M365 ID / GWS ID', columnName: 'm365Id', size: 'sm' },
  { label: 'Tenant ID', columnName: 'tenantId', size: 'sm' },
  { label: 'Contact Numbers', columnName: 'contactCount', size: 'sm' },
  { label: 'Contact Size in KB', columnName: 'contactSize', size: 'sm' },
  { label: 'Created At', columnName: 'createdAt', size: 'sm' },
  { label: 'Last Data Updated At', columnName: 'updatedAt', size: 'sm' },
  { label: 'Deactivated Since', columnName: 'deactivatedSince', size: 'sm' },
  { label: 'Deactivated By', columnName: 'deactivatedBy', size: 'sm' },
  { label: 'Contact Deactivated Since', columnName: 'cctDeactivatedSince', size: 'sm' },
  { label: 'Contact Deactivated By', columnName: 'cctDeactivatedBy', size: 'sm' },
  { label: 'Deleted At', columnName: 'deletedAt', size: 'sm' },
  { label: 'Deleted By', columnName: 'deletedBy', size: 'sm' },
  { label: 'Data Deleted At', columnName: 'dataDeletedAt', size: 'sm' },
];
export const CONTACT_DETAIL_ROWS = {
  userBackupId: 'Email Account ID',
  email: 'Email',
  generatedName: 'Mailbox Display Name',
  organizationName: 'Organization Name',
  partnerName: 'Partner Name',
  distributorName: 'Parent Name',
  host: 'Host',
  protocol: 'Protocol',
  m365Id: 'M365 ID / GWS ID',
  tenantId: 'Tenant ID',
  lastBackupId: 'Last Backup ID',
  lastBackupAt: 'Last Backup At',
  lastBackupSuccessfulAt: 'Last Successful Backup At',
  nextBackupAt: 'Next CCT Backup At',
  contactCount: 'Contact Numbers',
  contactSize: 'Contact Size in KB',
  deactivatedSince: 'Deactivated Since',
  deactivatedBy: 'Deactivated By',
  cctDeactivatedSince: 'Contact Deactivated Since',
  cctDeactivatedBy: 'Contact Deactivated By',
  deletedAt: 'Deleted At',
  deletedBy: 'Deleted By',
  dataDeletedAt: 'Data Deleted At',
  createdAt: 'Created At',
  updatedAt: 'Last Data Updated At',
};
export const CONTACT_LAST_BACKUP_ROWS = {
  backupId: 'Last Contact Backup ID',
  userBackupId: 'Email Account ID',
  startTime: 'Start Backup Time',
  endTime: 'End Backup Time',
  status: 'Backup Status',
  backupStorage: 'Backup Storage KB',
  currentMessagesCount: 'Current Items Numbers',
  totalMessagesCount: 'Total Items Numbers',
  retries: 'Retries Time',
  errorMessage: 'Error Message',
  machineIP: 'Run on Machine IP',
  createdAt: 'Created At',
  updatedAt: 'Last Updated At',
};
