import { createAsyncThunk } from '@reduxjs/toolkit';
import { exportMonthlyBackupReportAPI } from '@services/WebApiService';

const exportMonthlyBackupReportAsync = createAsyncThunk(
  'MonthlyBackup/monthlyBackupReportExport',
  async (paramsList) => {
    try {
      const data = await exportMonthlyBackupReportAPI(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = exportMonthlyBackupReportAsync;

const exportMonthlyBackupReportReducers = {
  [pending]: (state) => {
    state.exportMonthlyBackupReportState.fetching = true;
  },
  [rejected]: (state) => {
    state.exportMonthlyBackupReportState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.exportMonthlyBackupReportState.fetching = false;

    if (action?.payload?.error) {
      state.exportMonthlyBackupReportState.errorMessage = action.payload.error?.data.message;
      state.exportMonthlyBackupReportState.statusCode = action.payload.error?.status;

      if (action?.payload?.error?.status === 403) {
        state.exportMonthlyBackupReportState.forbiddenState = true;
        state.exportMonthlyBackupReportState.forbiddenErrorMessage = {
          heading: 'Forbidden Error',
          caption: 'You are not authorize to access this resource',
        };
      }
    }

    if (!action?.payload?.error && !action?.payload?.data) {
      state.exportMonthlyBackupReportState.errorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.data) {
      state.exportMonthlyBackupReportState.forbiddenState = false;
      state.exportMonthlyBackupReportState.forbiddenErrorMessage = {};
      state.exportMonthlyBackupReportState.statusCode = action.payload?.status;
      state.exportMonthlyBackupReportState.monthlyBackupExportData = action?.payload?.data;
    }
  },
};

export { exportMonthlyBackupReportAsync, exportMonthlyBackupReportReducers };

export default exportMonthlyBackupReportAsync;
