import { createSlice } from '@reduxjs/toolkit';

import {
  getOrganizationUsersList,
  getOrganizationUsersListExtraReducers,
} from './serviceActions/getOrganizationUsersList';

const initialState = {
  getOrganizationUsersListState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    paginationList: null,
    usersList: [],
    forbiddenState: false,
    forbiddenErrorMessage: null,
    usersListCorsErrorMessage: null,
  },
};

const organizationUsersListSlice = createSlice({
  name: 'UsersList',
  initialState,
  reducers: {
    resetUsersListState(state) {
      state.getOrganizationUsersListState = initialState.getOrganizationUsersListState;
    },
    resetUsersListStatusCodeState(state) {
      state.getOrganizationUsersListState.statusCode =
        initialState.getOrganizationUsersListState.statusCode;
    },
  },
  extraReducers: {
    ...getOrganizationUsersListExtraReducers,
  },
});

export { getOrganizationUsersList };
export const { resetUsersListState, resetUsersListStatusCodeState } =
  organizationUsersListSlice.actions;
export default organizationUsersListSlice.reducer;
