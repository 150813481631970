import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';

import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import {
  ITBox,
  QboAdvanceTable,
  QboButton,
  QboCard,
  QboFormGroup,
  QboSnackbar,
  QboTextField,
} from '@ui/Components';
import BaseSetting from '@config/BaseSetting';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import QboSortByButton from '@ui/Components/QboSortByButton';
import QboTypography from '@ui/Components/QboTypography';
import { Button } from '@mui/material';
import { resetOrganizationsListState } from '@features/organizationList/organizationsListSlice';
import {
  setOrganizationPaginations,
  setOrganizationTabState,
} from '@features/application/applicationSlice';
import PartnerFilterSection from '@ui/Sections/Shared/PartnerFilterSection';
import useDownload from '@hooks/useDownload';
import useFeatureFlag from '@hooks/useFeatureFlag';
import useOrganizationListService from './pageHooks/useOrganizationListService';
import useDataService from './pageHooks/useDataService';

export default function OrganizationListPage(props) {
  const { resellerID, tabFlag, selectTab, plan, resellerIDProp } = props;
  const { hasPermission, featureFlags } = useFeatureFlag();
  const { selectedTabState, paginationState } = useSelector((state) => state.application);
  const { getOrganizationsListState } = useSelector((state) => state.organizationsList);
  const { exportOrganizationsState } = useSelector((state) => state.exportOrganizations);
  const { organizationsList, fetching, paginationList, statusCode } = getOrganizationsListState;
  const { organizationPaginations } = paginationState;

  const { t } = useTranslation();
  const [pagination, setPagination] = useState({
    pageIndex: organizationPaginations?.pageIndex || 0,
    pageSize: organizationPaginations?.pageSize || 10,
  });
  const dispatch = useDispatch();
  const previousPropsRef = useRef(pagination);

  const filterPlanName = plan ?? '';

  const [snackbarState, setSnackbarState] = useState({ open: false });
  const [selectedColumn, setSelectedColumn] = useState('');
  const [searchFieldValue, setSearchFieldValue] = useState('');
  const [searchIDFieldValue, setSearchIDFieldValue] = useState(0);
  const [fullTextSearchValue, setFullTextSearch] = useState('');
  const [advanceClick, setAdvanceClick] = useState(false);
  const [resetClicked, setResetClicked] = useState(false);
  const [matchText, setMatchText] = useState('');
  const [adFilterResellerId, setAdFilterResellerId] = useState('');
  const [adFilterStatus, setAdFilterStatus] = useState('');
  const [adFilterAutoLicence, setAdFilterAutoLicense] = useState('');
  const [adFilterLicece, setAdFilterLicense] = useState('');
  const [adFilterStorageLocation, setAdFilterStorageLocation] = useState('');
  const [adFilterCountry, setAdFilterCountry] = useState('');
  const [adFilter, setAdFilter] = useState([
    { field: 'filter_status', value: '' },
    { field: 'filter_autoLicense', value: '' },
    { field: 'filter_license', value: '' },
    { field: 'filter_partner', value: '' },
    { field: 'filter_plan', value: filterPlanName },
    { field: 'filter_country', value: '' },
    { field: 'filter_storageLocation', value: '' },
  ]);

  const {
    buttonStyle,
    statusItemList,
    autoLicenseItemList,
    licenseTypeItemList,
    countryItemList,
    columnNames,
    storageLocationItemList,
    clickableCoulmnList,
  } = useDataService();

  useEffect(() => {
    if (!advanceClick) {
      setAdFilterStatus('');
      setAdFilterAutoLicense('');
      setAdFilterLicense('');
      setAdFilterCountry('');
      setAdFilter([
        { field: 'filter_status', value: '' },
        { field: 'filter_autoLicense', value: '' },
        { field: 'filter_license', value: '' },
        { field: 'filter_partner', value: '' },
        { field: 'filter_plan', value: filterPlanName },
        { field: 'filter_country', value: '' },
        { field: 'filter_storageLocation', value: '' },
      ]);
      setAdFilterResellerId('');
      setSelectedColumn('');
    }
  }, [advanceClick]);

  const {
    fetchParams,
    handleOnAdvanceSearchClick,
    handleOnSortByChange,
    matchingText,
    handleFetchOrganizationsListAsync,
    handleExport,
    handleResetExport,
  } = useOrganizationListService(
    setAdvanceClick,
    advanceClick,
    setSelectedColumn,
    searchIDFieldValue,
    fullTextSearchValue,
    resellerID ?? adFilterResellerId,
    pagination,
    adFilter,
    selectedColumn
  );

  useDownload(
    exportOrganizationsState.organizationExportData,
    'text/csv;charset=utf-8',
    fetchParams?.reseller
      ? `reseller_${fetchParams.reseller}_organizations.csv`
      : 'organizations.csv',
    handleResetExport,
    () => setSnackbarState({ open: true, type: 'success', message: 'Successfully downloaded' }),
    (err) => setSnackbarState({ open: true, type: 'error', message: err })
  );

  useEffect(() => {
    if ((selectTab === 0 || selectTab) && selectedTabState?.organizationTabState !== selectTab) {
      dispatch(setOrganizationTabState(selectTab));
    }
  }, [selectTab]);

  const handleUpdateFilters = (label, val) => {
    const newList = adFilter.map((ele) => {
      if (ele.field === label) {
        return { ...ele, value: val };
      }
      return ele;
    });
    setAdFilter([...newList]);
  };

  const fetchApiHandler = (flag, paginationFlag) => {
    if (!fetching) {
      handleFetchOrganizationsListAsync(flag, paginationFlag);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarState({ ...snackbarState, open: false });
  };

  const buildMatchText = () => {
    setMatchText('');
    matchingText(setMatchText);
  };

  const handleOnSearchClick = async (e) => {
    setResetClicked(false);
    e.preventDefault();
    setPagination({ pageIndex: 0, pageSize: 10 });
    fetchApiHandler(false, true);
    buildMatchText();
  };

  const resetFilterStateHandler = () => {
    setMatchText('');
    setAdFilterStatus('');
    setAdFilterCountry('');
    setAdFilterLicense('');
    setAdFilterAutoLicense('');
    setAdFilterStorageLocation('');
    setAdFilter([
      { field: 'filter_status', value: '' },
      { field: 'filter_autoLicense', value: '' },
      { field: 'filter_license', value: '' },
      { field: 'filter_partner', value: '' },
      { field: 'filter_plan', value: filterPlanName },
      { field: 'filter_country', value: '' },
      { field: 'filter_storageLocation', value: '' },
    ]);
    setAdFilterResellerId('');
    setSelectedColumn('');
    setSearchIDFieldValue(0);
    setFullTextSearch('');
    setSearchFieldValue('');
  };

  const handleOnResetButtonClick = () => {
    resetFilterStateHandler();
    if (!fetching) {
      const flag = true;
      dispatch(resetOrganizationsListState());
      fetchApiHandler(flag, false);
    }
  };

  const handleSearchFieldChange = (e) => {
    const input = e.target.value;
    setSearchFieldValue(input);
    if (/^\d+$/.test(input)) {
      setSearchIDFieldValue(input);
      setFullTextSearch(input);
    } else {
      setSearchIDFieldValue('');
      setFullTextSearch(input);
    }
  };

  useEffect(() => {
    const previousProps = previousPropsRef.current;
    buildMatchText();
    if (
      !resellerID ||
      resellerID !== resellerIDProp?.current ||
      selectTab === null ||
      selectedTabState?.organizationTabState !== selectTab ||
      !isEqual(previousProps, pagination)
    ) {
      resellerIDProp.current = resellerID;
      dispatch(setOrganizationPaginations(pagination));
      previousPropsRef.current = pagination;
      if (!fetching) {
        handleFetchOrganizationsListAsync();
      }
    } else if (isEqual(previousProps, pagination) && !fetching) {
      handleFetchOrganizationsListAsync();
    }
  }, [pagination]);

  const exportDisabled = Number(paginationList?.TotalCount || 0) <= 0 || !fetchParams?.reseller;
  const exportTooltip = () => {
    if (Number(paginationList?.TotalCount || 0) <= 0) {
      return t('organization_details_page.tooltip.result_empty');
    }
    if (!fetchParams?.reseller) {
      return t('organization_details_page.tooltip.partner_not_selected');
    }
    return null;
  };

  return (
    <>
      <QboSnackbar onClose={handleSnackbarClose} {...snackbarState} />
      {!tabFlag && <HeaderPageBlock>{t('organization_details_page.header.text')}</HeaderPageBlock>}
      <QboCard isMainWrapper>
        <QboFormGroup
          onSubmit={(e) => handleOnSearchClick(e)}
          buttonPostion="start"
          buttonStyle={buttonStyle}
          handleOnResetButton={handleOnResetButtonClick}
          firstButtonLabel={t('organization_details_page.button.search')}
          withGenerateAndResetButton
          buttonLabel="Validate Transfer">
          <QboTextField
            id="fullTextSearch"
            value={searchFieldValue !== '' ? searchFieldValue : ''}
            isWidthSetExplicit
            placeholder="ID/Name"
            inputProps={{ maxLength: 225 }}
            onChange={(e) => {
              handleSearchFieldChange(e);
            }}
          />
          <div style={{ marginTop: 8 }}>
            <Button
              disableRipple
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
                textTransform: 'none',
              }}
              onClick={() => {
                handleOnAdvanceSearchClick();
              }}>
              {advanceClick
                ? t('partner_details_page.button.hide_advance_search')
                : t('partner_details_page.button.show_advance_search')}
            </Button>
          </div>
          {advanceClick && (
            <div>
              {/* <QboSortByButton
                // forceUpdate={forceUpdate}
                // searchState={searchState}
                // isAllSelected={isAllSelected}
                // handleCloseClick={handleCloseClick}
                isSearchEnable={!false}
                // handleOnSearchChange={handleOnSearchChange}
                isCheckBoxColumnFilter
                marginRightValue={2}
                marginTopValue={2}
                // selected={selected}
                buttonWidth={220}
                startAdornmentTextWidth={180}
                startAdornmentText="Partner Name:"
                // handleCheckBoxChange={handleCheckBoxChange}
                // menuListCheckboxItems={filterdState}
              /> */}
              {resellerID || (
                <PartnerFilterSection
                  label="Partner:"
                  value={adFilterResellerId}
                  marginRightValue={2}
                  marginTopValue={3}
                  inputSize={180}
                  handleChange={(value) => {
                    setAdFilterResellerId(value);
                  }}
                />
              )}
              <QboSortByButton
                defaultValue="All"
                marginRightValue={2}
                marginTopValue={3}
                value={adFilterStatus}
                buttonWidth={220}
                startAdornmentTextWidth={60}
                startAdornmentText="Status:"
                handleChange={(event) => {
                  setAdFilterStatus(event.target.value);
                  handleUpdateFilters('filter_status', event.target.value);
                }}
                menuItemList={statusItemList}
              />
              <QboSortByButton
                defaultValue="All"
                marginRightValue={2}
                marginTopValue={3}
                value={adFilterCountry}
                buttonWidth={220}
                startAdornmentTextWidth={70}
                startAdornmentText="Country:"
                handleChange={(event) => {
                  setAdFilterCountry(event.target.value);
                  handleUpdateFilters('filter_country', event.target.value);
                }}
                menuItemList={countryItemList}
              />
              <QboSortByButton
                defaultValue="All"
                marginRightValue={2}
                marginTopValue={3}
                value={adFilterAutoLicence}
                buttonWidth={220}
                startAdornmentTextWidth={140}
                startAdornmentText="Auto License:"
                handleChange={(event) => {
                  setAdFilterAutoLicense(event.target.value);
                  handleUpdateFilters('filter_autoLicense', event.target.value);
                }}
                menuItemList={autoLicenseItemList}
              />
              <QboSortByButton
                defaultValue="All"
                type="text"
                marginRightValue={2}
                marginTopValue={3}
                value={adFilterLicece}
                buttonWidth={220}
                startAdornmentTextWidth={140}
                startAdornmentText="License Type:"
                handleChange={(event) => {
                  setAdFilterLicense(event.target.value);
                  handleUpdateFilters('filter_license', event.target.value);
                }}
                menuItemList={licenseTypeItemList}
              />
              <QboSortByButton
                defaultValue="All"
                type="text"
                marginRightValue={2}
                marginTopValue={3}
                value={adFilterStorageLocation}
                buttonWidth={220}
                startAdornmentTextWidth={140}
                startAdornmentText="Storage Location:"
                handleChange={(event) => {
                  setAdFilterStorageLocation(event.target.value);
                  handleUpdateFilters('filter_storageLocation', event.target.value);
                }}
                menuItemList={storageLocationItemList}
              />
              <div>
                <QboSortByButton
                  defaultValue="None"
                  marginTopValue={2}
                  marginRightValue={2}
                  value={selectedColumn}
                  buttonWidth={220}
                  startAdornmentTextWidth={70}
                  startAdornmentText="Sort by:"
                  handleChange={handleOnSortByChange}
                  menuItemList={columnNames}
                />
              </div>
            </div>
          )}
        </QboFormGroup>
      </QboCard>

      <QboCard noPadding isMainWrapper>
        <hr />
        <ITBox
          direction="row"
          sx={{
            margin: 2,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <QboTypography
            sx={{
              fontSize: 18,
              color: '#000000DE',
              fontWeight: 600,
            }}>
            Organizations List
          </QboTypography>
          {hasPermission(featureFlags.RunExportOrganizationInfo) && (
            <QboButton
              disabled={exportDisabled}
              withTitle={exportDisabled}
              title={exportTooltip()}
              style={{ width: 120 }}
              variant="contained"
              onClick={handleExport}
              loading={exportOrganizationsState.fetching}>
              {t('export_csv')}
            </QboButton>
          )}
        </ITBox>
        <QboTypography
          sx={{
            marginLeft: 2,
            marginTop: 2,
            marginBottom: 3,
            fontSize: '15px !important',
            color: '#42526e',
            fontWeight: 600,
          }}>
          {matchText}
        </QboTypography>
        {(organizationsList.length > 0 || fetching) && (
          <QboAdvanceTable
            isLoading={fetching}
            enableTopToolBar={false}
            enableSorting
            rows={organizationsList}
            headers={BaseSetting.organizationListHeaders}
            rowsCount={Number(paginationList?.TotalCount || 0)}
            pagination={pagination}
            setPagination={setPagination}
            enableColumnClickable={!false}
            columnsClickableList={clickableCoulmnList}
            showColumnId="organizationId"
          />
        )}
        {organizationsList.length === 0 && !fetching && <NoStatusSection />}
      </QboCard>
    </>
  );
}

OrganizationListPage.propTypes = {
  resellerID: PropTypes.number,
  tabFlag: PropTypes.bool,
  selectTab: PropTypes.number,
  plan: PropTypes.string,
  resellerIDProp: PropTypes.object,
};

OrganizationListPage.defaultProps = {
  resellerID: null,
  tabFlag: false,
  selectTab: null,
  plan: '',
  resellerIDProp: {},
};
