import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Skeleton } from '@mui/material';
import { QboTypography } from '.';

export default function QboCardSingleList({
  leftContent,
  rightContent,
  children,
  className,
  loading,
  compact,
  subHeadingText,
  ...rest
}) {
  const renderLeftContent = () => {
    if (!leftContent) return null;
    if (loading) {
      return (
        <Skeleton
          className="QboCardListItem__left-content"
          variant="circular"
          width={40}
          height={40}
        />
      );
    }

    return (
      <Grid
        sx={{
          backgroundColor: '#f2f0f0',
          padding: 1,
          border: '1px solid black',
          borderColor: '#ffffff',
          width: '40%',
        }}
        item
        className="QboCardListItem__left-content">
        {leftContent}
        {subHeadingText && (
          <QboTypography wrap={!false} sx={{ color: '#42526e', fontSize: '12px !important' }}>
            {subHeadingText}
          </QboTypography>
        )}
      </Grid>
    );
  };

  const renderRightContent = () => {
    if (!rightContent) return null;

    return (
      <Grid
        sx={{
          padding: 1,
          border: '1px solid black',
          borderColor: '#eef1f5e0',
          width: '60%',
          display: 'flex',
          alignItems: 'center',
        }}
        item
        className="QboCardListItem__right-content">
        <QboTypography sx={{ overflow: 'hidden', wordWrap: 'break-word' }} wrap={!false}>
          {rightContent}
        </QboTypography>
      </Grid>
    );
  };

  const attributes = {
    ...(loading ? { justifyContent: 'center', alignItems: 'center' } : {}),
  };

  return (
    <Grid container {...attributes} {...rest}>
      {renderLeftContent()}
      {renderRightContent()}
    </Grid>
  );
}

QboCardSingleList.propTypes = {
  leftContent: PropTypes.node,
  rightContent: PropTypes.node,
  className: PropTypes.string,
  subHeadingText: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  loading: PropTypes.bool,
  compact: PropTypes.bool,
};

QboCardSingleList.defaultProps = {
  leftContent: null,
  rightContent: null,
  className: '',
  children: '',
  subHeadingText: '',
  loading: false,
  compact: false,
};
