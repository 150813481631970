import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IconButton, Pagination, FormControl, MenuItem, TextField, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import RefreshIcon from '@mui/icons-material/Refresh';
import BaseSetting, { constant } from '@config/BaseSetting';
import PropTypes from 'prop-types';
import useMailBoxServices from '@pages/sharedHooks/useMailboxServices';
import { resetMailChangeStatus } from '@features/mailBox/mailboxChangeSlice';
import { useNavigate } from 'react-router';
import {
  ITBox,
  QboAlert,
  QboButton,
  QboCard,
  QboCheckbox,
  QboFormGroup,
  QboModal,
  QboSelect,
  QboSimpleDataTable,
  QboTextField,
  QboTypography,
} from '@ui/Components';
import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import useLocalStorage from '@hooks/useLocalStorage';
import { useEmailFromCSV } from '@hooks';
import ClientRoutes from '@config/Routes/WebClientRoutes';
import useForm from './pageHooks/useForm';

export default function MailboxChangePage() {
  const [mailboxData, setMailboxData] = useState([]);
  const [emailLists, setEmailLists] = useState([]);
  const { mailBoxServices, mailBoxAPIHandler } = useMailBoxServices();
  const [showModal, setShowModal] = useState(false);
  const inputRef = useRef(null);
  const { handleOnFileUpload } = useEmailFromCSV();
  const navigate = useNavigate();

  const { mailChangeRequestState } = useSelector((state) => state.mailRequest);
  const {
    data,
    fetching,
    success,
    forbiddenState,
    forbiddenErrorMessage,
    mailChangeCorsErrorMessage,
  } = mailChangeRequestState;
  const [pagesSet, setPagesSet] = useState({});
  const [pageNum, setPageNum] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [refresh, setRefresh] = useState(false);

  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);

  const { mailChangeState } = useSelector((state) => state.mail);
  const { statusCode, errorMessage, fetching: submittingRequest } = mailChangeState;
  const columns = [
    'support-ticket',
    'escalation-ticket',
    'in-progress-count',
    'success-count',
    'error-count',
    'id',
    'payload',
    'executed-on',
    'status-description',
    'description',
    'last-updated-on',
    'retry-count',
    'last-retried-on',
    'org-owner-id',
  ];

  useEffect(() => {
    mailBoxServices(setMailboxData, setPagesSet, columns);
  }, [data]);

  const callApiHandler = async () => {
    if (accessTokenSet) {
      mailBoxAPIHandler(pageNum);
    }
  };

  const refreshHandler = () => {
    setRefresh(true);
  };

  useEffect(() => {
    callApiHandler();
    setRefresh(false);
  }, [pageNum, refresh]);

  const handleChangePage = (event, newPage) => {
    setPageNum(newPage);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, mailboxData.length - pageNum * rowsPerPage);
  const page = constant.MAILBOX_CHANGE_PAGE;
  const resetStatusCode = resetMailChangeStatus;
  const isForbiddenOccured = forbiddenState;
  const corsErrorMessage = mailChangeCorsErrorMessage;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [noStatusFlag, setNoStatusFlag] = useState(false);
  const [selectErrorMessage, setSelectErrorMessage] = useState('');
  const [value] = useLocalStorage(constant.REGIONS_LIST);
  const isAstericRequired = true;

  const addOption = {
    addInDifferentOrganization: 1,
    changeEmail: 2,
  };

  const onClickRow = (row) => {
    navigate(ClientRoutes.mailBoxChangeDetailsPath({ id: row.id }), { state: { row } });
  };

  const {
    supportTicketAttributes,
    escalationTicketAttributes,
    descriptionAttributes,
    organizationOwnerIdAttributes,
    currentEmailAttributes,
    newEmailAttributes,
    changeRequestTypeAttributes,
    isBulkAttributes,
    prefixAttributes,
    mappingFileAttributes,
    onSubmit,
    resetForm,
    selectTypeOptions,
    emailListingAttributes,
  } = useForm(page);

  const tableHeaders = [
    {
      id: 1,
      label: 'ID',
      columnName: 'id',
      align: 'center',
    },
    {
      id: 2,
      label: 'Support Ticket',
      columnName: 'support-ticket',
    },
    {
      id: 3,
      label: 'Escalation Ticket',
      columnName: 'escalation-ticket',
    },
    {
      id: 4,
      label: 'Description',
      columnName: 'description',
    },
    {
      id: 5,
      label: 'Owner ID',
      columnName: 'org-owner-id',
    },
    {
      id: 6,
      label: 'Status',
      columnName: 'status-description',
    },

    {
      id: 7,
      label: 'Executed On',
      columnName: 'executed-on',
    },
    {
      id: 8,
      label: 'In Progress',
      columnName: 'in-progress-count',
    },
    {
      id: 9,
      label: 'Success',
      columnName: 'success-count',
    },
    {
      id: 10,
      label: 'Error',
      columnName: 'error-count',
    },
  ];

  useEffect(() => {
    if (statusCode === 200 || statusCode === 403) {
      resetForm();
    }
  }, [errorMessage, statusCode]);

  useEffect(() => {
    if (changeRequestTypeAttributes.value) {
      setSelectErrorMessage(
        'By Checked "Enable the customer to add the mailbox to another organization", the backup will not run anymore after the change.'
      );
    } else if (!changeRequestTypeAttributes.value) {
      setSelectErrorMessage('Backup will still run for M365 but will be stopped for GWS.');
    }
  }, [changeRequestTypeAttributes.value]);

  useEffect(() => {
    if (!success || mailboxData?.length === 0) {
      setNoStatusFlag(true);
    } else if (success && mailboxData?.length !== 0) {
      setNoStatusFlag(false);
    }
  }, [success, mailboxData?.length]);

  useEffect(() => {
    if (emailLists.length > 0) {
      emailListingAttributes.handleChange(emailLists.toString());
    }
  }, [emailLists]);

  useEffect(() => {
    if (statusCode === 200 || statusCode === 401) {
      if (inputRef.current) inputRef.current.value = null;
      resetForm();
    }
  }, [statusCode]);

  return (
    <>
      <HeaderPageBlock>{t(page)}</HeaderPageBlock>
      {corsErrorMessage && (
        <QboAlert type="error" style={{ fontSize: '1.15em' }}>
          {corsErrorMessage}
        </QboAlert>
      )}
      {!fetching && (
        <QboCard>
          {!isForbiddenOccured && (
            <ITBox className="SystemStatusSection__wrapper" direction="column">
              <ITBox className="SystemStatusSection__create-button">
                <IconButton
                  sx={{
                    position: 'relative',
                    right: '0.7rem',
                  }}
                  onClick={() => refreshHandler()}>
                  <RefreshIcon
                    sx={{
                      width: '2rem',
                      height: '2rem',
                    }}
                  />
                </IconButton>
                &nbsp;
                <QboButton variant="contained" onClick={() => setShowModal(true)}>
                  {t('mailbox_change.new_request')}
                </QboButton>
              </ITBox>
            </ITBox>
          )}

          <QboCard noPadding isMainWrapper>
            {success && mailboxData?.length > 0 && value && !isForbiddenOccured && (
              <>
                <QboSimpleDataTable
                  sx={{ width: '100%' }}
                  rows={mailboxData.map((m) => {
                    return { ...m, isLink: true };
                  })}
                  header={tableHeaders}
                  loading={fetching}
                  onClickRow={onClickRow}
                />{' '}
                {pagesSet?.TotalPages && (
                  <Pagination
                    sx={{
                      display: 'flex',
                      justifyContent: 'end',
                      margin: '1em',
                      fontSize: '2rem',
                    }}
                    count={Number(pagesSet?.TotalPages)}
                    page={pageNum}
                    onChange={handleChangePage}
                    size="large"
                  />
                )}
              </>
            )}
            {(noStatusFlag || !value || isForbiddenOccured) && !fetching && (
              <NoStatusSection forbiddenErrorMessage={forbiddenErrorMessage} />
            )}
            <QboModal
              onClose={() => {
                setShowModal(false);
                resetForm();
                dispatch(resetStatusCode());
                callApiHandler();
              }}
              open={showModal}
              title={t(page)}
              showCloseIcon
              classNameStyle="mail_modal"
              preventOutsideClick>
              <ITBox
                className="TransferSubscriptionFormPage__wrapper ITBox_wrapper_modal"
                direction="column">
                <QboFormGroup
                  loadingButton={submittingRequest}
                  buttonDisabled={submittingRequest}
                  onSubmit={onSubmit}
                  withSubmitButton
                  title={t('mailbox_change.new_request')}>
                  {(errorMessage || statusCode) && (
                    <QboAlert
                      type={statusCode === 200 ? 'success' : 'error'}
                      style={{ fontSize: '1.15em' }}>
                      {errorMessage || (statusCode === 200 && 'Successfully submitted')}
                    </QboAlert>
                  )}

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <QboTextField
                        id="supportTicket"
                        required={isAstericRequired}
                        placeholder={t('mailbox_change.labels.support_ticket')}
                        label={t('mailbox_change.labels.support_ticket')}
                        fullWidth
                        value={supportTicketAttributes.value}
                        errorMessage={supportTicketAttributes.errorMessage}
                        inputProps={{ maxLength: 225 }}
                        onChange={(e) => {
                          supportTicketAttributes.handleChange(e.currentTarget.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <QboTextField
                        id="escalationTicket"
                        placeholder={t('mailbox_change.labels.escalation_ticket')}
                        label={t('mailbox_change.labels.escalation_ticket')}
                        fullWidth
                        value={escalationTicketAttributes.value}
                        onChange={(e) =>
                          escalationTicketAttributes.handleChange(e.currentTarget.value)
                        }
                        inputProps={{ maxLength: 225 }}
                      />
                    </Grid>
                  </Grid>

                  <QboTextField
                    id="description"
                    placeholder={t('mailbox_change.labels.description')}
                    label={t('mailbox_change.labels.description')}
                    fullWidth
                    value={descriptionAttributes.value}
                    onChange={(e) => descriptionAttributes.handleChange(e.currentTarget.value)}
                    inputProps={{ maxLength: 225 }}
                  />

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <QboTextField
                        id="organization-owner-id"
                        required={isAstericRequired}
                        placeholder={t('mailbox_change.labels.organization_owner_id')}
                        label={t('mailbox_change.labels.organization_owner_id')}
                        fullWidth
                        value={organizationOwnerIdAttributes.value}
                        errorMessage={organizationOwnerIdAttributes.errorMessage}
                        onChange={(e) =>
                          organizationOwnerIdAttributes.handleChange(e.currentTarget.value)
                        }
                        inputProps={{ maxLength: 225 }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <QboSelect
                        label={t('mailbox_change.labels.select_type')}
                        options={selectTypeOptions}
                        handleChange={(e) => {
                          isBulkAttributes.handleChange(e.target.value);
                        }}
                        value={isBulkAttributes?.value}
                        defaultValue="0"
                      />
                    </Grid>
                  </Grid>

                  {isBulkAttributes.value === '1' ? (
                    <>
                      <QboTextField
                        id="list-emails"
                        required={isAstericRequired}
                        placeholder={t('mailbox_change.labels.list_emails')}
                        label={t('mailbox_change.labels.list_emails')}
                        multiline={Boolean(true)}
                        rows={5}
                        fullWidth
                        value={emailListingAttributes.value}
                        onChange={(e) => emailListingAttributes.handleChange(e.currentTarget.value)}
                        errorMessage={emailListingAttributes.errorMessage}
                      />

                      <QboTextField
                        id="csvFile"
                        placeholder={t('mailbox_change.labels.csv_file')}
                        label={t('mailbox_change.labels.csv_file')}
                        type="file"
                        inputRef={inputRef}
                        fullWidth
                        inputProps={{ maxLength: 225 }}
                        value={mappingFileAttributes?.value?.file}
                        onChange={(event) => {
                          handleOnFileUpload(event, setEmailLists);
                          mappingFileAttributes.handleChange(event?.target?.files[0]);
                        }}
                        errorMessage={mappingFileAttributes.errorMessage}
                      />

                      <QboTextField
                        id="prefix"
                        required={isAstericRequired}
                        placeholder="eg: m365 or gws"
                        label={t('mailbox_change.labels.prefix')}
                        fullWidth
                        value={prefixAttributes.value}
                        errorMessage={prefixAttributes.errorMessage}
                        onChange={(e) => prefixAttributes.handleChange(e.currentTarget.value)}
                        inputProps={{ maxLength: 225 }}
                        helperText="With prefix m365, the result of mailbox name will be john@dropsuite.com to m365_john@dropsuite.com"
                      />
                    </>
                  ) : (
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <QboTextField
                          id="current-email"
                          required={isAstericRequired}
                          placeholder={t('mailbox_change.labels.current_email')}
                          label={t('mailbox_change.labels.current_email')}
                          fullWidth
                          value={currentEmailAttributes.value}
                          errorMessage={currentEmailAttributes.errorMessage}
                          onChange={(e) =>
                            currentEmailAttributes.handleChange(e.currentTarget.value)
                          }
                          inputProps={{ maxLength: 225 }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <QboTextField
                          id="new-email"
                          required={isAstericRequired}
                          placeholder={t('mailbox_change.labels.new_email')}
                          label={t('mailbox_change.labels.new_email')}
                          fullWidth
                          value={newEmailAttributes.value}
                          errorMessage={newEmailAttributes.errorMessage}
                          onChange={(e) => newEmailAttributes.handleChange(e.currentTarget.value)}
                          inputProps={{ maxLength: 225 }}
                        />
                      </Grid>
                    </Grid>
                  )}

                  <FormControl className="FormControl__default-wrapper FormControl__full-width-wrapper">
                    <QboCheckbox
                      sx={{ fontSize: '12px !important' }}
                      className="QboCheckbox__wrapper_text"
                      label="Enable the customer to add the mailbox to another organization"
                      checked={changeRequestTypeAttributes.value}
                      onChange={(e) => {
                        changeRequestTypeAttributes.handleChange(e.target.checked);
                      }}
                    />
                    <QboAlert
                      variant="outlined"
                      type="info"
                      style={{
                        border: '1px solid #ebebeb',
                        background: '#f3f3f3',
                        fontSize: '10px !important',
                        marginTop: '20px',
                      }}>
                      <QboTypography noWrap={false} style={{ fontSize: '13px', color: '#767676' }}>
                        {selectErrorMessage}
                      </QboTypography>
                    </QboAlert>
                  </FormControl>
                </QboFormGroup>
              </ITBox>
            </QboModal>
          </QboCard>
        </QboCard>
      )}
    </>
  );
}
