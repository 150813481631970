import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMailboxBulkPurgeListAPI } from '@services/WebApiService';

const fetchMailboxBulkPurgeListAsync = createAsyncThunk(
  'BulkPurgeList/mailboxBulkPurgeList',
  async (paramsList) => {
    try {
      const data = await getMailboxBulkPurgeListAPI(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = fetchMailboxBulkPurgeListAsync;

const mailboxBulkPurgeListReducers = {
  [pending]: (state) => {
    state.mailboxBulkPurgeEachListState.fetching = true;
  },
  [rejected]: (state) => {
    state.mailboxBulkPurgeEachListState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.mailboxBulkPurgeEachListState.fetching = false;

    if (action?.payload?.error) {
      state.mailboxBulkPurgeEachListState.errorMessage = action.payload.error?.data.message;
      state.mailboxBulkPurgeEachListState.statusCode = action.payload.error?.status;

      if (action?.payload?.error?.status === 403) {
        state.mailboxBulkPurgeEachListState.forbiddenState = true;
        state.mailboxBulkPurgeEachListState.forbiddenErrorMessage = {
          heading: 'Forbidden Error',
          caption: 'You are not authorize to access this resource',
        };
      }
    }

    if (!action?.payload?.data && !action.payload?.error) {
      state.mailboxBulkPurgeEachListState.bulkPurgeCorsErrorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.data?.data) {
      state.mailboxBulkPurgeEachListState.forbiddenState = false;
      state.mailboxBulkPurgeEachListState.forbiddenErrorMessage = {};
      state.mailboxBulkPurgeEachListState.statusCode = action.payload?.status;
      state.mailboxBulkPurgeEachListState.bulkPurgeListData = action?.payload?.data?.data;

      if (action?.payload?.data?.pagination) {
        state.mailboxBulkPurgeEachListState.pagination = JSON.parse(
          action?.payload?.data?.pagination
        );
      }
    }
  },
};

export { fetchMailboxBulkPurgeListAsync, mailboxBulkPurgeListReducers };

export default fetchMailboxBulkPurgeListAsync;
