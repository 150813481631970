import { createAsyncThunk } from '@reduxjs/toolkit';
import { refreshTokenAPI } from '@services/WebApiService';

const getRefreshTokenAsync = createAsyncThunk('Token/getRefreshToken', async (paramsList) => {
  try {
    const data = await refreshTokenAPI(paramsList);
    return data;
  } catch (error) {
    return error;
  }
});

const { pending, fulfilled, rejected } = getRefreshTokenAsync;

const getRefreshTokenReducers = {
  [pending]: (state) => {
    state.getRefToken.fetching = true;
  },
  [rejected]: (state) => {
    state.getRefToken.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.getRefToken.fetching = false;

    if (action?.payload?.code) {
      if (action.payload?.response?.status !== 401) {
        state.getRefToken.refreshErrorMessage = action?.payload?.message;
      }
      state.getRefToken.error = action.payload.code;
      state.getRefToken.statusCode = action.payload?.response?.status;
    } else {
      state.getRefToken.success = true;
    }
  },
};

export { getRefreshTokenAsync, getRefreshTokenReducers };

export default getRefreshTokenAsync;
