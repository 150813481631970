import { createSlice } from '@reduxjs/toolkit';
import {
  gwsTenantDetailAsync,
  gwsTenantDetailExtraReducers,
} from './serviceActions/gwsTenantDetailAsync';

const initialState = {
  gwsTenantDetailState: {
    submitting: false,
    success: false,
    data: {},
    error: null,
    statusCode: null,
    errorMessage: null,
  },
};

const gwsTenantDetailSlice = createSlice({
  name: 'GWSTenantDetail',
  initialState,
  reducers: {
    resetGWSTenantDetail(state) {
      state.gwsTenantDetailState = initialState.gwsTenantDetailState;
    },
  },
  extraReducers: {
    ...gwsTenantDetailExtraReducers,
  },
});

export { gwsTenantDetailAsync };
export const { resetGWSTenantDetail } = gwsTenantDetailSlice.actions;
export default gwsTenantDetailSlice.reducer;
