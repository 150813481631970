import { createSlice } from '@reduxjs/toolkit';

import {
  getFeatureFlagAsync,
  getFeatureFlagExtraReducers,
} from './serviceActions/fetchFeatureFlagAsync';

const initialState = {
  getFeatureFlagState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    featureFlagErrorMessage: null,
    featureFlagList: [],
    forbiddenState: false,
    forbiddenErrorMessage: null,
  },
};

const getFeatureFlagSlice = createSlice({
  name: 'getFeatureFlag',
  initialState,
  reducers: {
    resetFeatureFlagState(state) {
      state.getFeatureFlagState = initialState.getFeatureFlagState;
    },
  },
  extraReducers: {
    ...getFeatureFlagExtraReducers,
  },
});

export { getFeatureFlagAsync };
export const { resetFeatureFlagState } = getFeatureFlagSlice.actions;
export default getFeatureFlagSlice.reducer;
