import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPurgeGWSTenantsAPI } from '@services/WebApiService';

const getPurgeGWSTenantsAsync = createAsyncThunk(
  'PurgeGWSTenants/getPurgeGWSTenants',
  async (paramsList) => {
    try {
      const data = await getPurgeGWSTenantsAPI(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = getPurgeGWSTenantsAsync;

const getPurgeGWSTenantsExtraReducers = {
  [pending]: (state) => {
    state.getPurgeGWSTenantsState.fetching = true;
  },
  [rejected]: (state) => {
    state.getPurgeGWSTenantsState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.getPurgeGWSTenantsState.fetching = false;

    if (action?.payload?.error) {
      state.getPurgeGWSTenantsState.errorMessage = action.payload.error?.data.message;
      state.getPurgeGWSTenantsState.status = action.payload.error?.status;

      if (action?.payload?.error?.status === 403) {
        state.getPurgeGWSTenantsState.forbiddenState = true;
        state.getPurgeGWSTenantsState.forbiddenErrorMessage = {
          heading: 'Forbidden Error',
          caption: 'You are not authorize to access this resource',
        };
      }
    }

    if (!action?.payload?.error && !action?.payload?.data) {
      state.getPurgeGWSTenantsState.purgeGWSTenantsCorsErrorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.data?.data) {
      state.getPurgeGWSTenantsState.forbiddenState = false;
      state.getPurgeGWSTenantsState.forbiddenErrorMessage = {};
      state.getPurgeGWSTenantsState.status = action.payload?.status;
      let list = [];

      if (action?.payload?.data?.data && action?.payload?.data?.data.length > 0) {
        list = action?.payload?.data?.data.map((item) => {
          const detailPayload = JSON.parse(item.payload);
          let statusDescription = '';

          try {
            statusDescription = item['status-description'].substring(
              item['status-description'],
              100
            );
          } catch (err) {
            statusDescription = item['status-description'];
          }

          return {
            id: item.id,
            'org-owner-id': detailPayload['org-owner-id'],
            'support-ticket': detailPayload['support-ticket'],
            'escalation-ticket': detailPayload['escalation-ticket'],
            description: detailPayload.description,
            'credential-id': detailPayload['credential-id'],
            'status-description': statusDescription,
            'executed-on': item['executed-on'],
            status: item.status,
            errorMessage: item['status-description'],
          };
        });
      }

      state.getPurgeGWSTenantsState.purgeGWSTenantsList = list;

      if (action?.payload?.data?.pagination) {
        state.getPurgeGWSTenantsState.paginations = JSON.parse(action?.payload?.data?.pagination);
      }
    }
  },
};

export { getPurgeGWSTenantsAsync, getPurgeGWSTenantsExtraReducers };
