import React from 'react';
import PropTypes from 'prop-types';
import { ListItemButton, ListItemIcon, Skeleton } from '@mui/material';
import { Link } from 'react-router-dom';
import { QboTypography } from '@ui/Components';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export default function QboDrawerMenuItem({
  className,
  path,
  icon,
  text,
  forceActive,
  onClick,
  loading,
  children,
  isSublinkOpen,
  style,
  isWrapEnable,
  isArrowEnable,
}) {
  if (loading) {
    return (
      <div className="QboDrawerMenuItem">
        <ListItemButton>
          <Skeleton
            variant="text"
            width={25}
            height={35}
            className="QboDrawerMenuItem__loading-icon MuiListItemIcon-root"
          />

          <Skeleton variant="text" width="100%" className="QboDrawerMenuItem__loading-label" />
        </ListItemButton>
      </div>
    );
  }

  return (
    <>
      <Link
        onClick={onClick}
        to={path}
        className={`QboDrawerMenuItem QboDrawerMenuItem__${
          forceActive ? 'active' : 'inactive'
        } ${className}`}>
        <ListItemButton>
          <ListItemIcon>{icon}</ListItemIcon>
          <QboTypography style={style} wrap={isWrapEnable} withTitle>
            {text}
          </QboTypography>
          {children && (
            <ListItemIcon>
              {isArrowEnable &&
                (isSublinkOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />)}
            </ListItemIcon>
          )}
        </ListItemButton>
      </Link>
      {children}
    </>
  );
}

QboDrawerMenuItem.propTypes = {
  path: PropTypes.string,
  style: PropTypes.object,
  icon: PropTypes.node,
  text: PropTypes.string,
  forceActive: PropTypes.bool,
  isWrapEnable: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  children: PropTypes.node,
  isSublinkOpen: PropTypes.bool,
  isArrowEnable: PropTypes.bool,
};

QboDrawerMenuItem.defaultProps = {
  path: '#menu',
  icon: '',
  text: '',
  forceActive: false,
  isWrapEnable: true,
  className: '',
  style: {},
  onClick: () => {},
  loading: false,
  children: '',
  isSublinkOpen: false,
  isArrowEnable: true,
};
