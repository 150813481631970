import { createAsyncThunk } from '@reduxjs/toolkit';
import { getBulkCreateSubResellerTemplateApi } from '@services/WebApiService';

const getBulkCreateSubResellerTemplateAsync = createAsyncThunk(
  'GetBulkCreateSubResellerTemplate/getBulkCreateSubResellerTemplate',
  async (paramsList) => {
    try {
      const data = await getBulkCreateSubResellerTemplateApi(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = getBulkCreateSubResellerTemplateAsync;

const getBulkCreateSubResellerTemplateExtraReducers = {
  [pending]: (state) => {
    state.getBulkCreateSubResellerTemplateState.fetching = true;
  },
  [rejected]: (state) => {
    state.getBulkCreateSubResellerTemplateState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.getBulkCreateSubResellerTemplateState.fetching = false;

    if (action?.payload?.status === 403) {
      state.getBulkCreateSubResellerTemplateState.forbiddenState = true;
      state.getBulkCreateSubResellerTemplateState.forbiddenErrorMessage = {
        heading: 'Forbidden Error',
        caption: 'You are not authorize to access this resource',
      };
    }

    if (!action?.payload) {
      state.getBulkCreateSubResellerTemplateState.corsErrorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.data) {
      state.getBulkCreateSubResellerTemplateState.exportData = action?.payload?.data;
      state.getBulkCreateSubResellerTemplateState.success = true;
      state.getBulkCreateSubResellerTemplateState.statusCode = action?.payload?.data['status-code'];
    }

    if (action?.payload?.error) {
      state.getBulkCreateSubResellerTemplateState.error = action.payload.error;
    }
  },
};

export { getBulkCreateSubResellerTemplateAsync, getBulkCreateSubResellerTemplateExtraReducers };
