import { ITBox, QboAdvanceTable, QboCard, QboTypography } from '@ui/Components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { SUMMARY_HEADERS } from './constants/toolsRequestHistoryConstants';

export default function SummarySection({ job }) {
  const { t } = useTranslation();
  return (
    <QboCard sx={{ marginBottom: '2rem' }}>
      <ITBox className="SystemStatusSection__wrapper" direction="row">
        <QboTypography style={{ margin: 16, fontSize: '2rem' }}>
          {t('tools_request_status.status_summary')}
        </QboTypography>
      </ITBox>

      <QboAdvanceTable
        enableTopToolBar={false}
        enableBottomToolbar={false}
        rows={[job]}
        enableSorting
        rowsCount={5}
        headers={SUMMARY_HEADERS}
      />
    </QboCard>
  );
}

SummarySection.propTypes = {
  job: PropTypes.object,
};

SummarySection.defaultProps = {
  job: null,
};
