import { createSlice } from '@reduxjs/toolkit';
import {
  fetchMonthlyBackupReportsAsync,
  monthlyBackupReportsReducers,
} from './serviceActions/fetchMonthlyBackupReportsAsync';

const initialState = {
  monthlyBackupReportsState: {
    fetching: false,
    monthlyBackupData: [],
    errorMessage: null,
    pagination: [],
    statusCode: null,
    forbiddenState: false,
    forbiddenErrorMessage: {},
    corsErrorMessage: null,
  },
};

const monthlyBackupReportsSlice = createSlice({
  name: 'MonthlyBackupReportsList',
  initialState,
  reducers: {
    resetMonthlyBackupReportsStatus(state) {
      state.monthlyBackupReportsState = initialState.monthlyBackupReportsState;
    },
    resetMonthlyBackupReportsListStatus(state) {
      state.monthlyBackupReportsState.monthlyBackupData =
        initialState.monthlyBackupReportsState.monthlyBackupData;
    },
    resetMonthlyBackupReportsErrorStatus(state) {
      state.monthlyBackupReportsState.errorMessage =
        initialState.monthlyBackupReportsState.errorMessage;
    },
  },
  extraReducers: {
    ...monthlyBackupReportsReducers,
  },
});

export { fetchMonthlyBackupReportsAsync };
export const {
  resetMonthlyBackupReportsStatus,
  resetMonthlyBackupReportsListStatus,
  resetMonthlyBackupReportsErrorStatus,
} = monthlyBackupReportsSlice.actions;
export default monthlyBackupReportsSlice.reducer;
