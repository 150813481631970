import { createSlice } from '@reduxjs/toolkit';
import {
  validateChangeBackupAdminAsync,
  validateChangeBackupAdminExtraReducers,
} from './serviceActions/validateChangeBackupAdminAsync';

const initialState = {
  validateChangeBackupAdminState: {
    submitting: false,
    success: false,
    data: {},
    error: null,
    statusCode: null,
    errorMessage: null,
  },
};

const validateChangeBackupAdminSlice = createSlice({
  name: 'ValidateChangeBackupAdmin',
  initialState,
  reducers: {
    resetValidateChangeBackupAdmin(state) {
      state.validateChangeBackupAdminState = initialState.validateChangeBackupAdminState;
    },
  },
  extraReducers: {
    ...validateChangeBackupAdminExtraReducers,
  },
});

export { validateChangeBackupAdminAsync };
export const { resetValidateChangeBackupAdmin } = validateChangeBackupAdminSlice.actions;
export default validateChangeBackupAdminSlice.reducer;
