import { createSlice } from '@reduxjs/toolkit';
import { runBackupAsync, runBackupExtraReducers } from './serviceActions/runBackupAsync';

const initialState = {
  runBackupState: {
    fetching: false,
    status: null,
    error: null,
    message: null,
    errorMessage: null,
    forbiddenState: false,
    forbiddenErrorMessage: null,
    runBackupCorsErrorMessage: null,
    serviceType: null,
  },
};

const runBackupSlice = createSlice({
  name: 'runBackup',
  initialState,
  reducers: {
    resetRunBackupState(state) {
      state.runBackupState = initialState.runBackupState;
    },
  },
  extraReducers: {
    ...runBackupExtraReducers,
  },
});

export { runBackupAsync };

export const { resetRunBackupState } = runBackupSlice.actions;
export default runBackupSlice.reducer;
