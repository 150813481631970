import { createSlice } from '@reduxjs/toolkit';

import {
  getM365UsersTenantAsync,
  getM365UsersTenantReducers,
} from './serviceActions/getM365UsersTenantAsync';

const initialState = {
  getM365UsersTenantState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    paginationList: null,
    m365UsersTenantInformation: [],
    forbiddenState: false,
    forbiddenErrorMessage: null,
    m365UsersTenantCorsErrorMessage: null,
  },
};

const m365UsersTenantSlice = createSlice({
  name: 'M365UsersTenant',
  initialState,
  reducers: {
    resetM365UsersTenantState(state) {
      state.getM365UsersTenantState = initialState.getM365UsersTenantState;
    },
    resetM365UsersTenantStatusCodeState(state) {
      state.getM365UsersTenantState.statusCode = initialState.getM365UsersTenantState.statusCode;
    },
  },
  extraReducers: {
    ...getM365UsersTenantReducers,
  },
});

export { getM365UsersTenantAsync };
export const { resetM365UsersTenantState, resetM365UsersTenantStatusCodeState } =
  m365UsersTenantSlice.actions;
export default m365UsersTenantSlice.reducer;
