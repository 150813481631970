import { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { isEqual } from 'lodash';
import { constant } from '@config/BaseSetting';
import useLocalStorage from '@hooks/useLocalStorage';
import useEmailFromCSV from '@hooks/useEmailFromCSV';
import { createSwitchCredentialAsync } from '@features/switchCredential/createSwitchCredentialSlice';
import useFormValidation from './useFormValidation';

export default function useForm() {
  const {
    formData,
    orgOwnerIdAttributes,
    supportTicketAttributes,
    escalationTicketAttributes,
    mappingFileAttributes,
    emailIdsAttributes,
    descriptionAttributes,
    changeRequestTypeAttributes,
    fromCredentialIdAttributes,
    toCredentialIdAttributes,
    validateAll,
    isValid,
    resetForm,
    validate,
  } = useFormValidation();

  const dispatch = useDispatch();
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);

  const previousPropsRef = useRef(formData);

  const handleSubmitRequest = () => {
    const parameters = {
      'support-ticket': formData.supportTicket,
      'escalation-ticket': formData.escalationTicket,
      description: formData.description,
      'org-owner-id': Number(formData.orgOwnerId),
      'mailbox-list': formData.emailIds,
      'to-credential-id': Number(formData.toCredentialId),
      'change-request-type': formData.changeRequestType,
    };

    if (formData.fromCredentialId && formData.fromCredentialId !== '') {
      parameters['from-credential-id'] = Number(formData.fromCredentialId);
    }

    if (isValid) {
      dispatch(
        createSwitchCredentialAsync({
          accessToken: accessTokenSet,
          reset: true,
          parameters,
        })
      );
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    validateAll();

    if (isValid) {
      handleSubmitRequest();
    }
  };

  useEffect(() => {
    handleSubmitRequest();
  }, [isValid]);

  return {
    formData,
    orgOwnerIdAttributes,
    mappingFileAttributes,
    supportTicketAttributes,
    escalationTicketAttributes,
    emailIdsAttributes,
    descriptionAttributes,
    changeRequestTypeAttributes,
    fromCredentialIdAttributes,
    toCredentialIdAttributes,
    validateAll,
    isValid,
    resetForm,
    validate,
    onSubmit,
  };
}
