import StorageFactory from '@libs/Storage';

const storageFactory = new StorageFactory({ keyStoragePrefix: 'INT_STORAGE' }, 'localStorage');
const storageClient = storageFactory.create();

export const clearStorage = () => {
  storageClient.constructor.clear();
};

const useLocalStorage = (key) => {
  const setValue = (value) => {
    if (value) storageClient.save(key, value);
  };

  const deleteValue = () => {
    storageClient.destroy(key);
  };

  const value = storageClient.get(key);

  return [value, setValue, deleteValue];
};

export default useLocalStorage;
