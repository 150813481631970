import { useDispatch } from 'react-redux';
import { getSwitchCredentialRequestAsync } from '@features/switchCredential/serviceActions/fetchSwitchCredentialRequestAsync';
import { constant } from '@config/BaseSetting';
import useLocalStorage from '@hooks/useLocalStorage';
import useForm from './useForm';

export default function useSwitchCredentials() {
  const dispatch = useDispatch();
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);

  const switchTypeOptions = [
    { id: '1', label: 'Imap to M365 Tenant' },
    { id: '2', label: 'Hosted Exchange to M365 Tenant' },
    { id: '3', label: 'GWS Tenant to M365 Tenant' },
    { id: '4', label: 'M365 Tenant to M365 Tenant' },
  ];

  const selectedSwitchOption = (selected) => {
    try {
      return switchTypeOptions.find((s) => Number(s.id) === Number(selected)).label;
    } catch (err) {
      return '';
    }
  };

  const fetchListAsync = (pageSize, pageIndex) => {
    dispatch(
      getSwitchCredentialRequestAsync({
        accessToken: accessTokenSet,
        reset: true,
        pageSize,
        pageNumber: pageIndex,
      })
    );
  };

  const listData = async (data, setList) => {
    const modifiedData = await data?.map((item) => {
      const payloadObj = JSON.parse(item.payload);
      const modifiedPayloadObj = {
        id: item?.id,
        'org-owner-id': payloadObj['org-owner-id'],
        'support-ticket': payloadObj['support-ticket'],
        'escalation-ticket': payloadObj['escalation-ticket'],
        status: item?.status,
        'status-description': item['status-description'],
        'executed-on': item['created-on'],
        'in-progress': item['in-progress-count'],
        success: item['success-count'],
        error: item['error-count'],
        'change-request-type': selectedSwitchOption(payloadObj['change-request-type']),
        'from-credential-id': payloadObj['from-credential-id'],
        'to-credential-id': payloadObj['to-credential-id'],
      };
      return modifiedPayloadObj;
    });

    setList(modifiedData);

    return modifiedData;
  };

  const headerTable = [
    { id: 1, label: 'ID', columnName: 'id', sx: { paddingLeft: 2 } },
    { id: 2, label: 'Support Ticket', columnName: 'support-ticket', sx: { paddingLeft: 2 } },
    { id: 3, label: 'Escalation Ticket', columnName: 'escalation-ticket', sx: { paddingLeft: 2 } },
    { id: 4, label: 'Org Owner ID', columnName: 'org-owner-id', sx: { paddingLeft: 2 } },
    { id: 5, label: 'Request Type', columnName: 'change-request-type', sx: { paddingLeft: 2 } },
    {
      id: 6,
      label: 'From Credential Id',
      columnName: 'from-credential-id',
      sx: { paddingLeft: 2 },
    },
    {
      id: 7,
      label: 'Target Credential Id',
      columnName: 'to-credential-id',
      sx: { paddingLeft: 2 },
    },
    {
      id: 8,
      label: 'Status',
      columnName: 'status-description',
      sx: { paddingLeft: 2 },
    },
    { id: 9, label: 'Executed On', columnName: 'executed-on', sx: { paddingLeft: 2 } },
    { id: 10, label: 'In Progress', columnName: 'in-progress', sx: { paddingLeft: 2 } },
    { id: 11, label: 'Success', columnName: 'success', sx: { paddingLeft: 2 } },
    { id: 12, label: 'Error', columnName: 'error', sx: { paddingLeft: 2 } },
  ];

  return { fetchListAsync, listData, form: useForm(), headerTable, switchTypeOptions };
}
