import { createSlice } from '@reduxjs/toolkit';

import {
  getParentFilterListAsync,
  getParentFilterListExtraReducers,
} from './serviceActions/getParentFilterListAsync';

const initialState = {
  getParentFilterListState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    parentFilterList: [],
  },
};

const ParentFilterListSlice = createSlice({
  name: 'ParentFilterList',
  initialState,
  reducers: {
    resetParentFilterListState(state) {
      state.getParentFilterListState = initialState.getParentFilterListState;
    },
  },
  extraReducers: {
    ...getParentFilterListExtraReducers,
  },
});

export { getParentFilterListAsync };
export const { resetParentFilterListState, resetParentFilterListStatusCodeState } =
  ParentFilterListSlice.actions;
export default ParentFilterListSlice.reducer;
