import React, { useEffect, useState } from 'react';
import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { m365TenantListPath } from '@config/Routes/WebClientRoutes';
import M365TenantDetailsPage from '@pages/TenantDetailsPage/M365TenantDetailsPage';
import { QboBreadcrumbs, QboCard, QboTabs, QboTypography } from '@ui/Components';
import { setM365TenantDetailState } from '@features/application/applicationSlice';
import M365UsersTenantPage from '@pages/TenantDetailsPage/M365UsersTenant';

export default function M365TenantDetailsTab() {
  const { m365TenantDetailState } = useSelector((state) => state.application);
  const { m365TenantRowInfo } = useSelector((state) => state.m365Tenant);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectTab, setSelectTab] = useState(0);
  const names = ['Details', 'M365 Users'];
  const { row } = m365TenantDetailState;
  const original = row?.row?.original;

  const handleOnBreadcrumbClick = () => {
    dispatch(setM365TenantDetailState({}));
  };

  const handleOnTabClick = (e, newValue) => {
    setSelectTab(newValue);
  };

  useEffect(() => {
    if (!original) {
      navigate(m365TenantListPath);
    }
  }, [original]);

  const yourComponent = (namesArray, selectTabState) => {
    if (namesArray[selectTabState] === 'Details') {
      return <M365TenantDetailsPage m365TenantCredentialID={original?.id} />;
    }
    if (namesArray[selectTabState] === 'M365 Users') {
      return (
        <QboCard noPadding isBordered sx={{ marginTop: 2 }}>
          <M365UsersTenantPage m365TenantCredentialID={m365TenantRowInfo?.id} />
        </QboCard>
      );
    }
    return (
      <QboCard sx={{ marginTop: 2 }} isCentered isBordered>
        <HeaderPageBlock>
          The {namesArray[selectTabState] || namesArray[0]} feature will be available soon.
        </HeaderPageBlock>
      </QboCard>
    );
  };
  return (
    <>
      <HeaderPageBlock>M365 Tenant Information</HeaderPageBlock>
      <QboBreadcrumbs sx={{ marginBottom: 2 }}>
        <Link
          onClick={() => handleOnBreadcrumbClick()}
          to={m365TenantListPath}
          style={{
            color: '#1c63d5',
            fontSize: '1.5rem',
            textDecoration: 'underline',
          }}>
          M365 Tenant
        </Link>
        <QboTypography color="text.primary" style={{ fontSize: '1.5rem' }}>
          M365 Tenant Information
        </QboTypography>
      </QboBreadcrumbs>

      <QboCard isMainWrapper>
        {original?.domain && (
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '2%' }}>
            <QboTypography variant="h5" wrap sx={{ fontSize: 18 }}>
              {original?.domain}
            </QboTypography>
            <QboTypography variant="h5" sx={{ marginLeft: 1, fontSize: 16 }}>
              (ID: {original?.id})
            </QboTypography>
          </div>
        )}
        <div
          style={{
            overflowX: 'auto',
            marginRight: 5,
            display: 'flex',
            scrollbarWidth: 'thin',
            backgroundColor: '#c4e3f0',
          }}>
          <QboTabs tabIndex={selectTab} title={names} onChange={handleOnTabClick} />
        </div>
        {yourComponent(names, selectTab, original)}
      </QboCard>
    </>
  );
}
