import { useDispatch } from 'react-redux';
import {
  bulkPurgeListPath,
  bulkPurgePath,
  dataIngestionPath,
  dataIngestionListPath,
  mailBoxChangePath,
  mailBoxSyncPath,
  viewAutoDiscoveryPath,
  viewExcludedMailBoxPath,
  domainBackupsPath,
  transferSubresellerPagePath,
  transferPath,
  seatUsageReportsPagePath,
  showPartnerListPath,
  resellersListPath,
  organizationInformationPath,
  showOrganizationListPath,
  m365TenantListPath,
  gwsTenantListPath,
  m365TenantDetailPath,
  gwsTenantDetailPath,
  hexTenantDetailPath,
} from '@config/Routes/WebClientRoutes';
import { resetAutoDiscoveryState } from '@features/autoDiscovery/getAutoDiscoverySlice';
import { resetExcludedMailboxesState } from '@features/excludedMailboxes/getExcludedMailboxesSlice';
import { resetIngestionSubmit } from '@features/ingestion/getIngestionSlice';
import { resetMailChangeRequestStatus } from '@features/mailBox/getMailBoxChangeRequestSlice';
import { resetMailSyncStatus } from '@features/mailBox/getMailBoxSyncSlice';
import { resetMailboxBulkPurgeStatus } from '@features/mailboxBulkPurge/getMailboxBulkPurgeSlice';
import { resetMailboxBulkPurgeEachListStatus } from '@features/mailboxBulkPurge/getMailboxBulkPurgeListSlice';
import { resetDomainBackupsState } from '@features/domainBackups/getDomainBackupsSlice';
import { resetTransferSubresellerState } from '@features/transferSubresellerService/getTransferSubresellerSlice';
import { resetTransferSubscriptionState } from '@features/transferSubscriptionService/getTransferSubscriptionSlice';
import { resetDetailsOfIngestionSubmit } from '@features/ingestion/getDetailsOfIngestionListSlice';
import { resetSeatUsageReportsStatus } from '@features/seatUsageReport/seatUsageSlice';
import { resetSeatUsageSummaryStatus } from '@features/seatUsageReport/seatUsageSummarySlice';
import {
  setOrganizationPaginations,
  setPartnersPaginationState,
  setPlanTabState,
} from '@features/application/applicationSlice';
import { resetOrganizationsListState } from '@features/organizationList/organizationsListSlice';
import { resetSelectedResellerConfigs } from '@features/resellerConfigs/getSelectedResellerConfigsSlice';
import { resetResellerNotificationsConfig } from '@features/resellerConfigs/getResellerNotificationsConfigSlice';
import { resetOrganizationInformationState } from '@features/organizationInformation/organizationInformationSlice';
import { resetM365TenantState } from '@features/m365Tenant/m365TenantSlice';
import { resetGWSTenantState } from '@features/gwsTenant/gwsTenantSlice';
import { resetM365TenantDetailsInfoState } from '@features/m365TenantDetailsInfo/m365TenantDetailsInfoSlice';
import { resetGWSTenantDetailsInfoState } from '@features/gwsTenantDetailsInfo/gwsTenantDetailsInfoSlice';
import { resetHEXTenantDetailsInfoState } from '@features/hexTenantDetails/hexTenantDetailsInfoSlice';
import { resetM365UsersTenantState } from '@features/m365UsersTenant/m365UsersTenantSlice';

export default function useStateReset() {
  const dispatch = useDispatch();

  const resetCommandState = () => {
    const pathName = window.location.pathname;

    const resetFunctions = {
      [viewExcludedMailBoxPath]: [resetExcludedMailboxesState],
      [viewAutoDiscoveryPath]: [resetAutoDiscoveryState],
      [mailBoxChangePath]: [resetMailChangeRequestStatus],
      [mailBoxSyncPath]: [resetMailSyncStatus],
      [dataIngestionPath]: [resetIngestionSubmit],
      [bulkPurgePath]: [resetMailboxBulkPurgeStatus],
      [bulkPurgeListPath]: [resetMailboxBulkPurgeEachListStatus],
      [domainBackupsPath]: [resetDomainBackupsState],
      [transferSubresellerPagePath]: [resetTransferSubresellerState],
      [transferPath]: [resetTransferSubscriptionState],
      [dataIngestionListPath]: [resetDetailsOfIngestionSubmit],
      [seatUsageReportsPagePath]: [resetSeatUsageReportsStatus, resetSeatUsageSummaryStatus],
      [resellersListPath]: [
        setPlanTabState,
        // setOrganizationTabState,
        resetSelectedResellerConfigs,
        resetResellerNotificationsConfig,
      ],
      [organizationInformationPath]: [resetOrganizationInformationState],
      [showOrganizationListPath]: [resetOrganizationsListState],
      [m365TenantDetailPath]: [resetM365TenantDetailsInfoState],
      [gwsTenantDetailPath]: [resetGWSTenantDetailsInfoState],
      [hexTenantDetailPath]: [resetHEXTenantDetailsInfoState],
      [m365TenantDetailPath]: [resetM365UsersTenantState],
    };

    Object.entries(resetFunctions).forEach(([path, resetFunctionArray]) => {
      if (pathName !== showPartnerListPath && pathName !== resellersListPath) {
        dispatch(setPartnersPaginationState());
      }
      if (pathName !== showOrganizationListPath && pathName !== organizationInformationPath) {
        dispatch(setOrganizationPaginations());
      }
      if (pathName !== organizationInformationPath && pathName !== m365TenantListPath) {
        dispatch(resetM365TenantState());
      }
      if (pathName !== organizationInformationPath && pathName !== gwsTenantListPath) {
        dispatch(resetGWSTenantState());
      }
      if (pathName !== path) {
        resetFunctionArray.forEach((resetFunction) => {
          dispatch(resetFunction());
        });
      }
    });
  };

  return { resetCommandState };
}
