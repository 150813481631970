import { useDispatch } from 'react-redux';

import { getExcludedMailboxesAsync } from '@features/excludedMailboxes/getExcludedMailboxesSlice';

export default function useExcludedMailboxes() {
  const dispatch = useDispatch();

  const getExcludedMailboxes = async (paramsList) => {
    dispatch(getExcludedMailboxesAsync(paramsList));
  };

  const getExcludedMailboxesRequestData = (data, setExcludedData) => {
    const modifiedData = data?.map((item) => {
      const payloadObj = JSON.parse(item.payload);
      const modifiedPayloadObj = {
        ...payloadObj,
        'executed-on': item['executed-on'],
        'status-description': item['status-description'],
      };
      return modifiedPayloadObj;
    });
    return setExcludedData(modifiedData);
  };

  return { getExcludedMailboxes, getExcludedMailboxesRequestData };
}
