import { createSlice } from '@reduxjs/toolkit';

import {
  getIngestionReportListAsync,
  getIngestionReportListExtraReducers,
} from './serviceActions/fetchIngestionReportListAsync';

const initialState = {
  getIngestionReportListState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    errorMessage: null,
    reportsPaginationList: null,
    ingestionReportsList: [],
    forbiddenState: false,
    forbiddenErrorMessage: null,
    ingestionReportsCorsErrorMessage: null,
  },
};

const ingestionReportsSlice = createSlice({
  name: 'IngestionReportsList',
  initialState,
  reducers: {
    resetIngestionReportsList(state) {
      state.getIngestionReportListState = initialState.getIngestionReportListState;
    },
  },
  extraReducers: {
    ...getIngestionReportListExtraReducers,
  },
});

export { getIngestionReportListAsync };
export const { resetIngestionReportsList } = ingestionReportsSlice.actions;
export default ingestionReportsSlice.reducer;
