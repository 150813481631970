import { useState } from 'react';
import * as yup from 'yup';
import SchemaBuilder from '@libs/SchemaBuilder';
import { pascalToTitleCase } from '@utilities/stringUtils';

const supportTicketName = 'support-ticket';
const escalationTicketName = 'escalation-ticket';
const descriptionName = 'description';
const organizationOwnerIdName = 'org-owner-id';
const currentEmailName = 'current-email';
const newEmailName = 'new-email';
const changeRequestTypeName = 'change-request-type';
const isBulkName = 'is-bulk';
const prefixName = 'prefix';
const mappingFileName = 'mapping-file';
const emailListingName = 'email-listing';

const InitialForm = {
  [supportTicketName]: '',
  [escalationTicketName]: '',
  [descriptionName]: '',
  [organizationOwnerIdName]: '',
  [currentEmailName]: '',
  [newEmailName]: '',
  [changeRequestTypeName]: false,
  [isBulkName]: false,
  [prefixName]: '',
  [mappingFileName]: '',
  [emailListingName]: '',
};

/* eslint-disable */
export default function useFormValidation() {
  // initialize
  const schemaBuilder = new SchemaBuilder({}, 'yup').create();

  const [isValid, setIsValid] = useState(false);
  const [errors, setErrors] = useState('');
  const [formData, setFormData] = useState(InitialForm);

  //
  // define schemas

  const formSchema = yup.object().shape({
    [supportTicketName]: yup.string().required(),
    [escalationTicketName]: yup.string(),
    [descriptionName]: yup.string(),
    [changeRequestTypeName]: yup.bool(),
    [isBulkName]: yup.bool(),
    [organizationOwnerIdName]: yup.string().required('Organization Owner ID is required field'),
    [currentEmailName]: yup.string().when(isBulkName, {
      is: false,
      then: () => yup.string().required('Current email is required'),
      otherwise: () => yup.string().notRequired(),
    }),
    [newEmailName]: yup
      .string()
      .when(isBulkName, {
        is: false,
        then: () => yup.string().required('New email is required'),
        otherwise: () => yup.string().notRequired(),
      })
      .notOneOf([yup.ref('current-email')], 'New Email must be different from the Current Email'),
    [emailListingName]: yup.string().when(isBulkName, {
      is: true,
      then: () => yup.string().required('Email listing is required'),
      otherwise: () => yup.string().notRequired(),
    }),
    [prefixName]: yup.string().when(isBulkName, {
      is: true,
      then: () => yup.string().required('Prefix name is required'),
      otherwise: () => yup.string().notRequired(),
    }),
    [mappingFileName]: yup
      .mixed()
      .when(isBulkName, {
        is: true,
        then: () => yup.mixed().required(),
        otherwise: () => yup.mixed().notRequired(),
      })
      .test('isValidType', 'Not a valid type', (value) =>
        value ? ['txt', 'csv'].indexOf(value?.name?.split('.')?.pop()) > -1 : true
      ),
  });

  //
  // validate input
  //
  const validate = async (value) => {
    // eslint-disable-next-line func-names

    await formSchema.validate(value, {   abortEarly: false }).catch(function (err) {
      const errorLogs = err.inner.reduce((acc, error) => {
        return {
          ...acc,
          [error.path]: pascalToTitleCase(error.message.split(',')[0]),
        };
      }, {});

      if (value['current-email'] === '' && value['new-email'] === '') {
        errorLogs['new-email'] = 'New email is required';
        errorLogs.description = null;
        errorLogs['escalation-ticket'] = null;
      }
      setErrors(errorLogs);
      return errorLogs;
    });

    const data = {
      [supportTicketName]: value[supportTicketName],
      [escalationTicketName]: value[escalationTicketName],
      [descriptionName]: value[descriptionName],
      [organizationOwnerIdName]: value[organizationOwnerIdName],
      [currentEmailName]: value[currentEmailName],
      [newEmailName]: value[newEmailName],
      [changeRequestTypeName]: value[changeRequestTypeName],
      [isBulkName]: value[isBulkName],
      [prefixName]: value[prefixName],
      [mappingFileName]: value[mappingFileName],
      [emailListingName]: value[emailListingName],
    };

    const validation = await formSchema.isValid(data);
    if (validation) {
      setErrors({});
    }
    setIsValid(validation);
  };

  const validateAll = () => {
    validate(formData);
  };

  // Update form data
  const updateFormData = (newData) => {
    setFormData((prev) => {
      return { ...prev, ...newData };
    });
    // validate(newData);
  };

  //
  // construct the attributes
  //
  const supportTicketAttributes = {
    handleChange: async (value) => {
      updateFormData({ [supportTicketName]: value });
    },
    errorMessage: errors[supportTicketName],
    value: formData[supportTicketName],
  };

  const changeRequestTypeAttributes = {
    handleChange: async (value) => {
      updateFormData({ [changeRequestTypeName]: value });
    },
    errorMessage: errors[changeRequestTypeName],
    value: formData[changeRequestTypeName],
  };

  const isBulkAttributes = {
    handleChange: async (value) => {
      updateFormData({ [isBulkName]: value });
    },
    errorMessage: errors[isBulkName],
    value: formData[isBulkName],
  };

  const escalationTicketAttributes = {
    handleChange: async (value) => {
      updateFormData({ [escalationTicketName]: value });
    },
    value: formData[escalationTicketName],
  };
  const descriptionAttributes = {
    handleChange: async (value) => {
      updateFormData({ [descriptionName]: value });
    },
    value: formData[descriptionName],
  };
  const organizationOwnerIdAttributes = {
    handleChange: async (value) => {
      updateFormData({ [organizationOwnerIdName]: value });
    },
    errorMessage: errors[organizationOwnerIdName],
    value: formData[organizationOwnerIdName],
  };
  const currentEmailAttributes = {
    handleChange: async (value) => {
      updateFormData({ [currentEmailName]: value });
    },
    errorMessage: errors[currentEmailName],
    value: formData[currentEmailName],
  };
  const emailListingAttributes = {
    handleChange: async (value) => {
      updateFormData({ [emailListingName]: value });
    },
    errorMessage: errors[emailListingName],
    value: formData[emailListingName],
  };
  const newEmailAttributes = {
    handleChange: async (value) => {
      updateFormData({ [newEmailName]: value });
    },
    errorMessage: errors[newEmailName],
    value: formData[newEmailName],
  };
  const prefixAttributes = {
    handleChange: async (value) => {
      updateFormData({ [prefixName]: value });
    },
    errorMessage: errors[prefixName],
    value: formData[prefixName],
  };
  const handleFileWrite = (value) => {
    if (value) {
      updateFormData({ [mappingFileName]: value });
    }
  };
  const mappingFileAttributes = {
    handleChange: async (value) => {
      handleFileWrite(value);
    },
    errorMessage: errors[mappingFileName],
    value: formData[mappingFileName],
  };

  const resetForm = () => {
    setFormData(InitialForm);
    setErrors({});
    setIsValid(false);
  };

  return {
    formData,
    supportTicketAttributes,
    escalationTicketAttributes,
    descriptionAttributes,
    organizationOwnerIdAttributes,
    currentEmailAttributes,
    newEmailAttributes,
    changeRequestTypeAttributes,
    isBulkAttributes,
    prefixAttributes,
    mappingFileAttributes,
    emailListingAttributes,
    validateAll,
    isValid,
    resetForm,
  };
}
