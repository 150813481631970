import { createAsyncThunk } from '@reduxjs/toolkit';
import { transferSubresellerApi } from '@services/WebApiService';

const transferSubresellerAsync = createAsyncThunk('TransferSubreseller', async (paramsList) => {
  try {
    const data = await transferSubresellerApi(paramsList);
    return data;
  } catch (err) {
    return { error: err?.response };
  }
});

const { pending, fulfilled, rejected } = transferSubresellerAsync;

const transferSubresellerExtraReducers = {
  [pending]: (state) => {
    state.transferSubreseller.submitting = true;
  },
  [rejected]: (state) => {
    state.transferSubreseller.submitting = false;
  },
  [fulfilled]: (state, action) => {
    state.transferSubreseller.submitting = false;

    if (action?.payload?.error) {
      state.transferSubreseller.error = action?.payload?.error?.data?.message;

      if (action?.payload?.error?.status === 403) {
        state.transferSubreseller.errorMessage = 'You are not authorize to access this resource';
      }
    } else if (action?.payload?.status === 200) {
      state.transferSubreseller.success = true;
    }

    if (!action?.payload?.error && !action.payload?.data && action?.payload?.status !== 200) {
      state.transferSubreseller.errorMessage =
        'Something went wrong while processing your request.';
    }
  },
};

export { transferSubresellerAsync, transferSubresellerExtraReducers };
