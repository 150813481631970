import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { MenuItem, Popover, Box, Avatar, IconButton, Menu } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { Logout as LogoutIcon, Check } from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LockIcon from '@mui/icons-material/Lock';
import { useTranslation } from 'react-i18next';

import { QboButton, QboModal, QboTypography } from '@ui/Components';
import useLocalStorage from '@hooks/useLocalStorage';
import BaseSetting, { constant } from '@config/BaseSetting';
import useRegionsList from '@pages/sharedHooks/useRegionsList';
import useSilentToken from '@pages/sharedHooks/useSilentToken';
import useLogoutAccount from '@pages/sharedHooks/useLogoutAccount';
import { resetRefreshStatusCode } from '@features/auth/refreshTokenSlice';
import { useLocation, useNavigate } from 'react-router-dom';

export default function MenuRightBlock() {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [selectRegion, setSelectRegion] = useLocalStorage(constant.SELECTED_REGIONS);
  const [storeAccessToken, setStoreAccessToken, deleteStoreAccessToken] = useLocalStorage(
    constant.ACCESS_TOKEN_STORAGE
  );
  const [value] = useLocalStorage(constant.REGIONS_LIST);

  const [regionAnchorEl, setRegionAnchorEl] = useState(null);
  const [logoutAnchorE2, setLogoutAnchorE2] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [regionName, setRegionName] = useState('');
  const [regionsList, setRegionsList] = useState([]);
  const [userName, setUserName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [open, setOpen] = useState(false);

  const { setRegionsListToState } = useRegionsList();
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const { getSilentToken } = useSilentToken();
  const { accountLogoutRedirect } = useLogoutAccount(instance);

  const { getRefToken } = useSelector((stateList) => stateList.refToken);
  const { getSilentTokenState } = useSelector((stateList) => stateList.silent);
  const { getRegions } = useSelector((stateList) => stateList.region);
  const { getAccessTokenState } = useSelector((stateList) => stateList.tokenAccess);
  const { success } = getSilentTokenState;
  const navigate = useNavigate();
  const { active } = BaseSetting.accountStatusCode;
  const { currentAccountStatus } = getAccessTokenState;
  const { t } = useTranslation();

  useEffect(() => {
    if (isAuthenticated && !state?.userNameError) {
      try {
        const { username, name } = instance.getActiveAccount();
        setUserName(username);
        setFirstName(name);
      } catch (error) {
        navigate('/login', { state: { userNameError: true } });
      }
    }
  }, []);

  const setRegionState = async () => {
    await setRegionsListToState(value, setRegionsList, setRegionName);
  };

  useEffect(() => {
    if (value) {
      setRegionState();
    }
  }, [value?.length]);

  useEffect(() => {
    if (getRefToken?.statusCode === 401) {
      setOpen(true);
    }
  }, [getRefToken?.statusCode]);

  const regionsPresenceHandler = () => {
    return Boolean(
      getSilentTokenState?.silentAccessToken &&
        (!selectRegion || !value) &&
        !getRegions.fetching &&
        getAccessTokenState?.tokenState
    );
  };

  const handleSignOut = async () => {
    await accountLogoutRedirect();
  };

  const handleClick = async (event) => {
    setRegionAnchorEl(event.currentTarget);
  };

  const handleOnLogoutClose = () => {
    setLogoutAnchorE2(null);
  };

  const handleOnLogoutClick = async (event) => {
    setLogoutAnchorE2(event.currentTarget);
  };

  const handleClose = () => {
    setRegionAnchorEl(null);
  };

  const handleMenuItemClick = (region) => {
    if (selectRegion !== region) {
      setSelectRegion(region);
      window.location.reload();
    }
    setRegionName(region);

    handleClose();
  };

  const logBackClickHandler = async () => {
    await dispatch(resetRefreshStatusCode());
    await deleteStoreAccessToken();
    await getSilentToken();
    setOpen(false);
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const renderSnackBar = () => {
    if (errorMsg !== '') {
      return (
        <MuiAlert
          variant="filled"
          style={{
            height: 20,
            alignItems: 'center',
            color: '#d32f2f',
            backgroundColor: 'white',
            textAlign: 'center',
            display: 'inline-flex',
          }}
          severity="error">
          {errorMsg}
        </MuiAlert>
      );
    }
    return <div style={{ height: 5 }} />;
  };

  const renderMenuItem = (region) => {
    return (
      <MenuItem
        key={region}
        value={regionName}
        style={{
          color: region === regionName && 'rgb(0, 114, 229)',
          backgroundColor: region === regionName && 'rgba(194, 224, 255, 0.6)',
        }}
        onClick={() => handleMenuItemClick(region)}>
        {region}
        {region === regionName && (
          <Check
            style={{
              color: region === regionName && 'rgb(0, 114, 229)',
              marginLeft: '0.5em',
            }}
          />
        )}
      </MenuItem>
    );
  };

  return (
    <Box className="Link__wrapper">
      {isAuthenticated && (
        <>
          {regionsList?.length > 0 && success && currentAccountStatus === active && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <QboButton
                variant="text"
                disableRipple
                onClick={handleClick}
                endIcon={<ArrowDropDownIcon />}>
                {regionName}
              </QboButton>
              <Popover
                open={!!regionAnchorEl}
                anchorEl={regionAnchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}>
                {regionsList?.length > 0 &&
                  regionsList.map((region) =>
                    renderMenuItem(`${region.regionCode} (${region.regionName})`)
                  )}
              </Popover>
              {renderSnackBar()}
            </div>
          )}
          <QboModal
            header={<div>{}</div>}
            open={open || regionsPresenceHandler()}
            preventOutsideClick
            classNameStyle="session_modal">
            <LockIcon sx={{ width: '5.5rem', height: '5.5rem' }} color="primary" />
            <QboTypography
              sx={{
                fontSize: '2.8rem',
                fontWeight: 'bold',
                color: '#0276aa',
                fontFamily: 'Times New Roman',
              }}>
              {t('session_timeout')}
            </QboTypography>
            <br />
            <QboButton
              sxText={{ fontSize: '1.5rem !important', fontFamily: 'Times New Roman' }}
              variant="contained"
              onClick={() => {
                logBackClickHandler();
              }}>
              {t('log_back_in')}
            </QboButton>
          </QboModal>
          {userName && success && currentAccountStatus === active && (
            <IconButton sx={{ position: 'relative' }} onClick={handleOnLogoutClick}>
              <Avatar sx={{ bgcolor: '#009fdf', width: 30, height: 30 }}>
                {firstName.charAt(0)}
              </Avatar>
            </IconButton>
          )}
          {userName && success && currentAccountStatus === active && (
            <Menu
              sx={{ maxWidth: '400px !important', minWidth: '250px', height: 400 }}
              open={logoutAnchorE2}
              anchorEl={logoutAnchorE2}
              onClose={handleOnLogoutClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}>
              <QboTypography
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: 2,
                  color: '#6b778c',
                  fontWeight: 'bold',
                }}>
                {t('account')}
              </QboTypography>
              <MenuItem>
                <Avatar sx={{ bgcolor: '#009fdf' }}>{firstName.charAt(0)}</Avatar>
                <MenuItem
                  sx={{
                    pointerEvents: 'none',
                    marginTop: 1,
                    marginBottom: 1,
                    fontWeight: 'bold',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'baseline',
                    color: '#6b778c',
                  }}>
                  <QboTypography sx={{ fontSize: 14 }}>{firstName}</QboTypography>
                  <QboTypography sx={{ fontSize: 12 }}>{userName}</QboTypography>
                </MenuItem>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleSignOut();
                }}>
                {t('logout')}
              </MenuItem>
            </Menu>
          )}
        </>
      )}
    </Box>
  );
}
