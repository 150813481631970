import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

export default function ITBox({ children, direction, className, ...rest }) {
  return (
    <Box
      className={`ItBox__wrapper ${
        direction === 'row' ? 'ItBox__direction-row' : 'ItBox__direction-column'
      } ${className}`}
      {...rest}>
      {children}
    </Box>
  );
}

ITBox.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  direction: PropTypes.string,
};
ITBox.defaultProps = {
  className: '',
  children: null,
  direction: 'row',
};
