import React, { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { QboTypography, QboButton } from '@ui/Components';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import useStepper from '@pages/sharedHooks/useStepper';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    // top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 30,
  height: 30,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className, listIcon } = props;

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {listIcon[String(props?.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
  listIcon: PropTypes.arrayOf(PropTypes.node),
};

ColorlibStepIcon.defaultProps = {
  active: false,
  className: '',
  completed: false,
  icon: null,
  listIcon: [null],
};

export default function StepperBlock({
  className,
  children,
  steps,
  icons,
  optionalStep,
  freezeSteps,
  showButton,
  hideLabel,
  activeStepAlt,
  isStepOptionalAlt,
  isStepSkippedAlt,
  onNext,
  onBack,
  onSkip,
  onReset,
  ...rest
}) {
  const {
    activeStep: activeStepDefault,
    isStepOptional: isStepOptionalDefault,
    isStepSkipped: isStepSkippedDefault,
    handleNext: handleNextDefault,
    handleBack: handleBackDefault,
    handleSkip: handleSkipDefault,
    handleReset: handleResetDefault,
    newSteps,
  } = useStepper({ optionalStep, steps, freezeSteps });
  const arrayChildren = Children.toArray(children);

  const activeStep = activeStepAlt !== null ? activeStepAlt : activeStepDefault;
  const isStepOptional = isStepOptionalAlt !== null ? isStepOptionalAlt : isStepOptionalDefault;
  const isStepSkipped = isStepSkippedAlt !== null ? isStepSkippedAlt : isStepSkippedDefault;
  const isLastStep =
    arrayChildren.length > steps.length
      ? activeStep === steps.length
      : activeStep === steps.length - 1;
  freezeSteps?.sort();

  const handleNext = () => {
    if (typeof onNext === 'function') {
      onNext();
    } else {
      handleNextDefault();
    }
  };

  const handleBack = () => {
    if (typeof onNext === 'function') {
      onBack();
    } else {
      handleBackDefault();
    }
  };

  const handleSkip = () => {
    if (typeof onNext === 'function') {
      onSkip();
    } else {
      handleSkipDefault();
    }
  };

  const handleReset = () => {
    if (typeof onNext === 'function') {
      onReset();
    } else {
      handleResetDefault();
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper
        className="StepperBlock__wrapper"
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = <QboTypography variant="caption">(Optional)</QboTypography>;
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel
                StepIconComponent={(props) => ColorlibStepIcon({ ...props, listIcon: icons })}
                {...labelProps}>
                {!hideLabel ? label : null}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Box className="StepperBlock__content">
        <Box>
          {/* {newSteps.map((x, index) => {
            return children && index === activeStep && typeof arrayChildren[x] === 'object' ? (
              <>{cloneElement(arrayChildren[x])}</>
            ) : null;
          })} */}
          {children && typeof arrayChildren[newSteps[activeStep]] === 'object' ? (
            <>{cloneElement(arrayChildren[newSteps[activeStep]])}</>
          ) : null}
        </Box>
      </Box>
      {showButton ? (
        <Box className="StepperBlock__footer">
          <QboButton
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}>
            Back
          </QboButton>
          <Box sx={{ flex: '1 1 auto' }} />
          {isStepOptional(activeStep) && (
            <QboButton color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
              Skip
            </QboButton>
          )}
          {isLastStep ? (
            <QboButton onClick={handleReset}>Reset</QboButton>
          ) : (
            <QboButton onClick={handleNext}>
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </QboButton>
          )}
        </Box>
      ) : null}
    </Box>
  );
}

StepperBlock.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
  steps: PropTypes.arrayOf(PropTypes.string),
  icons: PropTypes.objectOf(PropTypes.node),
  optionalStep: PropTypes.arrayOf(PropTypes.number),
  freezeSteps: PropTypes.arrayOf(PropTypes.number),
  showButton: PropTypes.bool,
  hideLabel: PropTypes.bool,
  activeStepAlt: PropTypes.number,
  isStepOptionalAlt: PropTypes.func,
  isStepSkippedAlt: PropTypes.func,
  onNext: PropTypes.func,
  onBack: PropTypes.func,
  onSkip: PropTypes.func,
  onReset: PropTypes.func,
};

StepperBlock.defaultProps = {
  children: '',
  className: '',
  steps: [],
  icons: {},
  optionalStep: [],
  freezeSteps: [],
  showButton: false,
  hideLabel: false,
  activeStepAlt: null,
  isStepOptionalAlt: null,
  isStepSkippedAlt: null,
  onNext: null,
  onBack: null,
  onSkip: null,
  onReset: null,
};
