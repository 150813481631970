import { createAsyncThunk } from '@reduxjs/toolkit';
import { getBulkPurgeMailStatusAPI } from '@services/WebApiService';

const fetchMailboxBulkPurgeStatusItem = createAsyncThunk('BulkPurgeStatus', async (paramsList) => {
  try {
    const data = await getBulkPurgeMailStatusAPI(paramsList);
    return data;
  } catch (error) {
    return { error: error.response };
  }
});

const { pending, fulfilled, rejected } = fetchMailboxBulkPurgeStatusItem;

const mailboxBulkPurgeStatusItemReducers = {
  [pending]: (state) => {
    state.mailboxBulkPurgeStatus.fetching = true;
  },
  [rejected]: (state) => {
    state.mailboxBulkPurgeStatus.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.mailboxBulkPurgeStatus.fetching = false;

    if (action?.payload?.error) {
      state.mailboxBulkPurgeStatus.errorMessage = action.payload.error?.data.message;
      state.mailboxBulkPurgeStatus.statusCode = action.payload.error?.status;

      if (action?.payload?.error?.status === 403) {
        state.mailboxBulkPurgeStatus.forbiddenState = true;
        state.mailboxBulkPurgeStatus.forbiddenErrorMessage = {
          heading: 'Forbidden Error',
          caption: 'You are not authorize to access this resource',
        };
      }
    }

    if (!action?.payload?.data && !action.payload?.error) {
      state.mailboxBulkPurgeStatus.bulkPurgeCorsErrorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.data) {
      state.mailboxBulkPurgeStatus.forbiddenState = false;
      state.mailboxBulkPurgeStatus.forbiddenErrorMessage = {};
      state.mailboxBulkPurgeStatus.statusCode = action.payload?.status;
      state.mailboxBulkPurgeStatus.bulkPurgeStatusData = [action?.payload?.data];
    }
  },
};

export { fetchMailboxBulkPurgeStatusItem, mailboxBulkPurgeStatusItemReducers };

export default fetchMailboxBulkPurgeStatusItem;
