import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import QboSortByButton from '@ui/Components/QboSortByButton';
import usePartnerFilterHook from '@pages/sharedHooks/usePartnerFilterHook';

export default function PartnerFilterSection(props) {
  const { parentId, value, handleChange, label, inputSize, marginTopValue, marginRightValue } =
    props;
  const { partnerFilterState, resetPartnerFilter, UpdateFilters, partnerList } =
    usePartnerFilterHook(parentId, value);

  useEffect(() => {
    handleChange('');
  }, [parentId]);

  return (
    <QboSortByButton
      defaultValue="All"
      forceUpdate={partnerFilterState.forceUpdate}
      searchState={partnerFilterState.searchState}
      handleCloseClick={() => {
        resetPartnerFilter();
        handleChange('');
      }}
      isSearchEnable={!false}
      handleOnSearchChange={(e) => {
        UpdateFilters(e.target.value, ['searchState']);
      }}
      handleChange={(e) => {
        UpdateFilters(e.target.value, ['selectedPartner']);
        handleChange(e.target.value);
      }}
      marginRightValue={marginRightValue}
      marginTopValue={marginTopValue}
      value={partnerFilterState.selectedPartner}
      buttonWidth={220}
      startAdornmentTextWidth={inputSize}
      startAdornmentText={label}
      menuItemList={partnerList}
    />
  );
}

PartnerFilterSection.propTypes = {
  parentId: PropTypes.number,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  label: PropTypes.string,
  inputSize: PropTypes.number,
  marginTopValue: PropTypes.number,
  marginRightValue: PropTypes.number,
};

PartnerFilterSection.defaultProps = {
  parentId: 0,
  value: '',
  handleChange: () => {},
  label: 'Partners',
  inputSize: 180,
  marginTopValue: undefined,
  marginRightValue: 2,
};
