import React from 'react';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { HomeOutlined as HomeOutlinedIcon } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { QboCard, QboFormGroup, QboTextField, QboAlert } from '@ui/Components';
import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import QboBreadcrums from '@ui/Components/QboBreadcrumbs';
import useForm from './pageHooks/useForm';

export default function FormPage() {
  const { t } = useTranslation();
  const {
    usernameAttributes,
    emailAttributes,
    genderAttributes,
    addressAttributes,
    phoneAttributes,
    pinCodeAttributes,
    isValid,
    success,
    onSubmit,
  } = useForm();

  return (
    <>
      <HeaderPageBlock>{t('form_page.header.text')}</HeaderPageBlock>
      <QboBreadcrums className="map-link">
        <Link
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
          style={{ color: 'black', fontSize: 14, display: 'flex', alignItems: 'center' }}
          to="/dashboard">
          <HomeOutlinedIcon sx={{ mr: 0.5 }} fontSize="medium" />
          {t('breadcrums.text')}
        </Link>
        <Typography style={{ fontSize: 14, color: 'black', fontFamily: 'inter' }}>
          {t('breadcrums.mailbox_change.new_request')}
        </Typography>
      </QboBreadcrums>

      <QboCard noPadding isMainWrapper>
        <Box className="FormPage__wrapper">
          <QboFormGroup onSubmit={onSubmit} withSubmitButton buttonLabel="Submit">
            {isValid ? (
              success && (
                <QboAlert type="success" style={{ fontSize: '1.15em' }}>
                  Success
                </QboAlert>
              )
            ) : (
              <QboAlert type="error" style={{ fontSize: '1.15em' }}>
                Please fill all the required field
              </QboAlert>
            )}
            <QboTextField
              id="username"
              placeholder={t('form_page.form.username_label')}
              label={t('form_page.form.username_label')}
              fullWidth
              value={usernameAttributes.value}
              name="test-one"
              onChange={(e) => {
                usernameAttributes.handleChange(e.currentTarget.value);
              }}
              errorMessage={usernameAttributes.errorMessage}
              withoutErrorIcon
              inputProps={{ maxLength: 225 }}
            />
            <QboTextField
              id="email"
              placeholder={t('form_page.form.email_label')}
              label={t('form_page.form.email_label')}
              fullWidth
              value={emailAttributes.value}
              name="test-one"
              onChange={(e) => {
                emailAttributes.handleChange(e.currentTarget.value);
              }}
              errorMessage={emailAttributes.errorMessage}
              withoutErrorIcon
              inputProps={{ maxLength: 225 }}
            />
            <QboTextField
              id="gender"
              placeholder={t('form_page.form.gender_label')}
              label={t('form_page.form.gender_label')}
              fullWidth
              value={genderAttributes.value}
              name="test-one"
              onChange={(e) => {
                genderAttributes.handleChange(e.currentTarget.value);
              }}
              errorMessage={genderAttributes.errorMessage}
              withoutErrorIcon
              inputProps={{ maxLength: 225 }}
            />
            <QboTextField
              id="address"
              placeholder={t('form_page.form.address_label')}
              label={t('form_page.form.address_label')}
              fullWidth
              value={addressAttributes.value}
              name="test-one"
              onChange={(e) => {
                addressAttributes.handleChange(e.currentTarget.value);
              }}
              errorMessage={addressAttributes.errorMessage}
              withoutErrorIcon
              inputProps={{ maxLength: 225 }}
            />
            <QboTextField
              id="phone"
              placeholder={t('form_page.form.phone_label')}
              label={t('form_page.form.phone_label')}
              fullWidth
              value={phoneAttributes.value}
              name="test-one"
              onChange={(e) => {
                phoneAttributes.handleChange(e.currentTarget.value);
              }}
              errorMessage={phoneAttributes.errorMessage}
              withoutErrorIcon
              inputProps={{ maxLength: 225 }}
            />
            <QboTextField
              id="pin-code"
              placeholder={t('form_page.form.pin_code_label')}
              label={t('form_page.form.pin_code_label')}
              fullWidth
              value={pinCodeAttributes.value}
              name="test-one"
              onChange={(e) => {
                pinCodeAttributes.handleChange(e.currentTarget.value);
              }}
              errorMessage={pinCodeAttributes.errorMessage}
              withoutErrorIcon
              inputProps={{ maxLength: 225 }}
            />
          </QboFormGroup>
        </Box>
      </QboCard>
    </>
  );
}
