import { useDispatch } from 'react-redux';
import { fetchMonthlyBackupReportsAsync } from '@features/monthlyBackupReport/serviceActions/fetchMonthlyBackupReportsAsync';
import {
  resetMonthlyBackupReportsErrorStatus,
  resetMonthlyBackupReportsListStatus,
} from '@features/monthlyBackupReport/monthlyBackupSlice';
import {
  exportMonthlyBackupReportAsync,
  resetExportMonthlyBackupReportStatus,
} from '@features/monthlyBackupReport/exportMonthlyBackupSlice';
import useLocalStorage from '@hooks/useLocalStorage';
import { constant } from '@config/BaseSetting';

export default function useFetchHandler() {
  const dispatch = useDispatch();
  const [accessToken] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);

  const fetchHandler = (paramsList) => {
    dispatch(fetchMonthlyBackupReportsAsync({ accessToken, reset: true, ...paramsList }));
  };

  const fetchExportHandler = (paramsList) => {
    dispatch(exportMonthlyBackupReportAsync({ accessToken, reset: true, ...paramsList }));
  };

  const conditionalStateReset = (statusCode, fetching, summaryFetching) => {
    if (statusCode !== 200) {
      dispatch(resetMonthlyBackupReportsListStatus());
    }

    if (statusCode !== 200) {
      dispatch(resetMonthlyBackupReportsListStatus());
    }

    if (statusCode === 200 || fetching) {
      dispatch(resetMonthlyBackupReportsErrorStatus());
    }
  };

  const responseDataConverter = (data, setReportData) => {
    const modifiedData = data?.map((item) => {
      const modifiedPayloadObj = {
        'organization-Id': item['organization-Id'],
        name: item?.name,
        'mailbox-Num': item['mailbox-Num'],
        'sites-Num': item['sites-Num'],
        'drive-Num': item['drive-Num'],
        'total-In-Gb': item['total-In-Gb'],
      };
      return modifiedPayloadObj;
    });
    return setReportData(modifiedData);
  };

  const convertToCsv = (reportsData, setDownloadStatus, setOpen, setType) => {
    try {
      const csvData = `data:text/csv;charset=utf-8,${encodeURIComponent(reportsData)}`;
      const link = document.createElement('a');
      link.href = csvData;
      link.download = 'reports.csv';
      document?.body?.appendChild(link);
      link.click();
      document?.body?.removeChild(link);

      setType('success');
      setDownloadStatus('Successfully Downloaded');
    } catch (error) {
      setType('error');
      setDownloadStatus(error);
    }

    setOpen(true);
    dispatch(resetExportMonthlyBackupReportStatus());
  };

  return {
    fetchHandler,
    conditionalStateReset,
    responseDataConverter,
    fetchExportHandler,
    convertToCsv,
  };
}
