import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import useUserBackupFecthing from '@pages/UserBackupPage/pageHooks/useUserBackupFecthing';
import { Skeleton } from '@mui/material';
import { QboCard } from '@ui/Components';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import QboCardSingleList from '@ui/Components/QboCardSingleList';
import { resetLastBackupState } from '@features/userBackup/userBackupInfoSlice';

export default function UserBackupDetailSection(props) {
  const dispatch = useDispatch();
  const { userBackupId, backupType, setLastBackupId } = props;
  const { userBackupDetailState } = useSelector((state) => state.userBackupState);
  const { fetching, data } = userBackupDetailState;

  const { handleFetchUserBackupDetailAsync, detailRows } = useUserBackupFecthing();

  const fetchHandler = () => {
    if (!fetching) {
      handleFetchUserBackupDetailAsync(userBackupId, backupType);
    }
  };

  useEffect(() => {
    dispatch(resetLastBackupState());

    if (userBackupId) fetchHandler();
  }, [userBackupId]);

  useEffect(() => {
    if (data) {
      setLastBackupId(data.lastBackupId);
    }
  }, [data]);

  const renderLoading = () => {
    return (
      <div style={{ display: 'contents' }}>
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="70%" />
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="70%" />
      </div>
    );
  };

  const renderList = () => {
    const elmList = [];
    const items = detailRows(backupType);
    Object.keys(items).forEach((key, item) => {
      elmList.push(
        <QboCardSingleList
          key={key}
          subHeadingText={items[key]?.sub}
          leftContent={items[key]?.title || items[key]}
          rightContent={data[key]?.toString() || '-'}
        />
      );
    });
    return elmList;
  };

  const componentHandler = () => {
    if (fetching) return renderLoading();

    if (data) {
      return (
        <QboCard
          className="QboCard__wrapper_search_text_highlight"
          sx={{
            scrollbarWidth: 'none !important',
            overflowY: 'scroll !important',
            height: '65vh',
          }}>
          <QboCard noPadding>{renderList()}</QboCard>
        </QboCard>
      );
    }

    return <NoStatusSection />;
  };

  return componentHandler();
}

UserBackupDetailSection.propTypes = {
  userBackupId: PropTypes.number,
  backupType: PropTypes.string,
  setLastBackupId: PropTypes.func,
};

UserBackupDetailSection.defaultProps = {
  userBackupId: null,
  backupType: 'email',
  setLastBackupId: () => {},
};
