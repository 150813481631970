import { useDispatch, useSelector } from 'react-redux';
import { constant } from '@config/BaseSetting';
import useLocalStorage from '@hooks/useLocalStorage';
import { resetBulkCreateOrganizationState } from '@features/bulkCreateOrganization/bulkCreateOrganizationSlice';
import { useEffect } from 'react';
import useCsvParser from '@hooks/useCsvParser';
import { showFixedAlert } from '@features/application/applicationSlice';
import { useTranslation } from 'react-i18next';
import ClientRoutes from '@config/Routes/WebClientRoutes';
import {
  bulkCreateSubResellerAsync,
  resetBulkCreateSubResellerState,
} from '@features/bulkCreateSubReseller/bulkCreateSubResellerSlice';
import useBulkCreateSubResellerForm from './useBulkCreateSubResellerForm';

export default function useBulkCreateSubReseller({
  selectedPartner,
  onSuccessRequest,
  onFailedRequest,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const form = useBulkCreateSubResellerForm();
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const { bulkCreateSubResellerState } = useSelector((state) => state.bulkCreateSubReseller);
  const csvParser = useCsvParser([
    'email',
    'first_name',
    'last_name',
    'organization_name',
    'telephone',
    'billing_address',
    'postal_code',
    'archiver_plan',
    'backup_plan',
    'personal_plan',
    'password',
  ]);

  const submitRequest = () => {
    dispatch(
      bulkCreateSubResellerAsync({
        accessTokenSet,
        reset: true,
        parameters: {
          'support-ticket': form.supportTicketAttributes.value,
          description: form.descriptionAttributes.value,
          'escalation-ticket': form.escalationTicketAttributes.value,
          'reseller-id': selectedPartner.id,
          'create-subreseller-requests-csv': csvParser.originalCsv,
        },
      })
    );
  };

  const handleOnValidateData = (e) => {
    form.onValidateData(e);
  };

  useEffect(() => {
    if (form.isValid) {
      submitRequest();
    }
  }, [form.isValid]);

  useEffect(() => {
    form.csvValidAttributes.handleChange(csvParser.result.isValid);
  }, [csvParser.result]);

  const resetAll = () => {
    dispatch(resetBulkCreateSubResellerState());
    form.resetForm();
    csvParser.reset();
  };

  useEffect(() => {
    if (bulkCreateSubResellerState.statusCode === 200) {
      if (bulkCreateSubResellerState.serviceType) {
        const link = ClientRoutes.toolsRequestStatusPath({
          'service-type': bulkCreateSubResellerState.serviceType,
        });
        dispatch(
          showFixedAlert({
            descriptionHtml: t(
              'partner_list_page.bulk_create_subreseller.success_submitted_with_link',
              {
                link,
              }
            ),
          })
        );
      } else {
        dispatch(
          showFixedAlert({
            descriptionHtml: t('partner_list_page.bulk_create_subreseller.success_submitted'),
          })
        );
      }
      if (onSuccessRequest) onSuccessRequest();
    } else if (bulkCreateSubResellerState.error && onFailedRequest) {
      onFailedRequest(bulkCreateSubResellerState.error?.data?.message);
    }
    resetAll();
  }, [bulkCreateSubResellerState.statusCode]);

  return {
    form,
    bulkCreateSubResellerState,
    parseResult: csvParser.result,
    handleOnValidateData,
    handleOnFileUpload: csvParser.handleOnFileUpload,
    resetAll,
  };
}
