import { createAsyncThunk } from '@reduxjs/toolkit';
import { switchSPACommandApi } from '@services/WebApiService';

const switchSPATypeAsync = createAsyncThunk('switcSPAType/switchSPAType', async (paramsList) => {
  try {
    const data = await switchSPACommandApi(paramsList);
    return data;
  } catch (error) {
    return { error: error.response };
  }
});

const { pending, fulfilled, rejected } = switchSPATypeAsync;

const switchSPATypeExtraReducers = {
  [pending]: (state) => {
    state.updateSPATypeState.submitting = true;
  },
  [rejected]: (state) => {
    state.updateSPATypeState.submitting = false;
  },
  [fulfilled]: (state, action) => {
    state.updateSPATypeState.submitting = false;
    if (action?.payload?.error) {
      state.updateSPATypeState.error = action?.payload?.error?.data?.message;
    } else if (action?.payload?.status === 200) {
      state.updateSPATypeState.success = true;
    } else if (
      !action?.payload?.error &&
      !action?.payload?.data &&
      action?.payload?.status !== 200
    ) {
      state.updateSPATypeState.errorMessage = 'Something went wrong while processing your request.';
    }
    if (action?.payload?.error?.status === 403) {
      state.updateSPATypeState.success = false;
      state.updateSPATypeState.errorMessage = 'You are not authorize to access this resource';
    }
  },
};

export { switchSPATypeAsync, switchSPATypeExtraReducers };
