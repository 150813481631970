import React from 'react';
import { useTranslation } from 'react-i18next';

import StepperBlock from '@ui/Blocks/Shared/StepperBlock';
import { QboModal, QboAlert, QboCard, QboAdvanceTable, QboTypography } from '@ui/Components';
import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import NewRequestButtonSection from '@ui/Sections/Shared/NewRequestButtonSection';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import RequestFormSection from '@ui/Sections/ChangeBackupAdminPage/RequestFormSection';
import RequestConfirmSection from '@ui/Sections/ChangeBackupAdminPage/RequestConfirmSection';

import useChangeBackupAdmin from './pageHooks/useChangeBackupAdmin';

export default function ChangeBackupAdminPage() {
  // Hooks
  const { t } = useTranslation();

  // Related to Page Hooks
  const {
    getChangeBackupAdminsState,
    changeBackupAdminState,
    tableColumns,
    form,
    closeSetupModal,
    openSetupModal,
    showRequestModal,
    stepper,
    onSubmitForm,
    onStepBack,
    pagination,
    fetchListAsync,
    validateChangeBackupAdminState,
    onValidateData,
    detailMessageDialog,
  } = useChangeBackupAdmin();

  const {
    changeBackupAdminsCorsErrorMessage,
    fetching,
    list,
    forbiddenState,
    forbiddenErrorMessage,
  } = getChangeBackupAdminsState;

  const { status: createStatusResponse, message: createMessage } = changeBackupAdminState;

  return (
    <>
      {/* Detail Message */}
      <QboModal
        classNameStyle="Qbo_model_error_message"
        title="Detail Message"
        showCloseIcon
        open={detailMessageDialog.showMesageDialog}
        onClose={() => detailMessageDialog.setShowMesageDialog(false)}>
        <QboTypography wrap={!false}>{detailMessageDialog.messageDialog}</QboTypography>
      </QboModal>

      {/* Header */}
      <HeaderPageBlock>{t('change_backup_admin.header.text')}</HeaderPageBlock>

      {/* Alert */}
      {(changeBackupAdminsCorsErrorMessage || createStatusResponse === 200) && (
        <QboAlert
          type={createStatusResponse === 200 ? 'success' : 'error'}
          style={{ fontSize: '1.15em' }}>
          {changeBackupAdminsCorsErrorMessage ||
            (createStatusResponse === 200 && 'Successfully submitted')}
        </QboAlert>
      )}

      {/* Main Section */}
      <QboCard>
        {/* New Request Button Section */}
        <NewRequestButtonSection
          title={t('change_backup_admin.new_request')}
          show={!forbiddenState}
          onClickRefresh={fetchListAsync}
          onClickNew={openSetupModal}
        />

        {/* Entries Section */}
        <QboCard noPadding isMainWrapper>
          <>
            {(list.length > 0 || fetching) && (
              <QboAdvanceTable
                enableTopToolBar={false}
                enableSorting={false}
                rows={list}
                isLoading={fetching}
                pagination={pagination.paginations}
                setPagination={pagination.setPaginations}
                headers={tableColumns}
                rowsCount={pagination?.paginationState?.TotalCount}
                isEdit={false}
                enableColumnClickable={!false}
              />
            )}
            {list.length === 0 && !fetching && (
              <NoStatusSection forbiddenErrorMessage={forbiddenErrorMessage} />
            )}
          </>
        </QboCard>
      </QboCard>

      {/* New Requestion Section */}
      <QboModal
        onClose={closeSetupModal}
        open={showRequestModal}
        title={t('change_backup_admin.modal.title_text')}
        showCloseIcon
        preventOutsideClick>
        <StepperBlock
          steps={stepper.steps}
          icons={stepper.stepIcons}
          hideLabel
          activeStepAlt={stepper.activeStep}
          isStepOptionalAlt={stepper.isStepOptional}
          isStepSkippedAlt={stepper.isStepOptional}>
          <RequestFormSection
            supportTicketAttributes={form.attributes.supportTicketAttributes}
            escalationTicketAttributes={form.attributes.escalationTicketAttributes}
            organizationOwnerIdAttributes={form.attributes.organizationOwnerIdAttributes}
            credentialIdAttributes={form.attributes.credentialIdAttributes}
            descriptionAttributes={form.attributes.descriptionAttributes}
            onSubmit={onValidateData}
            resetForm={form.resetForm}
            formData={form.attributes}
            isValid={form.isValid}
            detailState={validateChangeBackupAdminState}
            requestState={changeBackupAdminState}
          />
          <RequestConfirmSection
            onSubmit={onSubmitForm}
            formData={form.attributes}
            onBack={onStepBack}
            onCloseModal={closeSetupModal}
            detailState={validateChangeBackupAdminState}
            requestState={changeBackupAdminState}
          />
        </StepperBlock>
      </QboModal>
    </>
  );
}
