//
// create slice
// as centeralize between actions and reducers
//
// serviceActions module contains reducers and actions that
// created by createAsyncThunk from redux-toolkit
//
// for standard actions, that require other business logic
// can be put inside extraActions module
//
// export default will be reducers it self that processed by store
// other exports will contains actions that's dispatched from ReactComponent
//

import { createSlice } from '@reduxjs/toolkit';

import { submitFormAsync, submitFormExtraReducers } from './serviceActions/submitFormAsync';

const initialState = {
  submitForm: {
    submitting: false,
    success: false,
    error: null,
  },
};

const formSlice = createSlice({
  name: 'Form',
  initialState,
  reducers: {
    resetFormSubmit(state) {
      state.submitForm = initialState.submitForm;
    },
  },
  extraReducers: {
    ...submitFormExtraReducers,
  },
});

export { submitFormAsync };
export const { resetFormSubmit } = formSlice.actions;
export default formSlice.reducer;
