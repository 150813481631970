import { constant } from '@config/BaseSetting';
import { getHEXTenantAsync, resetHEXTenantState } from '@features/hexTenant/hexTenantSlice';
import { getOrganizationFilterListAsync } from '@features/organizationList/getOrganizationFilterListSlice';
import { getParentFilterListAsync } from '@features/organizationList/getParentFilterListSlice';
import { getPartnersFilterListAsync } from '@features/partnerList/partnersFilterListSlice';
import useLocalStorage from '@hooks/useLocalStorage';
import { useDispatch } from 'react-redux';

export default function useHEXTenantService(
  filterDataState,
  setFilterDataState,
  filterStates,
  pagination,
  adFilter,
  setAdFilter,
  organizationOwnerId,
  setFilterStateList,
  initialState,
  initialFilterState,
  filterStateList
) {
  const [accessToken] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const dispatch = useDispatch();

  const handleHEXTenantAsync = (flag, resetFlag = false) => {
    dispatch(
      getHEXTenantAsync({
        accessToken,
        reset: true,
        organizationOwnerId,
        fullTextSearch: flag
          ? filterDataState.searchFieldValueText
          : filterStates.searchFieldValueText,
        pageNumber: flag ? 1 : pagination.pageIndex + 1,
        pageSize: flag ? 10 : pagination.pageSize,
        searchById: resetFlag ? 0 : filterDataState.searchFieldValueID,
        orderBy: filterDataState.selectedSortColumn,
        filter: resetFlag ? initialFilterState : adFilter,
      })
    );
  };
  const handleStateUpdate = (key, value) => {
    setFilterDataState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const handleUpdateFilters = (label, val) => {
    const newList = adFilter.map((ele) => {
      if (ele.field === label) {
        return { ...ele, value: val };
      }
      return ele;
    });
    setAdFilter([...newList]);
  };
  const handleOnResetButtonClick = () => {
    setFilterDataState((prevState) => ({
      ...prevState,
      textFieldValue: filterStates.textFieldValue,
      searchFieldValueText: filterStates.searchFieldValueText,
      searchFieldValueID: filterStates.searchFieldValueID,
      selectedSortColumn: filterStates.selectedSortColumn,
      matchText: filterStates.matchText,
      resetClicked: filterStates.resetClicked,
    }));
    setAdFilter(initialFilterState);
    setFilterStateList(() => {
      const updatedState = { ...initialState };
      const properties = ['forceUpdate', 'forceUpdateOrganization', 'forceUpdateParent'];

      properties.forEach((property) => {
        updatedState[property] = true;
      });

      return updatedState;
    });

    dispatch(resetHEXTenantState());
    handleHEXTenantAsync(false, true);
  };
  const handleOnTextFiledChange = (e) => {
    const input = e.target.value;
    setFilterDataState((prevState) => ({
      ...prevState,
      textFieldValue: input,
    }));
    if (/^\d+$/.test(input)) {
      setFilterDataState((prevState) => ({
        ...prevState,
        searchFieldValueID: input,
      }));
    } else {
      setFilterDataState((prevState) => ({
        ...prevState,
        searchFieldValueID: '',
        searchFieldValueText: input,
      }));
    }
  };
  const handleOnHideAdvanceClick = () => {
    setFilterDataState((prevState) => ({
      ...prevState,
      selectedSortColumn: filterStates.selectedSortColumn,
    }));
    setAdFilter(initialFilterState);
  };
  const matchTextHandler = () => {
    const { searchFieldValueID, searchFieldValueText } = filterDataState;

    let textData = 'Matching ';

    if (searchFieldValueID) {
      if (textData !== 'Matching ') {
        textData += ', ';
      }
      textData += ` ID  = ${searchFieldValueID}`;
    }

    if (searchFieldValueText) {
      if (textData !== 'Matching ') {
        textData += ', ';
      }
      textData += ` Email = '${searchFieldValueText}' `;
    }

    adFilter.forEach((filter) => {
      if (filter.value) {
        if (filter.field === 'filter_enable_ssl') {
          textData += 'SSL  = ';
        } else if (filter.field === 'filter_selected_partner') {
          textData += 'Partner Name = ';
          textData += `'${filter.value}'`;
        } else if (filter.field === 'filter_selected_organization') {
          textData += 'Organization Name = ';
          textData += `'${filter.value}'`;
        } else if (filter.field === 'filter_selected_parent') {
          textData += 'Parent Name = ';
          textData += `'${filter.value}'`;
        }
        textData += ', ';
      }
    });

    if (textData === 'Matching ') {
      textData = '';
    }
    textData = textData.replace(/,\s*$/, '');

    setFilterDataState((prevState) => ({
      ...prevState,
      matchText: textData,
    }));
  };

  const getHEXDataFromResponse = (data, setHEXListData) => {
    const dataObject = {
      0: 'Not Ready',
      1: 'In Progress',
      5: 'Error',
      7: 'Completed',
    };
    const modifiedData = data?.map((item) => {
      const modifiedPayloadObj = {
        ...item,
        status: dataObject[item.status],
      };
      return modifiedPayloadObj;
    });
    return setHEXListData(modifiedData);
  };

  const handlePartnerFilterSearch = (search) => {
    dispatch(
      getPartnersFilterListAsync({
        accessToken,
        reset: true,
        ...{ searchTerm: search, isOnlyParent: false },
      })
    );
  };
  const handleOrganizationFilterSearch = (search) => {
    dispatch(
      getOrganizationFilterListAsync({
        accessToken,
        reset: true,
        ...{ searchTerm: search },
      })
    );
  };
  const handleParentFilterSearch = (search) => {
    dispatch(
      getParentFilterListAsync({
        accessToken,
        reset: true,
        ...{ searchTerm: search },
      })
    );
  };

  const handleOnSearchClose = (keyProp, properties, forceUpdateState, apiHandler, apiParams) => {
    const flag = filterStateList[keyProp];
    setFilterStateList((prevState) => {
      const updatedState = { ...prevState };

      properties.forEach((property) => {
        updatedState[property] = '';
      });

      updatedState[forceUpdateState] = !flag;

      return updatedState;
    });

    apiHandler(apiParams);
  };

  const handleOnNameChange = (event, keyProp, properties) => {
    const newValue = event.target.value;

    setFilterStateList((prevState) => {
      const updatedState = { ...prevState };

      properties.forEach((property) => {
        updatedState[property] = newValue;
      });

      return updatedState;
    });

    handleUpdateFilters(keyProp, newValue);
  };

  const handleOnSearchChange = (properties, value) => {
    setFilterStateList((prevProp) => ({
      ...prevProp,
      [properties]: value,
    }));
  };

  const responseConverter = (data, keyProp) => {
    const convertedResponse = data
      .filter(
        (item) => item[keyProp] !== undefined && item[keyProp] !== null && item[keyProp] !== ''
      )
      .map((item) => ({
        id: item?.id,
        value: item[keyProp],
        title: item[keyProp],
      }));

    return convertedResponse;
  };

  return {
    handleHEXTenantAsync,
    handleStateUpdate,
    handleUpdateFilters,
    handleOnResetButtonClick,
    handleOnTextFiledChange,
    handleOnHideAdvanceClick,
    matchTextHandler,
    getHEXDataFromResponse,
    handlePartnerFilterSearch,
    handleOrganizationFilterSearch,
    handleParentFilterSearch,
    handleOnSearchChange,
    handleOnNameChange,
    responseConverter,
    handleOnSearchClose,
  };
}
