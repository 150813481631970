import WebApiRoutes from '@config/Routes/WebApiRoutes';
import ServiceClientFactory from '@libs/ServiceClient';

const webClientApi = (accessToken, reset) => {
  return new ServiceClientFactory({
    baseUrl: WebApiRoutes.getBaseUrl(),
    accessToken,
    reset,
  }).create();
};

const SystemStatusService = {
  fetchSystemStatusServiceApi: (paramsList = {}) => {
    const { accessToken, reset, parameters } = paramsList;
    const { statusType, type } = parameters;
    return webClientApi(accessToken, reset).getRequest({
      url: WebApiRoutes.systemStatusPath({ statusType, type }),
    });
  },
};

export default SystemStatusService;
