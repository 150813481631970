import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';

import { ITBox, QboAlert, QboFormGroup, QboTextField } from '@ui/Components';

export default function RequestFormSection({ onSubmit, formData, detailState }) {
  const { t } = useTranslation();
  const { statusCode: status, errorMessage } = detailState;
  const isAstericRequired = true;

  return (
    <ITBox className="TransferSubscriptionFormPage__wrapper" direction="column">
      <QboFormGroup onSubmit={onSubmit} withSubmitButton>
        {errorMessage && status !== 200 && (
          <QboAlert type="error" style={{ fontSize: '1.15em' }}>
            {errorMessage}
          </QboAlert>
        )}
        <QboTextField
          id="supportTicket"
          required={isAstericRequired}
          placeholder={t('purge_m365_tenant.labels.support_ticket')}
          label={t('purge_m365_tenant.labels.support_ticket')}
          fullWidth
          value={formData.supportTicketAttributes.value}
          errorMessage={formData.supportTicketAttributes.errorMessage}
          inputProps={{ maxLength: 225 }}
          onChange={(e) => {
            formData.supportTicketAttributes.handleChange(e.currentTarget.value);
          }}
        />
        <QboTextField
          id="escalationTicket"
          placeholder={t('purge_m365_tenant.labels.escalation_ticket')}
          label={t('purge_m365_tenant.labels.escalation_ticket')}
          fullWidth
          value={formData.escalationTicketAttributes.value}
          onChange={(e) => formData.escalationTicketAttributes.handleChange(e.currentTarget.value)}
          inputProps={{ maxLength: 225 }}
        />
        <QboTextField
          id="description"
          placeholder={t('purge_m365_tenant.labels.description')}
          label={t('purge_m365_tenant.labels.description')}
          fullWidth
          value={formData.descriptionAttributes.value}
          onChange={(e) => formData.descriptionAttributes.handleChange(e.currentTarget.value)}
          inputProps={{ maxLength: 225 }}
        />
        <QboTextField
          id="organization-owner-id"
          required={isAstericRequired}
          placeholder={t('purge_m365_tenant.labels.organization_owner_id')}
          label={t('purge_m365_tenant.labels.organization_owner_id')}
          fullWidth
          value={formData.organizationOwnerIdAttributes.value}
          errorMessage={formData.organizationOwnerIdAttributes.errorMessage}
          onChange={(e) =>
            formData.organizationOwnerIdAttributes.handleChange(e.currentTarget.value)
          }
          inputProps={{ maxLength: 225 }}
          type="number"
        />
        <QboTextField
          id="credential-id"
          required={isAstericRequired}
          placeholder={t('purge_m365_tenant.labels.credential_id')}
          label={t('purge_m365_tenant.labels.credential_id')}
          fullWidth
          value={formData.credentialIdAttributes.value}
          errorMessage={formData.credentialIdAttributes.errorMessage}
          onChange={(e) => formData.credentialIdAttributes.handleChange(e.currentTarget.value)}
          inputProps={{ maxLength: 225 }}
          type="number"
        />
      </QboFormGroup>
    </ITBox>
  );
}

RequestFormSection.propTypes = {
  formData: PropTypes.object,
  onSubmit: PropTypes.func,
  detailState: PropTypes.object,
};

RequestFormSection.defaultProps = {
  formData: {
    supportTicketAttributes: {},
    escalationTicketAttributes: {},
    organizationOwnerIdAttributes: {},
    credentialIdAttributes: {},
    descriptionAttributes: {},
  },
  onSubmit: () => {},
  detailState: {},
};
