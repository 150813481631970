import { createSlice } from '@reduxjs/toolkit';

import {
  createAutoDiscoveryAsync,
  createAutoDiscoveryExtraReducers,
} from './serviceActions/createAutoDiscoveryAsync';

const initialState = {
  autoDiscoveryState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    autoDiscoveryFormList: [],
    paginationList: {},
    message: null,
  },
};

const autoDiscoverySlice = createSlice({
  name: 'AutoDiscovery',
  initialState,
  reducers: {
    resetCreateAutoDiscoveryState(state) {
      state.autoDiscoveryState = initialState.autoDiscoveryState;
    },
  },
  extraReducers: {
    ...createAutoDiscoveryExtraReducers,
  },
});

export { createAutoDiscoveryAsync };
export const { resetCreateAutoDiscoveryState } = autoDiscoverySlice.actions;
export default autoDiscoverySlice.reducer;
