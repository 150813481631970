export default function useDataService() {
  const organizationDetails = [
    {
      label: 'Name',
      properties: 'name',
      valType: 'string',
    },
    {
      label: 'Created On',
      properties: 'createdAt',
      valType: 'string',
    },
    {
      label: 'Last Updated On',
      properties: 'updatedAt',
      valType: 'string',
    },
    {
      label: 'Recipient Notifications',
      properties: 'notificationEmails',
      valType: 'string',
    },
    {
      label: 'Insights',
      properties: 'insights',
      valType: 'string',
    },
    {
      label: 'Compliance',
      properties: 'compliance',
      valType: 'string',
    },
    {
      label: 'Metadata Cluster ID',
      properties: 'clusterId',
      valType: 'int',
    },
    {
      label: 'Upgraded to Archive Plan',
      properties: 'upgradeToArchiveStatus',
      valType: 'int',
    },
    {
      label: 'Upgraded to Archive Plan On',
      properties: 'upgradeToArchiveAt',
      valType: 'string',
    },
    {
      label: 'Custom Deactivated account',
      properties: 'customDeactivateRulePercentage',
      valType: 'string',
    },
    {
      label: 'Custom % for Deactivated account',
      properties: 'numberDeactivateRulePercentage',
      valType: 'string',
    },
    {
      label: 'O365 SSO',
      properties: 'o365AuthenticationMethod',
      valType: 'string',
    },
    {
      label: 'Journal Cluster',
      properties: 'journalClusterId',
      valType: 'int',
    },
    {
      label: 'Compliance(DPO)',
      properties: 'dpo',
      valType: 'string',
    },
    {
      label: 'AD Group',
      properties: 'adGroup',
      valType: 'string',
    },
    {
      label: 'Group & Team Enablement',
      properties: 'msGroupAbilities',
      valType: 'string',
    },
    {
      label: 'Public Folder',
      properties: 'publicFolder',
      valType: 'string',
    },
    {
      label: 'Auto License',
      properties: 'autoLicense',
      valType: 'string',
    },
    {
      label: 'Auto License Limit',
      properties: 'autolicenseLimit',
      valType: 'int',
    },
    {
      label: 'Download Split Size',
      properties: 'downloadSplitSizes',
      valType: 'string',
    },
    {
      label: 'Last Notified Out Of Capacity',
      properties: 'notifyCapacityAt',
      valType: 'string',
    },
    {
      label: 'FINRA Compliant',
      properties: 'finraCompliant',
      valType: 'string',
    },
    {
      label: 'In-Place Archive',
      properties: 'o365InPlaceArchive',
      valType: 'string',
    },
    {
      label: 'Enable Private Chat',
      properties: 'o365MsChat',
      valType: 'string',
    },
    {
      label: 'Last Notified Add Mailbox Through Autodiscover',
      properties: 'lastAddedAutodiscoverSentAt',
      valType: 'string',
    },
    {
      label: 'Partner ID',
      properties: 'resellerId',
      valType: 'int',
    },
    {
      label: 'Has billable mailbox?',
      properties: 'hasLicensedEmailAccount',
      valType: 'string',
    },
    {
      label: 'Search Cluster',
      properties: 'searchClusterId',
      valType: 'int',
    },
    {
      label: 'Enable Backup Concat Calendar Task?',
      properties: 'enableBackupExchange',
      valType: 'string',
    },
    {
      label: 'Last Notified Add Mailbox',
      properties: 'lastAddEmailAccountSentAt',
      valType: 'string',
    },
    {
      label: 'Last Notified Mailbox Deactivated because Not Exist in AD Group',
      properties: 'adGroupMailboxDeactivationSentAt',
      valType: 'string',
    },
    {
      label: 'Last Notified For Summary Mailbox Deactivation',
      properties: 'lastMailboxDeactivatedSummarySentAt',
      valType: 'string',
    },
    {
      label: 'NFR Status',
      properties: 'nfrSubscriptionStatus',
      valType: 'string',
    },
    {
      label: 'NFR Updated At',
      properties: 'nfrUpdatedAt',
      valType: 'string',
    },
    {
      label: 'Last Subscription ID',
      properties: 'lastSubscriptionId',
      valType: 'int',
    },
    {
      label: 'SPA Enabled',
      properties: 'enableNonBackupAdminFlow',
      valType: 'string',
    },
    {
      label: 'Banner SPA Appear?',
      properties: 'displayPolpAnnouncement',
      valType: 'string',
    },
    {
      label: 'SPA type',
      properties: 'defaultNonBackupAdminFlow',
      valType: 'int',
    },
    {
      label: 'Allow Duplicate Journal Messages',
      properties: 'allowDuplicateJournalMessages',
      valType: 'string',
    },
    {
      label: 'Keystone Storage',
      properties: 'privateCloudFlag',
      valType: 'string',
    },
    {
      label: 'Keystone Storage Config',
      properties: 'privateCloudConfig',
      valType: 'string',
    },
    {
      label: 'Discrepancy Checker',
      properties: 'discrepancyCheckerEnabled',
      valType: 'string',
    },
    {
      label: 'Discrepancy Checker Config',
      properties: 'discrepancyCheckerConfig',
      valType: 'string',
    },
    {
      label: 'Point in Time Mailbox',
      properties: 'pitEnable',
      valType: 'string',
    },
    {
      label: 'Checksum Download',
      properties: 'checksumDownloadFlag',
      valType: 'string',
    },
    {
      label: 'Checksum Download Method',
      properties: 'checksumDownloadAlgorithmConfig',
      valType: 'string',
    },
    {
      label: 'SD Drive Modularity',
      properties: 'sharedDriveModularityEnable',
      valType: 'string',
    },
    {
      label: 'Organization Owner ID/Client ID',
      properties: 'id',
      valType: 'int',
    },
    {
      label: 'Organization Email',
      properties: 'email',
      valType: 'string',
    },
    {
      label: 'Partner ID',
      properties: 'PartnerID',
      valType: 'string',
    },
    {
      label: 'First Name',
      properties: 'firstName',
      valType: 'string',
    },
    {
      label: 'Last Name',
      properties: 'lastName',
      valType: 'string',
    },
    {
      label: 'Address 1',
      properties: 'address1',
      valType: 'string',
    },
    {
      label: 'Address 2',
      properties: 'address2',
      valType: 'string',
    },
    {
      label: 'Country',
      properties: 'country',
      valType: 'string',
    },
    {
      label: 'Time zone',
      properties: 'timeZone',
      valType: 'string',
    },
    {
      label: 'Postal Code',
      properties: 'postalCode',
      valType: 'string',
    },
    {
      label: 'API Authentication Token',
      properties: 'authenticationToken',
      valType: 'string',
    },

    {
      label: 'Region ID',
      properties: 'regionId',
      valType: 'int',
    },
    {
      label: 'Plan ID',
      properties: 'planId',
      valType: 'int',
    },
    {
      label: 'Max Storage Usage',
      properties: 'maxUsage',
      valType: 'int',
    },
    {
      label: 'Currrent Storage Usage',
      properties: 'currentUsage',
      valType: 'int',
    },
    {
      label: 'Plan Expires At',
      subHeadingText: '(Direct User)',
      properties: 'planExpiresAt',
      valType: 'string',
    },

    {
      label: 'Deactivated Since',
      properties: 'deactivatedSince',
      valType: 'string',
    },
    {
      label: 'Deactivated Reason',
      properties: 'deactivationReason',
      valType: 'string',
    },
    {
      label: 'Last Notified Expires Plan',
      subHeadingText: '(Direct User)',
      properties: 'notifiedExpiringAt',
      valType: 'string',
    },
    {
      label: 'Deleted At',
      properties: 'deletedAt',
      valType: 'string',
    },
    {
      label: 'Data Deleted At',
      properties: 'deletedDataAt',
      valType: 'string',
    },
    {
      label: 'Connectwise Company ID',
      properties: 'connectwiseCompanyId',
      valType: 'string',
    },
    {
      label: 'Organization Deleted',
      properties: 'immediateDeleteManually',
      valType: 'string',
    },
    {
      label: 'Custom deactivated treshold',
      properties: 'allowDeactivateMoreAccount',
      valType: 'string',
    },
    {
      label: 'M365 Enfore SSO Login',
      properties: 'enforceSso',
      valType: 'string',
    },
    {
      label: 'Last Notified Out Of Space',
      properties: 'lastOutOfSpaceSentAt',
      valType: 'string',
    },
    {
      label: 'Last Notified Reach Limit Space',
      properties: 'lastReachLimitSentAt',
      valType: 'string',
    },
    {
      label: 'Purged after grace period',
      properties: 'afterGracePeriodPurgeAt',
      valType: 'string',
    },
    {
      label: 'Purged after grace period status',
      properties: 'gracePeriodDeletionStatus',
      valType: 'int',
    },
    {
      label: 'QBO Organization',
      properties: 'isQbo',
      valType: 'string',
    },
    {
      label: 'GWS Enforce SSO Login',
      properties: 'enforceGwsSso',
      valType: 'string',
    },
  ];
  return {
    organizationDetails,
  };
}
