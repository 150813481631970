import * as React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';

export function TooltipWrapper({ condition, children, title }) {
  return condition ? (
    <QboTooltip title={title}>
      <span className="QboButton__tooltip">{children}</span>
    </QboTooltip>
  ) : (
    children
  );
}

TooltipWrapper.propTypes = {
  condition: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object]),
};

TooltipWrapper.defaultProps = {
  condition: false,
  children: null,
  title: null,
};

export default function QboTooltip({ children, className, title, ...rest }) {
  return (
    <Tooltip className={`QboTooltip__wrapper ${className}`} {...rest} title={title}>
      {children}
    </Tooltip>
  );
}

QboTooltip.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

QboTooltip.defaultProps = {
  className: '',
  title: null,
};
