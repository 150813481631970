import { useDispatch } from 'react-redux';
import { constant } from '@config/BaseSetting';
import useLocalStorage from '@hooks/useLocalStorage';
import {
  switchSPACommandListAsync,
  switchSPATypeAsync,
} from '@features/swicthSPAType/switchSPATypeSlice';
import { credentilasSPAinfoAsync } from '@features/swicthSPAType/credentilasSPAinfoSlice';

export default function useSwitchSPACommand() {
  const dispatch = useDispatch();
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);

  const handleSwitchSPACommandListAsync = (pageIndex, pageSize) => {
    dispatch(
      switchSPACommandListAsync({
        accessToken: accessTokenSet,
        reset: true,
        pageNumber: pageIndex,
        pageSize,
      })
    );
  };

  const handleCredentilasSPAinfoAsync = (parameters) => {
    dispatch(
      credentilasSPAinfoAsync({
        accessToken: accessTokenSet,
        reset: true,
        parameters,
      })
    );
  };

  const handleUpdateSPATypeAsync = (parameters) => {
    dispatch(
      switchSPATypeAsync({
        accessToken: accessTokenSet,
        reset: true,
        parameters,
      })
    );
  };

  const spaTypeToText = (spaType) => {
    switch (spaType) {
      case 1:
        return 'Automatic';
      case 0:
        return 'Manual';
      default:
        return '-';
    }
  };

  const processHandleSwitchSPACommandList = (data, setInitiateListState) => {
    const modifiedData = data?.map((item) => {
      const payloadObj = JSON.parse(item.payload);

      const modifiedPayloadObj = {
        id: item.id,
        'request-id': payloadObj['request-id'],
        'org-owner-id': payloadObj['org-owner-id'],
        'credentials-id': payloadObj['credentials-id'],
        'spa-type': spaTypeToText(payloadObj['spa-type']),
        'status-description': item['status-description'],
        status: item.status,
        description: payloadObj.description,
        'support-ticket': payloadObj['support-ticket'],
        'escalation-ticket': payloadObj['escalation-ticket'],
        'executed-on': item['executed-on'],
      };
      return modifiedPayloadObj;
    });
    return setInitiateListState(modifiedData);
  };

  return {
    handleSwitchSPACommandListAsync,
    processHandleSwitchSPACommandList,
    handleCredentilasSPAinfoAsync,
    handleUpdateSPATypeAsync,
  };
}
