export default function useResellerLabelDataService() {
  const apiSettings = [
    {
      label: 'Show Password',
      properties: 'apiShowsPassword',
      valType: 'bool',
    },
    {
      label: 'Show Username',
      properties: 'apiShowsUserName',
      valType: 'bool',
    },
    {
      label: 'Download Audit logs',
      properties: 'auditLogDownloadViaApi',
      valType: 'bool',
    },
    {
      label: 'Cumulative Usage Report',
      properties: 'generateCumulativeUsageReport',
      valType: 'bool',
    },
  ];
  const notificationsSettingsABackupNotification = [
    {
      label: 'Converted Shared Mailbox is out of seat',
      properties: 'notifyCapacitySeatSharedMailbox',
      valType: 'bool',
    },
    {
      label: 'Need More Seats Frequency',
      properties: 'notifyNeedMoreSeatFrequency',
      valType: 'string',
    },
    {
      label: 'User sent invitations',
      properties: 'notifyPasswordRequestMail',
      valType: 'bool',
    },
  ];
  const notificationsSettingsABackupNotificationAccountAdded = [
    {
      label: 'Notify Frequency',
      properties: 'notifyCreationNotificationFrequency',
      valType: 'string',
    },
    {
      label: 'Need More Seats Frequency',
      properties: 'notifyNeedMoreSeatFrequency',
      valType: 'string',
    },
    {
      label: 'User sent invitations',
      properties: 'notifyPasswordRequestMail',
      valType: 'bool',
    },
    {
      label: 'Email is successfully added to backup',
      properties: 'notifyCreationNotificationMail',
      valType: 'bool',
    },
    {
      label: 'Individual user accounts is added to backup',
      properties: 'notifyIndividualUserAddedToBackup',
      valType: 'bool',
    },
  ];
  const notificationsSettingsABackupNotificationAutoDiscover = [
    {
      label: 'Added to AutoDiscover Frequency',
      properties: 'notifyAddedAutodiscoverFrequency',
      valType: 'string',
    },
    {
      label: 'AutoDiscover Capacity Frequency',
      properties: 'notifyCapacityAutodiscoverFrequency',
      valType: 'string',
    },
    {
      label: 'AutoDiscover add emails to the backup',
      properties: 'notifyEmailAccountAddedMail',
      valType: 'bool',
    },
    {
      label: 'No seats available for AutoDiscover',
      properties: 'notifyInsufficientSeatNotificationMail',
      valType: 'bool',
    },
  ];
  const notificationsSettingsUserManagementNotification = [
    {
      label: 'Deactivated account reactivated',
      properties: 'notifyAccountReactivation',
      valType: 'bool',
    },
    {
      label: 'Login to the Customer Portal disabled',
      subHeadingText: '(for Backup & Archiver)',
      properties: 'notifyLoginDisabledNotificationMail',
      valType: 'bool',
    },
    {
      label: 'Login to the Customer Portal enabled',
      subHeadingText: '(for Backup & Archiver)',
      properties: 'notifyLoginEnabledNotificationMail',
      valType: 'bool',
    },
    {
      label: 'The invited user successfully added his credential',
      subHeadingText: '(for Backup & Archiver)',
      properties: 'notifyRandomPasswordInfoMail',
      valType: 'bool',
    },
    {
      label: 'Closed Account Reactivated',
      properties: 'notifyReactivateCloseAccountNotificationMail',
      valType: 'bool',
    },
    {
      label: 'Their organization first created',
      properties: 'notifyResellerWelcomeMail',
      valType: 'bool',
    },
    {
      label: 'Organization’s ownership transferred',
      properties: 'notifyTransferOwnershipMail',
      valType: 'bool',
    },
  ];
  const notificationsSettingsUserManagementNotificationDeactivatedAccount = [
    {
      label: 'Deactivated Account',
      properties: 'notifyDeactivatedAccount',
      valType: 'bool',
    },
    {
      label: 'Notify Frequency',
      properties: 'notifyDeactivatedAccountFrequency',
      valType: 'string',
    },
  ];
  const notificationsSettingsReporting = [
    {
      label: 'Add backup Report Frequency',
      properties: 'notifyAddBackupReportFrequency',
      valType: 'string',
    },
    {
      label: 'Deleted Sub-reseller Report Frequency',
      properties: 'notifyDeletedSubPartnerFrequency',
      valType: 'string',
    },
    {
      label: 'New Organisation Report Frequency',
      properties: 'notifyNewOrganizationReportFrequency',
      valType: 'string',
    },
    {
      label: 'Partner User Deleted Notify Frequency',
      properties: 'notifyPartnerUserDeletedFrequency',
      valType: 'string',
    },
    {
      label: 'Recovered Sub-reseller Report Frequency',
      properties: 'notifyRecoveredSubPartnerFrequency',
      valType: 'string',
    },
    {
      label: 'Subscription Change Report Frequency',
      properties: 'notifySubscriptionChangeReportFrequency',
      valType: 'string',
    },
  ];
  const notificationsSettingsOrganizationManagementNotification = [
    {
      label: 'Grace period is ending',
      properties: 'notifyGracePeriodReminder',
      valType: 'bool',
    },
    {
      label: 'Notify Frequency',
      properties: 'notifyGracePeriodReminderFrequency',
      valType: 'string',
    },
  ];
  const notificationsSettingsNFRConvertedToPaid = [
    {
      label: 'Converted Organisation',
      properties: 'notifyConvertedNfrOrganization',
      valType: 'bool',
    },
    {
      label: 'Report Frequency',
      properties: 'notifyConvertedNfrOrganizationReportFrequency',
      valType: 'string',
    },
  ];
  const notificationsSettingsNFRExpiringOrganisation = [
    {
      label: 'Expiring Organisation',
      properties: 'notifyExpiringNfrOrganization',
      valType: 'bool',
    },
    {
      label: 'Report Frequency',
      properties: 'notifyExpiringNfrOrganizationReportFrequency',
      valType: 'string',
    },
  ];
  const notificationsSettingsNFRNewOrganisation = [
    {
      label: 'Welcome Mail',
      properties: 'notifyNfrWelcomeMail',
      valType: 'bool',
    },
    {
      label: 'Report Frequency',
      properties: 'notifyNewNfrOrganizationReportFrequency',
      valType: 'string',
    },
  ];
  const notificationsSettingsNFR = [
    {
      label: 'Expired Organisation',
      properties: 'notifyExpiredNfrOrganization',
      valType: 'bool',
    },
    {
      label: 'NFR Extended',
      properties: 'notifyExtendedNfrOrganization',
      valType: 'bool',
    },
  ];
  const notificationsSettingsOrgOwner = [
    {
      label: 'Email Updated',
      properties: 'notifyOrgOwnerEmailEdited',
      valType: 'bool',
    },
    {
      label: 'Email Updated Frequency',
      properties: 'notifyOrgOwnerEmailUpdatedFrequency',
      valType: 'string',
    },
  ];
  const notificationsSettingsAccountingBackupNotification = [
    {
      label: 'Email Updated',
      properties: 'notifyOrgOwnerEmailEdited',
      valType: 'bool',
    },
    {
      label: 'Email Updated Frequency',
      properties: 'notifyOrgOwnerEmailUpdatedFrequency',
      valType: 'string',
    },
  ];
  const notificationsSettingsBackupAD = [
    {
      label: 'AD Group mailbox deactivated',
      properties: 'notifyAdGroupMailboxDeactivation',
      valType: 'bool',
    },
    {
      label: 'Get cumulative email count',
      properties: 'notifyAdGroupMailboxDeactivationCumulative',
      valType: 'bool',
    },
    {
      label: 'Notify Frequency',
      properties: 'notifyAdGroupMailboxDeactivationFrequency',
      valType: 'string',
    },
  ];
  const notificationsSettingsBackupError = [
    {
      label: 'Get cumulative email count',
      properties: 'notifyBackupFailedCumulative',
      valType: 'bool',
    },
    {
      label: 'The system detects an error in the backup process',
      properties: 'notifyFailure',
      valType: 'bool',
    },
    {
      label: 'Notify Frequency',
      properties: 'notifyBackupFailedFrequency',
      valType: 'string',
    },
  ];
  const notificationsSettingsMailboxDeactivated = [
    {
      label: 'Deactivated Cumulative Summary',
      properties: 'notifyMailboxDeactivatedCumulative',
      valType: 'bool',
    },
    {
      label: 'Deactivated Frequency',
      properties: 'notifyMailboxDeactivatedFrequency',
      valType: 'string',
    },
    {
      label: 'Deactivated Summary',
      properties: 'notifyMailboxDeactivatedSummary',
      valType: 'bool',
    },
  ];
  const notificationsSettingsOutOfCapacityPersonalPlan = [
    {
      label: 'Backup is out of space',
      subHeadingText: '(for Personal only)',
      properties: 'notifyCapacity',
      valType: 'string',
    },
    {
      label: 'Notify Frequency',
      properties: 'notifyCapacityFrequency',
      valType: 'bool',
    },
  ];
  const notificationsSettingsSuccessfulBackup = [
    {
      label: 'Backup/archiving completed',
      properties: 'notifyBackup',
      valType: 'bool',
    },
    {
      label: 'Notify Frequency',
      properties: 'notifyBackupFrequency',
      valType: 'string',
    },
    {
      label: 'Lock frequency for success backup',
      properties: 'notifyBackupLocked',
      valType: 'bool',
    },
  ];
  const notificationsSettingsComplianceNotification = [
    {
      label: 'New results for saved search occur',
      subHeadingText: '(for Archiver only)',
      properties: 'notifySendAlertMail',
      valType: 'bool',
    },
    {
      label: 'Notify Frequency',
      properties: 'notifySaveSearchFrequency',
      valType: 'string',
    },
  ];
  const notificationsSettingsCredentialsNotification = [
    {
      label: 'The system finds Device Authorization error',
      properties: 'notifyDeviceAuthError',
      valType: 'bool',
    },
  ];
  const notificationsSettingsAccounting = [
    {
      label: 'Deactivated Account Reactivated',
      properties: 'notifyAccountingDeactivatedAccountReactivated',
      valType: 'bool',
    },
    {
      label: 'The system finds an authentication error',
      properties: 'notifyAccountingDetectAuthError',
      valType: 'bool',
    },

    {
      label: 'Company is successfully added to backup',
      properties: 'notifyAccountingInitialBackupCompleted',
      valType: 'bool',
    },
    {
      label: 'Organization account deactivated/inactive',
      properties: 'notifyAccountingOrgAccountDeactivated',
      valType: 'bool',
    },
    {
      label: 'Reset Password Requested',
      properties: 'notifyAccountingResetPasswordRequested',
      valType: 'bool',
    },
    {
      label: 'Their organization first created',
      properties: 'notifyAccountingWelcomeMail',
      valType: 'bool',
    },
  ];
  const notificationsSettingsGeneral = [
    {
      label: 'Invitation Request Email',
      properties: 'notifyAdminInvitationRequestMail',
      valType: 'bool',
    },
    {
      label: 'Forgot Password Email',
      properties: 'notifyForgotPasswordMail',
      valType: 'bool',
    },
    {
      label: 'Account Reactivation',
      properties: 'notifyAccountingWelcomeMail',
      valType: 'bool',
    },
    {
      label: 'Pending Email Account Password Request',
      properties: 'notifyPendingEmailAccountPasswordRequest',
      valType: 'bool',
    },
    {
      label: 'Search Alert',
      properties: 'notifySearchAlert',
      valType: 'bool',
    },
    {
      label: 'Signup',
      properties: 'notifySignup',
      valType: 'bool',
    },
    {
      label: 'Sub Account email',
      properties: 'notifySubAccountMail',
      valType: 'bool',
    },
    {
      label: 'Welcome Email',
      properties: 'notifyWelcomeMail',
      valType: 'bool',
    },
  ];
  const notificationsSettingsGeneralErrorSummary = [
    {
      label: 'Error Summary Cumulative',
      properties: 'notifyErrorSummaryCumulative',
      valType: 'bool',
    },
    {
      label: 'Error Summary Frequency',
      properties: 'notifyErrorSummaryFrequency',
      valType: 'string',
    },
  ];
  const notificationsSettingsGeneralMimeConversionError = [
    {
      label: 'Get cumulative email count',
      properties: 'notifyMimeConversionErrorCumulative',
      valType: 'bool',
    },
    {
      label: 'Notify Frequency',
      properties: 'notifyMimeConversionErrorFrequency',
      valType: 'string',
    },
  ];
  const notificationsSettingsUserManagementNotificationSubscriptionChange = [
    {
      label: 'Autolicense',
      properties: 'notifySubscriptionChangeAutolicense',
      valType: 'bool',
    },
    {
      label: 'Delete',
      properties: 'notifySubscriptionChangeDelete',
      valType: 'bool',
    },
    {
      label: 'Plan',
      properties: 'notifySubscriptionChangePlan',
      valType: 'bool',
    },
    {
      label: 'Resubscribe',
      properties: 'notifySubscriptionChangeResubscribe',
      valType: 'bool',
    },
    {
      label: 'Seat',
      properties: 'notifySubscriptionChangeSeat',
      valType: 'bool',
    },
    {
      label: 'Suspend',
      properties: 'notifySubscriptionChangeSuspend',
      valType: 'bool',
    },
    {
      label: 'Unsubscribe',
      properties: 'notifySubscriptionChangeUnsubscribe',
      valType: 'bool',
    },
    {
      label: 'Unsuspend',
      properties: 'notifySubscriptionChangeUnsuspend',
      valType: 'bool',
    },
  ];
  const notificationsSettingsBackupReachingLimit = [
    {
      label: 'Backup is reaching its limit',
      properties: 'notifyLimit',
      valType: 'bool',
    },
  ];
  const notificationSettingsDownloadNotificationDownloadReady = [
    {
      label: 'Download is ready',
      properties: 'notifyDownloadCompletionNotificationMail',
      valType: 'bool',
    },
  ];
  const resellerDetails = [
    {
      label: 'ID',
      properties: 'id',
      valType: 'int',
    },
    {
      label: 'Address',
      properties: 'address',
      valType: 'string',
    },
    {
      label: 'Channel',
      properties: 'channel',
      valType: 'string',
    },
    {
      label: 'Contact Us Email',
      properties: 'contactUsEmail',
      valType: 'string',
    },
    {
      label: 'Created On',
      properties: 'createdAt',
      valType: 'string',
    },
    {
      label: 'Default Country',
      properties: 'defaultCountry',
      valType: 'string',
    },
    {
      label: 'Default Locale',
      properties: 'defaultLocale',
      valType: 'string',
    },
    {
      label: 'Default Region',
      properties: 'defaultRegionId',
      valType: 'int',
    },
    {
      label: 'Default Time Zone',
      properties: 'defaultTimeZone',
      valType: 'string',
    },
    {
      label: 'Deleted On',
      properties: 'notifySubscriptionChangeUnsuspend',
      valType: 'bool',
    },
    {
      label: 'Favicon',
      properties: 'notifySubscriptionChangeUnsuspend',
      valType: 'bool',
    },
    {
      label: 'Icon',
      properties: 'notifySubscriptionChangeUnsuspend',
      valType: 'bool',
    },
    {
      label: 'Locked Country',
      properties: 'lockedCountry',
      valType: 'bool',
    },
    {
      label: 'Locked Locale',
      properties: 'lockedLocale',
      valType: 'bool',
    },
    {
      label: 'Locked Time Zone',
      properties: 'lockedTimeZone',
      valType: 'bool',
    },
    {
      label: 'Owner',
      properties: 'owner',
      valType: 'string',
    },
    {
      label: 'Partner ID',
      properties: 'resellerId',
      valType: 'int',
    },
    {
      label: 'Last Updated On',
      properties: 'updatedAt',
      valType: 'string',
    },
  ];
  const resellerDetailsPartnerType = [
    {
      label: 'Distributor',
      properties: 'canAddResellers',
      valType: 'bool',
    },
    {
      label: 'Direct User',
      properties: 'directUser',
      valType: 'bool',
    },
    {
      label: 'Test Reseller',
      properties: 'testReseller',
      valType: 'bool',
    },
  ];
  const resellerDetailsPortalSettings = [
    {
      label: 'Add email Account',
      properties: 'addEmailAccountViaPortal',
      valType: 'bool',
    },
    {
      label: 'Banner Last Updated at',
      properties: 'BannerUpdatedAt',
      valType: 'string',
    },
    {
      label: 'Email Editable',
      properties: 'defaultEditableEmail',
      valType: 'bool',
    },
    {
      label: 'Contact Us message',
      properties: 'contactUsMessage',
      valType: 'string',
    },
    {
      label: 'Custom CSS',
      properties: 'customCss',
      valType: 'string',
    },
    {
      label: 'Domain URL',
      properties: 'domain',
      valType: 'string',
    },
    {
      label: 'Layout',
      properties: 'layout',
      valType: 'string',
    },
    {
      label: 'Redirect URL',
      properties: 'redirectUrl',
      valType: 'string',
    },
    {
      label: 'Support Email',
      properties: 'supportEmail',
      valType: 'string',
    },
    {
      label: 'Template',
      properties: 'template',
      valType: 'string',
    },
    {
      label: 'Theme',
      properties: 'theme',
      valType: 'string',
    },
    {
      label: 'Title',
      properties: 'title',
      valType: 'string',
    },
    {
      label: 'Whitelabel for Sub-reseller',
      subHeadingText:
        '(Sub-reseller Portal under this Master Reseller will follow the master’s logo, title name, SMTP Settings and more. Any Dropsuite identifier will be removed.)',
      properties: 'whitelable',
      valType: 'bool',
    },
    {
      label: 'Favicon Last Updated at',
      properties: 'faviconUpdatedAt',
      valType: 'string',
    },
    {
      label: 'Logo Last Updated At',
      properties: 'logoUpdatedAt',
      valType: 'string',
    },
  ];
  const resellerPermissions = [
    {
      label: 'Deactivate More Account',
      properties: 'allowDeactivateMoreAccount',
      valType: 'bool',
    },
    {
      label: 'Support',
      properties: 'allowSupportAccountSettings',
      valType: 'bool',
    },
    {
      label: 'Add Users from Portal',
      properties: 'canAddUserFromPortal',
      valType: 'bool',
    },
    {
      label: 'Purchase Plan',
      properties: 'canPurchasePlan',
      valType: 'bool',
    },
    {
      label: 'Change Password',
      properties: 'changePassword',
      valType: 'bool',
    },
    {
      label: 'Create Account',
      properties: 'createAccount',
      valType: 'bool',
    },
    {
      label: 'Custom  % Deactivate',
      properties: 'customDeactivateRulePercentage',
      valType: 'bool',
    },
    {
      label: 'Deactivate Personal Email Account',
      properties: 'deactivatePersonalEmailAccount',
      valType: 'bool',
    },
    {
      label: 'Forgot Passoword',
      properties: 'forgotPassword',
      valType: 'bool',
    },
    {
      label: 'Deactivate Personal Email Account',
      properties: 'deactivatePersonalEmailAccount',
      valType: 'bool',
    },
    {
      label: 'Hide Change Password',
      properties: 'hideChangePassword',
      valType: 'bool',
    },
    {
      label: 'CTA Guideline',
      properties: 'showCtaGuideline',
      valType: 'bool',
    },
  ];
  const resellerPermissionsAdminPrivileges = [
    {
      label: 'Auto License',
      properties: 'autoLicense',
      valType: 'bool',
    },
    {
      label: 'Delete Subscriptions',
      properties: 'canDeleteSubscription',
      valType: 'bool',
    },
    {
      label: 'Full Access SSO',
      properties: 'fullAccessSSO',
      valType: 'bool',
    },
    {
      label: 'Manage Service Provider',
      properties: 'manageServiceProvider',
      valType: 'bool',
    },
  ];
  const resellerPermissionsContractSettings = [
    {
      label: 'Show MNDA',
      properties: 'showMNDA',
      valType: 'bool',
    },
    {
      label: 'Standard Contract',
      properties: 'standardContract',
      valType: 'bool',
    },
  ];
  const resellerPermissionsDistributorMasterResellerPermissions = [
    {
      label: 'View & Access Sub Reseller Manage Feature',
      subHeadingText:
        '(Enables the Master Reseller to access & edit Manage Feature tab on Sub Reseller.)',
      properties: 'allowDistributorAccessSubResellerManageFeatures',
      valType: 'bool',
    },
    {
      label: 'Master reseller creates a user for Sub Reseller',
      subHeadingText:
        '(Enables the Master Reseller to create users manually on their Sub Reseller.)',
      properties: 'allowDistributorCreateSubResellerUser',
      valType: 'bool',
    },
    {
      label: 'Master Reseller Edit User Details',
      subHeadingText: '(Enables the Master Reseller to edit user details on their Sub Reseller)',
      properties: 'allowDistributorEditSubResellerUserDetails',
      valType: 'bool',
    },
    {
      label: 'Master Reseller edits Subscription for Sub Reseller',
      subHeadingText:
        '(Enables the Master Reseller to edit subscriptions manually on Sub Resellers user details)',
      properties: 'allowDistributorEditSubResellerUserSubscription',
      valType: 'bool',
    },
    {
      label: 'Sub Reseller creates a user for Sub Reseller',
      subHeadingText: '(Enables Sub Reseller to create uses manually.)',
      properties: 'allowSubResellerCreateSubResellerUser',
      valType: 'bool',
    },
    {
      label: 'Sub reseller Edit User Details',
      subHeadingText: '(Enables the Sub Reseller to edit user details)',
      properties: 'allowSubResellerEditSubResellerUserDetails',
      valType: 'bool',
    },
    {
      label: 'Sub Reseller edits Subscription for Sub Reseller',
      subHeadingText: '(Enables Sub Reseller to edit and manage subscriptions manually.)',
      properties: 'allowSubResellerEditSubResellerUserSubscription',
      valType: 'bool',
    },
    {
      label: 'Custom Logo for Sub Reseller Portal',
      subHeadingText: '(Enables the Master Reseller to upload logo for their sub reseller portal.)',
      properties: 'isLogoCustomer',
      valType: 'bool',
    },
    {
      label: 'Create Sub Reseller',
      subHeadingText: '(Enables the Master Reseller to create Sub Reseller Manually.)',
      properties: 'resellerCanCreateSubReseller',
      valType: 'bool',
    },
    {
      label: 'View & Access NFR User Account & Plan',
      subHeadingText:
        '(Enables the Super Admin to create and view Subscription account with NFR, also create and hide/unhide plans on Master Reseller & Sub Reseller.)',
      properties: 'resellerCanViewNfr',
      valType: 'bool',
    },
    {
      label: 'Sub Reseller creates Support Ticket',
      subHeadingText:
        '(Enables all Sub Resellers under this account to view and create support tickets to Dropsuite Support.)',
      properties: 'subResellerCanCreateSupportTicket',
      valType: 'bool',
    },
  ];
  const resellerPermissionsFeatures = [
    {
      label: 'Department',
      properties: 'accessDepartment',
      valType: 'bool',
    },
    {
      label: 'Hosted Exchange',
      properties: 'accessHostedExchange',
      valType: 'bool',
    },
    {
      label: 'AD Group',
      properties: 'adGroup',
      valType: 'bool',
    },
    {
      label: 'DPO(Compliance)',
      properties: 'canAccessDPO',
      valType: 'bool',
    },
    {
      label: 'Backup Exchange',
      properties: 'defaultEnableBackupExchange',
      valType: 'bool',
    },
    {
      label: 'Login',
      properties: 'defaultEnableLogin',
      valType: 'bool',
    },
    // {
    //   label: 'MS Groups Settings',
    //   properties: 'defaultMSGroupAbilities',
    //   valType: 'string',
    // },
    {
      label: 'Point in Time Restore',
      properties: 'defaultPitEnable',
      valType: 'bool',
    },
    {
      label: 'GWS backup',
      subHeadingText:
        '(Enables this reseller to has the ability to backup Google Workspace. Please make sure it is not applied to white label user.)',
      properties: 'enableGSuiteOptions',
      valType: 'bool',
    },
    {
      label: 'In-place Archive',
      subHeadingText:
        "(Enables this reseller to has the ability to backup In-Place Archive. Note: Once it is enabled on an organization, it can't be disabled back.)",
      properties: 'enabledO365InPlaceArchive',
      valType: 'bool',
    },
    {
      label: 'M365 Private Chat',
      subHeadingText:
        "(Enables this reseller to has the ability to backup M365 Private Chat. Note: Once it is enabled on an organization. It can't be disabled back.)",
      properties: 'enabledO365MsChat',
      valType: 'bool',
    },
    {
      label: 'Finra Compliant',
      subHeadingText:
        "(Allow reseller to enable WORM Storage feature. Please note that customers can't use the DPO feature and has retention policy limited to a minimum of 3 years once WORM Storage enabled)",
      properties: 'finraCompliant',
      valType: 'bool',
    },
    {
      label: 'Free Shared Mailboxes',
      properties: 'freeSharedMailboxes',
      valType: 'bool',
    },
    {
      label: 'Hide Gmail',
      properties: 'hideGmailOptions',
      valType: 'bool',
    },
    {
      label: 'Office Backup',
      properties: 'officeBackup',
      valType: 'bool',
    },
    {
      label: 'Public Folder',
      properties: 'publicFolder',
      valType: 'bool',
    },
    {
      label: 'Shared Drive',
      properties: 'shareDriveEnable',
      valType: 'bool',
    },
    {
      label: 'Use Unified Tables',
      properties: 'unifiedDataFeatureFlag',
      valType: 'string',
    },
  ];
  const resellerPermissionsFeaturesMsGroupSettings = [
    {
      label: 'Backup Type',
      properties: 'backup_type',
      valType: 'bool',
    },
    {
      label: 'Calendar',
      properties: 'calendar',
      valType: 'bool',
    },
    {
      label: 'File',
      properties: 'file',
      valType: 'bool',
    },
    {
      label: 'Site',
      properties: 'site',
      valType: 'bool',
    },
    {
      label: 'Note',
      properties: 'note',
      valType: 'bool',
    },
    {
      label: 'Team',
      properties: 'team',
      valType: 'bool',
    },
    {
      label: 'Shared Mailbox',
      properties: 'shared_mailbox',
      valType: 'bool',
    },
    {
      label: 'Task',
      properties: 'task',
      valType: 'bool',
    },
  ];
  const resellerPermissionsFeaturesLoginAsClient = [
    {
      label: 'Login as Client',
      properties: 'resellerImpersonate',
      valType: 'bool',
    },
    // {
    //   label: 'Permissions',
    //   properties: 'resellerImpersonatePermissions',
    //   valType: 'string',
    // },
  ];
  const resellerPermissionsFeaturesLoginAsClientPermissions = [
    {
      label: 'Type',
      properties: 'type',
      valType: 'string',
    },
  ];
  const resellerPermissionsFeaturesLoginAsClientView = [
    {
      label: 'Message',
      properties: 'message',
      valType: 'string',
    },
    {
      label: 'Drive File',
      properties: 'drive_file',
      valType: 'string',
    },
    {
      label: 'Calendar',
      properties: 'calendar',
      valType: 'string',
    },
    {
      label: 'Contact',
      properties: 'contact',
      valType: 'string',
    },
    {
      label: 'Task',
      properties: 'task',
      valType: 'string',
    },
    {
      label: 'Share Point',
      properties: 'sharepoint',
      valType: 'string',
    },
    {
      label: 'Shared Drive',
      properties: 'shared_drive',
      valType: 'string',
    },
    {
      label: 'Groups & Teams',
      properties: 'ms_group',
      valType: 'string',
    },
    {
      label: 'Private Chat',
      properties: 'ms_chat',
      valType: 'string',
    },
  ];
  const resellerPermissionsFeaturesLoginAsClientOthers = [
    {
      label: 'Search',
      properties: 'search',
      valType: 'string',
    },
    {
      label: 'Attachment',
      properties: 'attachment',
      valType: 'string',
    },
    {
      label: 'Insights',
      properties: 'insight',
      valType: 'string',
    },
    {
      label: 'Compliances',
      subHeadingText: '(Retention policy, Legal hold)',
      properties: 'compliance_group_1',
      valType: 'string',
    },
    {
      label: 'Compliances',
      subHeadingText: '(Ediscovery, Alert, Tag, Audit log, Review process)',
      properties: 'compliance_group_2',
      valType: 'string',
    },
  ];
  const resellerPermissionsFeaturesNFR = [
    {
      label: 'NFR Period',
      properties: 'nfrPeriodDays',
      valType: 'int',
    },
    {
      label: 'NFR QBO Organization Limit',
      properties: 'nfrQboOrganizationLimit',
      valType: 'int',
    },
    {
      label: 'Add NFR',
      subHeadingText: '(Enables partner to create NFR plans & organizations.)',
      properties: 'resellerCanAddNfrPlan',
      valType: 'bool',
    },
  ];
  const resellerPermissionsFeaturesNonProfit = [
    {
      label: 'Inherit to Sub-Reseller',
      subHeadingText:
        '(By enabling this, sub-resellers will follow the settings that is currently active for the distributor.)',
      properties: 'inheritNfpEnablement',
      valType: 'bool',
    },
    {
      label: 'Non-Profit Plan',
      subHeadingText: '(Enable this reseller to resell Non-profit SKU.)',
      properties: 'nfpEnabled',
      valType: 'bool',
    },
  ];
  const resellerPermissionsFeaturesQBO = [
    {
      label: 'Inherit to Sub-Reseller',
      subHeadingText:
        '(By enabling this, sub-resellers will follow the settings that is currently active for the distributor.)',
      properties: 'inheritQboBackupFeature',
      valType: 'bool',
    },
    {
      label: 'Quickbooks Online',
      properties: 'qboEnabled',
      valType: 'string',
    },
  ];
  const resellerPermissionsOrgSettingsOrgPermissionsAccountsActivities = [
    {
      label: 'Delete accounts',
      subHeadingText: '(Personal & Backup plan only)',
      properties: 'canDeleteEmailAccount',
      valType: 'bool',
    },
    {
      label: 'Deactivate accounts, sites or groups',
      properties: 'deactivateEmailAccount',
      valType: 'bool',
    },
    {
      label: 'Download accounts, sites or groups',
      properties: 'downloadEmailAccount',
      valType: 'bool',
    },
    {
      label: 'Add email accounts, sites or groups to backup',
      properties: 'manageEmailAccount',
      valType: 'bool',
    },
    {
      label: 'Migrate Email Accounts',
      properties: 'migrateEmailAccount',
      valType: 'bool',
    },
    {
      label: 'Restore accounts, sites or groups',
      properties: 'restoreEmailAccount',
      valType: 'bool',
    },
    {
      label: 'View Backup History',
      properties: 'viewBackupHistory',
      valType: 'bool',
    },
    {
      label: 'View Migrate History',
      properties: 'viewMigrateHistory',
      valType: 'bool',
    },
    {
      label: 'View Restore History',
      properties: 'viewRestoreHistory',
      valType: 'bool',
    },
  ];
  const resellerPermissionsOrgSettingsOrgPermissionsEnableRestoreMigrateDownloadLimit = [
    {
      label:
        'System will only process 1 restore/download/migrate per user on each backup product at a time.',
      subHeadingText: 'Any additional requests will be put in queue',
      properties: 'userActionsSequenceLimit',
      valType: 'bool',
    },
  ];
  const resellerPermissionsOrgSettingsOrgPermissionsJournalAccountsArchiverPlanOnly = [
    {
      label: 'Add/update journal account',
      properties: 'manageJournalAccount',
      valType: 'bool',
    },
  ];
  const resellerPermissionsOrgSettingsOrgPermissionsOrganisationPreferences = [
    {
      label: 'Update their account’s credentials',
      properties: 'accountSettings',
      valType: 'bool',
    },
    {
      label: 'Invite colleagues to backup/archive',
      properties: 'managePendingAccount',
      valType: 'bool',
    },
    {
      label: 'Manage Pending email accounts, sites or groups',
      properties: 'managePendingEmailAccount',
      valType: 'bool',
    },
    {
      label: 'Create user login from backed up/archived email account',
      properties: 'managePendingUser',
      valType: 'bool',
    },
    {
      label: 'Login to portal using email credentials or Azure AD SSO',
      subHeadingText: '(M365 only)',
      properties: 'webLogin',
      valType: 'bool',
    },
  ];
  const resellerPermissionsOrgSettingsOrgPermissionsOthers = [
    {
      label: 'View Attachment',
      properties: 'viewAttachment',
      valType: 'bool',
    },
    {
      label: 'View Insights',
      properties: 'viewInsights',
      valType: 'bool',
    },
    {
      label: 'View Report',
      properties: 'viewReport',
      valType: 'bool',
    },
  ];
  const resellerPermissionsOrgSettingsOrgPermissionsSavedSearchArchiverPlanOnly = [
    {
      label: 'Set Alert for Saved Search',
      properties: 'alertAdvancedSearch',
      valType: 'bool',
    },
    {
      label: 'Download Advanced Search',
      properties: 'downloadAdvancedSearch',
      valType: 'bool',
    },
    {
      label: 'Migrate Advanced Search',
      properties: 'migrateAdvancedSearch',
      valType: 'bool',
    },
    {
      label: 'Restore Advanced Search',
      properties: 'restoreAdvancedSearch',
      valType: 'bool',
    },
    {
      label: 'Create Saved Search',
      properties: 'saveAdvancedSearch',
      valType: 'bool',
    },
  ];
  const resellerPermissionsOrgSettingsOrgPermissionsUserManagementBackupAndArchiverPlanOnly = [
    {
      label: 'Manage User Access',
      properties: 'manageUserAccesss',
      valType: 'bool',
    },
  ];
  const resellerPermissionsPaymentSettings = [
    {
      label: 'Billing Date',
      properties: 'billingDate',
      valType: 'int',
    },
    {
      label: 'Billing Type',
      properties: 'billingType',
      valType: 'string',
    },
    {
      label: 'Next Month Billing Date',
      properties: 'nextMonthBillingDate',
      valType: 'int',
    },
    {
      label: 'Deactivated Account %',
      properties: 'numberDeactivateRulePercentage',
      valType: 'double',
    },
    {
      label: 'Payment Billing Entity',
      properties: 'paymentBillingEntity',
      valType: 'int',
    },
    {
      label: 'Paypal',
      properties: 'paypalEnabled',
      valType: 'bool',
    },
    {
      label: 'Stripe',
      properties: 'stripeEnabled',
      valType: 'bool',
    },
  ];
  const resellerPermissionsTrial = [
    {
      label: 'Trial Allowed',
      properties: 'canHaveTrial',
      valType: 'bool',
    },
    {
      label: 'Trial Days',
      properties: 'trialDays',
      valType: 'int',
    },
    {
      label: 'Trial Period',
      properties: 'trialPeriod',
      valType: 'int',
    },
  ];
  const resellerPricingLabels = [
    {
      label: 'Commit Price',
      properties: 'commitPrice',
      valType: 'double',
    },
    {
      label: 'Currency',
      properties: 'currency',
      valType: 'string',
    },
    {
      label: 'Minimum Storage Required for Customer SKU for Basic Backup (GB)',
      properties: 'minPersonalStorage',
      valType: 'int',
    },
    {
      label: 'Personal Price Per GB',
      subHeadingText: '(Note: For prices, 1.2 = 1 dollar 20 cents. 0.2 = 20 cents)',
      properties: 'personalPricePerGb',
      valType: 'double',
    },
  ];
  const smtpSettingsLabels = [
    {
      label: 'ID',
      properties: 'smtpID',
      valType: 'int',
    },
    {
      label: 'From Name',
      properties: 'fromName',
      valType: 'string',
    },
    // {
    //   label: 'Email',
    //   properties: 'encryptedEmail',
    //   valType: 'string',
    // },
    {
      label: 'Host',
      properties: 'host',
      valType: 'string',
    },
    {
      label: 'Port',
      properties: 'port',
      valType: 'int',
    },
    {
      label: 'Username',
      properties: 'username',
      valType: 'string',
    },
    {
      label: 'Password',
      properties: 'encryptedPassword',
      valType: 'string',
    },
    {
      label: 'Authentication',
      properties: 'authentication',
      valType: 'string',
    },
    {
      label: 'SSL',
      properties: 'ssl',
      valType: 'bool',
    },
    {
      label: 'Start TLS',
      properties: 'startTls',
      valType: 'bool',
    },

    {
      label: 'Custom SMTP',
      properties: 'useCustomSmtp',
      valType: 'bool',
    },
    {
      label: 'Created On',
      properties: 'smtpCreatedAt',
      valType: 'string',
    },
    {
      label: 'Last Updated On',
      properties: 'smtpUpdatedAt',
      valType: 'string',
    },
    {
      label: 'Delivery Method',
      properties: 'deliveryMethod',
      valType: 'string',
    },
    {
      label: 'Use Masking',
      properties: 'useMasking',
      valType: 'bool',
    },
    {
      label: 'Test Status',
      properties: 'testStatus',
      valType: 'int',
    },
    {
      label: 'Last Test Sent On',
      properties: 'lastTestSentAt',
      valType: 'string',
    },
    {
      label: 'SMTP Auto Test',
      properties: 'smtpAutoTestEnabled',
      valType: 'bool',
    },
    {
      label: 'Test Email Destination',
      properties: 'testEmailDestination',
      valType: 'string',
    },
    {
      label: 'Test Periodicity Enabled',
      properties: 'testPeriodicityEnabled',
      valType: 'bool',
    },
    {
      label: 'Test Periodicity',
      properties: 'testPeriodicity',
      valType: 'string',
    },
  ];

  return {
    apiSettings,
    notificationsSettingsABackupNotification,
    notificationsSettingsABackupNotificationAccountAdded,
    notificationsSettingsABackupNotificationAutoDiscover,
    notificationsSettingsUserManagementNotification,
    notificationsSettingsUserManagementNotificationDeactivatedAccount,
    notificationsSettingsReporting,
    notificationsSettingsOrganizationManagementNotification,
    notificationsSettingsNFRConvertedToPaid,
    notificationsSettingsNFRExpiringOrganisation,
    notificationsSettingsNFRNewOrganisation,
    notificationsSettingsNFR,
    notificationsSettingsOrgOwner,
    notificationsSettingsAccountingBackupNotification,
    notificationsSettingsBackupAD,
    notificationsSettingsBackupError,
    notificationsSettingsMailboxDeactivated,
    notificationsSettingsOutOfCapacityPersonalPlan,
    notificationsSettingsSuccessfulBackup,
    notificationsSettingsComplianceNotification,
    notificationsSettingsAccounting,
    notificationsSettingsGeneral,
    notificationsSettingsGeneralErrorSummary,
    notificationsSettingsGeneralMimeConversionError,
    notificationsSettingsUserManagementNotificationSubscriptionChange,
    notificationsSettingsBackupReachingLimit,
    notificationsSettingsCredentialsNotification,
    notificationSettingsDownloadNotificationDownloadReady,
    resellerDetails,
    resellerDetailsPartnerType,
    resellerDetailsPortalSettings,
    resellerPermissions,
    resellerPermissionsAdminPrivileges,
    resellerPermissionsContractSettings,
    resellerPermissionsDistributorMasterResellerPermissions,
    resellerPermissionsFeatures,
    resellerPermissionsFeaturesLoginAsClient,
    resellerPermissionsFeaturesNFR,
    resellerPermissionsFeaturesNonProfit,
    resellerPermissionsFeaturesQBO,
    resellerPermissionsOrgSettingsOrgPermissionsAccountsActivities,
    resellerPermissionsOrgSettingsOrgPermissionsEnableRestoreMigrateDownloadLimit,
    resellerPermissionsOrgSettingsOrgPermissionsJournalAccountsArchiverPlanOnly,
    resellerPermissionsOrgSettingsOrgPermissionsOrganisationPreferences,
    resellerPermissionsOrgSettingsOrgPermissionsOthers,
    resellerPermissionsOrgSettingsOrgPermissionsSavedSearchArchiverPlanOnly,
    resellerPermissionsOrgSettingsOrgPermissionsUserManagementBackupAndArchiverPlanOnly,
    resellerPermissionsPaymentSettings,
    resellerPermissionsTrial,
    resellerPricingLabels,
    resellerPermissionsFeaturesLoginAsClientPermissions,
    resellerPermissionsFeaturesLoginAsClientView,
    resellerPermissionsFeaturesLoginAsClientOthers,
    resellerPermissionsFeaturesMsGroupSettings,
    smtpSettingsLabels,
  };
}
