import SchemaBuilderError, { throwMessage } from './SchemaBuilderError';

/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */
class SchemaBuilder {
  getClient() {
    throw new SchemaBuilderError(throwMessage('getClient', 'SchemaBuilder'));
  }

  object() {
    throw new SchemaBuilderError(throwMessage('object', 'SchemaBuilder'));
  }

  string() {
    throw new SchemaBuilderError(throwMessage('string', 'SchemaBuilder'));
  }

  number() {
    throw new SchemaBuilderError(throwMessage('number', 'SchemaBuilder'));
  }

  date() {
    throw new SchemaBuilderError(throwMessage('date', 'SchemaBuilder'));
  }

  array() {
    throw new SchemaBuilderError(throwMessage('array', 'SchemaBuilder'));
  }
}

export default SchemaBuilder;
