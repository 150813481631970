import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import { QboCard, QboBreadcrumbs, QboTypography, QboTabs, QboLink } from '@ui/Components';
import { userBackupInfoPath } from '@config/Routes/WebClientRoutes';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import UserBackupDetailSection from '@ui/Sections/UserBackupPage/UserBackupDetailSection';
import LastBackupSection from '@ui/Sections/UserBackupPage/LastBackupSection';
import { useSelector } from 'react-redux';

export default function UserBackupDetailPage() {
  // navigation
  const navigate = useNavigate();
  const { detailPageState } = useSelector((state) => state.userBackupState);
  const { id, email, backupType } = detailPageState;

  // tabs
  const tabNames = ['Details', 'Last Backup'];
  const [selectTab, setSelectTab] = useState(0);
  const handleOnTabClick = (e, newValue) => {
    setSelectTab(newValue);
  };

  // store last backup id on state
  const [lastBackupId, setLastBackupId] = useState(0);

  // redirect to list page if state is null
  useEffect(() => {
    if (!id) {
      navigate(userBackupInfoPath);
    }
  }, [id]);

  const tabComponent = (selectTabState) => {
    switch (selectTabState) {
      case 0:
        return (
          <QboCard noPadding isBordered sx={{ marginTop: 2 }}>
            <UserBackupDetailSection
              userBackupId={id}
              backupType={backupType}
              setLastBackupId={setLastBackupId}
            />
          </QboCard>
        );
      case 1:
        return (
          <QboCard noPadding isBordered sx={{ marginTop: 2 }}>
            <LastBackupSection lastBackupId={lastBackupId} backupType={backupType} />
          </QboCard>
        );
      default:
        return <NoStatusSection />;
    }
  };

  return (
    <>
      <HeaderPageBlock>Backup Detail ({backupType})</HeaderPageBlock>
      <QboBreadcrumbs sx={{ marginBottom: 2 }}>
        <QboLink
          onClick={() => {
            navigate(userBackupInfoPath);
          }}>
          Backups
        </QboLink>
        <QboTypography color="text.primary">Backup Detail ({backupType})</QboTypography>
      </QboBreadcrumbs>
      <QboCard isMainWrapper>
        {id && (
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '2%' }}>
            <QboTypography variant="h5" wrap>
              {email}
            </QboTypography>
            <QboTypography variant="h5">(ID: {id})</QboTypography>
          </div>
        )}
        <div
          style={{
            overflowX: 'auto',
            marginRight: 5,
            display: 'flex',
            scrollbarWidth: 'thin',
            backgroundColor: '#c4e3f0',
          }}>
          <QboTabs tabIndex={selectTab} title={tabNames} onChange={handleOnTabClick} />
        </div>
        {tabComponent(selectTab)}
      </QboCard>
    </>
  );
}
