import { createSlice } from '@reduxjs/toolkit';
import {
  submitTransferPartnerAsync,
  submitTransferPartnerFormExtraReducers,
} from './serviceActions/submitTransferPartnerAsync';

const initialState = {
  submitTransferPartnerForm: {
    submitting: false,
    success: false,
    error: null,
    statusCode: null,
    errorMessage: null,
  },
};

const submitTransferPartnerSlice = createSlice({
  name: 'SubmitTransferPartnerForm',
  initialState,
  reducers: {
    resetSubmitTransferPartnerFormSubmit(state) {
      state.submitTransferPartnerForm = initialState.submitTransferPartnerForm;
    },
  },
  extraReducers: {
    ...submitTransferPartnerFormExtraReducers,
  },
});

export { submitTransferPartnerAsync };
export const { resetSubmitTransferPartnerFormSubmit } = submitTransferPartnerSlice.actions;
export default submitTransferPartnerSlice.reducer;
