import { createAsyncThunk } from '@reduxjs/toolkit';
import { createMailBoxAPI } from '@services/WebApiService';

const mailBoxChangeAsync = createAsyncThunk('Change/mailBoxChange', async (paramsList) => {
  try {
    const data = await createMailBoxAPI(paramsList);
    return data;
  } catch (error) {
    return error.response;
  }
});

const { pending, fulfilled, rejected } = mailBoxChangeAsync;
const mailboxChangeReducers = {
  [pending]: (state) => {
    state.mailChangeState.fetching = true;
  },
  [rejected]: (state) => {
    state.mailChangeState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.mailChangeState.fetching = false;
    state.mailChangeState.statusCode = action?.payload?.status;
    state.mailChangeState.errorMessage = action?.payload?.data?.message;

    if (action?.payload?.data?.title) {
      state.mailChangeState.errorMessage = action?.payload?.data?.title;
    }

    if (action?.payload?.status === 403) {
      state.mailChangeState.errorMessage = 'You are not authorize to access this resource';
    }

    if (!action?.payload) {
      state.mailChangeState.errorMessage = 'Something went wrong while processing your request.';
    }

    if (action?.payload?.code) {
      state.mailChangeState.error = action.payload.code;
    } else {
      state.mailChangeState.success = true;
    }
  },
};

export { mailBoxChangeAsync, mailboxChangeReducers };

export default mailBoxChangeAsync;
