import { createSlice } from '@reduxjs/toolkit';

import { getRegionsListAsync, getRegionsExtraReducers } from './serviceActions/fetchRegionsAsync';

const initialState = {
  getRegions: {
    fetching: false,
    success: false,
    error: null,
    data: [],
    regionErrorMessage: null,
  },
};

const regionsSlice = createSlice({
  name: 'Regions',
  initialState,
  reducers: {
    resetGetRegionsStatus(state) {
      state.getRegions = initialState.getRegions;
    },
  },
  extraReducers: {
    ...getRegionsExtraReducers,
  },
});

export { getRegionsListAsync };
export const { resetGetRegionsStatus, resetRegionsStatusCode } = regionsSlice.actions;
export default regionsSlice.reducer;
