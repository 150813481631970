import { useTranslation } from 'react-i18next';
import DateTimeFactory from '@libs/DateTime';
import BaseSetting from '@config/BaseSetting';

const DEFAULT_FULL_DATE_TIME = 'FULL_DATE_TIME'; // eg: Thursday, 28 February 2019 at 22:55
const DEFAULT_DATE_TIME = 'DATE_TIME'; // eg: 28 Feb 2019 22:55
const DEFAULT_DATE = 'DATE'; // eg: 28 Feb 2019
const DEFAULT_FULL_DATE = 'DATE_DETAIL'; // eg: 28 February 2019
const DEFAULT_TIME = 'TIME'; // eg: 22:55
const DEFAULT_FULL_DATE_TIME_WITHOUT_DAY_NAME = 'FULL_DATE_TIME_WITHOUT_DAY_NAME'; // eg: 28 February 2019 22:55
const DEFAULT_FULL_DATE_TIME_WITH_SORTH_MONTH = 'FULL_DATE_TIME_WITH_SORTH_MONTH'; // eg: Thursday, 28 Feb 2019 at 22:55

const dateClient = new DateTimeFactory({ parseFormat: BaseSetting.backendDateTimeFormat }).create();

export default function useDateTime() {
  const { t } = useTranslation();

  const dateFormat = {
    DEFAULT_FULL_DATE_TIME,
    DEFAULT_DATE_TIME,
    DEFAULT_DATE,
    DEFAULT_FULL_DATE,
    DEFAULT_TIME,
    DEFAULT_FULL_DATE_TIME_WITHOUT_DAY_NAME,
    DEFAULT_FULL_DATE_TIME_WITH_SORTH_MONTH,
  };

  const dateToString = (format, date) => {
    if (!date) return null;

    switch (format) {
      case DEFAULT_FULL_DATE_TIME:
        return dateClient.toString(date, t('date.formats.default.full_date_time'));
      case DEFAULT_DATE_TIME:
        return dateClient.toString(date, t('date.formats.default.date_time'));
      case DEFAULT_DATE:
        return dateClient.toString(date, t('date.formats.default.date'));
      case DEFAULT_FULL_DATE:
        return dateClient.toString(date, t('date.formats.default.full_date'));
      case DEFAULT_TIME:
        return dateClient.toString(date, t('date.formats.default.time'));
      case DEFAULT_FULL_DATE_TIME_WITHOUT_DAY_NAME:
        return dateClient.toString(date, t('date.formats.default.full_date_time_without_day_name'));
      case DEFAULT_FULL_DATE_TIME_WITH_SORTH_MONTH:
        return dateClient.toString(date, t('date.formats.default.full_date_time_with_short_month'));
      default:
        throw Error('Date time format is not defined');
    }
  };

  const dateDistance = (date1, date2, { separator }) => {
    const optSeprator = separator || ' ';
    const [inHour, inMinutes, inSeconds] = dateClient.dateDistance(date1, date2);

    const viewFormat = [];

    if (inHour > 0) viewFormat.push(t('date.initial_hour', { count: inHour }));
    if (inMinutes > 0) viewFormat.push(t('date.initial_minute', { count: inMinutes }));
    if (inSeconds > 0) viewFormat.push(t('date.initial_second', { count: inSeconds }));

    return viewFormat.join(optSeprator);
  };

  const dateTimeNow = () => {
    return dateClient.dateTimeNow();
  };

  const mergeDateAndTime = (date, time) => {
    return dateClient.mergeDateAndTime(date, time);
  };

  const equalDateValidation = (date, time) => {
    return dateClient.equalDateValidation(date, time);
  };

  const toISOFormat = (date, time) => {
    return dateClient.toISOFormat(date, time);
  };

  const dateTimeNowObject = () => {
    return dateClient.dateTimeNowObject();
  };

  const stringToDate = (date) => {
    return dateClient.stringToDate(date);
  };

  const sortArrayDate = (date) => {
    return dateClient.sortArrayDate(date);
  };

  return {
    dateToString,
    dateFormat,
    dateDistance,
    dateTimeNow,
    mergeDateAndTime,
    equalDateValidation,
    toISOFormat,
    dateTimeNowObject,
    stringToDate,
    sortArrayDate,
  };
}
