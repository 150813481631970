import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import useLocalStorage from '@hooks/useLocalStorage';
import { submitPurgeExternalAccountAsync } from '@features/purgeExternalAccount/purgeExternalAccountSlice';
import { constant } from '@config/BaseSetting';
import {
  getPurgeExternalAccountChangeAsync,
  resetPurgeExternalAccountStatus,
} from '@features/purgeExternalAccount/getPurgeExternalAccountSlice';
import useValidation from './useValidation';

function useForm() {
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const { getPurgeExternalAccountState } = useSelector(
    (state) => state.getPurgeExternalAccountState
  );
  const dispatch = useDispatch();

  const {
    supportTicketAttributes,
    escalationTicketAttributes,
    descriptionAttributes,
    organizationOwnerIdAttributes,
    externalEmailAccountAttributes,
    resetForm,
    isValid,
    formData,
    validateAll,
  } = useValidation();
  const previousPropsRef = useRef(formData);

  const submitHandler = (isFormValid) => {
    const previousProps = previousPropsRef.current;
    if (isFormValid && !isEqual(previousProps, formData)) {
      dispatch(
        submitPurgeExternalAccountAsync({
          accessToken: accessTokenSet,
          reset: true,
          parameters: formData,
        })
      );
      previousPropsRef.current = formData;
    }
  };

  const getPurgeExternalAccountRequestData = (data, setPurgeExternalAccountData) => {
    const modifiedData = data?.map((item) => {
      const payloadObj = JSON.parse(item.payload);
      const modifiedPayloadObj = {
        ...payloadObj,
        'executed-on': item['executed-on'],
        'status-description': item['status-description'],
      };
      return modifiedPayloadObj;
    });
    setPurgeExternalAccountData(modifiedData);
  };

  const getPurgeExternalAccountServices = (setPurgeExternalAccountData, setPagesSet) => {
    if (getPurgeExternalAccountState?.data?.length > 0) {
      setPagesSet(getPurgeExternalAccountState?.pagination);
      getPurgeExternalAccountRequestData(
        getPurgeExternalAccountState?.data,
        setPurgeExternalAccountData
      );
    }
  };

  const getPurgeExternalAccountAPIHandler = (pageParams) => {
    if (!getPurgeExternalAccountState?.fetching) {
      dispatch(resetPurgeExternalAccountStatus());
      dispatch(
        getPurgeExternalAccountChangeAsync({
          accessToken: accessTokenSet,
          reset: true,
          pageNumber: pageParams,
        })
      );
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const isFormValid = validateAll();
    submitHandler(isFormValid);
  };

  useEffect(() => {}, [isValid]);

  return {
    supportTicketAttributes,
    escalationTicketAttributes,
    descriptionAttributes,
    organizationOwnerIdAttributes,
    externalEmailAccountAttributes,
    isValid,
    resetForm,
    formData,
    onSubmit,
    getPurgeExternalAccountServices,
    getPurgeExternalAccountAPIHandler,
  };
}

export default useForm;
