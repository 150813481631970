import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import {
  ITBox,
  QboAdvanceTable,
  QboAlert,
  QboButton,
  QboCard,
  QboFormGroup,
  QboSnackbar,
  QboTextField,
  QboTypography,
} from '@ui/Components';
import BaseSetting from '@config/BaseSetting';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import { resetMonthlyBackupReportsStatus } from '@features/monthlyBackupReport/monthlyBackupSlice';
import useForm from './pageHooks/useForm';
import useFetchHandler from './pageHooks/useFetchHandler';

export default function MonthlyBackupReportsPage() {
  const {
    resellerIdAttributes,
    isValid,
    resetForm,
    onSubmit,
    resellerId,
    pagination,
    setPagination,
  } = useForm();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { fetchHandler, responseDataConverter, fetchExportHandler, convertToCsv } =
    useFetchHandler();
  const isAsteriskRequired = true;
  const [reportData, setReportData] = useState([]);
  const { monthlyBackupReportsState } = useSelector((state) => state.monthlyBackupData);
  const { exportMonthlyBackupReportState } = useSelector(
    (state) => state.exportMonthlyBackupReport
  );

  const [downloadStatus, setDownloadStatus] = useState('');
  const [open, setOpen] = useState(false);
  const [type, setType] = useState('');

  const {
    fetching,
    errorMessage,
    forbiddenErrorMessage,
    pagination: paginations,
    statusCode,
  } = monthlyBackupReportsState;
  const { fetching: exportFetching, monthlyBackupExportData } = exportMonthlyBackupReportState;

  const style = {
    color: '#000000DE',
    fontWeight: 600,
  };

  const buttonStyle = {
    height: 36,
    backgroundColor: '#a5a5a5',
    textDecoration: 'none',
    color: 'black',
    '&:hover': {
      backgroundColor: '#a5a5a5',
    },
    textTransform: 'unset',
    fontSize: 14,
  };

  const handleOnExportCSV = () => {
    if (!exportFetching) {
      fetchExportHandler({
        pageNumber: pagination.pageIndex + 1,
        pageSize: pagination.pageSize,
        resellerId,
      });
    }
  };

  useEffect(() => {
    if (monthlyBackupExportData) {
      convertToCsv(monthlyBackupExportData, setDownloadStatus, setOpen, setType);
    }
  }, [monthlyBackupExportData]);

  const handleOnResetButton = () => {
    dispatch(resetMonthlyBackupReportsStatus());
    resetForm();
  };

  const fetchMonthlyBackupHandler = () => {
    fetchHandler({
      pageNumber: pagination.pageIndex + 1,
      pageSize: pagination.pageSize,
      resellerId,
    });
  };

  const handleOnSnackbarCose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (statusCode === 200) {
      resetForm();
    }
  }, [statusCode]);

  useEffect(() => {
    responseDataConverter(monthlyBackupReportsState?.monthlyBackupData, setReportData, resetForm);
  }, [monthlyBackupReportsState.monthlyBackupData]);

  useEffect(() => {
    if (!errorMessage && !isEmpty(resellerId) && statusCode) {
      fetchMonthlyBackupHandler();
    }
  }, [pagination]);

  useEffect(() => {
    if (isValid && !isEmpty(resellerId) && !fetching) {
      fetchMonthlyBackupHandler();
    }
  }, [resellerId]);

  return (
    <>
      <QboSnackbar
        open={open}
        type={type}
        message={downloadStatus}
        onClose={handleOnSnackbarCose}
      />
      <QboCard noPadding isMainWrapper>
        <ITBox className="TransferSubscriptionFormPage__wrapper" direction="column">
          <QboFormGroup
            buttonStyle={buttonStyle}
            onSubmit={onSubmit}
            withGenerateAndResetButton
            handleOnResetButton={handleOnResetButton}>
            <QboTypography style={{ fontSize: 20, ...style }}>
              {t('monthly_backup_report.title')}
            </QboTypography>
            <QboTextField
              isWidthSetExplicit
              required={isAsteriskRequired}
              id="resellerID"
              placeholder={t('monthly_backup_report.form.reseller_id')}
              label={t('monthly_backup_report.form.reseller_id')}
              value={resellerIdAttributes?.value}
              errorMessage={resellerIdAttributes?.errorMessage}
              inputProps={{ maxLength: 225 }}
              onChange={(e) => {
                resellerIdAttributes.handleChange(e.currentTarget.value);
              }}
            />
          </QboFormGroup>
        </ITBox>
      </QboCard>
      <hr />

      {monthlyBackupReportsState?.monthlyBackupData.length > 0 ? (
        <QboCard>
          <ITBox className="TransferSubscriptionFormPage__wrapper" direction="column">
            {resellerId !== 0 && (
              <QboTypography style={{ fontSize: 14, ...style }}>
                Reseller ID: {resellerId}
              </QboTypography>
            )}
          </ITBox>
          <ITBox className="TransferSubscriptionFormPage__wrapper" direction="column">
            <QboTypography style={{ fontSize: 20, ...style }}>
              {t('monthly_backup_report.monthly_backup_details')}
            </QboTypography>
            <QboButton style={{ width: 120 }} variant="contained" onClick={handleOnExportCSV}>
              {t('export_csv')}
            </QboButton>
          </ITBox>
          <div style={{ padding: '0px 15px 20px 15px' }}>
            <QboAdvanceTable
              isLoading={fetching}
              headers={BaseSetting.monthlyBackupDetailsTableHeaders}
              rows={reportData}
              pagination={pagination}
              setPagination={setPagination}
              rowsCount={paginations?.TotalCount}
            />
          </div>
        </QboCard>
      ) : (
        <QboCard>
          <ITBox className="TransferSubscriptionFormPage__wrapper" direction="column">
            <QboTypography style={{ fontSize: 20, ...style }}>
              {t('monthly_backup_report.monthly_backup_details')}
            </QboTypography>
            {errorMessage && (
              <QboAlert type="error" style={{ fontSize: '1.15em' }}>
                {errorMessage}
              </QboAlert>
            )}
            <NoStatusSection forbiddenErrorMessage={forbiddenErrorMessage} />
          </ITBox>
        </QboCard>
      )}
    </>
  );
}
