import React, { useEffect } from 'react';
import { Skeleton } from '@mui/material';
import { QboCard } from '@ui/Components';
import QboCardSingleList from '@ui/Components/QboCardSingleList';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import { useSelector } from 'react-redux';
import useDataService from './pagehooks/useDataService';
import useHEXTenantDetailInformationSerice from './pagehooks/useHEXTenantDetailInformationSerice';

export default function HEXTenantDetailsPage(props) {
  const { hexTenantCredentialId } = props;
  const { getHEXTenantDetailsInfoState } = useSelector((state) => state.hexTenantDetailsInfo);
  const { hexTenantDetailsInformation, fetching } = getHEXTenantDetailsInfoState;
  const { handleFetchHEXTenantDetailInformationAsync } = useHEXTenantDetailInformationSerice();
  const { hexTenantInfoDetails } = useDataService();

  useEffect(() => {
    if (hexTenantDetailsInformation.length === 0) {
      handleFetchHEXTenantDetailInformationAsync(hexTenantCredentialId);
    }
  }, [hexTenantCredentialId]);

  const valueHandler = (properties) => {
    const responseData = hexTenantDetailsInformation[0];
    let value = ' ';

    const dataObject = {
      0: 'Not Ready',
      1: 'In Progress',
      5: 'Error',
      7: 'Completed',
    };

    if (responseData && Object.keys(responseData).length > 0) {
      value = responseData[properties] || '-';

      if (properties === 'status') {
        value = dataObject[responseData[properties]] || 'Error';
      }
    }

    return value;
  };

  const renderLoading = () => {
    return (
      <div style={{ display: 'contents' }}>
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="70%" />
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="70%" />
      </div>
    );
  };

  const componentHandler = () => {
    let content = null;

    if (fetching) {
      content = renderLoading();
    } else if (
      hexTenantDetailsInformation[0] &&
      Object.keys(hexTenantDetailsInformation[0]).length > 0
    ) {
      content = (
        <QboCard
          className="QboCard__wrapper_search_text_highlight"
          sx={{
            scrollbarWidth: 'none !important',
            overflowY: 'scroll !important',
            height: '65vh',
          }}>
          <QboCard sx={{ marginTop: 2, marginBottom: 2 }}>
            {hexTenantInfoDetails?.map((notification) => (
              <QboCardSingleList
                key={notification.label}
                subHeadingText={notification?.subHeadingText}
                leftContent={notification.label}
                rightContent={valueHandler(notification.properties)}
              />
            ))}
          </QboCard>
        </QboCard>
      );
    } else if (!fetching && !hexTenantDetailsInformation[0]) {
      content = <NoStatusSection />;
    }

    return content;
  };

  return componentHandler();
}
