import { createSlice } from '@reduxjs/toolkit';

import {
  createDomainBackupsAsync,
  createDomainBackupsExtraReducers,
} from './serviceActions/createDomainBackupsAsync';

const initialState = {
  domainBackupsState: {
    fetching: false,
    status: null,
    error: null,
    message: null,
    errorMessage: null,
    forbiddenState: false,
    forbiddenErrorMessage: null,
    domainBackupsCorsErrorMessage: null,
  },
};

const createDomainBackupsSlice = createSlice({
  name: 'domainBackups',
  initialState,
  reducers: {
    resetCreateDomainBackupsState(state) {
      state.domainBackupsState = initialState.domainBackupsState;
    },
  },
  extraReducers: {
    ...createDomainBackupsExtraReducers,
  },
});

export { createDomainBackupsAsync };

export const { resetCreateDomainBackupsState } = createDomainBackupsSlice.actions;
export default createDomainBackupsSlice.reducer;
