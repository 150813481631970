import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { QboCard, QboCardCaption, QboTypography } from '@ui/Components';
import NoStatus from '@images/generals/NoStatus.svg';

export default function ErrorHandle(props) {
  const { titleText } = props;
  return (
    NoStatus && (
      <QboCard noPadding isMainWrapper>
        <Box className="LoginPage__wrapper">
          <QboCardCaption
            imageUrl={NoStatus}
            heading={titleText}
            caption="An unexpected issue occurred within the server."
          />
        </Box>
      </QboCard>
    )
  );
}

ErrorHandle.propTypes = {
  titleText: PropTypes.string,
};
ErrorHandle.defaultProps = {
  titleText: null,
};
