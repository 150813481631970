import { useState } from 'react';
import * as yup from 'yup';
import { pascalToTitleCase } from '@utilities/stringUtils';

const supportTicketName = 'supportTicket';
const escalationTicketName = 'escalationTicket';
const descriptionName = 'description';

const initialForm = {
  [supportTicketName]: '',
  [escalationTicketName]: '',
  [descriptionName]: '',
};

export default function useFormValidation() {
  const [isValid, setIsValid] = useState(false);
  const [errors, setErrors] = useState('');
  const [formData, setFormData] = useState(initialForm);
  const formSchema = yup.object().shape({
    [supportTicketName]: yup.string().required('Support Ticket is required field'),
    [escalationTicketName]: yup.string(),
    [descriptionName]: yup.string(),
  });

  const validate = async (value) => {
    await formSchema.validate(value, { abortEarly: false }).catch((err) => {
      const errorLogs = err.inner.reduce((acc, error) => {
        return {
          ...acc,
          [error.path]: pascalToTitleCase(error.message.split(',')[0]),
        };
      }, {});

      setErrors(errorLogs);
      return errorLogs;
    });

    const validation = await formSchema.isValid(value);
    if (validation) {
      setErrors({});
    }
    setIsValid(validation);
  };

  const validateAll = () => {
    validate(formData);
  };

  const resetValidation = () => {
    if (!isValid) {
      setErrors({});
      setIsValid(false);
    }
  };

  const resetForm = () => {
    setFormData(initialForm);
    resetValidation();
  };

  const updateFormData = (newData) => {
    setFormData((prev) => {
      return { ...prev, ...newData };
    });
    resetValidation();
  };

  const supportTicketAttributes = {
    handleChange: async (value) => {
      updateFormData({ [supportTicketName]: value });
    },
    errorMessage: errors[supportTicketName],
    value: formData[supportTicketName],
  };
  const escalationTicketAttributes = {
    handleChange: async (value) => {
      updateFormData({ [escalationTicketName]: value });
    },
    value: formData[escalationTicketName],
  };
  const descriptionAttributes = {
    handleChange: async (value) => {
      updateFormData({ [descriptionName]: value });
    },
    value: formData[descriptionName],
  };

  return {
    formData,
    supportTicketAttributes,
    escalationTicketAttributes,
    descriptionAttributes,
    validateAll,
    isValid,
    resetForm,
  };
}
