import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { QboSnackbar, QboTypography } from '@ui/Components';
import { dataIngestionListPath } from '@config/Routes/WebClientRoutes';
import BaseSetting from '@config/BaseSetting';
import { resetIngestionFailedEmlState } from '@features/ingestion/ingestionFailedEmlSlice';
import useIngestionReportsSection from './pageHooks/useIngestionReportsSection';
import ReportsPage from './ReportsPage';

function FailedEmlIngestionReportsPage(props) {
  const { pagination, setPagination, failedEmlIngestionId, setOpenChildEmlPage } = props;
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [paginations, setPaginations] = useState({ pageIndex: 0, pageSize: 10 });
  const [reportsData, setReportsData] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severityType, setSeverityType] = useState('');
  const { handleFetchIngestionFailedEmlItemAsync, convertIngestionFailedEmlData } =
    useIngestionReportsSection();
  const { getIngestionFailedEmlItemState } = useSelector(
    (stateList) => stateList.ingestionFailedEmlState
  );

  const { fetching, ingestionFailedEmlItem, paginationList } = getIngestionFailedEmlItemState;

  useEffect(() => {
    if (ingestionFailedEmlItem?.length) {
      convertIngestionFailedEmlData(ingestionFailedEmlItem, setReportsData);
    }
  }, [ingestionFailedEmlItem]);

  const fetchHandler = () => {
    if (!fetching) {
      handleFetchIngestionFailedEmlItemAsync(
        failedEmlIngestionId,
        paginations.pageIndex + 1,
        paginations.pageSize
      );
    }
  };

  const handleOnCloseClick = () => {
    setOpenChildEmlPage(false);
    dispatch(resetIngestionFailedEmlState());
    setPagination(pagination);
  };

  const populateTitle = () => (
    <QboTypography sx={{ color: '#42526E' }} className="HeaderPageBlock__heading" variant="h4">
      {t('reports_page.header.eml_statistics_page')}({t('reports_page.header.ingestion_item_id')}
      {failedEmlIngestionId})
    </QboTypography>
  );

  useEffect(() => {
    if (failedEmlIngestionId) {
      setShowModal(true);
    }
  }, [failedEmlIngestionId]);

  useEffect(() => {
    fetchHandler();
  }, [failedEmlIngestionId, paginations]);

  const handleOnButtonClick = () => {
    const fileName = 'exported_data';
  };

  const handleOnSnackbarClose = () => setOpen(false);

  return (
    <>
      <QboSnackbar
        open={open}
        onClose={handleOnSnackbarClose}
        message={message}
        type={severityType}
      />
      <ReportsPage
        isButtonNeeded={false}
        buttonName={t('export_csv')}
        classNameStyle={
          (reportsData?.length <= 6 && 'DataIngestion_reports_modal_eml_min_data') ||
          (reportsData?.length > 6 && 'DataIngestion_reports_modal_eml_data')
        }
        handleOnCloseClick={handleOnCloseClick}
        handleOnButtonClick={handleOnButtonClick}
        showModal={showModal}
        setShowModal={setShowModal}
        pageTitle={populateTitle()}
        reportsData={reportsData}
        fetching={fetching}
        pagination={paginations}
        setPagination={setPaginations}
        headersList={BaseSetting.failedEmlIngestionPageHeaders}
        paginationList={paginationList}
        ingestionID={state?.row}
        path={dataIngestionListPath}
      />
    </>
  );
}

FailedEmlIngestionReportsPage.propTypes = {
  pagination: PropTypes.object,
  setPagination: PropTypes.func,
  setOpenChildEmlPage: PropTypes.func,
  failedEmlIngestionId: PropTypes.number,
};

FailedEmlIngestionReportsPage.defaultProps = {
  pagination: {},
  setPagination: () => {},
  setOpenChildEmlPage: () => {},
  failedEmlIngestionId: 0,
};

export default FailedEmlIngestionReportsPage;
