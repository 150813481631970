import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Menu } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

export default function QboKebabMenu({ menuActions, row }) {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const menuOpen = Boolean(menuAnchorEl);
  const menuClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const menuClose = () => {
    setMenuAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={(event) => menuClick(event)}>
        <MoreHorizIcon />
      </IconButton>
      <Menu anchorEl={menuAnchorEl} open={menuOpen} onClose={menuClose}>
        {menuActions(row, menuClose)}
      </Menu>
    </>
  );
}

QboKebabMenu.propTypes = {
  menuActions: PropTypes.arrayOf(PropTypes.object),
  row: PropTypes.object,
};

QboKebabMenu.defaultProps = {
  menuActions: [],
  row: {},
};
