import { createSlice } from '@reduxjs/toolkit';

import {
  getTransferSubscriptionAsync,
  getTransferSubscriptionExtraReducers,
} from './serviceActions/fetchTransferSubscriptionAsync';

const initialState = {
  getTransferSubscriptionState: {
    fetching: false,
    status: null,
    transferSubscriptionList: [],
    errorMessage: null,
    paginations: [],
    forbiddenState: false,
    forbiddenErrorMessage: null,
    transferSubscriptionCorsErrorMessage: null,
  },
};

const getTransferSubscriptionSlice = createSlice({
  name: 'TransferSubscription',
  initialState,
  reducers: {
    resetTransferSubscriptionState(state) {
      state.getTransferSubscriptionState = initialState.getTransferSubscriptionState;
    },
  },
  extraReducers: {
    ...getTransferSubscriptionExtraReducers,
  },
});

export { getTransferSubscriptionAsync };

export const { resetTransferSubscriptionState } = getTransferSubscriptionSlice.actions;
export default getTransferSubscriptionSlice.reducer;
