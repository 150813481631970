import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { fetchTodosAysnc } from '@features/todo/todoSlice';
import { setIsDrawerOpen, setApplicationReadyStatus } from '@features/application/applicationSlice';
import {
  dashboardPath,
  homePath,
  loginPath,
  errorHandlerPath,
} from '@config/Routes/WebClientRoutes';
import usePageMenu from '@pages/sharedHooks/usePageMenu';
import useLoginSSO from '@pages/LoginPage/pageHooks/useLoginSSO';
import '@config/themes/default';

export default function DefaultApplication() {
  const { setSignedIn } = useLoginSSO();
  const dispatch = useDispatch();
  const { pathname, state } = useLocation();
  const navigate = useNavigate();
  const { inProgress, accounts } = useMsal();
  const { isUserSignedIn } = useSelector((stateList) => stateList.auth);
  const { isDrawerOpen, isApplicationReady } = useSelector((stateList) => stateList.application);
  const { listMenu, setActivePageMenu } = usePageMenu();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (isUserSignedIn || isAuthenticated) {
      if (pathname === loginPath && !state?.userNameError) {
        setActivePageMenu(listMenu.HOME_MENU);
        navigate(dashboardPath, { replace: true });
      }
    } else if (pathname !== loginPath && inProgress === InteractionStatus.None) {
      navigate(loginPath, { replace: true });
    }
  }, [isUserSignedIn, inProgress, pathname]);

  useEffect(() => {
    // If auth is already setup, there must be a condition of having an access token
    // before running setApplicationReadyStatus
    dispatch(setApplicationReadyStatus(true));
  }, []);

  const handleDrawerOpen = () => {
    dispatch(setIsDrawerOpen(true));
  };

  const handleDrawerClose = () => {
    dispatch(setIsDrawerOpen(false));
  };

  return {
    isPublicPage: !isUserSignedIn,
    handleDrawerClose,
    handleDrawerOpen,
    accessToken: 'xxx',
    isDrawerOpen,
    isShowSecondBar: false,
    isApplicationReady,
  };
}
