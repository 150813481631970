import { createSlice } from '@reduxjs/toolkit';
import {
  submitSeatCountChangeAsync,
  submitSeatCountChangeFormExtraReducers,
} from './serviceActions/submitSeatCountChangeAsync';

const initialState = {
  submitSeatCountChangeForm: {
    submitting: false,
    success: false,
    error: null,
    statusCode: null,
    errorMessage: null,
  },
};

const seatCountChangeSlice = createSlice({
  name: 'SeatCountChangeForm',
  initialState,
  reducers: {
    resetSeatCountChangeFormSubmit(state) {
      state.submitSeatCountChangeForm = initialState.submitSeatCountChangeForm;
    },
  },
  extraReducers: {
    ...submitSeatCountChangeFormExtraReducers,
  },
});

export { submitSeatCountChangeAsync };
export const { resetSeatCountChangeFormSubmit } = seatCountChangeSlice.actions;
export default seatCountChangeSlice.reducer;
