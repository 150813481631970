import { useDispatch, useSelector } from 'react-redux';
import useLocalStorage from '@hooks/useLocalStorage';
import { useTranslation } from 'react-i18next';
import { constant } from '@config/BaseSetting';
import { useEffect } from 'react';
import { resetRunXeroSyncState, runXeroSyncAsync } from '@features/invoice/runXeroSyncSlice';
import { showFixedAlert } from '@features/application/applicationSlice';
import ClientRoutes from '@config/Routes/WebClientRoutes';
import useForm from './useForm';

export default function useXeroSync({ period, onSuccessRequest }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const form = useForm();
  const { runXeroSyncState } = useSelector((state) => state.runXeroSync);

  const handleXeroSyncAsync = (parameters) => {
    dispatch(
      runXeroSyncAsync({
        accessTokenSet,
        reset: true,
        parameters,
      })
    );
  };

  const submitRequest = () => {
    if (form.isValid) {
      handleXeroSyncAsync({
        'support-ticket': form.attributes.supportTicketAttributes.value,
        description: form.attributes.descriptionAttributes.value,
        'escalation-ticket': form.attributes.escalationTicketAttributes.value,
        period,
      });
    }
  };

  const handleSubmit = (e) => {
    form.onValidateData(e);
    submitRequest();
  };

  useEffect(() => {
    submitRequest();
  }, [form.isValid]);

  useEffect(() => {
    if (runXeroSyncState.status === 200) {
      if (!runXeroSyncState.jobIds || runXeroSyncState.jobIds?.length > 1) {
        if (runXeroSyncState.serviceType) {
          const link = ClientRoutes.toolsRequestStatusPath({
            'service-type': runXeroSyncState.serviceType,
          });
          dispatch(
            showFixedAlert({
              descriptionHtml: t('invoices_page.xero_sync.success_submitted_with_link', {
                link,
              }),
            })
          );
        } else {
          dispatch(
            showFixedAlert({
              descriptionHtml: t('invoices_page.xero_sync.success_submitted'),
            })
          );
        }
      } else {
        const link = ClientRoutes.toolsRequestStatusPath({
          'service-type': 34,
        });
        dispatch(
          showFixedAlert({
            descriptionHtml: t('invoices_page.xero_sync.success_submitted_with_link', {
              link,
            }),
          })
        );
      }
      if (onSuccessRequest) onSuccessRequest();
    }
    form.resetForm();
    dispatch(resetRunXeroSyncState());
  }, [runXeroSyncState.status]);
  return {
    form,
    runXeroSyncState,
    handleSubmit,
  };
}
