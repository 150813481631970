import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputBase from '@mui/material/InputBase';
import FormControl from '@mui/material/FormControl';
import ErrorIcon from '@mui/icons-material/Error';
import { MenuItem, Select } from '@mui/material';

export default function QboSelect({
  id,
  label,
  required,
  name,
  disabled,
  errorMessage,
  placeholder,
  helperText,
  fullWidth,
  explicitStyleClass,
  withoutErrorIcon,
  isWidthSetExplicit,
  value,
  options,
  handleChange,
  defaultValue,
  ...rest
}) {
  const inputAttributes = {};
  let error = false;

  if (name) inputAttributes.name = name;
  if (placeholder) inputAttributes.placeholder = placeholder;
  if (errorMessage) error = true;

  return (
    <FormControl
      className={`FormControl__default-wrapper ${
        fullWidth ? 'FormControl__full-width-wrapper' : ''
      } ${isWidthSetExplicit ? 'FormControl__width-wrapper' : ''} ${explicitStyleClass}`}
      disabled={disabled}
      error={error}>
      {label && (
        <label className="QboTextField__label" htmlFor={`#${id}`}>
          {label}
          {required && <span style={{ color: 'red' }}> *</span>}
        </label>
      )}

      <Select
        value={value || defaultValue}
        select
        id={id}
        {...inputAttributes}
        {...rest}
        onChange={handleChange}>
        {options.map((item) => (
          <MenuItem key={item?.id} value={item?.id} style={{ height: '40px' }}>
            {item?.label}
          </MenuItem>
        ))}
      </Select>

      {helperText && !errorMessage && (
        <span className="QboTextField__helper-text">{helperText}</span>
      )}

      {errorMessage && (
        <span className="QboTextField__error-text">
          {!withoutErrorIcon && <ErrorIcon />}
          {` ${errorMessage}`}
        </span>
      )}
    </FormControl>
  );
}

QboSelect.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  explicitStyleClass: PropTypes.string,
  fullWidth: PropTypes.bool,
  isWidthSetExplicit: PropTypes.bool,
  withoutErrorIcon: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.string,
  options: PropTypes.array,
  handleChange: PropTypes.func,
  defaultValue: PropTypes.string,
};

QboSelect.defaultProps = {
  id: '',
  name: '',
  explicitStyleClass: '',
  label: null,
  disabled: false,
  errorMessage: null,
  placeholder: null,
  helperText: null,
  fullWidth: true,
  isWidthSetExplicit: false,
  withoutErrorIcon: false,
  required: false,
  value: '',
  options: [],
  handleChange: () => {},
  defaultValue: null,
};
