import { resellersListPath, organizationInformationPath } from '@config/Routes/WebClientRoutes';

export default function useDataService() {
  const buttonStyle = {
    height: 36,
    backgroundColor: '#a5a5a5',
    textDecoration: 'none',
    color: 'black',
    '&:hover': {
      backgroundColor: '#a5a5a5',
    },
    textTransform: 'unset',
    fontSize: 14,
  };

  const TFAStatusItemList = [
    {
      id: 1,
      value: 'Enabled',
      title: 'Enabled',
    },
    {
      id: 2,
      value: 'Disabled',
      title: 'Disabled',
    },
  ];

  const roleItemList = [
    {
      id: 1,
      value: 'admin',
      title: 'Admin',
    },
    {
      id: 2,
      value: 'dme_admin',
      title: 'DME Admin',
    },
    {
      id: 3,
      value: 'finance',
      title: 'Finance',
    },
    {
      id: 4,
      value: 'reseller_owner',
      title: 'Reseller Owner',
    },
    {
      id: 5,
      value: 'super_admin',
      title: 'Super Admin',
    },
    {
      id: 6,
      value: 'support',
      title: 'Support',
    },
  ];

  const SubscriptionRoleItemList = [
    {
      id: 1,
      value: '1',
      title: 'Owner',
    },
    {
      id: 2,
      value: '2',
      title: 'Full Admin',
    },
    {
      id: 3,
      value: '3',
      title: 'Group Admin',
    },
    {
      id: 4,
      value: '4',
      title: 'IT Admin',
    },
    {
      id: 5,
      value: '5',
      title: 'User',
    },
    {
      id: 6,
      value: '6',
      title: 'User Read Only',
    },
    {
      id: 7,
      value: '7',
      title: 'Compliance and Review Officer',
    },
    {
      id: 8,
      value: '8',
      title: 'Reviewer',
    },
    {
      id: 9,
      value: '9',
      title: 'Limited Reviewer',
    },
    {
      id: 10,
      value: '10',
      title: 'DPO',
    },
    {
      id: 11,
      value: '11',
      title: 'Restricted IT Admin',
    },
  ];

  const licenseTypeItemList = [
    {
      id: 1,
      value: 'Paid',
      title: 'Paid',
    },
    {
      id: 2,
      value: 'NFR',
      title: 'NFR',
    },
    {
      id: 3,
      value: 'NFP',
      title: 'NFP',
    },
  ];

  const columnNames = [
    {
      id: 1,
      value: 'ID',
      title: 'ID',
    },
    {
      id: 2,
      value: 'Name',
      title: 'Name',
    },
    {
      id: 3,
      value: 'Email',
      title: 'Email',
    },
    {
      id: 4,
      value: 'Role',
      title: 'Role',
    },
    {
      id: 5,
      value: 'CreatedOn',
      title: 'Created On',
    },
  ];

  const clickableCoulmnList = [
    { id: 1, column: 'partnerName', path: resellersListPath },
    { id: 2, column: 'organizationName', path: organizationInformationPath },
  ];

  const countryItemList = [
    'AD',
    'AE',
    'AF',
    'AI',
    'AL',
    'AO',
    'AR',
    'AS',
    'AT',
    'AU',
    'AW',
    'AX',
    'AZ',
    'BB',
    'BD',
    'BE',
    'BF',
    'BG',
    'BH',
    'BJ',
    'BM',
    'BN',
    'BO',
    'BR',
    'BS',
    'BT',
    'BW',
    'BY',
    'BZ',
    'CA',
    'CD',
    'CF',
    'CG',
    'CH',
    'CK',
    'CL',
    'CM',
    'CN',
    'CO',
    'CR',
    'CU',
    'CV',
    'CW',
    'CY',
    'CZ',
    'DE',
    'DJ',
    'DK',
    'DO',
    'DZ',
    'EC',
    'EE',
    'EG',
    'EH',
    'ER',
    'ES',
    'ET',
    'FI',
    'FJ',
    'FK',
    'FM',
    'FO',
    'FR',
    'GA',
    'GB',
    'GD',
    'GE',
    'GF',
    'GG',
    'GH',
    'GI',
    'GL',
    'GM',
    'GN',
    'GP',
    'GQ',
    'GR',
    'GT',
    'GU',
    'GW',
    'GY',
    'HK',
    'HN',
    'HR',
    'HT',
    'HU',
    'ID',
    'IE',
    'IL',
    'IM',
    'IN',
    'IO',
    'IQ',
    'IR',
    'IS',
    'IT',
    'JE',
    'JM',
    'JO',
    'JP',
    'KE',
    'KG',
    'KH',
    'KI',
    'KM',
    'KN',
    'KP',
    'KR',
    'KW',
    'KY',
    'KZ',
    'LA',
    'LB',
    'LC',
    'LI',
    'LK',
    'LR',
    'LS',
    'LT',
    'LU',
    'LV',
    'LY',
    'MA',
    'MC',
    'MD',
    'ME',
    'MF',
    'MG',
    'MH',
    'MK',
    'ML',
    'MM',
    'MN',
    'MO',
    'MP',
    'MQ',
    'MR',
    'MS',
    'MT',
    'MU',
    'MV',
    'MW',
    'MX',
    'MY',
    'MZ',
    'NA',
    'NC',
    'NE',
    'NF',
    'NG',
    'NI',
    'NL',
    'NO',
    'NP',
    'NR',
    'NU',
    'NZ',
    'OM',
    'PA',
    'PE',
    'PF',
    'PG',
    'PH',
    'PK',
    'PL',
    'PM',
    'PN',
    'PR',
    'PS',
    'PT',
    'PW',
    'PY',
    'QA',
    'RE',
    'RO',
    'RS',
    'RU',
    'RW',
    'SA',
    'SB',
    'SC',
    'SD',
    'SE',
    'SG',
    'SH',
    'SI',
    'SJ',
    'SK',
    'SL',
    'SM',
    'SN',
    'SO',
    'SR',
    'SS',
    'ST',
    'SV',
    'SX',
    'SY',
    'SZ',
    'TC',
    'TD',
    'TF',
    'TG',
    'TH',
    'TJ',
    'TK',
    'TL',
    'TM',
    'TN',
    'TO',
    'TR',
    'TT',
    'TV',
    'TW',
    'TZ',
    'UA',
    'UG',
    'US',
    'UY',
    'UZ',
    'VA',
    'VC',
    'VE',
    'VG',
    'VI',
    'VN',
    'VU',
    'WF',
    'WS',
    'YE',
    'YT',
    'ZA',
    'ZM',
    'ZW',
  ];

  const userListHeaders = [
    { id: 1, label: 'ID', columnName: 'userID', sx: { paddingLeft: 2 }, size: 100 },
    { id: 2, label: 'Name', columnName: 'name', sx: { paddingLeft: 2 } },
    { id: 3, label: 'Subscription Role', columnName: 'subscriptionRole', sx: { paddingLeft: 2 } },
    { id: 4, label: 'Email', columnName: 'email', sx: { paddingLeft: 2 } },
    { id: 5, label: 'Organization Name', columnName: 'organizationName', sx: { paddingLeft: 2 } },
    { id: 5, label: 'Partner Name', columnName: 'partnerName', sx: { paddingLeft: 2 } },
    { id: 6, label: 'Country', columnName: 'country', sx: { paddingLeft: 2 }, size: 100 },
    { id: 7, label: 'Time Zone', columnName: 'timeZone', sx: { paddingLeft: 2 } },
    {
      id: 8,
      label: 'Current Login Country',
      columnName: 'currentLoginCountry',
      sx: { paddingLeft: 2 },
    },
    { id: 9, label: 'External ID', columnName: 'externalID', sx: { paddingLeft: 2 } },
    { id: 10, label: 'Created On', columnName: 'createdOn', sx: { paddingLeft: 2 } },
    { id: 11, label: 'Last Updated At', columnName: 'lastUpdatedAt', sx: { paddingLeft: 2 } },
    { id: 12, label: 'Deleted On', columnName: 'deletedOn', sx: { paddingLeft: 2 } },
    { id: 13, label: 'Invitation Sent On', columnName: 'invitationSentOn', sx: { paddingLeft: 2 } },
    {
      id: 14,
      label: 'Login Disabled',
      columnName: 'loginDisabled',
      sx: { paddingLeft: 2 },
      size: 100,
    },
    {
      id: 15,
      label: 'Pre Login Disabled',
      columnName: 'preLoginDisabled',
      sx: { paddingLeft: 2 },
      size: 100,
    },
    {
      id: 16,
      label: 'Sign In Count',
      columnName: 'signInCount',
      sx: { paddingLeft: 2 },
      size: 100,
    },
    {
      id: 17,
      label: 'Failed Login Attempts',
      columnName: 'failedLoginAttempts',
      sx: { paddingLeft: 2 },
      size: 120,
    },
    {
      id: 18,
      label: 'Last Failed Login Attempt',
      columnName: 'lastFailedLoginAttempt',
      sx: { paddingLeft: 2 },
    },
    { id: 19, label: 'Last Login On', columnName: 'lastLoginOn', sx: { paddingLeft: 2 } },
    {
      id: 20,
      label: 'Last Login IP',
      columnName: 'lastLoginIP',
      sx: { paddingLeft: 2 },
    },
    {
      id: 21,
      label: 'Last Reset Password Sent On',
      columnName: 'lastResetPassworSentOn',
      sx: { paddingLeft: 2 },
    },
    { id: 22, label: '2FA Status', columnName: 'tfaStatus', sx: { paddingLeft: 2 }, size: 100 },
    { id: 23, label: '2FA Generated On', columnName: 'tfaGeneratedON', sx: { paddingLeft: 2 } },
    {
      id: 24,
      label: 'Failed 2FA Attempts',
      columnName: 'failed2FAAttempts',
      sx: { paddingLeft: 2 },
      size: 100,
    },
    { id: 25, label: 'Last Failed 2FA On', columnName: 'lastFailed2FAOn', sx: { paddingLeft: 2 } },
    { id: 26, label: 'Reset 2FA Sent On', columnName: 'reset2FASentOn', sx: { paddingLeft: 2 } },
    { id: 27, label: 'Azure AD SSO', columnName: 'sso', sx: { paddingLeft: 2 }, size: 100 },
    { id: 28, label: 'GWS SSO', columnName: 'gwsSSO', sx: { paddingLeft: 2 }, size: 100 },
    {
      id: 29,
      label: 'SSO Link Requested By',
      columnName: 'ssoLinkRequestedBy',
      sx: { paddingLeft: 2 },
    },
    {
      id: 30,
      label: 'Backup Notification',
      columnName: 'backupNotification',
      sx: { paddingLeft: 2 },
      size: 100,
    },
    {
      id: 31,
      label: 'Capacity Notification',
      columnName: 'capacityNotification',
      sx: { paddingLeft: 2 },
      size: 100,
    },
    {
      id: 32,
      label: 'Email Account Failure Notification',
      columnName: 'emailAccountFailureNotification',
      sx: { paddingLeft: 2 },
    },
    {
      id: 33,
      label: 'Email Account Success Notification',
      columnName: 'emailAccountSuccessNotification',
      sx: { paddingLeft: 2 },
    },
    {
      id: 34,
      label: 'Failure Notification',
      columnName: 'failureNotification',
      sx: { paddingLeft: 2 },
      size: 100,
    },
    {
      id: 35,
      label: 'Features Notification',
      columnName: 'featuresNotification',
      sx: { paddingLeft: 2 },
      size: 100,
    },
  ];

  return {
    buttonStyle,
    TFAStatusItemList,
    roleItemList,
    licenseTypeItemList,
    countryItemList,
    columnNames,
    clickableCoulmnList,
    SubscriptionRoleItemList,
    userListHeaders,
  };
}
