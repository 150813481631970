import { constant } from '@config/BaseSetting';
import { getM365TenantDetailsInfoAsync } from '@features/m365TenantDetailsInfo/m365TenantDetailsInfoSlice';
import useLocalStorage from '@hooks/useLocalStorage';
import { useDispatch } from 'react-redux';

export default function useM365TenantDetailInformationService() {
  const [accessToken] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);

  const dispatch = useDispatch();

  const handleFetchM365TenantDetailInformationAsync = (m365TenantCredentialID) => {
    dispatch(
      getM365TenantDetailsInfoAsync({
        accessToken,
        reset: true,
        m365TenantID: m365TenantCredentialID,
      })
    );
  };

  const getM365TenantDetailsDataFromResponse = (data, setM365TenantDetails) => {
    const dataObject = {
      0: 'Not Ready',
      1: 'In Progress',
      7: 'Completed',
    };

    const maskedReportObject = {
      '-1': 'Not Masked',
    };

    const modifiedData = data?.map((item) => {
      const modifiedPayloadObj = {
        ...item,
        encryptedAzureAppSecret: item.encryptedAzureAppSecret
          ? `${item.encryptedAzureAppSecret.slice(0, 6)}${item.encryptedAzureAppSecret && ' ...'}`
          : '',
        refreshToken: item.refreshToken ? 'Yes' : 'No',
        delegatedRefreshToken: item.delegatedRefreshToken ? 'Yes' : 'No',
        encryptedAppPassword: item.encryptedAppPassword ? 'Yes' : 'No',
        deviceRefreshToken: item.deviceRefreshToken ? 'Yes' : 'No',
        permissionStatus: dataObject[item.permissionStatus] || 'Error',
        exoAuthStatus: dataObject[item.exoAuthStatus] || 'Error',
        encryptedServiceAppSecret: item.encryptedServiceAppSecret
          ? item.encryptedServiceAppSecret.slice(0, 6)
          : '',
        maskedReport: maskedReportObject[item.maskedReport] || 'Masked',
      };
      return modifiedPayloadObj;
    });
    return setM365TenantDetails(modifiedData);
  };

  return { handleFetchM365TenantDetailInformationAsync, getM365TenantDetailsDataFromResponse };
}
