import React, { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { QboTypography, QboButton } from '@ui/Components';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    // top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 30,
  height: 30,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className, listIcon } = props;

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {listIcon[String(props?.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
  listIcon: PropTypes.arrayOf(PropTypes.node),
};

ColorlibStepIcon.defaultProps = {
  active: false,
  className: '',
  completed: false,
  icon: null,
  listIcon: [null],
};

export default function ItStepper({
  className,
  children,
  steps,
  icons,
  optionalStep,
  showButton,
  hideLabel,
  onNext,
  onBack,
  onSkip,
  OnFinish,
  ...rest
}) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => {
    return optionalStep.includes(step);
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const arrayChildren = Children.toArray(children);

  return (
    <Box sx={{ width: '100%' }}>
      {/* <Stepper className="ItStepper__wrapper" activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = <QboTypography variant="caption">Optional</QboTypography>;
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper> */}
      <Stepper
        className="ItStepper__wrapper"
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = <QboTypography variant="caption">(Optional)</QboTypography>;
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel
                StepIconComponent={(props) => ColorlibStepIcon({ ...props, listIcon: icons })}
                {...labelProps}>
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Box className="ItStepper__content">
        {activeStep === steps.length ? (
          cloneElement(arrayChildren[steps.length])
        ) : (
          <Box>
            {children && arrayChildren.length > 0
              ? Children.map(arrayChildren, (child, index) => {
                  if (activeStep === index && steps?.length > 0) {
                    return (
                      <>
                        {cloneElement(child, {
                          index,
                        })}
                      </>
                    );
                  }
                  return null;
                })
              : null}
          </Box>
        )}
      </Box>
      <Box className="ItStepper__footer">
        <QboButton color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
          Back
        </QboButton>
        <Box sx={{ flex: '1 1 auto' }} />
        {isStepOptional(activeStep) && (
          <QboButton color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
            Skip
          </QboButton>
        )}
        {activeStep === steps.length ? (
          <QboButton onClick={handleReset}>Reset</QboButton>
        ) : (
          <QboButton onClick={handleNext}>
            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
          </QboButton>
        )}
      </Box>
    </Box>
  );
}

ItStepper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
  steps: PropTypes.arrayOf(PropTypes.string),
  icons: PropTypes.objectOf(PropTypes.node),
  optionalStep: PropTypes.arrayOf(PropTypes.number),
  showButton: PropTypes.bool,
  hideLabel: PropTypes.bool,
  onNext: PropTypes.func,
  onBack: PropTypes.func,
  onSkip: PropTypes.func,
  OnFinish: PropTypes.func,
};

ItStepper.defaultProps = {
  children: '',
  className: '',
  steps: [],
  icons: {},
  optionalStep: [],
  showButton: false,
  hideLabel: false,
  onNext: null,
  onBack: null,
  onSkip: null,
  OnFinish: null,
};
