import React from 'react';
import PropTypes from 'prop-types';
import BaseSetting from '@config/BaseSetting';
import { useTranslation } from 'react-i18next';
import { QboDropdown, QboButton } from '@ui/Components';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';

const options = BaseSetting.paginationPerPageNumbers.map((o) => {
  return [o, <span>{o}</span>];
});

export default function QboPagination({
  openRowsPerPage,
  pageNumber,
  hasNextLink,
  hasPrevLink,
  onClickNextLink,
  onClickPrevLink,
  onChangePageNumber,
  paginationLabel,
}) {
  const { t } = useTranslation();
  return (
    <div className="QboPagination__wrapper">
      {openRowsPerPage && (
        <>
          {paginationLabel || t('pagination.rows_per_page_label')}
          <QboDropdown
            menuItems={options}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            onClickItem={(selected) => {
              onChangePageNumber(selected);
            }}
            dropdownClassName="QboPagination__dropdown-menu">
            {pageNumber}
          </QboDropdown>
        </>
      )}
      <div className="QboPagination__control">
        <QboButton disabled={!hasPrevLink} onClick={onClickPrevLink}>
          <KeyboardArrowLeftOutlinedIcon />
        </QboButton>
        <QboButton disabled={!hasNextLink} onClick={onClickNextLink}>
          <KeyboardArrowRightOutlinedIcon />
        </QboButton>
      </div>
    </div>
  );
}

QboPagination.propTypes = {
  openRowsPerPage: PropTypes.bool,
  hasNextLink: PropTypes.string,
  hasPrevLink: PropTypes.string,
  pageNumber: PropTypes.number,
  onClickNextLink: PropTypes.func,
  onClickPrevLink: PropTypes.func,
  onChangePageNumber: PropTypes.func,
  paginationLabel: PropTypes.string,
};

QboPagination.defaultProps = {
  openRowsPerPage: true,
  hasNextLink: null,
  hasPrevLink: null,
  pageNumber: 10,
  onClickNextLink: () => {},
  onClickPrevLink: () => {},
  onChangePageNumber: () => {},
  paginationLabel: '',
};
