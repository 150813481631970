import { useDispatch } from 'react-redux';
import { constant } from '@config/BaseSetting';
import useLocalStorage from '@hooks/useLocalStorage';
import { fetchMailboxChangeStatus } from '@features/mailBox/mailboxChangeStatusSlice';
import { fetchMailboxChangeDetailAsync } from '@features/mailBox/serviceActions/fetchMailboxChangeDetailAsync';

export default function useMailboxChangeDetail() {
  const dispatch = useDispatch();
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);

  const getMailboxChangeDetailAsync = (pageSize, pageIndex, id) => {
    dispatch(
      fetchMailboxChangeDetailAsync({
        accessToken: accessTokenSet,
        reset: true,
        id,
        pageSize,
        pageNumber: pageIndex,
      })
    );
  };

  const getMailboxChangeStatusAsync = (id) => {
    dispatch(fetchMailboxChangeStatus({ accessToken: accessTokenSet, reset: true, id }));
  };

  return { getMailboxChangeDetailAsync, getMailboxChangeStatusAsync };
}
