import { createSlice } from '@reduxjs/toolkit';

import {
  getIngestionsInProgressAsync,
  getIngestionsInProgressExtraReducers,
} from './serviceActions/fetchIngestionsInProgressAsync';

const initialState = {
  getIngestionInProgressState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    paginationList: null,
    ingestionList: [],
    forbiddenState: false,
    forbiddenErrorMessage: null,
    ingestionCorsErrorMessage: null,
  },
};

const getIngestionInProgressSlice = createSlice({
  name: 'IngestionList',
  initialState,
  reducers: {
    resetIngestionSubmit(state) {
      state.getIngestionInProgressState = initialState.getIngestionInProgressState;
    },
  },
  extraReducers: {
    ...getIngestionsInProgressExtraReducers,
  },
});

export { getIngestionsInProgressAsync };
export const { resetIngestionInProgressSubmit } = getIngestionInProgressSlice.actions;
export default getIngestionInProgressSlice.reducer;
