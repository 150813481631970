import { useDispatch } from 'react-redux';
import { fetchMailboxBulkPurgeAsync } from '@features/mailboxBulkPurge/serviceActions/fetchMailboxBulkPurgeAsync';
import { constant } from '@config/BaseSetting';
import useLocalStorage from '@hooks/useLocalStorage';

export default function useBulkPurgeList() {
  const dispatch = useDispatch();
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);

  const fetchBulkPurgeListAsync = (pageSize, pageIndex) => {
    dispatch(
      fetchMailboxBulkPurgeAsync({
        accessToken: accessTokenSet,
        reset: true,
        pageSize,
        pageNumber: pageIndex,
      })
    );
  };

  const bulkPurgeDataCovert = async (data, setBulkPurgeList) => {
    const modifiedData = await data?.map((item) => {
      const payloadObj = JSON.parse(item.payload);
      const modifiedPayloadObj = {
        id: item?.id,
        'org-owner-id': payloadObj['org-owner-id'],
        'support-ticket': payloadObj['support-ticket'],
        'escalation-ticket': payloadObj['escalation-ticket'],
        status: item?.status,
        'status-description': item['status-description'],
        'executed-on': item['created-on'],
        'in-progress': item['in-progress-count'],
        success: item['success-count'],
        error: item['error-count'],
      };
      return modifiedPayloadObj;
    });

    setBulkPurgeList(modifiedData);

    return modifiedData;
  };

  return { fetchBulkPurgeListAsync, bulkPurgeDataCovert };
}
