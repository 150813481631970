import { tenantSizeReportAPI } from '@services/WebApiService';
import { createAsyncThunk } from '@reduxjs/toolkit';

const tenantSizeReportAsync = createAsyncThunk(
  'TenantSizeReport/tenantSizeReport',
  async (paramsList) => {
    try {
      const data = await tenantSizeReportAPI(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = tenantSizeReportAsync;

const tenantSizeReportExtraReducers = {
  [pending]: (state) => {
    state.tenantSizeReportState.fetching = true;
  },
  [rejected]: (state) => {
    state.tenantSizeReportState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.tenantSizeReportState.fetching = false;

    if (action?.payload && !action?.payload?.error) {
      state.tenantSizeReportState.status = action?.payload?.status;
      state.tenantSizeReportState.message = action?.payload?.data?.message;
    }
    if (action?.payload?.status === 403) {
      state.tenantSizeReportState.message = 'You are not authorize to access this resource';
    }

    if (!action?.payload) {
      state.tenantSizeReportState.message = 'Something went wrong while processing your request.';
    }

    if (action?.payload?.error) {
      const statusMessage = action?.payload?.error?.data;
      state.tenantSizeReportState.status = action?.payload?.error?.status;
      state.tenantSizeReportState.message = statusMessage?.title || statusMessage?.message;
      state.tenantSizeReportState.error = action.payload.error;
    } else {
      state.tenantSizeReportState.success = true;
      state.tenantSizeReportState.serviceType = action.payload.data.data.serviceTypeCode;
    }
  },
};

export { tenantSizeReportAsync, tenantSizeReportExtraReducers };
