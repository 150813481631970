import { useState } from 'react';
import * as yup from 'yup';

const descriptionName = 'description';
const supportTicketName = 'supportTicket';
const escalationTicketName = 'escalationTicket';
const csvValid = 'csvValid';

const InitialForm = {
  [descriptionName]: '',
  [supportTicketName]: '',
  [escalationTicketName]: '',
  [csvValid]: false,
};

export default function useBulkCreateOrganizationFormValidation() {
  const [isValid, setIsValid] = useState(false);
  const [errors, setErrors] = useState('');
  const [formData, setFormData] = useState(InitialForm);

  const formSchema = yup.object().shape({
    [csvValid]: yup.boolean().isTrue('Please upload a valid CSV file'),
    [supportTicketName]: yup.string().required('Support Ticket is required field'),
  });

  const validate = async (value) => {
    await formSchema.validate(value, { abortEarly: false }).catch((err) => {
      const errorLogs = err.inner.reduce((acc, error) => {
        return {
          ...acc,
          [error.path]: error.message,
        };
      }, {});
      setErrors(errorLogs);
      return errorLogs;
    });
    const validation = await formSchema.isValid(value);
    if (validation) {
      setErrors({});
    }
    setIsValid(validation);
    return validation;
  };

  const validateAll = () => {
    validate(formData);
  };

  const updateFormData = (newData) => {
    setFormData((prev) => {
      return { ...prev, ...newData };
    });
  };

  const supportTicketAttributes = {
    handleChange: async (value) => {
      updateFormData({ [supportTicketName]: value });
    },
    errorMessage: errors[supportTicketName],
    value: formData[supportTicketName],
  };

  const descriptionAttributes = {
    handleChange: async (value) => {
      updateFormData({ [descriptionName]: value });
    },
    value: formData[descriptionName],
  };

  const escalationTicketAttributes = {
    handleChange: async (value) => {
      updateFormData({ [escalationTicketName]: value });
    },
    value: formData[escalationTicketName],
  };

  const csvValidAttributes = {
    handleChange: async (value) => {
      updateFormData({ [csvValid]: value });
    },
    errorMessage: errors[csvValid],
    value: formData[csvValid],
  };

  const resetForm = () => {
    setFormData(InitialForm);
    setErrors({});
    setIsValid(false);
  };

  return {
    formData,
    supportTicketAttributes,
    escalationTicketAttributes,
    descriptionAttributes,
    csvValidAttributes,
    validateAll,
    isValid,
    validate,
    resetForm,
  };
}
