import { createAsyncThunk } from '@reduxjs/toolkit';
import { getGwsUsersTenantAPI } from '@services/WebApiService';

const getGwsUsersTenantAsync = createAsyncThunk(
  'gwsUsersTenant/getGwsUsersTenant',
  async (paramsList) => {
    try {
      const data = await getGwsUsersTenantAPI(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = getGwsUsersTenantAsync;

const getGwsUsersTenantReducers = {
  [pending]: (state) => {
    state.getGwsUsersTenantState.fetching = true;
  },
  [rejected]: (state) => {
    state.getGwsUsersTenantState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.getGwsUsersTenantState.fetching = false;

    if (action?.payload?.status === 403) {
      state.getGwsUsersTenantState.forbiddenState = true;
      state.getGwsUsersTenantState.forbiddenErrorMessage = {
        heading: 'Forbidden Error',
        caption: 'You are not authorize to access this resource',
      };
    }

    if (!action?.payload) {
      state.getGwsUsersTenantState.gwsUsersTenantCorsErrorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.data?.data) {
      state.getGwsUsersTenantState.gwsUsersTenantInformation = action?.payload?.data?.data;
      if (action?.payload?.data?.pagination) {
        state.getGwsUsersTenantState.paginationList = JSON.parse(action?.payload?.data?.pagination);
      }
      state.getGwsUsersTenantState.success = true;
      state.getGwsUsersTenantState.statusCode = action?.payload?.data['status-code'];
    }

    if (action?.payload?.error) {
      state.getGwsUsersTenantState.error = action.payload.error;
    }
  },
};

export { getGwsUsersTenantAsync, getGwsUsersTenantReducers };
