import { createSlice } from '@reduxjs/toolkit';

import {
  purgeGWSTenantAsync,
  purgeGWSTenantExtraReducers,
} from './serviceActions/purgeGWSTenantAsync';

const initialState = {
  purgeGWSTenantState: {
    fetching: false,
    status: null,
    error: null,
    message: null,
    errorMessage: null,
    forbiddenState: false,
    forbiddenErrorMessage: null,
    purgeGWSTenantCorsErrorMessage: null,
  },
};

const recreatePurgeGWSTenantSlice = createSlice({
  name: 'purgeGWSTenant',
  initialState,
  reducers: {
    resetPurgeGWSTenantState(state) {
      state.purgeGWSTenantState = initialState.purgeGWSTenantState;
    },
  },
  extraReducers: {
    ...purgeGWSTenantExtraReducers,
  },
});

export { purgeGWSTenantAsync };

export const { resetPurgeGWSTenantState } = recreatePurgeGWSTenantSlice.actions;
export default recreatePurgeGWSTenantSlice.reducer;
