import { createSlice } from '@reduxjs/toolkit';

import {
  getPartnersListAsync,
  getPartnersListExtraReducers,
} from './serviceActions/getPartnersListAsync';

const initialState = {
  getPartnersListState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    paginationList: null,
    partnersList: [],
    forbiddenState: false,
    forbiddenErrorMessage: null,
    partnersListCorsErrorMessage: null,
  },
};

const partnersListSlice = createSlice({
  name: 'PartnersList',
  initialState,
  reducers: {
    resetPartnersListState(state) {
      state.getPartnersListState = initialState.getPartnersListState;
    },
    resetPartnersListStatusCodeState(state) {
      state.getPartnersListState.statusCode = initialState.getPartnersListState.statusCode;
    },
  },
  extraReducers: {
    ...getPartnersListExtraReducers,
  },
});

export { getPartnersListAsync };
export const { resetPartnersListState, resetPartnersListStatusCodeState } =
  partnersListSlice.actions;
export default partnersListSlice.reducer;
