import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';

import { ITBox, QboAlert, QboFormGroup, QboTextField } from '@ui/Components';
import { resetCreateTransferSubscriptionInitiateState } from '@features/transferSubscriptionInitiatePageService/createTransferSubscriptionInitiateSlice';

export default function TransferSubscriptionInitiateForm({
  supportTicketAttributes,
  descriptionAttributes,
  escalationTicketAttributes,
  targetPartnerIDAttributes,
  organizationOwnerIDAttributes,
  onSubmit,
  formData,
}) {
  const { t } = useTranslation();
  const { transferSubscriptionInitiateState } = useSelector((state) => state.initiateRequestState);
  const { status, message } = transferSubscriptionInitiateState;
  const isAstericRequired = true;
  const dispatch = useDispatch();
  const previousPropsRef = useRef(formData);

  useEffect(() => {
    if (!isEqual(previousPropsRef.current, formData) && status) {
      dispatch(resetCreateTransferSubscriptionInitiateState());
    }
  }, [formData]);

  return (
    <ITBox className="TransferSubscriptionFormPage__wrapper" direction="column">
      <QboFormGroup onSubmit={onSubmit} withSubmitButton>
        {(status || message) && (
          <QboAlert type={status === 200 ? 'success' : 'error'} style={{ fontSize: '1.15em' }}>
            {status === 200 ? 'Submitted Successfully' : message}
          </QboAlert>
        )}
        <QboTextField
          id="supportTicket"
          required={isAstericRequired}
          placeholder={t('initiate_page.labels.support_ticket')}
          label={t('initiate_page.labels.support_ticket')}
          fullWidth
          value={supportTicketAttributes.value}
          errorMessage={supportTicketAttributes.errorMessage}
          inputProps={{ maxLength: 225 }}
          onChange={(e) => {
            supportTicketAttributes.handleChange(e.currentTarget.value);
          }}
        />
        <QboTextField
          id="escalationTicket"
          placeholder={t('initiate_page.labels.escalation_ticket')}
          label={t('initiate_page.labels.escalation_ticket')}
          fullWidth
          value={escalationTicketAttributes.value}
          errorMessage={escalationTicketAttributes.errorMessage}
          onChange={(e) => escalationTicketAttributes.handleChange(e.currentTarget.value)}
          inputProps={{ maxLength: 225 }}
        />
        <QboTextField
          id="description"
          placeholder={t('initiate_page.labels.description')}
          label={t('initiate_page.labels.description')}
          fullWidth
          value={descriptionAttributes.value}
          onChange={(e) => descriptionAttributes.handleChange(e.currentTarget.value)}
          errorMessage={descriptionAttributes.errorMessage}
          inputProps={{ maxLength: 225 }}
        />
        <QboTextField
          id="Organization Owner ID"
          required={isAstericRequired}
          placeholder={t('initiate_page.labels.organization_owner_id')}
          label={t('initiate_page.labels.organization_owner_id')}
          fullWidth
          inputProps={{ maxLength: 225 }}
          value={organizationOwnerIDAttributes.value}
          onChange={(e) => {
            organizationOwnerIDAttributes.handleChange(e.currentTarget.value);
          }}
          errorMessage={organizationOwnerIDAttributes.errorMessage}
        />
        <QboTextField
          id="Target Partner ID"
          required={isAstericRequired}
          placeholder={t('initiate_page.labels.target_reseller_id')}
          label={t('initiate_page.labels.target_reseller_id')}
          fullWidth
          inputProps={{ maxLength: 225 }}
          value={targetPartnerIDAttributes.value}
          onChange={(e) => {
            targetPartnerIDAttributes.handleChange(e.currentTarget.value);
          }}
          errorMessage={targetPartnerIDAttributes.errorMessage}
        />
      </QboFormGroup>
    </ITBox>
  );
}

TransferSubscriptionInitiateForm.propTypes = {
  supportTicketAttributes: PropTypes.object,
  descriptionAttributes: PropTypes.object,
  escalationTicketAttributes: PropTypes.object,
  targetPartnerIDAttributes: PropTypes.object,
  organizationOwnerIDAttributes: PropTypes.object,
  formData: PropTypes.object,
  onSubmit: PropTypes.func,
};

TransferSubscriptionInitiateForm.defaultProps = {
  supportTicketAttributes: {},
  descriptionAttributes: {},
  escalationTicketAttributes: {},
  targetPartnerIDAttributes: {},
  organizationOwnerIDAttributes: {},
  formData: {},
  onSubmit: () => {},
};
