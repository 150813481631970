import { createAsyncThunk } from '@reduxjs/toolkit';
import { getOrganizationInformationAPI } from '@services/WebApiService';

const getOrganizationInformationAsync = createAsyncThunk(
  'organizationInformation/getOrganizationInformation',
  async (paramsList) => {
    try {
      const data = await getOrganizationInformationAPI(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = getOrganizationInformationAsync;

const getOrganizationInformationReducers = {
  [pending]: (state) => {
    state.getOrganizationInformationState.fetching = true;
  },
  [rejected]: (state) => {
    state.getOrganizationInformationState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.getOrganizationInformationState.fetching = false;

    if (action?.payload?.status === 403) {
      state.getOrganizationInformationState.forbiddenState = true;
      state.getOrganizationInformationState.forbiddenErrorMessage = {
        heading: 'Forbidden Error',
        caption: 'You are not authorize to access this resource',
      };
    }

    if (!action?.payload) {
      state.getOrganizationInformationState.organizationInformationCorsErrorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.data?.data) {
      state.getOrganizationInformationState.organizationInformation = action?.payload?.data?.data;
      // state.getOrganizationInformationState.paginationList = JSON.parse(
      //   action?.payload?.data?.pagination
      // );
      state.getOrganizationInformationState.success = true;
      state.getOrganizationInformationState.statusCode = action?.payload?.data['status-code'];
    }

    if (action?.payload?.error) {
      state.getOrganizationInformationState.error = action.payload.error;
    }
  },
};

export { getOrganizationInformationAsync, getOrganizationInformationReducers };
