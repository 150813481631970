import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useLocalStorage from '@hooks/useLocalStorage';
import { constant } from '@config/BaseSetting';
import { getOrganizationFilterListAsync } from '@features/organizationList/getOrganizationFilterListSlice';
import { setOrganizationFilterState } from '@features/application/applicationSlice';

export default function useOrganizationFilterHook(partnerId, externalValue) {
  const dispatch = useDispatch();
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const { getOrganizationFilterListState } = useSelector((state) => state.organizationFilterList);
  const { organizationFilterList } = getOrganizationFilterListState;
  const { organizationFilterState } = useSelector((stateList) => stateList.application);

  const [organizationList, setOrganizationList] = useState([]);

  const handleOrganizationFilterSearch = (search, partner) => {
    dispatch(
      getOrganizationFilterListAsync({
        accessToken: accessTokenSet,
        reset: true,
        ...{ searchTerm: search, partnerId: partner },
      })
    );
  };

  const responseConverter = (data) => {
    return data.map((item) => ({
      id: item?.id,
      value: item?.id,
      title: `${item?.id} - ${item.organizationName}`,
    }));
  };

  const resetOrgFilter = () => {
    dispatch(
      setOrganizationFilterState({
        forceUpdate: !organizationFilterState.forceUpdate,
        searchState: '',
        selectedOrganization: '',
      })
    );
    handleOrganizationFilterSearch('', partnerId);
  };

  const UpdateFilters = (value, properties) => {
    const newValue = value;
    const newState = { ...organizationFilterState };
    properties.forEach((property) => {
      newState[property] = newValue;
    });
    dispatch(setOrganizationFilterState(newState));
  };

  useEffect(() => {
    if (
      organizationFilterState.searchState.length === 0 ||
      organizationFilterState.searchState.length > 2
    )
      handleOrganizationFilterSearch(organizationFilterState.searchState, partnerId);
  }, [organizationFilterState.searchState]);

  useEffect(() => {
    UpdateFilters('', ['selectedOrganization', 'searchState']);
    handleOrganizationFilterSearch(organizationFilterState.searchState, partnerId);
  }, [partnerId]);

  useEffect(() => {
    setOrganizationList(responseConverter(organizationFilterList));
  }, [organizationFilterList]);

  useEffect(() => {
    if (externalValue === organizationFilterState.selectedOrganization) return;
    UpdateFilters(externalValue || '', ['selectedOrganization']);
  }, [externalValue]);

  return {
    organizationFilterState,
    resetOrgFilter,
    UpdateFilters,
    organizationList,
  };
}
