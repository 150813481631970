import { createSlice } from '@reduxjs/toolkit';

import {
  getGwsUsersTenantAsync,
  getGwsUsersTenantReducers,
} from './serviceActions/getGwsUsersTenantAsync';

const initialState = {
  getGwsUsersTenantState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    paginationList: null,
    gwsUsersTenantInformation: [],
    forbiddenState: false,
    forbiddenErrorMessage: null,
    gwsUsersTenantCorsErrorMessage: null,
  },
};

const gwsUsersTenantSlice = createSlice({
  name: 'GwsUsersTenant',
  initialState,
  reducers: {
    resetGwsUsersTenantState(state) {
      state.getGwsUsersTenantState = initialState.getGwsUsersTenantState;
    },
    resetGwsUsersTenantStatusCodeState(state) {
      state.getGwsUsersTenantState.statusCode = initialState.getGwsUsersTenantState.statusCode;
    },
  },
  extraReducers: {
    ...getGwsUsersTenantReducers,
  },
});

export { getGwsUsersTenantAsync };
export const { resetGwsUsersTenantState, resetGwsUsersTenantStatusCodeState } =
  gwsUsersTenantSlice.actions;
export default gwsUsersTenantSlice.reducer;
