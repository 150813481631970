import { createSlice } from '@reduxjs/toolkit';

import {
  getChangeBackupAdminsAsync,
  getChangeBackupAdminsExtraReducers,
} from './serviceActions/fetchChangeBackupAdminsAsync';

const initialState = {
  getChangeBackupAdminsState: {
    fetching: false,
    status: null,
    list: [],
    errorMessage: null,
    paginations: {},
    forbiddenState: false,
    forbiddenErrorMessage: null,
    changeBackupAdminsCorsErrorMessage: null,
  },
};

const changeBackupAdminsSlice = createSlice({
  name: 'changeBackupAdmins',
  initialState,
  reducers: {
    resetChangeBackupAdminsState(state) {
      state.getChangeBackupAdminsState = initialState.getChangeBackupAdminsState;
    },
  },
  extraReducers: {
    ...getChangeBackupAdminsExtraReducers,
  },
});

export { getChangeBackupAdminsAsync };

export const { resetChangeBackupAdminsState } = changeBackupAdminsSlice.actions;
export default changeBackupAdminsSlice.reducer;
