import { constant } from '@config/BaseSetting';
import { getGWSTenantAsync, resetGWSTenantState } from '@features/gwsTenant/gwsTenantSlice';
import useLocalStorage from '@hooks/useLocalStorage';
import { useDispatch } from 'react-redux';

export default function useGWSTenantService(
  filterDataState,
  setFilterDataState,
  filterStates,
  organizationOwnerId,
  pagination,
  adFilter,
  setAdFilter,
  setPagination,
  setGWSListData,
  setOrganizationErrorMessageTextFiled,
  setErrorMessagePartnerTextFiled
) {
  const [accessToken] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const dispatch = useDispatch();
  const emptyFilterState = [
    { field: 'filter_app_auth_status', value: '' },
    { field: 'filter_completed_autodiscover_setup', value: '' },
    { field: 'filter_completed_setup', value: '' },
  ];

  const handleGWSTenantAsync = (flag, resetFlag = false) => {
    dispatch(
      getGWSTenantAsync({
        accessToken,
        reset: true,
        fullTextSearch: resetFlag
          ? filterStates.searchFieldValueText
          : filterDataState.searchFieldValueText,
        pageNumber: flag ? 1 : pagination.pageIndex + 1,
        pageSize: flag ? 10 : pagination.pageSize,
        searchById: resetFlag ? 0 : filterDataState.searchFieldValueID,
        organizationOwnerId: resetFlag ? organizationOwnerId : filterDataState.searchOrgId,
        partnerID: resetFlag ? 0 : filterDataState.searchPartnerId,
        orderBy: filterDataState.selectedSortColumn,
        filter: resetFlag ? emptyFilterState : adFilter,
      })
    );
  };
  const handleStateUpdate = (key, value) => {
    setFilterDataState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const handleUpdateFilters = (label, val) => {
    const newList = adFilter.map((ele) => {
      if (ele.field === label) {
        return { ...ele, value: val };
      }
      return ele;
    });
    setAdFilter([...newList]);
  };

  const handleOnTextFiledChange = (e) => {
    const input = e.target.value;
    setFilterDataState((prevState) => ({
      ...prevState,
      textFieldValue: input,
    }));
    if (/^\d+$/.test(input)) {
      setFilterDataState((prevState) => ({
        ...prevState,
        searchFieldValueText: input,
        searchFieldValueID: input,
      }));
    } else {
      setFilterDataState((prevState) => ({
        ...prevState,
        searchFieldValueID: '',
        searchFieldValueText: input,
      }));
    }
  };

  const handleOnResetButtonClick = () => {
    setFilterDataState((prevState) => ({
      ...prevState,
      textFieldValue: filterStates.textFieldValue,
      searchFieldValueText: filterStates.searchFieldValueText,
      searchFieldValueID: filterStates.searchFieldValueID,
      searchOrgId: filterStates.searchOrgId,
      searchOrgIdText: filterStates.searchOrgIdText,
      searchPartnerId: filterStates.searchPartnerId,
      searchPartnerIdText: filterStates.searchPartnerIdText,
      selectedSortColumn: filterStates.selectedSortColumn,
      matchText: filterStates.matchText,
      resetClicked: filterStates.resetClicked,
    }));
    setGWSListData([]);
    setAdFilter(emptyFilterState);
    setPagination({ pageIndex: 0, pageSize: 10 });
    dispatch(resetGWSTenantState());
    handleGWSTenantAsync(true, true);
  };

  const handleOnHideAdvanceClick = () => {
    setFilterDataState((prevState) => ({
      ...prevState,
      selectedSortColumn: filterStates.selectedSortColumn,
    }));
    setAdFilter(emptyFilterState);
  };
  const matchTextHandler = () => {
    const { searchFieldValueID, searchFieldValueText, searchOrgId, searchPartnerId } =
      filterDataState;

    let textData = 'Matching ';

    if (searchFieldValueText || searchFieldValueID) {
      if (textData !== 'Matching ') {
        textData += ', ';
      }
      textData += ` Credential ID/Domain/Tenant ID = '${searchFieldValueText}' `;
    }
    if (searchOrgId) {
      if (textData !== 'Matching ') {
        textData += ', ';
      }
      textData += ` Organization Owner ID = '${searchOrgId}' `;
    }

    if (searchPartnerId) {
      if (textData !== 'Matching ') {
        textData += ', ';
      }
      textData += ` Partner ID = '${searchPartnerId}', `;
    }
    adFilter.forEach((filter) => {
      if (filter.value) {
        if (filter.field === 'filter_app_auth_status') {
          textData += 'App Auth Status  = ';
        } else if (filter.field === 'filter_completed_autodiscover_setup') {
          textData += 'Autodiscover Setup Completed = ';
        } else if (filter.field === 'filter_completed_setup') {
          textData += 'Credential Setup Completed = ';
        }
        textData += filter.value;
        textData += ', ';
      }
    });

    if (textData === 'Matching ') {
      textData = '';
    }
    textData = textData.replace(/,\s*$/, '');

    setFilterDataState((prevState) => ({
      ...prevState,
      matchText: textData,
    }));
  };
  const getGWSDataFromResponse = (data) => {
    const dataObject = {
      0: 'Not Ready',
      7: 'Completed',
      11: 'Error',
    };
    const completedAutodiscoverSetupObject = {
      True: 'Yes',
      False: 'No',
    };

    const modifiedData = data?.map((item) => {
      const responseArray = item?.response !== '' ? JSON.parse(item?.response) : [];

      const responseFilterCallback = (status) => {
        if (responseArray?.length > 0) {
          const userObject = responseArray.filter((user) => user?.active === status);

          return userObject?.length;
        }

        return 0;
      };

      const modifiedPayloadObj = {
        ...item,
        availableUsersNumber: responseFilterCallback('available'),
        excludedUsersNumber: responseFilterCallback('blacklist'),
        addedUsersNumber: responseFilterCallback('active'),
        notAvailableUsersNumber: responseFilterCallback('not_available'),
        allUserNumbers: responseArray?.length || 0,
        completedAutodiscoverSetup:
          completedAutodiscoverSetupObject[item.completedAutodiscoverSetup],
        appAuthStatus: dataObject[item.appAuthStatus],
        tenant: item.tenant || '-',
      };
      return modifiedPayloadObj;
    });
    return setGWSListData(modifiedData);
  };

  const handleOnOwnerIDTextFiledChange = (e) => {
    const input = e.target.value;
    setFilterDataState((prevState) => ({
      ...prevState,
      searchOrgIdText: input,
    }));
    if (/^\d+$/.test(input)) {
      setFilterDataState((prevState) => ({
        ...prevState,
        searchOrgId: input,
      }));
      setOrganizationErrorMessageTextFiled('');
    } else if (input === '') {
      setOrganizationErrorMessageTextFiled('');
      setFilterDataState((prevState) => ({
        ...prevState,
        searchOrgId: input,
      }));
    } else {
      setOrganizationErrorMessageTextFiled('Invalid input type');
    }
  };
  const handleOnPartnerIDTextFiledChange = (e) => {
    const input = e.target.value;
    setFilterDataState((prevState) => ({
      ...prevState,
      searchPartnerIdText: input,
    }));
    if (/^\d+$/.test(input)) {
      setFilterDataState((prevState) => ({
        ...prevState,
        searchPartnerId: input,
      }));
      setErrorMessagePartnerTextFiled('');
    } else if (input === '') {
      setErrorMessagePartnerTextFiled('');
      setFilterDataState((prevState) => ({
        ...prevState,
        searchPartnerId: input,
      }));
    } else {
      setErrorMessagePartnerTextFiled('Invalid input type');
    }
  };
  return {
    handleGWSTenantAsync,
    handleStateUpdate,
    handleOnResetButtonClick,
    handleOnTextFiledChange,
    handleOnHideAdvanceClick,
    handleUpdateFilters,
    matchTextHandler,
    getGWSDataFromResponse,
    handleOnPartnerIDTextFiledChange,
    handleOnOwnerIDTextFiledChange,
  };
}
