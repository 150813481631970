import { createSlice } from '@reduxjs/toolkit';
import {
  submitPurgeSharedDriveAsync,
  submitPurgeSharedDriveFormExtraReducers,
} from './serviceActions/submitPurgeSharedDriveAsync';

const initialState = {
  submitPurgeSharedDriveForm: {
    submitting: false,
    success: false,
    error: null,
    statusCode: null,
    errorMessage: null,
  },
};

const purgeSharedDriveSlice = createSlice({
  name: 'PurgeSharedDriveForm',
  initialState,
  reducers: {
    resetPurgeSharedDriveFormSubmit(state) {
      state.submitPurgeSharedDriveForm = initialState.submitPurgeSharedDriveForm;
    },
  },
  extraReducers: {
    ...submitPurgeSharedDriveFormExtraReducers,
  },
});

export { submitPurgeSharedDriveAsync };
export const { resetPurgeSharedDriveFormSubmit } = purgeSharedDriveSlice.actions;
export default purgeSharedDriveSlice.reducer;
