import { useState } from 'react';
import * as yup from 'yup';
import SchemaBuilder from '@libs/SchemaBuilder';
import { pascalToTitleCase } from '@utilities/stringUtils';

const supportTicketName = 'support-ticket';
const escalationTicketName = 'escalation-ticket';
const descriptionName = 'description';
const organizationOwnerIdName = 'org-owner-id';
const changeOfSeats = 'no-of-seats';

const InitialForm = {
  [supportTicketName]: '',
  [escalationTicketName]: '',
  [descriptionName]: '',
  [organizationOwnerIdName]: '',
  [changeOfSeats]: 1,
};
const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

export default function useFormValidation() {
  const schemaBuilder = new SchemaBuilder({}, 'yup').create();

  const [isValid, setIsValid] = useState(false);
  const [errors, setErrors] = useState('');
  const [formData, setFormData] = useState(InitialForm);

  const formSchema = yup.object().shape({
    [supportTicketName]: yup.string().required('Support Ticket is required field'),
    [escalationTicketName]: yup.string(),
    [descriptionName]: yup.string(),
    [organizationOwnerIdName]: yup.string().required('Organization Owner ID is required field'),
    [changeOfSeats]: yup
      .number()
      .typeError('Invalid Seat Count')
      .required('New Seat Count is required field'),
  });

  const validate = async (value) => {
    // eslint-disable-next-line func-names
    await formSchema.validate(value, { abortEarly: false }).catch(function (err) {
      const errorLogs = err.inner.reduce((acc, error) => {
        return {
          ...acc,
          [error.path]: pascalToTitleCase(error.message.split(',')[0]),
        };
      }, {});
      setErrors(errorLogs);
      return errorLogs;
    });

    const validation = await formSchema.isValid(value);
    if (validation) {
      setErrors({});
    }
    setIsValid(validation);

    return validation;
  };

  const validateAll = async () => {
    const flag = await validate(formData);

    return flag;
  };

  const updateFormData = (newData) => {
    setFormData((prev) => {
      return { ...prev, ...newData };
    });
  };

  const supportTicketAttributes = {
    handleChange: async (value) => {
      updateFormData({ [supportTicketName]: value });
    },
    errorMessage: errors[supportTicketName],
    value: formData[supportTicketName],
  };

  const escalationTicketAttributes = {
    handleChange: async (value) => {
      updateFormData({ [escalationTicketName]: value });
    },
    value: formData[escalationTicketName],
  };
  const descriptionAttributes = {
    handleChange: async (value) => {
      updateFormData({ [descriptionName]: value });
    },
    value: formData[descriptionName],
  };
  const organizationOwnerIdAttributes = {
    handleChange: async (value) => {
      updateFormData({ [organizationOwnerIdName]: value });
    },
    errorMessage: errors[organizationOwnerIdName],
    value: formData[organizationOwnerIdName],
  };

  const changeOfSeatsAttributes = {
    handleChange: async (value) => {
      updateFormData({ [changeOfSeats]: value });
    },
    errorMessage: errors[changeOfSeats],
    value: formData[changeOfSeats],
  };

  const resetForm = () => {
    setFormData(InitialForm);
    setErrors({});
    setIsValid(false);
  };

  return {
    formData,
    supportTicketAttributes,
    escalationTicketAttributes,
    descriptionAttributes,
    organizationOwnerIdAttributes,
    changeOfSeatsAttributes,
    validateAll,
    isValid,
    resetForm,
  };
}
