import { createAsyncThunk } from '@reduxjs/toolkit';
import { transferSubscriptionApi } from '@services/WebApiService';

const transferSubscriptionAsync = createAsyncThunk('TransferSubscription', async (paramsList) => {
  try {
    const data = await transferSubscriptionApi(paramsList);
    return data;
  } catch (err) {
    return { error: err?.response };
  }
});

const { pending, fulfilled, rejected } = transferSubscriptionAsync;

const transferSubscriptionExtraReducers = {
  [pending]: (state) => {
    state.transferSubscription.submitting = true;
  },
  [rejected]: (state) => {
    state.transferSubscription.submitting = false;
  },
  [fulfilled]: (state, action) => {
    state.transferSubscription.submitting = false;
    if (action?.payload?.error) {
      state.transferSubscription.error = action?.payload?.error?.data?.message;
    } else if (action?.payload?.status === 200) {
      state.transferSubscription.success = true;
      state.transferSubscriptionValidate.value = action?.payload?.data?.data;
    } else if (
      !action?.payload?.error &&
      !action?.payload?.data &&
      action?.payload?.status !== 200
    ) {
      state.transferSubscription.errorMessage =
        'Something went wrong while processing your request.';
    }
    if (action?.payload?.error?.status === 403) {
      state.transferSubscription.success = false;
      state.transferSubscription.errorMessage = 'You are not authorize to access this resource';
    }
  },
};

export { transferSubscriptionAsync, transferSubscriptionExtraReducers };
