import { useDispatch } from 'react-redux';
import mailBoxChangeAsync from '@features/mailBox/serviceActions/mailBoxChangeAsync';

export default function useMailBoxChange() {
  const dispatch = useDispatch();

  const newRequest = async (paramsList) => {
    try {
      const data = await dispatch(mailBoxChangeAsync(paramsList));

      return data;
    } catch (error) {
      return error;
    }
  };

  const getMailBoxRequestData = (data, setMailboxData, columns = []) => {
    const modifiedData = data?.map((item) => {
      let modifiedPayloadObj = {};
      const payloadObj = JSON.parse(item.payload);

      if (columns.length > 0) {
        columns.forEach((col) => {
          modifiedPayloadObj[col] = payloadObj[col];
          if (!modifiedPayloadObj[col]) {
            modifiedPayloadObj[col] = item[col];
          }
        });
      } else {
        modifiedPayloadObj = {
          ...payloadObj,
          'executed-on': item['executed-on'],
          'status-description': item['status-description'],
        };
      }

      return modifiedPayloadObj;
    });
    setMailboxData(modifiedData);
  };

  const checkValueExistance = (formData) =>
    !!formData['support-ticket'] &&
    !!formData['org-owner-id'] &&
    !!formData['current-email'] &&
    !!formData['new-email'];

  return { newRequest, getMailBoxRequestData, checkValueExistance };
}
