import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSeatCountChangeAPI } from '@services/WebApiService';

const getSeatCountChangeAsync = createAsyncThunk(
  'SeatCountChange/getSeatCountChange',
  async (paramsList) => {
    try {
      const data = await getSeatCountChangeAPI(paramsList);
      return data;
    } catch (error) {
      return error.response;
    }
  }
);

const { pending, fulfilled, rejected } = getSeatCountChangeAsync;

const getSeatCountChangeReducers = {
  [pending]: (state) => {
    state.getSeatCountChangeState.fetching = true;
  },
  [rejected]: (state) => {
    state.getSeatCountChangeState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.getSeatCountChangeState.fetching = false;

    if (action?.payload?.data?.message) {
      state.getSeatCountChangeState.errorMessage = action?.payload?.data?.message;
    }

    if (action?.payload?.status === 403) {
      state.getSeatCountChangeState.forbiddenState = true;
      state.getSeatCountChangeState.forbiddenErrorMessage = {
        heading: 'Forbidden Error',
        caption: 'You are not authorize to access this resource',
      };
    }

    if (action?.payload?.data?.data) {
      state.getSeatCountChangeState.forbiddenState = false;
      state.getSeatCountChangeState.forbiddenErrorMessage = {};
      state.getSeatCountChangeState.data = action?.payload?.data?.data;
      state.getSeatCountChangeState.success = true;
    }
    if (action?.payload?.data?.pagination) {
      state.getSeatCountChangeState.pagination = JSON.parse(action?.payload?.data?.pagination);
    }

    if (!action?.payload) {
      state.getSeatCountChangeState.getSeatCountChangeCorsErrorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.code) {
      state.getSeatCountChangeState.error = action.payload.code;
    }
  },
};

export { getSeatCountChangeAsync, getSeatCountChangeReducers };

export default getSeatCountChangeAsync;
