import { createAsyncThunk } from '@reduxjs/toolkit';
import { getHEXTenantAPI } from '@services/WebApiService';

const getHEXTenantAsync = createAsyncThunk('HEXTenant/getHEXTenant', async (paramsList) => {
  try {
    const data = await getHEXTenantAPI(paramsList);
    return data;
  } catch (error) {
    return { error: error.response };
  }
});

const { pending, fulfilled, rejected } = getHEXTenantAsync;

const getHEXTenantReducers = {
  [pending]: (state) => {
    state.getHEXTenantState.fetching = true;
  },
  [rejected]: (state) => {
    state.getHEXTenantState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.getHEXTenantState.fetching = false;

    if (action?.payload?.status === 403) {
      state.getHEXTenantState.forbiddenState = true;
      state.getHEXTenantState.forbiddenErrorMessage = {
        heading: 'Forbidden Error',
        caption: 'You are not authorize to access this resource',
      };
    }

    if (!action?.payload) {
      state.getHEXTenantState.hexTenantCorsErrorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.data?.data) {
      state.getHEXTenantState.hexTenantInformation = action?.payload?.data?.data;
      state.getHEXTenantState.paginationList = JSON.parse(action?.payload?.data?.pagination);
      state.getHEXTenantState.success = true;
      state.getHEXTenantState.statusCode = action?.payload?.data['status-code'];
    }

    if (action?.payload?.error) {
      state.getHEXTenantState.error = action.payload.error;
    }
  },
};

export { getHEXTenantAsync, getHEXTenantReducers };
