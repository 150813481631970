import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchListingApi } from '@services/WebApiService';

const fetchListingAysnc = createAsyncThunk('Todos/fetchListing', async () => {
  const data = await fetchListingApi();
  return data;
});

const { pending, fulfilled, rejected } = fetchListingAysnc;

const fetchListingExtraReducers = {
  [pending]: (state) => {
    state.fetchListing = true;
  },
  [rejected]: (state) => {
    state.fetchListing = false;
  },
  [fulfilled]: (state, action) => {
    if (!action.payload.error) {
      state.listing.value = action.payload.data;
    } else {
      state.error = action.payload.error;
    }
    state.fetchListing = false;
  },
};

export { fetchListingAysnc, fetchListingExtraReducers };
