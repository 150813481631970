import { createAsyncThunk } from '@reduxjs/toolkit';
import { userBackupDetailAPI } from '@services/WebApiService';

const fetchUserBackupDetailAsync = createAsyncThunk(
  'UserBackupDetail/getUserBackupDetail',
  async (paramsList) => {
    try {
      const data = await userBackupDetailAPI(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = fetchUserBackupDetailAsync;

const getUserBackupDetailExtraReducers = {
  [pending]: (state) => {
    state.userBackupDetailState.fetching = true;
  },
  [rejected]: (state) => {
    state.userBackupDetailState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.userBackupDetailState.fetching = false;

    if (action?.payload?.status === 403) {
      state.userBackupDetailState.forbiddenState = true;
      state.userBackupDetailState.forbiddenErrorMessage = {
        heading: 'Forbidden Error',
        caption: 'You are not authorize to access this resource',
      };
    }

    if (!action?.payload) {
      state.userBackupDetailState.UsersDetailCorsErrorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.data) {
      state.userBackupDetailState.data = action?.payload?.data;
      state.userBackupDetailState.success = true;
      state.userBackupDetailState.statusCode = action?.payload?.status;
    }

    if (action?.payload?.error) {
      state.userBackupDetailState.error = action.payload.error;
    }
  },
};

export { fetchUserBackupDetailAsync, getUserBackupDetailExtraReducers };
