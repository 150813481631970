import React from 'react';
import PropTypes from 'prop-types';
import { ITBox, QboLabelGroup } from '@ui/Components';
import { Grid } from '@mui/material';

export default function InvalidateServiceTokenConfirmation({ selectedCredential }) {
  if (!selectedCredential) return null;

  return (
    <ITBox
      className="TransferSubresellerPage__information-box"
      direction="column"
      sx={{ marginBottom: '20px' }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <QboLabelGroup
            title="Organization Owner ID"
            description={selectedCredential?.organizationOwnerId?.toString() || '-'}
          />
        </Grid>
        <Grid item xs={6}>
          <QboLabelGroup
            title="Organization Name"
            description={selectedCredential?.orgName || '-'}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <QboLabelGroup
            title="Partner ID"
            description={selectedCredential?.partnerId?.toString() || '-'}
          />
        </Grid>
        <Grid item xs={6}>
          <QboLabelGroup
            title="Partner Organization Name"
            description={selectedCredential?.tenantPartnerName?.toString() || '-'}
          />
        </Grid>
      </Grid>

      <QboLabelGroup
        title="Credential ID"
        description={selectedCredential?.id?.toString() || '-'}
      />

      <QboLabelGroup
        title="Domain Name"
        description={selectedCredential?.domain?.toString() || '-'}
      />
    </ITBox>
  );
}

InvalidateServiceTokenConfirmation.propTypes = {
  selectedCredential: PropTypes.object,
};

InvalidateServiceTokenConfirmation.defaultProps = {
  selectedCredential: null,
};
