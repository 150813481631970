import WebApiRoutes from '@config/Routes/WebApiRoutes';
import ServiceClientFactory from '@libs/ServiceClient';

const webClientApi = (accessToken, reset) => {
  return new ServiceClientFactory({
    baseUrl: WebApiRoutes.getBaseUrl(),
    accessToken,
    reset,
  }).create();
};

const UpdateEnforceSSOService = {
  createToggleEnforceSSOApi: (paramsList) => {
    const { accessToken, reset } = paramsList;
    return webClientApi(accessToken, reset).postRequest({
      url: WebApiRoutes.toggleEnforceSSOPath(),
      parameters: paramsList.parameters,
    });
  },
  validateToggleEnforceSSOApi: (paramsList) => {
    const { accessToken, reset } = paramsList;
    return webClientApi(accessToken, reset).postRequest({
      url: WebApiRoutes.validateToggleEnforceSSOPath(),
      parameters: paramsList.parameters,
    });
  },
};

export default UpdateEnforceSSOService;
