import WebApiRoutes from '@config/Routes/WebApiRoutes';
import ServiceClientFactory from '@libs/ServiceClient';

const webClientApi = (accessToken) => {
  return new ServiceClientFactory({ baseUrl: WebApiRoutes.getBaseUrl(), accessToken }).create();
};

const InvoiceService = {
  getInvoicesListApi: (paramsList) => {
    const {
      accessToken,
      reset,
      pageNumber,
      pageSize,
      fullTextSearch,
      searchById,
      resellerId,
      filter,
      orderBy,
    } = paramsList;
    return webClientApi(accessToken, reset).postRequest({
      url: WebApiRoutes.getInvoicesListPath({
        pageNumber,
        pageSize,
        resellerId,
      }),
      parameters: {
        'full-text-search': fullTextSearch,
        'search-by-id': Number(searchById),
        filters: filter,
        'order-by': orderBy,
      },
    });
  },

  checkXeroSyncRunningApi: (paramsList) => {
    const { accessToken, reset } = paramsList;

    return webClientApi(accessToken, reset).getRequest({
      url: WebApiRoutes.checkXeroSyncRunningPath(),
    });
  },

  runXeroSyncApi: (paramsList) => {
    const { accessToken, reset, parameters } = paramsList;

    return webClientApi(accessToken, reset).postRequest({
      url: WebApiRoutes.runXeroSyncPath(),
      parameters,
    });
  },
};

export default InvoiceService;
