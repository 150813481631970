import { createAsyncThunk } from '@reduxjs/toolkit';
import { getOrganizationFilterListAPI } from '@services/WebApiService';

const getOrganizationFilterListAsync = createAsyncThunk(
  'OrganizationFilterList/getOrganizationFilterLists',
  async (paramsList) => {
    try {
      const data = await getOrganizationFilterListAPI(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = getOrganizationFilterListAsync;

const getOrganizationFilterListExtraReducers = {
  [pending]: (state) => {
    state.getOrganizationFilterListState.fetching = true;
  },
  [rejected]: (state) => {
    state.getOrganizationFilterListState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.getOrganizationFilterListState.fetching = false;

    if (action?.payload?.data?.data) {
      state.getOrganizationFilterListState.organizationFilterList = action?.payload?.data?.data;
      state.getOrganizationFilterListState.success = true;
      state.getOrganizationFilterListState.statusCode = action?.payload?.data['status-code'];
    }

    if (action?.payload?.error) {
      state.getOrganizationFilterListState.error = action.payload.error;
    }
  },
};

export { getOrganizationFilterListAsync, getOrganizationFilterListExtraReducers };
