import { createAsyncThunk } from '@reduxjs/toolkit';
import { validateChangeBackupAdminAPI } from '@services/WebApiService';

const validateChangeBackupAdminAsync = createAsyncThunk(
  'ChangeBackupAdmin/validateChangeBackupAdmin',
  async (paramsList) => {
    try {
      const data = await validateChangeBackupAdminAPI(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = validateChangeBackupAdminAsync;

const validateChangeBackupAdminExtraReducers = {
  [pending]: (state) => {
    state.validateChangeBackupAdminState.submitting = true;
  },
  [rejected]: (state) => {
    state.validateChangeBackupAdminState.submitting = false;
  },
  [fulfilled]: (state, action) => {
    state.validateChangeBackupAdminState.submitting = false;
    let statusCode = 200;
    const data = action?.payload?.data;

    try {
      statusCode = action?.payload?.data['status-code'];
    } catch (err) {
      console.info(err);
    }

    if (data && statusCode) {
      state.validateChangeBackupAdminState.statusCode = statusCode;
    }

    if (data && statusCode !== 200) {
      state.validateChangeBackupAdminState.errorMessage = action.payload.data.message;
    }

    if (action?.payload?.status === 403) {
      state.validateChangeBackupAdminState.errorMessage =
        'You are not authorize to access this resource';
    }

    if (action?.payload?.error?.status === 404) {
      state.validateChangeBackupAdminState.errorMessage = 'Invalid Endpoint';
    }

    if (!action?.payload && !action?.payload?.error) {
      state.validateChangeBackupAdminState.errorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.data) {
      state.validateChangeBackupAdminState.data = action.payload.data.data;
      state.validateChangeBackupAdminState.success = true;
    }
  },
};

export { validateChangeBackupAdminAsync, validateChangeBackupAdminExtraReducers };
