import { createSlice } from '@reduxjs/toolkit';
import {
  getGWSTenantDetailsInfoAsync,
  getGWSTenantDetailsInfoReducers,
} from './serviceActions/getGWSTenantDetailsInfoAsync';

const initialState = {
  getGWSTenantDetailsInfoState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    gwsTenantDetailsInformation: [],
    forbiddenState: false,
    forbiddenErrorMessage: null,
    gwsTenantDetailsInfoCorsErrorMessage: null,
  },
};

const gwsTenantDetailsInfoSlice = createSlice({
  name: 'GWSTenantDetailsInfo',
  initialState,
  reducers: {
    resetGWSTenantDetailsInfoState(state) {
      state.getGWSTenantDetailsInfoState = initialState.getGWSTenantDetailsInfoState;
    },
    resetGWSTenantDetailsInfoStatusCodeState(state) {
      state.getGWSTenantDetailsInfoState.statusCode =
        initialState.getGWSTenantDetailsInfoState.statusCode;
    },
  },
  extraReducers: {
    ...getGWSTenantDetailsInfoReducers,
  },
});

export { getGWSTenantDetailsInfoAsync };
export const { resetGWSTenantDetailsInfoState, resetGWSTenantDetailsInfoStatusCodeState } =
  gwsTenantDetailsInfoSlice.actions;
export default gwsTenantDetailsInfoSlice.reducer;
