import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';
import { QboCard, QboButton, ITBox, QboTypography, QboAlert } from '@ui/Components';

function TextWithLabel({ title, description }) {
  return (
    <>
      <QboTypography variant="caption">{title}</QboTypography>
      <QboTypography
        wrap
        variant="subtitle2"
        withTitle
        className="TransferSubscriptionPage__text-with-label">
        {description}
      </QboTypography>
    </>
  );
}

TextWithLabel.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

TextWithLabel.defaultProps = {
  title: '',
  description: '',
};

export default function CredentialsSPASection({
  credentialsSPAData,
  onBack,
  onSubmit,
  loading,
  showButton,
}) {
  const { t } = useTranslation();
  const data = credentialsSPAData;

  const renderLoading = () => {
    return (
      <div style={{ display: 'contents' }}>
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="70%" />
      </div>
    );
  };

  const spaTypeToText = (spaType) => {
    switch (spaType) {
      case 1:
        return t('switch_spa_page.confirmation.automatic');
      case 0:
        return t('switch_spa_page.confirmation.manual');
      default:
        return '-';
    }
  };

  const revestedSpaTypeToText = (spaType) => {
    switch (spaType) {
      case 0:
        return t('switch_spa_page.confirmation.automatic');
      case 1:
        return t('switch_spa_page.confirmation.manual');
      default:
        return '-';
    }
  };

  const renderContent = () => {
    if (loading) {
      return (
        <>
          <QboCard
            className="
            -wrapper"
            titleCard={t('switch_spa_page.confirmation.title_text')}
            isBordered>
            <ITBox className="TransferSubscriptionPage__information-box">
              <ITBox direction="column">{renderLoading()}</ITBox>
              <ITBox direction="column">{renderLoading()}</ITBox>
            </ITBox>
          </QboCard>
          <ITBox className="TransferSubscriptionPage__source-target">
            <QboCard
              classNameWrapper="TransferSubscriptionPage__source-target-card-wrapper"
              className="TransferSubscriptionPage__center-item"
              titleCard={t('switch_spa_page.confirmation.current_spa_type')}
              isBordered>
              {renderLoading()}
            </QboCard>
            <QboCard
              classNameWrapper="TransferSubscriptionPage__source-target-card-wrapper"
              className="TransferSubscriptionPage__center-item"
              titleCard={t('switch_spa_page.confirmation.switch_spa_to')}
              isBordered>
              {renderLoading()}
            </QboCard>
          </ITBox>
        </>
      );
    }

    return (
      <>
        <QboCard
          className="TransferSubscriptionPage__information-wrapper"
          titleCard={t('switch_spa_page.confirmation.title_text')}
          isBordered>
          <ITBox className="TransferSubscriptionPage__information-box">
            <ITBox direction="column">
              <TextWithLabel
                title={t('switch_spa_page.confirmation.organization_name')}
                description={data['organization-name']}
              />
              <TextWithLabel
                title={t('switch_spa_page.confirmation.domain')}
                description={data?.domain}
              />
            </ITBox>
          </ITBox>
        </QboCard>
        <ITBox className="TransferSubscriptionPage__source-target">
          <QboCard
            classNameWrapper="TransferSubscriptionPage__source-target-card-wrapper"
            className="TransferSubscriptionPage__center-item"
            titleCard={t('switch_spa_page.confirmation.current_spa_type')}
            isBordered>
            <TextWithLabel description={spaTypeToText(data['spa-type'])} />
          </QboCard>
          <QboCard
            classNameWrapper="TransferSubscriptionPage__source-target-card-wrapper"
            className="TransferSubscriptionPage__center-item"
            titleCard={t('switch_spa_page.confirmation.switch_spa_to')}
            isBordered>
            <TextWithLabel description={revestedSpaTypeToText(data['spa-type'])} />
          </QboCard>
        </ITBox>
      </>
    );
  };

  return (
    <ITBox className="TransferSubscriptionPage__wrapper" direction="column">
      {renderContent()}
      {showButton ? (
        <ITBox direction="row" className="TransferSubscriptionPage__footer-button">
          <QboButton
            variant="outlined"
            color="secondary"
            onClick={() => onBack()}
            loading={loading}>
            {t('transfer_subscription_page.view_data.back_button_text')}
          </QboButton>
          <QboButton
            variant="contained"
            withTitle={false}
            onClick={() => onSubmit()}
            loading={loading}
            disabled={Object.keys(data).length === 0 || data['use-backup-admin'] === true}>
            {t('switch_spa_page.confirmation.proceed_button')}
          </QboButton>
        </ITBox>
      ) : null}
    </ITBox>
  );
}

CredentialsSPASection.propTypes = {
  credentialsSPAData: PropTypes.object,
  onBack: PropTypes.func,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
  showButton: PropTypes.bool,
};

CredentialsSPASection.defaultProps = {
  credentialsSPAData: {},
  onBack: () => {},
  onSubmit: () => {},
  loading: true,
  showButton: true,
};
