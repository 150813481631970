import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { QboCard, ITBox, QboTextField, QboFormGroup } from '@ui/Components';

export default function TransferSubscriptionFormSection({
  supportTicketAttributes,
  escalationTicketAttributes,
  descriptionAttributes,
  resellerRequestIdAttributes,
  onSubmit,
}) {
  const { t } = useTranslation();
  const isAstericRequired = true;
  return (
    // <QboCard noPadding isMainWrapper>
    <ITBox className="TransferSubscriptionFormPage__wrapper" direction="column">
      <QboFormGroup
        className="TransferSubscriptionFormPage__form-group"
        onSubmit={onSubmit}
        withSubmitButton
        title={t('transfer_subscription_page.form.title_text')}
        buttonLabel={t('transfer_subscription_page.form.submit_button_text')}>
        <QboTextField
          id="username"
          required={isAstericRequired}
          placeholder={t('transfer_subscription_page.form.support_ticket_label')}
          label={t('transfer_subscription_page.form.support_ticket_label')}
          fullWidth
          value={supportTicketAttributes.value}
          name="test-one"
          onChange={(e) => {
            supportTicketAttributes.handleChange(e.currentTarget.value);
          }}
          errorMessage={supportTicketAttributes.errorMessage}
          withoutErrorIcon
          inputProps={{ maxLength: 225 }}
        />
        <QboTextField
          id="email"
          placeholder={t('transfer_subscription_page.form.escalation_ticket_label')}
          label={t('transfer_subscription_page.form.escalation_ticket_label')}
          fullWidth
          value={escalationTicketAttributes.value}
          name="test-one"
          onChange={(e) => {
            escalationTicketAttributes.handleChange(e.currentTarget.value);
          }}
          errorMessage={escalationTicketAttributes.errorMessage}
          withoutErrorIcon
          inputProps={{ maxLength: 225 }}
        />
        <QboTextField
          id="gender"
          placeholder={t('transfer_subscription_page.form.description_label')}
          label={t('transfer_subscription_page.form.description_label')}
          fullWidth
          value={descriptionAttributes.value}
          name="test-one"
          onChange={(e) => {
            descriptionAttributes.handleChange(e.currentTarget.value);
          }}
          errorMessage={descriptionAttributes.errorMessage}
          withoutErrorIcon
          inputProps={{ maxLength: 225 }}
        />
        <QboTextField
          id="address"
          required={isAstericRequired}
          placeholder={t('transfer_subscription_page.form.change_reseller_request_id')}
          label={t('transfer_subscription_page.form.change_reseller_request_id')}
          fullWidth
          value={resellerRequestIdAttributes.value}
          name="test-one"
          onChange={(e) => {
            resellerRequestIdAttributes.handleChange(e.currentTarget.value);
          }}
          errorMessage={resellerRequestIdAttributes.errorMessage}
          withoutErrorIcon
          inputProps={{ maxLength: 225 }}
        />
      </QboFormGroup>
    </ITBox>
    // </QboCard>
  );
}

TransferSubscriptionFormSection.propTypes = {
  supportTicketAttributes: PropTypes.object,
  escalationTicketAttributes: PropTypes.object,
  descriptionAttributes: PropTypes.object,
  resellerRequestIdAttributes: PropTypes.object,
  onSubmit: PropTypes.func,
};

TransferSubscriptionFormSection.defaultProps = {
  supportTicketAttributes: {},
  escalationTicketAttributes: {},
  descriptionAttributes: {},
  resellerRequestIdAttributes: {},
  onSubmit: {},
};
