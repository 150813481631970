import { createAsyncThunk } from '@reduxjs/toolkit';
import { getTransferSubresellerAPI } from '@services/WebApiService';

const getTransferSubresellerAsync = createAsyncThunk(
  'TransferSubreseller/getTransferSubreseller',
  async (paramsList) => {
    try {
      const data = await getTransferSubresellerAPI(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = getTransferSubresellerAsync;

const getTransferSubresellerExtraReducers = {
  [pending]: (state) => {
    state.getTransferSubresellerState.fetching = true;
  },
  [rejected]: (state) => {
    state.getTransferSubresellerState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.getTransferSubresellerState.fetching = false;

    if (action?.payload?.error) {
      state.getTransferSubresellerState.errorMessage = action.payload.error?.data.message;
      state.getTransferSubresellerState.status = action.payload.error?.status;

      if (action?.payload?.error?.status === 403) {
        state.getTransferSubresellerState.forbiddenState = true;
        state.getTransferSubresellerState.forbiddenErrorMessage = {
          heading: 'Forbidden Error',
          caption: 'You are not authorize to access this resource',
        };
      }
    }

    if (!action?.payload?.error && !action?.payload?.data) {
      state.getTransferSubresellerState.transferSubresellerCorsErrorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.data?.data) {
      state.getTransferSubresellerState.forbiddenState = false;
      state.getTransferSubresellerState.forbiddenErrorMessage = {};
      state.getTransferSubresellerState.status = action.payload?.status;
      state.getTransferSubresellerState.transferSubresellerList = action?.payload?.data?.data;

      if (action?.payload?.data?.pagination) {
        state.getTransferSubresellerState.paginations = JSON.parse(
          action?.payload?.data?.pagination
        );
      }
    }
  },
};

export { getTransferSubresellerAsync, getTransferSubresellerExtraReducers };
