import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, CardActions, Grid, Typography } from '@mui/material';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { useTranslation } from 'react-i18next';
import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import {
  transferSubresellerPagePath,
  transferSubscriptionPagePath,
  mailBoxChangePath,
  mailBoxSyncPath,
  viewExcludedMailBoxPath,
  viewAutoDiscoveryPath,
  dataIngestionPath,
  bulkPurgePath,
  domainBackupsPath,
} from '@config/Routes/WebClientRoutes';
import useFeatureFlag from '@hooks/useFeatureFlag';

const steps = ['Select campaign settings', 'Create an ad group', 'Create an ad', 'Test'];

const icons = {
  1: <SettingsIcon />,
  2: <GroupAddIcon />,
  3: <VideoLabelIcon />,
  4: <VideoLabelIcon />,
};

export default function DashboardPage() {
  const { t } = useTranslation();
  const { getFeatureFlagStateHandler } = useFeatureFlag();
  const featureflag = getFeatureFlagStateHandler();
  const mainMenus = [
    {
      id: 1,
      name: t('drawer.main_menu.transfer_subreseller_page'),
      description:
        'This command is built to enable sub reseller transfer from one distributor to another distributor.  Inputs required are: Sub reseller id, Source Distributor Id & Target Distributor Id.  This command validates the provided inputs before performing the transfer.',
      path: transferSubresellerPagePath,
    },
    {
      id: 2,
      name: t('drawer.main_menu.transfer_subscription_page'),
      description:
        'This command is built to enable subscription transfer between resellers.  The request is to be created from Sales portal and the request id generated is to be provided as input.  This command validates the provided request id and approval status before performing the transfer.',
      path: transferSubscriptionPagePath,
    },

    {
      id: 3,
      name: t('mailbox_change.text'),
      description:
        'This command enables email address change for an existing mailbox to the new email address. Inputs required are: Organization owner id, existing mailbox address and new email address.  The command updates email address after validating the given inputs and ensures the new email address does not already exist.',
      path: mailBoxChangePath,
    },
    {
      id: 4,
      name: t('mailbox_sync'),
      description:
        'This command synchronizes new email address, UPN and display name to an existing mailbox address provided.  This is used for O365 subscriptions.  Inputs required are: Organization owner id, existing mailbox address and new email address.  The command updates email address, UPN and display name after validating the given inputs and ensures the new email address does not already exist.',
      path: mailBoxSyncPath,
    },

    {
      id: 5,
      name: t('drawer.main_menu.view_excluded_mailbox'),
      description:
        'This command helps to retrieve the mailboxes for a given organization.   Inputs required are:  Organization owner id, email address (optional). If email address is provided, the command confirms whether the given email address is excluded or not.',
      path: viewExcludedMailBoxPath,
    },
    {
      id: 6,
      name: t('drawer.main_menu.view_auto_discovery'),
      description:
        'This command helps to retrieve the auto discover status for mailbox and sharepoint for a given organization.  Organization owner id is to be provided as input and the auto discover status of mailbox and sharepoint for all the tenants under the given organization.',
      path: viewAutoDiscoveryPath,
    },
    {
      id: 7,
      name: t('drawer.main_menu.data_ingestion'),
      description:
        'The Data Ingestion feature enables direct import of old data into our system, ensuring seamless integration. It simplifies the process of bringing in old data, saving time and effort. Effortlessly import and leverage old data with our Data Ingestion feature.',
      path: dataIngestionPath,
    },
    {
      id: 8,
      name: t('drawer.main_menu.bulk_purge'),
      description:
        'Purge Mail Accounts is a process to purge single or multiple email accounts which to be purged and not included in the backup process.',
      path: bulkPurgePath,
    },
    {
      id: 9,
      name: t('drawer.main_menu.domain_backup_on_dashboard'),
      description:
        'This command helps to initiate sharepoint, teams & groups backup for a tenant domain of a given organization.  Inputs required are: Organization owner id, and domain.',
      path: domainBackupsPath,
    },
  ];

  const modifiedMainMenus = mainMenus.filter(
    (item) => featureflag?.find((flag) => flag.path === item.path)?.isActive && item
  );

  const navigate = useNavigate();

  return (
    <div>
      <HeaderPageBlock>{t('dashboard_page.header.text')}</HeaderPageBlock>
      <Grid container rowSpacing={2} spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {modifiedMainMenus.map((menu) => (
          <Grid item xs={12} md={6} key={menu?.id}>
            <Card className="CardItem_wrapper" style={{ maxWidth: '85%' }}>
              <Typography
                gutterBottom
                component="div"
                style={{
                  fontSize: 16,
                  backgroundColor: '#c2e2ef',
                  borderBottom: '1px solid lightGrey',
                  padding: '5px',
                }}>
                {menu.name}
              </Typography>
              <Typography fontSize={15} style={{ padding: 10 }}>
                {menu.description}
              </Typography>
              <br />
              <br />
              <CardActions style={{ position: 'absolute', bottom: 0 }}>
                <Button onClick={() => navigate(menu.path)} variant="contained">
                  {t('dashboard_page.get_start_button')}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      <br />
    </div>
  );
}
