import { createAsyncThunk } from '@reduxjs/toolkit';
import { exportUserBackupAPI } from '@services/WebApiService';

const exportUserBackupAsync = createAsyncThunk(
  'ExportUserBackup/exportUserBackup',
  async (paramsList) => {
    try {
      const data = await exportUserBackupAPI(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = exportUserBackupAsync;

const exportUserBackupExtraReducers = {
  [pending]: (state) => {
    state.exportUserBackupState.fetching = true;
  },
  [rejected]: (state) => {
    state.exportUserBackupState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.exportUserBackupState.fetching = false;

    if (action?.payload?.status === 403) {
      state.exportUserBackupState.forbiddenState = true;
      state.exportUserBackupState.forbiddenErrorMessage = {
        heading: 'Forbidden Error',
        caption: 'You are not authorize to access this resource',
      };
    }

    if (!action?.payload) {
      state.exportUserBackupState.exportUserBackupCorsErrorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.data) {
      state.exportUserBackupState.userBackupExportData = action?.payload?.data;
      state.exportUserBackupState.success = true;
      state.exportUserBackupState.statusCode = action?.payload?.data['status-code'];
    }

    if (action?.payload?.error) {
      state.exportUserBackupState.error = action.payload.error;
    }
  },
};

export { exportUserBackupAsync, exportUserBackupExtraReducers };
