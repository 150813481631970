import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPartnersListAPI } from '@services/WebApiService';

const getSubResellerListAsync = createAsyncThunk(
  'SubResellerList/getSubResellerList',
  async (paramsList) => {
    try {
      const data = await getPartnersListAPI(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = getSubResellerListAsync;

const getSubResellerListReducers = {
  [pending]: (state) => {
    state.getSubResellerListState.fetching = true;
  },
  [rejected]: (state) => {
    state.getSubResellerListState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.getSubResellerListState.fetching = false;

    if (action?.payload?.data?.message) {
      state.getSubResellerListState.errorMessage = action?.payload?.data?.message;
    }

    if (action?.payload?.status === 403) {
      state.getSubResellerListState.forbiddenState = true;
      state.getSubResellerListState.forbiddenErrorMessage = {
        heading: 'Forbidden Error',
        caption: 'You are not authorize to access this resource',
      };
    }

    if (action?.payload?.data?.data) {
      state.getSubResellerListState.forbiddenState = false;
      state.getSubResellerListState.forbiddenErrorMessage = {};
      state.getSubResellerListState.subResellerListData = action?.payload?.data?.data;
      state.getSubResellerListState.success = true;
      state.getSubResellerListState.statusCode = action?.payload?.status;
    }
    if (action?.payload?.data?.pagination) {
      state.getSubResellerListState.pagination = JSON.parse(action?.payload?.data?.pagination);
    }

    if (!action?.payload) {
      state.getSubResellerListState.getSubResellerListCorsErrorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.code) {
      state.getSubResellerListState.error = action.payload.code;
    }
  },
};

export { getSubResellerListAsync, getSubResellerListReducers };

export default getSubResellerListAsync;
