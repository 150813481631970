import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { List, ListItem, ListSubheader, Collapse } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  HistoryOutlined,
  HomeOutlined as HomeOutlinedIcon,
  SubscriptionsOutlined as SubscriptionsOutlinedIcon,
  BarChartOutlined,
  EventRepeat,
} from '@mui/icons-material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import HomeRepairServiceOutlinedIcon from '@mui/icons-material/HomeRepairServiceOutlined';

import {
  dashboardPath,
  mailBoxChangePath,
  dataIngestionPath,
  transferSubresellerPagePath,
  transferSubscriptionPagePath,
  bulkPurgePath,
  seatUsageReportsPagePath,
  showPartnerListPath,
  showOrganizationListPath,
  showPlansListPath,
  m365TenantListPath,
  userBackupInfoPath,
  gwsTenantListPath,
  hexTenantListPath,
  toolsRequestStatusPath,
  backupStatusesPath,
} from '@config/Routes/WebClientRoutes';
import { QboDrawerMenuItem } from '@ui/Components';
import useFeatureFlag from '@hooks/useFeatureFlag';
import useStateReset from '@hooks/useStateReset';
import {
  setPurgeSublink,
  setSeatUsageSublink,
  setTransferSublink,
  setPartnerManagementLink,
  setTransferlink,
  setToolLink,
  setBillingLink,
  setOrganizationLink,
  setPartnerLink,
  setTenantLink,
  setSystemStatusLink,
} from '@features/application/applicationSlice';
import { constant } from '@config/BaseSetting';
import useLocalStorage from '@hooks/useLocalStorage';
import useMenuBlockService from './pagehooks/useMenuBlockService';

export default function MenuBlock({ drawerOpen, flagState, setFlagState }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const windowLocationPath = window.location.pathname;
  const [activeMenu] = useLocalStorage(constant.MENUS_FLAG_STATE);

  const {
    isApplicationReady,
    transferSublink,
    seatUsageSublink,
    purgeSublink,
    partnerManagementLink,
    transferlink,
    toolLink,
    billingLink,
    organizationLink,
    partnerLink,
    tenantLink,
    systemStatusLink,
  } = useSelector((state) => state.application);
  const isInitializing = !isApplicationReady;
  const { getFeatureFlagStateHandler } = useFeatureFlag();
  const { resetCommandState } = useStateReset();
  const {
    toolsObjects,
    menuReportsObject,
    purgeMenuObjects,
    tenantsMenuObjects,
    partnerMenuObjects,
    orgMenuObjects,
    userAndOrgMenuObjects,
    subscriptionMenuObjects,
    transferMenuObjects,
    partnerTransferMenuObjects,
    organisationMenuObject,
    partnerManagementMenuObjects,
    billingMenuObjects,
    partnerManagementPathList,
    organisationPathList,
    partnerPathList,
    tenantsPathList,
    transferPathList,
    systemStatusPathList,
    purgePathList,
    toolsPathList,
    tenantPathsLIst,
    menuStateHandler,
    menuFlagHandler,
    menuStatesOne,
    menuStatesTwo,
    ingestionPathList,
    reportsPathList,
    plansListpath,
    transferSubscriptionPathsList,
    collapsibleButtonHandler,
    systemStatusMenuObjects,
  } = useMenuBlockService();
  const flagsState = getFeatureFlagStateHandler();

  const sideMenuStateHandler = async (properties, value, flag = false) => {
    menuStateHandler(setFlagState, properties, value, flag);
  };

  const handleMultiStateReset = (properties) => {
    setFlagState((prevState) => {
      const newState = { ...prevState };
      properties.forEach((prop) => {
        newState[prop] = null;
      });
      return newState;
    });
  };

  useEffect(() => {
    const activeMenuItem = JSON.parse(activeMenu);
    const allNull = Object.values(flagState ?? {}).every((value) => value === null);

    if (allNull) {
      setFlagState(activeMenuItem);
    }

    menuFlagHandler(activeMenuItem);
  }, [activeMenu]);

  useEffect(() => {
    resetCommandState();
  }, [windowLocationPath]);

  const subLink = (menu, styleName, handler = () => {}, properties = []) => {
    const { path, icon, title } = menu;
    const activePath = menu?.forceActivePath?.length > 0 ? menu.forceActivePath : [path];

    return [
      <QboDrawerMenuItem
        key={path}
        icon={icon}
        text={title}
        path={path}
        onClick={() => handler(properties)}
        className={styleName}
        forceActive={activePath.includes(windowLocationPath)}
      />,
    ].filter(
      (menuBlock) => flagsState?.find((item) => item?.path === menuBlock?.props?.path)?.isActive
    );
  };

  const getActiveFlagsList = (collapsibleMenuObjects) => {
    const filteredPurgeMenuObjects = collapsibleMenuObjects.filter((menuObject) => {
      const matchingData = flagsState?.find((item) => item.path === menuObject.path);
      return matchingData && matchingData?.isActive;
    });

    return filteredPurgeMenuObjects;
  };

  useEffect(() => {
    collapsibleButtonHandler();
  }, []);

  return (
    <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', height: '100%' }}
      component="nav"
      aria-labelledby="main-menus"
      subheader={
        drawerOpen && (
          <ListSubheader className="DrawerSubHeader__default" component="div">
            {t('drawer.main_menu.text')}
          </ListSubheader>
        )
      }>
      <ListItem disablePadding sx={{ display: 'block' }}>
        {isInitializing && (
          <>
            <QboDrawerMenuItem loading />
            <QboDrawerMenuItem loading />
          </>
        )}
        {!isInitializing && (
          <>
            <QboDrawerMenuItem
              key={dashboardPath}
              icon={<HomeOutlinedIcon className="Icon__default" />}
              text={t('drawer.main_menu.homepage')}
              isWithLabel
              loading={flagsState.length === 0}
              path={dashboardPath}
              forceActive={windowLocationPath === dashboardPath}
              onClick={() => sideMenuStateHandler('dashboardManagement', true)}
            />
            {[
              <QboDrawerMenuItem
                key={showPartnerListPath}
                icon={<PersonAddAltIcon className="Icon__default" />}
                text="Partner Management"
                isWithLabel
                isWrapEnable={!partnerManagementLink}
                path={getActiveFlagsList(partnerManagementMenuObjects)[0]?.path}
                forceActive={partnerManagementPathList.includes(windowLocationPath)}
                onClick={() => {
                  if (flagState?.partnerManagement === false) {
                    handleMultiStateReset(menuStatesOne);
                  }
                  handleMultiStateReset(menuStatesTwo);
                  sideMenuStateHandler('partnerManagement', !partnerManagementLink, true);
                  dispatch(setPartnerManagementLink({ open: !partnerManagementLink }));
                }}
                isSublinkOpen={partnerManagementLink}>
                <Collapse in={partnerManagementLink} timeout="auto" unmountOnExit>
                  <QboDrawerMenuItem
                    className="QboDrawerMenuItemSublinks"
                    key={showPartnerListPath}
                    icon={<MoveUpIcon className="Icon__default" />}
                    text="Partners"
                    isWithLabel
                    forceActive={partnerPathList.includes(windowLocationPath)}
                    path={getActiveFlagsList(partnerMenuObjects)[0]?.path}
                    isWrapEnable={!partnerLink}
                    onClick={() => {
                      handleMultiStateReset([
                        'transferManagement',
                        'subscriptionManagement',
                        'tenantManagement',
                        'orgManagement',
                        'systemStatusManagement',
                      ]);
                      sideMenuStateHandler('partnersManagement', !partnerLink, true);
                      dispatch(setPartnerLink({ open: !partnerLink }));
                    }}
                    isSublinkOpen={partnerLink}>
                    <Collapse in={partnerLink} timeout="auto" unmountOnExit>
                      {partnerMenuObjects?.map((menu) =>
                        subLink(menu, 'QboDrawerMenuItemSublinks1', handleMultiStateReset, [])
                      )}
                    </Collapse>
                  </QboDrawerMenuItem>
                  {userAndOrgMenuObjects?.map((menu) =>
                    subLink(menu, 'QboDrawerMenuItemSublinks', handleMultiStateReset, menuStatesOne)
                  )}
                  <QboDrawerMenuItem
                    className="QboDrawerMenuItemSublinks"
                    key={systemStatusPathList}
                    icon={<EventRepeat className="Icon__default" />}
                    text="System Status"
                    isWithLabel
                    forceActive={systemStatusPathList.includes(windowLocationPath)}
                    path={getActiveFlagsList(systemStatusMenuObjects)[0]?.path}
                    isWrapEnable={!systemStatusLink}
                    onClick={() => {
                      handleMultiStateReset([
                        'transferManagement',
                        'subscriptionManagement',
                        'tenantManagement',
                        'orgManagement',
                        'partnersManagement',
                      ]);
                      sideMenuStateHandler('systemStatusManagement', !systemStatusLink, true);
                      dispatch(setSystemStatusLink({ open: !systemStatusLink }));
                    }}
                    isSublinkOpen={systemStatusLink}>
                    <Collapse in={systemStatusLink} timeout="auto" unmountOnExit>
                      {systemStatusMenuObjects?.map((menu) =>
                        subLink(menu, 'QboDrawerMenuItemSublinks1', handleMultiStateReset, [])
                      )}
                    </Collapse>
                  </QboDrawerMenuItem>
                  <QboDrawerMenuItem
                    className="QboDrawerMenuItemSublinks"
                    key={showOrganizationListPath}
                    icon={<MoveUpIcon className="Icon__default" />}
                    text="Organisations"
                    isWithLabel
                    forceActive={organisationPathList.includes(windowLocationPath)}
                    path={getActiveFlagsList(orgMenuObjects)[0]?.path}
                    isWrapEnable={!organizationLink}
                    onClick={() => {
                      handleMultiStateReset([
                        'transferManagement',
                        'subscriptionManagement',
                        'tenantManagement',
                        'partnersManagement',
                        'systemStatusManagement',
                      ]);
                      sideMenuStateHandler('orgManagement', !organizationLink, true);
                      dispatch(setOrganizationLink({ open: !organizationLink }));
                    }}
                    isSublinkOpen={organizationLink}>
                    <Collapse in={organizationLink} timeout="auto" unmountOnExit>
                      {organisationMenuObject?.map((menu) => {
                        return subLink(menu, 'QboDrawerMenuItemSublinks1', handleMultiStateReset, [
                          'tenantManagement',
                        ]);
                      })}
                      {getActiveFlagsList(tenantsMenuObjects)[0]?.path && (
                        <QboDrawerMenuItem
                          className="QboDrawerMenuItemSublinks1"
                          key={m365TenantListPath}
                          icon={<ContentPasteGoIcon className="Icon__default" />}
                          text="Tenants"
                          isWithLabel
                          forceActive={tenantPathsLIst.includes(windowLocationPath)}
                          path={getActiveFlagsList(tenantsMenuObjects)[0]?.path}
                          isWrapEnable={false}
                          onClick={() => {
                            sideMenuStateHandler('tenantManagement', !tenantLink, true);
                            dispatch(setTenantLink({ open: !tenantLink }));
                          }}
                          isSublinkOpen={tenantLink}>
                          <Collapse in={tenantLink} timeout="auto" unmountOnExit>
                            {tenantsMenuObjects?.map((menu) =>
                              subLink(menu, 'QboDrawerMenuItemSublink2')
                            )}
                          </Collapse>
                        </QboDrawerMenuItem>
                      )}
                    </Collapse>
                  </QboDrawerMenuItem>
                  <QboDrawerMenuItem
                    className="QboDrawerMenuItemSublinks"
                    key={transferSubresellerPagePath}
                    icon={<MoveUpIcon className="Icon__default" />}
                    text="Transfer"
                    isWithLabel
                    forceActive={transferPathList.includes(windowLocationPath)}
                    path={getActiveFlagsList(transferMenuObjects)[0]?.path}
                    isWrapEnable={!transferlink}
                    onClick={() => {
                      handleMultiStateReset([
                        'tenantManagement',
                        'orgManagement',
                        'partnersManagement',
                        'subscriptionManagement',
                        'systemStatusManagement',
                      ]);
                      sideMenuStateHandler('transferManagement', !transferlink, true);
                      dispatch(setTransferlink({ open: !transferlink }));
                    }}
                    isSublinkOpen={transferlink}>
                    <Collapse in={transferlink} timeout="auto" unmountOnExit>
                      {partnerTransferMenuObjects?.map((menu) =>
                        subLink(menu, 'QboDrawerMenuItemSublinks1', handleMultiStateReset, [
                          'subscriptionManagement',
                        ])
                      )}
                      {getActiveFlagsList(subscriptionMenuObjects)[0]?.path && (
                        <QboDrawerMenuItem
                          className="QboDrawerMenuItemSublinks1"
                          key={transferSubscriptionPagePath}
                          icon={<SubscriptionsOutlinedIcon className="Icon__default" />}
                          text="Subscription"
                          isWithLabel
                          forceActive={transferSubscriptionPathsList.includes(windowLocationPath)}
                          path={getActiveFlagsList(subscriptionMenuObjects)[0]?.path}
                          isWrapEnable={false}
                          onClick={() => {
                            sideMenuStateHandler('subscriptionManagement', !transferSublink, true);
                            dispatch(setTransferSublink({ open: !transferSublink }));
                          }}
                          isSublinkOpen={transferSublink}>
                          <Collapse in={transferSublink} timeout="auto" unmountOnExit>
                            {subscriptionMenuObjects?.map((menu) =>
                              subLink(menu, 'QboDrawerMenuItemSublink2')
                            )}
                          </Collapse>
                        </QboDrawerMenuItem>
                      )}
                    </Collapse>
                  </QboDrawerMenuItem>
                </Collapse>
              </QboDrawerMenuItem>,

              <QboDrawerMenuItem
                key={dataIngestionPath}
                icon={<DataUsageIcon className="Icon__default" />}
                text={t('drawer.main_menu.data_ingestion')}
                isWithLabel
                path={dataIngestionPath}
                forceActive={ingestionPathList.includes(windowLocationPath)}
                onClick={() => sideMenuStateHandler('dataIngestionManagement', true)}
              />,
              <QboDrawerMenuItem
                style={{ paddingRight: '0px' }}
                key={bulkPurgePath}
                icon={<DeleteForeverIcon className="Icon__default" />}
                text="Purge"
                isWithLabel
                path={getActiveFlagsList(purgeMenuObjects)[0]?.path}
                forceActive={purgePathList.includes(windowLocationPath)}
                onClick={() => {
                  sideMenuStateHandler('purgeManagement', !purgeSublink);
                  dispatch(setPurgeSublink({ open: !purgeSublink, handleOnPurgeSublink: true }));
                }}
                isSublinkOpen={purgeSublink}>
                <Collapse in={purgeSublink} timeout="auto" unmountOnExit>
                  {purgeMenuObjects?.map((menu) => subLink(menu, 'QboDrawerMenuItemSublinks'))}
                </Collapse>
              </QboDrawerMenuItem>,

              <QboDrawerMenuItem
                style={{ paddingRight: '0px' }}
                key={seatUsageReportsPagePath}
                icon={<BarChartOutlined className="Icon__default" />}
                text={t('drawer.main_menu.seat_usage_report')}
                isWithLabel
                path={getActiveFlagsList(menuReportsObject)[0]?.path}
                forceActive={reportsPathList.includes(windowLocationPath)}
                onClick={() => {
                  sideMenuStateHandler('reportsManagement', !seatUsageSublink);
                  dispatch(setSeatUsageSublink({ open: !seatUsageSublink, handleOnSublink: true }));
                }}
                isSublinkOpen={seatUsageSublink}>
                <Collapse in={seatUsageSublink} timeout="auto" unmountOnExit>
                  {menuReportsObject?.map((menu) => subLink(menu, 'QboDrawerMenuItemSublinks'))}
                </Collapse>
              </QboDrawerMenuItem>,

              <QboDrawerMenuItem
                style={{ paddingRight: '0px' }}
                key={mailBoxChangePath}
                icon={<HomeRepairServiceOutlinedIcon className="Icon__default" />}
                text="Tools"
                isWithLabel
                path={getActiveFlagsList(toolsObjects)[0]?.path}
                forceActive={toolsPathList.includes(windowLocationPath)}
                onClick={() => {
                  sideMenuStateHandler('toolsManagement', !toolLink);
                  dispatch(setToolLink({ open: !toolLink }));
                }}
                isSublinkOpen={toolLink}>
                <Collapse in={toolLink} timeout="auto" unmountOnExit>
                  {toolsObjects?.map((menu) => subLink(menu, 'QboDrawerMenuItemSublinks'))}
                </Collapse>
              </QboDrawerMenuItem>,

              <QboDrawerMenuItem
                style={{ paddingRight: '0px' }}
                key={showPlansListPath}
                icon={<AccountBalanceOutlinedIcon className="Icon__default" />}
                text="Billing"
                isWithLabel
                path={getActiveFlagsList(billingMenuObjects)[0]?.path}
                forceActive={plansListpath.includes(windowLocationPath)}
                onClick={() => {
                  sideMenuStateHandler('billingManagement', !billingLink);
                  dispatch(setBillingLink({ open: !billingLink }));
                }}
                isSublinkOpen={billingLink}>
                <Collapse in={billingLink} timeout="auto" unmountOnExit>
                  {billingMenuObjects?.map((menu) => subLink(menu, 'QboDrawerMenuItemSublinks'))}
                </Collapse>
              </QboDrawerMenuItem>,

              <QboDrawerMenuItem
                key={toolsRequestStatusPath}
                icon={<HistoryOutlined className="Icon__default" />}
                text={t('drawer.main_menu.tools_request_status')}
                isWithLabel
                loading={flagsState.length === 0}
                path={toolsRequestStatusPath}
                forceActive={windowLocationPath === toolsRequestStatusPath}
                onClick={() => sideMenuStateHandler('toolsRequestHistory', true)}
              />,
            ].filter(
              (menuBlock) =>
                flagsState?.find((item) => item?.path === menuBlock?.props?.path)?.isActive
            )}
          </>
        )}
      </ListItem>
    </List>
  );
}

MenuBlock.propTypes = {
  drawerOpen: PropTypes.bool,
  flagState: PropTypes.object,
  setFlagState: PropTypes.func,
};

MenuBlock.defaultProps = {
  drawerOpen: true,
  flagState: {},
  setFlagState: () => {},
};
