import React from 'react';
import MuiAppBar from '@mui/material/AppBar';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Box, Container, Toolbar } from '@mui/material';
import MenuRightBlock from '@ui/Blocks/Shared/NavbarBlocks/MenuRightBlock';
import MenuLeftBlock from '@ui/Blocks/Shared/NavbarBlocks/MenuLeftBlock';
import DropsuiteLogoSvg from '@images/logos/dropsuite-logo.svg';
import { useSelector } from 'react-redux';

export default function NavbarSection({ drawerOpen, drawerWidth, className }) {
  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const { getSilentTokenState } = useSelector((stateList) => stateList.silent);
  const { errorMessage } = getSilentTokenState;

  return (
    <AppBar
      className={`Navbar__default ${className}`}
      position="fixed"
      open={drawerOpen}
      sx={{
        zIndex: 1,
        paddingLeft: drawerOpen ? 0 : '55px',
      }}>
      <Container maxWidth="xlg" className="Box__main-with-padding">
        <Toolbar sx={{ paddingLeft: '0px !important', paddingRight: '0px !important' }}>
          <MenuLeftBlock logo={DropsuiteLogoSvg} />
          <Box sx={{ flexGrow: 1 }} />
          {!errorMessage && <MenuRightBlock />}
        </Toolbar>
      </Container>
    </AppBar>
  );
}

NavbarSection.propTypes = {
  drawerOpen: PropTypes.bool,
  drawerWidth: PropTypes.number,
  className: PropTypes.string,
};

NavbarSection.defaultProps = {
  drawerOpen: true,
  drawerWidth: 240,
  className: '',
};
