import { useDispatch } from 'react-redux';

import { getAutoDiscoveryAsync } from '@features/autoDiscovery/getAutoDiscoverySlice';

export default function useAutoDiscovery() {
  const dispatch = useDispatch();

  const getAutoDiscovery = async (paramsList) => {
    dispatch(getAutoDiscoveryAsync(paramsList));
  };

  const getAutoDiscoveryData = (data, setAutoDiscoveryData) => {
    const modifiedData = data?.map((item) => {
      const payloadObj = JSON.parse(item.payload);
      const modifiedPayloadObj = {
        'owner-id': payloadObj['org-owner-id'],
        'executed-on': item['executed-on'],
        'status-description': item['status-description'],
      };
      return modifiedPayloadObj;
    });
    return setAutoDiscoveryData(modifiedData);
  };

  const helperToConvertString = (data) => {
    const dataCovertList = data?.map((value) => ({
      domain: value?.domain,
      'mailbox-status': value['mailbox-status'] ? 'Enabled' : 'Disabled',
      'sharepoint-status': value['sharepoint-status'] ? 'Enabled' : 'Disabled',
      'ad-sync-status': value['ad-sync-status'] ? 'Enabled' : 'Disabled',
    }));

    return dataCovertList;
  };

  return { getAutoDiscovery, getAutoDiscoveryData, helperToConvertString };
}
