import { useDispatch, useSelector } from 'react-redux';
import { useMsal } from '@azure/msal-react';
import { getTokenSilentAsync } from '@features/auth/msalSlice';
import { constant } from '@config/BaseSetting';
import useLocalStorage from '@hooks/useLocalStorage';
import { getFeatureFlagAsync } from '@features/featureFlag/featureFlagSlice';
import useAccessToken from './useAccessToken';
import useRegionsList from './useRegionsList';

export default function useSilentToken() {
  const { instance, accounts } = useMsal();
  const dispatch = useDispatch();
  const { getAccessToken } = useAccessToken();
  const { getRegionsList } = useRegionsList();

  const [token, setToken] = useLocalStorage(constant.TOKEN_STORAGE);
  const [value] = useLocalStorage(constant.REGIONS_LIST);

  const { getAccessTokenState } = useSelector((state) => state.tokenAccess);
  const { getSilentTokenState } = useSelector((state) => state.silent);
  const { getRegions } = useSelector((state) => state.region);
  const { getFeatureFlagState } = useSelector((state) => state.flags);
  const { fetching } = getAccessTokenState;
  const { featureFlagList } = getFeatureFlagState;

  const getSilentToken = async () => {
    if (instance.initialize && accounts?.length > 0 && !getSilentTokenState?.fetching) {
      const silentToken = await dispatch(getTokenSilentAsync({ instance, accounts }));
      setToken(silentToken?.payload?.accessToken);

      let tokenState = '';
      if (!fetching && silentToken?.payload?.accessToken) {
        tokenState = await getAccessToken({
          accessToken: silentToken?.payload?.accessToken,
          reset: true,
        });
      }

      if (!fetching && tokenState?.payload?.data?.accessToken && !getRegions?.fetching) {
        await getRegionsList(tokenState?.payload?.data?.accessToken);
      }

      if (
        !getFeatureFlagState?.fetching &&
        featureFlagList?.length === 0 &&
        tokenState?.payload?.data?.accessToken
      )
        await dispatch(
          getFeatureFlagAsync({ accessToken: tokenState?.payload?.data?.accessToken, reset: true })
        );
    }
  };

  return { getSilentToken };
}
