import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IconButton, Pagination } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

import BaseSetting, { constant } from '@config/BaseSetting';
import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import { ITBox, QboAlert, QboButton, QboCard, QboModal, QboSimpleDataTable } from '@ui/Components';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import ViewExcludedMailboxFormSection from '@ui/Sections/ViewExcludedMailboxPage/ViewExcludedMailboxFormSection';
import { resetCreateExcludedMailboxesState } from '@features/excludedMailboxes/excludedMailboxesSlice';
import useLocalStorage from '@hooks/useLocalStorage';
import useForm from './pageHooks/useForm';
import useExcludedMailboxes from './pageHooks/useExcludedMailboxes';

export default function ViewExcludedMailBoxPage() {
  const [page, setPage] = useState(1);
  const {
    organisationIdAttributes,
    emailAttributes,
    onSubmit,
    resetForm,
    isValid,
    formData,
    previousPropsRef,
  } = useForm();
  const [showModal, setShowModal] = useState(false);
  const [noStatusFlag, setNoStatusFlag] = useState(false);
  const [excludedData, setExcludedData] = useState([]);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { getExcludedMailboxes, getExcludedMailboxesRequestData } = useExcludedMailboxes();
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const [regionValue] = useLocalStorage(constant.REGIONS_LIST);

  const { getExcludedMailboxesState } = useSelector((state) => state.excludedMail);
  const {
    fetching,
    excludedMailboxesList,
    paginationList,
    success,
    forbiddenState,
    forbiddenErrorMessage,
    excludedCorsErrorMessage,
  } = getExcludedMailboxesState;

  const handleChange = (e, value) => {
    setPage(value);
  };

  useEffect(() => {
    if (!fetching) {
      getExcludedMailboxes({ accessToken: accessTokenSet, reset: true, pageNumber: page });
    }
  }, [page]);

  useEffect(() => {
    getExcludedMailboxesRequestData(excludedMailboxesList, setExcludedData);
  }, [excludedMailboxesList?.length, page]);

  const handleRefreshMailboxes = () => {
    if (!fetching) {
      getExcludedMailboxes({ accessToken: accessTokenSet, reset: true, pageNumber: page });
    }
  };

  useEffect(() => {
    if (!success || excludedData?.length === 0) {
      setNoStatusFlag(true);
    } else if (success && excludedData?.length !== 0) {
      setNoStatusFlag(false);
    }
  }, [success, excludedData?.length]);

  return (
    <>
      <HeaderPageBlock>{t('view_excluded_mailbox_page.header.text')}</HeaderPageBlock>
      {excludedCorsErrorMessage && (
        <QboAlert type="error" style={{ fontSize: '1.15em' }}>
          {excludedCorsErrorMessage}
        </QboAlert>
      )}
      {!fetching && (
        <QboCard>
          {!forbiddenState && (
            <ITBox className="SystemStatusSection__wrapper" direction="column">
              <ITBox className="SystemStatusSection__create-button">
                <IconButton
                  sx={{
                    position: 'relative',
                    right: '0.7rem',
                  }}
                  onClick={handleRefreshMailboxes}>
                  <RefreshIcon
                    sx={{
                      width: '2rem',
                      height: '2rem',
                    }}
                  />
                </IconButton>
                &nbsp;
                <QboButton variant="contained" onClick={() => setShowModal(true)}>
                  {t('mailbox_change.new_request')}
                </QboButton>
              </ITBox>
            </ITBox>
          )}

          <QboCard noPadding isMainWrapper>
            {success && excludedData?.length > 0 && regionValue && !forbiddenState && (
              <>
                <QboSimpleDataTable
                  sx={{ width: '100%' }}
                  rows={excludedData}
                  loading={fetching}
                  header={BaseSetting.viewExcludedMailboxTableHeader}
                  onClickRow={(row) => {}}
                />
                {paginationList?.TotalPages && (
                  <Pagination
                    style={{ display: 'flex', justifyContent: 'end', marginTop: '1em  ' }}
                    count={Number(paginationList?.TotalPages)}
                    page={page}
                    onChange={handleChange}
                  />
                )}
              </>
            )}
            {(noStatusFlag || !regionValue || forbiddenState) && (
              <NoStatusSection forbiddenErrorMessage={forbiddenErrorMessage} />
            )}
          </QboCard>
          <QboModal
            onClose={() => {
              setShowModal(false);
              resetForm();
              dispatch(resetCreateExcludedMailboxesState());
              handleRefreshMailboxes();
              previousPropsRef.current = {};
            }}
            open={showModal}
            title={t('view_excluded_mailbox_page.modal.title')}
            showCloseIcon
            classNameStyle="view_excluded_mailbox_modal"
            preventOutsideClick>
            <ViewExcludedMailboxFormSection
              organisationIdAttributes={organisationIdAttributes}
              emailAttributes={emailAttributes}
              onSubmit={onSubmit}
              resetForm={resetForm}
              formData={formData}
              previousPropsRef={previousPropsRef}
              isValid={isValid}
            />
          </QboModal>
        </QboCard>
      )}
    </>
  );
}
