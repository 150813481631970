import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IconButton, Pagination, FormControl, MenuItem, TextField, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import RefreshIcon from '@mui/icons-material/Refresh';
import BaseSetting, { constant } from '@config/BaseSetting';
import PropTypes from 'prop-types';
import {
  ITBox,
  QboAlert,
  QboButton,
  QboCard,
  QboCheckbox,
  QboFormGroup,
  QboModal,
  QboSimpleDataTable,
  QboTextField,
} from '@ui/Components';
import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import useLocalStorage from '@hooks/useLocalStorage';
import useForm from './pageHooks/useForm';

export default function MailboxChange(props) {
  const {
    page,
    refreshHandler,
    mailboxData,
    handleChangePage,
    pagesSet,
    setShowModal,
    showModal,
    callApiHandler,
    statusCode,
    errorMessage,
    resetStatusCode,
    fetching,
    success,
    pageNum,
    isForbiddenOccured,
    forbiddenErrorMessage,
    corsErrorMessage,
  } = props;
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [noStatusFlag, setNoStatusFlag] = useState(false);
  const [selectErrorMessage, setSelectErrorMessage] = useState('');
  const [value] = useLocalStorage(constant.REGIONS_LIST);
  const isAstericRequired = true;

  const addOption = {
    addInDifferentOrganization: 1,
    changeEmail: 2,
  };

  const {
    supportTicketAttributes,
    escalationTicketAttributes,
    descriptionAttributes,
    organizationOwnerIdAttributes,
    currentEmailAttributes,
    newEmailAttributes,
    changeRequestTypeAttributes,
    onSubmit,
    resetForm,
  } = useForm(page);

  useEffect(() => {
    if (statusCode === 200 || statusCode === 403) {
      resetForm();
    }
  }, [errorMessage, statusCode]);

  useEffect(() => {
    if (changeRequestTypeAttributes.value) {
      setSelectErrorMessage('Backup will not run anymore after the change.');
    } else if (!changeRequestTypeAttributes.value) {
      setSelectErrorMessage('Backup will still run for M365 but will be stopped for GWS.');
    }
  }, [changeRequestTypeAttributes.value]);

  useEffect(() => {
    if (!success || mailboxData?.length === 0) {
      setNoStatusFlag(true);
    } else if (success && mailboxData?.length !== 0) {
      setNoStatusFlag(false);
    }
  }, [success, mailboxData?.length]);

  return (
    <>
      <HeaderPageBlock>{t(page)}</HeaderPageBlock>
      {corsErrorMessage && (
        <QboAlert type="error" style={{ fontSize: '1.15em' }}>
          {corsErrorMessage}
        </QboAlert>
      )}
      {!fetching && (
        <QboCard>
          {!isForbiddenOccured && (
            <ITBox className="SystemStatusSection__wrapper" direction="column">
              <ITBox className="SystemStatusSection__create-button">
                <IconButton
                  sx={{
                    position: 'relative',
                    right: '0.7rem',
                  }}
                  onClick={() => refreshHandler()}>
                  <RefreshIcon
                    sx={{
                      width: '2rem',
                      height: '2rem',
                    }}
                  />
                </IconButton>
                &nbsp;
                <QboButton variant="contained" onClick={() => setShowModal(true)}>
                  {t('mailbox_change.new_request')}
                </QboButton>
              </ITBox>
            </ITBox>
          )}

          <QboCard noPadding isMainWrapper>
            {success && mailboxData?.length > 0 && value && !isForbiddenOccured && (
              <>
                <QboSimpleDataTable
                  sx={{ width: '100%' }}
                  rows={mailboxData}
                  header={BaseSetting.tableHeader1}
                  loading={fetching}
                  onClickRow={(row) => {}}
                />{' '}
                {pagesSet?.TotalPages && (
                  <Pagination
                    style={{ display: 'flex', justifyContent: 'end', marginTop: '1em  ' }}
                    count={Number(pagesSet?.TotalPages)}
                    page={pageNum}
                    onChange={handleChangePage}
                  />
                )}
              </>
            )}
            {(noStatusFlag || !value || isForbiddenOccured) && !fetching && (
              <NoStatusSection forbiddenErrorMessage={forbiddenErrorMessage} />
            )}
            <QboModal
              onClose={() => {
                setShowModal(false);
                resetForm();
                dispatch(resetStatusCode());
                callApiHandler();
              }}
              open={showModal}
              title={t(page)}
              showCloseIcon
              classNameStyle="mail_modal"
              preventOutsideClick>
              <ITBox
                className="TransferSubscriptionFormPage__wrapper ITBox_wrapper_modal"
                direction="column">
                <QboFormGroup
                  onSubmit={onSubmit}
                  withSubmitButton
                  title={t('mailbox_change.new_request')}>
                  {(errorMessage || statusCode) && (
                    <QboAlert
                      type={statusCode === 200 ? 'success' : 'error'}
                      style={{ fontSize: '1.15em' }}>
                      {errorMessage || (statusCode === 200 && 'Successfully submitted')}
                    </QboAlert>
                  )}

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <QboTextField
                        id="supportTicket"
                        required={isAstericRequired}
                        placeholder={t('mailbox_change.labels.support_ticket')}
                        label={t('mailbox_change.labels.support_ticket')}
                        fullWidth
                        value={supportTicketAttributes.value}
                        errorMessage={supportTicketAttributes.errorMessage}
                        inputProps={{ maxLength: 225 }}
                        onChange={(e) => {
                          supportTicketAttributes.handleChange(e.currentTarget.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <QboTextField
                        id="escalationTicket"
                        placeholder={t('mailbox_change.labels.escalation_ticket')}
                        label={t('mailbox_change.labels.escalation_ticket')}
                        fullWidth
                        value={escalationTicketAttributes.value}
                        onChange={(e) =>
                          escalationTicketAttributes.handleChange(e.currentTarget.value)
                        }
                        inputProps={{ maxLength: 225 }}
                      />
                    </Grid>
                  </Grid>

                  <QboTextField
                    id="description"
                    placeholder={t('mailbox_change.labels.description')}
                    label={t('mailbox_change.labels.description')}
                    fullWidth
                    value={descriptionAttributes.value}
                    onChange={(e) => descriptionAttributes.handleChange(e.currentTarget.value)}
                    inputProps={{ maxLength: 225 }}
                  />

                  <QboTextField
                    id="organization-owner-id"
                    required={isAstericRequired}
                    placeholder={t('mailbox_change.labels.organization_owner_id')}
                    label={t('mailbox_change.labels.organization_owner_id')}
                    fullWidth
                    value={organizationOwnerIdAttributes.value}
                    errorMessage={organizationOwnerIdAttributes.errorMessage}
                    onChange={(e) =>
                      organizationOwnerIdAttributes.handleChange(e.currentTarget.value)
                    }
                    inputProps={{ maxLength: 225 }}
                  />

                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <QboTextField
                        id="current-email"
                        required={isAstericRequired}
                        placeholder={t('mailbox_change.labels.current_email')}
                        label={t('mailbox_change.labels.current_email')}
                        fullWidth
                        value={currentEmailAttributes.value}
                        errorMessage={currentEmailAttributes.errorMessage}
                        onChange={(e) => currentEmailAttributes.handleChange(e.currentTarget.value)}
                        inputProps={{ maxLength: 225 }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <QboTextField
                        id="new-email"
                        required={isAstericRequired}
                        placeholder={t('mailbox_change.labels.new_email')}
                        label={t('mailbox_change.labels.new_email')}
                        fullWidth
                        value={newEmailAttributes.value}
                        errorMessage={newEmailAttributes.errorMessage}
                        onChange={(e) => newEmailAttributes.handleChange(e.currentTarget.value)}
                        inputProps={{ maxLength: 225 }}
                      />
                    </Grid>
                  </Grid>

                  {page === 'Mailbox Change' && (
                    <FormControl className="FormControl__default-wrapper FormControl__full-width-wrapper">
                      <QboCheckbox
                        sx={{ fontSize: '12px !important' }}
                        className="QboCheckbox__wrapper_text"
                        label="Enable the customer to add the mailbox to another organization"
                        checked={changeRequestTypeAttributes.value}
                        onChange={(e) => {
                          changeRequestTypeAttributes.handleChange(e.target.checked);
                        }}
                      />
                      {selectErrorMessage && (
                        <span
                          style={{ height: '15px !important' }}
                          className="QboTextField__warning-text">
                          <WarningAmberIcon className="QboTextField__warning-icon" />
                          {selectErrorMessage}
                        </span>
                      )}
                    </FormControl>
                  )}
                </QboFormGroup>
              </ITBox>
            </QboModal>
          </QboCard>
        </QboCard>
      )}
    </>
  );
}

MailboxChange.propTypes = {
  page: PropTypes.string,
  refreshHandler: PropTypes.func,
  mailboxData: PropTypes.array,
  handleChangePage: PropTypes.func,
  pagesSet: PropTypes.object,
  setShowModal: PropTypes.func,
  showModal: PropTypes.bool,
  callApiHandler: PropTypes.func,
  statusCode: PropTypes.number,
  errorMessage: PropTypes.string,
  resetStatusCode: PropTypes.func,
  fetching: PropTypes.bool,
  success: PropTypes.bool,
  pageNum: PropTypes.number,
  isForbiddenOccured: PropTypes.bool,
  forbiddenErrorMessage: PropTypes.object,
  corsErrorMessage: PropTypes.string,
};

MailboxChange.defaultProps = {
  page: null,
  refreshHandler: null,
  mailboxData: null,
  handleChangePage: null,
  setShowModal: null,
  showModal: null,
  callApiHandler: null,
  statusCode: null,
  errorMessage: null,
  resetStatusCode: null,
  fetching: false,
  success: false,
  pagesSet: {},
  pageNum: 1,
  isForbiddenOccured: false,
  forbiddenErrorMessage: {},
  corsErrorMessage: null,
};
