import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useStepper from '@pages/sharedHooks/useStepper';
import { resetCredentialsSPAinfoState } from '@features/swicthSPAType/credentilasSPAinfoSlice';
import {
  resetSwitchSPATypeState,
  resetUpdateSPATypeState,
} from '@features/swicthSPAType/switchSPATypeSlice';
import useFormValidation from './useFormValidation';
import useSwitchSPACommand from './useSwitchSPACommand';

export default function useForm() {
  const {
    supportTicketAttributes,
    escalationTicketAttributes,
    descriptionAttributes,
    organizationOwnerIdAttributes,
    credentialsIdAttributes,
    resetForm,
    isValid,
    formData,
    validateAll,
  } = useFormValidation();

  const { t } = useTranslation();
  const { activeStep, isStepOptional, isStepSkipped, handleNext, handleBack, handleReset } =
    useStepper({});
  const dispatch = useDispatch();
  const { credentialsSPAinfoState } = useSelector((state) => state.credentialsSPAinfoState);
  const {
    success: successValidate,
    error: errorValidate,
    submitting: submittingValidate,
    data,
    validateErrorMessage,
  } = credentialsSPAinfoState;
  const { updateSPATypeState } = useSelector((state) => state.switchSPATypeState);
  const { success, error, submitting } = updateSPATypeState;

  const [showModal, setShowModal] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [nonSPAError, setNonSPAError] = useState('');

  const handleResetForm = () => {
    handleReset();
    resetForm();
  };

  const onOpenModal = () => {
    setShowModal(true);
    dispatch(resetUpdateSPATypeState());
  };

  const onCloseModal = () => {
    setShowModal(false);
    dispatch(resetCredentialsSPAinfoState());
    handleResetForm();
    setIsValidated(false);
  };

  const {
    handleCredentilasSPAinfoAsync,
    handleUpdateSPATypeAsync,
    handleSwitchSPACommandListAsync,
  } = useSwitchSPACommand();

  const payload = {
    'support-ticket': formData?.supportTicket,
    'escalation-ticket': formData?.escalationTicket,
    description: formData?.description,
    'org-owner-id': formData?.organizationOwnerId,
    'credentials-id': formData?.credentialsId,
    'spa-type': data['spa-type'] === 1 ? 0 : 1,
  };

  const apiSubmitHandler = () => {
    handleUpdateSPATypeAsync(payload);
  };

  const apiValidationHandler = () => {
    if (isValid) {
      handleCredentilasSPAinfoAsync({
        'org-owner-id': formData?.organizationOwnerId,
        'credentials-id': formData?.credentialsId,
      });
    }
  };

  const apiFetchHandler = () => {
    handleSwitchSPACommandListAsync(1, 10);
  };

  useEffect(() => {
    setNonSPAError(null);
    if (successValidate && isValidated) {
      if (data['use-backup-admin'] === true) {
        setNonSPAError(t('switch_spa_page.confirmation.non-spa'));
      } else {
        handleNext();
      }
    }
  }, [successValidate, errorValidate]);

  useEffect(() => {
    return () => {
      apiFetchHandler();
    };
  }, [success, error]);

  const onValidation = async (e) => {
    e.preventDefault();
    const validate = await validateAll();
    if (validate) {
      setIsValidated(true);
      dispatch(resetCredentialsSPAinfoState());
      apiValidationHandler();
    }
  };

  const onBack = () => {
    dispatch(resetCredentialsSPAinfoState());
    handleBack();
    setIsValidated(false);
  };

  const onValidateData = (e) => {
    e.preventDefault();
    validateAll();
  };

  const onSubmit = () => {
    dispatch(resetSwitchSPATypeState());
    apiSubmitHandler();
    onCloseModal();
  };

  return {
    supportTicketAttributes,
    escalationTicketAttributes,
    descriptionAttributes,
    credentialsIdAttributes,
    organizationOwnerIdAttributes,
    isValid,
    resetForm,
    formData,
    onSubmit,
    onValidateData,
    onValidation,
    onBack,
    activeStep,
    isStepOptional,
    isStepSkipped,
    loading: submittingValidate || submitting,
    credentialsInfo: data,
    onOpenModal,
    onCloseModal,
    showModal,
    errorValidation: validateErrorMessage || nonSPAError,
    submitted: success,
  };
}
