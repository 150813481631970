import React from 'react';
import PropTypes from 'prop-types';
import { ITBox, QboAlert, QboLabelGroup, QboTypography } from '@ui/Components';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

const accountTypeMapping = {
  1: 'Partner User',
  2: 'Organization Owner',
};

export default function ToggleEnforceSSOConfirmation({ selectedAccount }) {
  if (!selectedAccount) return null;

  const { t } = useTranslation();
  const [current, next] =
    selectedAccount.sso === 'Disabled' ? ['Disabled', 'Enabled'] : ['Enabled', 'Disabled'];

  return (
    <>
      <QboAlert
        variant="outlined"
        type="info"
        style={{
          border: '1px solid #ebebeb',
          background: '#f3f3f3',
          fontSize: '10px !important',
          marginTop: '20px',
        }}>
        <QboTypography
          noWrap={false}
          style={{ fontSize: '13px', color: '#767676', whiteSpace: 'pre-line' }}>
          {t('user_page.toggle_enforce_sso.information.current', { current })}
          <strong>{t('user_page.toggle_enforce_sso.information.next', { next })}</strong>
        </QboTypography>
      </QboAlert>
      <ITBox
        className="TransferSubresellerPage__information-box"
        direction="column"
        sx={{ marginBottom: '20px' }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <QboLabelGroup
              title="Account ID"
              description={selectedAccount?.id?.toString() || '-'}
            />
          </Grid>
          <Grid item xs={6}>
            <QboLabelGroup
              title="Type"
              description={accountTypeMapping[selectedAccount?.type] || '-'}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <QboLabelGroup title="Name" description={selectedAccount?.name?.toString() || '-'} />
          </Grid>
          <Grid item xs={6}>
            <QboLabelGroup title="Email" description={selectedAccount?.email?.toString() || '-'} />
          </Grid>
        </Grid>

        {(!!selectedAccount?.organizationOwnerId || !!selectedAccount?.organizationName) && (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <QboLabelGroup
                title="Organization Owner ID"
                description={selectedAccount?.organizationOwnerId?.toString() || '-'}
              />
            </Grid>
            <Grid item xs={6}>
              <QboLabelGroup
                title="Organization Name"
                description={selectedAccount?.organizationName || '-'}
              />
            </Grid>
          </Grid>
        )}

        {(!!selectedAccount?.partnerId || !!selectedAccount?.partnerName) && (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <QboLabelGroup
                title="Partner ID"
                description={selectedAccount?.partnerId?.toString() || '-'}
              />
            </Grid>
            <Grid item xs={6}>
              <QboLabelGroup
                title="Partner Organization Name"
                description={selectedAccount?.partnerName?.toString() || '-'}
              />
            </Grid>
          </Grid>
        )}
      </ITBox>
    </>
  );
}

ToggleEnforceSSOConfirmation.propTypes = {
  selectedAccount: PropTypes.object,
};

ToggleEnforceSSOConfirmation.defaultProps = {
  selectedAccount: null,
};
