import { useState } from 'react';
import * as yup from 'yup';

const orgOwnerIdName = 'orgOwnerId';
const descriptionName = 'description';
const mappingFileName = 'mappingFile';
const supportTicketName = 'supportTicket';
const escalationTicketName = 'escalationTicket';
const emailIdsName = 'emailIds';
const changeRequestTypeName = 'changeRequestType';
const fromCredentialIdName = 'fromCredentialId';
const toCredentialIdName = 'toCredentialId';

const InitialForm = {
  [orgOwnerIdName]: '',
  [descriptionName]: '',
  [mappingFileName]: '',
  [supportTicketName]: '',
  [escalationTicketName]: '',
  [emailIdsName]: '',
  [changeRequestTypeName]: '1',
  [fromCredentialIdName]: '',
  [toCredentialIdName]: '',
};

export default function useFormValidation() {
  const [isValid, setIsValid] = useState(false);
  const [errors, setErrors] = useState('');
  const [formData, setFormData] = useState(InitialForm);

  const formSchema = yup.object().shape({
    [orgOwnerIdName]: yup
      .number()
      .typeError('Organization Owner ID is a required field and must be a number type')
      .required(),
    [mappingFileName]: yup
      .mixed()
      .required()
      .test('isValidType', 'Not a valid type', (value) =>
        value ? ['txt', 'csv'].indexOf(value?.name?.split('.')?.pop()) > -1 : true
      ),
    [supportTicketName]: yup.string().required('Support Ticket is required field'),
    [emailIdsName]: yup.string().required('Emails is a required field'),
    [fromCredentialIdName]: yup.string().when(changeRequestTypeName, {
      is: (v) => v !== '1',
      then: () => yup.string().required('From Credential ID is required'),
      otherwise: () => yup.string().notRequired(),
    }),
    [toCredentialIdName]: yup.string().required('Target Credential ID is a required field'),
  });

  const validate = async (value) => {
    await formSchema.validate(value, { abortEarly: false }).catch(function (err) {
      const errorLogs = err.inner.reduce((acc, error) => {
        return {
          ...acc,
          [error.path]: error.message,
        };
      }, {});
      setErrors(errorLogs);
      return errorLogs;
    });
    const validation = await formSchema.isValid(value);
    if (validation) {
      setErrors({});
    }
    setIsValid(validation);
    return validation;
  };

  const validateAll = () => {
    formData.emailIds = formData.emailIds
      .split(',')
      .filter((item) => item.trim())
      .toString();
    validate(formData);
  };

  const updateFormData = (newData) => {
    setFormData((prev) => {
      return { ...prev, ...newData };
    });
  };

  const orgOwnerIdAttributes = {
    handleChange: async (value) => {
      updateFormData({ [orgOwnerIdName]: value });
    },
    errorMessage: errors[orgOwnerIdName],
    value: formData[orgOwnerIdName],
  };

  const handleFileWrite = (value) => {
    if (value) {
      updateFormData({ [mappingFileName]: value });
    }
  };
  const mappingFileAttributes = {
    handleChange: async (value) => {
      handleFileWrite(value);
    },
    errorMessage: errors[mappingFileName],
    value: formData[mappingFileName],
  };
  const supportTicketAttributes = {
    handleChange: async (value) => {
      updateFormData({ [supportTicketName]: value });
    },
    errorMessage: errors[supportTicketName],
    value: formData[supportTicketName],
  };
  const descriptionAttributes = {
    handleChange: async (value) => {
      updateFormData({ [descriptionName]: value });
    },
    value: formData[descriptionName],
  };
  const escalationTicketAttributes = {
    handleChange: async (value) => {
      updateFormData({ [escalationTicketName]: value });
    },
    value: formData[escalationTicketName],
  };
  const emailIdsAttributes = {
    handleChange: async (value) => {
      updateFormData({ [emailIdsName]: value });
    },
    value: formData[emailIdsName],
    errorMessage: errors[emailIdsName],
  };
  const changeRequestTypeAttributes = {
    handleChange: async (value) => {
      updateFormData({ [changeRequestTypeName]: value });
    },
    value: formData[changeRequestTypeName],
    errorMessage: errors[changeRequestTypeName],
  };
  const fromCredentialIdAttributes = {
    handleChange: async (value) => {
      updateFormData({ [fromCredentialIdName]: value });
    },
    value: formData[fromCredentialIdName],
    errorMessage: errors[fromCredentialIdName],
  };
  const toCredentialIdAttributes = {
    handleChange: async (value) => {
      updateFormData({ [toCredentialIdName]: value });
    },
    value: formData[toCredentialIdName],
    errorMessage: errors[toCredentialIdName],
  };

  const resetForm = () => {
    setFormData(InitialForm);
    setErrors({});
    setIsValid(false);
  };

  return {
    formData,
    orgOwnerIdAttributes,
    supportTicketAttributes,
    escalationTicketAttributes,
    mappingFileAttributes,
    emailIdsAttributes,
    descriptionAttributes,
    changeRequestTypeAttributes,
    fromCredentialIdAttributes,
    toCredentialIdAttributes,
    validateAll,
    isValid,
    validate,
    resetForm,
  };
}
