import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSeatUsageReportsSummaryAPI } from '@services/WebApiService';

const fetchSeatUsageReportsSummaryAsync = createAsyncThunk(
  'SeatUsage/seatUsageReportsSummary',
  async (paramsList) => {
    try {
      const data = await getSeatUsageReportsSummaryAPI(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = fetchSeatUsageReportsSummaryAsync;

const seatUsageReportsSummaryReducers = {
  [pending]: (state) => {
    state.seatUsageSummaryState.fetching = true;
  },
  [rejected]: (state) => {
    state.seatUsageSummaryState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.seatUsageSummaryState.fetching = false;

    if (action?.payload?.error) {
      state.seatUsageSummaryState.errorMessage = action.payload.error?.data.message;
      state.seatUsageSummaryState.summaryStatusCode = action.payload.error?.status;

      if (action?.payload?.error?.status === 403) {
        state.seatUsageSummaryState.forbiddenState = true;
        state.seatUsageSummaryState.forbiddenErrorMessage = {
          heading: 'Forbidden Error',
          caption: 'You are not authorize to access this resource',
        };
      }
    }

    if (!action?.payload?.error && !action?.payload?.data) {
      state.seatUsageSummaryState.errorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.data?.data) {
      state.seatUsageSummaryState.forbiddenState = false;
      state.seatUsageSummaryState.forbiddenErrorMessage = {};
      state.seatUsageSummaryState.summaryStatusCode = action.payload?.status;
      state.seatUsageSummaryState.seatUsageSummaryData = action?.payload?.data?.data;
    }
  },
};

export { fetchSeatUsageReportsSummaryAsync, seatUsageReportsSummaryReducers };

export default fetchSeatUsageReportsSummaryAsync;
