import { createAsyncThunk } from '@reduxjs/toolkit';
import { reRunDataIngestionListItemAPI } from '@services/WebApiService';

const reRunDataIngestionListItemAsync = createAsyncThunk(
  'Ingestion/ReRunIngestion',
  async (paramsList) => {
    try {
      const data = await reRunDataIngestionListItemAPI(paramsList);
      return data;
    } catch (error) {
      return error.response;
    }
  }
);

const { pending, fulfilled, rejected } = reRunDataIngestionListItemAsync;

const getReRunDataIngestionListItemExtraReducers = {
  [pending]: (state) => {
    state.getReRunDataIngestionListItemState.fetching = true;
  },
  [rejected]: (state) => {
    state.getReRunDataIngestionListItemState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.getReRunDataIngestionListItemState.fetching = false;

    if (action?.payload?.status) {
      state.getReRunDataIngestionListItemState.statusCode = action?.payload?.status;
      state.getReRunDataIngestionListItemState.success = true;
    }

    if (action?.payload?.data) {
      state.getReRunDataIngestionListItemState.reRunIngestionListResult =
        action?.payload?.data?.data;
      state.getReRunDataIngestionListItemState.success = true;
    }

    if (action?.payload?.error) {
      state.getReRunDataIngestionListItemState.error = action.payload.error;
    }
  },
};

export { reRunDataIngestionListItemAsync, getReRunDataIngestionListItemExtraReducers };
