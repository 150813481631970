import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { isEqual } from 'lodash';

import { constant } from '@config/BaseSetting';
import useLocalStorage from '@hooks/useLocalStorage';
import {
  createTenantAuthErrorsAsync,
  resetCreateTenantAuthErrorsState,
} from '@features/tenantAuthErrors/tenantAuthErrorsSlice';
import useFormValidation from './useFormValidation';

function useForm() {
  const { formData, organisationIdAttributes, validateAll, isValid, resetForm } =
    useFormValidation();
  const dispatch = useDispatch();
  const previousPropsRef = useRef(formData);
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);

  const submitTheForm = () => {
    if (isValid && !!formData['org-owner-id']) {
      dispatch(
        createTenantAuthErrorsAsync({
          accessToken: accessTokenSet,
          reset: true,
          parameters: formData,
        })
      );
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    validateAll();
    const previousProps = previousPropsRef.current;
    if (!isEqual(previousProps, formData)) {
      dispatch(resetCreateTenantAuthErrorsState());
      submitTheForm();
      previousPropsRef.current = formData;
    }
  };

  useEffect(() => {
    submitTheForm();
  }, [isValid]);

  useEffect(() => {
    if (!isValid) {
      dispatch(resetCreateTenantAuthErrorsState());
    }
  }, [isValid]);

  return {
    formData,
    organisationIdAttributes,
    validateAll,
    isValid,
    resetForm,
    onSubmit,
  };
}

export default useForm;
