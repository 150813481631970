import { createAsyncThunk } from '@reduxjs/toolkit';
import { createMailBoxChangeRequestAPI } from '@services/WebApiService';

const getMailBoxChangeAsync = createAsyncThunk('GetF/mailBoxChangeRequest', async (paramsList) => {
  try {
    const data = await createMailBoxChangeRequestAPI(paramsList);
    return data;
  } catch (error) {
    return error.response;
  }
});

const { pending, fulfilled, rejected } = getMailBoxChangeAsync;

const getMailboxChangeReducers = {
  [pending]: (state) => {
    state.mailChangeRequestState.fetching = true;
  },
  [rejected]: (state) => {
    state.mailChangeRequestState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.mailChangeRequestState.fetching = false;

    if (action?.payload?.data?.message) {
      state.mailChangeRequestState.errorMessage = action?.payload?.data?.message;
    }

    if (action?.payload?.status === 403) {
      state.mailChangeRequestState.forbiddenState = true;
      state.mailChangeRequestState.forbiddenErrorMessage = {
        heading: 'Forbidden Error',
        caption: 'You are not authorize to access this resource',
      };
    }

    if (action?.payload?.data?.data) {
      state.mailChangeRequestState.forbiddenState = false;
      state.mailChangeRequestState.forbiddenErrorMessage = {};
      state.mailChangeRequestState.data = action?.payload?.data?.data;
      state.mailChangeRequestState.success = true;
    }
    if (action?.payload?.data?.pagination) {
      state.mailChangeRequestState.pagination = JSON.parse(action?.payload?.data?.pagination);
    }

    if (!action?.payload) {
      state.mailChangeRequestState.mailChangeCorsErrorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.code) {
      state.mailChangeRequestState.error = action.payload.code;
    }
  },
};

export { getMailBoxChangeAsync, getMailboxChangeReducers };

export default getMailBoxChangeAsync;
