import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import { FormControl, MenuItem, TextField } from '@mui/material';
import {
  ITBox,
  QboAlert,
  QboCheckbox,
  QboFormGroup,
  QboSelect,
  QboTextField,
} from '@ui/Components';
import { resetIngestionFormSubmit } from '@features/ingestion/ingestionSlice';
import BaseSetting from '@config/BaseSetting';

export default function DataIngestionFormSection({
  organizationOwnerIdAttributes,
  ingestionTypeAttributes,
  catchAllEmailAccountAttributes,
  storageTypeAttributes,
  zipFilePasswordAttributes,
  mappingFileAttributes,
  ftpPasswordAttributes,
  ftpUrlAttributes,
  ftpUserNameAttributes,
  s3bucketnameAttributes,
  s3KeyAttributes,
  folderAttributes,
  supportTicketAttributes,
  escalationTicketAttributes,
  descriptionAttributes,
  retainFolderStructureAttributes,
  s3StorageRegionAttributes,
  onSubmit,
  formData,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { submitIngestionForm } = useSelector((state) => state.ingestionForm);
  const { errorMessage, statusCode } = submitIngestionForm;

  const storageType = {
    FTP: 1101,
    S3TYPE: 1102,
    PROSERV: 1103,
    PROSERV_S3: 1104,
  };

  const ingestionType = {
    archiver: 1,
    nonArchiver: 0,
    archiverTitle: 'Archiver',
    nonArchiverTitle: 'Non-Archiver',
  };
  const storageRegions = BaseSetting.s3StorageRegions;

  const inputRef = useRef(null);
  const isPasswordField = true;
  const isAstericRequired = true;

  const previousPropsRef = useRef(formData);

  useEffect(() => {
    if (!isEqual(previousPropsRef.current, formData)) {
      dispatch(resetIngestionFormSubmit());
    }
  }, [formData]);

  useEffect(() => {
    if (statusCode === 200 || statusCode === 403) {
      inputRef.current.value = null;
    }
  }, [statusCode]);

  return (
    <ITBox className="TransferSubscriptionFormPage__wrapper" direction="column">
      <QboFormGroup onSubmit={onSubmit} withSubmitButton>
        {(errorMessage || statusCode) && (
          <QboAlert type={statusCode === 200 ? 'success' : 'error'} style={{ fontSize: '1.15em' }}>
            {errorMessage || (statusCode === 200 && 'Successfully submitted')}
          </QboAlert>
        )}
        <QboTextField
          id="supportTicket"
          placeholder={t('mailbox_change.labels.support_ticket')}
          label={t('mailbox_change.labels.support_ticket')}
          fullWidth
          value={supportTicketAttributes.value}
          errorMessage={supportTicketAttributes.errorMessage}
          inputProps={{ maxLength: 225 }}
          onChange={(e) => {
            supportTicketAttributes.handleChange(e.currentTarget.value);
          }}
        />
        <QboTextField
          id="escalationTicket"
          placeholder={t('mailbox_change.labels.escalation_ticket')}
          label={t('mailbox_change.labels.escalation_ticket')}
          fullWidth
          value={escalationTicketAttributes.value}
          onChange={(e) => escalationTicketAttributes.handleChange(e.currentTarget.value)}
          inputProps={{ maxLength: 225 }}
        />
        <QboTextField
          id="description"
          placeholder={t('mailbox_change.labels.description')}
          label={t('mailbox_change.labels.description')}
          fullWidth
          value={descriptionAttributes.value}
          onChange={(e) => descriptionAttributes.handleChange(e.currentTarget.value)}
          inputProps={{ maxLength: 225 }}
        />
        <QboTextField
          id="accountId"
          required={isAstericRequired}
          placeholder={t('data_ingestion_page.modal.form.account_id')}
          label={t('data_ingestion_page.modal.form.account_id')}
          fullWidth
          inputProps={{ maxLength: 225 }}
          value={organizationOwnerIdAttributes.value}
          onChange={(e) => {
            organizationOwnerIdAttributes.handleChange(e.currentTarget.value);
          }}
          errorMessage={organizationOwnerIdAttributes.errorMessage}
        />
        <FormControl className="FormControl__default-wrapper FormControl__full-width-wrapper">
          <div className="QboTextField__label" id="storageType">
            {t('data_ingestion_page.modal.form.ingestion_type')}
          </div>
          <TextField
            value={ingestionTypeAttributes.value}
            select
            onChange={(e) => ingestionTypeAttributes.handleChange(e.target.value)}>
            {[ingestionType.nonArchiver, ingestionType.archiver].map((option) => (
              <MenuItem key={option} value={option}>
                {(option === ingestionType.archiver && ingestionType.archiverTitle) ||
                  (option === ingestionType.nonArchiver && ingestionType.nonArchiverTitle)}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
        {ingestionTypeAttributes.value === ingestionType.archiver && (
          <QboTextField
            id="catchAllEmailAccount"
            placeholder={t('data_ingestion_page.modal.form.catch_all_email_account')}
            label={t('data_ingestion_page.modal.form.catch_all_email_account')}
            fullWidth
            inputProps={{ maxLength: 225 }}
            value={catchAllEmailAccountAttributes.value}
            onChange={(e) => {
              catchAllEmailAccountAttributes.handleChange(e.currentTarget.value);
            }}
            errorMessage={catchAllEmailAccountAttributes.errorMessage}
          />
        )}
        <FormControl className="FormControl__default-wrapper FormControl__full-width-wrapper">
          <div className="QboTextField__label" id="storageType">
            {t('data_ingestion_page.modal.form.storage_type')}
          </div>
          <TextField
            value={storageTypeAttributes.value}
            select
            onChange={(e) => storageTypeAttributes.handleChange(e.target.value)}>
            {[storageType.FTP, storageType.S3TYPE, storageType.PROSERV, storageType.PROSERV_S3].map(
              (option) => (
                <MenuItem key={option} value={option}>
                  {(option === storageType.FTP && 'FTP') ||
                    (option === storageType.S3TYPE && 'S3TYPE') ||
                    (option === storageType.PROSERV && 'PROSERV') ||
                    (option === storageType.PROSERV_S3 && 'PROSERV S3')}
                </MenuItem>
              )
            )}
          </TextField>
        </FormControl>
        <QboTextField
          id="mappingFile"
          required={isAstericRequired}
          placeholder={t('data_ingestion_page.modal.form.mapping_file')}
          label={t('data_ingestion_page.modal.form.mapping_file')}
          type="file"
          inputRef={inputRef}
          fullWidth
          inputProps={{ maxLength: 225 }}
          value={mappingFileAttributes?.value?.file}
          onChange={(e) => {
            mappingFileAttributes.handleChange(e?.target?.files[0]);
          }}
          errorMessage={mappingFileAttributes.errorMessage}
        />
        <QboTextField
          id="zipFilePassword"
          isPasswordField={isPasswordField}
          placeholder={t('data_ingestion_page.modal.form.zip_file_password')}
          label={t('data_ingestion_page.modal.form.zip_file_password')}
          fullWidth
          inputProps={{ maxLength: 225 }}
          value={zipFilePasswordAttributes.value}
          onChange={(e) => {
            zipFilePasswordAttributes.handleChange(e.currentTarget.value);
          }}
          isEyeButton={false}
          errorMessage={zipFilePasswordAttributes.errorMessage}
        />
        <QboCheckbox
          label={t('data_ingestion_page.modal.form.retain_folder_structure')}
          checked={retainFolderStructureAttributes.value}
          onChange={(e) => {
            retainFolderStructureAttributes.handleChange(e.target.checked);
          }}
        />
        {storageTypeAttributes.value === storageType.FTP && (
          <>
            <QboTextField
              id="ftpUrl"
              required={isAstericRequired}
              placeholder={t('data_ingestion_page.modal.form.ftp_url')}
              label={t('data_ingestion_page.modal.form.ftp_url')}
              fullWidth
              inputProps={{ maxLength: 225 }}
              value={ftpUrlAttributes.value}
              onChange={(e) => {
                ftpUrlAttributes.handleChange(e.currentTarget.value);
              }}
              errorMessage={ftpUrlAttributes.errorMessage}
            />
            <QboTextField
              id="ftpUserName"
              required={isAstericRequired}
              placeholder={t('data_ingestion_page.modal.form.ftp_user_name')}
              label={t('data_ingestion_page.modal.form.ftp_user_name')}
              fullWidth
              inputProps={{ maxLength: 225 }}
              value={ftpUserNameAttributes.value}
              onChange={(e) => {
                ftpUserNameAttributes.handleChange(e.currentTarget.value);
              }}
              errorMessage={ftpUserNameAttributes.errorMessage}
            />
            <QboTextField
              id="ftpPassword"
              required={isAstericRequired}
              isPasswordField={isPasswordField}
              isEyeButton={false}
              placeholder={t('data_ingestion_page.modal.form.ftp_password')}
              label={t('data_ingestion_page.modal.form.ftp_password')}
              fullWidth
              inputProps={{ maxLength: 225 }}
              value={ftpPasswordAttributes.value}
              onChange={(e) => {
                ftpPasswordAttributes.handleChange(e.currentTarget.value);
              }}
              errorMessage={ftpPasswordAttributes.errorMessage}
            />{' '}
          </>
        )}
        {(storageTypeAttributes.value === storageType.S3TYPE ||
          storageTypeAttributes.value === storageType.PROSERV_S3) && (
          <>
            <QboTextField
              id="s3BucketName"
              required={isAstericRequired}
              placeholder={t('data_ingestion_page.modal.form.s3_bucket_name')}
              label={t('data_ingestion_page.modal.form.s3_bucket_name')}
              fullWidth
              inputProps={{ maxLength: 225 }}
              value={s3bucketnameAttributes.value}
              onChange={(e) => {
                s3bucketnameAttributes.handleChange(e.currentTarget.value);
              }}
              errorMessage={s3bucketnameAttributes.errorMessage}
            />
            <QboTextField
              id="s3Key"
              required={isAstericRequired}
              placeholder={t('data_ingestion_page.modal.form.s3_key')}
              label={t('data_ingestion_page.modal.form.s3_key')}
              fullWidth
              inputProps={{ maxLength: 225 }}
              value={s3KeyAttributes.value}
              onChange={(e) => {
                s3KeyAttributes.handleChange(e.currentTarget.value);
              }}
              errorMessage={s3KeyAttributes.errorMessage}
            />
            <QboSelect
              id="s3StorageRegion"
              label={t('data_ingestion_page.modal.form.s3_storage_region')}
              options={Object.keys(storageRegions).map((region) => ({
                id: storageRegions[region].value,
                label: storageRegions[region].label,
              }))}
              handleChange={(e) => s3StorageRegionAttributes.handleChange(e.target.value)}
              value={s3StorageRegionAttributes?.value}
              defaultValue=""
            />
          </>
        )}
        {storageTypeAttributes.value === storageType.PROSERV && (
          <QboTextField
            id="folder"
            placeholder={t('data_ingestion_page.modal.form.folder')}
            label={t('data_ingestion_page.modal.form.folder')}
            fullWidth
            inputProps={{ maxLength: 225 }}
            value={folderAttributes.value}
            onChange={(e) => {
              folderAttributes.handleChange(e.currentTarget.value);
            }}
            errorMessage={folderAttributes.errorMessage}
          />
        )}
      </QboFormGroup>
    </ITBox>
  );
}

DataIngestionFormSection.propTypes = {
  organizationOwnerIdAttributes: PropTypes.object,
  ingestionTypeAttributes: PropTypes.object,
  catchAllEmailAccountAttributes: PropTypes.object,
  storageTypeAttributes: PropTypes.object,
  mappingFileAttributes: PropTypes.object,
  zipFilePasswordAttributes: PropTypes.object,
  ftpPasswordAttributes: PropTypes.object,
  ftpUrlAttributes: PropTypes.object,
  ftpUserNameAttributes: PropTypes.object,
  s3bucketnameAttributes: PropTypes.object,
  s3KeyAttributes: PropTypes.object,
  folderAttributes: PropTypes.object,
  supportTicketAttributes: PropTypes.object,
  escalationTicketAttributes: PropTypes.object,
  descriptionAttributes: PropTypes.object,
  retainFolderStructureAttributes: PropTypes.object,
  s3StorageRegionAttributes: PropTypes.object,
  onSubmit: PropTypes.func,
  formData: PropTypes.object,
};

DataIngestionFormSection.defaultProps = {
  organizationOwnerIdAttributes: {},
  ingestionTypeAttributes: {},
  catchAllEmailAccountAttributes: {},
  storageTypeAttributes: {},
  mappingFileAttributes: {},
  zipFilePasswordAttributes: {},
  ftpPasswordAttributes: {},
  ftpUrlAttributes: {},
  ftpUserNameAttributes: {},
  s3bucketnameAttributes: {},
  s3KeyAttributes: {},
  folderAttributes: {},
  supportTicketAttributes: {},
  escalationTicketAttributes: {},
  descriptionAttributes: {},
  retainFolderStructureAttributes: {},
  s3StorageRegionAttributes: {},
  onSubmit: {},
  formData: {},
};
