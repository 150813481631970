import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
  ITBox,
  QboAlert,
  QboCard,
  QboCardCaption,
  QboFormGroup,
  QboSimpleDataTable,
  QboTextField,
  QboTypography,
} from '@ui/Components';
import NoStatus from '@images/generals/NoStatus.svg';
import BaseSetting from '@config/BaseSetting';

export default function ViewExcludedMailboxFormSection({
  isValid,
  organisationIdAttributes,
  emailAttributes,
  onSubmit,
  resetForm,
  formData,
  previousPropsRef,
}) {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');

  const { excludedMailboxesState } = useSelector((state) => state.excludedMailForm);
  const { statusCode, message, mailboxesList } = excludedMailboxesState;

  const excludeMailChangeHandler = (statusCodeState) => {
    return statusCodeState === 200 && statusCodeState
      ? previousPropsRef.current?.email && `${previousPropsRef.current?.email} is excluded`
      : previousPropsRef.current?.email && `${previousPropsRef.current?.email} is not excluded`;
  };

  useEffect(() => {
    if (formData?.email && formData?.email !== '') setEmail(formData.email);
  }, [formData]);

  const mailFilter = (excludeMailList, emailval) => {
    excludeMailList.filter((data) => {
      if (data.email === emailval) {
        setEmail(emailval);
      }
      return null;
    });
  };

  useEffect(() => {
    if (mailboxesList && mailboxesList?.length !== 0) {
      mailFilter(mailboxesList, email);
    }
  }, [mailboxesList?.length]);

  useEffect(() => {
    if (statusCode === 200 || statusCode === 403) {
      resetForm();
    }
  }, [statusCode, message]);

  return (
    <ITBox className="TransferSubscriptionFormPage__wrapper" direction="column">
      <QboFormGroup onSubmit={onSubmit} withSubmitButton>
        {(statusCode || message) && (
          <QboAlert
            type={
              statusCode === 200 && mailboxesList && mailboxesList?.length !== 0
                ? 'success'
                : 'error'
            }
            style={{ fontSize: '1.15em' }}>
            {message}
          </QboAlert>
        )}
        <QboTextField
          id="organisationID"
          required={Boolean(true)}
          placeholder={t('view_excluded_mailbox_page.form.organisation_id')}
          label={t('view_excluded_mailbox_page.form.organisation_id')}
          value={organisationIdAttributes.value}
          errorMessage={organisationIdAttributes.errorMessage}
          inputProps={{ maxLength: 225 }}
          onChange={(e) => {
            organisationIdAttributes.handleChange(e.currentTarget.value);
          }}
          fullWidth
        />
        <QboTextField
          id="email"
          placeholder={t('view_excluded_mailbox_page.form.email')}
          label={t('view_excluded_mailbox_page.form.email')}
          value={emailAttributes.value}
          errorMessage={emailAttributes.value ? emailAttributes.errorMessage : ''}
          inputProps={{ maxLength: 225 }}
          onChange={(e) => {
            emailAttributes.handleChange(e.currentTarget.value);
          }}
          fullWidth
        />
      </QboFormGroup>
      {mailboxesList?.length !== 0 && mailboxesList ? (
        <QboCard noPadding isMainWrapper sx={{ bgColor: 'blue' }}>
          <QboSimpleDataTable
            classNameHeaderStyle="view_excluded_mailbox_header"
            minWidth={false}
            rows={mailboxesList}
            className="view_excluded_mailbox"
            header={BaseSetting.viewExcludedMailboxFormTableHeader}
            onClickRow={(row) => {}}
          />
        </QboCard>
      ) : (
        statusCode &&
        message && (
          <QboCardCaption
            sx={{ width: '100%', height: '220px', border: '1px solid black' }}
            imageUrl={NoStatus}
            heading={t('system_status_section.no_status_section.no_record_text')}
          />
        )
      )}
      <br />
      {!!email && isValid && statusCode && (
        <QboTypography sx={{ fontWeight: 'bolder' }}>
          {excludeMailChangeHandler(statusCode)}
        </QboTypography>
      )}
    </ITBox>
  );
}

ViewExcludedMailboxFormSection.propTypes = {
  organisationIdAttributes: PropTypes.object,
  emailAttributes: PropTypes.object,
  onSubmit: PropTypes.func,
  resetForm: PropTypes.func,
  formData: PropTypes.object,
  previousPropsRef: PropTypes.object,
  isValid: PropTypes.bool,
};

ViewExcludedMailboxFormSection.defaultProps = {
  organisationIdAttributes: {},
  emailAttributes: {},
  onSubmit: () => {},
  resetForm: () => {},
  formData: {},
  previousPropsRef: {},
  isValid: false,
};
