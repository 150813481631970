import { createSlice } from '@reduxjs/toolkit';
import {
  mailboxChangeStatusReducers,
  fetchMailboxChangeStatus,
} from './serviceActions/fetchMailboxChangeStatusAsync';

const initialState = {
  mailboxChangeStatusState: {
    fetching: false,
    data: {},
    errorMessage: null,
    statusCode: null,
    forbiddenState: false,
    forbiddenErrorMessage: {},
    mailboxChangeStatusCorsErrorMessage: null,
  },
};

const mailboxChangeStatusSlice = createSlice({
  name: 'MailboxChangeStatus',
  initialState,
  reducers: {
    resetMailboxChangeStatus(state) {
      state.mailboxChangeStatusState = initialState.mailboxChangeStatusState;
    },
    resetMailboxChangeStatusState(state) {
      state.mailboxChangeStatusState.data = initialState.mailboxChangeStatusState.data;
    },
  },
  extraReducers: {
    ...mailboxChangeStatusReducers,
  },
});

export { fetchMailboxChangeStatus };
export const { resetMailboxChangeStatus, resetMailboxChangeStatusState } =
  mailboxChangeStatusSlice.actions;
export default mailboxChangeStatusSlice.reducer;
