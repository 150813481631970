import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSwitchCredentialStatusAPI } from '@services/WebApiService';

const fetchSwitchCredentialStatusAsync = createAsyncThunk(
  'fetchSwitchCredentialStatusAsync/SwitchCredentialStatus',
  async (paramsList) => {
    try {
      const data = await getSwitchCredentialStatusAPI(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = fetchSwitchCredentialStatusAsync;

const switchCredentialStatusReducers = {
  [pending]: (state) => {
    state.switchCredentialStatusState.fetching = true;
  },
  [rejected]: (state) => {
    state.switchCredentialStatusState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.switchCredentialStatusState.fetching = false;

    if (action?.payload?.error) {
      state.switchCredentialStatusState.errorMessage = action.payload.error?.data.message;
      state.switchCredentialStatusState.statusCode = action.payload.error?.status;

      if (action?.payload?.error?.status === 403) {
        state.switchCredentialStatusState.forbiddenState = true;
        state.switchCredentialStatusState.forbiddenErrorMessage = {
          heading: 'Forbidden Error',
          caption: 'You are not authorize to access this resource',
        };
      }
    }

    if (!action?.payload?.data && !action.payload?.error) {
      state.switchCredentialStatusState.switchCredentialStatusCorsErrorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.data) {
      state.switchCredentialStatusState.forbiddenState = false;
      state.switchCredentialStatusState.forbiddenErrorMessage = {};
      state.switchCredentialStatusState.statusCode = action.payload?.status;
      state.switchCredentialStatusState.data = action?.payload?.data;
    }
  },
};

export { fetchSwitchCredentialStatusAsync, switchCredentialStatusReducers };

export default fetchSwitchCredentialStatusAsync;
