import WebApiRoutes from '@config/Routes/WebApiRoutes';
import ServiceClientFactory from '@libs/ServiceClient';

const webClientApi = (accessToken) => {
  return new ServiceClientFactory({ baseUrl: WebApiRoutes.getBaseUrl(), accessToken }).create();
};

const TodosService = {
  fetchTodosApi: () => {
    return webClientApi().getRequest({
      url: WebApiRoutes.todosPath(),
    });
  },
};

export default TodosService;
