import { useState } from 'react';
import * as yup from 'yup';
import SchemaBuilder from '@libs/SchemaBuilder';
import { pascalToTitleCase } from '@utilities/stringUtils';

const supportTicketName = 'support-ticket';
const escalationTicketName = 'escalation-ticket';
const descriptionName = 'description';
const organizationOwnerIdName = 'org-owner-id';
const domainName = 'domain';

const initialForm = {
  [supportTicketName]: '',
  [escalationTicketName]: '',
  [descriptionName]: '',
  [organizationOwnerIdName]: '',
  [domainName]: '',
};

export default function useFormValidation() {
  const schemaBuilder = new SchemaBuilder({}, 'yup').create();

  const [isValid, setIsValid] = useState(false);
  const [errors, setErrors] = useState('');
  const [formData, setFormData] = useState(initialForm);
  const formSchema = yup.object().shape({
    [supportTicketName]: yup.string().required(),
    [escalationTicketName]: yup.string(),
    [descriptionName]: yup.string(),
    [organizationOwnerIdName]: yup.string().required('Organization Owner ID is required field'),
    [domainName]: yup.string().required(),
  });

  const validate = async (value) => {
    await formSchema.validate(value, { abortEarly: false }).catch(function (err) {
      const errorLogs = err.inner.reduce((acc, error) => {
        return {
          ...acc,
          [error.path]: pascalToTitleCase(error.message.split(',')[0]),
        };
      }, {});

      setErrors(errorLogs);
      return errorLogs;
    });

    const validation = await formSchema.isValid(value);
    if (validation) {
      setErrors({});
    }
    setIsValid(validation);
  };

  const validateAll = () => {
    validate(formData);
  };

  const updateFormData = (newData) => {
    setFormData((prev) => {
      return { ...prev, ...newData };
    });
  };

  const supportTicketAttributes = {
    handleChange: async (value) => {
      updateFormData({ [supportTicketName]: value });
    },
    errorMessage: errors[supportTicketName],
    value: formData[supportTicketName],
  };
  const escalationTicketAttributes = {
    handleChange: async (value) => {
      updateFormData({ [escalationTicketName]: value });
    },
    value: formData[escalationTicketName],
  };
  const descriptionAttributes = {
    handleChange: async (value) => {
      updateFormData({ [descriptionName]: value });
    },
    value: formData[descriptionName],
  };
  const organizationOwnerIdAttributes = {
    handleChange: async (value) => {
      updateFormData({ [organizationOwnerIdName]: value });
    },
    errorMessage: errors[organizationOwnerIdName],
    value: formData[organizationOwnerIdName],
  };
  const domainAttributes = {
    handleChange: async (value) => {
      updateFormData({ [domainName]: value });
    },
    errorMessage: errors[domainName],
    value: formData[domainName],
  };

  const resetForm = () => {
    setFormData(initialForm);
    setErrors({});
    setIsValid(false);
  };

  return {
    formData,
    supportTicketAttributes,
    escalationTicketAttributes,
    descriptionAttributes,
    organizationOwnerIdAttributes,
    domainAttributes,
    validateAll,
    isValid,
    resetForm,
  };
}
