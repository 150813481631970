import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';

import { ITBox, QboAlert, QboFormGroup, QboTextField } from '@ui/Components';
import { resetCreateDomainBackupsState } from '@features/domainBackups/createDomainBackupsSlice';

function DomainBackupsFormSection(props) {
  const {
    supportTicketAttributes,
    escalationTicketAttributes,
    organizationOwnerIdAttributes,
    descriptionAttributes,
    domainAttributes,
    onSubmit,
    resetForm,
    formData,
    isValid,
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { domainBackupsState } = useSelector((state) => state.domainFormState);
  const { status, message } = domainBackupsState;
  const isAstericRequired = true;

  const previousPropsRef = useRef(formData);

  useEffect(() => {
    if (!isEqual(previousPropsRef.current, formData)) {
      dispatch(resetCreateDomainBackupsState());
    }
  }, [formData]);

  useEffect(() => {
    if (status === 200) {
      resetForm();
    }
  }, [status]);

  return (
    <ITBox className="TransferSubscriptionFormPage__wrapper" direction="column">
      <QboFormGroup onSubmit={onSubmit} withSubmitButton>
        {(status || message) && (
          <QboAlert type={status === 200 ? 'success' : 'error'} style={{ fontSize: '1.15em' }}>
            {status === 200 ? 'Submitted Successfully' : message}
          </QboAlert>
        )}
        <QboTextField
          id="supportTicket"
          required={isAstericRequired}
          placeholder={t('domain_backups.labels.support_ticket')}
          label={t('domain_backups.labels.support_ticket')}
          fullWidth
          value={supportTicketAttributes.value}
          errorMessage={supportTicketAttributes.errorMessage}
          inputProps={{ maxLength: 225 }}
          onChange={(e) => {
            supportTicketAttributes.handleChange(e.currentTarget.value);
          }}
        />
        <QboTextField
          id="escalationTicket"
          placeholder={t('domain_backups.labels.escalation_ticket')}
          label={t('domain_backups.labels.escalation_ticket')}
          fullWidth
          value={escalationTicketAttributes.value}
          onChange={(e) => escalationTicketAttributes.handleChange(e.currentTarget.value)}
          inputProps={{ maxLength: 225 }}
        />
        <QboTextField
          id="description"
          placeholder={t('domain_backups.labels.description')}
          label={t('domain_backups.labels.description')}
          fullWidth
          value={descriptionAttributes.value}
          onChange={(e) => descriptionAttributes.handleChange(e.currentTarget.value)}
          inputProps={{ maxLength: 225 }}
        />
        <QboTextField
          id="organization-owner-id"
          required={isAstericRequired}
          placeholder={t('domain_backups.labels.organization_owner_id')}
          label={t('domain_backups.labels.organization_owner_id')}
          fullWidth
          value={organizationOwnerIdAttributes.value}
          errorMessage={organizationOwnerIdAttributes.errorMessage}
          onChange={(e) => organizationOwnerIdAttributes.handleChange(e.currentTarget.value)}
          inputProps={{ maxLength: 225 }}
        />
        <QboTextField
          id="current-email"
          required={isAstericRequired}
          placeholder={t('domain_backups.labels.domain')}
          label={t('domain_backups.labels.domain')}
          fullWidth
          value={domainAttributes.value}
          errorMessage={domainAttributes.errorMessage}
          onChange={(e) => domainAttributes.handleChange(e.currentTarget.value)}
          inputProps={{ maxLength: 225 }}
        />
      </QboFormGroup>
    </ITBox>
  );
}

DomainBackupsFormSection.propTypes = {
  supportTicketAttributes: PropTypes.object,
  escalationTicketAttributes: PropTypes.object,
  organizationOwnerIdAttributes: PropTypes.object,
  descriptionAttributes: PropTypes.object,
  domainAttributes: PropTypes.object,
  resetForm: PropTypes.func,
  formData: PropTypes.object,
  isValid: PropTypes.bool,
  onSubmit: PropTypes.func,
};

DomainBackupsFormSection.defaultProps = {
  supportTicketAttributes: {},
  escalationTicketAttributes: {},
  organizationOwnerIdAttributes: {},
  descriptionAttributes: {},
  domainAttributes: {},
  resetForm: () => {},
  formData: {},
  isValid: false,
  onSubmit: () => {},
};

export default DomainBackupsFormSection;
