import { createAsyncThunk } from '@reduxjs/toolkit';
import { getExcludedMailboxesAPI } from '@services/WebApiService';

const getExcludedMailboxesAsync = createAsyncThunk(
  'Mailboxes/getExcludedMailboxes',
  async (paramsList) => {
    try {
      const data = await getExcludedMailboxesAPI(paramsList);
      return data;
    } catch (error) {
      return error.response;
    }
  }
);

const { pending, fulfilled, rejected } = getExcludedMailboxesAsync;

const getExcludedMailboxesExtraReducers = {
  [pending]: (state) => {
    state.getExcludedMailboxesState.fetching = true;
  },
  [rejected]: (state) => {
    state.getExcludedMailboxesState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.getExcludedMailboxesState.fetching = false;

    if (action?.payload?.status === 403) {
      state.getExcludedMailboxesState.forbiddenState = true;
      state.getExcludedMailboxesState.forbiddenErrorMessage = {
        heading: 'Forbidden Error',
        caption: 'You are not authorize to access this resource',
      };
    }

    if (action?.payload?.data?.data) {
      state.getExcludedMailboxesState.excludedMailboxesList = action?.payload?.data?.data;
      state.getExcludedMailboxesState.paginationList = JSON.parse(
        action?.payload?.data?.pagination
      );
      state.getExcludedMailboxesState.success = true;
    }

    if (!action?.payload) {
      state.getExcludedMailboxesState.excludedCorsErrorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.error) {
      state.getExcludedMailboxesState.error = action.payload.error;
    }
  },
};

export { getExcludedMailboxesAsync, getExcludedMailboxesExtraReducers };
