export default function useDataService() {
  const toolsRequestHistoryHeaders = [
    {
      id: 1,
      label: 'ID',
      columnName: 'id',
      sx: { paddingLeft: 2 },
    },
    { id: 2, label: 'Support Ticket', columnName: 'support-ticket', sx: { paddingLeft: 2 } },
    { id: 3, label: 'Escalation Ticket', columnName: 'escalation-ticket', sx: { paddingLeft: 2 } },
    { id: 4, label: 'Description', columnName: 'description', sx: { paddingLeft: 2 } },
    { id: 5, label: 'Job Type', columnName: 'job-type', sx: { paddingLeft: 2 } },
    { id: 6, label: 'Service Type ID', columnName: 'service-type-id', sx: { paddingLeft: 2 } },
    { id: 7, label: 'Service Type', columnName: 'service-type', sx: { paddingLeft: 2 } },
    { id: 8, label: 'Executed On', columnName: 'executed-on', sx: { paddingLeft: 2 } },
    { id: 9, label: 'Status', columnName: 'status', sx: { paddingLeft: 2 } },
    {
      id: 10,
      label: 'Status Description',
      columnName: 'status-description',
      sx: { paddingLeft: 2 },
    },
    { id: 11, label: 'Details', columnName: 'details', sx: { paddingLeft: 2 } },
  ];

  return { toolsRequestHistoryHeaders };
}
