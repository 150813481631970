import { createAsyncThunk } from '@reduxjs/toolkit';
import { getTransferPartnerAPI } from '@services/WebApiService';

const getTransferPartnerAsync = createAsyncThunk(
  'transferPartner/gettransferPartners',
  async (paramsList) => {
    try {
      const data = await getTransferPartnerAPI(paramsList);
      return data;
    } catch (error) {
      return error.response;
    }
  }
);

const { pending, fulfilled, rejected } = getTransferPartnerAsync;

const getTransferPartnerExtraReducers = {
  [pending]: (state) => {
    state.getTransferPartnerState.fetching = true;
  },
  [rejected]: (state) => {
    state.getTransferPartnerState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.getTransferPartnerState.fetching = false;

    if (action?.payload?.status === 403) {
      state.getTransferPartnerState.forbiddenState = true;
      state.getTransferPartnerState.forbiddenErrorMessage = {
        heading: 'Forbidden Error',
        caption: 'You are not authorize to access this resource',
      };
    }

    if (!action?.payload) {
      state.getTransferPartnerState.transferPartnerCorsErrorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.data?.data) {
      state.getTransferPartnerState.transferPartnerList = action?.payload?.data?.data;
      state.getTransferPartnerState.paginationList = JSON.parse(action?.payload?.data?.pagination);
      state.getTransferPartnerState.success = true;
    }

    if (action?.payload?.error) {
      state.getTransferPartnerState.error = action.payload.error;
    }
  },
};

export { getTransferPartnerAsync, getTransferPartnerExtraReducers };
