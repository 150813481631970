import React, { useState } from 'react';
import { constant } from '@config/BaseSetting';
import {
  getM365UsersTenantAsync,
  resetM365UsersTenantState,
} from '@features/m365UsersTenant/m365UsersTenantSlice';
import { showFixedAlert } from '@features/application/applicationSlice';
import useLocalStorage from '@hooks/useLocalStorage';
import { useDispatch, useSelector } from 'react-redux';
import { MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useFeatureFlag from '@hooks/useFeatureFlag';

export default function useM365UsersTenantService(
  filterDataState,
  setFilterDataState,
  filterStates,
  pagination,
  adFilter,
  setAdFilter,
  initialFilterState,
  m365TenantCredentialID,
  setPagination,
  setOpen,
  setModuleData
) {
  const { featureFlags, hasPermission } = useFeatureFlag();
  const { t } = useTranslation();
  const [accessToken] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const { m365TenantDetailState } = useSelector((state) => state.application);
  const [selectedCredential, setSelectedCredential] = useState(null);
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [requestTenantSizeReportModal, setRequestTenantSizeReportModal] = useState(false);
  const dispatch = useDispatch();

  const handleFetchM365UsersTenantAsync = (flag, resetFlag = false) => {
    dispatch(
      getM365UsersTenantAsync({
        accessToken,
        reset: true,
        credentialId: m365TenantCredentialID,
        fullTextSearch: flag
          ? filterStates.searchFieldValueText
          : filterDataState.searchFieldValueText,
        pageNumber: flag ? 1 : pagination.pageIndex + 1,
        pageSize: flag ? 10 : pagination.pageSize,
        orderBy: flag ? filterDataState.selectedSortColumn : filterStates.selectedSortColumn,
        filter: resetFlag ? initialFilterState : adFilter,
      })
    );
  };

  const handleStateUpdate = (key, value) => {
    setFilterDataState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleUpdateFilters = (label, val) => {
    const newList = adFilter.map((ele) => {
      if (ele.field === label) {
        return { ...ele, value: val };
      }
      return ele;
    });
    setAdFilter([...newList]);
  };

  const handleOnResetButtonClick = () => {
    setFilterDataState((prevState) => ({
      ...prevState,
      textFieldValue: filterStates.textFieldValue,
      searchFieldValueText: filterStates.searchFieldValueText,
      searchFieldValueID: filterStates.searchFieldValueID,
      selectedSortColumn: filterStates.selectedSortColumn,
      matchText: filterStates.matchText,
      resetClicked: filterStates.resetClicked,
    }));
    setAdFilter(initialFilterState);
    setPagination({ pageIndex: 0, pageSize: 10 });

    dispatch(resetM365UsersTenantState());
    handleFetchM365UsersTenantAsync(true, true);
  };

  const handleOnTextFiledChange = (e) => {
    const input = e.target.value;
    setFilterDataState((prevState) => ({
      ...prevState,
      textFieldValue: input,
    }));
    if (/^\d+$/.test(input)) {
      setFilterDataState((prevState) => ({
        ...prevState,
        searchFieldValueID: input,
      }));
    } else {
      setFilterDataState((prevState) => ({
        ...prevState,
        searchFieldValueID: '',
        searchFieldValueText: input,
      }));
    }
  };

  const handleOnHideAdvanceClick = () => {
    setFilterDataState((prevState) => ({
      ...prevState,
      selectedSortColumn: filterStates.selectedSortColumn,
    }));
    setAdFilter(initialFilterState);
  };

  const matchTextHandler = () => {
    const { searchFieldValueID, searchFieldValueText } = filterDataState;

    let textData = 'Matching ';

    if (searchFieldValueText) {
      if (textData !== 'Matching ') {
        textData += ', ';
      }
      textData += ` User Name/Email/UPN/M365 ID = '${searchFieldValueText}' `;
    }

    if (searchFieldValueID) {
      if (textData !== 'Matching ') {
        textData += ', ';
      }
      textData += ` User Name/Email/UPN/M365 ID = '${searchFieldValueID}' `;
    }

    adFilter.forEach((filter) => {
      if (filter.value) {
        if (filter.field === 'filter_assignedLicenses') {
          textData += 'Assigned Licenses  = ';
        } else if (filter.field === 'filter_licensed') {
          textData += 'Has M365 License = ';
        } else if (filter.field === 'filter_active') {
          textData += 'Status = ';
        } else if (filter.field === 'filter_selected_partner') {
          textData += 'Partner Name = ';
        } else if (filter.field === 'filter_selected_organization') {
          textData += 'Organization Name = ';
        } else if (filter.field === 'filter_selected_parent') {
          textData += 'Parent Name = ';
        }
        textData += filter.value;
        textData += ', ';
      }
    });

    if (textData === 'Matching ') {
      textData = '';
    }
    textData = textData.replace(/,\s*$/, '');

    setFilterDataState((prevState) => ({
      ...prevState,
      matchText: textData,
    }));
  };

  const convertResponseData = (data) => {
    const modifiedData = data?.map((item) => {
      const modifiedPayloadObj = {
        ...item,
        licensed: String(item.licensed) || '',
      };
      return modifiedPayloadObj;
    });
    return modifiedData;
  };

  const columnClickHandler = (rowData) => {
    setModuleData(rowData);
    setOpen(true);
  };
  const handleOnClose = () => {
    setOpen(false);
  };

  const clicableColumnsList = [{ id: 1, column: 'licensed', handler: columnClickHandler }];

  const showTenantSizeReportModal = (row) => {
    const tenant = row.original;
    const credentials = m365TenantDetailState.row?.row?.original;

    if (credentials.deletedAt || credentials.isMain !== 'True') {
      dispatch(
        showFixedAlert({
          descriptionHtml: t('tenant_page.tenant_size_report.validations.main_and_active'),
          severity: 'error',
        })
      );
    } else {
      setRequestTenantSizeReportModal(true);
      setSelectedTenant(tenant);
      setSelectedCredential(credentials);
    }
  };

  const menuActions = ({ row, closeMenu }) => {
    const menus = [];

    if (hasPermission(featureFlags.TenantSizeReport)) {
      menus.push(
        <MenuItem
          key="1"
          onClick={() => {
            showTenantSizeReportModal(row);
            setTimeout(() => {
              closeMenu();
            }, 1000);
          }}>
          {t('tenant_page.tenant_size_report.title')}
        </MenuItem>
      );
    }

    return menus;
  };

  const hasMenuActions = hasPermission(featureFlags.TenantSizeReport);

  return {
    handleFetchM365UsersTenantAsync,
    handleStateUpdate,
    handleUpdateFilters,
    handleOnResetButtonClick,
    handleOnTextFiledChange,
    handleOnHideAdvanceClick,
    matchTextHandler,
    convertResponseData,
    clicableColumnsList,
    handleOnClose,
    menuActions,
    hasMenuActions,
    requestTenantSizeReportModal,
    setRequestTenantSizeReportModal,
    selectedCredential,
    selectedTenant,
  };
}
