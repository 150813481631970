import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Skeleton } from '@mui/material';

export default function QboCardListItem({
  leftContent,
  rightContent,
  children,
  className,
  loading,
  compact,
  isMainNeeded,
  ...rest
}) {
  const renderLeftContent = () => {
    if (!leftContent) return null;
    if (loading) {
      return (
        <Skeleton
          className="QboCardListItem__left-content"
          variant="circular"
          width={40}
          height={40}
        />
      );
    }

    return (
      <Grid sx={{ width: '40%' }} item className="QboCardListItem__left-content">
        {leftContent}
      </Grid>
    );
  };

  const renderRightContent = () => {
    if (!rightContent) return null;

    return (
      <Grid
        sx={{ flexGrow: 'unset' }}
        item
        // className="QboCardListItem__right-content"
      >
        {rightContent}
      </Grid>
    );
  };

  const renderMainContent = () => {
    return (
      <Grid item className="QboCardListItem__content">
        {loading && (
          <>
            <Skeleton variant="text" width="30%" />
            <Skeleton variant="text" width="10%" height={15} />
          </>
        )}

        {!loading && children}
      </Grid>
    );
  };

  const attributes = {
    ...(loading ? { justifyContent: 'center', alignItems: 'center' } : {}),
  };

  return (
    <Grid
      container
      className={`QboCardListItem__wrapper ${loading ? 'QboCardListItem__loading' : ''} ${
        compact ? 'QboCardListItem__compact-padding' : ''
      } ${className}`}
      {...attributes}
      {...rest}>
      {renderLeftContent()}
      {isMainNeeded && renderMainContent()}
      {renderRightContent()}
    </Grid>
  );
}

QboCardListItem.propTypes = {
  leftContent: PropTypes.node,
  rightContent: PropTypes.node,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  loading: PropTypes.bool,
  compact: PropTypes.bool,
  isMainNeeded: PropTypes.bool,
};

QboCardListItem.defaultProps = {
  leftContent: null,
  rightContent: null,
  className: '',
  children: '',
  loading: false,
  compact: false,
  isMainNeeded: false,
};
