import { useDispatch } from 'react-redux';
import { fetchSeatUsageReportsAsync } from '@features/seatUsageReport/serviceActions/fetchSeatUsageReportsAsync';
import {
  resetSeatUsageReportsErrorStatus,
  resetSeatUsageReportsListStatus,
} from '@features/seatUsageReport/seatUsageSlice';
import {
  resetSeatUsageSummaryErrorStatus,
  resetSeatUsageSummaryListStatus,
  fetchSeatUsageReportsSummaryAsync,
} from '@features/seatUsageReport/seatUsageSummarySlice';
import {
  exportSeatUsageReportAsync,
  resetExportSeatUsageReportStatus,
} from '@features/seatUsageReport/exportSeatUsageSlice';
import useLocalStorage from '@hooks/useLocalStorage';
import { constant } from '@config/BaseSetting';

export default function useFetchHandler() {
  const dispatch = useDispatch();
  const [accessToken] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);

  const fetchHandler = (paramsList) => {
    dispatch(fetchSeatUsageReportsAsync({ accessToken, reset: true, ...paramsList }));
  };

  const fetchSeatUsageSummary = (paramsList) => {
    dispatch(fetchSeatUsageReportsSummaryAsync({ accessToken, reset: true, ...paramsList }));
  };

  const fetchExportHandler = (paramsList) => {
    dispatch(exportSeatUsageReportAsync({ accessToken, reset: true, ...paramsList }));
  };

  const responseConverter = (data) => {
    const responseData = [
      {
        category: 'Total Seats',
        counts: data['purchase-seat'],
      },
      {
        category: 'Seat Used',
        counts: data['used-seat'],
      },
      {
        category: 'Seats Deactivate',
        counts: data['free-deactivated-seat'],
      },
    ];

    return responseData;
  };

  const conditionalStateReset = (statusCode, summaryStatusCode, fetching, summaryFetching) => {
    if (statusCode !== 200 && summaryStatusCode !== 200) {
      dispatch(resetSeatUsageReportsListStatus());
      dispatch(resetSeatUsageSummaryListStatus());
    }

    if (statusCode !== 200) {
      dispatch(resetSeatUsageReportsListStatus());
    }

    if (summaryStatusCode !== 200) {
      dispatch(resetSeatUsageSummaryListStatus());
    }

    if ((statusCode === 200 && summaryStatusCode === 200) || (fetching && summaryFetching)) {
      dispatch(resetSeatUsageReportsErrorStatus());
      dispatch(resetSeatUsageSummaryErrorStatus());
    }

    if (statusCode === 200 || fetching) {
      dispatch(resetSeatUsageReportsErrorStatus());
    }

    if (summaryStatusCode === 200 || summaryFetching) {
      dispatch(resetSeatUsageSummaryErrorStatus());
    }
  };

  const responseDataConverter = (data, setReportData) => {
    const modifiedData = data?.map((item) => {
      const modifiedPayloadObj = {
        email: item?.email,
        billable: item?.billable ? 'Yes' : 'No',
        status: item?.status,
        'deactivated-since': item['deactivated-since'],
        'has-m365-license': item['has-m365-license'] ? 'Yes' : 'No',
        'public-folder': item['public-folder'] ? 'Yes' : 'No',
        'needs-dropsuite-license': item['needs-dropsuite-license'] ? 'Yes' : 'No',
        domain: item?.domain,
        protocol: item?.protocol,
        'organization-name': item?.['organization-name'],
        'partner-name': item?.['partner-name'],
      };
      return modifiedPayloadObj;
    });
    return setReportData(modifiedData);
  };

  const convertToCsv = (reportsData, setDownloadStatus, setOpen, setType) => {
    try {
      const csvData = `data:text/csv;charset=utf-8,${encodeURIComponent(reportsData)}`;
      const link = document.createElement('a');
      link.href = csvData;
      link.download = 'reports.csv';
      document?.body?.appendChild(link);
      link.click();
      document?.body?.removeChild(link);

      setType('success');
      setDownloadStatus('Successfully Downloaded');
    } catch (error) {
      setType('error');
      setDownloadStatus(error);
    }

    setOpen(true);
    dispatch(resetExportSeatUsageReportStatus());
  };

  return {
    fetchHandler,
    fetchSeatUsageSummary,
    responseConverter,
    conditionalStateReset,
    responseDataConverter,
    fetchExportHandler,
    convertToCsv,
  };
}
