import { createSlice } from '@reduxjs/toolkit';

import {
  getTransferSubscriptionInitiateAsync,
  getTransferSubscriptionInitiateExtraReducers,
} from './serviceActions/fetchTransferSubscriptionInitiateAsync';

const initialState = {
  getTransferSubscriptionInitiateState: {
    fetching: false,
    status: null,
    transferSubscriptionInitiateList: [],
    errorMessage: null,
    paginations: [],
    forbiddenState: false,
    forbiddenErrorMessage: null,
    transferSubscriptionInitiateCorsErrorMessage: null,
  },
};

const getTransferSubscriptionInitiateSlice = createSlice({
  name: 'TransferSubscriptionInitiate',
  initialState,
  reducers: {
    resetTransferSubscriptionInitiateState(state) {
      state.getTransferSubscriptionInitiateState =
        initialState.getTransferSubscriptionInitiateState;
    },
  },
  extraReducers: {
    ...getTransferSubscriptionInitiateExtraReducers,
  },
});

export { getTransferSubscriptionInitiateAsync };

export const { resetTransferSubscriptionInitiateState } =
  getTransferSubscriptionInitiateSlice.actions;
export default getTransferSubscriptionInitiateSlice.reducer;
