import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import useLocalStorage from '@hooks/useLocalStorage';
import { submitPurgeSharedDriveAsync } from '@features/purgeSharedDrive/purgeSharedDriveSlice';
import { constant } from '@config/BaseSetting';
import {
  getPurgeSharedDriveChangeAsync,
  resetPurgeSharedDriveStatus,
} from '@features/purgeSharedDrive/getPurgeSharedDriveSlice';
import useValidation from './useValidation';

function useForm() {
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const { getPurgeSharedDriveState } = useSelector((state) => state.getPurgeSharedDriveState);

  const dispatch = useDispatch();

  const {
    supportTicketAttributes,
    escalationTicketAttributes,
    descriptionAttributes,
    organizationOwnerIdAttributes,
    tenantIDAttributes,
    resetForm,
    isValid,
    formData,
    validateAll,
  } = useValidation();
  const previousPropsRef = useRef(formData);

  const submitHandler = (isFormValid) => {
    const previousProps = previousPropsRef.current;
    if (isFormValid && !isEqual(previousProps, formData)) {
      dispatch(
        submitPurgeSharedDriveAsync({
          accessToke: accessTokenSet,
          reset: true,
          parameters: formData,
        })
      );
      previousPropsRef.current = formData;
    }
  };

  const getPurgeSharedDriveRequestData = (data, setPurgeSharedDriveData) => {
    const modifiedData = data?.map((item) => {
      const payloadObj = JSON.parse(item.payload);
      const modifiedPayloadObj = {
        ...payloadObj,
        'executed-on': item['executed-on'],
        'status-description': item['status-description'],
      };
      return modifiedPayloadObj;
    });
    setPurgeSharedDriveData(modifiedData);
  };

  const getPurgeSharedDriveServices = (setPurgeSharedDriveData, setPagesSet) => {
    if (getPurgeSharedDriveState?.data?.length > 0) {
      setPagesSet(getPurgeSharedDriveState?.pagination);
      getPurgeSharedDriveRequestData(getPurgeSharedDriveState?.data, setPurgeSharedDriveData);
    }
  };

  const getPurgeSharedDriveAPIHandler = (pageParams) => {
    if (!getPurgeSharedDriveState?.fetching) {
      dispatch(resetPurgeSharedDriveStatus());
      dispatch(
        getPurgeSharedDriveChangeAsync({
          accessToken: accessTokenSet,
          reset: true,
          pageNumber: pageParams,
        })
      );
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const isFormValid = validateAll();
    submitHandler(isFormValid);
  };

  useEffect(() => {}, [isValid]);

  return {
    supportTicketAttributes,
    escalationTicketAttributes,
    descriptionAttributes,
    organizationOwnerIdAttributes,
    tenantIDAttributes,
    isValid,
    resetForm,
    formData,
    onSubmit,
    getPurgeSharedDriveServices,
    getPurgeSharedDriveAPIHandler,
  };
}

export default useForm;
