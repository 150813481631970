import React, { useEffect, useState } from 'react';
import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import {
  QboAdvanceTable,
  QboCard,
  QboFormGroup,
  QboTextField,
  QboTypography,
} from '@ui/Components';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import QboSortByButton from '@ui/Components/QboSortByButton';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import useGWSTenantService from './pagehooks/useGWSTenantService';
import useDataService from './pagehooks/useDataService';

export default function GWSTenantPage(props) {
  const { organizationOwnerId, organizationOwnerIdProp, paginationProp } = props;
  const { t } = useTranslation();
  const { getGWSTenantState } = useSelector((state) => state.gwsTenant);
  const { gwsTenantInformation, fetching, paginationList } = getGWSTenantState;
  const [gwsListData, setGWSListData] = useState([]);
  const [organizationErrorMessageTextFiled, setOrganizationErrorMessageTextFiled] = useState('');
  const [errorMessagePartnerTextFiled, setErrorMessagePartnerTextFiled] = useState('');
  const [pagination, setPagination] = useState({
    pageIndex: paginationProp?.current?.pageIndex || 0,
    pageSize: paginationProp?.current?.pageSize || 10,
  });
  const filterStates = {
    advanceClick: false,
    textFieldValue: '',
    searchFieldValueText: '',
    searchFieldValueID: '',
    searchOrgId: organizationOwnerId || 0,
    searchPartnerId: 0,
    searchOrgIdText: '',
    searchPartnerIdText: '',
    selectedSortColumn: '',
    matchText: '',
    resetClicked: false,
  };

  const [filterDataState, setFilterDataState] = useState(filterStates);

  const [adFilter, setAdFilter] = useState([
    { field: 'filter_app_auth_status', value: '' },
    { field: 'filter_completed_autodiscover_setup', value: '' },
    { field: 'filter_completed_setup', value: '' },
  ]);

  const {
    gwsTenantHeaders,
    buttonStyle,
    authStatus,
    autoDiscoverSetup,
    credentialSetup,
    sortColumnNames,
    clickableCoulmnList,
  } = useDataService();
  const {
    handleGWSTenantAsync,
    handleStateUpdate,
    handleOnResetButtonClick,
    handleOnTextFiledChange,
    handleOnHideAdvanceClick,
    handleUpdateFilters,
    matchTextHandler,
    getGWSDataFromResponse,
    handleOnPartnerIDTextFiledChange,
    handleOnOwnerIDTextFiledChange,
  } = useGWSTenantService(
    filterDataState,
    setFilterDataState,
    filterStates,
    organizationOwnerId,
    pagination,
    adFilter,
    setAdFilter,
    setPagination,
    setGWSListData,
    setOrganizationErrorMessageTextFiled,
    setErrorMessagePartnerTextFiled
  );
  const [gwsHeaders, setGWSHeaders] = useState(gwsTenantHeaders);

  const getGWSTenantHeaders = (organizationId) => {
    if (organizationId) {
      const headers = gwsTenantHeaders.filter(
        (header) => !['Organization Owner ID', 'Partner ID'].includes(header.label)
      );
      setGWSHeaders(headers);
    }
  };
  const handleOnSearchClick = (e) => {
    e.preventDefault();
    matchTextHandler();
    handleGWSTenantAsync(true);
    setPagination({ pageIndex: 0, pageSize: 10 });
  };

  useEffect(() => {
    const previousProps = paginationProp.current;

    if (
      !organizationOwnerId ||
      organizationOwnerId !== organizationOwnerIdProp?.current ||
      !isEqual(previousProps, pagination)
    ) {
      organizationOwnerIdProp.current = organizationOwnerId;
      paginationProp.current = pagination;
      if (!fetching) {
        handleGWSTenantAsync(false);
      }
    }
  }, [pagination]);

  useEffect(() => {
    getGWSDataFromResponse(gwsTenantInformation);
  }, [gwsTenantInformation]);

  useEffect(() => {
    getGWSTenantHeaders(organizationOwnerId);
  }, [organizationOwnerId]);

  return (
    <>
      {!organizationOwnerId && <HeaderPageBlock>GWS Tenant Information</HeaderPageBlock>}

      <QboCard isMainWrapper>
        <QboFormGroup
          onSubmit={(e) => handleOnSearchClick(e)}
          buttonPostion="start"
          buttonStyle={buttonStyle}
          handleOnResetButton={() => {
            handleOnResetButtonClick();
          }}
          firstButtonLabel={t('partner_details_page.button.search')}
          withGenerateAndResetButton
          buttonLabel="Validate Transfer">
          <div style={{ display: 'flex' }}>
            <QboTextField
              id="fullTextSearch"
              label="Credential Filter :"
              explicitStyleClass="FormControl__width-wrapper_40"
              value={filterDataState.textFieldValue}
              isWidthSetExplicit
              placeholder="Credential ID/Domain/Tenant ID"
              inputProps={{ maxLength: 225 }}
              onChange={(e) => {
                handleOnTextFiledChange(e);
              }}
            />
            {!organizationOwnerId && (
              <QboTextField
                id="fullTextSearch"
                label="Organization Filter :"
                explicitStyleClass="FormControl__width-wrapper_40_with_margin"
                value={filterDataState.searchOrgIdText}
                isWidthSetExplicit
                placeholder="Organization Owner ID"
                inputProps={{ maxLength: 225 }}
                errorMessage={organizationErrorMessageTextFiled}
                onChange={(e) => {
                  handleOnOwnerIDTextFiledChange(e);
                }}
              />
            )}
          </div>
          {!organizationOwnerId && (
            <QboTextField
              id="fullTextSearch"
              explicitStyleClass="FormControl__width-wrapper_40"
              label="Partner Filter :"
              value={filterDataState.searchPartnerIdText}
              isWidthSetExplicit
              placeholder="Partner ID"
              inputProps={{ maxLength: 225 }}
              errorMessage={errorMessagePartnerTextFiled}
              onChange={(e) => {
                handleOnPartnerIDTextFiledChange(e);
              }}
            />
          )}
          <div style={{ marginTop: 8 }}>
            <Button
              disableRipple
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
                textTransform: 'none',
              }}
              onClick={() => {
                handleStateUpdate('advanceClick', !filterDataState.advanceClick);
                handleOnHideAdvanceClick();
              }}>
              {filterDataState.advanceClick
                ? t('partner_details_page.button.hide_advance_search')
                : t('partner_details_page.button.show_advance_search')}
            </Button>
          </div>
          {filterDataState.advanceClick && (
            <div>
              <QboSortByButton
                defaultValue="All"
                marginTopValue={2}
                marginRightValue={2}
                value={adFilter.find((item) => item.field === 'filter_app_auth_status').value}
                buttonWidth={220}
                startAdornmentTextWidth={190}
                startAdornmentText="App Auth Status :"
                handleChange={(e) => handleUpdateFilters('filter_app_auth_status', e.target.value)}
                menuItemList={authStatus}
              />
              <QboSortByButton
                defaultValue="All"
                marginTopValue={2}
                marginRightValue={2}
                value={
                  adFilter.find((item) => item.field === 'filter_completed_autodiscover_setup')
                    .value
                }
                buttonWidth={220}
                startAdornmentTextWidth={190}
                startAdornmentText="Autodiscover Setup Completed :"
                handleChange={(e) =>
                  handleUpdateFilters('filter_completed_autodiscover_setup', e.target.value)
                }
                menuItemList={autoDiscoverSetup}
              />
              <QboSortByButton
                defaultValue="All"
                marginTopValue={2}
                marginRightValue={2}
                value={adFilter.find((item) => item.field === 'filter_completed_setup').value}
                buttonWidth={220}
                startAdornmentTextWidth={190}
                startAdornmentText="Credential Setup Completed :"
                handleChange={(e) => handleUpdateFilters('filter_completed_setup', e.target.value)}
                menuItemList={credentialSetup}
              />
              <div>
                <QboSortByButton
                  defaultValue="None"
                  marginTopValue={2}
                  marginRightValue={2}
                  value={filterDataState.selectedSortColumn}
                  buttonWidth={220}
                  startAdornmentTextWidth={70}
                  startAdornmentText="Sort by:"
                  handleChange={(e) => handleStateUpdate('selectedSortColumn', e.target.value)}
                  menuItemList={sortColumnNames}
                />
              </div>
            </div>
          )}
        </QboFormGroup>
      </QboCard>

      <QboCard noPadding isMainWrapper>
        <hr />
        <QboTypography
          sx={{
            marginLeft: 2,
            marginBottom: 3,
            fontSize: 18,
            color: '#000000DE',
            fontWeight: 600,
          }}>
          GWS Tenant List
        </QboTypography>
        {!filterDataState.resetClicked && filterDataState.matchText ? (
          <QboTypography
            sx={{
              marginLeft: 2,
              marginTop: 2,
              marginBottom: 3,
              fontSize: '15px !important',
              color: '#42526e',
              fontWeight: 600,
              overflow: 'hidden',
              whiteSpace: 'pre-wrap',
            }}>
            {!filterDataState.resetClicked && filterDataState.matchText}
          </QboTypography>
        ) : null}
        {(gwsListData.length > 0 || fetching) && (
          <QboAdvanceTable
            isLoading={fetching}
            enableTopToolBar={false}
            headers={gwsHeaders}
            rows={gwsListData}
            rowsCount={Number(paginationList?.TotalCount || 0)}
            pagination={pagination}
            setPagination={setPagination}
            enableColumnClickable={!false}
            columnsClickableList={clickableCoulmnList}
          />
        )}
        {gwsListData.length === 0 && !fetching && <NoStatusSection />}
      </QboCard>
    </>
  );
}

GWSTenantPage.propTypes = {
  organizationOwnerId: PropTypes.number,
  organizationOwnerIdProp: PropTypes.object,
  paginationProp: PropTypes.object,
};

GWSTenantPage.defaultProps = {
  organizationOwnerId: null,
  organizationOwnerIdProp: {},
  paginationProp: {},
};
