import React from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { QboCard, QboCardCaption } from '@ui/Components';
import NoStatus from '@images/generals/NoStatus.svg';
import BaseSetting from '@config/BaseSetting';

export default function RegistrationPage(props) {
  const { currentAccountStatus } = props;
  const { t } = useTranslation();
  const { suspended, approval } = BaseSetting.accountStatusCode;

  return (
    NoStatus && (
      <QboCard noPadding isMainWrapper>
        <Box className="LoginPage__wrapper" style={{ display: 'flex', flexDirection: 'column' }}>
          <QboCardCaption
            imageUrl={NoStatus}
            heading={
              (currentAccountStatus === suspended && t('account_status.suspended')) ||
              (currentAccountStatus === approval && t('account_status.approval'))
            }
            headingSx={{ textAlign: 'center', width: '60%' }}
          />
        </Box>
      </QboCard>
    )
  );
}

RegistrationPage.propTypes = {
  currentAccountStatus: PropTypes.number,
};
RegistrationPage.defaultProps = {
  currentAccountStatus: null,
};
