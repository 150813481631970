import React from 'react';
import PropTypes from 'prop-types';
import { QboCardCaption } from '@ui/Components';
import NoStatus from '@images/generals/NoStatus.svg';

export default function QboNoStatus({ title }) {
  return (
    <QboCardCaption
      className="QboNoStatus-Wrapper"
      imageSx={{ height: 120 }}
      imageUrl={NoStatus}
      heading={title}
    />
  );
}

QboNoStatus.propTypes = {
  title: PropTypes.string,
};

QboNoStatus.defaultProps = {
  title: '',
};
