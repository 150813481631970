import {
  ITBox,
  QboAlert,
  QboFormGroup,
  QboModal,
  QboSimpleDataTable,
  QboTextField,
} from '@ui/Components';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useBulkCreateOrganization from './pageHooks/useBulkCreateOrganization';
import { CREATE_ORGANIZATION_HEADERS } from './pageHooks/constants/bulkCreateOrganizationConstants';

function BulkCreateOrganizationModal({ closeModal, showModal, title, children, selectedPartner }) {
  const fileInputRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState();

  const {
    form,
    bulkCreateOrganizationState,
    parseResult,
    handleOnValidateData,
    handleOnFileUpload,
    resetAll,
  } = useBulkCreateOrganization({
    selectedPartner,
    onSuccessRequest: closeModal,
    onFailedRequest: setErrorMessage,
  });
  const { t } = useTranslation();

  const closeAndReset = () => {
    setErrorMessage(null);
    closeModal();
    resetAll();
  };

  return (
    <QboModal
      onClose={closeAndReset}
      open={showModal}
      title={title}
      showCloseIcon
      preventOutsideClick>
      <ITBox direction="TransferSubscriptionFormPage__wrapper column">
        <QboFormGroup
          loadingButton={bulkCreateOrganizationState.fetching}
          onSubmit={(e) => {
            setErrorMessage(null);
            handleOnValidateData(e);
          }}
          withGenerateAndResetButton
          handleOnResetButton={closeAndReset}
          secondButtonLabel="Cancel"
          firstButtonLabel="Submit">
          {errorMessage && (
            <QboAlert
              variant="outlined"
              type="error"
              style={{
                // border: '1px solid #ebebeb',
                background: '#fdeded',
                fontSize: '10px !important',
              }}>
              <p>
                {t('partner_list_page.bulk_create_organization.error', { error: errorMessage })}
              </p>
            </QboAlert>
          )}
          {children}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <QboTextField
                id="supportTicket"
                required
                placeholder="Support Ticket"
                label="Support Ticket"
                fullWidth
                value={form.supportTicketAttributes.value}
                errorMessage={form.supportTicketAttributes.errorMessage}
                inputProps={{ maxLength: 225 }}
                onChange={(e) => {
                  form.supportTicketAttributes.handleChange(e.currentTarget.value);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <QboTextField
                id="escalationTicket"
                placeholder="Escalation Ticket"
                label="Escalation Ticket"
                fullWidth
                value={form.escalationTicketAttributes.value}
                onChange={(e) =>
                  form.escalationTicketAttributes.handleChange(e.currentTarget.value)
                }
                inputProps={{ maxLength: 225 }}
              />
            </Grid>
          </Grid>
          <QboTextField
            id="description"
            placeholder="Description"
            label="Description"
            fullWidth
            value={form.descriptionAttributes.value}
            onChange={(e) => form.descriptionAttributes.handleChange(e.currentTarget.value)}
            inputProps={{ maxLength: 225 }}
          />
          <QboTextField
            id="csvFile"
            placeholder={t('bulk_purge.labels.csv_file')}
            label={t('partner_list_page.bulk_create_organization.labels.csv_file')}
            type="file"
            fullWidth
            errorMessage={parseResult.results?.length ? null : form.csvValidAttributes.errorMessage}
            inputRef={fileInputRef}
            onChange={handleOnFileUpload}
          />
          {parseResult.errors?.length && (
            <QboAlert
              variant="outlined"
              type="error"
              style={{
                background: '#fdeded',
                fontSize: '10px !important',
                marginTop: '20px',
              }}>
              {parseResult.errors.map((e) => (
                <p>{e}</p>
              ))}
            </QboAlert>
          )}
          {parseResult.results?.length && (
            <QboSimpleDataTable header={CREATE_ORGANIZATION_HEADERS} rows={parseResult.results} />
          )}
        </QboFormGroup>
      </ITBox>
    </QboModal>
  );
}

BulkCreateOrganizationModal.propTypes = {
  closeModal: PropTypes.func,
  showModal: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  selectedPartner: PropTypes.object,
};

BulkCreateOrganizationModal.defaultProps = {
  closeModal: () => {},
  showModal: false,
  title: '',
  children: '',
  selectedPartner: null,
};

export default BulkCreateOrganizationModal;
