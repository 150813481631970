import useBulkCreateSubResellerFormValidation from './useBulkCreateSubResellerFormValidation';

export default function useBulkCreateSubResellerForm() {
  const {
    formData,
    supportTicketAttributes,
    escalationTicketAttributes,
    descriptionAttributes,
    csvValidAttributes,
    resetForm,
    validateAll,
    isValid,
  } = useBulkCreateSubResellerFormValidation();

  const onValidateData = (e) => {
    e.preventDefault();
    validateAll();
  };

  return {
    formData,
    supportTicketAttributes,
    escalationTicketAttributes,
    descriptionAttributes,
    csvValidAttributes,
    resetForm,
    onValidateData,
    isValid,
  };
}
