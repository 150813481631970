import React from 'react';
import PropTypes from 'prop-types';
import QboSortByButton from '@ui/Components/QboSortByButton';
import useDistributorFilterHook from '@pages/sharedHooks/useDistributorFilterHook';

export default function DistributorFilterSection(props) {
  const { value, handleChange, label, inputSize } = props;
  const { distributorFilterState, resetPartnerFilter, UpdateFilters, partnerList } =
    useDistributorFilterHook(value);

  return (
    <QboSortByButton
      defaultValue="All"
      forceUpdate={distributorFilterState.forceUpdate}
      searchState={distributorFilterState.searchState}
      handleCloseClick={() => {
        resetPartnerFilter();
        handleChange();
      }}
      isSearchEnable={!false}
      handleOnSearchChange={(e) => {
        UpdateFilters(e.target.value, ['searchState']);
      }}
      handleChange={(e) => {
        UpdateFilters(e.target.value, ['selectedPartner']);
        handleChange(e.target.value);
      }}
      marginRightValue={2}
      value={distributorFilterState.selectedPartner}
      buttonWidth={220}
      startAdornmentTextWidth={inputSize}
      startAdornmentText={label}
      menuItemList={partnerList}
    />
  );
}

DistributorFilterSection.propTypes = {
  value: PropTypes.string,
  handleChange: PropTypes.func,
  label: PropTypes.string,
  inputSize: PropTypes.number,
};

DistributorFilterSection.defaultProps = {
  value: '',
  handleChange: () => {},
  label: 'Partners',
  inputSize: 180,
};
