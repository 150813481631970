import React, { useEffect, useState } from 'react';
import {
  ITBox,
  QboAdvanceTable,
  QboAlert,
  QboBreadcrumbs,
  QboCard,
  QboCardCaption,
  QboTypography,
} from '@ui/Components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { IconButton } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

import BaseSetting, { constant } from '@config/BaseSetting';
import NoStatus from '@images/generals/NoStatus.svg';
import useLocalStorage from '@hooks/useLocalStorage';
import { mailBoxChangePath } from '@config/Routes/WebClientRoutes';
import { resetListMailboxChangeDetail } from '@features/mailBox/mailboxChangeDetailSlice';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import useMailboxChangeDetail from './pageHooks/useMailboxChangeDetail';

export default function DetailMailboxChange() {
  const { t } = useTranslation();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { getMailboxChangeDetailAsync, getMailboxChangeStatusAsync } = useMailboxChangeDetail();
  const [regionValue] = useLocalStorage(constant.REGIONS_LIST);
  const [paginations, setPaginations] = useState({ pageIndex: 0, pageSize: 10 });
  const row = state?.row;

  const { mailboxChangeDetailState } = useSelector((s) => s.mailboxChangeDetail);
  const {
    fetching,
    list,
    pagination,
    forbiddenState,
    forbiddenErrorMessage,
    mailboxChangeDetailCorsErrorMessage: corsError,
  } = mailboxChangeDetailState;

  const { mailboxChangeStatusState } = useSelector((s) => s.mailboxChangeStatus);
  const { data, mailboxChangeDetailCorsErrorMessage } = mailboxChangeStatusState;

  const fetchMailboxChangeStatusAsync = () => {
    if (!mailboxChangeStatusState?.fetching) {
      getMailboxChangeStatusAsync(state?.row?.id);
    }
  };

  const refreshMailboxChangeStatus = () => {
    fetchMailboxChangeStatusAsync();
  };

  useEffect(() => {
    fetchMailboxChangeStatusAsync();
  }, [row]);

  const fetchMailboxChangeDetail = () => {
    if (!fetching) {
      dispatch(resetListMailboxChangeDetail());
      getMailboxChangeDetailAsync(paginations.pageSize, paginations.pageIndex + 1, state?.row?.id);
    }
  };

  const refreshHandler = () => {
    fetchMailboxChangeDetail();
  };

  useEffect(() => {
    fetchMailboxChangeDetail();
  }, [paginations.pageIndex, paginations.pageSize, row]);

  const mailboxBulkPurgeListTableHeaders = [
    { id: 1, label: 'ID', columnName: 'id', sx: { paddingLeft: 2 } },
    { id: 2, label: 'Organization Owner Id', columnName: 'org-owner-id', sx: { paddingLeft: 2 } },
    {
      id: 3,
      label: 'Current Email',
      columnName: 'current-email-account',
      sx: { paddingLeft: 2 },
    },
    {
      id: 4,
      label: 'New Email',
      columnName: 'new-email-account',
      sx: { paddingLeft: 2 },
    },
    {
      id: 5,
      label: 'Enable Add in Other Org?',
      columnName: 'change-request-type-desc',
      sx: { paddingLeft: 2 },
    },
    {
      id: 6,
      label: 'Status',
      columnName: 'status-description',
      sx: { paddingLeft: 2 },
    },
    {
      id: 7,
      label: 'Last Updated At',
      columnName: 'last-updated-on',
      sx: { paddingLeft: 2 },
    },
    {
      id: 8,
      label: 'Retry Count',
      columnName: 'retry-count',
      sx: { paddingLeft: 2 },
    },
    {
      id: 9,
      label: 'Last Retry At',
      columnName: 'last-retried-on',
      sx: { paddingLeft: 2 },
    },
  ];

  return (
    <>
      <span style={{ padding: '2.2rem 1rem 1.8rem 1rem' }}>
        {' '}
        <QboBreadcrumbs>
          <Link
            to={mailBoxChangePath}
            style={{ color: '#1c63d5', fontSize: '1.5rem', textDecoration: 'underline' }}>
            {t('mailbox_change.header.text')}
          </Link>
          <QboTypography color="text.primary" style={{ fontSize: '1.5rem' }}>
            {t('mailbox_change_detail_page.list_label')}
          </QboTypography>
        </QboBreadcrumbs>
      </span>
      {(corsError || mailboxChangeDetailCorsErrorMessage) && (
        <QboAlert type="error" style={{ fontSize: '1.15em' }}>
          {corsError || mailboxChangeDetailCorsErrorMessage}
        </QboAlert>
      )}
      <QboCard>
        <ITBox className="SystemStatusSection__wrapper" direction="row">
          <QboTypography
            className="HeaderPageBlock__heading"
            variant="h4"
            sx={{ display: 'flex', alignItems: 'center', paddingLeft: 2 }}>
            {t('mailbox_change_detail_page.header.status_text')}
          </QboTypography>
          <ITBox className="SystemStatusSection__create-button">
            <IconButton
              sx={{ position: 'relative', right: '0.7rem' }}
              onClick={() => refreshMailboxChangeStatus()}>
              <RefreshIcon sx={{ width: '2rem', height: '2rem' }} />
            </IconButton>
          </ITBox>
        </ITBox>

        {Object.keys(data).length > 0 && (
          <QboAdvanceTable
            enableTopToolBar={false}
            enableBottomToolbar={false}
            rows={[data]}
            isLoading={mailboxChangeStatusState?.fetching}
            enableSorting={Boolean(true)}
            rowsCount={5}
            headers={BaseSetting.mailboxBulkPurgeStatusHeaders}
          />
        )}
        {Object.keys(data).length === 0 && (
          <QboCardCaption
            imageSx={{ height: 120 }}
            sx={{ width: '100%', height: '220px' }}
            imageUrl={NoStatus}
            heading={t('system_status_section.no_status_section.no_record_text')}
          />
        )}
      </QboCard>
      &nbsp;
      <QboCard>
        <ITBox className="SystemStatusSection__wrapper" direction="row">
          <QboTypography
            className="HeaderPageBlock__heading"
            variant="h4"
            sx={{ display: 'flex', alignItems: 'center', paddingLeft: 2 }}>
            {t('mailbox_change_detail_page.header.text')}&nbsp;
            {state?.row?.id}
          </QboTypography>
          <ITBox className="SystemStatusSection__create-button">
            <IconButton
              sx={{ position: 'relative', right: '0.7rem' }}
              onClick={() => refreshHandler()}>
              <RefreshIcon sx={{ width: '2rem', height: '2rem' }} />
            </IconButton>
          </ITBox>
        </ITBox>

        {(list.length > 0 || fetching) && (
          <QboAdvanceTable
            rows={list}
            isLoading={fetching}
            enableSorting={Boolean(true)}
            pagination={paginations}
            setPagination={setPaginations}
            rowsCount={pagination?.TotalCount || 0}
            headers={mailboxBulkPurgeListTableHeaders}
          />
        )}
        {((!fetching && list.length === 0) || !regionValue || forbiddenState) && (
          <NoStatusSection forbiddenErrorMessage={forbiddenErrorMessage} />
        )}
      </QboCard>
    </>
  );
}
