import { createAsyncThunk } from '@reduxjs/toolkit';
import InvoiceService from '@services/WebApiService/InvoiceService';

const checkXeroSyncRunningAsync = createAsyncThunk(
  'XeroSync/checkXeroSyncRunning',
  async (paramsList) => {
    try {
      const data = await InvoiceService.checkXeroSyncRunningApi(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = checkXeroSyncRunningAsync;

const checkXeroSyncRunningExtraReducers = {
  [pending]: (state) => {
    state.checkXeroSyncRunningState.fetching = true;
  },
  [rejected]: (state) => {
    state.checkXeroSyncRunningState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.checkXeroSyncRunningState.fetching = false;

    if (action?.payload?.status === 403) {
      state.checkXeroSyncRunningState.forbiddenState = true;
      state.checkXeroSyncRunningState.forbiddenErrorMessage = {
        heading: 'Forbidden Error',
        caption: 'You are not authorize to access this resource',
      };
    }

    if (!action?.payload) {
      state.checkXeroSyncRunningState.InvoicesListCorsErrorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.data) {
      state.checkXeroSyncRunningState.isRunning = action?.payload?.data?.data;
      state.checkXeroSyncRunningState.success = true;
      state.checkXeroSyncRunningState.statusCode = action?.payload?.data['status-code'];
    }

    if (action?.payload?.error) {
      state.checkXeroSyncRunningState.error = action.payload.error;
    }
  },
};

export { checkXeroSyncRunningAsync, checkXeroSyncRunningExtraReducers };
