import { createAsyncThunk } from '@reduxjs/toolkit';
import { userBackupListAPI } from '@services/WebApiService';

const fetchUserBackupListAsync = createAsyncThunk(
  'UserBackupList/getUserBackupLists',
  async (paramsList) => {
    try {
      const data = await userBackupListAPI(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = fetchUserBackupListAsync;

const getUserBackupListExtraReducers = {
  [pending]: (state) => {
    state.userBackupListState.fetching = true;
  },
  [rejected]: (state) => {
    state.userBackupListState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.userBackupListState.fetching = false;

    if (action?.payload?.status === 403) {
      state.userBackupListState.forbiddenState = true;
      state.userBackupListState.forbiddenErrorMessage = {
        heading: 'Forbidden Error',
        caption: 'You are not authorize to access this resource',
      };
    }

    if (!action?.payload) {
      state.userBackupListState.UsersListCorsErrorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.data?.data) {
      state.userBackupListState.userBackupList = action?.payload?.data?.data;
      state.userBackupListState.paginations = JSON.parse(action?.payload?.data?.pagination);
      state.userBackupListState.success = true;
      state.userBackupListState.statusCode = action?.payload?.data['status-code'];
    }

    if (action?.payload?.error) {
      state.userBackupListState.error = action.payload.error;
    }
  },
};

export { fetchUserBackupListAsync, getUserBackupListExtraReducers };
