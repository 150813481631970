import { createAsyncThunk } from '@reduxjs/toolkit';
import { transferSubresellerValidateApi } from '@services/WebApiService';

const transferSubresellerValidateAsync = createAsyncThunk(
  'TransferSubreseller/validate',
  async (paramsList) => {
    try {
      const data = await transferSubresellerValidateApi(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = transferSubresellerValidateAsync;

const transferSubresellerValidateExtraReducers = {
  [pending]: (state) => {
    state.transferSubresellerValidate.submitting = true;
  },
  [rejected]: (state) => {
    state.transferSubresellerValidate.submitting = false;
  },
  [fulfilled]: (state, action) => {
    state.transferSubresellerValidate.submitting = false;

    if (action?.payload?.error) {
      state.transferSubresellerValidate.error = action.payload.error;
      state.transferSubresellerValidate.validateErrorMessage = action.payload.error?.data?.message;
      state.transferSubresellerValidate.statusCode = action.payload.error?.status;

      if (action?.payload?.error?.status === 403) {
        state.transferSubresellerValidate.validateErrorMessage =
          'You are not authorize to access this resource';
      }
    } else {
      state.transferSubresellerValidate.success = true;
      state.transferSubresellerValidate.value = action.payload.data;
    }
  },
};

export { transferSubresellerValidateAsync, transferSubresellerValidateExtraReducers };
