import { createSlice } from '@reduxjs/toolkit';
import {
  fetchSwitchCredentialItemsAsync,
  switchCredentialItemsReducers,
} from './serviceActions/fetchSwitchCredentialItemsAsync';

const initialState = {
  switchCredentialItemsState: {
    fetching: false,
    list: [],
    errorMessage: null,
    pagination: [],
    statusCode: null,
    forbiddenState: false,
    forbiddenErrorMessage: {},
    switchCredentialItemsCorsErrorMessage: null,
  },
};

const switchCredentialItemsSlice = createSlice({
  name: 'SwitchCredentialItems',
  initialState,
  reducers: {
    resetSwitchCredentialItemsState(state) {
      state.switchCredentialItemsState = initialState.switchCredentialItemsState;
    },
    resetSwitchCredentialItemsListState(state) {
      state.switchCredentialItemsState.list = initialState.switchCredentialItemsState.list;
    },
  },
  extraReducers: {
    ...switchCredentialItemsReducers,
  },
});

export { fetchSwitchCredentialItemsAsync };
export const { resetSwitchCredentialItemsState, resetSwitchCredentialItemsListState } =
  switchCredentialItemsSlice.actions;
export default switchCredentialItemsSlice.reducer;
