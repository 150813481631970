import { createAsyncThunk } from '@reduxjs/toolkit';
import { exportTenantSizeReportAPI } from '@services/WebApiService';
import { saveAs } from 'file-saver';

const exportTenantSizeReportAsync = createAsyncThunk(
  'tenantSizeReport/exportTenantSizeReport',
  async (paramsList) => {
    try {
      const data = await exportTenantSizeReportAPI(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = exportTenantSizeReportAsync;

const exportTenantSizeReportExtraReducers = {
  [pending]: (state) => {
    state.exportTenantSizeReportState.fetching = true;
  },
  [rejected]: (state) => {
    state.exportTenantSizeReportState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.exportTenantSizeReportState.fetching = false;

    if (action?.payload?.status === 403) {
      state.exportTenantSizeReportState.forbiddenState = true;
      state.exportTenantSizeReportState.forbiddenErrorMessage = {
        heading: 'Forbidden Error',
        caption: 'You are not authorize to access this resource',
      };
    }

    if (!action?.payload) {
      state.exportTenantSizeReportState.extportTenantSizeReportCorsErrorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.data) {
      state.exportTenantSizeReportState.data = action?.payload?.data;
      state.exportTenantSizeReportState.contentType = action?.payload?.headers['content-type'];
      state.exportTenantSizeReportState.success = true;
      state.exportTenantSizeReportState.statusCode = action?.payload?.data['status-code'];
    }

    if (action?.payload?.error) {
      state.exportTenantSizeReportState.error = action.payload.error;
    }
  },
};

export { exportTenantSizeReportAsync, exportTenantSizeReportExtraReducers };
