import { createSlice } from '@reduxjs/toolkit';

import {
  invalidateServiceTokenAsync,
  invalidateServiceTokenReducers,
} from './serviceActions/invalidateServiceTokenAsync';

const initialState = {
  invalidateServiceTokenState: {
    fetching: false,
    status: null,
    error: null,
    message: null,
    errorMessage: null,
    forbiddenState: false,
    forbiddenErrorMessage: null,
    invalidateServiceTokenCorsErrorMessage: null,
    serviceType: null,
  },
};

const invalidateServiceTokenSlice = createSlice({
  name: 'invalidateServiceToken',
  initialState,
  reducers: {
    resetInvalidateServiceTokenState(state) {
      state.invalidateServiceTokenState = initialState.invalidateServiceTokenState;
    },
  },
  extraReducers: {
    ...invalidateServiceTokenReducers,
  },
});

export { invalidateServiceTokenAsync };

export const { resetInvalidateServiceTokenState } = invalidateServiceTokenSlice.actions;
export default invalidateServiceTokenSlice.reducer;
