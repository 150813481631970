import { createSlice } from '@reduxjs/toolkit';

import {
  exportTenantSizeReportAsync,
  exportTenantSizeReportExtraReducers,
} from './serviceActions/exportTenantSizeReportAsync';

const initialState = {
  exportTenantSizeReportState: {
    fetching: false,
    status: null,
    error: null,
    message: null,
    errorMessage: null,
    data: null,
    contentType: null,
    forbiddenState: false,
    forbiddenErrorMessage: null,
    extportTenantSizeReportCorsErrorMessage: null,
    serviceType: null,
  },
};

const exportTenantSizeReportSlice = createSlice({
  name: 'exportTenantSizeReport',
  initialState,
  reducers: {
    resetExportTenantSizeReportState(state) {
      state.exportTenantSizeReportState = initialState.exportTenantSizeReportState;
    },
  },
  extraReducers: {
    ...exportTenantSizeReportExtraReducers,
  },
});

export { exportTenantSizeReportAsync };

export const { resetExportTenantSizeReportState } = exportTenantSizeReportSlice.actions;
export default exportTenantSizeReportSlice.reducer;
