import React, { useEffect, useState } from 'react';
import { constant } from '@config/BaseSetting';
import { useSelector } from 'react-redux';
import useMailBoxServices from '@pages/sharedHooks/useMailboxServices';
import { resetMailSyncStatus } from '@features/mailBox/mailBoxSyncSlice';
import useLocalStorage from '@hooks/useLocalStorage';
import MailboxPage from '../MailboxPage';

export default function MailboxSyncChange() {
  const [mailboxSyncData, setMailboxSyncData] = useState([]);
  const { mailBoxSyncServices, mailBoxSyncApiHandler } = useMailBoxServices();
  const [pagesSet, setPagesSet] = useState({});
  const [pageNum, setPageNum] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [refresh, setRefresh] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);

  const { mailSyncRequestState } = useSelector((state) => state.syncRequest);
  const { fetching, success, forbiddenState, forbiddenErrorMessage, mailSyncCorsErrorMessage } =
    mailSyncRequestState;
  const { mailSyncState } = useSelector((state) => state.sync);
  const { statusCode, errorMessage } = mailSyncState;

  const callApiHandler = async () => {
    if (accessTokenSet) {
      await mailBoxSyncApiHandler(pageNum);
    }
  };

  const refreshHandler = () => {
    setRefresh(true);
  };

  useEffect(() => {
    callApiHandler();
    setRefresh(false);
  }, [pageNum, refresh]);

  useEffect(() => {
    mailBoxSyncServices(setMailboxSyncData, setPagesSet);
  }, [mailSyncRequestState?.data]);

  const handleChangePage = (event, newPage) => {
    setPageNum(newPage);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, mailboxSyncData.length - pageNum * rowsPerPage);

  return (
    <MailboxPage
      page={constant.MAILBOX_SYNC_PAGE}
      refreshHandler={refreshHandler}
      mailboxData={mailboxSyncData}
      handleChangePage={handleChangePage}
      pagesSet={pagesSet}
      setShowModal={setShowModal}
      showModal={showModal}
      callApiHandler={callApiHandler}
      statusCode={statusCode}
      errorMessage={errorMessage}
      resetStatusCode={resetMailSyncStatus}
      fetching={fetching}
      success={success}
      pageNum={pageNum}
      isForbiddenOccured={forbiddenState}
      forbiddenErrorMessage={forbiddenErrorMessage}
      corsErrorMessage={mailSyncCorsErrorMessage}
    />
  );
}
