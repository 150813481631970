import { createSlice } from '@reduxjs/toolkit';
import {
  bulkCreateSubResellerAsync,
  bulkCreateSubResellerExtraReducers,
} from './serviceActions/bulkCreateSubResellerAsync';

const initialState = {
  bulkCreateSubResellerState: {
    fetching: false,
    statusCode: null,
    error: null,
    message: null,
    errorMessage: null,
    forbiddenState: false,
    forbiddenErrorMessage: null,
    corsErrorMessage: null,
    serviceType: null,
  },
};

const bulkCreateSubResellerSlice = createSlice({
  name: 'bulkCreateSubReseller',
  initialState,
  reducers: {
    resetBulkCreateSubResellerState(state) {
      state.bulkCreateSubResellerState = initialState.bulkCreateSubResellerState;
    },
  },
  extraReducers: {
    ...bulkCreateSubResellerExtraReducers,
  },
});

export { bulkCreateSubResellerAsync };

export const { resetBulkCreateSubResellerState } = bulkCreateSubResellerSlice.actions;
export default bulkCreateSubResellerSlice.reducer;
