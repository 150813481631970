import { getResellerNotificationsConfigAPI } from '@services/WebApiService';
import { createAsyncThunk } from '@reduxjs/toolkit';

const getResellerNotificationsConfigAsync = createAsyncThunk(
  'ResellerNotificationsConfig/getResellerNotificationsConfig',
  async (paramsList) => {
    try {
      const data = await getResellerNotificationsConfigAPI(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = getResellerNotificationsConfigAsync;

const getResellerNotificationsConfigReducers = {
  [pending]: (state) => {
    state.getResellerNotificationsConfigState.fetching = true;
  },
  [rejected]: (state) => {
    state.getResellerNotificationsConfigState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.getResellerNotificationsConfigState.fetching = false;

    if (action?.payload?.data?.message) {
      state.getResellerNotificationsConfigState.errorMessage = action?.payload?.data?.message;
    }

    if (action?.payload?.status === 403) {
      state.getResellerNotificationsConfigState.forbiddenState = true;
      state.getResellerNotificationsConfigState.forbiddenErrorMessage = {
        heading: 'Forbidden Error',
        caption: 'You are not authorize to access this resource',
      };
    }

    if (action?.payload?.data?.data) {
      state.getResellerNotificationsConfigState.forbiddenState = false;
      state.getResellerNotificationsConfigState.forbiddenErrorMessage = {};
      state.getResellerNotificationsConfigState.resellerNotificationsConfigData =
        action?.payload?.data?.data;
      state.getResellerNotificationsConfigState.success = true;
    }

    if (!action?.payload) {
      state.getResellerNotificationsConfigState.getResellerNotificationsConfigCorsErrorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.code) {
      state.getResellerNotificationsConfigState.error = action.payload.code;
    }
  },
};

export { getResellerNotificationsConfigAsync, getResellerNotificationsConfigReducers };

export default getResellerNotificationsConfigAsync;
