import SchemaBuilderError from './SchemaBuilderError';
import YupSchemaBuilder from './YupSchemaBuilder';

class SchemaBuilderFactory {
  constructor(options, schemaBuilderType) {
    this.schemaBuilderType = schemaBuilderType;
    this.options = options;
  }

  create() {
    if (this.schemaBuilderType === 'yup') {
      return new YupSchemaBuilder();
    }

    throw new SchemaBuilderError('schema builder type is not defined.');
  }
}

export default SchemaBuilderFactory;
