import { useMemo, React, useState } from 'react';
import { useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import axios from 'axios';
import { useMsal } from '@azure/msal-react';

import { refreshTokenAPI } from '@config/Routes/WebTokensApiRoutes';
import useRefreshToken from '@pages/sharedHooks/useRefreshToken';
import useLogoutAccount from '@pages/sharedHooks/useLogoutAccount';
import { QboButton, QboModal, QboTypography } from '@ui/Components';

const refreshTokenExcludedUrl = [refreshTokenAPI];

/* eslint-disable no-underscore-dangle */
export default function ServiceClientInterceptors({ children }) {
  const { getRefToken } = useSelector((state) => state.refToken);
  const { error, fetching } = getRefToken;
  const { getRefreshToken } = useRefreshToken();
  const [authorized, setAuthorized] = useState(false);
  const [tokenState, setTokenState] = useState('');
  const { instance } = useMsal();
  const { accountLogoutRedirect } = useLogoutAccount(instance);

  const handleAuthFailure = async () => {
    setAuthorized(false);
    await accountLogoutRedirect();
  };

  useMemo(() => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (errorMsg) => {
        const { response, config } = errorMsg;
        const originalConfig = config;

        if (!refreshTokenExcludedUrl.includes(originalConfig.url) && response) {
          const { data, status } = response;

          if (status === 401 && !originalConfig._retry) {
            originalConfig._retry = true;

            try {
              if (Boolean(!error) && !fetching) {
                setTokenState(await getRefreshToken());
              }

              if (tokenState && tokenState.payload && tokenState.payload.data) {
                const { accessToken } = tokenState.payload.data;
                axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
                originalConfig.headers.Authorization = `Bearer ${accessToken}`;

                return axios(originalConfig);
              }
            } catch (err) {
              return Promise.reject(err);
            }
          }
        }
        return Promise.reject(errorMsg);
      }
    );
  }, []);

  return (
    <div>
      {children}{' '}
      <QboModal
        classNameStyle="QboModal__wrapper_interceptors"
        header={<div>{}</div>}
        open={Boolean(authorized)}
        preventOutsideClick>
        <QboTypography style={{ fontSize: '2.5rem' }}>
          There is an issue in connecting.
        </QboTypography>
        <br />
        <QboButton sxText={{ fontSize: '18px !important' }} onClick={handleAuthFailure}>
          Go back to login ...
        </QboButton>
      </QboModal>
    </div>
  );
}

ServiceClientInterceptors.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
