import { createSlice } from '@reduxjs/toolkit';
import { getHEXTenantAsync, getHEXTenantReducers } from './serviceActions/getHEXTenantAsync';

const initialState = {
  getHEXTenantState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    paginationList: null,
    hexTenantInformation: [],
    forbiddenState: false,
    forbiddenErrorMessage: null,
    hexTenantCorsErrorMessage: null,
  },
};

const hexTenantSlice = createSlice({
  name: 'HEXTenant',
  initialState,
  reducers: {
    resetHEXTenantState(state) {
      state.getHEXTenantState = initialState.getHEXTenantState;
    },
    resetHEXTenantStatusCodeState(state) {
      state.getHEXTenantState.statusCode = initialState.getHEXTenantState.statusCode;
    },
  },
  extraReducers: {
    ...getHEXTenantReducers,
  },
});

export { getHEXTenantAsync };
export const { resetHEXTenantState, resetHEXTenantStatusCodeState } = hexTenantSlice.actions;
export default hexTenantSlice.reducer;
