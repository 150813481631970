import { createSlice } from '@reduxjs/toolkit';
import {
  bulkCreateOrganizationAsync,
  bulkCreateOrganizationExtraReducers,
} from './serviceActions/bulkCreateOrganizationAsync';

const initialState = {
  bulkCreateOrganizationState: {
    fetching: false,
    statusCode: null,
    error: null,
    message: null,
    errorMessage: null,
    forbiddenState: false,
    forbiddenErrorMessage: null,
    corsErrorMessage: null,
    serviceType: null,
  },
};

const bulkCreateOrganizationSlice = createSlice({
  name: 'bulkCreateOrganization',
  initialState,
  reducers: {
    resetBulkCreateOrganizationState(state) {
      state.bulkCreateOrganizationState = initialState.bulkCreateOrganizationState;
    },
  },
  extraReducers: {
    ...bulkCreateOrganizationExtraReducers,
  },
});

export { bulkCreateOrganizationAsync };

export const { resetBulkCreateOrganizationState } = bulkCreateOrganizationSlice.actions;
export default bulkCreateOrganizationSlice.reducer;
