import { createSlice } from '@reduxjs/toolkit';

import {
  exportOrganizationsAsync,
  exportOrganizationsExtraReducers,
} from './serviceActions/exportOrganizationsAsync';

const initialState = {
  exportOrganizationsState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    organizationExportData: null,
    forbiddenState: false,
    forbiddenErrorMessage: null,
    organizationsListCorsErrorMessage: null,
  },
};

const exportOrganizationsSlice = createSlice({
  name: 'ExportOrganizations',
  initialState,
  reducers: {
    resetExportOrganizationsState(state) {
      state.exportOrganizationsState = initialState.exportOrganizationsState;
    },
  },
  extraReducers: {
    ...exportOrganizationsExtraReducers,
  },
});

export { exportOrganizationsAsync };
export const { resetExportOrganizationsState } = exportOrganizationsSlice.actions;
export default exportOrganizationsSlice.reducer;
