import { createSlice } from '@reduxjs/toolkit';
import {
  fetchSwitchCredentialStatusAsync,
  switchCredentialStatusReducers,
} from './serviceActions/fetchSwitchCredentialStatusAsync';

const initialState = {
  switchCredentialStatusState: {
    fetching: false,
    data: {},
    errorMessage: null,
    statusCode: null,
    forbiddenState: false,
    forbiddenErrorMessage: {},
    switchCredentialStatusCorsErrorMessage: null,
  },
};

const switchCredentialStatusSlice = createSlice({
  name: 'SwitchCredentialStatus',
  initialState,
  reducers: {
    resetSwitchCredentialStatusState(state) {
      state.switchCredentialStatusState = initialState.switchCredentialStatusState;
    },
    resetSwitchCredentialStatusDataState(state) {
      state.switchCredentialStatusState.data = initialState.switchCredentialStatusState.data;
    },
  },
  extraReducers: {
    ...switchCredentialStatusReducers,
  },
});

export { fetchSwitchCredentialStatusAsync };
export const { resetSwitchCredentialStatusState, resetSwitchCredentialStatusDataState } =
  switchCredentialStatusSlice.actions;
export default switchCredentialStatusSlice.reducer;
