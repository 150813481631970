import * as deLocale from './locales/de.json';
import * as enLocale from './locales/en.json';
import * as esLocale from './locales/es.json';
import * as frLocale from './locales/fr.json';
import * as itLocale from './locales/it.json';
import * as jaLocale from './locales/ja.json';
import * as nlLocale from './locales/nl.json';
import * as plLocale from './locales/pl.json';
import * as ptLocale from './locales/pt.json';
import * as viLocale from './locales/vi.json';

export const constant = {
  MENUS: {
    HOME_MENU: 'HOME_MENU',
    FORM_MENU: 'FORM_MENU',
    LISTING_MENU: 'LISTING_MENU',
    SUBMIT_MENU: 'SUBMIT_MENU',
    TRANSFER_SUBRESELLER_MENU: 'TRANSFER_SUBRESELLER_MENU',
    PARTNER_MENU: 'PARTNER_MENU',
    ORGANIZATION_MENU: 'ORGANIZATION_MENU',
    USERS_MENU: 'USERS_MENU',
    USERBACKUP_MENU: 'USERBACKUP_MENU',
    BACKUPSTATUSES_MENU: 'BACKUPSTATUSES_MENU',
    PLANS_MENU: 'PLANS_MENU',
    PARTNER_TRANSFER_MENU: 'PARTNER_TRANSFER_MENU',
    SUBSCRIPTION_TRANSFER_MENU: 'SUBSCRIPTION_TRANSFER_MENU',
    TRANSFER_PATH_MENU: 'TRANSFER_PATH_MENU',
    SEAT_COUNT_CHANGE_MENU: 'SEAT_COUNT_CHANGE_MENU',
    CHECK_AUTH_ERROR_MENU: 'CHECK_AUTH_ERROR_MENU',
    SWITCH_SPA_TYPE_MENU: 'SWITCH_SPA_TYPE_MENU',
    MONTHLY_BACKUP_REPORT_MENU: 'MONTHLY_BACKUP_REPORT_MENU',
    RECREATE_BCKUP_ADMIN_MENU: 'RECREATE_BCKUP_ADMIN_MENU',
    TRANSFER_SUBSCRIPTION_MENU: 'TRANSFER_SUBSCRIPTION_MENU',
    MAILBOX_CHANGE: 'MAILBOX_CHANGE',
    MAILBOX_SYNC: 'MAILBOX CHANGE (O365)',
    VIEW_EXCLUDED_MAILBOX: 'VIEW_EXCLUDED_MAILBOX',
    VIEW_AUTO_DISCOVERY: 'VIEW_AUTO_DISCOVERY',
    DATA_INGESTION: 'DATA_INGESTION',
    MAILBOX_PURGE: 'MAILBOX_PURGE',
    PURGE_M365_TENANT: 'PURGE_M365_TENANT',
    M365_TENANT_DETAILS: 'M365_TENANT_DETAILS',
    GWS_TENANT_DETAILS: 'GWS_TENANT_DETAILS',
    DOMAIN_BACKUPS: 'DOMAIN_BACKUPS',
    GWS_TENANT_PURGE: 'GWS_TENANT_PURGE',
    TRANSFER: 'TRANSFER',
    SEAT_USAGE: 'SEAT_USAGE',
    EXTERNAL_ACCOUNT: 'EXTERNAL_ACCOUNT',
    SHARED_DRIVE: 'SHARED_DRIVE',
    TENANT_M365_INFO: 'TENANT_M365_INFO',
    TENANT_GWS_INFO: 'TENANT_GWS_INFO',
    TENANT_HEX_INFO: 'TENANT_HEX_INFO',
  },
  MENU_STORAGE: 'MENU_STORAGE',
  TOKEN_STORAGE: 'TOKEN_STORAGE',
  ACCESS_TOKEN_STORAGE: 'ACCESS_TOKEN_STORAGE',
  TIMEOUT_STORAGE: 'TIMEOUT_STORAGE',
  REGIONS_LIST: 'REGIONS_LIST',
  SELECTED_REGIONS: 'SELECTED_REGIONS',
  REGIONS_FLAG: 'REGIONS_FLAG',
  MAILBOX_CHANGE_PAGE: 'Mailbox Change',
  MAILBOX_SYNC_PAGE: 'Mailbox Change (O365)',
  TENANT_M365: 'TENANT_M365_INFO',
  TENANT_GWS: 'TENANT_GWS_INFO',
  MENUS_FLAG_STATE: 'MENUS_FLAG_STATE',
  TENANT_HEX: 'TENANT_HEX_INFO',
};

export const pubClientApplication = {
  auth: {
    clientId: process.env.REACT_APP_INTERNAL_TOOL_CLIENT_ID,
    authority: process.env.REACT_APP_INTERNAL_TOOL_AUTHORITY,
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};

/**
 * Basic Setting Variables Define
 */
const BaseSetting = {
  name: 'Internal-Tools-SPA',
  displayName: 'InternalToolsSPA',
  appVersion: '1.0.0',
  defaultLanguage: 'en',
  defaultNameInitial: 'Avatar',
  paginationPerPageNumbers: ['10', '20', '30', '40', '50'],
  internalToolApiHost: process.env.REACT_APP_INTERNAL_TOOL_API_HOST,
  internalToolClientHost: process.env.REACT_APP_INTERNAL_TOOL_CLIENT_HOST,
  config: {
    tokenRequestScopes: process.env.REACT_APP_INTERNAL_TOOL_AUTHORIZE,
    clientId: process.env.REACT_APP_INTERNAL_TOOL_CLIENT_ID,
    authority: process.env.REACT_APP_INTERNAL_TOOL_AUTHORITY,
    redirectUri: window.location.origin,
    storeAuthStateInCookie: false,
    cacheLocation: 'localStorage',
  },
  graphConfig: {
    graphMeEndpoint: process.env.REACT_APP_INTERNAL_TOOL_API_GRAPH_ME_END_POINT,
    internalGateWayHost: process.env.REACT_APP_INTERNAL_TOOL_API_GATEWAY_HOST,
  },
  resourcesLocales: {
    de: {
      translation: deLocale,
    },
    en: {
      translation: enLocale,
    },
    es: {
      translation: esLocale,
    },
    fr: {
      translation: frLocale,
    },
    it: {
      translation: itLocale,
    },
    ja: {
      translation: jaLocale,
    },
    nl: {
      translation: nlLocale,
    },
    pl: {
      translation: plLocale,
    },
    pt: {
      translation: ptLocale,
    },
    vi: {
      translation: viLocale,
    },
  },
  viewExcludedMailboxTableHeader: [
    {
      id: 1,
      label: 'Owner ID',
      columnName: 'org-owner-id',
    },
    {
      id: 2,
      label: 'Email',
      columnName: 'email',
    },

    {
      id: 3,
      label: 'Executed On',
      columnName: 'executed-on',
    },
    {
      id: 4,
      label: 'Status',
      columnName: 'status-description',
    },
  ],
  viewExcludedMailboxFormTableHeader: [
    {
      id: 1,
      label: 'Name',
      columnName: 'name',
    },
    {
      id: 2,
      label: 'Email',
      columnName: 'email',
    },
  ],
  viewAutoDiscoveryTableHeader: [
    {
      id: 1,
      label: 'Owner ID',
      columnName: 'owner-id',
    },
    {
      id: 2,
      label: 'Executed On',
      columnName: 'executed-on',
    },
    {
      id: 3,
      label: 'Status',
      columnName: 'status-description',
    },
  ],
  viewAutoDiscoveryFormTableHeader: [
    {
      id: 1,
      label: 'Domain',
      columnName: 'domain',
    },
    {
      id: 2,
      label: 'Mailbox Status',
      columnName: 'mailbox-status',
    },
    {
      id: 3,
      label: 'Sharepoint Status',
      columnName: 'sharepoint-status',
    },
    {
      id: 4,
      label: 'AD Sync Status',
      columnName: 'ad-sync-status',
    },
  ],
  viewTenantAuthErrorsFormTableHeader: [
    {
      id: 1,
      label: 'Tenant Id',
      columnName: 'tenant-Id',
    },
    {
      id: 2,
      label: 'Error Message',
      columnName: 'error-message',
    },
  ],
  tableHeader1: [
    {
      id: 6,
      label: 'Owner ID',
      columnName: 'org-owner-id',
      align: 'center',
    },
    {
      id: 1,
      label: 'Support Ticket',
      columnName: 'support-ticket',
    },
    {
      id: 2,
      label: 'Escalation Ticket',
      columnName: 'escalation-ticket',
    },
    {
      id: 3,
      label: 'Description',
      columnName: 'description',
    },
    {
      id: 4,
      label: 'Current Email',
      columnName: 'current-email',
    },
    {
      id: 5,
      label: 'New Email',
      columnName: 'new-email',
    },

    {
      id: 7,
      label: 'Executed On',
      columnName: 'executed-on',
    },
    {
      id: 8,
      label: 'Status',
      columnName: 'status-description',
    },
  ],
  dashboardTable: {
    commandTableHeader: [
      {
        id: 6,
        label: 'Command',
        columnName: 'command',
        align: 'left',
      },
      {
        id: 1,
        label: 'Total',
        columnName: 'total',
      },
      {
        id: 2,
        label: 'Success',
        columnName: 'success',
      },
      {
        id: 3,
        label: 'Failed',
        columnName: 'failed',
      },
    ],
    ingestionsTableHeader: [
      {
        id: 1,
        label: 'Request',
        columnName: 'request',
        align: 'left',
      },
      {
        id: 2,
        label: 'Description',
        columnName: 'description',
      },
      {
        id: 3,
        label: 'Total',
        columnName: 'total',
      },
      {
        id: 4,
        label: 'Success',
        columnName: 'success',
      },
      {
        id: 5,
        label: 'Failed',
        columnName: 'failed',
      },
      {
        id: 6,
        label: 'In Progress',
        columnName: 'inProgress',
      },
      {
        id: 7,
        label: 'Overall Status',
        columnName: 'overallStatus',
      },
    ],
  },
  transferSubresellerHeaders: [
    { id: 1, label: 'ID', columnName: 'id', sx: { paddingLeft: 2 } },
    { id: 2, label: 'Support Ticket', columnName: 'support-ticket', sx: { paddingLeft: 2 } },
    { id: 3, label: 'Escalation Ticket', columnName: 'escalation-ticket', sx: { paddingLeft: 2 } },
    { id: 4, label: 'Description', columnName: 'description', sx: { paddingLeft: 2 } },
    {
      id: 5,
      label: 'Source Distributor ID',
      columnName: 'sourceDistributorID',
      sx: { paddingLeft: 2 },
    },
    {
      id: 6,
      label: 'Target Distributor ID',
      columnName: 'targetDistributorID',
      sx: { paddingLeft: 2 },
    },
    {
      id: 7,
      label: 'Reseller Id To Be Transferred',
      columnName: 'resellerIDToBeTransferred',
      sx: { paddingLeft: 2 },
    },
    {
      id: 8,
      label: 'Effective Trasfer Date',
      columnName: 'effectiveTrasferDate',
      sx: { paddingLeft: 2 },
    },
    { id: 9, label: 'Target Currency', columnName: 'targetCurrency', sx: { paddingLeft: 2 } },
    { id: 10, label: 'New Archiver Price', columnName: 'newPriceArchiver', sx: { paddingLeft: 2 } },
    { id: 11, label: 'New Backup Price', columnName: 'newPriceBackup', sx: { paddingLeft: 2 } },
    { id: 12, label: 'New Qbo Price', columnName: 'newPriceQbo', sx: { paddingLeft: 2 } },
    {
      id: 13,
      label: 'New NfP Archiver Price',
      columnName: 'newPriceNfpArchiver',
      sx: { paddingLeft: 2 },
    },
    {
      id: 14,
      label: 'New NfP Backup Price',
      columnName: 'newPriceNfpBackup',
      sx: { paddingLeft: 2 },
    },
    { id: 15, label: 'Transfer Type', columnName: 'transferType', sx: { paddingLeft: 2 } },
    { id: 16, label: 'Target Owner', columnName: 'targetOwner', sx: { paddingLeft: 2 } },
    { id: 17, label: 'Target Channel', columnName: 'targetChannel', sx: { paddingLeft: 2 } },
    { id: 18, label: 'Status', columnName: 'status-description', sx: { paddingLeft: 2 } },
    { id: 19, label: 'Executed On', columnName: 'executed-on', sx: { paddingLeft: 2 } },
  ],
  transferSubresellerNewHeaders: [
    { id: 1, label: 'Support Ticket', columnName: 'supportTicket', sx: { paddingLeft: 2 } },
    { id: 2, label: 'Escalation Ticket', columnName: 'escalationTicket', sx: { paddingLeft: 2 } },
    { id: 3, label: 'Description', columnName: 'description', sx: { paddingLeft: 2 } },
    { id: 4, label: 'Transfer Type', columnName: 'transferType', sx: { paddingLeft: 2 } },
    {
      id: 5,
      label: 'Reseller ID to be transferred',
      columnName: 'resellerIDToBeTransferred',
      sx: { paddingLeft: 2 },
    },
    {
      id: 6,
      label: 'Target Distributor ID',
      columnName: 'targetDistributorID',
      sx: { paddingLeft: 2 },
    },
    {
      id: 7,
      label: 'Effective Trasfer Data',
      columnName: 'effectiveTrasferData',
      sx: { paddingLeft: 2 },
    },
    { id: 8, label: 'New Price Archiver', columnName: 'newPriceArchiver', sx: { paddingLeft: 2 } },
    { id: 9, label: 'New Price Backup', columnName: 'newPriceBackup', sx: { paddingLeft: 2 } },
    { id: 10, label: 'New Price Qbo', columnName: 'newPriceQbo', sx: { paddingLeft: 2 } },
    {
      id: 11,
      label: 'New NfP Archiver Price',
      columnName: 'newPriceNfpArchiver',
      sx: { paddingLeft: 2 },
    },
    {
      id: 12,
      label: 'New NfP Backup Price',
      columnName: 'newPriceNfpBackup',
      sx: { paddingLeft: 2 },
    },
    { id: 13, label: 'Target Currency', columnName: 'targetCurrency', sx: { paddingLeft: 2 } },
    { id: 14, label: 'Billing Type', columnName: 'billingType', sx: { paddingLeft: 2 } },
    { id: 15, label: 'Payment Type', columnName: 'paymentType', sx: { paddingLeft: 2 } },
  ],
  transferSubscriptionHeaders: [
    { id: 1, label: 'ID', columnName: 'id', sx: { paddingLeft: 2 } },
    { id: 2, label: 'Support Ticket', columnName: 'support-ticket', sx: { paddingLeft: 2 } },
    { id: 3, label: 'Escalation Ticket', columnName: 'escalation-ticket', sx: { paddingLeft: 2 } },
    { id: 4, label: 'Description', columnName: 'description', sx: { paddingLeft: 2 } },
    {
      id: 5,
      label: 'Reseller Request ID',
      columnName: 'reseller-request-id',
      sx: { paddingLeft: 2 },
    },
    {
      id: 6,
      label: 'Status',
      columnName: 'status-description',
      sx: { paddingLeft: 2 },
    },
    { id: 7, label: 'Executed On', columnName: 'executed-on', sx: { paddingLeft: 2 } },
  ],
  transferSubscriptionInitiatePageHeaders: [
    {
      id: 1,
      label: 'Organization Owner ID',
      columnName: 'org-owner-id',
      sx: { paddingLeft: 2 },
      size: 190,
    },
    { id: 2, label: 'Support Ticket', columnName: 'support-ticket', sx: { paddingLeft: 2 } },
    { id: 3, label: 'Escalation Ticket', columnName: 'escalation-ticket', sx: { paddingLeft: 2 } },
    { id: 4, label: 'Description', columnName: 'description', sx: { paddingLeft: 2 } },
    {
      id: 5,
      label: 'Target Partner ID',
      columnName: 'target-partner-id',
      sx: { paddingLeft: 2 },
    },
    {
      id: 6,
      label: 'Request ID',
      columnName: 'request-id',
      sx: { paddingLeft: 2 },
    },
    {
      id: 7,
      label: 'Status',
      columnName: 'status-description',
      sx: { paddingLeft: 2 },
    },
    { id: 7, label: 'Executed On', columnName: 'executed-on', sx: { paddingLeft: 2 } },
  ],

  partnerListHeaders: [
    { id: 1, label: 'ID', columnName: 'id', sx: { paddingLeft: 2 }, size: 70 },
    {
      id: 2,
      label: 'Partner Type',
      columnName: 'partnerType',
      sx: { paddingLeft: 2 },
      size: 120,
    },
    {
      id: 3,
      label: 'Name',
      columnName: 'name',
      sx: { paddingLeft: 2 },
      size: 170,
    },
    { id: 4, label: 'Status', columnName: 'status', sx: { paddingLeft: 2 }, size: 50 },
    { id: 5, label: 'Country', columnName: 'country', sx: { paddingLeft: 2 }, size: 100 },
    // { id: 6, label: 'Region', columnName: 'region', sx: { paddingLeft: 2 }, size: 110 },
    { id: 7, label: 'Parent', columnName: 'parent', sx: { paddingLeft: 2 }, size: 100 },
    {
      id: 8,
      label: 'Owner email',
      columnName: 'ownerEmail',
      sx: { paddingLeft: 2 },
      size: 150,
    },
    { id: 9, label: 'Sales Owner', columnName: 'salesOwner', sx: { paddingLeft: 2 }, size: 80 },
    {
      id: 10,
      label: 'Integration type',
      columnName: 'integrationType',
      sx: { paddingLeft: 2 },
      size: 140,
    },
    {
      id: 11,
      label: 'Last invoice Amount',
      columnName: 'lastInvoiceAmount',
      sx: { paddingLeft: 2 },
      size: 100,
    },
    {
      id: 12,
      label: 'Last Invoice Due Date',
      columnName: 'lastInvoiceDueDate',
      sx: { paddingLeft: 2 },
      size: 180,
    },
    { id: 13, label: 'Invoices Due', columnName: 'invoicesDue', sx: { paddingLeft: 2 }, size: 80 },
    {
      id: 14,
      label: 'Last payment Date',
      columnName: 'lastPaymentDate',
      sx: { paddingLeft: 2 },
      size: 180,
    },
    { id: 15, label: 'No of Users', columnName: 'numberOfUsers', sx: { paddingLeft: 2 }, size: 80 },
    {
      id: 16,
      label: 'No of Sub-resellers',
      columnName: 'numberOfSubResellers',
      sx: { paddingLeft: 2 },
      size: 80,
    },
    {
      id: 17,
      label: 'No of Organization',
      columnName: 'numberOfOrganisations',
      sx: { paddingLeft: 2 },
      size: 80,
    },
    { id: 18, label: 'Created on', columnName: 'createdOn', sx: { paddingLeft: 2 }, size: 180 },
  ],
  subResellerHeaders: [
    { id: 1, label: 'ID', columnName: 'id', sx: { paddingLeft: 2 }, size: 100 },
    { id: 2, label: 'Partner Type', columnName: 'partnerType', sx: { paddingLeft: 2 }, size: 130 },
    { id: 3, label: 'Name', columnName: 'name', sx: { paddingLeft: 2 } },
    { id: 4, label: 'Status', columnName: 'status', sx: { paddingLeft: 2 }, size: 110 },
    { id: 5, label: 'Country', columnName: 'country', sx: { paddingLeft: 2 }, size: 110 },
    { id: 6, label: 'Parent', columnName: 'parent', sx: { paddingLeft: 2 } },
    { id: 7, label: 'Owner email', columnName: 'ownerEmail', sx: { paddingLeft: 2 } },
    { id: 8, label: 'Sales Owner', columnName: 'salesOwner', sx: { paddingLeft: 2 }, size: 120 },
    {
      id: 9,
      label: 'Integration type',
      columnName: 'integrationType',
      sx: { paddingLeft: 2 },
      size: 120,
    },
    {
      id: 10,
      label: 'Last invoice Amount',
      columnName: 'lastInvoiceAmount',
      sx: { paddingLeft: 2 },
    },
    {
      id: 11,
      label: 'Last Invoice Due Date',
      columnName: 'lastInvoiceDueDate',
      sx: { paddingLeft: 2 },
    },
    { id: 12, label: 'Invoices Due', columnName: 'invoicesDue', sx: { paddingLeft: 2 }, size: 100 },
    { id: 13, label: 'Last payment Date', columnName: 'lastPaymentDate', sx: { paddingLeft: 2 } },
    {
      id: 14,
      label: 'No of Users',
      columnName: 'numberOfUsers',
      sx: { paddingLeft: 2 },
      size: 100,
    },
    {
      id: 15,
      label: 'No of Organization',
      columnName: 'numberOfOrganisations',
      sx: { paddingLeft: 2 },
      size: 120,
    },
    { id: 16, label: 'Created on', columnName: 'createdOn', sx: { paddingLeft: 2 } },
  ],
  organizationListHeaders: [
    {
      id: 1,
      label: 'Organization Owner Id/Client ID',
      columnName: 'organizationId',
      sx: { paddingLeft: 2 },
      size: 120,
    },
    { id: 2, label: 'Organization Name', columnName: 'orgName', sx: { paddingLeft: 2 } },
    { id: 3, label: 'Owner email', columnName: 'ownerEmail', sx: { paddingLeft: 2 } },
    { id: 4, label: 'Plan', columnName: 'plan', sx: { paddingLeft: 2 } },
    { id: 5, label: 'License', columnName: 'license', sx: { paddingLeft: 2 }, size: 100 },
    { id: 6, label: 'Status', columnName: 'status', sx: { paddingLeft: 2 }, size: 100 },
    { id: 7, label: 'Partner/sub-reseller Name', columnName: 'partner', sx: { paddingLeft: 2 } },
    {
      id: 8,
      label: 'Seats Purchased',
      columnName: 'seatsPurchased',
      sx: { paddingLeft: 2 },
      size: 100,
    },
    { id: 9, label: 'Seats Used', columnName: 'seatsUsed', sx: { paddingLeft: 2 }, size: 100 },
    { id: 10, label: 'Created on', columnName: 'createdOn', sx: { paddingLeft: 2 } },
    { id: 11, label: 'Country', columnName: 'country', sx: { paddingLeft: 2 }, size: 100 },
    {
      id: 12,
      label: 'Last invoice Status',
      columnName: 'lastInvoiceStatus',
      sx: { paddingLeft: 2 },
    },
    { id: 13, label: 'Auto License', columnName: 'autoLicense', sx: { paddingLeft: 2 }, size: 100 },
    {
      id: 14,
      label: 'Storage Location',
      columnName: 'storageLocation',
      sx: { paddingLeft: 2 },
      size: 100,
    },
  ],
  userListHeaders: [
    { id: 1, label: 'ID', columnName: 'userID', sx: { paddingLeft: 2 }, size: 100 },
    { id: 2, label: 'Partner ID', columnName: 'id', sx: { paddingLeft: 2 }, size: 100 },
    { id: 3, label: 'Name', columnName: 'name', sx: { paddingLeft: 2 } },
    { id: 4, label: 'Role', columnName: 'role', sx: { paddingLeft: 2 } },
    { id: 5, label: 'Email', columnName: 'email', sx: { paddingLeft: 2 } },
    { id: 6, label: 'Partner Name', columnName: 'partnerName', sx: { paddingLeft: 2 } },
    { id: 7, label: 'Country', columnName: 'country', sx: { paddingLeft: 2 }, size: 100 },
    { id: 8, label: 'Time Zone', columnName: 'timeZone', sx: { paddingLeft: 2 } },
    {
      id: 9,
      label: 'Current Login Country',
      columnName: 'currentLoginCountry',
      sx: { paddingLeft: 2 },
    },
    { id: 10, label: 'External ID', columnName: 'externalID', sx: { paddingLeft: 2 } },
    { id: 11, label: 'Created On', columnName: 'createdOn', sx: { paddingLeft: 2 } },
    { id: 12, label: 'Last Updated At', columnName: 'lastUpdatedAt', sx: { paddingLeft: 2 } },
    { id: 13, label: 'Deleted On', columnName: 'deletedOn', sx: { paddingLeft: 2 } },
    { id: 14, label: 'Invitation Sent On', columnName: 'invitationSentOn', sx: { paddingLeft: 2 } },
    {
      id: 15,
      label: 'Login Disabled',
      columnName: 'loginDisabled',
      sx: { paddingLeft: 2 },
      size: 100,
    },
    {
      id: 16,
      label: 'Pre Login Disabled',
      columnName: 'preLoginDisabled',
      sx: { paddingLeft: 2 },
      size: 100,
    },
    {
      id: 17,
      label: 'Sign In Count',
      columnName: 'signInCount',
      sx: { paddingLeft: 2 },
      size: 100,
    },
    {
      id: 18,
      label: 'Failed Login Attempts',
      columnName: 'failedLoginAttempts',
      sx: { paddingLeft: 2 },
      size: 120,
    },
    {
      id: 19,
      label: 'Last Failed Login Attempt',
      columnName: 'lastFailedLoginAttempt',
      sx: { paddingLeft: 2 },
    },
    { id: 20, label: 'Last Login On', columnName: 'lastLoginOn', sx: { paddingLeft: 2 } },
    {
      id: 21,
      label: 'Last Login IP',
      columnName: 'lastLoginIP',
      sx: { paddingLeft: 2 },
    },
    {
      id: 22,
      label: 'Last Reset Password Sent On',
      columnName: 'lastResetPassworSentOn',
      sx: { paddingLeft: 2 },
    },
    { id: 23, label: '2FA Status', columnName: 'tfaStatus', sx: { paddingLeft: 2 }, size: 100 },
    { id: 24, label: '2FA Generated On', columnName: 'tfaGeneratedON', sx: { paddingLeft: 2 } },
    {
      id: 25,
      label: 'Failed 2FA Attempts',
      columnName: 'failed2FAAttempts',
      sx: { paddingLeft: 2 },
      size: 100,
    },
    { id: 26, label: 'Last Failed 2FA On', columnName: 'lastFailed2FAOn', sx: { paddingLeft: 2 } },
    { id: 27, label: 'Reset 2FA Sent On', columnName: 'reset2FASentOn', sx: { paddingLeft: 2 } },
    { id: 28, label: 'Azure AD SSO', columnName: 'sso', sx: { paddingLeft: 2 }, size: 100 },
    { id: 29, label: 'GWS SSO', columnName: 'gwsSSO', sx: { paddingLeft: 2 }, size: 100 },
    {
      id: 30,
      label: 'SSO Link Requested By',
      columnName: 'ssoLinkRequestedBy',
      sx: { paddingLeft: 2 },
    },
    {
      id: 31,
      label: 'Backup Notification',
      columnName: 'backupNotification',
      sx: { paddingLeft: 2 },
      size: 100,
    },
    {
      id: 32,
      label: 'Capacity Notification',
      columnName: 'capacityNotification',
      sx: { paddingLeft: 2 },
      size: 100,
    },
    {
      id: 33,
      label: 'Email Account Failure Notification',
      columnName: 'emailAccountFailureNotification',
      sx: { paddingLeft: 2 },
    },
    {
      id: 34,
      label: 'Email Account Success Notification',
      columnName: 'emailAccountSuccessNotification',
      sx: { paddingLeft: 2 },
    },
    {
      id: 35,
      label: 'Failure Notification',
      columnName: 'failureNotification',
      sx: { paddingLeft: 2 },
      size: 100,
    },
    {
      id: 36,
      label: 'Features Notification',
      columnName: 'featuresNotification',
      sx: { paddingLeft: 2 },
      size: 100,
    },
    {
      id: 37,
      label: 'Reseller General Notification',
      columnName: 'resellerGeneralNotification',
      sx: { paddingLeft: 2 },
    },
    {
      id: 38,
      label: 'Reseller Invoice Notification',
      columnName: 'resellerInvoiceNotification',
      sx: { paddingLeft: 2 },
    },
    {
      id: 39,
      label: 'Reseller Support Notification',
      columnName: 'resellerSupportNotification',
      sx: { paddingLeft: 2 },
    },
  ],
  dataIngestionListHeaders: [
    { id: 1, label: 'Ingestion Job ID', columnName: 'ingestion-id', sx: { paddingLeft: 2 } },
    { id: 2, label: 'Description', columnName: 'description', sx: { paddingLeft: 2 } },
    {
      id: 3,
      label: 'Organization Owner Id',
      columnName: 'account-id',
      sx: { paddingLeft: 2 },
      size: 190,
    },
    { id: 4, label: 'Ingestion Type', columnName: 'ingestion-type', sx: { paddingLeft: 2 } },
    { id: 5, label: 'Storage Type', columnName: 'storage-type', sx: { paddingLeft: 2 } },
    { id: 6, label: 'Status', columnName: 'status', sx: { paddingLeft: 2 } },
    { id: 7, label: 'Status Description', columnName: 'message', sx: { paddingLeft: 2 } },
    { id: 8, label: 'Executed On', columnName: 'created-on', sx: { paddingLeft: 2 } },
    { id: 9, label: 'Queued', columnName: 'queued', sx: { paddingLeft: 2 } },
    { id: 10, label: 'In Progress', columnName: 'in-progress', sx: { paddingLeft: 2 } },
    { id: 11, label: 'Success', columnName: 'success', sx: { paddingLeft: 2 } },
    { id: 12, label: 'Failed', columnName: 'failed', sx: { paddingLeft: 2 } },
    { id: 13, label: 'Download Failed', columnName: 'download-failed', sx: { paddingLeft: 2 } },
  ],
  dataIngestionListItemHeaders: [
    { id: 1, label: 'ID', columnName: 'id', sx: { paddingLeft: 2 } },
    { id: 2, label: 'Email Account', columnName: 'email-account-id', sx: { paddingLeft: 2 } },
    { id: 3, label: 'File Name', columnName: 'file-name', sx: { paddingLeft: 2 } },
    { id: 4, label: 'Status', columnName: 'status', sx: { paddingLeft: 2 } },
    {
      id: 5,
      label: 'Status Description',
      columnName: 'status-description',
      sx: { paddingLeft: 2 },
    },
    { id: 6, label: 'Created On', columnName: 'created-on', sx: { paddingLeft: 2 } },
    { id: 7, label: 'Retry count', columnName: 'retry-count', sx: { paddingLeft: 2 } },
    { id: 8, label: 'Last Updated', columnName: 'last-updated', sx: { paddingLeft: 2 } },
  ],
  ingestionListTableHeaders: [
    { id: 1, label: 'Account ID', columnName: 'accountId' },
    { id: 2, label: 'Storage Type', columnName: 'storageType' },
    { id: 3, label: 'Mapping FIle', columnName: 'mappingFile' },
    { id: 4, label: 'FTP Url', columnName: 'ftpUrl' },
    { id: 5, label: 'FTP UserName', columnName: 'ftpUserName' },
    { id: 6, label: 'FTP Password', columnName: 'ftpPassword' },
    { id: 7, label: 'S3 Bucket Name', columnName: 's3BucketName' },
    { id: 8, label: 'S3 Key', columnName: 's3Key' },
  ],
  ingestionReportsListHeaders: [
    {
      id: 1,
      label: 'Total emails provided for Ingestion',
      columnName: 'total-count',
      sx: { paddingLeft: 2 },
    },
    { id: 2, label: 'Emails Ingested', columnName: 'success-count', sx: { paddingLeft: 2 } },
    {
      id: 3,
      label: 'Emails Failed',
      columnName: 'failure-count',
      sx: { paddingLeft: 2 },
      size: 190,
    },
    {
      id: 4,
      label: 'Download Time Taken (Minutes)',
      columnName: 'download-time-taken',
      sx: { paddingLeft: 2 },
    },
    {
      id: 5,
      label: 'Ingestion Time Taken (Minutes)',
      columnName: 'time-taken',
      sx: { paddingLeft: 2 },
    },
    {
      id: 6,
      label: 'Total Time (Minutes)',
      columnName: 'total-time-taken',
      sx: { paddingLeft: 2 },
    },
    {
      id: 7,
      label: 'Ingested Data Size (MB)',
      columnName: 'ingested-data-size',
      sx: { paddingLeft: 2 },
    },
    { id: 8, label: 'PST/Zip Size (MB)', columnName: 'size', sx: { paddingLeft: 2 } },
    { id: 9, label: 'Indexed Items', columnName: 'indexed-items', sx: { paddingLeft: 2 } },
    { id: 10, label: 'Duplicate Items', columnName: 'duplicate-items', sx: { paddingLeft: 2 } },
  ],

  failedEmlIngestionPageHeaders: [
    {
      id: 1,
      label: 'Eml File',
      columnName: 'file-name',
      sx: { paddingLeft: 2 },
    },
    {
      id: 2,
      label: 'Error Description',
      columnName: 'failure-message',
      sx: { paddingLeft: 2 },
    },
  ],

  seatUsageSummaryTableHeaders: [
    { id: 1, label: 'Category', columnName: 'category', sx: { paddingLeft: 2 } },
    { id: 2, label: 'Counts', columnName: 'counts', sx: { paddingLeft: 2 } },
  ],

  seatUsageDetailsTableHeaders: [
    { id: 1, label: 'Email', columnName: 'email', sx: { paddingLeft: 2 } },
    {
      id: 2,
      label: 'Deactivated Since',
      columnName: 'deactivated-since',
      sx: { paddingLeft: 2 },
    },
    { id: 3, label: 'Billable', columnName: 'billable', sx: { paddingLeft: 2 } },
    {
      id: 4,
      label: 'Has M365 License',
      columnName: 'has-m365-license',
      sx: { paddingLeft: 2 },
    },
    {
      id: 5,
      label: 'Public Folder',
      columnName: 'public-folder',
      sx: { paddingLeft: 2 },
    },
    { id: 6, label: 'Status', columnName: 'status', sx: { paddingLeft: 2 } },
    {
      id: 7,
      label: 'Needs Dropsuite License',
      columnName: 'needs-dropsuite-license',
      sx: { paddingLeft: 2 },
    },
    {
      id: 8,
      label: 'Domain',
      columnName: 'domain',
      sx: { paddingLeft: 2 },
    },
    {
      id: 9,
      label: 'Protocol',
      columnName: 'protocol',
      sx: { paddingLeft: 2 },
    },
    {
      id: 10,
      label: 'Organization Name',
      columnName: 'organization-name',
      sx: { paddingLeft: 2 },
    },
    {
      id: 11,
      label: 'Partner Name',
      columnName: 'partner-name',
      sx: { paddingLeft: 2 },
    },
  ],

  monthlyBackupDetailsTableHeaders: [
    { id: 1, label: 'Organization Id', columnName: 'organization-Id', sx: { paddingLeft: 2 } },
    {
      id: 2,
      label: 'Name',
      columnName: 'name',
      sx: { paddingLeft: 2 },
    },
    { id: 3, label: 'Total In GB', columnName: 'total-In-Gb', sx: { paddingLeft: 2 } },
    {
      id: 4,
      label: 'Mailbox Number',
      columnName: 'mailbox-Num',
      sx: { paddingLeft: 2 },
    },
    {
      id: 5,
      label: 'Drive Number',
      columnName: 'drive-Num',
      sx: { paddingLeft: 2 },
    },
    { id: 6, label: 'Sites Number', columnName: 'sites-Num', sx: { paddingLeft: 2 } },
  ],

  mailboxBulkPurgeTableHeaders: [
    { id: 1, label: 'ID', columnName: 'id', sx: { paddingLeft: 2 } },
    { id: 2, label: 'Support Ticket', columnName: 'support-ticket', sx: { paddingLeft: 2 } },
    { id: 3, label: 'Escalation Ticket', columnName: 'escalation-ticket', sx: { paddingLeft: 2 } },
    { id: 4, label: 'Owner ID', columnName: 'org-owner-id', sx: { paddingLeft: 2 } },
    {
      id: 5,
      label: 'Status',
      columnName: 'status-description',
      sx: { paddingLeft: 2 },
    },
    { id: 6, label: 'Executed On', columnName: 'executed-on', sx: { paddingLeft: 2 } },
    { id: 7, label: 'In Progress', columnName: 'in-progress', sx: { paddingLeft: 2 } },
    { id: 8, label: 'Success', columnName: 'success', sx: { paddingLeft: 2 } },
    { id: 9, label: 'Error', columnName: 'error', sx: { paddingLeft: 2 } },
  ],
  mailboxBulkPurgeListTableHeaders: [
    { id: 1, label: 'ID', columnName: 'id', sx: { paddingLeft: 2 } },
    { id: 2, label: 'Email Account', columnName: 'mail-account', sx: { paddingLeft: 2 } },
    {
      id: 3,
      label: 'Status',
      columnName: 'status-description',
      sx: { paddingLeft: 2 },
    },
  ],
  mailboxBulkPurgeStatusHeaders: [
    { id: 1, label: 'In Progress', columnName: 'in-progress-count', sx: { paddingLeft: 2 } },
    { id: 2, label: 'Success', columnName: 'success-count', sx: { paddingLeft: 2 } },
    { id: 3, label: 'Error', columnName: 'error-count', sx: { paddingLeft: 2 } },
  ],
  purgeExternalAccounts: [
    { id: 1, label: 'Owner ID', columnName: 'org-owner-id', sx: { paddingLeft: 2 } },
    { id: 2, label: 'Support Ticket', columnName: 'support-ticket', sx: { paddingLeft: 2 } },
    { id: 3, label: 'Escalation Ticket', columnName: 'escalation-ticket', sx: { paddingLeft: 2 } },
    { id: 4, label: 'Description', columnName: 'description', sx: { paddingLeft: 2 } },
    {
      id: 5,
      label: 'External Email Account',
      columnName: 'external-email-account',
      sx: { paddingLeft: 2 },
    },
    { id: 6, label: 'Executed On', columnName: 'executed-on', sx: { paddingLeft: 2 } },
    { id: 7, label: 'Status', columnName: 'status-description', sx: { paddingLeft: 2 } },
  ],
  plansListHeaders: [
    { id: 1, label: 'ID', columnName: 'planId', sx: { paddingLeft: 2 }, size: 100 },
    { id: 2, label: 'Plan Name', columnName: 'planName', sx: { paddingLeft: 2 } },
    { id: 3, label: 'Plan Type', columnName: 'planType', sx: { paddingLeft: 2 }, size: 100 },
    { id: 4, label: 'License Type', columnName: 'licenseType', sx: { paddingLeft: 2 }, size: 100 },
    { id: 5, label: 'Plan Price', columnName: 'planPrice', sx: { paddingLeft: 2 }, size: 100 },
    {
      id: 6,
      label: 'End User Currency',
      columnName: 'endUserCurrency',
      sx: { paddingLeft: 2 },
      size: 100,
    },
    {
      id: 7,
      label: 'End User Price',
      columnName: 'endUserPrice',
      sx: { paddingLeft: 2 },
      size: 100,
    },
    { id: 8, label: 'Plan Status', columnName: 'planStatus', sx: { paddingLeft: 2 }, size: 100 },
    { id: 9, label: 'Partner Name', columnName: 'partnerName', sx: { paddingLeft: 2 } },
    { id: 10, label: 'Seats Used', columnName: 'usedSeat', sx: { paddingLeft: 2 }, size: 100 },
    { id: 11, label: 'Is NFR', columnName: 'isNFR', sx: { paddingLeft: 2 }, size: 100 },
    { id: 12, label: 'Last Updated At', columnName: 'lastUpdatedAt', sx: { paddingLeft: 2 } },
    { id: 13, label: 'Deleted Reason', columnName: 'deletedReason', sx: { paddingLeft: 2 } },
    { id: 14, label: 'Deleted At', columnName: 'deletedAt', sx: { paddingLeft: 2 } },
    { id: 15, label: 'From NPP', columnName: 'fromNPP', sx: { paddingLeft: 2 }, size: 100 },
    {
      id: 16,
      label: 'No Of Organizations',
      columnName: 'numberOfOrganizations',
      sx: { paddingLeft: 2 },
      size: 100,
    },
    { id: 17, label: 'Created On', columnName: 'createdOn', sx: { paddingLeft: 2 } },
  ],

  purgeSharedDrives: [
    { id: 1, label: 'Owner ID', columnName: 'org-owner-id', sx: { paddingLeft: 2 }, size: 100 },
    { id: 2, label: 'Support Ticket', columnName: 'support-ticket', sx: { paddingLeft: 2 } },
    { id: 3, label: 'Escalation Ticket', columnName: 'escalation-ticket', sx: { paddingLeft: 2 } },
    { id: 4, label: 'Description', columnName: 'description', sx: { paddingLeft: 2 } },
    { id: 5, label: 'Domain Name', columnName: 'domain-name', sx: { paddingLeft: 2 } },
    { id: 6, label: 'Executed On', columnName: 'executed-on', sx: { paddingLeft: 2 } },
    { id: 7, label: 'Status', columnName: 'status-description', sx: { paddingLeft: 2 } },
  ],
  domainBackupsHeaders: [
    {
      id: 1,
      label: 'Organization Owner ID',
      columnName: 'org-owner-id',
      align: 'center',
      sx: { paddingLeft: 2 },
      size: 190,
    },
    { id: 2, label: 'Support Ticket', columnName: 'support-ticket', sx: { paddingLeft: 2 } },
    { id: 3, label: 'Escalation Ticket', columnName: 'escalation-ticket', sx: { paddingLeft: 2 } },
    { id: 4, label: 'Description', columnName: 'description', sx: { paddingLeft: 2 } },
    { id: 5, label: 'Domain', columnName: 'domain', sx: { paddingLeft: 2 } },
    { id: 6, label: 'Status', columnName: 'status-description', sx: { paddingLeft: 2 } },
    { id: 7, label: 'Executed On', columnName: 'executed-on', sx: { paddingLeft: 2 } },
  ],

  SeatCountChanges: [
    { id: 1, label: 'Owner ID', columnName: 'org-owner-id', sx: { paddingLeft: 2 } },
    { id: 2, label: 'Support Ticket', columnName: 'support-ticket', sx: { paddingLeft: 2 } },
    { id: 3, label: 'Escalation Ticket', columnName: 'escalation-ticket', sx: { paddingLeft: 2 } },
    { id: 4, label: 'Description', columnName: 'description', sx: { paddingLeft: 2 } },
    { id: 5, label: 'Seat Count', columnName: 'no-of-seats', sx: { paddingLeft: 2 } },
    { id: 6, label: 'Executed On', columnName: 'executed-on', sx: { paddingLeft: 2 } },
    { id: 7, label: 'Status', columnName: 'status-description', sx: { paddingLeft: 2 } },
  ],

  accountStatusCode: { suspended: 1, approval: 2, active: 0 },
  backendDateTimeFormat: 'YYYY-MM-DDTHH:mm:ss.SSSSZ',
  s3StorageRegions: {
    AUS: { value: 'ap-southeast-2', label: 'Sydney (ap-southeast-2)' },
    EUC: { value: 'eu-central-1', label: 'Frankfurt (eu-central-1)' },
    EUW: { value: 'eu-west-1', label: 'Ireland (eu-west-1 )' },
    LON: { value: 'eu-west-2', label: 'London (eu-west-2)' },
    CAC: { value: 'ca-central-1', label: 'Canada (ca-central-1)' },
    SG: { value: 'ap-southeast-1', label: 'Singapore (ap-southeast-1)' },
    USW: { value: 'us-west-1', label: 'N.California (us-west-1)' },
    ORN: { value: 'us-west-2', label: 'Oregon (us-west-2)' },
    TOK: { value: 'ap-northeast-1', label: 'Tokyo (ap-northeast-1)' },
  },
};

export default BaseSetting;
