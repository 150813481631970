import { createSlice } from '@reduxjs/toolkit';
import {
  getMailBoxChangeAsync,
  getMailboxChangeReducers,
} from './serviceActions/mailBoxChangeRequestAsync';

const initialState = {
  mailChangeRequestState: {
    fetching: false,
    success: false,
    data: [],
    error: null,
    errorMessage: null,
    pagination: [],
    forbiddenState: false,
    forbiddenErrorMessage: {},
    mailChangeCorsErrorMessage: null,
  },
};

const mailChangeRequestSlice = createSlice({
  name: 'MailChange',
  initialState,
  reducers: {
    resetMailChangeRequestStatus(state) {
      state.mailChangeRequestState = initialState.mailChangeRequestState;
    },
  },
  extraReducers: {
    ...getMailboxChangeReducers,
  },
});

export { getMailBoxChangeAsync };
export const { resetMailChangeRequestStatus } = mailChangeRequestSlice.actions;
export default mailChangeRequestSlice.reducer;
