import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ITBox, QboAlert, QboFormGroup, QboTextField } from '@ui/Components';
import { useDispatch, useSelector } from 'react-redux';
import QboRadioButton from '@ui/Components/QboRadioButton';
import { resetIngestionJobFormSubmit } from '@features/ingestion/ingestionJobSlice';

export default function DataIngestionJobFormSection(props) {
  const isAstericRequired = true;
  const { t } = useTranslation();
  const inputRef = useRef(null);
  const [value, setValue] = useState('');
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  const {
    organizationOwnerIdAttributes,
    mappingFileAttributes,
    oneItemStructureAttributes,
    multipleItemStructureAttributes,
    emailAccountStructureAttributes,
    fileNameStructureAttributes,
    ingestionID,
    resetForm,
    onSubmit,
  } = props;

  const radioButtonData = [
    {
      id: 1,
      value: 'one',
      radioLabel: `${t('data_ingestion_job_page.modal.form.one_item_select')}`,
    },
    {
      id: 2,
      value: 'multi',
      radioLabel: `${t('data_ingestion_job_page.modal.form.multi_item')}`,
    },
  ];

  useEffect(() => {
    if (value === 'one') {
      dispatch(resetIngestionJobFormSubmit());
      resetForm();
      multipleItemStructureAttributes.handleChange('');
      oneItemStructureAttributes.handleChange(value);
    } else if (value === 'multi') {
      dispatch(resetIngestionJobFormSubmit());
      resetForm();
      oneItemStructureAttributes.handleChange('');
      multipleItemStructureAttributes.handleChange(value);
    }
  }, [value]);

  const { submitIngestionJobForm } = useSelector((state) => state.ingestionJob);
  const { statusCode, errorMessage } = submitIngestionJobForm;

  useEffect(() => {
    organizationOwnerIdAttributes.handleChange(ingestionID);
  }, [ingestionID, value]);

  useEffect(() => {
    if (statusCode === 200 || statusCode === 403) {
      resetForm();
      setValue('');
      if (inputRef.current?.value) {
        inputRef.current.value = null;
      }
    }
  }, [statusCode]);

  return (
    <ITBox className="TransferSubscriptionFormPage__wrapper" direction="column">
      <QboFormGroup onSubmit={onSubmit} withSubmitButton buttonDisabled={Boolean(!value)}>
        {(errorMessage || statusCode) && (
          <QboAlert type={statusCode === 200 ? 'success' : 'error'} style={{ fontSize: '1.15em' }}>
            {errorMessage || (statusCode === 200 && 'Successfully submitted')}
          </QboAlert>
        )}
        <QboRadioButton
          radioButtonData={radioButtonData}
          value={value}
          setValue={setValue}
          error={error}
          setError={setError}
        />

        {value === 'one' && (
          <>
            <QboTextField
              id="accountId"
              required={isAstericRequired}
              placeholder={t('data_ingestion_job_page.modal.form.email_account')}
              label={t('data_ingestion_job_page.modal.form.email_account')}
              fullWidth
              inputProps={{ maxLength: 225 }}
              value={emailAccountStructureAttributes.value}
              onChange={(e) => {
                emailAccountStructureAttributes.handleChange(e.currentTarget.value);
              }}
              errorMessage={emailAccountStructureAttributes.errorMessage}
            />
            <QboTextField
              id="accountId"
              required={isAstericRequired}
              placeholder={t('data_ingestion_job_page.modal.form.file_name')}
              label={t('data_ingestion_job_page.modal.form.file_name')}
              fullWidth
              inputProps={{ maxLength: 225 }}
              value={fileNameStructureAttributes.value}
              onChange={(e) => {
                fileNameStructureAttributes.handleChange(e.currentTarget.value);
              }}
              errorMessage={fileNameStructureAttributes.errorMessage}
            />
          </>
        )}

        {value === 'multi' && (
          <QboTextField
            id="mappingFile"
            required={isAstericRequired}
            placeholder={t('data_ingestion_job_page.modal.form.mapping_file')}
            label={t('data_ingestion_job_page.modal.form.mapping_file')}
            type="file"
            inputRef={inputRef}
            fullWidth
            inputProps={{ maxLength: 225 }}
            value={mappingFileAttributes?.value?.file}
            onChange={(e) => {
              mappingFileAttributes.handleChange(e?.target?.files[0]);
            }}
            errorMessage={mappingFileAttributes.errorMessage}
          />
        )}
      </QboFormGroup>
    </ITBox>
  );
}

DataIngestionJobFormSection.propTypes = {
  organizationOwnerIdAttributes: PropTypes.object,
  mappingFileAttributes: PropTypes.object,
  oneItemStructureAttributes: PropTypes.object,
  multipleItemStructureAttributes: PropTypes.object,
  emailAccountStructureAttributes: PropTypes.object,
  fileNameStructureAttributes: PropTypes.object,
  ingestionID: PropTypes.number,
  resetForm: PropTypes.func,
  onSubmit: PropTypes.func,
};

DataIngestionJobFormSection.defaultProps = {
  organizationOwnerIdAttributes: {},
  mappingFileAttributes: {},
  oneItemStructureAttributes: {},
  multipleItemStructureAttributes: {},
  emailAccountStructureAttributes: {},
  fileNameStructureAttributes: {},
  ingestionID: null,
  resetForm: () => {},
  onSubmit: () => {},
};
