import { createAsyncThunk } from '@reduxjs/toolkit';
import { transferSubscriptionValidateApi } from '@services/WebApiService';

const transferSubscriptionValidateAsync = createAsyncThunk(
  'TransferSubscription/validate',
  async (paramsList) => {
    const data = await transferSubscriptionValidateApi(paramsList);
    return data;
  }
);

const { pending, fulfilled, rejected } = transferSubscriptionValidateAsync;

const transferSubscriptionValidateExtraReducers = {
  [pending]: (state) => {
    state.transferSubscriptionValidate.submitting = true;
  },
  [rejected]: (state) => {
    state.transferSubscriptionValidate.submitting = false;
  },
  [fulfilled]: (state, action) => {
    state.transferSubscriptionValidate.submitting = false;

    if (action?.payload?.error) {
      state.transferSubscriptionValidate.error = action.payload.error;
    } else {
      state.transferSubscriptionValidate.success = true;
      state.transferSubscriptionValidate.value = action?.payload?.data;
    }
  },
};

export { transferSubscriptionValidateAsync, transferSubscriptionValidateExtraReducers };
