import { createSlice } from '@reduxjs/toolkit';
import {
  createSwitchCredentialAsync,
  createSwitchCredentialReducers,
} from './serviceActions/createSwitchCredentialAsync';

const initialState = {
  createSwitchCredentialState: {
    fetching: false,
    success: false,
    error: null,
    errorMessage: null,
    statusCode: null,
  },
};

const createSwitchCredentialSlice = createSlice({
  name: 'CreateSwitchCredentialState',
  initialState,
  reducers: {
    resetCreateSwitchCredentialState(state) {
      state.createSwitchCredentialState = initialState.createSwitchCredentialState;
    },
    resetCreateSwitchCredentialResponseState(state) {
      state.createSwitchCredentialState.statusCode = null;
      state.createSwitchCredentialState.errorMessage = null;
    },
  },
  extraReducers: {
    ...createSwitchCredentialReducers,
  },
});

export { createSwitchCredentialAsync };
export const { resetCreateSwitchCredentialState, resetCreateSwitchCredentialResponseState } =
  createSwitchCredentialSlice.actions;
export default createSwitchCredentialSlice.reducer;
