import { createSlice } from '@reduxjs/toolkit';

import {
  exportUserDiscrepancyReportAsync,
  exportUserDiscrepancyReportExtraReducers,
} from './serviceActions/exportUserDiscrepancyAsync';

const initialState = {
  exportUserDiscrepancyReportState: {
    fetching: false,
    status: null,
    error: null,
    message: null,
    errorMessage: null,
    data: null,
    contentType: null,
    forbiddenState: false,
    forbiddenErrorMessage: null,
    extportUserDiscrepancyReportCorsErrorMessage: null,
    serviceType: null,
  },
};

const exportUserDiscrepancyReportSlice = createSlice({
  name: 'exportUserDiscrepancyReport',
  initialState,
  reducers: {
    resetExportUserDiscrepancyReportState(state) {
      state.exportUserDiscrepancyReportState = initialState.exportUserDiscrepancyReportState;
    },
  },
  extraReducers: {
    ...exportUserDiscrepancyReportExtraReducers,
  },
});

export { exportUserDiscrepancyReportAsync };

export const { resetExportUserDiscrepancyReportState } = exportUserDiscrepancyReportSlice.actions;
export default exportUserDiscrepancyReportSlice.reducer;
