import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import {
  ITBox,
  QboAdvanceTable,
  QboButton,
  QboCard,
  QboFormGroup,
  QboModal,
  QboTextField,
  QboTypography,
} from '@ui/Components';
import React, { useState } from 'react';
import DistributorFilterSection from '@ui/Sections/Shared/DistributorFilterSection';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import QboSortByButton from '@ui/Components/QboSortByButton';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import useUserClaims from '@hooks/useUserClaims';
import XeroSyncModal from '@ui/Sections/InvoicePage/XeroSyncModal';
import useFeatureFlag from '@hooks/useFeatureFlag';
import useInvoicesService from './pageHooks/useInvoicesService';
import useDataService from './pageHooks/useDataService';

export default function InvoicesListPage({ resellerId }) {
  const { featureFlags, hasPermission } = useFeatureFlag();
  const claims = useUserClaims();
  const { t } = useTranslation();
  const [showAdFilter, setShowAdFilter] = useState(false);
  const [showXeroSyncModal, setShowXeroSyncModal] = useState(false);
  const invoicesService = useInvoicesService(resellerId);
  const dataService = useDataService();

  const formatPercent = (v) => {
    if (typeof v !== 'number') return v;
    return v.toLocaleString(navigator.language, {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const formatCurrency = (v, currency) => {
    if (!currency || typeof v !== 'number') return v;
    try {
      return v.toLocaleString(navigator.language, {
        style: 'currency',
        currency,
      });
    } catch {
      return v.toLocaleString(navigator.language, {
        maximumFractionDigits: 2,
      });
    }
  };

  const formatList = (list) =>
    list?.map((row) => {
      return {
        ...row,
        gst: formatPercent(row.gst),
        directUserEmailSubscriptionCost: formatCurrency(
          row.directUserEmailSubscriptionCost,
          row.currency
        ),
        subPartnerEmailSubscriptionCost: formatCurrency(
          row.subPartnerEmailSubscriptionCost,
          row.currency
        ),
        qboSubscriptionCost: formatCurrency(row.qboSubscriptionCost, row.currency),
        discount: formatCurrency(row.discount, row.currency),
        adjustmentAmount: formatCurrency(row.adjustmentAmount, row.currency),
        subTotal: formatCurrency(row.subTotal, row.currency),
        amountBeforeTax: formatCurrency(row.amountBeforeTax, row.currency),
        totalAmount: formatCurrency(row.totalAmount, row.currency),
      };
    }) ?? [];

  const allowXeroSync =
    !resellerId &&
    hasPermission(featureFlags.XeroSync) &&
    claims?.role &&
    ['superadmin', 'admin', 'finance'].includes(claims?.role?.toLowerCase());

  return (
    <>
      {!resellerId && <HeaderPageBlock>Invoices List</HeaderPageBlock>}
      <QboCard>
        <QboCard isMainWrapper>
          <QboFormGroup
            onSubmit={invoicesService.handleSubmit}
            buttonPostion="start"
            handleOnResetButton={invoicesService.handleReset}
            firstButtonLabel="Search"
            withGenerateAndResetButton
            loadingButton={invoicesService.listState.fetching}>
            <QboTextField
              id="searchText"
              value={invoicesService.searchText}
              isWidthSetExplicit
              placeholder="Invoice ID/Reseller ID/Reseller Name/Xero ID"
              inputProps={{ maxLength: 225 }}
              onChange={invoicesService.handleChangeSearchText}
            />
            <div style={{ marginTop: '1rem' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Period"
                  views={['year', 'month']}
                  value={invoicesService.date}
                  defaultValue={dayjs()}
                  maxDate={dayjs()}
                  minDate={dayjs().subtract(2, 'year')}
                  onChange={invoicesService.handleChangeDate}
                  sx={{ marginBottom: 2 }}
                />
              </LocalizationProvider>
            </div>
            <div style={{ marginTop: 8 }}>
              <Button
                disableRipple
                style={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  textTransform: 'none',
                }}
                onClick={() => setShowAdFilter(!showAdFilter)}>
                {showAdFilter
                  ? t('partner_details_page.button.hide_advance_search')
                  : t('partner_details_page.button.show_advance_search')}
              </Button>
            </div>
            {showAdFilter && (
              <>
                <div>
                  {!resellerId && (
                    <DistributorFilterSection
                      value={invoicesService.resellerId}
                      handleChange={invoicesService.handleChangeResellerId}
                      label="Reseller:"
                    />
                  )}
                  <QboSortByButton
                    defaultValue="All"
                    value={invoicesService.syncedToXero}
                    buttonWidth={220}
                    startAdornmentTextWidth={220}
                    startAdornmentText="Synced To Xero:"
                    handleChange={invoicesService.handleChangeSyncedToXero}
                    menuItemList={dataService.syncedToXeroItemList}
                  />
                </div>
                <div>
                  <QboSortByButton
                    defaultValue="None"
                    value={invoicesService.orderBy}
                    buttonWidth={260}
                    startAdornmentTextWidth={160}
                    startAdornmentText="Sort By:"
                    handleChange={invoicesService.handleChangeOrderBy}
                    menuItemList={dataService.orderByItemList}
                  />
                </div>
              </>
            )}
          </QboFormGroup>
        </QboCard>
        {allowXeroSync && (
          <ITBox
            direction="row"
            sx={{
              margin: 2,
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}>
            <QboButton
              style={{ width: 'max-content' }}
              variant="contained"
              onClick={() => setShowXeroSyncModal(true)}>
              Sync to Xero
            </QboButton>
          </ITBox>
        )}
        {invoicesService.listState.list?.length > 0 && !invoicesService.listState.fetching && (
          <QboTypography
            sx={{
              marginLeft: 2,
              marginTop: 2,
              marginBottom: 3,
              fontSize: '15px !important',
              color: '#42526e',
              fontWeight: 600,
            }}>
            {invoicesService.matchText}
          </QboTypography>
        )}
        {(invoicesService.listState.list?.length > 0 || invoicesService.listState.fetching) && (
          <QboAdvanceTable
            isLoading={invoicesService.listState.fetching}
            enableSorting
            enableTopToolBar={false}
            headers={dataService.columnNames}
            rows={formatList(invoicesService.listState.list)}
            pagination={invoicesService.pagination}
            setPagination={invoicesService.handleChangePagination}
            rowsCount={invoicesService.listState.pagination?.TotalCount || 0}
            columnsClickableList={dataService.clickableCoulmnList}
            enableColumnClickable={!resellerId}
          />
        )}
        {invoicesService.listState.list?.length === 0 && !invoicesService.listState.fetching && (
          <NoStatusSection />
        )}
      </QboCard>
      {allowXeroSync && (
        <XeroSyncModal
          selectedPeriod={invoicesService.date.format('YYYY-MM-DDTHH:mm:ss')}
          closeModal={() => setShowXeroSyncModal(false)}
          showModal={showXeroSyncModal}
        />
      )}
    </>
  );
}

InvoicesListPage.propTypes = {
  resellerId: PropTypes.number,
};

InvoicesListPage.defaultProps = {
  resellerId: 0,
};
