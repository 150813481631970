import axios from 'axios';
import BaseSetting from '@config/BaseSetting';
import ServiceClient from './ServiceClient';

export default class AxiosServiceClient extends ServiceClient {
  // constants
  ERROR_UNAUTHORIZED = 'Unauthorized';

  // client of service
  client = axios;

  // exclude from refresh auth token
  refreshTokenExcludedUrl = [];

  // refresh token url
  refreshTokenUrl = null;

  constructor({ baseUrl, accessToken, reset }) {
    super({ baseUrl, accessToken });

    this.client.defaults.baseURL = baseUrl;
    this.client.defaults.headers.common.Accept = 'application/json';
    this.client.defaults.headers.common['Content-Type'] = 'application/json';
    this.client.defaults.headers.common['Access-Control-Allow-Origin'] =
      BaseSetting.internalToolClientHost;
    this.client.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET,PUT,POST,DELETE';
    this.client.defaults.headers.common['Access-Control-Allow-Headers'] = 'Content-Type';

    this.updateAccessTokenHeader(accessToken, reset);
  }

  updateAccessTokenHeader(accessToken = null, reset = false) {
    if (accessToken && (!this.client.defaults.headers.common.Authorization || reset)) {
      this.client.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    }
  }

  getClient() {
    return this.client;
  }

  postRequest({ url, parameters = {}, config = {} }) {
    return this.client.post(url, parameters, config);
  }

  postIngestionRequest({ url, parameters = {}, config = {} }) {
    this.client.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    return this.client.post(url, parameters, config);
  }

  getRequest({ url, parameters = {} }) {
    return this.client.get(url, parameters);
  }

  putRequest({ url, parameters = {} }) {
    return this.client.put(url, parameters);
  }
}
