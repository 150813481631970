import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';

import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import { QboAdvanceTable, QboCard, QboFormGroup, QboTextField } from '@ui/Components';
import BaseSetting from '@config/BaseSetting';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import QboSortByButton from '@ui/Components/QboSortByButton';
import QboTypography from '@ui/Components/QboTypography';
import { Button } from '@mui/material';
import { resetUsersListState } from '@features/usersList/usersListSlice';
import { setUserTabState } from '@features/application/applicationSlice';
import ToggleEnforceSSOModal from '@ui/Sections/UpdateEnforceSSOPage/ToggleEnforceSSOModal';
import ToggleEnforceSSOConfirmation from '@ui/Sections/UpdateEnforceSSOPage/ToggleEnforceSSOConfirmation';
import useUserListService from './pageHooks/useUserListService';
import useDataService from './pageHooks/useDataService';

export default function OrganizationUserListPage(props) {
  const { orgOwnerId, tabFlag, selectTab } = props;

  const { t } = useTranslation();
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const dispatch = useDispatch();
  const previousPropsRef = useRef(pagination);

  const [selectedColumn, setSelectedColumn] = useState('');
  const [searchFieldValue, setSearchFieldValue] = useState('');
  const [searchIDFieldValue, setSearchIDFieldValue] = useState(0);
  const [fullTextSearchValue, setFullTextSearch] = useState('');
  const [advanceClick, setAdvanceClick] = useState(false);
  const [resetClicked, setResetClicked] = useState(false);
  const [matchText, setMatchText] = useState('');
  const [adFilter2FAStatus, setAdFilter2FAStatus] = useState('');
  const [adFilterSSO, setAdFilterSSO] = useState('');
  const [adFilterPartner, setAdFilterPartner] = useState('');
  const [adFilterRole, setAdFilterRole] = useState('');
  const [adFilter, setAdFilter] = useState([
    { field: 'filter_2faStatus', value: '' },
    { field: 'filter_sso', value: '' },
    { field: 'filter_subscription_role', value: '0' },
    { field: 'filter_gwsSSO', value: '' },
    { field: 'filter_orgOwnerId', value: orgOwnerId },
  ]);

  const { selectedTabState } = useSelector((state) => state.application);
  const { getOrganizationUsersListState } = useSelector((state) => state.organizationUsersList);
  const { usersList, fetching, paginationList, statusCode } = getOrganizationUsersListState;

  const {
    buttonStyle,
    TFAStatusItemList,
    SubscriptionRoleItemList,
    columnNames,
    clickableCoulmnList,
    userListHeaders,
  } = useDataService();

  useEffect(() => {
    if (!advanceClick) {
      setAdFilter2FAStatus('');
      setAdFilterSSO('');
      setAdFilterPartner('');
      setAdFilterRole('');
      setAdFilter([
        { field: 'filter_2faStatus', value: '' },
        { field: 'filter_sso', value: '' },
        { field: 'filter_subscription_role', value: '0' },
        { field: 'filter_gwsSSO', value: '' },
        { field: 'filter_orgOwnerId', value: orgOwnerId },
      ]);
      setSelectedColumn('');
    }
  }, [advanceClick]);

  const {
    handleOnAdvanceSearchClick,
    handleOnSortByChange,
    matchingText,
    handleFetchUsersListAsync,
    selectedAccount,
    setSelectedAccount,
    menuActions,
    hasMenuActions,
    toggleEnforceSSOModal,
    setToggleEnforceSSOModal,
  } = useUserListService(
    setAdvanceClick,
    advanceClick,
    setSelectedColumn,
    searchIDFieldValue,
    fullTextSearchValue,
    orgOwnerId,
    pagination,
    adFilter,
    selectedColumn
  );

  useEffect(() => {
    if (!fetching) {
      handleFetchUsersListAsync();
    }
  }, [pagination]);

  useEffect(() => {
    if ((selectTab === 0 || selectTab) && selectedTabState?.userTabState !== selectTab) {
      dispatch(setUserTabState(selectTab));
    }
  }, [selectTab]);

  const handleUpdateFilters = (label, val) => {
    const newList = adFilter.map((ele) => {
      if (ele.field === label) {
        return { ...ele, value: val };
      }
      return ele;
    });
    setAdFilter([...newList]);
  };

  const fetchApiHandler = (flag) => {
    if (!fetching) {
      handleFetchUsersListAsync(flag);
    }
  };

  const handleOnSearchClick = async (e) => {
    setResetClicked(false);
    setMatchText('');
    e.preventDefault();
    setPagination({ pageIndex: 0, pageSize: 10 });
    fetchApiHandler();
    matchingText(setMatchText);
  };

  const resetFilterStateHandler = () => {
    setMatchText('');
    setAdFilter2FAStatus('');
    setAdFilterRole('');
    setAdFilterPartner('');
    setAdFilterSSO('');
    setAdFilter([
      { field: 'filter_2faStatus', value: '' },
      { field: 'filter_sso', value: '' },
      { field: 'filter_subscription_role', value: '0' },
      { field: 'filter_gwsSSO', value: '' },
      { field: 'filter_orgOwnerId', value: orgOwnerId },
    ]);
    setSelectedColumn('');
    setSearchIDFieldValue(0);
    setFullTextSearch('');
    setSearchFieldValue('');
  };

  const handleOnResetButtonClick = () => {
    resetFilterStateHandler();
    if (!fetching) {
      const flag = true;
      dispatch(resetUsersListState());
      fetchApiHandler(flag);
    }
  };

  const handleSearchFieldChange = (e) => {
    const input = e.target.value;
    setSearchFieldValue(input);
    if (/^\d+$/.test(input)) {
      setSearchIDFieldValue(input);
      setFullTextSearch(input);
    } else {
      setSearchIDFieldValue('');
      setFullTextSearch(input);
    }
  };

  return (
    <>
      {!tabFlag && <HeaderPageBlock>{t('user_details_page.header.text')}</HeaderPageBlock>}
      <QboCard isMainWrapper>
        <QboFormGroup
          onSubmit={(e) => handleOnSearchClick(e)}
          buttonPostion="start"
          buttonStyle={buttonStyle}
          handleOnResetButton={handleOnResetButtonClick}
          firstButtonLabel={t('user_details_page.button.search')}
          withGenerateAndResetButton
          buttonLabel="Validate Transfer">
          <QboTextField
            id="fullTextSearch"
            value={searchFieldValue !== '' ? searchFieldValue : ''}
            isWidthSetExplicit
            placeholder="ID/Name/Organization Name/Partner Name"
            inputProps={{ maxLength: 225 }}
            onChange={(e) => {
              handleSearchFieldChange(e);
            }}
          />
          <div style={{ marginTop: 8 }}>
            <Button
              disableRipple
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
                textTransform: 'none',
              }}
              onClick={() => {
                handleOnAdvanceSearchClick();
              }}>
              {advanceClick
                ? t('partner_details_page.button.hide_advance_search')
                : t('partner_details_page.button.show_advance_search')}
            </Button>
          </div>
          {advanceClick && (
            <div>
              <QboSortByButton
                defaultValue="All"
                marginRightValue={2}
                marginTopValue={3}
                value={adFilterRole}
                buttonWidth={220}
                startAdornmentTextWidth={70}
                startAdornmentText="Subscription Role:"
                handleChange={(event) => {
                  setAdFilterRole(event.target.value);
                  handleUpdateFilters('filter_subscription_role', event.target.value);
                }}
                menuItemList={SubscriptionRoleItemList}
              />
              <QboSortByButton
                defaultValue="All"
                marginRightValue={2}
                marginTopValue={3}
                value={adFilter2FAStatus}
                buttonWidth={220}
                startAdornmentTextWidth={140}
                startAdornmentText="2FA Status:"
                handleChange={(event) => {
                  setAdFilter2FAStatus(event.target.value);
                  handleUpdateFilters('filter_2faStatus', event.target.value);
                }}
                menuItemList={TFAStatusItemList}
              />
              <QboSortByButton
                defaultValue="All"
                type="text"
                marginRightValue={2}
                marginTopValue={3}
                value={adFilterSSO}
                buttonWidth={220}
                startAdornmentTextWidth={180}
                startAdornmentText="Azure AD SSO:"
                handleChange={(event) => {
                  setAdFilterSSO(event.target.value);
                  handleUpdateFilters('filter_sso', event.target.value);
                }}
                menuItemList={TFAStatusItemList}
              />
              <QboSortByButton
                defaultValue="All"
                marginRightValue={2}
                marginTopValue={3}
                value={adFilterPartner}
                buttonWidth={220}
                startAdornmentTextWidth={90}
                startAdornmentText="GWS SSO:"
                handleChange={(event) => {
                  setAdFilterPartner(event.target.value);
                  handleUpdateFilters('filter_gwsSSO', event.target.value);
                }}
                menuItemList={TFAStatusItemList}
              />
            </div>
          )}
        </QboFormGroup>
      </QboCard>

      <QboCard noPadding isMainWrapper>
        <hr />
        <QboTypography
          sx={{
            marginLeft: 2,
            marginBottom: 3,
            fontSize: 18,
            color: '#000000DE',
            fontWeight: 600,
          }}>
          Users List
        </QboTypography>
        {usersList.length > 0 && statusCode ? (
          <QboTypography
            sx={{
              marginLeft: 2,
              marginTop: 2,
              marginBottom: 3,
              fontSize: '15px !important',
              color: '#42526e',
              fontWeight: 600,
            }}>
            {!resetClicked && matchText}
          </QboTypography>
        ) : null}
        {(usersList.length > 0 || fetching) && (
          <QboAdvanceTable
            isLoading={fetching}
            enableTopToolBar={false}
            enableSorting
            rows={usersList}
            headers={userListHeaders}
            rowsCount={Number(paginationList?.TotalCount)}
            pagination={pagination}
            setPagination={setPagination}
            columnsClickableList={clickableCoulmnList}
            enableColumnClickable={!false}
            renderRowActionMenuItems={menuActions}
            enableRowActions={hasMenuActions}
          />
        )}

        {usersList.length === 0 && !fetching && <NoStatusSection />}
      </QboCard>

      <ToggleEnforceSSOModal
        selectedAccount={selectedAccount}
        title={t('user_page.toggle_enforce_sso.title')}
        showModal={toggleEnforceSSOModal}
        closeModal={() => {
          setToggleEnforceSSOModal(false);
        }}>
        <ToggleEnforceSSOConfirmation selectedAccount={selectedAccount} />
      </ToggleEnforceSSOModal>
    </>
  );
}

OrganizationUserListPage.propTypes = {
  orgOwnerId: PropTypes.string,
  tabFlag: PropTypes.bool,
  selectTab: PropTypes.number,
};

OrganizationUserListPage.defaultProps = {
  orgOwnerId: '0',
  tabFlag: false,
  selectTab: null,
};
