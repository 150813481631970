//
// create slice
// as centeralize between actions and reducers
//
// serviceActions module contains reducers and actions that
// created by createAsyncThunk from redux-toolkit
//
// for standard actions, that require other business logic
// can be put inside extraActions module
//
// export default will be reducers it self that processed by store
// other exports will contains actions that's dispatched from ReactComponent
//

import { createSlice } from '@reduxjs/toolkit';
import {
  transferSubresellerValidateAsync,
  transferSubresellerValidateExtraReducers,
} from './serviceActions/transferSubresellerValidateAsync';
import {
  transferSubresellerAsync,
  transferSubresellerExtraReducers,
} from './serviceActions/transferSubresellerAsync';

const initialState = {
  transferSubreseller: {
    submitting: false,
    success: false,
    error: null,
    errorMessage: null,
  },
  transferSubresellerValidate: {
    validateErrorMessage: null,
    statusCode: null,
    submitting: false,
    success: false,
    error: null,
    value: {},
  },
};

const transferSubresellerSlice = createSlice({
  name: 'TransferSubreseller',
  initialState,
  reducers: {
    resetStateStatus(state) {
      state.transferSubreseller = initialState.transferSubreseller;
      state.transferSubresellerValidate.submitting =
        initialState.transferSubresellerValidate.submitting;
      state.transferSubresellerValidate.success = initialState.transferSubresellerValidate.success;
      state.transferSubresellerValidate.error = initialState.transferSubresellerValidate.error;
    },
    resetStateValue(state) {
      state.transferSubresellerValidate.value = initialState.transferSubresellerValidate.value;
      state.transferSubresellerValidate.validateErrorMessage =
        initialState.transferSubresellerValidate.validateErrorMessage;
    },
  },
  extraReducers: {
    ...transferSubresellerValidateExtraReducers,
    ...transferSubresellerExtraReducers,
  },
});

export { transferSubresellerValidateAsync, transferSubresellerAsync };
export const { resetStateStatus, resetStateValue } = transferSubresellerSlice.actions;
export default transferSubresellerSlice.reducer;
