import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';
import { ITBox, QboAlert, QboButton, QboCard, QboTypography } from '@ui/Components';

function TextWithLabel({ title, description, color }) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: 7,
        borderBottom: '1px solid #d7dbe3',
        paddingBottom: '10px',
      }}>
      <QboTypography sx={{ fontSize: 13, fontWeight: 700 }}>{title}</QboTypography>
      <QboTypography wrap sx={{ fontSize: 13, color: { color } }}>
        &nbsp;{description}
      </QboTypography>
    </div>
  );
}

TextWithLabel.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  description: PropTypes.string,
};

TextWithLabel.defaultProps = {
  title: '',
  color: '',
  description: '',
};

export default function RequestConfirmSection({
  onSubmit,
  onBack,
  onCloseModal,
  detailState,
  requestState,
}) {
  const { submitting: isLoading, data } = detailState;
  const { status, message, fetching } = requestState;

  const displayStatusCode = status;
  const displayErrorMessage = message;

  const {
    organizationOwnerId,
    organizationName,
    resellerId,
    resellerOrganizationName,
    domainName,
    managedDomainName,
    useBackupAdmin,
    backupAdminEmail,
    migratedToNonBackupAdminAt,
    migrateToNonBackupAdminStatusLabel,
  } = detailState.data;

  const renderLoading = () => {
    return (
      <div style={{ display: 'contents' }}>
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="70%" />
      </div>
    );
  };

  return (
    <ITBox className="TransferSubscriptionFormPage__wrapper" direction="column">
      {(displayErrorMessage || displayStatusCode) && (
        <QboAlert
          type={displayStatusCode === 200 ? 'success' : 'error'}
          style={{ fontSize: '1.15em' }}>
          {displayErrorMessage || (displayStatusCode === 200 && 'Successfully submitted')}
        </QboAlert>
      )}
      <QboCard
        className="TransferSubresellerPage__information-wrapper"
        titleCard="M365 Tenant Info"
        isBordered>
        {isLoading && renderLoading()}
        {!isLoading && (
          <ITBox className="TransferSubresellerPage__information-box" direction="column">
            {isLoading ? (
              renderLoading()
            ) : (
              <>
                {!data?.errorDescription && !isLoading && (
                  <>
                    <TextWithLabel
                      title="Organization Owner ID"
                      description={organizationOwnerId?.toString() || '-'}
                    />
                    <TextWithLabel
                      title="Organization Name"
                      description={organizationName || '-'}
                    />
                    <TextWithLabel title="Partner ID" description={resellerId?.toString() || '-'} />
                    <TextWithLabel
                      title="Partner Organization Name"
                      description={resellerOrganizationName || '-'}
                    />
                    <TextWithLabel title="Domain Name" description={domainName || '-'} />
                    <TextWithLabel
                      title="Managed Domain Name"
                      description={managedDomainName || '-'}
                    />
                    <TextWithLabel
                      title="Use Backup Admin"
                      description={useBackupAdmin?.toString() || '-'}
                    />
                    <TextWithLabel
                      title="Backup Admin Email"
                      description={backupAdminEmail || '-'}
                    />
                    <TextWithLabel
                      title="Migrated to SPA At"
                      description={migratedToNonBackupAdminAt || '-'}
                    />
                    <TextWithLabel
                      title="Migrated to SPA Status"
                      description={migrateToNonBackupAdminStatusLabel || '-'}
                    />

                    <hr />

                    <QboTypography fontWeight="bold" style={{ marginTop: '20px' }}>
                      * ) This command will purge the credential only,the backup data will remain
                      exist, user can re-add the tenant again afterwards.
                    </QboTypography>
                  </>
                )}

                {data?.errorDescription && (
                  <TextWithLabel
                    color="#D63301"
                    title="Error Description"
                    description={data?.errorDescription}
                  />
                )}
              </>
            )}
          </ITBox>
        )}
      </QboCard>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <QboButton
          disabled={fetching}
          sx={{ width: '100px !important' }}
          variant="outlined"
          color="secondary"
          onClick={() => onBack()}>
          Back
        </QboButton>
        {status === 200 ? (
          <QboButton sx={{ width: '100px !important' }} variant="contained" onClick={onCloseModal}>
            Close
          </QboButton>
        ) : (
          <QboButton
            disabled={!organizationOwnerId || fetching}
            sx={{ width: '150px !important', justifyContent: 'end' }}
            variant="contained"
            onClick={onSubmit}>
            Submit Request
          </QboButton>
        )}
      </div>
    </ITBox>
  );
}

RequestConfirmSection.propTypes = {
  onSubmit: PropTypes.func,
  onBack: PropTypes.func,
  onCloseModal: PropTypes.func,
  detailState: PropTypes.object,
  requestState: PropTypes.object,
};

RequestConfirmSection.defaultProps = {
  onSubmit: () => {},
  onBack: () => {},
  onCloseModal: () => {},
  detailState: {},
  requestState: {},
};
