import { useEffect } from 'react';
import { saveAs } from 'file-saver';

export default function useDownloadExcel(
  data,
  contentType,
  fileName,
  resetData,
  onSuccess,
  onError
) {
  const startDownload = (downloadData) => {
    try {
      // Create a blob with the data
      const datas = new Blob([downloadData], { type: contentType });
      saveAs(datas, fileName);
      if (onSuccess) onSuccess();
    } catch (err) {
      if (onError) onError(err);
    }
  };

  useEffect(() => {
    if (data) {
      startDownload(data);
      if (resetData) resetData();
    }
  }, [data]);
}
