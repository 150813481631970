import { createSlice } from '@reduxjs/toolkit';
import {
  getPurgeSharedDriveChangeAsync,
  getPurgeSharedDriveReducers,
} from './serviceActions/getPurgeSharedDriveAsync';

const initialState = {
  getPurgeSharedDriveState: {
    fetching: false,
    success: false,
    data: [],
    error: null,
    errorMessage: null,
    pagination: [],
    forbiddenState: false,
    forbiddenErrorMessage: {},
    getPurgeSharedDriveCorsErrorMessage: null,
  },
};

const getPurgeSharedDriveSlice = createSlice({
  name: 'GetPurgeSharedDrive',
  initialState,
  reducers: {
    resetPurgeSharedDriveStatus(state) {
      state.getPurgeSharedDriveState = initialState.getPurgeSharedDriveState;
    },
  },
  extraReducers: {
    ...getPurgeSharedDriveReducers,
  },
});

export { getPurgeSharedDriveChangeAsync };
export const { resetPurgeSharedDriveStatus } = getPurgeSharedDriveSlice.actions;
export default getPurgeSharedDriveSlice.reducer;
