import { createSlice } from '@reduxjs/toolkit';

import {
  getSilentTokenReducers,
  getTokenSilentAsync,
} from './serviceActions/fetchTokenSilentFromMsal';

const initialState = {
  getSilentTokenState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    errorMessage: null,
    silentAccessToken: null,
    silentAccessAccount: null,
  },
};

const silentTokenSlice = createSlice({
  name: 'RefreshToken',
  initialState,
  reducers: {
    resetSilentTokenStatus(state) {
      state.getSilentTokenState = initialState.getSilentTokenState;
    },
  },
  extraReducers: {
    ...getSilentTokenReducers,
  },
});

export { getTokenSilentAsync };
export const { resetSilentTokenStatus } = silentTokenSlice.actions;
export default silentTokenSlice.reducer;
