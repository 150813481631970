import { createSlice } from '@reduxjs/toolkit';
import {
  getBulkCreateSubResellerItemsAsync,
  getBulkCreateSubResellerItemsExtraReducers,
} from './serviceActions/getBulkCreateSubResellerItemsAsync';

const initialState = {
  getBulkCreateSubResellerItemsState: {
    fetching: false,
    statusCode: null,
    list: [],
    errorMessage: null,
    pagination: {},
    forbiddenState: false,
    forbiddenErrorMessage: null,
    corsErrorMessage: null,
  },
};

const getBulkCreateSubResellerItemsSlice = createSlice({
  name: 'getBulkCreateSubResellerItems',
  initialState,
  reducers: {
    resetGetBulkCreateSubResellerItemsState(state) {
      state.getBulkCreateSubResellerItemsState = initialState.getBulkCreateSubResellerItemsState;
    },
  },
  extraReducers: {
    ...getBulkCreateSubResellerItemsExtraReducers,
  },
});

export { getBulkCreateSubResellerItemsAsync };

export const { resetGetBulkCreateSubResellerItemsState } =
  getBulkCreateSubResellerItemsSlice.actions;
export default getBulkCreateSubResellerItemsSlice.reducer;
