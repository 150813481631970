import { createSlice } from '@reduxjs/toolkit';

import {
  getOrganizationsListAsync,
  getOrganizationsListExtraReducers,
} from './serviceActions/getOrganizationsListAsync';

const initialState = {
  getOrganizationsListState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    paginationList: null,
    organizationsList: [],
    forbiddenState: false,
    forbiddenErrorMessage: null,
    organizationsListCorsErrorMessage: null,
  },
};

const organizationsListSlice = createSlice({
  name: 'OrganizationsList',
  initialState,
  reducers: {
    resetOrganizationsListState(state) {
      state.getOrganizationsListState = initialState.getOrganizationsListState;
    },
    resetOrganizationsListStatusCodeState(state) {
      state.getOrganizationsListState.statusCode =
        initialState.getOrganizationsListState.statusCode;
    },
  },
  extraReducers: {
    ...getOrganizationsListExtraReducers,
  },
});

export { getOrganizationsListAsync };
export const { resetOrganizationsListState, resetOrganizationsListStatusCodeState } =
  organizationsListSlice.actions;
export default organizationsListSlice.reducer;
