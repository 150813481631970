import { resellersListPath } from '@config/Routes/WebClientRoutes';

export default function useDataService() {
  const buttonStyle = {
    height: 36,
    backgroundColor: '#a5a5a5',
    textDecoration: 'none',
    color: 'black',
    '&:hover': {
      backgroundColor: '#a5a5a5',
    },
    textTransform: 'unset',
    fontSize: 14,
  };

  const columnNames = [
    { id: 1, value: 'PlanName', title: 'Name' },
    { id: 2, value: 'PlanId', title: 'ID' },
    { id: 3, value: 'PlanType', title: 'Type' },
    { id: 4, value: 'PlanStatus', title: 'Status' },
  ];

  const licenseType = [
    { id: 1, value: 'Paid', title: 'Paid' },
    { id: 2, value: 'NFR', title: 'NFR' },
    { id: 3, value: 'NFP', title: 'NFP' },
  ];

  const planTypes = [
    { id: 1, value: 'personal', title: 'Personal' },
    { id: 2, value: 'backup', title: 'Backup' },
    { id: 3, value: 'archiver', title: 'Archiver' },
    { id: 3, value: 'qbo', title: 'Qbo' },
  ];

  const partnerNamesList = [
    { id: 1, value: 'personal', title: 'Personal' },
    { id: 2, value: 'backup', title: 'Backup' },
    { id: 3, value: 'archiver', title: 'Archiver' },
  ];

  const planStatusList = [
    { id: 1, value: 'Hidden', title: 'Hidden' },
    { id: 2, value: 'Visible', title: 'Visible' },
  ];

  const clickableCoulmnList = [
    { id: 1, column: 'partnerName', path: resellersListPath, tab: 1 },
    { id: 2, column: 'numberOfOrganizations', path: resellersListPath, tab: 5 },
  ];

  const names = ['Distributor', 'Sub-Reseller', 'Direct-Reseller'];

  return {
    names,
    planStatusList,
    planTypes,
    columnNames,
    buttonStyle,
    licenseType,
    partnerNamesList,
    clickableCoulmnList,
  };
}
