import { useState } from 'react';
import * as yup from 'yup';
import { isEmpty } from 'lodash';
import dayjs from 'dayjs';

const supportTicketName = 'support-ticket';
const escalationTicketName = 'escalation-ticket';
const descriptionName = 'description';
const transferTypeName = 'transferType';
const resellerIDToBeTransferredName = 'resellerIDToBeTransferred';
const targetDistributorIDName = 'targetDistributorID';
const sourceDistributorIDName = 'sourceDistributorID';
const effectiveTrasferDateName = 'effectiveTrasferDate';
const targetOwnerName = 'targetOwner';
const targetChannelName = 'targetChannel';
const newPriceArchiverName = 'newPriceArchiver';
const newPriceBackupName = 'newPriceBackup';
const newPriceQboName = 'newPriceQbo';
const newPriceNfpArchiverName = 'newPriceNfpArchiver';
const newPriceNfpBackupName = 'newPriceNfpBackup';
const targetCurrencyName = 'targetCurrency';
const billingTypeName = 'billingType';
const wireTransferName = 'wireTransfer';
const paypalTypeName = 'paypal';
const stripeTypeName = 'stripe';
const confirmingTheAboveInfoName = 'confirmingTheAboveInformation';

const InitialFormConfirmationData = {
  [confirmingTheAboveInfoName]: false,
};

const InitialForm = {
  [supportTicketName]: '',
  [escalationTicketName]: '',
  [descriptionName]: '',
  [transferTypeName]: 1,
  [resellerIDToBeTransferredName]: '',
  [targetDistributorIDName]: '',
  [sourceDistributorIDName]: '',
  [effectiveTrasferDateName]: dayjs(Date.now()),
  [targetOwnerName]: '',
  [targetChannelName]: '',
  [newPriceArchiverName]: '',
  [newPriceBackupName]: '',
  [newPriceQboName]: '',
  [newPriceNfpArchiverName]: '',
  [newPriceNfpBackupName]: '',
  [targetCurrencyName]: 'USD',
  [billingTypeName]: 2,
  [wireTransferName]: true,
  [paypalTypeName]: false,
  [stripeTypeName]: false,
};

export default function useFormValidation() {
  const [isValid, setIsValid] = useState(false);
  const [isValidCheck, setIsValidCheck] = useState(true);
  const [isValidConfirmation, setIsValidConfirmation] = useState(false);
  const [errors, setErrors] = useState('');
  const [errorsConfirmation, setErrorsConfirmation] = useState('');
  const [formData, setFormData] = useState(InitialForm);
  const [formDataConfirmation, setFormDataConfirmation] = useState(InitialFormConfirmationData);

  const transferType = {
    transferSubResellerBetweenDistributor: 1,
    transferDirectResellerToDistributorAsSubReseller: 2,
    transferSubResellerFromDistributorToMakeDirectReseller: 3,
  };

  const transferTypesList = [
    transferType.transferSubResellerBetweenDistributor,
    transferType.transferDirectResellerToDistributorAsSubReseller,
  ];

  const transferTypesSourecDistributorList = [
    transferType.transferSubResellerBetweenDistributor,
    transferType.transferSubResellerFromDistributorToMakeDirectReseller,
  ];

  const numberRegex = /^\d*\.?\d+$/;
  const numberRegex1 = /^(?:\d*\.?\d+)?$/;

  const formSchema = yup.object().shape({
    [supportTicketName]: yup.string().required('Support Ticket is required field'),
    [escalationTicketName]: yup.string(),
    [descriptionName]: yup.string(),
    [transferTypeName]: yup.number().required(),
    [sourceDistributorIDName]: yup.string().when(transferTypeName, {
      is: (data) => {
        return transferTypesSourecDistributorList.includes(data);
      },
      then: () => yup.string().required('Source Distributor ID is required field'),
    }),
    [resellerIDToBeTransferredName]: yup
      .string()
      .required('Reseller id to be transferred is required field')
      .notOneOf(
        [yup.ref(sourceDistributorIDName)],
        'Reseller id to be transferred should not be the same as Source Distributor ID'
      ),
    [targetDistributorIDName]: yup.string().when(transferTypeName, {
      is: (data) => {
        return transferTypesList.includes(data);
      },
      then: () =>
        yup
          .string()
          .notOneOf(
            [yup.ref(sourceDistributorIDName)],
            'Target Distributor ID should not be the same as Source Distributor ID'
          )
          .notOneOf(
            [yup.ref(resellerIDToBeTransferredName)],
            'Target Distributor ID should not be the same as Reseller id to be transferred'
          )
          .required('Target Distributor ID is required field'),
    }),
    [effectiveTrasferDateName]: yup.string().required('Effective Trasfer Date is required field'),
    [targetOwnerName]: yup.string().trim().required('Target Owner is required field'),
    [targetChannelName]: yup.string().trim().required('Target Channel is required field'),
    [newPriceArchiverName]: yup
      .string()
      .matches(numberRegex, 'Price value should be a number.')
      .required('Archiver price is required'),
    [newPriceBackupName]: yup
      .string()
      .matches(numberRegex, 'Price value should be a number.')
      .required('Backup price is required field'),
    [newPriceQboName]: yup.string().matches(numberRegex1, 'Price value should be a number.'),
    [newPriceNfpArchiverName]: yup
      .string()
      .matches(numberRegex1, 'Price value should be a number.'),
    [newPriceNfpBackupName]: yup.string().matches(numberRegex1, 'Price value should be a number.'),
    [billingTypeName]: yup.number().required(),
    [targetCurrencyName]: yup.string().required('Target Currency is required field'),
    [wireTransferName]: yup.bool(),
    [paypalTypeName]: yup.bool(),
    [stripeTypeName]: yup.bool(),
  });

  const formSchemaConfirmation = yup.object().shape({
    [confirmingTheAboveInfoName]: yup.bool().required(),
  });

  const validate = async (value) => {
    // eslint-disable-next-line func-names
    await formSchema.validate(value, { abortEarly: false }).catch(function (err) {
      const errorLogs = err.inner.reduce((acc, error) => {
        return {
          ...acc,
          [error.path]: error.message,
        };
      }, {});

      if (
        transferType.transferDirectResellerToDistributorAsSubReseller &&
        isEmpty(value?.resellerIDToBeTransferred)
      ) {
        errorLogs.resellerIDToBeTransferred = 'Reseller id to be transferred is required field';
      }
      if (
        transferType.transferDirectResellerToDistributorAsSubReseller &&
        isEmpty(value?.targetDistributorID)
      ) {
        errorLogs.targetDistributorID = 'Target Distributor ID is required field';
      }
      if (
        transferTypesSourecDistributorList.includes(value?.transferType) &&
        isEmpty(value?.sourceDistributorID)
      ) {
        setIsValidCheck(false);
        errorLogs.sourceDistributorID = 'Source Distributor ID is required field';
      }
      if (transferTypesList.includes(value?.transferType) && isEmpty(value?.targetDistributorID)) {
        setIsValidCheck(false);
        errorLogs.targetDistributorID = 'Target Distributor ID is required field';
      }
      if (isEmpty(value?.resellerIDToBeTransferred)) {
        setIsValidCheck(false);
        errorLogs.resellerIDToBeTransferred = 'Reseller id to be transferred is required field';
      }

      setIsValidCheck(true);

      setErrors(errorLogs);
      return errorLogs;
    });

    const validation = await formSchema.isValid(value);
    const { targetDistributorID, resellerIDToBeTransferred, sourceDistributorID, ...rest } = errors;

    if (validation) {
      setErrors(rest);
    }
    if (validation && isValidCheck) {
      setErrors({});
    }

    setIsValid(validation && isValidCheck);

    return validation && isValidCheck;
  };

  const validateConfirmation = async (value) => {
    // eslint-disable-next-line func-names
    await formSchemaConfirmation.validate(value, { abortEarly: false }).catch(function (err) {
      const errorLogs = err.inner.reduce((acc, error) => {
        return {
          ...acc,
          [error.path]: error.message,
        };
      }, {});
      setErrorsConfirmation(errorLogs);
      return errorLogs;
    });
    const validation = await formSchemaConfirmation.isValid(value);

    let flag;
    if (validation && value.confirmingTheAboveInformation) {
      setErrorsConfirmation({});
      flag = true;
    } else {
      setErrorsConfirmation({
        confirmingTheAboveInformation: 'Please confirm the above information',
      });
      flag = false;
    }
    setIsValidConfirmation(flag);

    return flag;
  };

  const validateAll = async () => {
    const flag = await validate(formData);

    return flag;
  };
  const validateAllConfirmation = async () => {
    const flag = await validateConfirmation(formDataConfirmation);

    return flag;
  };

  const updateFormData = (newData) => {
    setFormData((prev) => {
      return { ...prev, ...newData };
    });
  };
  const updateFormDataConfirmation = (newData) => {
    setFormDataConfirmation((prev) => {
      return { ...prev, ...newData };
    });
  };
  const supportTicketAttributes = {
    handleChange: async (value) => {
      updateFormData({ [supportTicketName]: value });
    },
    errorMessage: errors[supportTicketName],
    value: formData[supportTicketName],
  };
  const escalationTicketAttributes = {
    handleChange: async (value) => {
      updateFormData({ [escalationTicketName]: value });
    },
    value: formData[escalationTicketName],
  };
  const descriptionAttributes = {
    handleChange: async (value) => {
      updateFormData({ [descriptionName]: value });
    },
    value: formData[descriptionName],
  };
  const transferTypeAttributes = {
    handleChange: async (value) => {
      updateFormData({ [transferTypeName]: value });
    },
    errorMessage: errors[transferTypeName],
    value: formData[transferTypeName],
  };
  const resellerIDToBeTransferredAttributes = {
    handleChange: async (value) => {
      updateFormData({ [resellerIDToBeTransferredName]: value });
    },
    errorMessage: errors[resellerIDToBeTransferredName],
    value: formData[resellerIDToBeTransferredName],
  };
  const targetDistributorIDAttributes = {
    handleChange: async (value) => {
      updateFormData({ [targetDistributorIDName]: value });
    },
    errorMessage: errors[targetDistributorIDName],
    value: formData[targetDistributorIDName],
  };
  const sourceDistributorIDAttributes = {
    handleChange: async (value) => {
      updateFormData({ [sourceDistributorIDName]: value });
    },
    errorMessage: errors[sourceDistributorIDName],
    value: formData[sourceDistributorIDName],
  };
  const effectiveTrasferDateAttributes = {
    handleChange: async (value) => {
      updateFormData({ [effectiveTrasferDateName]: value });
    },
    errorMessage: errors[effectiveTrasferDateName],
    value: formData[effectiveTrasferDateName],
  };
  const targetOwnerAttributes = {
    handleChange: async (value) => {
      updateFormData({ [targetOwnerName]: value });
    },
    errorMessage: errors[targetOwnerName],
    value: formData[targetOwnerName],
  };
  const targetChannelAttributes = {
    handleChange: async (value) => {
      updateFormData({ [targetChannelName]: value });
    },
    errorMessage: errors[targetChannelName],
    value: formData[targetChannelName],
  };
  const newPriceArchiverAttributes = {
    handleChange: async (value) => {
      updateFormData({ [newPriceArchiverName]: value });
    },
    errorMessage: errors[newPriceArchiverName],
    value: formData[newPriceArchiverName],
  };
  const newPriceBackupAttributes = {
    handleChange: async (value) => {
      updateFormData({ [newPriceBackupName]: value });
    },
    errorMessage: errors[newPriceBackupName],
    value: formData[newPriceBackupName],
  };
  const newPriceQboAttributes = {
    handleChange: async (value) => {
      updateFormData({ [newPriceQboName]: value });
    },
    errorMessage: errors[newPriceQboName],
    value: formData[newPriceQboName],
  };
  const newPriceNfpArchiverAttributes = {
    handleChange: async (value) => {
      updateFormData({ [newPriceNfpArchiverName]: value });
    },
    errorMessage: errors[newPriceNfpArchiverName],
    value: formData[newPriceNfpArchiverName],
  };
  const newPriceNfpBackupAttributes = {
    handleChange: async (value) => {
      updateFormData({ [newPriceNfpBackupName]: value });
    },
    errorMessage: errors[newPriceNfpBackupName],
    value: formData[newPriceNfpBackupName],
  };
  const targetCurrencyAttributes = {
    handleChange: async (value) => {
      updateFormData({ [targetCurrencyName]: value });
    },
    errorMessage: errors[targetCurrencyName],
    value: formData[targetCurrencyName],
  };
  const billingTypeAttributes = {
    handleChange: async (value) => {
      updateFormData({ [billingTypeName]: value });
    },
    errorMessage: errors[billingTypeName],
    value: formData[billingTypeName],
  };
  const wireTransferAttributes = {
    handleChange: async (value) => {
      updateFormData({ [wireTransferName]: value });
    },
    value: formData[wireTransferName],
  };
  const paypalTypeAttributes = {
    handleChange: async (value) => {
      updateFormData({ [paypalTypeName]: value });
    },
    value: formData[paypalTypeName],
  };
  const stripeTypeAttributes = {
    handleChange: async (value) => {
      updateFormData({ [stripeTypeName]: value });
    },
    value: formData[stripeTypeName],
  };
  const confirmingTheAboveInfoAttributes = {
    handleChange: async (value) => {
      updateFormDataConfirmation({ [confirmingTheAboveInfoName]: value });
    },
    errorMessage: errorsConfirmation[confirmingTheAboveInfoName],
    value: formDataConfirmation[confirmingTheAboveInfoName],
  };

  const resetForm = () => {
    setFormData(InitialForm);
    setErrors({});
    setIsValid(false);
  };

  const resetFormConfirmation = () => {
    setFormDataConfirmation(InitialFormConfirmationData);
    setErrorsConfirmation({});
    setIsValidConfirmation(false);
  };

  return {
    supportTicketAttributes,
    escalationTicketAttributes,
    descriptionAttributes,
    transferTypeAttributes,
    resellerIDToBeTransferredAttributes,
    targetDistributorIDAttributes,
    sourceDistributorIDAttributes,
    effectiveTrasferDateAttributes,
    targetOwnerAttributes,
    targetChannelAttributes,
    newPriceArchiverAttributes,
    newPriceBackupAttributes,
    newPriceQboAttributes,
    newPriceNfpArchiverAttributes,
    newPriceNfpBackupAttributes,
    targetCurrencyAttributes,
    billingTypeAttributes,
    wireTransferAttributes,
    paypalTypeAttributes,
    stripeTypeAttributes,
    confirmingTheAboveInfoAttributes,
    formData,
    formDataConfirmation,
    validateAll,
    validateAllConfirmation,
    isValid,
    isValidConfirmation,
    resetForm,
    resetFormConfirmation,
  };
}
