import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useLocalStorage from '@hooks/useLocalStorage';
import { constant } from '@config/BaseSetting';
import fetchRegionsAsync from '@features/regions/serviceActions/fetchRegionsAsync';

export default function useRegionsList() {
  const dispatch = useDispatch();
  const [value, setValues, deleteValue] = useLocalStorage(constant.REGIONS_LIST);
  const [selectRegion, setSelectRegion] = useLocalStorage(constant.SELECTED_REGIONS);
  const { getRegions } = useSelector((state) => state.region);

  const getRegionsList = async (tokenState) => {
    await dispatch(fetchRegionsAsync({ accessToken: tokenState, reset: true }))
      .then((response) => {
        if (response?.payload?.data) {
          const { data } = response.payload.data;
          setValues(JSON.stringify(data));
          if (!selectRegion) {
            setSelectRegion(`${data[0].regionCode} (${data[0].regionName})`);
          }
        }
      })
      .catch((error) => {
        deleteValue();
      });
  };

  const setRegionsListToState = async (data, setRegionsList, setRegionName) => {
    const region = JSON.parse(data)[0];
    setRegionsList(JSON.parse(data));
    const regionCodeWithName = `${region.regionCode} (${region.regionName})`;
    setRegionName(selectRegion?.length > 0 ? selectRegion : regionCodeWithName);
  };

  const activeRegionCode = () => {
    return selectRegion?.split(' ')[0];
  };

  const getSelectedRegionObject = () => {
    if (getRegions?.data) return getRegions.data.find((i) => i.regionCode === activeRegionCode());

    return {};
  };

  return { getRegionsList, setRegionsListToState, getSelectedRegionObject };
}
