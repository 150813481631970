import React, { useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Alert, AlertTitle, Box, Container, Dialog } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Navigation from '@navigation';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { QboFixedAlert, QboSpinnerProgress } from '@ui/Components';
import NavbarSection from '@layouts/default/NavbarSection';
import DrawerSection from '@layouts/default/DrawerSection';
import FooterSection from '@layouts/default/FooterSection';
import useSilentToken from '@pages/sharedHooks/useSilentToken';
import { resetFixedAlert } from '@features/application/applicationSlice';
import useDefaultSetupApplication from './layoutHooks/useDefaultSetupApplication';
import '@config/themes/default';

const drawerWidth = 270;

const navigationContainer = {
  marginTop: '4rem !important',
  marginBottom: '7rem !important',
  paddingLeft: '0px !important',
  paddingRight: '0px !important',
};

export default function DefaultApplication() {
  const {
    isApplicationReady,
    isDrawerOpen,
    isShowSecondBar,
    handleDrawerOpen,
    handleDrawerClose,
    isPublicPage,
  } = useDefaultSetupApplication();
  const mainMarginTop = isShowSecondBar ? '8.5rem' : '5.5rem';
  const dispatch = useDispatch();
  const { accounts } = useMsal();
  const { getAccessTokenState } = useSelector((stateList) => stateList.tokenAccess);

  const { mailChangeRequestState } = useSelector((stateList) => stateList.mailRequest);
  const { mailSyncRequestState } = useSelector((stateList) => stateList.syncRequest);
  const { getExcludedMailboxesState } = useSelector((stateList) => stateList.excludedMail);
  const { getIngestionDataState } = useSelector((stateList) => stateList.ingestion);
  const { getAutoDiscoveryState } = useSelector((stateList) => stateList.getAutoDiscovery);
  const { getFeatureFlagState } = useSelector((stateList) => stateList.flags);
  const { getSilentTokenState } = useSelector((stateList) => stateList.silent);
  const { getRegions } = useSelector((stateList) => stateList.region);
  const { fixedAlertState } = useSelector((stateList) => stateList.application);

  const { getSilentToken } = useSilentToken();

  useEffect(() => {
    getSilentToken();
  }, [accounts?.length]);

  useEffect(() => {
    if (fixedAlertState.show) {
      setTimeout(() => {
        dispatch(resetFixedAlert());
      }, 8000);
    }
  }, [fixedAlertState]);

  const loadingHandler = () => {
    return (
      mailChangeRequestState?.fetching ||
      mailSyncRequestState?.fetching ||
      getExcludedMailboxesState?.fetching ||
      getIngestionDataState?.fetching ||
      getAutoDiscoveryState?.fetching ||
      getAccessTokenState?.fetching ||
      getFeatureFlagState?.fetching ||
      getRegions?.fetching ||
      getSilentTokenState?.fetching
    );
  };

  if (isPublicPage) {
    return (
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <NavbarSection className="DefaultApplication__navbar" drawerOpen={false} drawerWidth={0} />
        <Box
          component="main"
          sx={{
            minHeight: `calc(100vh - ${mainMarginTop})`,
            marginTop: mainMarginTop,
          }}
          className={`Box__main Box__main-with-padding ${
            !isApplicationReady ? 'DefaultApplication__not-ready' : ''
          }`}>
          <>
            <Container
              className="DefaultApplication__navigation"
              maxWidth="xlg"
              sx={navigationContainer}>
              <Navigation />
            </Container>
            <Dialog
              open={loadingHandler()}
              PaperProps={{
                style: {
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                  overflow: 'hidden',
                },
              }}
              BackdropProps={{
                style: {
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                },
              }}>
              <QboSpinnerProgress className="DefaultApplication__not-ready-loading" fullWrapper />
            </Dialog>
          </>
          <FooterSection />
        </Box>
      </Box>
    );
  }

  return (
    <>
      <QboFixedAlert
        show={fixedAlertState.show}
        descriptionHtml={fixedAlertState.descriptionHtml}
        severity={fixedAlertState.severity}
      />

      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <NavbarSection drawerOpen={isDrawerOpen} drawerWidth={drawerWidth} />
        <DrawerSection
          drawerWidth={drawerWidth}
          drawerOpen={isDrawerOpen}
          handleDrawerOpen={handleDrawerOpen}
          handleDrawerClose={handleDrawerClose}
        />
        <Box
          component="main"
          sx={{
            minHeight: `calc(100vh - ${mainMarginTop})`,
            marginTop: mainMarginTop,
          }}
          className={`${
            isDrawerOpen ? 'Box__main_drawer_open' : 'Box__main'
          } Box__main-with-padding ${!isApplicationReady ? 'DefaultApplication__not-ready' : ''}`}>
          <Container
            className="DefaultApplication__navigation"
            maxWidth="xlg"
            sx={navigationContainer}>
            <Navigation />
          </Container>
          <Dialog
            open={loadingHandler()}
            PaperProps={{
              style: {
                backgroundColor: 'transparent',
                boxShadow: 'none',
                overflow: 'hidden',
              },
            }}
            BackdropProps={{
              style: {
                backgroundColor: 'transparent',
                boxShadow: 'none',
              },
            }}>
            <QboSpinnerProgress className="DefaultApplication__not-ready-loading" fullWrapper />
          </Dialog>
          <FooterSection />
        </Box>
      </Box>
    </>
  );
}
