//
// create slice
// as centeralize between actions and reducers
//
// serviceActions module contains reducers and actions that
// created by createAsyncThunk from redux-toolkit
//
// for standard actions, that require other business logic
// can be put inside extraActions module
//
// export default will be reducers it self that processed by store
// other exports will contains actions that's dispatched from ReactComponent
//

import { createSlice } from '@reduxjs/toolkit';

import { fetchListingAysnc, fetchListingExtraReducers } from './serviceActions/fetchListingAysnc';

const initialState = {
  listing: {
    value: [],
  },
  fetchListing: true,
  error: null,
};

const listingSlice = createSlice({
  name: 'Listing',
  initialState,
  reducers: {
    resetListing(state) {
      state.listing = initialState.listing;
      state.fetchListing = initialState.fetchListing;
      state.error = initialState.error;
    },
  },
  extraReducers: {
    ...fetchListingExtraReducers,
  },
});

export { fetchListingAysnc };
export const { resetListing } = listingSlice.actions;
export default listingSlice.reducer;
