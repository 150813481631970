import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FormControl, MenuItem, Skeleton, TextField } from '@mui/material';
import {
  ITBox,
  QboAlert,
  QboButton,
  QboCard,
  QboCheckbox,
  QboFormGroup,
  QboTextField,
  QboTypography,
} from '@ui/Components';

function TextWithLabel({ title, description, color }) {
  return (
    <div style={{ display: 'flex', marginTop: 7 }}>
      <QboTypography sx={{ fontSize: 13, fontWeight: 700 }}>{title}</QboTypography>
      <QboTypography wrap sx={{ fontSize: 13, color: { color } }}>
        &nbsp;{description}
      </QboTypography>
    </div>
  );
}

TextWithLabel.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  description: PropTypes.string,
};

TextWithLabel.defaultProps = {
  title: '',
  color: '',
  description: '',
};

export default function TransferSubresellerFormConfirmSectionPage({
  confirmingTheAboveInfoAttributes,
  formData,
  onSubmit,
  onBack,
  resetForm,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const transferTypeTitles = {
    1: 'Transfer sub-reseller between distributor',
    2: 'Transfer direct reseller to distributor as sub-reseller',
    3: 'Transfer sub-reseller from distributor to make direct-reseller',
  };

  const { validateTransferPartnerForm } = useSelector(
    (state) => state.validateTransferPartnerState
  );

  const { submitTransferPartnerForm } = useSelector((state) => state.submitTransferPartnerState);

  const { submitting, validatedData } = validateTransferPartnerForm;
  const { submitting: isLoading, statusCode, errorMessage } = submitTransferPartnerForm;

  useEffect(() => {
    if (statusCode === 200) {
      onBack();
      resetForm();
    }
  }, [statusCode]);

  const renderLoading = () => {
    return (
      <div style={{ display: 'contents' }}>
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="70%" />
      </div>
    );
  };

  return (
    <ITBox className="TransferSubscriptionFormPage__wrapper" direction="column">
      {(errorMessage || statusCode) && (
        <QboAlert type={statusCode === 200 ? 'success' : 'error'} style={{ fontSize: '1.15em' }}>
          {errorMessage || (statusCode === 200 && 'Successfully submitted')}
        </QboAlert>
      )}
      <QboCard
        className="TransferSubresellerPage__information-wrapper"
        titleCard={t('transfer_partner_page.modal.data_list.req_info_title_text')}
        isBordered>
        {isLoading && renderLoading()}
        {!isLoading && (
          <ITBox className="TransferSubresellerPage__information-box" direction="column">
            {submitting ? (
              renderLoading()
            ) : (
              <>
                <TextWithLabel
                  color={validatedData?.validationSuccess ? '#4F8A10' : '#D63301'}
                  title={t('transfer_partner_page.modal.data_list.validation_success')}
                  description={validatedData?.validationSuccess ? 'true' : 'false'}
                />
                {validatedData?.errorDescription && (
                  <TextWithLabel
                    color="#D63301"
                    title={t('transfer_partner_page.modal.data_list.error_description')}
                    description={validatedData?.errorDescription}
                  />
                )}
                <hr />
              </>
            )}
            {submitting ? (
              renderLoading()
            ) : (
              <>
                <TextWithLabel
                  title={t('transfer_partner_page.modal.data_list.transfer_type')}
                  description={transferTypeTitles[validatedData?.transferType]}
                />
                <TextWithLabel
                  title={t('transfer_partner_page.modal.data_list.source_distributor')}
                  description={`${validatedData?.sourceDistributorName ?? ''} (${
                    validatedData?.sourceDistributorID ?? ''
                  })`}
                />
                <TextWithLabel
                  title={t('transfer_partner_page.modal.data_list.reseller_to_transder')}
                  description={`${validatedData?.resellerToTransferName ?? ''} (${
                    validatedData?.resellerIDToBeTransferred ?? ''
                  })`}
                />
                <TextWithLabel
                  title={t('transfer_partner_page.modal.data_list.target_distributor')}
                  description={`${validatedData?.targetDistributorName ?? ''} (${
                    validatedData?.targetDistributorID ?? ''
                  })`}
                />
              </>
            )}
            {submitting ? (
              renderLoading()
            ) : (
              <>
                <TextWithLabel
                  title={t('transfer_partner_page.modal.data_list.description')}
                  description={validatedData?.description}
                />
                <TextWithLabel
                  title={t('transfer_partner_page.modal.data_list.support_ticket')}
                  description={validatedData['support-ticket']}
                />
                <TextWithLabel
                  title={t('transfer_partner_page.modal.data_list.escalation_ticket')}
                  description={validatedData['escalation-ticket']}
                />
              </>
            )}
          </ITBox>
        )}
      </QboCard>
      <QboFormGroup>
        <FormControl className="FormControl__default-wrapper FormControl__full-width-wrapper">
          <QboCheckbox
            sx={{ fontSize: '12px !important' }}
            className="QboCheckbox__wrapper_text"
            label={t('transfer_partner_page.modal.form.confirming_the_above_information')}
            checked={confirmingTheAboveInfoAttributes.value}
            onChange={(e) => {
              confirmingTheAboveInfoAttributes.handleChange(e.target.checked);
            }}
          />
          {confirmingTheAboveInfoAttributes.errorMessage && (
            <span style={{ height: '15px !important' }} className="QboTextField__warning-text">
              {/* <WarningAmberIcon className="QboTextField__warning-icon" /> */}
              {confirmingTheAboveInfoAttributes.errorMessage}
            </span>
          )}
        </FormControl>
      </QboFormGroup>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <QboButton
          disabled={isLoading}
          sx={{ width: '100px !important' }}
          variant="outlined"
          color="secondary"
          onClick={() => onBack()}>
          {t('transfer_subreseller_page.view_data.back_button_text')}
        </QboButton>
        <QboButton
          disabled={!validatedData?.validationSuccess || isLoading}
          sx={{ width: '150px !important', justifyContent: 'end' }}
          variant="contained"
          onClick={onSubmit}>
          {t('transfer_subreseller_page.view_data.transfer_button_text')}
        </QboButton>
      </div>
    </ITBox>
  );
}

TransferSubresellerFormConfirmSectionPage.propTypes = {
  confirmingTheAboveInfoAttributes: PropTypes.object,
  onSubmit: PropTypes.func,
  onBack: PropTypes.func,
  resetForm: PropTypes.func,
  formData: PropTypes.object,
};

TransferSubresellerFormConfirmSectionPage.defaultProps = {
  confirmingTheAboveInfoAttributes: {},
  formData: {},
  onSubmit: () => {},
  onBack: () => {},
  resetForm: () => {},
};
