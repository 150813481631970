import { createSlice } from '@reduxjs/toolkit';
import {
  exportSeatUsageReportAsync,
  exportSeatUsageReportReducers,
} from './serviceActions/exportSeatUsageReportAsync';

const initialState = {
  exportSeatUsageReportState: {
    fetching: false,
    seatUsageExportData: null,
    errorMessage: null,
    pagination: [],
    statusCode: null,
    forbiddenState: false,
    forbiddenErrorMessage: {},
    corsErrorMessage: null,
  },
};

const exportSeatUsageReportSlice = createSlice({
  name: 'ExportSeatUsageReport',
  initialState,
  reducers: {
    resetExportSeatUsageReportStatus(state) {
      state.exportSeatUsageReportState = initialState.exportSeatUsageReportState;
    },
  },
  extraReducers: {
    ...exportSeatUsageReportReducers,
  },
});

export { exportSeatUsageReportAsync };
export const { resetExportSeatUsageReportStatus } = exportSeatUsageReportSlice.actions;
export default exportSeatUsageReportSlice.reducer;
