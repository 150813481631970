import { createAsyncThunk } from '@reduxjs/toolkit';
import { getOrganizationsListAPI } from '@services/WebApiService';

const getOrganizationsListAsync = createAsyncThunk(
  'OrganizationsList/getOrganizationsLists',
  async (paramsList) => {
    try {
      const data = await getOrganizationsListAPI(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = getOrganizationsListAsync;

const getOrganizationsListExtraReducers = {
  [pending]: (state) => {
    state.getOrganizationsListState.fetching = true;
  },
  [rejected]: (state) => {
    state.getOrganizationsListState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.getOrganizationsListState.fetching = false;

    if (action?.payload?.status === 403) {
      state.getOrganizationsListState.forbiddenState = true;
      state.getOrganizationsListState.forbiddenErrorMessage = {
        heading: 'Forbidden Error',
        caption: 'You are not authorize to access this resource',
      };
    }

    if (!action?.payload) {
      state.getOrganizationsListState.OrganizationsListCorsErrorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.data?.data) {
      state.getOrganizationsListState.organizationsList = action?.payload?.data?.data;
      state.getOrganizationsListState.paginationList = JSON.parse(
        action?.payload?.data?.pagination
      );
      state.getOrganizationsListState.success = true;
      state.getOrganizationsListState.statusCode = action?.payload?.data['status-code'];
    }

    if (action?.payload?.error) {
      state.getOrganizationsListState.error = action.payload.error;
    }
  },
};

export { getOrganizationsListAsync, getOrganizationsListExtraReducers };
