import { createSlice } from '@reduxjs/toolkit';

import { getUsersListAsync, getUsersListExtraReducers } from './serviceActions/getUsersListAsync';

const initialState = {
  getUsersListState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    paginationList: null,
    usersList: [],
    forbiddenState: false,
    forbiddenErrorMessage: null,
    usersListCorsErrorMessage: null,
  },
};

const usersListSlice = createSlice({
  name: 'UsersList',
  initialState,
  reducers: {
    resetUsersListState(state) {
      state.getUsersListState = initialState.getUsersListState;
    },
    resetUsersListStatusCodeState(state) {
      state.getUsersListState.statusCode = initialState.getUsersListState.statusCode;
    },
  },
  extraReducers: {
    ...getUsersListExtraReducers,
  },
});

export { getUsersListAsync };
export const { resetUsersListState, resetUsersListStatusCodeState } = usersListSlice.actions;
export default usersListSlice.reducer;
