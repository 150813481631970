import { createAsyncThunk } from '@reduxjs/toolkit';
import { bulkCreateOrganizationApi } from '@services/WebApiService';

const bulkCreateOrganizationAsync = createAsyncThunk(
  'BulkCreateOrganization/bulkCreateOrganization',
  async (paramsList) => {
    try {
      const data = await bulkCreateOrganizationApi(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = bulkCreateOrganizationAsync;

const bulkCreateOrganizationExtraReducers = {
  [pending]: (state) => {
    state.bulkCreateOrganizationState.fetching = true;
  },
  [rejected]: (state) => {
    state.bulkCreateOrganizationState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.bulkCreateOrganizationState.fetching = false;

    if (action?.payload && !action?.payload?.error) {
      state.bulkCreateOrganizationState.statusCode = action?.payload?.status;
      state.bulkCreateOrganizationState.message = action?.payload?.data?.message;
    }

    if (action?.payload?.status === 403) {
      state.bulkCreateOrganizationState.message = 'You are not authorize to access this resource';
    }

    if (!action?.payload) {
      state.bulkCreateOrganizationState.message =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.error) {
      const statusMessage = action?.payload?.error?.data;
      state.bulkCreateOrganizationState.statusCode = action?.payload?.error?.status;
      state.bulkCreateOrganizationState.message = statusMessage?.title || statusMessage?.message;
      state.bulkCreateOrganizationState.error = action.payload.error;
    } else {
      state.bulkCreateOrganizationState.success = true;
      state.bulkCreateOrganizationState.serviceType = action.payload.data.data.serviceTypeCode;
    }
  },
};

export { bulkCreateOrganizationAsync, bulkCreateOrganizationExtraReducers };
