import { createSlice } from '@reduxjs/toolkit';

import {
  getIngestionReportListItemAsync,
  getIngestionReportListItemExtraReducers,
} from './serviceActions/fetchIngestionReportListItemAsync';

const initialState = {
  getIngestionReportListItemState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    errorMessage: null,
    reportsPaginationListItem: null,
    ingestionReportsListItem: [],
    forbiddenState: false,
    forbiddenErrorMessage: null,
    ingestionReportsListItemCorsErrorMessage: null,
  },
};

const ingestionReportsListItemSlice = createSlice({
  name: 'IngestionReportsListItem',
  initialState,
  reducers: {
    resetIngestionReportListItem(state) {
      state.getIngestionReportListItemState = initialState.getIngestionReportListItemState;
    },
  },
  extraReducers: {
    ...getIngestionReportListItemExtraReducers,
  },
});

export { getIngestionReportListItemAsync };
export const { resetIngestionReportListItem } = ingestionReportsListItemSlice.actions;
export default ingestionReportsListItemSlice.reducer;
