import { createAsyncThunk } from '@reduxjs/toolkit';
import { getParentFilterListAPI } from '@services/WebApiService';

const getParentFilterListAsync = createAsyncThunk(
  'ParentFilterList/getParentFilterLists',
  async (paramsList) => {
    try {
      const data = await getParentFilterListAPI(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = getParentFilterListAsync;

const getParentFilterListExtraReducers = {
  [pending]: (state) => {
    state.getParentFilterListState.fetching = true;
  },
  [rejected]: (state) => {
    state.getParentFilterListState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.getParentFilterListState.fetching = false;

    if (action?.payload?.data?.data) {
      state.getParentFilterListState.parentFilterList = action?.payload?.data?.data;
      state.getParentFilterListState.success = true;
      state.getParentFilterListState.statusCode = action?.payload?.data['status-code'];
    }

    if (action?.payload?.error) {
      state.getParentFilterListState.error = action.payload.error;
    }
  },
};

export { getParentFilterListAsync, getParentFilterListExtraReducers };
