export default function useDataService() {
  const buttonStyle = {
    height: 36,
    backgroundColor: '#a5a5a5',
    textDecoration: 'none',
    color: 'black',
    '&:hover': {
      backgroundColor: '#a5a5a5',
    },
    textTransform: 'unset',
    fontSize: 14,
  };

  const storageTypeList = [
    { id: 1, value: '1101', title: 'FTP' },
    { id: 2, value: '1102', title: 'S3TYPE' },
    { id: 3, value: '1103', title: 'PROSERV' },
    { id: 4, value: '1104', title: 'PROSERV S3' },
  ];

  const ingestionTypeList = [
    { id: 1, value: '0', title: 'Non Archiver' },
    { id: 2, value: '1', title: 'Archiver' },
  ];

  const statusList = [
    { id: 1, value: '22', title: 'Queued' },
    { id: 2, value: '12', title: 'In Progress' },
    { id: 3, value: '7', title: 'Completed' },
    { id: 4, value: '5', title: 'Failed' },
    { id: 5, value: '13', title: 'Completed with errors' },
    { id: 6, value: '32', title: 'Job Created' },
    { id: 7, value: '34', title: 'Queueing in Progress' },
    { id: 8, value: '35', title: 'Job Created with Failures' },
  ];

  return {
    storageTypeList,
    ingestionTypeList,
    statusList,
    buttonStyle,
  };
}
