import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';
import { QboCard, QboButton, ITBox, QboTypography } from '@ui/Components';
import { TransferSubresellerStatus } from '@pages/TransferSubresellerPage/pageHooks/useTransferSubreseller';

function TextWithLabel({ title, description }) {
  return (
    <>
      <QboTypography variant="caption">{title}</QboTypography>
      <QboTypography
        wrap
        variant="subtitle2"
        withTitle
        className="TransferSubscriptionPage__text-with-label">
        {description}
      </QboTypography>
    </>
  );
}

TextWithLabel.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

TextWithLabel.defaultProps = {
  title: '',
  description: '',
};

export default function TransferSubresellerSection({
  transferSubresellerData,
  onBack,
  onTransfer,
  loading,
}) {
  const { t } = useTranslation();
  const data = transferSubresellerData;
  const defaultData = '-';

  const renderLoading = () => {
    return (
      <div style={{ display: 'contents' }}>
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="70%" />
      </div>
    );
  };

  const renderContent = () => {
    if (loading) {
      return (
        <>
          <QboCard
            className="TransferSubresellerPage__information-wrapper"
            titleCard={t('transfer_subreseller_page.view_data.req_info_title_text')}
            isBordered>
            <ITBox className="TransferSubresellerPage__information-box">
              <ITBox direction="column">{renderLoading()}</ITBox>
              <ITBox direction="column">{renderLoading()}</ITBox>
            </ITBox>
          </QboCard>
          <ITBox className="TransferSubresellerPage__source-target">
            <QboCard
              classNameWrapper="TransferSubresellerPage__source-target-card-wrapper"
              className="TransferSubresellerPage__center-item"
              titleCard={t('transfer_subreseller_page.view_data.source_title_text')}
              isBordered>
              {renderLoading()}
            </QboCard>
            <QboCard
              classNameWrapper="TransferSubresellerPage__source-target-card-wrapper"
              className="TransferSubresellerPage__center-item"
              titleCard={t('transfer_subreseller_page.view_data.target_title_text')}
              isBordered>
              {renderLoading()}
            </QboCard>
          </ITBox>
        </>
      );
    }

    return (
      <>
        <QboCard
          className="TransferSubresellerPage__information-wrapper"
          titleCard={t('transfer_subreseller_page.view_data.req_info_title_text')}
          isBordered>
          <ITBox className="TransferSubresellerPage__information-box" direction="column">
            <TextWithLabel
              title={t('transfer_subreseller_page.view_data.status')}
              description={data.status || defaultData}
            />
            <TextWithLabel
              title={t('transfer_subreseller_page.view_data.reason')}
              description={data.message || defaultData}
            />
          </ITBox>
        </QboCard>
        <ITBox className="TransferSubresellerPage__source-target">
          <QboCard
            classNameWrapper="TransferSubresellerPage__source-target-card-wrapper"
            className="TransferSubresellerPage__center-item"
            titleCard={t('transfer_subreseller_page.view_data.source_title_text')}
            isBordered>
            <TextWithLabel
              title={t('transfer_subreseller_page.view_data.subreseller_name')}
              description={
                data['source-subreseller-name'] && data['source-subreseller-id']
                  ? t('transfer_subreseller_page.view_data.name_with_id_format', {
                      name: data['source-subreseller-name'],
                      id: data['source-subreseller-id'],
                    })
                  : defaultData
              }
            />
            <TextWithLabel
              title={t('transfer_subreseller_page.view_data.source_reseller_name')}
              description={
                data['source-parent-reseller-name'] && data['source-parent-reseller-id']
                  ? t('transfer_subreseller_page.view_data.name_with_id_format', {
                      name: data['source-parent-reseller-name'],
                      id: data['source-parent-reseller-id'],
                    })
                  : defaultData
              }
            />
          </QboCard>
          <QboCard
            classNameWrapper="TransferSubresellerPage__source-target-card-wrapper"
            className="TransferSubresellerPage__center-item"
            titleCard={t('transfer_subreseller_page.view_data.target_title_text')}
            isBordered>
            <TextWithLabel
              title={t('transfer_subreseller_page.view_data.target_reseller_name')}
              description={
                data['target-reseller-name'] && data['target-reseller-id']
                  ? t('transfer_subreseller_page.view_data.name_with_id_format', {
                      name: data['target-reseller-name'],
                      id: data['target-reseller-id'],
                    })
                  : defaultData
              }
            />
          </QboCard>
        </ITBox>
      </>
    );
  };

  return (
    <ITBox className="TransferSubresellerPage__wrapper" direction="column">
      {renderContent()}
      <ITBox direction="row" className="TransferSubresellerPage__footer-button">
        <QboButton
          variant="outlined"
          color="secondary"
          onClick={() => onBack('reset')}
          loading={loading}>
          {t('transfer_subreseller_page.view_data.back_button_text')}
        </QboButton>
        <QboButton
          variant="contained"
          onClick={() => onTransfer()}
          loading={loading}
          disabled={data?.status !== TransferSubresellerStatus.StatusAccepted}>
          {t('transfer_subreseller_page.view_data.transfer_button_text')}
        </QboButton>
      </ITBox>
    </ITBox>
  );
}

TransferSubresellerSection.propTypes = {
  transferSubresellerData: PropTypes.object,
  onBack: PropTypes.func,
  onTransfer: PropTypes.func,
  loading: PropTypes.bool,
};

TransferSubresellerSection.defaultProps = {
  transferSubresellerData: {},
  onBack: () => {},
  onTransfer: () => {},
  loading: true,
};
