import BaseSetting from '@config/BaseSetting';
import { createAsyncThunk } from '@reduxjs/toolkit';

const tokenRequest = {
  scopes: [BaseSetting.config.tokenRequestScopes],
};

const getTokenSilentAsync = createAsyncThunk('Token/getToken', async (paramsList) => {
  const data = await paramsList?.instance.acquireTokenSilent({
    ...tokenRequest,
    account: paramsList?.accounts[0],
  });

  return data;
});

const { pending, fulfilled, rejected } = getTokenSilentAsync;

const getSilentTokenReducers = {
  [pending]: (state) => {
    state.getSilentTokenState.fetching = true;
  },
  [rejected]: (state, action) => {
    state.getSilentTokenState.fetching = false;
    state.getSilentTokenState.errorMessage = action?.error?.name;
  },
  [fulfilled]: (state, action) => {
    state.getSilentTokenState.fetching = false;
    state.getSilentTokenState.errorMessage = null;

    if (action?.payload) {
      state.getSilentTokenState.silentAccessToken = action?.payload?.accessToken;
      state.getSilentTokenState.silentAccessAccount = action?.payload?.account;
      state.getSilentTokenState.success = true;
    }

    if (action?.payload?.code) {
      state.getSilentTokenState.error = action.payload.code;
      state.getSilentTokenState.statusCode = action.payload?.response?.status;
    }
  },
};

export { getTokenSilentAsync, getSilentTokenReducers };

export default getTokenSilentAsync;
