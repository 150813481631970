import WebApiRoutes from '@config/Routes/WebApiRoutes';
import ServiceClientFactory from '@libs/ServiceClient';

const webClientApi = (accessToken) => {
  return new ServiceClientFactory({
    baseUrl: 'https://jsonplaceholder.typicode.com/',
    accessToken,
  }).create();
};

const ListingService = {
  fetchListingApi: (payload = {}) => {
    const parameters = { ...payload };
    return webClientApi().getRequest({
      url: WebApiRoutes.listingPath(),
    });
  },
};

export default ListingService;
