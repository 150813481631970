//
// create slice
// as centeralize between actions and reducers
//
// serviceActions module contains reducers and actions that
// created by createAsyncThunk from redux-toolkit
//
// for standard actions, that require other business logic
// can be put inside extraActions module
//
// export default will be reducers it self that processed by store
// other exports will contains actions that's dispatched from ReactComponent
//

import { createSlice } from '@reduxjs/toolkit';

import {
  fetchSystemStatusAysnc,
  fetchSystemStatusExtraReducers,
} from './serviceActions/fetchSystemStatusAysnc';

const initialState = {
  systemStatus: {
    value: [],
  },
  fetchSystemStatus: true,
  error: null,
  systemStatusCorsErrorMessage: null,
  forbiddenErrorMessage: null,
  isForbiddenOccurred: false,
};

const systemStatusSlice = createSlice({
  name: 'SystemStatus',
  initialState,
  reducers: {
    resetState() {
      return initialState;
    },
  },
  extraReducers: {
    ...fetchSystemStatusExtraReducers,
  },
});

export { fetchSystemStatusAysnc };
export const { resetState } = systemStatusSlice.actions;
export default systemStatusSlice.reducer;
