import React from 'react';
import PropTypes from 'prop-types';
import {
  ITBox,
  QboAlert,
  QboFormGroup,
  QboModal,
  QboSpinnerProgress,
  QboTextField,
  QboTypography,
} from '@ui/Components';
import { Grid } from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import useXeroSync from './pageHooks/useXeroSync';
import useXeroSyncValidation from './pageHooks/useXeroSyncValidation';

function XeroSyncModal({ closeModal, showModal, selectedPeriod }) {
  const { t } = useTranslation();
  const validation = useXeroSyncValidation({ showModal, onFailed: closeModal });
  const { form, runXeroSyncState, handleSubmit } = useXeroSync({
    period: selectedPeriod,
    onSuccessRequest: closeModal,
  });

  const closeAndReset = () => {
    form.resetForm();
    closeModal();
  };

  return (
    <>
      {validation.validating && (
        <QboSpinnerProgress className="DefaultApplication__not-ready-loading" overlay />
      )}
      <QboModal
        onClose={closeAndReset}
        open={showModal && validation.isValid}
        title={t('invoices_page.xero_sync.title')}
        showCloseIcon
        preventOutsideClick>
        <ITBox className="TransferSubscriptionFormPage__wrapper" direction="column">
          <QboFormGroup
            loadingButton={runXeroSyncState.fetching}
            onSubmit={handleSubmit}
            withGenerateAndResetButton
            handleOnResetButton={closeAndReset}
            secondButtonLabel="Cancel"
            firstButtonLabel="Submit">
            <QboAlert
              variant="outlined"
              type="info"
              style={{
                border: '1px solid #ebebeb',
                background: '#f3f3f3',
                fontSize: '10px !important',
                marginTop: '20px',
              }}>
              <QboTypography
                noWrap={false}
                style={{
                  fontSize: '13px',
                  color: '#767676',
                  whiteSpace: 'pre-line',
                }}>
                {t('invoices_page.xero_sync.information')}
                <strong>{dayjs(selectedPeriod).format('MMMM YYYY')}</strong>
              </QboTypography>
            </QboAlert>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <QboTextField
                  id="supportTicket"
                  required
                  placeholder="Support Ticket"
                  label="Support Ticket"
                  fullWidth
                  value={form.attributes.supportTicketAttributes.value}
                  errorMessage={form.attributes.supportTicketAttributes.errorMessage}
                  inputProps={{ maxLength: 225 }}
                  onChange={(e) => {
                    form.attributes.supportTicketAttributes.handleChange(e.currentTarget.value);
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <QboTextField
                  id="escalationTicket"
                  placeholder="Escalation Ticket"
                  label="Escalation Ticket"
                  fullWidth
                  value={form.attributes.escalationTicketAttributes.value}
                  onChange={(e) =>
                    form.attributes.escalationTicketAttributes.handleChange(e.currentTarget.value)
                  }
                  inputProps={{ maxLength: 225 }}
                />
              </Grid>
            </Grid>

            <QboTextField
              id="description"
              placeholder="Description"
              label="Description"
              fullWidth
              value={form.attributes.descriptionAttributes.value}
              onChange={(e) =>
                form.attributes.descriptionAttributes.handleChange(e.currentTarget.value)
              }
              inputProps={{ maxLength: 225 }}
            />
          </QboFormGroup>
        </ITBox>
      </QboModal>
    </>
  );
}

XeroSyncModal.propTypes = {
  closeModal: PropTypes.func,
  showModal: PropTypes.bool,
  selectedPeriod: PropTypes.string,
};

XeroSyncModal.defaultProps = {
  closeModal: () => {},
  showModal: false,
  selectedPeriod: '',
};

export default XeroSyncModal;
