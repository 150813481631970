/**
 * Schema Builder Library
 *
 * Schema Builder Library uses Factory Design Pattern
 * the factory will be intialized within SchemaBuilderFactory Class
 *
 * available schema builders:
 *   - yup
 *
 * eg:
 *   schemaBuilder = new SchemaBuilderFactory({}, 'yup').create();
 *
 *   # save the data
 *   localStorage.save('accessToken', 'this is token')
 *
 *   # get the data
 *   localStorage.get('accessToken')
 *
 *   # delete or clear the data
 *   localStorage.delete('accessToken')
 *   localStorage.deleteAll()
 * * */

import SchemaBuilder from './SchemaBuilderFactory';

export default SchemaBuilder;
