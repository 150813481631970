import { createSlice } from '@reduxjs/toolkit';

import {
  purgeM365TenantAsync,
  purgeM365TenantExtraReducers,
} from './serviceActions/purgeM365TenantAsync';

const initialState = {
  purgeM365TenantState: {
    fetching: false,
    status: null,
    error: null,
    message: null,
    errorMessage: null,
    forbiddenState: false,
    forbiddenErrorMessage: null,
    purgeM365TenantCorsErrorMessage: null,
  },
};

const recreatePurgeM365TenantSlice = createSlice({
  name: 'purgeM365Tenant',
  initialState,
  reducers: {
    resetPurgeM365TenantState(state) {
      state.purgeM365TenantState = initialState.purgeM365TenantState;
    },
  },
  extraReducers: {
    ...purgeM365TenantExtraReducers,
  },
});

export { purgeM365TenantAsync };

export const { resetPurgeM365TenantState } = recreatePurgeM365TenantSlice.actions;
export default recreatePurgeM365TenantSlice.reducer;
