import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { IconButton } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

import {
  ITBox,
  QboAlert,
  QboButton,
  QboCard,
  QboModal,
  QboAdvanceTable,
  QboTypography,
} from '@ui/Components';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import { resetRecreateBackupAdminsState } from '@features/recreateBackupAdmins/recreateBackupAdminsSlice';
import { resetM365TenantDetail } from '@features/m365TenantDetail/m365TenantDetailSlice';
import RequestFormSection from '@ui/Sections/RecreateBackupAdminsPage/RequestFormSection';
import RequestConfirmSection from '@ui/Sections/RecreateBackupAdminsPage/RequestConfirmSection';
import StepperBlock from '@ui/Blocks/Shared/StepperBlock';
import SettingsIcon from '@mui/icons-material/Settings';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import useStepper from '@pages/sharedHooks/useStepper';

import useRecreateBackupAdmins from './pageHooks/useRecreateBackupAdmins';
import useForm from './pageHooks/useForm';

export default function RecreateBackupAdminPage() {
  // Helper
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const previousRecreateBackupAdminsListData = useRef();
  const steps = ['1', '2'];
  const stepIcons = {
    1: <SettingsIcon />,
    2: <VideoLabelIcon />,
  };

  // States
  const [paginations, setPaginations] = useState({ pageIndex: 0, pageSize: 10 });
  const [recreateBackupAdminsListState, setRecreateBackupAdminsState] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [erroMessageDialog, setErroMessageDialog] = useState(null);
  const {
    activeStep,
    isStepOptional,
    handleNext,
    handleBack,
    handleReset: resetStepper,
  } = useStepper({});
  const { m365TenantDetailState } = useSelector((state) => state.m365TenantDetail);
  const { statusCode } = m365TenantDetailState;
  const { recreateBackupAdminsState } = useSelector((state) => state.recreateBackupAdmins);
  const { status: createStatusResponse, message: createMessage } = recreateBackupAdminsState;

  // Hooks for listing
  const { handleFetchRecreateBackupAdminsList, getFilterdDataFromResponse, tableColumns } =
    useRecreateBackupAdmins();
  // Hooks for request form submission
  const {
    supportTicketAttributes,
    escalationTicketAttributes,
    organizationOwnerIdAttributes,
    credentialIdAttributes,
    descriptionAttributes,
    onSubmit,
    resetForm,
    formData,
    isValid,
    onValidateData,
  } = useForm();

  // Hook To Move To Next Page
  useEffect(() => {
    if (statusCode === 200) handleNext();
  }, [statusCode]);

  // Redux States
  const { getRecreateBackupAdminsState } = useSelector((state) => state.getRecreateBackupAdmins);

  const {
    fetching,
    status,
    recreateBackupAdminsList,
    recreateBackupAdminsCorsErrorMessage,
    paginations: pagination,
    forbiddenState,
    forbiddenErrorMessage,
  } = getRecreateBackupAdminsState;

  const fetchHandler = () => {
    previousRecreateBackupAdminsListData.current = recreateBackupAdminsListState;

    if (!fetching) {
      handleFetchRecreateBackupAdminsList(paginations.pageIndex + 1, paginations.pageSize);
    }
  };

  // UseEffect Callbacks
  // when filter table is triggered
  useEffect(() => {
    previousRecreateBackupAdminsListData.current = recreateBackupAdminsListState;
    getFilterdDataFromResponse(recreateBackupAdminsListState, setRecreateBackupAdminsState);
  }, [recreateBackupAdminsList]);

  // when pagination is clicked
  useEffect(() => {
    if (status) {
      fetchHandler();
    }
  }, [paginations.pageSize, paginations.pageIndex]);

  const resetDialogModal = () => {
    handleBack();
    dispatch(resetM365TenantDetail());
    resetForm();
  };

  const onDisplayModal = () => {
    setShowModal(true);
    dispatch(resetRecreateBackupAdminsState());
    resetStepper();
  };

  const onBackToFirstStep = () => {
    handleBack();
    dispatch(resetM365TenantDetail());
  };

  const closeModal = () => {
    resetDialogModal();
    setShowModal(false);
    fetchHandler();
  };

  // when page is visited on the first time
  useEffect(() => {
    fetchHandler();
  }, []);

  useEffect(() => {
    if (createStatusResponse === 200) closeModal();
  }, [createStatusResponse]);

  const dailogHandler = (errorMessage) => {
    setErroMessageDialog(errorMessage);
    setShowErrorDialog(true);
  };

  return (
    <>
      <QboModal
        classNameStyle="Qbo_model_error_message"
        title="Detail Message"
        showCloseIcon
        open={showErrorDialog}
        onClose={() => setShowErrorDialog(false)}>
        <QboTypography wrap={!false}>{erroMessageDialog}</QboTypography>
      </QboModal>

      {/* Header */}
      <HeaderPageBlock>{t('recreate_backup_admin.header.text')}</HeaderPageBlock>
      {/* Error Message */}
      {(recreateBackupAdminsCorsErrorMessage || createStatusResponse === 200) && (
        <QboAlert
          type={createStatusResponse === 200 ? 'success' : 'error'}
          style={{ fontSize: '1.15em' }}>
          {recreateBackupAdminsCorsErrorMessage ||
            (createStatusResponse === 200 && 'Successfully submitted')}
        </QboAlert>
      )}

      <QboCard>
        {/* New Request Button Section */}
        {!forbiddenState && (
          <ITBox className="SystemStatusSection__wrapper" direction="column">
            {!forbiddenState && (
              <ITBox className="SystemStatusSection__create-button">
                <IconButton
                  sx={{ position: 'relative', right: '0.7rem' }}
                  onClick={() => fetchHandler()}>
                  <RefreshIcon sx={{ width: '2rem', height: '2rem' }} />
                </IconButton>
                &nbsp;
                <QboButton variant="contained" onClick={onDisplayModal}>
                  {t('recreate_backup_admin.new_request')}
                </QboButton>
              </ITBox>
            )}
          </ITBox>
        )}

        {/* Entries Section */}
        <QboCard noPadding isMainWrapper>
          <>
            {(recreateBackupAdminsList.length > 0 || fetching) && (
              <QboAdvanceTable
                enableTopToolBar={false}
                enableSorting={false}
                rows={recreateBackupAdminsList}
                isLoading={fetching}
                pagination={paginations}
                setPagination={setPaginations}
                headers={tableColumns}
                rowsCount={pagination?.TotalCount}
                isEdit={false}
                enableColumnClickable={!false}
                columnsClickableList={[
                  {
                    id: 1,
                    column: 'status-description',
                    handler: dailogHandler,
                  },
                ]}
              />
            )}
            {recreateBackupAdminsList.length === 0 && !fetching && (
              <NoStatusSection forbiddenErrorMessage={forbiddenErrorMessage} />
            )}
          </>
        </QboCard>

        {/* Form Modal Section */}
        <QboModal
          onClose={closeModal}
          open={showModal}
          title={t('recreate_backup_admin.modal.title_text')}
          showCloseIcon
          preventOutsideClick>
          <StepperBlock
            steps={steps}
            icons={stepIcons}
            hideLabel
            activeStepAlt={activeStep}
            isStepOptionalAlt={isStepOptional}
            isStepSkippedAlt={isStepOptional}>
            <RequestFormSection
              supportTicketAttributes={supportTicketAttributes}
              escalationTicketAttributes={escalationTicketAttributes}
              organizationOwnerIdAttributes={organizationOwnerIdAttributes}
              credentialIdAttributes={credentialIdAttributes}
              descriptionAttributes={descriptionAttributes}
              onSubmit={onValidateData}
              resetForm={resetForm}
              formData={formData}
              isValid={isValid}
            />
            <RequestConfirmSection
              onSubmit={onSubmit}
              formData={formData}
              onBack={onBackToFirstStep}
              onCloseModal={closeModal}
            />
          </StepperBlock>
        </QboModal>
      </QboCard>
    </>
  );
}
