import { createAsyncThunk } from '@reduxjs/toolkit';
import { createDomainBackupsAPI } from '@services/WebApiService';

const createDomainBackupsAsync = createAsyncThunk(
  'DomainBackups/createDomainBackups',
  async (paramsList) => {
    try {
      const data = await createDomainBackupsAPI(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = createDomainBackupsAsync;

const createDomainBackupsExtraReducers = {
  [pending]: (state) => {
    state.domainBackupsState.fetching = true;
  },
  [rejected]: (state) => {
    state.domainBackupsState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.domainBackupsState.fetching = false;

    if (action?.payload && !action?.payload?.error) {
      state.domainBackupsState.status = action?.payload?.status;
      state.domainBackupsState.message = action?.payload?.data?.message;
    }

    if (action?.payload?.status === 403) {
      state.domainBackupsState.message = 'You are not authorize to access this resource';
    }

    if (!action?.payload) {
      state.domainBackupsState.message = 'Something went wrong while processing your request.';
    }

    if (action?.payload?.error) {
      const statusMessage = action?.payload?.error?.data;
      state.domainBackupsState.status = action?.payload?.error?.status;
      state.domainBackupsState.message = statusMessage?.title || statusMessage?.message;
      state.domainBackupsState.error = action.payload.error;
    } else {
      state.domainBackupsState.success = true;
    }
  },
};

export { createDomainBackupsAsync, createDomainBackupsExtraReducers };
