import { createSlice } from '@reduxjs/toolkit';
import {
  mailboxBulkPurgeStatusItemReducers,
  fetchMailboxBulkPurgeStatusItem,
} from './serviceActions/fetchMailboxBulkPurgeStatusAsync';

const initialState = {
  mailboxBulkPurgeStatus: {
    fetching: false,
    bulkPurgeStatusData: [],
    errorMessage: null,
    statusCode: null,
    forbiddenState: false,
    forbiddenErrorMessage: {},
    bulkPurgeCorsErrorMessage: null,
  },
};

const mailboxBulkPurgeStatusSlice = createSlice({
  name: 'MailboxBulkPurgeStatus',
  initialState,
  reducers: {
    resetMailboxBulkPurgeStatusItem(state) {
      state.mailboxBulkPurgeStatus = initialState.mailboxBulkPurgeStatus;
    },
    resetMailboxBulkPurgeEachItemStatus(state) {
      state.mailboxBulkPurgeStatus.bulkPurgeStatusData =
        initialState.mailboxBulkPurgeStatus.bulkPurgeStatusData;
    },
  },
  extraReducers: {
    ...mailboxBulkPurgeStatusItemReducers,
  },
});

export { fetchMailboxBulkPurgeStatusItem };
export const { resetMailboxBulkPurgeStatusItem, resetMailboxBulkPurgeEachItemStatus } =
  mailboxBulkPurgeStatusSlice.actions;
export default mailboxBulkPurgeStatusSlice.reducer;
