import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { QboModal, QboAdvanceTable, QboButton, QboTypography } from '@ui/Components';
import { dataIngestionPath, dataIngestionListPath } from '@config/Routes/WebClientRoutes';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';

function ReportsPage(props) {
  const {
    showModal,
    setShowModal,
    pageTitle,
    reportsData,
    fetching,
    headersList,
    pagination,
    setPagination,
    paginationList,
    ingestionID,
    path,
    enableTopToolBar,
    enablePaginationProp,
    handleOnCloseClick,
    classNameStyle,
    buttonName,
    handleOnButtonClick,
    isButtonNeeded,
    isNoteShow,
    noteMessage,
  } = props;

  const navigate = useNavigate();

  const handlePageMap = () => {
    if (path === dataIngestionPath) {
      navigate(path);
    } else {
      navigate(dataIngestionListPath, { state: { row: ingestionID } });
    }
  };

  return (
    <QboModal
      onClose={() => {
        setShowModal(false);
        handlePageMap();
        handleOnCloseClick();
      }}
      title={pageTitle}
      classNameStyle={classNameStyle}
      open={showModal}
      showCloseIcon
      preventOutsideClick>
      {isNoteShow && (
        <>
          <QboTypography>{noteMessage}</QboTypography>
          <br />
        </>
      )}
      {isButtonNeeded && (
        <QboButton
          onClick={handleOnButtonClick}
          variant="contained"
          style={{
            left: '95%',
            position: 'sticky',
            fontSize: 14,
            marginBottom: 2,
            height: 35,
          }}>
          {buttonName}
        </QboButton>
      )}
      {(reportsData?.length || fetching) && (
        <QboAdvanceTable
          enableTopToolBar={enableTopToolBar}
          enablePaginationProp={enablePaginationProp}
          enableSorting
          rows={reportsData}
          isLoading={fetching}
          headers={headersList}
          pagination={pagination}
          setPagination={setPagination}
          rowsCount={Number(paginationList?.TotalCount)}
        />
      )}
      {!reportsData?.length && !fetching && <NoStatusSection />}
    </QboModal>
  );
}

ReportsPage.propTypes = {
  showModal: PropTypes.bool,
  enableTopToolBar: PropTypes.bool,
  enablePaginationProp: PropTypes.bool,
  setShowModal: PropTypes.func,
  pageTitle: PropTypes.object,
  reportsData: PropTypes.array,
  fetching: PropTypes.bool,
  headersList: PropTypes.array,
  pagination: PropTypes.object,
  setPagination: PropTypes.func,
  handleOnCloseClick: PropTypes.func,
  paginationList: PropTypes.object,
  ingestionID: PropTypes.number,
  path: PropTypes.string,
  classNameStyle: PropTypes.string,
  buttonName: PropTypes.string,
  handleOnButtonClick: PropTypes.func,
  isButtonNeeded: PropTypes.bool,
  isNoteShow: PropTypes.bool,
  noteMessage: PropTypes.string,
};

ReportsPage.defaultProps = {
  showModal: false,
  enableTopToolBar: true,
  enablePaginationProp: true,
  setShowModal: () => {},
  pageTitle: null,
  reportsData: [],
  fetching: false,
  headersList: [],
  pagination: {},
  setPagination: () => {},
  handleOnCloseClick: () => {},
  paginationList: {},
  ingestionID: null,
  path: null,
  classNameStyle: null,
  buttonName: null,
  handleOnButtonClick: () => {},
  isButtonNeeded: false,
  isNoteShow: false,
  noteMessage: null,
};

export default ReportsPage;
