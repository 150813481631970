import React, { useState } from 'react';
import { QboButton, QboSnackbar } from '@ui/Components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  exportUserBackupAsync,
  resetExportUserBackupState,
} from '@features/userBackup/userBackupInfoSlice';
import useLocalStorage from '@hooks/useLocalStorage';
import { constant } from '@config/BaseSetting';
import useDownload from '@hooks/useDownload';
import useFeatureFlag from '@hooks/useFeatureFlag';

export default function ExportEmailBackupSection(props) {
  const { fetchParams, hasResults } = props;

  const dispatch = useDispatch();
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const { t } = useTranslation();
  const { hasPermission, featureFlags } = useFeatureFlag();
  const [snackbarState, setSnackbarState] = useState({ open: false });
  const { exportUserBackupState } = useSelector((state) => state.userBackupState);

  const handleResetExport = () => {
    dispatch(resetExportUserBackupState());
  };

  const handleExport = () => {
    dispatch(
      exportUserBackupAsync({
        accessToken: accessTokenSet,
        reset: true,
        ...fetchParams,
      })
    );
  };

  const getExportFileName = () => {
    let name = 'backups.csv';
    if (fetchParams?.backupType) {
      name = `${fetchParams.backupType}_${name}`;
    }
    if (fetchParams?.organizationOwnerId) {
      name = `organization_${fetchParams.organizationOwnerId}_${name}`;
    } else if (fetchParams?.resellerId) {
      return `reseller_${fetchParams.resellerId}_${name}`;
    }
    return name;
  };

  const handleSnackbarClose = () => {
    setSnackbarState({ ...snackbarState, open: false });
  };

  useDownload(
    exportUserBackupState.userBackupExportData,
    'text/csv;charset=utf-8',
    getExportFileName(),
    handleResetExport,
    () => setSnackbarState({ open: true, type: 'success', message: 'Successfully downloaded' }),
    (err) => setSnackbarState({ open: true, type: 'error', message: err })
  );

  if (!hasPermission(featureFlags.RunExportEmailBackupInfo)) return null;

  const exportDisabled =
    !hasResults || (!fetchParams?.organizationOwnerId && !fetchParams?.resellerId);
  const exportTooltip = () => {
    if (!hasResults) {
      return t('user_backup_page.tooltip.result_empty');
    }
    if (!fetchParams?.organizationOwnerId && !fetchParams?.resellerId) {
      return t('user_backup_page.tooltip.partner_or_org_not_selected');
    }
    return null;
  };

  return (
    <>
      <QboSnackbar onClose={handleSnackbarClose} {...snackbarState} />
      <QboButton
        disabled={exportDisabled}
        withTitle={exportDisabled}
        title={exportTooltip()}
        sxText={{ fontSize: '18px !important' }}
        variant="contained"
        onClick={handleExport}
        loading={exportUserBackupState.fetching}>
        {t('export_csv')}
      </QboButton>
    </>
  );
}

ExportEmailBackupSection.propTypes = {
  fetchParams: PropTypes.object,
  hasResults: PropTypes.bool,
};

ExportEmailBackupSection.defaultProps = {
  fetchParams: null,
  hasResults: false,
};
