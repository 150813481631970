import BaseSetting from '@config/BaseSetting';
import Routes from './Routes';

class WebTokensApiRoutes extends Routes {
  authenticationPath(params = {}, format = '') {
    return this.createUrl('/api/v1.0/authorize', params, format);
  }

  refreshTokenPath(params = {}, format = '') {
    return this.createUrl('/api/v1.0/refresh', params, format);
  }

  getRegionsListPath(params = {}, format = '') {
    return this.createUrl('/api/v1.0/regions', params, format);
  }
}

const ApiTokenRoutes = new WebTokensApiRoutes(BaseSetting.graphConfig.internalGateWayHost);

export const authenticateAPI = ApiTokenRoutes.authenticationPath();
export const refreshTokenAPI = ApiTokenRoutes.refreshTokenPath();
export const getRegionsAPI = ApiTokenRoutes.getRegionsListPath();

export default ApiTokenRoutes;
