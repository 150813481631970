import React, { useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ITBox, QboAlert, QboButton, QboCard, QboModal } from '@ui/Components';
import SwitchSPATypeFormSection from '@ui/Sections/SwitchSPATypePage/SwitchSPATypeFormSection';
import CredentialsSPASection from '@ui/Sections/SwitchSPATypePage/CredentialsSPASection';
import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import QboAdvanceTable from '@ui/Components/QboAdvanceTable';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import StepperBlock from '@ui/Blocks/Shared/StepperBlock';
import SettingsIcon from '@mui/icons-material/Settings';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import useSwitchSPACommand from './pageHooks/useSwitchSPACommand';
import useForm from './pageHooks/useForm';

export default function SwitchSPATypePage() {
  const { t } = useTranslation();
  const { handleSwitchSPACommandListAsync, processHandleSwitchSPACommandList } =
    useSwitchSPACommand();
  const [paginations, setPaginations] = useState({ pageIndex: 0, pageSize: 10 });
  const [initiateListState, setInitiateListState] = useState([]);
  const { switchSPATypeState } = useSelector((state) => state.switchSPATypeState);
  const {
    fetching,
    switchSPATypeList,
    paginations: pagination,
    forbiddenState,
    forbiddenErrorMessage,
    switchSPATyperCorsErrorMessage,
  } = switchSPATypeState;

  const tableColumns = [
    { id: 1, label: 'Support Ticket', columnName: 'support-ticket', sx: { paddingLeft: 2 } },
    { id: 2, label: 'Escalation Ticket', columnName: 'escalation-ticket', sx: { paddingLeft: 2 } },
    {
      id: 3,
      label: 'Organization Owner ID',
      columnName: 'org-owner-id',
      sx: { paddingLeft: 2 },
    },
    { id: 4, label: 'Credentials ID', columnName: 'credentials-id', sx: { paddingLeft: 2 } },
    { id: 5, label: 'Switch to', columnName: 'spa-type', sx: { paddingLeft: 2 } },
    { id: 5, label: 'Executed On', columnName: 'executed-on', sx: { paddingLeft: 2 } },
    { id: 7, label: 'Status', columnName: 'status-description', sx: { paddingLeft: 2 } },
  ];

  const fetchHandler = () => {
    if (!fetching) {
      handleSwitchSPACommandListAsync(paginations.pageIndex + 1, paginations.pageSize);
    }
  };

  useEffect(() => {
    if (switchSPATypeList?.length) {
      processHandleSwitchSPACommandList(switchSPATypeList, setInitiateListState);
    }
  }, [switchSPATypeList]);

  useEffect(() => {
    fetchHandler();
  }, [paginations.pageIndex, paginations.pageSize]);

  // Form steps
  const steps = ['1', '2'];
  const icons = {
    1: <SettingsIcon />,
    2: <CheckCircleOutlineOutlinedIcon />,
  };

  const {
    supportTicketAttributes,
    escalationTicketAttributes,
    descriptionAttributes,
    organizationOwnerIdAttributes,
    credentialsIdAttributes,
    onSubmit,
    onValidation,
    onBack,
    activeStep,
    isStepOptional,
    isStepSkipped,
    loading,
    credentialsInfo,
    onOpenModal,
    onCloseModal,
    showModal,
    errorValidation,
    submitted,
  } = useForm();

  return (
    <>
      <HeaderPageBlock>{t('switch_spa_page.header.text')}</HeaderPageBlock>
      {(switchSPATyperCorsErrorMessage || submitted) && (
        <QboAlert type={submitted === true ? 'success' : 'error'} style={{ fontSize: '1.15em' }}>
          {submitted === true ? 'Submitted Successfully' : switchSPATyperCorsErrorMessage}
        </QboAlert>
      )}
      <QboCard>
        <ITBox className="SystemStatusSection__wrapper" direction="column">
          {!forbiddenState && (
            <ITBox className="SystemStatusSection__create-button">
              <IconButton sx={{ position: 'relative', right: '0.7rem' }} onClick={fetchHandler}>
                <RefreshIcon sx={{ width: '2rem', height: '2rem' }} />
              </IconButton>
              &nbsp;
              <QboButton variant="contained" onClick={onOpenModal}>
                {t('mailbox_change.new_request')}
              </QboButton>
            </ITBox>
          )}
        </ITBox>

        <QboCard noPadding isMainWrapper>
          <>
            {(initiateListState?.length || fetching) && (
              <QboAdvanceTable
                rows={initiateListState}
                isLoading={fetching}
                pagination={paginations}
                setPagination={setPaginations}
                headers={tableColumns}
                enableSorting={Boolean(true)}
                rowsCount={Number(pagination?.TotalCount)}
              />
            )}
            {!initiateListState?.length && !fetching && (
              <NoStatusSection forbiddenErrorMessage={forbiddenErrorMessage} />
            )}
          </>
        </QboCard>
        <QboModal
          className="SwitchSPATypePage_default-modal"
          onClose={onCloseModal}
          open={showModal}
          title={t('switch_spa_page.form.title_text')}
          showCloseIcon
          preventOutsideClick>
          <StepperBlock
            steps={steps}
            icons={icons}
            hideLabel
            activeStepAlt={activeStep}
            isStepOptionalAlt={isStepOptional}
            isStepSkippedAlt={isStepSkipped}>
            <SwitchSPATypeFormSection
              supportTicketAttributes={supportTicketAttributes}
              escalationTicketAttributes={escalationTicketAttributes}
              descriptionAttributes={descriptionAttributes}
              credentialsIdAttributes={credentialsIdAttributes}
              organizationOwnerIdAttributes={organizationOwnerIdAttributes}
              errorMessage={errorValidation}
              onSubmit={onValidation}
            />
            <CredentialsSPASection
              credentialsSPAData={credentialsInfo}
              onBack={onBack}
              onSubmit={onSubmit}
              loading={loading}
              showConfirmationModal={showModal}
            />
          </StepperBlock>
        </QboModal>
      </QboCard>
    </>
  );
}
