import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { QboTypography, QboButton, QboLink } from '@ui/Components';

export function QboModalCardList({ children, className }) {
  return <Box className={`Modal__card-list ${className}`}>{children}</Box>;
}
QboModalCardList.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
QboModalCardList.defaultProps = {
  children: null,
  className: null,
};

export function QboModalListItem({ icon, name, description1, description2 }) {
  return (
    <div className="Modal__list-item">
      {icon}
      <div className="Modal__list-item-text">
        <QboTypography variant="caption">{name}</QboTypography>
        <QboTypography variant="subtitle2">{description1}</QboTypography>
        <QboTypography variant="caption">{description2}</QboTypography>
      </div>
    </div>
  );
}
QboModalListItem.propTypes = {
  icon: PropTypes.node,
  name: PropTypes.string,
  description1: PropTypes.string,
  description2: PropTypes.string,
};
QboModalListItem.defaultProps = {
  icon: null,
  name: '',
  description1: '',
  description2: '',
};

export default function QboModal({
  classNameStyle,
  open,
  onClose,
  header,
  children,
  footer,
  className,
  title,
  button,
  preventOutsideClick,
  showCloseIcon,
  ...rest
}) {
  const { t } = useTranslation();

  const onCloseModal = (e, reason) => {
    if (!(reason === 'backdropClick' && preventOutsideClick)) {
      onClose();
    }
  };

  const renderHeader = () => {
    if (header) {
      return header;
    }

    return (
      <Box className="QboModal__header">
        <QboTypography id="modal-modal-title" variant="h5">
          {title}
        </QboTypography>
        {showCloseIcon ? (
          <QboLink className="QboModal__close-icon QboLink__gray-primary-hover" onClick={onClose}>
            <CloseIcon />
          </QboLink>
        ) : null}
      </Box>
    );
  };

  const renderBody = () => {
    if (!children) return null;
    if (typeof children === 'string') {
      return (
        <Box className="QboModal__body">
          <QboTypography id="modal-modal-body">{children}</QboTypography>
        </Box>
      );
    }
    return <Box className="QboModal__body">{children}</Box>;
  };

  const renderFooter = () => {
    if (!footer && !button) return null;
    if (footer) {
      return footer;
    }
    return (
      <Box
        className={`QboModal__footer ${
          typeof button === 'object' && button?.noPadding ? 'QboModal__button-no-padding' : ''
        }${
          typeof button === 'object' && button?.position === 'right' ? 'QboModal__button-right' : ''
        }${
          typeof button === 'object' && button?.position === 'left' ? 'QboModal__button-left' : ''
        }${
          Array.isArray(button) && button[0].position === 'right' ? 'QboModal__button-right' : ''
        }${Array.isArray(button) && button[0].position === 'left' ? 'QboModal__button-left' : ''}`}>
        {Array.isArray(button) && button[0]?.text && (
          <QboButton
            variant="outlined"
            onClick={() => button[0]?.onClick()}
            loading={button[0]?.loading}
            disabled={button[0]?.disabled || false}
            hidden={button[0]?.hidden || false}
            color="secondary">
            {button[0]?.text}
          </QboButton>
        )}
        {Array.isArray(button) && button[1]?.text && (
          <QboButton
            variant="contained"
            onClick={() => (typeof button === 'string' ? onClose() : button[1]?.onClick())}
            loading={button[1]?.loading}
            disabled={button[1]?.disabled || false}
            hidden={button[1]?.hidden || false}>
            {button[1]?.text}
          </QboButton>
        )}
        {typeof button === 'object' && !Array.isArray(button) && (
          <QboButton
            variant={button?.variant || 'contained'}
            onClick={() => button?.onClick()}
            loading={button?.loading}
            disabled={button?.disabled || false}
            hidden={button?.hidden || false}>
            {button.text}
          </QboButton>
        )}
        {typeof button === 'string' && button && (
          <QboButton variant="contained" onClick={() => onCloseModal()}>
            {button || t('modal.default_button')}
          </QboButton>
        )}
      </Box>
    );
  };

  const { style, ...propAttributes } = rest;
  if (preventOutsideClick) propAttributes.disableEscapeKeyDown = true;

  return (
    <Modal
      {...propAttributes}
      className={`QboModal__default ${className}`}
      open={open}
      onClose={onCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box className={`QboModal__wrapper ${classNameStyle}`}>
        {renderHeader()}
        {renderBody()}
        {renderFooter()}
      </Box>
    </Modal>
  );
}

QboModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
  header: PropTypes.node,
  title: PropTypes.string,
  footer: PropTypes.node,
  classNameStyle: PropTypes.string,
  button: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
    PropTypes.string,
  ]),
  preventOutsideClick: PropTypes.bool,
  showCloseIcon: PropTypes.bool,
};

QboModal.defaultProps = {
  open: false,
  onClose: () => {},
  children: null,
  className: '',
  header: null,
  title: '',
  footer: null,
  classNameStyle: '',
  button: '',
  preventOutsideClick: false,
  showCloseIcon: false,
};
