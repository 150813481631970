import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { resetRecreateBackupAdminsState } from '@features/recreateBackupAdmins/recreateBackupAdminsSlice';
import { resetGetRecreateBackupAdminsState } from '@features/recreateBackupAdmins/getRecreateBackupAdminsSlice';
import useValidation from './useValidation';
import useRecreateBackupAdmins from './useRecreateBackupAdmins';

function useForm() {
  const dispatch = useDispatch();
  const { handleCreateBackupsRequests, handleGetTenantDetail } = useRecreateBackupAdmins();

  const {
    supportTicketAttributes,
    escalationTicketAttributes,
    descriptionAttributes,
    organizationOwnerIdAttributes,
    credentialIdAttributes,
    resetForm,
    isValid,
    formData,
    validateAll,
  } = useValidation();

  const callApiHandler = () => {
    dispatch(resetRecreateBackupAdminsState());
    handleCreateBackupsRequests(formData);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    callApiHandler();
  };

  const onValidateData = (e) => {
    e.preventDefault();
    validateAll();

    if (isValid) {
      handleGetTenantDetail(formData['credential-id'], formData['org-owner-id']);
    }
  };

  useEffect(() => {
    if (isValid) {
      dispatch(resetGetRecreateBackupAdminsState());
      handleGetTenantDetail(formData['credential-id'], formData['org-owner-id']);
    }
  }, [isValid]);

  return {
    supportTicketAttributes,
    escalationTicketAttributes,
    descriptionAttributes,
    organizationOwnerIdAttributes,
    credentialIdAttributes,
    isValid,
    resetForm,
    formData,
    onSubmit,
    onValidateData,
  };
}

export default useForm;
