import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

import { ITBox, QboAdvanceTable, QboButton } from '@ui/Components';
import BaseSetting from '@config/BaseSetting';
import useTransferSubscriptionService from '@pages/TransferSubscriptionPage/pageHooks/useTransferSubscriptionService';
import NoStatusSection from '../SystemStatusPage/NoStatusSection';

export default function TransferSubscriptionFormPage({ onCreateTicket }) {
  const { t } = useTranslation();
  const { handleTransferSubscriptionAsync, processDataTransferSubscription } =
    useTransferSubscriptionService();

  const [transferData, setTransferData] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const { getTransferSubscriptionState } = useSelector(
    (state) => state.getTransferSubscriptionState
  );
  const { fetching, forbiddenErrorMessage, forbiddenState, transferSubscriptionList, paginations } =
    getTransferSubscriptionState;

  const fetchHandler = () => {
    if (!fetching) {
      handleTransferSubscriptionAsync(pagination.pageIndex + 1, pagination.pageSize);
    }
  };

  useEffect(() => {
    fetchHandler();
  }, [pagination.pageIndex, pagination.pageSize]);

  useEffect(() => {
    if (transferSubscriptionList?.length) {
      processDataTransferSubscription(transferSubscriptionList, setTransferData);
    }
  }, [transferSubscriptionList]);

  return (
    <ITBox className="SystemStatusSection__wrapper" direction="column">
      {!forbiddenState && (
        <ITBox className="SystemStatusSection__create-button">
          <IconButton sx={{ position: 'relative', right: '0.7rem' }} onClick={fetchHandler}>
            <RefreshIcon sx={{ width: '2rem', height: '2rem' }} />
          </IconButton>
          &nbsp;
          <QboButton variant="contained" onClick={() => onCreateTicket()} loading={fetching}>
            {t('system_status_section.create_button_text')}
          </QboButton>
        </ITBox>
      )}
      {(transferData.length > 0 || fetching) && (
        <QboAdvanceTable
          enableSorting
          isLoading={fetching}
          rows={transferData}
          headers={BaseSetting.transferSubscriptionHeaders}
          pagination={pagination}
          setPagination={setPagination}
          rowsCount={paginations?.TotalCount}
        />
      )}
      {!fetching && !transferData?.length && (
        <NoStatusSection forbiddenErrorMessage={forbiddenErrorMessage} />
      )}
    </ITBox>
  );
}

TransferSubscriptionFormPage.propTypes = {
  onCreateTicket: PropTypes.func,
};

TransferSubscriptionFormPage.defaultProps = {
  onCreateTicket: () => {},
};
