import { useState } from 'react';
import * as yup from 'yup';

const accountIDName = 'accountId';
const descriptionName = 'description';
const mappingFileName = 'mappingFile';
const supportTicketName = 'supportTicket';
const escalationTicketName = 'escalationTicket';
const emailIds = 'emailIds';

const InitialForm = {
  [accountIDName]: '',
  [descriptionName]: '',
  [mappingFileName]: '',
  [supportTicketName]: '',
  [escalationTicketName]: '',
  [emailIds]: '',
};

export default function useFormValidation() {
  const [isValid, setIsValid] = useState(false);
  const [errors, setErrors] = useState('');
  const [formData, setFormData] = useState(InitialForm);

  const formSchema = yup.object().shape({
    [accountIDName]: yup
      .number()
      .typeError('Organization Owner ID is a required field and must be a number type')
      .required(),
    [mappingFileName]: yup
      .mixed()
      .required()
      .test('isValidType', 'Not a valid type', (value) =>
        value ? ['txt', 'csv'].indexOf(value?.name?.split('.')?.pop()) > -1 : true
      ),
    [supportTicketName]: yup.string().required('Support Ticket is required field'),
    [emailIds]: yup.string().required('Emails is a required field'),
  });

  const validate = async (value) => {
    await formSchema.validate(value, { abortEarly: false }).catch(function (err) {
      const errorLogs = err.inner.reduce((acc, error) => {
        return {
          ...acc,
          [error.path]: error.message,
        };
      }, {});
      setErrors(errorLogs);
      return errorLogs;
    });
    const validation = await formSchema.isValid(value);
    if (validation) {
      setErrors({});
    }
    setIsValid(validation);
    return validation;
  };

  const validateAll = () => {
    formData.emailIds = formData.emailIds
      .split(',')
      .filter((item) => item.trim())
      .toString();
    validate(formData);
  };

  const updateFormData = (newData) => {
    setFormData((prev) => {
      return { ...prev, ...newData };
    });
  };

  const accountIDAttributes = {
    handleChange: async (value) => {
      updateFormData({ [accountIDName]: value });
    },
    errorMessage: errors[accountIDName],
    value: formData[accountIDName],
  };

  const handleFileWrite = (value) => {
    if (value) {
      updateFormData({ [mappingFileName]: value });
    }
  };
  const mappingFileAttributes = {
    handleChange: async (value) => {
      handleFileWrite(value);
    },
    errorMessage: errors[mappingFileName],
    value: formData[mappingFileName],
  };

  const supportTicketAttributes = {
    handleChange: async (value) => {
      updateFormData({ [supportTicketName]: value });
    },
    errorMessage: errors[supportTicketName],
    value: formData[supportTicketName],
  };

  const descriptionAttributes = {
    handleChange: async (value) => {
      updateFormData({ [descriptionName]: value });
    },
    value: formData[descriptionName],
  };
  const escalationTicketAttributes = {
    handleChange: async (value) => {
      updateFormData({ [escalationTicketName]: value });
    },
    value: formData[escalationTicketName],
  };

  const emailIdsAttributes = {
    handleChange: async (value) => {
      updateFormData({ [emailIds]: value });
    },
    value: formData[emailIds],
    errorMessage: errors[emailIds],
  };

  const resetForm = () => {
    setFormData(InitialForm);
    setErrors({});
    setIsValid(false);
  };

  return {
    formData,
    accountIDAttributes,
    supportTicketAttributes,
    escalationTicketAttributes,
    mappingFileAttributes,
    emailIdsAttributes,
    descriptionAttributes,
    validateAll,
    isValid,
    validate,
    resetForm,
  };
}
