import { createSlice } from '@reduxjs/toolkit';

import {
  changeBackupAdminAsync,
  changeBackupAdminExtraReducers,
} from './serviceActions/changeBackupAdminAsync';

const initialState = {
  changeBackupAdminState: {
    fetching: false,
    status: null,
    error: null,
    message: null,
    errorMessage: null,
    forbiddenState: false,
    forbiddenErrorMessage: null,
    changeBackupAdminCorsErrorMessage: null,
  },
};

const changeBackupAdminSlice = createSlice({
  name: 'changeBackupAdmin',
  initialState,
  reducers: {
    resetChangeBackupAdminState(state) {
      state.changeBackupAdminState = initialState.changeBackupAdminState;
    },
  },
  extraReducers: {
    ...changeBackupAdminExtraReducers,
  },
});

export { changeBackupAdminAsync };

export const { resetChangeBackupAdminState } = changeBackupAdminSlice.actions;
export default changeBackupAdminSlice.reducer;
