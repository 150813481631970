import { createSlice } from '@reduxjs/toolkit';

import {
  getDomainBackupsAsync,
  getDomainBackupsExtraReducers,
} from './serviceActions/fetchDomainBackupsAsync';

const initialState = {
  getDomainBackupsState: {
    fetching: false,
    status: null,
    domainBackupsList: [],
    errorMessage: null,
    paginations: [],
    forbiddenState: false,
    forbiddenErrorMessage: null,
    domainBackupsCorsErrorMessage: null,
  },
};

const getDomainBackupsSlice = createSlice({
  name: 'domainBackups',
  initialState,
  reducers: {
    resetDomainBackupsState(state) {
      state.getDomainBackupsState = initialState.getDomainBackupsState;
    },
  },
  extraReducers: {
    ...getDomainBackupsExtraReducers,
  },
});

export { getDomainBackupsAsync };

export const { resetDomainBackupsState } = getDomainBackupsSlice.actions;
export default getDomainBackupsSlice.reducer;
