import { createAsyncThunk } from '@reduxjs/toolkit';
import { getFeatureFlagAPI } from '@services/WebApiService';

const getFeatureFlagAsync = createAsyncThunk('FeatureFlag/getFeatureFlag', async (paramsList) => {
  try {
    const data = await getFeatureFlagAPI(paramsList);
    return data;
  } catch (error) {
    return error?.response;
  }
});

const { pending, fulfilled, rejected } = getFeatureFlagAsync;

const getFeatureFlagExtraReducers = {
  [pending]: (state) => {
    state.getFeatureFlagState.fetching = true;
  },
  [rejected]: (state) => {
    state.getFeatureFlagState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.getFeatureFlagState.fetching = false;

    if (action?.payload?.status === 403) {
      state.getFeatureFlagState.forbiddenState = true;
      state.getFeatureFlagState.forbiddenErrorMessage = {
        heading: 'Forbidden Error',
        caption: 'You are not authorize to access this resource',
      };
    }
    if (action?.payload?.data?.data) {
      state.getFeatureFlagState.featureFlagList = action?.payload?.data?.data;
      state.getFeatureFlagState.statusCode = action?.payload?.status;
      state.getFeatureFlagState.success = true;
    }

    if (action?.payload?.status !== 200) {
      state.getFeatureFlagState.featureFlagErrorMessage = 'Internal Server Error';
    }
  },
};

export { getFeatureFlagAsync, getFeatureFlagExtraReducers };
