import { createSlice } from '@reduxjs/toolkit';

import {
  getRecreateBackupAdminsAsync,
  getRecreateBackupAdminsExtraReducers,
} from './serviceActions/fetchRecreateBackupAdminsAsync';

const initialState = {
  getRecreateBackupAdminsState: {
    fetching: false,
    status: null,
    recreateBackupAdminsList: [],
    errorMessage: null,
    paginations: [],
    forbiddenState: false,
    forbiddenErrorMessage: null,
    recreateBackupAdminsCorsErrorMessage: null,
  },
};

const recreateBackupAdminsSlice = createSlice({
  name: 'recreateBackupAdmins',
  initialState,
  reducers: {
    resetGetRecreateBackupAdminsState(state) {
      state.getRecreateBackupAdminsState = initialState.getRecreateBackupAdminsState;
    },
  },
  extraReducers: {
    ...getRecreateBackupAdminsExtraReducers,
  },
});

export { getRecreateBackupAdminsAsync };

export const { resetGetRecreateBackupAdminsState } = recreateBackupAdminsSlice.actions;
export default recreateBackupAdminsSlice.reducer;
