import WebApiRoutes from '@config/Routes/WebApiRoutes';
import ServiceClientFactory from '@libs/ServiceClient';

const webClientApi = (accessToken) => {
  return new ServiceClientFactory({ baseUrl: WebApiRoutes.getBaseUrl(), accessToken }).create();
};

const SwitchSPATypeService = {
  switchSPACommandListApi: (paramsList = {}) => {
    const { accessToken, reset, pageNumber, pageSize } = paramsList;
    const urlParams = { pageNumber };
    if (pageSize) {
      urlParams.pageSize = pageSize;
    }
    return webClientApi(accessToken, reset).getRequest({
      url: WebApiRoutes.switchSPATypePath(urlParams),
    });
  },
  switchSPACommandApi: (paramsList = {}) => {
    const { accessToken, reset, parameters } = paramsList;
    return webClientApi(accessToken, reset).postRequest({
      url: WebApiRoutes.switchSPATypePath(),
      parameters,
    });
  },
  credentilasSPAinfoApi: (paramsList = {}) => {
    const { accessToken, reset, parameters } = paramsList;
    return webClientApi(accessToken, reset).getRequest({
      url: WebApiRoutes.SPACrendentialsPath(parameters),
    });
  },
};

export default SwitchSPATypeService;
