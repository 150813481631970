import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  transferSubscriptionValidateAsync,
  transferSubscriptionAsync,
  resetStateStatus,
  resetStateValue,
} from '@features/transferSubscription/transferSubscriptionSlice';
import useStepper from '@pages/sharedHooks/useStepper';
import { useAlertMessage, usePageAlert, useDateTime } from '@hooks';
import useSystemStatus from '@pages/sharedHooks/useSystemStatus';
import useLocalStorage from '@hooks/useLocalStorage';
import { constant } from '@config/BaseSetting';
import useTransferSubscriptionFormValidation from './useTransferSubscriptionFormValidation';
import useTransferSubscriptionService from './useTransferSubscriptionService';

const StatusNotExist = 'NotExist';
const StatusWaiting = 'Waiting';
const StatusAccepted = 'Accepted';
const StatusDeclined = 'Declined';

export const TransferSubscriptionStatus = {
  StatusNotExist,
  StatusWaiting,
  StatusAccepted,
  StatusDeclined,
};

export default function useTransferSubscription(props = {}) {
  const { optionalStep } = props;
  const { getSuccessMessage, getErrorMessage, konstMessage } = useAlertMessage();
  const { updateSuccessAlert, updateErrorAlert } = usePageAlert();
  const { dateToString, dateFormat } = useDateTime();
  const {
    systemStatusList: collection,
    loading: isLoadingSystemStatus,
    handleFetchSystemStatus,
  } = useSystemStatus({
    payload: { statusType: 'command-jobs', type: 3 },
  });
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);

  const [transfer, setTransfer] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const {
    formData,
    supportTicketAttributes,
    escalationTicketAttributes,
    descriptionAttributes,
    resellerRequestIdAttributes,
    validateAll,
    resetForm,
  } = useTransferSubscriptionFormValidation();

  const { activeStep, isStepOptional, isStepSkipped, handleNext, handleBack, handleReset } =
    useStepper({ optionalStep });
  const { handleTransferSubscriptionAsync } = useTransferSubscriptionService();
  const paginations = {
    pageIndex: 1,
    pageSize: 10,
  };

  const dispatch = useDispatch();
  const { transferSubscriptionValidate, transferSubscription } = useSelector(
    (state) => state.TransferSubscription
  );
  const {
    success: successValidate,
    error: errorValidate,
    submitting: submittingValidate,
    value,
    validateErrorMessage,
  } = transferSubscriptionValidate;
  const { success, error, submitting, errorMessage } = transferSubscription;
  const payload = {
    'support-ticket': formData?.supportTicket,
    'escalation-ticket': formData?.escalationTicket,
    description: formData?.description,
    'change-reseller-request-id': formData?.resellerRequestIdName,
  };

  const handleResetForm = () => {
    handleReset();
    resetForm();
    setTransfer(false);
    dispatch(resetStateValue());
    // setShowModal(false);
    handleTransferSubscriptionAsync(paginations.pageIndex, paginations.pageSize);
    // handleFetchSystemStatus();
  };

  const handleResetFormState = () => {
    handleReset();
    resetForm();
    setTransfer(false);
    handleTransferSubscriptionAsync(paginations.pageIndex, paginations.pageSize);
    dispatch(resetStateValue());
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const isValidate = await validateAll();
    if (isValidate && !submittingValidate) {
      dispatch(
        transferSubscriptionValidateAsync({
          accessToken: accessTokenSet,
          reset: true,
          parameters: payload,
        })
      );
      handleNext();
    }
  };

  const onOpenConfirmation = () => {
    handleNext();
  };

  const onTransfer = () => {
    dispatch(
      transferSubscriptionAsync({ accessToken: accessTokenSet, reset: true, parameters: payload })
    );
  };

  const onBack = () => {
    if (transfer) {
      handleResetForm();
    } else {
      handleBack();
    }
  };

  const onOpenModal = () => {
    setShowModal(true);
  };

  const onCloseModal = () => {
    setShowModal(false);
    handleResetForm();
  };

  useEffect(() => {
    return () => {
      dispatch(resetStateStatus());
    };
  }, [successValidate, errorValidate]);

  useEffect(() => {
    if (success || error) {
      setTransfer(true);
    }
    return () => {
      dispatch(resetStateStatus());
    };
  }, [success, error]);

  useEffect(() => {
    if (success) {
      const date = value?.['change-reseller-date'];
      const alertPayload = {
        ...getSuccessMessage(konstMessage.SUCCESS_TRANSFER_SUBSCRIPTION, {
          change_reseller_date: dateToString(dateFormat.DEFAULT_DATE_TIME, date),
        }),
      };

      updateSuccessAlert(alertPayload);
      handleResetForm();
    } else if (error) {
      const alertPayload = {
        ...getErrorMessage(konstMessage.ERROR_TRANSFER_SUBSCRIPTION, {
          error_message: error,
        }),
      };

      updateErrorAlert(alertPayload);
      handleResetForm();
    } else if (errorMessage) {
      const alertPayload = {
        ...getErrorMessage(konstMessage.ERROR_TRANSFER_SUBSCRIPTION, {
          error_message: errorMessage,
        }),
      };

      updateErrorAlert(alertPayload);
      handleResetFormState();
    } else if (validateErrorMessage) {
      const alertPayload = {
        ...getErrorMessage(konstMessage.ERROR_TRANSFER_SUBRESELLER, {
          error_message: validateErrorMessage,
        }),
      };
      updateErrorAlert(alertPayload);
      handleResetForm();
    }
  }, [success, error, errorMessage, validateErrorMessage]);

  return {
    supportTicketAttributes,
    escalationTicketAttributes,
    descriptionAttributes,
    resellerRequestIdAttributes,
    onSubmit,
    activeStep,
    isStepOptional,
    isStepSkipped,
    onBack,
    transferSubscriptionData: value,
    onTransfer,
    loading: submittingValidate || submitting,
    showModal,
    onOpenModal,
    onCloseModal,
    onOpenConfirmation,
    collection,
    isLoadingSystemStatus,
  };
}
