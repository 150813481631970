export default function useDataService() {
  const gwsTenantInfoDetails = [
    {
      label: 'Credential ID',
      properties: 'id',
      valType: 'int',
    },
    {
      label: 'Organization Owner ID',
      properties: 'organizationID',
      valType: 'int',
    },
    {
      label: 'Partner ID',
      properties: 'partnerID',
      valType: 'int',
    },
    { label: 'Tenant ID', properties: 'tenant', valType: 'string' },
    {
      label: 'Email',
      properties: 'encryptedEmail',
      valType: 'string',
    },
    {
      label: 'App Auth Status',
      properties: 'appAuthStatus',
      valType: 'int',
    },
    {
      label: 'Domain',
      properties: 'domain',
      valType: 'string',
    },
    {
      label: 'User Autodiscover Enabled At',
      properties: 'lastAutodiscoverActivatedAt',
      valType: 'string',
    },
    { label: 'Created At', properties: 'createdAt', valType: 'string' },
    { label: 'Last Updated At', properties: 'updatedAt', valType: 'string' },
    {
      label: 'Adding Backup Email',
      properties: 'addingBackupEmail',
      valType: 'bool',
    },
    {
      label: 'Last Added Backup Email At',
      properties: 'lastAddedBackupEmailAt',
      valType: 'string',
    },
    {
      label: 'Last Added User Backup Refereces',
      properties: 'addBackupEmailReferences',
      valType: 'string',
    },
    { label: 'Total User Numbers', properties: 'totalUsers', valType: 'int' },
    {
      label: 'Last Fetch Users At',
      properties: 'lastScheduleGSuiteRequestAt',
      valType: 'string',
    },
    {
      label: 'Last Fetch Users ID',
      properties: 'lastRequestId',
      valType: 'int',
    },
    {
      label: 'Sd Drive Autodiscover Enabled At',
      properties: 'sdDriveAutodiscoverActivatedAt',
      valType: 'string',
    },
    {
      label: 'Autodiscover Setup Completed',
      properties: 'completedAutodiscoverSetup',
      valType: 'bool',
    },
    {
      label: 'Last Added Sd Drive Backup Refereces',
      properties: 'addBackupSdDriveReferences',
      valType: 'string',
    },
    {
      label: 'Adding Backup Sd Drive',
      properties: 'addingBackupSdDrive',
      valType: 'bool',
    },
    {
      label: 'Last Added Sd Drive At',
      properties: 'lastAddedBackupSdDriveAt',
      valType: 'string',
    },
    {
      label: 'Credential Setup Completed',
      properties: 'completedSetup',
      valType: 'bool',
    },
    {
      label: 'Partner Name',
      properties: 'tenantPartnerName',
      valType: 'string',
    },
    {
      label: 'Organization Name',
      properties: 'orgName',
      valType: 'string',
    },
    {
      label: 'Parent',
      properties: 'tenantParent',
      valType: 'string',
    },
    {
      label: 'Endpoint',
      properties: 'url',
      valType: 'string',
    },
    {
      label: 'Gws Created At',
      properties: 'gwsCreatedAt',
      valType: 'string',
    },
    {
      label: 'Gws Updated At',
      properties: 'gwsUpdatedAt',
      valType: 'string',
    },
    {
      label: 'Available Users Number',
      properties: 'availableUsersNumber',
      valType: 'string',
    },
    {
      label: 'Added Users Number',
      properties: 'addedUsersNumber',
      valType: 'string',
    },
    {
      label: 'Excluded Users Number',
      properties: 'excludedUsersNumber',
      valType: 'string',
    },
    {
      label: 'Not Available Users Number',
      properties: 'notAvailableUsersNumber',
      valType: 'string',
    },
    {
      label: 'All User Numbers',
      properties: 'allUserNumbers',
      valType: 'string',
    },
  ];
  return {
    gwsTenantInfoDetails,
  };
}
