import React, { useEffect, useState } from 'react';
import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { hexTenantListPath } from '@config/Routes/WebClientRoutes';
import HEXTenantDetailsPage from '@pages/TenantDetailsPage/HEXTenantDetailsPage';
import { QboBreadcrumbs, QboCard, QboTabs, QboTypography } from '@ui/Components';
import { setHEXTenantDetailState } from '@features/hexTenantDetails/hexTenantDetailsInfoSlice';

export default function HEXTenantDetailsTab() {
  const { hexTenantDetailState } = useSelector((state) => state.hexTenantDetailsInfo);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectTab, setSelectTab] = useState(0);
  const names = ['Details'];
  const { row } = hexTenantDetailState;
  const original = row?.row?.original;

  const handleOnBreadcrumbClick = () => {
    dispatch(setHEXTenantDetailState({}));
  };

  const handleOnTabClick = (e, newValue) => {
    setSelectTab(newValue);
  };

  useEffect(() => {
    if (!original) {
      navigate(hexTenantListPath);
    }
  }, [original]);

  const detailsComponent = (namesArray, selectTabState) => {
    if (namesArray[selectTabState] === 'Details') {
      return <HEXTenantDetailsPage hexTenantCredentialId={original?.id} />;
    }
    return (
      <QboCard sx={{ marginTop: 2 }} isCentered isBordered>
        <HeaderPageBlock>
          The {namesArray[selectTabState] || namesArray[0]} feature will be available soon.
        </HeaderPageBlock>
      </QboCard>
    );
  };
  return (
    <>
      <HeaderPageBlock>HEX Tenant Information</HeaderPageBlock>
      <QboBreadcrumbs sx={{ marginBottom: 2 }}>
        <Link
          onClick={() => handleOnBreadcrumbClick()}
          to={hexTenantListPath}
          style={{
            color: '#1c63d5',
            fontSize: '1.5rem',
            textDecoration: 'underline',
          }}>
          HEX Tenant
        </Link>
        <QboTypography color="text.primary" style={{ fontSize: '1.5rem' }}>
          HEX Tenant Information
        </QboTypography>
      </QboBreadcrumbs>

      <QboCard isMainWrapper>
        {original?.encryptedPowershellUsername && (
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '2%' }}>
            <QboTypography variant="h5" wrap sx={{ fontSize: 18 }}>
              {original?.encryptedPowershellUsername}
            </QboTypography>
            <QboTypography variant="h5" sx={{ marginLeft: 1, fontSize: 16 }}>
              (ID: {original?.id})
            </QboTypography>
          </div>
        )}
        <div
          style={{
            overflowX: 'auto',
            marginRight: 5,
            display: 'flex',
            scrollbarWidth: 'thin',
            backgroundColor: '#c4e3f0',
          }}>
          <QboTabs tabIndex={selectTab} title={names} onChange={handleOnTabClick} />
        </div>
        {detailsComponent(names, selectTab, original)}
      </QboCard>
    </>
  );
}
