import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import {
  ITBox,
  QboAdvanceTable,
  QboAlert,
  QboButton,
  QboCard,
  QboFormGroup,
  QboSnackbar,
  QboTextField,
  QboTypography,
  QboSelect,
} from '@ui/Components';
import BaseSetting from '@config/BaseSetting';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import { resetSeatUsageReportsStatus } from '@features/seatUsageReport/seatUsageSlice';
import { resetSeatUsageSummaryStatus } from '@features/seatUsageReport/seatUsageSummarySlice';
import useForm from './pageHooks/useForm';
import useFetchHandler from './pageHooks/useFetchHandler';

export default function SeatUsageReportsPage() {
  const {
    formData,
    organizationOwnerIdAttributes,
    protocolAttributes,
    credentialIdAttributes,
    isValid,
    resetForm,
    onSubmit,
    orgOwnerId,
    pagination,
    setPagination,
  } = useForm();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    fetchHandler,
    fetchSeatUsageSummary,
    responseConverter,
    conditionalStateReset,
    responseDataConverter,
    fetchExportHandler,
    convertToCsv,
  } = useFetchHandler();
  const isAsteriskRequired = true;
  const [reportData, setReportData] = useState([]);
  const [reportSummaryData, setReportSummaryData] = useState([]);
  const { seatUsageReportsState } = useSelector((state) => state.seatUsageReportsData);
  const { seatUsageSummaryState } = useSelector((state) => state.seatUsageSummary);
  const { exportSeatUsageReportState } = useSelector((state) => state.exportSeatUsageReport);

  const [downloadStatus, setDownloadStatus] = useState('');
  const [open, setOpen] = useState(false);
  const [type, setType] = useState('');

  const {
    fetching,
    errorMessage,
    forbiddenErrorMessage,
    pagination: paginations,
    statusCode,
  } = seatUsageReportsState;
  const {
    fetching: summaryFetching,
    errorMessage: errorsMsgSummary,
    seatUsageSummaryData,
    summaryStatusCode,
  } = seatUsageSummaryState;
  const { fetching: exportFetching, seatUsageExportData } = exportSeatUsageReportState;
  const width = !isEmpty(seatUsageSummaryData) || summaryFetching ? '50%' : '100%';

  const style = {
    color: '#000000DE',
    fontWeight: 600,
  };

  const buttonStyle = {
    height: 36,
    backgroundColor: '#a5a5a5',
    textDecoration: 'none',
    color: 'black',
    '&:hover': {
      backgroundColor: '#a5a5a5',
    },
    textTransform: 'unset',
    fontSize: 14,
  };

  const handleOnExportCSV = () => {
    if (!exportFetching) {
      fetchExportHandler({
        pageNumber: pagination.pageIndex + 1,
        pageSize: pagination.pageSize,
        orgOwnerId,
        protocol: protocolAttributes?.value,
        credentialId: credentialIdAttributes?.value,
      });
    }
  };

  useEffect(() => {
    if (seatUsageExportData) {
      convertToCsv(seatUsageExportData, setDownloadStatus, setOpen, setType);
    }
  }, [seatUsageExportData]);

  const handleOnResetButton = () => {
    dispatch(resetSeatUsageReportsStatus());
    dispatch(resetSeatUsageSummaryStatus());
    resetForm();
  };

  const fetchSeatUsageHandler = () => {
    fetchHandler({
      pageNumber: pagination.pageIndex + 1,
      pageSize: pagination.pageSize,
      orgOwnerId,
      protocol: protocolAttributes?.value,
      credentialId: credentialIdAttributes?.value,
    });
  };

  const fetchSeatUsageSummaryPagination = () => {
    if (isValid && !isEmpty(orgOwnerId)) {
      fetchSeatUsageSummary({ orgOwnerId });
    }
  };

  const getFlag = () => {
    return (
      (!isEmpty(reportSummaryData) || summaryFetching) &&
      (!isEmpty(reportSummaryData) || summaryFetching)
    );
  };

  const protocolType = [
    { id: '0', label: 'All' },
    // {id: 1, label: 'IMAP'},
    // {id: 0, label: 'POP'},
    { id: '3', label: 'M365' },
    { id: '4', label: 'GWS' },
  ];

  const handleOnSnackbarCose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (statusCode === 200) {
      resetForm();
    }
  }, [statusCode]);

  useEffect(() => {
    responseDataConverter(seatUsageReportsState?.seatUsageData, setReportData, resetForm);
  }, [seatUsageReportsState?.seatUsageData]);

  useEffect(() => {
    if (!isEmpty(seatUsageSummaryData)) {
      setReportSummaryData(responseConverter(seatUsageSummaryData));
    } else {
      setReportSummaryData([]);
    }
  }, [seatUsageSummaryData]);

  useEffect(() => {
    if (!errorMessage && !errorsMsgSummary && !isEmpty(orgOwnerId) && statusCode) {
      fetchSeatUsageHandler();
    }
  }, [pagination]);

  useEffect(() => {
    fetchSeatUsageSummaryPagination();
    if (isValid && !isEmpty(orgOwnerId) && !fetching) {
      fetchSeatUsageHandler();
    }
  }, [orgOwnerId]);

  useEffect(() => {
    conditionalStateReset(statusCode, summaryStatusCode, fetching, summaryFetching);
  }, [statusCode, summaryStatusCode, fetching, summaryFetching]);

  return (
    <>
      <QboSnackbar
        open={open}
        type={type}
        message={downloadStatus}
        onClose={handleOnSnackbarCose}
      />
      <QboCard noPadding isMainWrapper>
        <ITBox className="TransferSubscriptionFormPage__wrapper" direction="column">
          <QboFormGroup
            buttonStyle={buttonStyle}
            onSubmit={onSubmit}
            withGenerateAndResetButton
            handleOnResetButton={handleOnResetButton}>
            <QboTypography style={{ fontSize: 20, ...style }}>
              {t('seat_usage_report.title')}
            </QboTypography>
            <QboTextField
              isWidthSetExplicit
              required={isAsteriskRequired}
              id="organisationOwnerID"
              type="number"
              placeholder={t('seat_usage_report.form.organisation_id')}
              label={t('seat_usage_report.form.organisation_id')}
              value={organizationOwnerIdAttributes?.value}
              errorMessage={organizationOwnerIdAttributes?.errorMessage}
              inputProps={{ maxLength: 225 }}
              onChange={(e) => {
                organizationOwnerIdAttributes.handleChange(e.currentTarget.value);
              }}
            />
            <QboSelect
              isWidthSetExplicit
              label={t('seat_usage_report.form.protocol')}
              options={protocolType}
              handleChange={(e) => {
                protocolAttributes.handleChange(e.target.value);
              }}
              value={protocolAttributes?.value}
              defaultValue="0"
            />
            <QboTextField
              isWidthSetExplicit
              id="credentialID"
              type="number"
              placeholder={t('seat_usage_report.form.credential_id')}
              label={t('seat_usage_report.form.credential_id')}
              value={credentialIdAttributes?.value}
              errorMessage={credentialIdAttributes?.errorMessage}
              inputProps={{ maxLength: 225 }}
              onChange={(e) => {
                credentialIdAttributes.handleChange(e.currentTarget.value);
              }}
              disabled={!protocolAttributes.value || protocolAttributes.value === '0'}
            />
            {/*  */}
          </QboFormGroup>
        </ITBox>
      </QboCard>
      <hr />

      {getFlag() ? (
        <QboCard>
          <ITBox className="TransferSubscriptionFormPage__wrapper" direction="column">
            <QboTypography style={{ fontSize: 20, ...style }}>
              {t('seat_usage_report.seat_usage_summary')}
            </QboTypography>
            {orgOwnerId !== 0 && (
              <QboTypography style={{ fontSize: 14, ...style }}>
                Organization Owner ID: {orgOwnerId}
              </QboTypography>
            )}
          </ITBox>
          <div style={{ padding: '0px 15px 20px 15px', width, marginBottom: 15 }}>
            <QboAdvanceTable
              isLoading={summaryFetching}
              headers={BaseSetting.seatUsageSummaryTableHeaders}
              rows={reportSummaryData}
              enableTopToolBar={false}
              enableBottomToolbar={false}
            />
          </div>
          <ITBox className="TransferSubscriptionFormPage__wrapper" direction="column">
            <QboTypography style={{ fontSize: 20, ...style }}>
              {t('seat_usage_report.seat_usage_details')}
            </QboTypography>
            {getFlag() && (
              <QboButton style={{ width: 120 }} variant="contained" onClick={handleOnExportCSV}>
                {t('export_csv')}
              </QboButton>
            )}
          </ITBox>
          <div style={{ padding: '0px 15px 20px 15px' }}>
            {!isEmpty(formData?.protocol) || !isEmpty(formData?.credentialId) ? (
              <QboTypography variant="subtitle2">
                {t('seat_usage_report.data_is_filtered')}
              </QboTypography>
            ) : (
              ''
            )}
            <QboAdvanceTable
              isLoading={fetching}
              headers={BaseSetting.seatUsageDetailsTableHeaders}
              rows={reportData}
              pagination={pagination}
              setPagination={setPagination}
              rowsCount={Number(paginations?.TotalCount)}
            />
          </div>
        </QboCard>
      ) : (
        <QboCard>
          <ITBox className="TransferSubscriptionFormPage__wrapper" direction="column">
            <QboTypography style={{ fontSize: 20, ...style }}>
              {t('seat_usage_report.seat_usage_summary')}
            </QboTypography>
            {(errorsMsgSummary || errorMessage) && (
              <QboAlert type="error" style={{ fontSize: '1.15em' }}>
                {errorsMsgSummary}
              </QboAlert>
            )}
          </ITBox>
          <NoStatusSection forbiddenErrorMessage={forbiddenErrorMessage} />
        </QboCard>
      )}
    </>
  );
}
