import WebApiRoutes from '@config/Routes/WebApiRoutes';
import ServiceClientFactory from '@libs/ServiceClient';

const webClientApi = (accessToken, reset) => {
  return new ServiceClientFactory({
    baseUrl: WebApiRoutes.getBaseUrl(),
    accessToken,
    reset,
  }).create();
};

const TransferSubscriptionService = {
  transferSubscriptionValidateApi: (paramsList = {}) => {
    const { accessToken, reset, parameters } = paramsList;
    return webClientApi(accessToken, reset).postRequest({
      url: WebApiRoutes.transferSubscriptionValidatePath(),
      parameters,
    });
  },
  transferSubscriptionApi: (paramsList = {}) => {
    const { accessToken, reset, parameters } = paramsList;
    return webClientApi(accessToken, reset).postRequest({
      url: WebApiRoutes.transferSubscriptionPath(),
      parameters,
    });
  },
};

export default TransferSubscriptionService;
