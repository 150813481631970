import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';
import { QboCard, QboButton, ITBox, QboTypography } from '@ui/Components';
import { TransferSubscriptionStatus } from '@pages/TransferSubscriptionPage/pageHooks/useTransferSubscription';
import { useDateTime } from '@hooks';

function TextWithLabel({ title, description }) {
  return (
    <>
      <QboTypography variant="caption">{title}</QboTypography>
      <QboTypography
        wrap
        variant="subtitle2"
        withTitle
        className="TransferSubscriptionPage__text-with-label">
        {description}
      </QboTypography>
    </>
  );
}

TextWithLabel.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

TextWithLabel.defaultProps = {
  title: '',
  description: '',
};

export default function TransferSubscriptionSection({
  transferSubscriptionData,
  onBack,
  onTransfer,
  loading,
  showButton,
}) {
  const { t } = useTranslation();
  const { dateToString, dateFormat } = useDateTime();
  const data = transferSubscriptionData;

  const renderLoading = () => {
    return (
      <div style={{ display: 'contents' }}>
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="70%" />
      </div>
    );
  };

  const renderContent = () => {
    if (loading) {
      return (
        <>
          <QboCard
            className="TransferSubscriptionPage__information-wrapper"
            titleCard={t('transfer_subscription_page.view_data.req_info_title_text')}
            isBordered>
            <ITBox className="TransferSubscriptionPage__information-box">
              <ITBox direction="column">{renderLoading()}</ITBox>
              <ITBox direction="column">{renderLoading()}</ITBox>
            </ITBox>
          </QboCard>
          <ITBox className="TransferSubscriptionPage__source-target">
            <QboCard
              classNameWrapper="TransferSubscriptionPage__source-target-card-wrapper"
              className="TransferSubscriptionPage__center-item"
              titleCard={t('transfer_subscription_page.view_data.source_title_text')}
              isBordered>
              {renderLoading()}
            </QboCard>
            <QboCard
              classNameWrapper="TransferSubscriptionPage__source-target-card-wrapper"
              className="TransferSubscriptionPage__center-item"
              titleCard={t('transfer_subscription_page.view_data.target_title_text')}
              isBordered>
              {renderLoading()}
            </QboCard>
          </ITBox>
        </>
      );
    }

    return (
      <>
        <QboCard
          className="TransferSubscriptionPage__information-wrapper"
          titleCard={t('transfer_subscription_page.view_data.req_info_title_text')}
          isBordered>
          <ITBox className="TransferSubscriptionPage__information-box">
            <ITBox direction="column">
              <TextWithLabel
                title={t('transfer_subscription_page.view_data.request_at')}
                description={dateToString(dateFormat.DEFAULT_DATE_TIME, data['requested-date'])}
              />
              <TextWithLabel
                title={t('transfer_subscription_page.view_data.request_by')}
                description={data['requester-email']}
              />
              <TextWithLabel
                title={t('transfer_subscription_page.view_data.subscription_owner')}
                description={data['subscription-owner-email-to-moved']}
              />
            </ITBox>
            <ITBox direction="column">
              <TextWithLabel
                title={t('transfer_subscription_page.view_data.status')}
                description={data['request-status']}
              />
              {data['accepted-date'] ? (
                <>
                  <TextWithLabel
                    title={t('transfer_subscription_page.view_data.accepted_at')}
                    description={data['accepted-date']}
                  />
                  <TextWithLabel
                    title={t('transfer_subscription_page.view_data.accepted_by')}
                    description={data['accepted-by']}
                  />
                </>
              ) : null}
              {data['declined-date'] ? (
                <>
                  <TextWithLabel
                    title={t('transfer_subscription_page.view_data.decline_at')}
                    description={data['declined-date']}
                  />
                  <TextWithLabel
                    title={t('transfer_subscription_page.view_data.decline_by')}
                    description={data['declined-by']}
                  />
                </>
              ) : null}
            </ITBox>
          </ITBox>
        </QboCard>
        <ITBox className="TransferSubscriptionPage__source-target">
          <QboCard
            classNameWrapper="TransferSubscriptionPage__source-target-card-wrapper"
            className="TransferSubscriptionPage__center-item"
            titleCard={t('transfer_subscription_page.view_data.source_title_text')}
            isBordered>
            <TextWithLabel
              title={t('transfer_subscription_page.view_data.source_reseller_name')}
              description={data['source-reseller-name']}
            />
            <TextWithLabel
              title={t('transfer_subscription_page.view_data.source_plan_name')}
              description={data['plan-source-name']}
            />
          </QboCard>
          <QboCard
            classNameWrapper="TransferSubscriptionPage__source-target-card-wrapper"
            className="TransferSubscriptionPage__center-item"
            titleCard={t('transfer_subscription_page.view_data.target_title_text')}
            isBordered>
            <TextWithLabel
              title={t('transfer_subscription_page.view_data.target_reseller_name')}
              description={data['destination-reseller-name']}
            />
            <TextWithLabel
              title={t('transfer_subscription_page.view_data.target_plan_name')}
              description={data['plan-destination-name']}
            />
          </QboCard>
        </ITBox>
      </>
    );
  };

  return (
    <ITBox className="TransferSubscriptionPage__wrapper" direction="column">
      {renderContent()}
      {showButton ? (
        <ITBox direction="row" className="TransferSubscriptionPage__footer-button">
          <QboButton
            variant="outlined"
            color="secondary"
            onClick={() => onBack()}
            loading={loading}>
            {t('transfer_subscription_page.view_data.back_button_text')}
          </QboButton>
          <QboButton
            variant="contained"
            withTitle={
              !(
                data['request-status'] === TransferSubscriptionStatus.StatusAccepted &&
                data['change-reseller-date'] === null
              )
            }
            title={t('transfer_subscription_page.view_data.tooltip_disabled_button_text')}
            onClick={() => onTransfer()}
            loading={loading}
            disabled={
              !(
                data['request-status'] === TransferSubscriptionStatus.StatusAccepted &&
                data['change-reseller-date'] === null
              )
            }>
            {t('transfer_subscription_page.view_data.transfer_button_text')}
          </QboButton>
        </ITBox>
      ) : null}
    </ITBox>
  );
}

TransferSubscriptionSection.propTypes = {
  transferSubscriptionData: PropTypes.object,
  onBack: PropTypes.func,
  onTransfer: PropTypes.func,
  loading: PropTypes.bool,
  showButton: PropTypes.bool,
};

TransferSubscriptionSection.defaultProps = {
  transferSubscriptionData: {},
  onBack: () => {},
  onTransfer: () => {},
  loading: true,
  showButton: true,
};
