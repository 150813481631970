import WebApiRoutes from '@config/Routes/WebApiRoutes';
import ServiceClientFactory from '@libs/ServiceClient';

const webClientApi = (accessToken) => {
  return new ServiceClientFactory({ baseUrl: WebApiRoutes.getBaseUrl(), accessToken }).create();
};

const ToolsRequestHistoryService = {
  getToolsRequestHistoryApi: (paramsList = {}) => {
    const { accessToken, reset, payload, serviceType, pageNumber, pageSize } = paramsList;
    const urlParams = { pageNumber };
    if (pageSize) {
      urlParams.pageSize = pageSize;
    }
    if (payload) {
      urlParams.payload = payload;
    }
    if (serviceType) {
      urlParams.serviceType = serviceType;
    }

    return webClientApi(accessToken, reset).getRequest({
      url: WebApiRoutes.getToolsRequestHistoryPath(urlParams),
    });
  },

  getToolRequestDetailsApi: (paramsList = {}) => {
    const { accessToken, reset, jobType, id } = paramsList;
    const urlParams = {};
    if (jobType) {
      urlParams.jobType = jobType;
    }
    if (id) {
      urlParams.id = id;
    }

    return webClientApi(accessToken, reset).getRequest({
      url: WebApiRoutes.getToolRequestDetails(urlParams),
    });
  },

  getServiceTypesApi: (paramsList = {}) => {
    const { accessToken, reset } = paramsList;
    return webClientApi(accessToken, reset).getRequest({
      url: WebApiRoutes.getServiceTypes({}),
    });
  },
};

export default ToolsRequestHistoryService;
