import { createSlice } from '@reduxjs/toolkit';
import {
  getPurgeExternalAccountChangeAsync,
  getPurgeExternalAccountReducers,
} from './serviceActions/getPurgeExternalAccountAsync';

const initialState = {
  getPurgeExternalAccountState: {
    fetching: false,
    success: false,
    data: [],
    error: null,
    errorMessage: null,
    pagination: [],
    forbiddenState: false,
    forbiddenErrorMessage: {},
    getPurgeExternalAccountCorsErrorMessage: null,
  },
};

const getPurgeExternalAccountSlice = createSlice({
  name: 'GetPurgeExternalAccount',
  initialState,
  reducers: {
    resetPurgeExternalAccountStatus(state) {
      state.getPurgeExternalAccountState = initialState.getPurgeExternalAccountState;
    },
  },
  extraReducers: {
    ...getPurgeExternalAccountReducers,
  },
});

export { getPurgeExternalAccountChangeAsync };
export const { resetPurgeExternalAccountStatus } = getPurgeExternalAccountSlice.actions;
export default getPurgeExternalAccountSlice.reducer;
