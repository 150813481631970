import { createSlice } from '@reduxjs/toolkit';

import {
  getDetailsOfDataIngestionAsync,
  getDetailsOfDataIngestionExtraReducers,
} from './serviceActions/fetchDetailsOfIngestionListAsync';

const initialState = {
  getDetailsOfIngestionDataState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    detailsOfPaginationList: null,
    detailsOfIngestionList: [],
    corsErrorState: null,
  },
};

const getDetailsOfIngestionSlice = createSlice({
  name: 'DetailsOfIngestionList',
  initialState,
  reducers: {
    resetDetailsOfIngestionSubmit(state) {
      state.getDetailsOfIngestionDataState = initialState.getDetailsOfIngestionDataState;
    },
  },
  extraReducers: {
    ...getDetailsOfDataIngestionExtraReducers,
  },
});

export { getDetailsOfDataIngestionAsync };
export const { resetDetailsOfIngestionSubmit } = getDetailsOfIngestionSlice.actions;
export default getDetailsOfIngestionSlice.reducer;
