import React, { useEffect, useRef, useState } from 'react';
import { IconButton } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { ITBox, QboAlert, QboButton, QboCard, QboModal } from '@ui/Components';
import BaseSetting from '@config/BaseSetting';
import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import QboAdvanceTable from '@ui/Components/QboAdvanceTable';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import DomainBackupsFormSection from '@ui/Sections/DomainBackupFormPage/DomainBackupsFormSection';
import { resetCreateDomainBackupsState } from '@features/domainBackups/createDomainBackupsSlice';
import useDomainBackups from './pageHooks/useDomainBackups';
import useForm from './pageHooks/useForm';

function DomainBackups() {
  const {
    supportTicketAttributes,
    escalationTicketAttributes,
    organizationOwnerIdAttributes,
    descriptionAttributes,
    domainAttributes,
    onSubmit,
    resetForm,
    formData,
    isValid,
  } = useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { handleFetchuseDomainBackupsList, getFilterdDataFromResponse } = useDomainBackups();
  const [paginations, setPaginations] = useState({ pageIndex: 0, pageSize: 10 });
  const [domainBackupsListState, setDomainBackupsListState] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const { getDomainBackupsState } = useSelector((state) => state.domainState);
  const previousDomainBackupListData = useRef();
  const {
    fetching,
    status,
    domainBackupsList,
    domainBackupsCorsErrorMessage,
    paginations: pagination,
    forbiddenState,
    forbiddenErrorMessage,
  } = getDomainBackupsState;

  const fetchHandler = () => {
    previousDomainBackupListData.current = domainBackupsList;
    if (!fetching) {
      handleFetchuseDomainBackupsList(paginations.pageIndex + 1, paginations.pageSize);
    }
  };

  useEffect(() => {
    previousDomainBackupListData.current = domainBackupsList;
    getFilterdDataFromResponse(domainBackupsList, setDomainBackupsListState);
  }, [domainBackupsList]);

  useEffect(() => {
    if (status) {
      fetchHandler();
    }
  }, [paginations.pageSize, paginations.pageIndex]);

  useEffect(() => {
    fetchHandler();
  }, []);

  return (
    <>
      <HeaderPageBlock>{t('domain_backups.header.text')}</HeaderPageBlock>
      {domainBackupsCorsErrorMessage && (
        <QboAlert type="error" style={{ fontSize: '1.15em' }}>
          {domainBackupsCorsErrorMessage}
        </QboAlert>
      )}
      <QboCard>
        {!forbiddenState && (
          <ITBox className="SystemStatusSection__wrapper" direction="column">
            {!forbiddenState && (
              <ITBox className="SystemStatusSection__create-button">
                <IconButton
                  sx={{ position: 'relative', right: '0.7rem' }}
                  onClick={() => fetchHandler()}>
                  <RefreshIcon sx={{ width: '2rem', height: '2rem' }} />
                </IconButton>
                &nbsp;
                <QboButton variant="contained" onClick={() => setShowModal(true)}>
                  {t('mailbox_change.new_request')}
                </QboButton>
              </ITBox>
            )}
          </ITBox>
        )}

        <QboCard noPadding isMainWrapper>
          <>
            {(domainBackupsListState.length > 0 || fetching) && (
              <QboAdvanceTable
                rows={domainBackupsListState}
                isLoading={fetching || previousDomainBackupListData.current !== domainBackupsList}
                pagination={paginations}
                setPagination={setPaginations}
                headers={BaseSetting.domainBackupsHeaders}
                enableSorting={Boolean(true)}
                rowsCount={pagination?.TotalCount}
              />
            )}
            {domainBackupsListState.length === 0 && !fetching && (
              <NoStatusSection forbiddenErrorMessage={forbiddenErrorMessage} />
            )}
          </>
        </QboCard>
        <QboModal
          onClose={() => {
            resetForm();
            setShowModal(false);
            dispatch(resetCreateDomainBackupsState());
            fetchHandler();
          }}
          open={showModal}
          title={t('domain_backups.modal.title_text')}
          showCloseIcon
          preventOutsideClick>
          <DomainBackupsFormSection
            supportTicketAttributes={supportTicketAttributes}
            escalationTicketAttributes={escalationTicketAttributes}
            organizationOwnerIdAttributes={organizationOwnerIdAttributes}
            descriptionAttributes={descriptionAttributes}
            domainAttributes={domainAttributes}
            onSubmit={onSubmit}
            resetForm={resetForm}
            formData={formData}
            isValid={isValid}
          />
        </QboModal>
      </QboCard>
    </>
  );
}

export default DomainBackups;
