import { createSlice } from '@reduxjs/toolkit';

import {
  runAutodiscoverAsync,
  runAutodiscoverExtraReducers,
} from './serviceActions/runAutodiscoverAsync';

const initialState = {
  runAutodiscoverState: {
    fetching: false,
    status: null,
    error: null,
    message: null,
    errorMessage: null,
    forbiddenState: false,
    forbiddenErrorMessage: null,
    runAutodiscoverCorsErrorMessage: null,
    serviceType: null,
  },
};

const runAutodiscoverSlice = createSlice({
  name: 'runLicenseChecker',
  initialState,
  reducers: {
    resetRunAutodiscoverState(state) {
      state.runAutodiscoverState = initialState.runAutodiscoverState;
    },
  },
  extraReducers: {
    ...runAutodiscoverExtraReducers,
  },
});

export { runAutodiscoverAsync };

export const { resetRunAutodiscoverState } = runAutodiscoverSlice.actions;
export default runAutodiscoverSlice.reducer;
