import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ITBox, QboAlert, QboFormGroup, QboTextField } from '@ui/Components';
import useEmailFromCSV from '@hooks/useEmailFromCSV';
import { resetBulkPurgeFormSubmit } from '@features/mailboxBulkPurge/mailboxBulkPurgeSlice';

export default function BulkPurgeFormSection({
  accountIDAttributes,
  mappingFileAttributes,
  supportTicketAttributes,
  escalationTicketAttributes,
  emailIdsAttributes,
  descriptionAttributes,
  onSubmit,
  resetForm,
  formData,
  isValid,
  validate,
}) {
  const { t } = useTranslation();
  const [emailLists, setEmailLists] = useState([]);
  const { handleOnFileUpload } = useEmailFromCSV();
  const dispatch = useDispatch();
  const isFormEmpty = Object.values(formData).filter((i) => i).length === 0;
  const isAstericRequired = true;
  const inputRef = useRef(null);

  const { submitBulkPurgeForm } = useSelector((state) => state.bulkFormData);
  const { statusCode, message } = submitBulkPurgeForm;

  useEffect(() => {
    if (emailLists.length > 0) {
      emailIdsAttributes.handleChange(emailLists.toString());
    }
  }, [emailLists]);

  useEffect(() => {
    if (statusCode === 200 || statusCode === 401) {
      inputRef.current.value = null;
      resetForm();
    }
  }, [statusCode]);

  useEffect(() => {
    if (statusCode === 200 || statusCode === 401) {
      if (!isFormEmpty) dispatch(resetBulkPurgeFormSubmit());
    }
  }, [isFormEmpty]);

  return (
    <ITBox className="TransferSubscriptionFormPage__wrapper" direction="column">
      <QboFormGroup onSubmit={onSubmit} withSubmitButton>
        {(statusCode || message) && statusCode !== 401 && (
          <QboAlert type={statusCode === 200 ? 'success' : 'error'} style={{ fontSize: '1.15em' }}>
            {(statusCode === 200 && 'Successfully Submitted') || message}
          </QboAlert>
        )}
        <QboTextField
          id="supportTicket"
          required={isAstericRequired}
          placeholder={t('bulk_purge.labels.support_ticket')}
          label={t('bulk_purge.labels.support_ticket')}
          fullWidth
          value={supportTicketAttributes.value}
          errorMessage={supportTicketAttributes.errorMessage}
          inputProps={{ maxLength: 225 }}
          onChange={(e) => {
            supportTicketAttributes.handleChange(e.currentTarget.value);
          }}
        />
        <QboTextField
          id="escalationTicket"
          placeholder={t('bulk_purge.labels.escalation_ticket')}
          label={t('bulk_purge.labels.escalation_ticket')}
          fullWidth
          value={escalationTicketAttributes.value}
          onChange={(e) => escalationTicketAttributes.handleChange(e.currentTarget.value)}
          inputProps={{ maxLength: 225 }}
        />
        <QboTextField
          id="description"
          placeholder={t('bulk_purge.labels.description')}
          label={t('bulk_purge.labels.description')}
          fullWidth
          value={descriptionAttributes.value}
          onChange={(e) => descriptionAttributes.handleChange(e.currentTarget.value)}
          inputProps={{ maxLength: 225 }}
        />
        <QboTextField
          id="organizationOwnerId"
          required={isAstericRequired}
          placeholder={t('bulk_purge.labels.organization_owner_id')}
          label={t('bulk_purge.labels.organization_owner_id')}
          fullWidth
          inputProps={{ maxLength: 225 }}
          value={accountIDAttributes.value}
          onChange={(e) => {
            accountIDAttributes.handleChange(e.currentTarget.value);
          }}
          errorMessage={accountIDAttributes.errorMessage}
        />
        <QboTextField
          id="emailIds"
          required={isAstericRequired}
          placeholder={t('bulk_purge.labels.email_accounts')}
          label={t('bulk_purge.labels.email_accounts')}
          multiline={Boolean(true)}
          rows={5}
          fullWidth
          value={emailIdsAttributes.value}
          onChange={(e) => emailIdsAttributes.handleChange(e.currentTarget.value)}
          errorMessage={emailIdsAttributes.errorMessage}
        />
        <QboTextField
          id="csvFile"
          placeholder={t('bulk_purge.labels.csv_file')}
          label={t('bulk_purge.labels.csv_file')}
          type="file"
          inputRef={inputRef}
          fullWidth
          inputProps={{ maxLength: 225 }}
          value={mappingFileAttributes?.value?.file}
          onChange={(event) => {
            handleOnFileUpload(event, setEmailLists);
            mappingFileAttributes.handleChange(event?.target?.files[0]);
          }}
          errorMessage={mappingFileAttributes.errorMessage}
        />
      </QboFormGroup>
    </ITBox>
  );
}
BulkPurgeFormSection.propTypes = {
  accountIDAttributes: PropTypes.object,
  mappingFileAttributes: PropTypes.object,
  supportTicketAttributes: PropTypes.object,
  escalationTicketAttributes: PropTypes.object,
  emailIdsAttributes: PropTypes.object,
  descriptionAttributes: PropTypes.object,
  formData: PropTypes.object,
  onSubmit: PropTypes.func,
  resetForm: PropTypes.func,
  validate: PropTypes.func,
  isValid: PropTypes.bool,
};

BulkPurgeFormSection.defaultProps = {
  accountIDAttributes: {},
  mappingFileAttributes: {},
  supportTicketAttributes: {},
  escalationTicketAttributes: {},
  emailIdsAttributes: {},
  descriptionAttributes: {},
  formData: {},
  onSubmit: () => {},
  resetForm: () => {},
  validate: () => {},
  isValid: false,
};
