import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default function useUrlQuery(key) {
  const [value, setValue] = useState();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const paramsString = location.search.startsWith('?')
      ? location.search.substring(1)
      : location.search;
    const searchParams = new URLSearchParams(paramsString);
    setValue(searchParams.get(key));
  }, [location]);

  const setQueryValue = (newValue) => {
    const paramsString = location.search.startsWith('?')
      ? location.search.substring(1)
      : location.search;
    const searchParams = new URLSearchParams(paramsString);

    if (newValue) {
      searchParams.set(key, newValue);
    } else {
      searchParams.delete(key);
    }

    location.search = searchParams.toString();
    navigate(location);
  };

  return [value, setQueryValue];
}
