import { createSlice } from '@reduxjs/toolkit';
import {
  getSelectedResellerConfigsAsync,
  getSelectedResellerConfigsReducers,
} from './serviceActions/getSelectedResellerConfigsAsync';

const initialState = {
  getSelectedResellerConfigsState: {
    fetching: false,
    success: false,
    selectedResellerConfigdata: [],
    error: null,
    errorMessage: null,
    pagination: [],
    forbiddenState: false,
    forbiddenErrorMessage: {},
    getSelectedResellerConfigsCorsErrorMessage: null,
  },
};

const getSelectedResellerConfigsSlice = createSlice({
  name: 'GetSelectedResellerConfigs',
  initialState,
  reducers: {
    resetSelectedResellerConfigs(state) {
      state.getSelectedResellerConfigsState = initialState.getSelectedResellerConfigsState;
    },
  },
  extraReducers: {
    ...getSelectedResellerConfigsReducers,
  },
});

export { getSelectedResellerConfigsAsync };
export const { resetSelectedResellerConfigs } = getSelectedResellerConfigsSlice.actions;
export default getSelectedResellerConfigsSlice.reducer;
