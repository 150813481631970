import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import { Checkbox, ListItemText, ListItemIcon, TextField } from '@mui/material';
import { QboTypography } from '.';

export default function QboSortByButton(props) {
  const {
    buttonWidth,
    marginRightValue,
    startAdornmentText,
    startAdornmentTextWidth,
    variantType,
    value,
    handleChange,
    menuItemList,
    marginTopValue,
    isCheckBoxColumnFilter,
    selected,
    isAllSelected,
    handleCheckBoxChange,
    menuListCheckboxItems,
    isSearchEnable,
    handleOnSearchChange,
    searchState,
    handleCloseClick,
    forceUpdate,
    defaultValue,
    marginButtonValue,
  } = props;

  return (
    <FormControl
      sx={{
        m: 1,
        width: buttonWidth,
        margin: 0,
        marginRight: marginRightValue,
        marginTop: marginTopValue,
        marginBottom: marginButtonValue,
      }}>
      {!isCheckBoxColumnFilter && (
        <Select
          startAdornment={
            <QboTypography variant={variantType} sx={{ width: startAdornmentTextWidth }}>
              {startAdornmentText}
            </QboTypography>
          }
          value={value}
          onChange={handleChange}
          displayEmpty>
          {isSearchEnable && (
            <TextField
              key={forceUpdate}
              InputProps={{
                endAdornment: (
                  <CloseIcon style={{ cursor: 'pointer' }} onClick={handleCloseClick} />
                ),
              }}
              value={searchState}
              onChange={(event) => handleOnSearchChange(event)}
              style={{ marginLeft: 20, width: '80%' }}
              placeholder="Search"
              hiddenLabel
              id="filled-hidden-label-small"
              variant="filled"
              size="small"
              onKeyDown={(e) => {
                if (e.key !== 'Escape') {
                  e.stopPropagation();
                }
              }}
            />
          )}
          <MenuItem value="">
            <QboTypography variant="caption">{defaultValue}</QboTypography>
          </MenuItem>
          {menuItemList?.map((item) => (
            <MenuItem key={item?.id || item} value={item?.value || item}>
              <QboTypography sx={{ fontSize: 13 }}>{item?.title || item}</QboTypography>
            </MenuItem>
          ))}
        </Select>
      )}

      {isCheckBoxColumnFilter && (
        <Select
          MenuProps={{ autoFocus: false }}
          startAdornment={
            <QboTypography variant={variantType} sx={{ width: startAdornmentTextWidth }}>
              {startAdornmentText}
            </QboTypography>
          }
          labelId="mutiple-select-label"
          multiple
          value={selected}
          onChange={handleCheckBoxChange}
          renderValue={(select) => <QboTypography>{select.join(', ')}</QboTypography>}>
          {isSearchEnable && (
            <TextField
              key={forceUpdate}
              InputProps={{
                endAdornment: (
                  <CloseIcon style={{ cursor: 'pointer' }} onClick={handleCloseClick} />
                ),
              }}
              value={searchState}
              onChange={(event) => handleOnSearchChange(event)}
              style={{ marginLeft: 20, width: '80%' }}
              placeholder="Search"
              hiddenLabel
              id="filled-hidden-label-small"
              variant="filled"
              size="small"
              onKeyDown={(e) => {
                if (e.key !== 'Escape') {
                  e.stopPropagation();
                }
              }}
            />
          )}
          <MenuItem value="all">
            <ListItemIcon>
              <Checkbox
                checked={isAllSelected}
                indeterminate={
                  selected.length > 0 && selected.length < menuListCheckboxItems.length
                }
              />
            </ListItemIcon>
            <ListItemText primary="Select All" />
          </MenuItem>
          {menuListCheckboxItems.map((option) => (
            <MenuItem key={option} value={option}>
              <ListItemIcon>
                <Checkbox checked={selected.indexOf(option) > -1} />
              </ListItemIcon>
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
      )}
    </FormControl>
  );
}

QboSortByButton.propTypes = {
  buttonWidth: PropTypes.number,
  marginRightValue: PropTypes.number,
  marginTopValue: PropTypes.number,
  startAdornmentTextWidth: PropTypes.number,
  startAdornmentText: PropTypes.string,
  variantType: PropTypes.string,
  searchState: PropTypes.string,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  menuItemList: PropTypes.array,
  isCheckBoxColumnFilter: PropTypes.bool,
  isSearchEnable: PropTypes.bool,
  selected: PropTypes.array,
  isAllSelected: PropTypes.bool,
  forceUpdate: PropTypes.bool,
  handleCheckBoxChange: PropTypes.func,
  handleCloseClick: PropTypes.func,
  handleOnSearchChange: PropTypes.func,
  menuListCheckboxItems: PropTypes.array,
  marginButtonValue: PropTypes.number,
};

QboSortByButton.defaultProps = {
  buttonWidth: 0,
  marginRightValue: 0,
  marginTopValue: 0,
  startAdornmentTextWidth: 0,
  startAdornmentText: '',
  variantType: '',
  searchState: '',
  defaultValue: '',
  value: '',
  handleChange: () => {},
  menuItemList: [],
  isCheckBoxColumnFilter: false,
  forceUpdate: false,
  isSearchEnable: false,
  selected: [],
  isAllSelected: false,
  handleCheckBoxChange: () => {},
  handleOnSearchChange: () => {},
  handleCloseClick: () => {},
  menuListCheckboxItems: [],
  marginButtonValue: 0,
};
