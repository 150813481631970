import React, { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

export default function QboTabs({
  className,
  title,
  children,
  TabsParentWrapper,
  showCardWrapper,
  icon,
  iconPosition,
  tabIndex,
  onChange,
  sx,
  ...rest
}) {
  const arrayChildren = Children.toArray(children);

  return (
    <Box className={`QboTabs__default ${className}`}>
      <ConditionalWrapper
        {...rest}
        condition={TabsParentWrapper}
        wrapper={(child) => TabsParentWrapper(child)}>
        <Tabs style={{ backgroundColor: '#c4e3f0' }} value={tabIndex} onChange={onChange}>
          {title?.map((item, index) => {
            // eslint-disable-next-line react/no-array-index-key
            return <Tab key={item} icon={icon[index]} label={item} iconPosition={iconPosition} />;
          })}
        </Tabs>
      </ConditionalWrapper>

      <Box>
        {Children.map(arrayChildren, (child, index) => {
          if (tabIndex === index && title?.length > 0) {
            return (
              <>
                {cloneElement(child, {
                  index,
                })}
              </>
            );
          }
          return null;
        })}
      </Box>
    </Box>
  );
}

QboTabs.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.array,
  TabsParentWrapper: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
  showCardWrapper: PropTypes.bool,
  icon: PropTypes.arrayOf(PropTypes.node),
  iconPosition: PropTypes.string,
  tabIndex: PropTypes.number,
  onChange: PropTypes.func,
  sx: PropTypes.object,
};

QboTabs.defaultProps = {
  className: '',
  children: null,
  title: [],
  TabsParentWrapper: null,
  showCardWrapper: true,
  icon: [],
  iconPosition: 'start',
  tabIndex: 0,
  sx: {},
  onChange: () => {},
};
