import { useState } from 'react';
import * as yup from 'yup';
import { pascalToTitleCase } from '@utilities/stringUtils';

const organisationId = 'organisation-id';
const email = 'email';

const InitialForm = {
  [organisationId]: '',
  [email]: '',
};
const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

export default function useFormValidation() {
  const [isValid, setIsValid] = useState(false);
  const [errors, setErrors] = useState('');
  const [formData, setFormData] = useState(InitialForm);

  const formSchema = yup.object().shape({
    [organisationId]: yup.string().required('Organization Owner ID is required field'),
    [email]: yup.string().test((value) => (value ? emailRegex.test(value) : true)),
  });

  const validate = async (value) => {
    await formSchema.validate(value, { abortEarly: false }).catch(function (err) {
      const errorLogs = err.inner.reduce((acc, error) => {
        return {
          ...acc,
          [error.path]: pascalToTitleCase(error.message.split(',')[0]),
        };
      }, {});

      setErrors(errorLogs);
      return errorLogs;
    });

    const validation = await formSchema.isValid(value);
    if (validation) {
      setErrors({});
    }
    setIsValid(validation);
    return validation;
  };

  const validateAll = () => {
    validate(formData);
  };

  const updateFormData = (newData) => {
    setFormData((prev) => {
      return { ...prev, ...newData };
    });
  };

  const organisationIdAttributes = {
    handleChange: async (value) => {
      updateFormData({ [organisationId]: value });
    },
    errorMessage: errors[organisationId],
    value: formData[organisationId],
  };
  const emailAttributes = {
    handleChange: async (value) => {
      updateFormData({ [email]: value });
    },
    errorMessage: errors[email],
    value: formData[email],
  };

  const resetForm = () => {
    setFormData(InitialForm);
    setErrors({});
    setIsValid(false);
  };

  return {
    formData,
    organisationIdAttributes,
    emailAttributes,
    validateAll,
    isValid,
    resetForm,
    validate,
  };
}
