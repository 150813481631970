//
// create slice
// as centeralize between actions and reducers
//
// serviceActions module contains reducers and actions that
// created by createAsyncThunk from redux-toolkit
//
// for standard actions, that require other business logic
// can be put inside extraActions module
//
// export default will be reducers it self that processed by store
// other exports will contains actions that's dispatched from ReactComponent
//

import { createSlice } from '@reduxjs/toolkit';
import {
  transferSubscriptionValidateAsync,
  transferSubscriptionValidateExtraReducers,
} from './serviceActions/transferSubscriptionValidateAsync';
import {
  transferSubscriptionAsync,
  transferSubscriptionExtraReducers,
} from './serviceActions/transferSubscriptionAsync';

const initialState = {
  transferSubscription: {
    submitting: false,
    success: false,
    error: null,
    errorMessage: null,
  },
  transferSubscriptionValidate: {
    validateErrorMessage: null,
    statusCode: null,
    submitting: false,
    success: false,
    error: null,
    value: {},
  },
};

const transferSubscriptionSlice = createSlice({
  name: 'TransferSubscription',
  initialState,
  reducers: {
    resetStateStatus(state) {
      state.transferSubscription = initialState.transferSubscription;
      state.transferSubscriptionValidate.submitting =
        initialState.transferSubscriptionValidate.submitting;
      state.transferSubscriptionValidate.success =
        initialState.transferSubscriptionValidate.success;
      state.transferSubscriptionValidate.error = initialState.transferSubscriptionValidate.error;
    },
    resetStateValue(state) {
      state.transferSubscriptionValidate.value = initialState.transferSubscriptionValidate.value;
      state.transferSubscriptionValidate.validateErrorMessage =
        initialState.transferSubscriptionValidate.validateErrorMessage;
    },
  },
  extraReducers: {
    ...transferSubscriptionValidateExtraReducers,
    ...transferSubscriptionExtraReducers,
  },
});

export { transferSubscriptionValidateAsync, transferSubscriptionAsync };
export const { resetStateStatus, resetStateValue } = transferSubscriptionSlice.actions;
export default transferSubscriptionSlice.reducer;
