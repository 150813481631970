import { isEmpty } from 'lodash';
import useFormValidation from './useFormValidation';
import useTransferSubresellerNewService from './useTransferSubresellerNewService';

export default function useForm() {
  const { handleSubmitTransferPartnerAsync } = useTransferSubresellerNewService();

  const {
    supportTicketAttributes,
    escalationTicketAttributes,
    descriptionAttributes,
    transferTypeAttributes,
    resellerIDToBeTransferredAttributes,
    targetDistributorIDAttributes,
    sourceDistributorIDAttributes,
    effectiveTrasferDateAttributes,
    targetOwnerAttributes,
    targetChannelAttributes,
    newPriceArchiverAttributes,
    newPriceBackupAttributes,
    newPriceQboAttributes,
    newPriceNfpArchiverAttributes,
    newPriceNfpBackupAttributes,
    targetCurrencyAttributes,
    billingTypeAttributes,
    wireTransferAttributes,
    paypalTypeAttributes,
    stripeTypeAttributes,
    confirmingTheAboveInfoAttributes,
    formData,
    formDataConfirmation,
    validateAll,
    validateAllConfirmation,
    isValid,
    isValidConfirmation,
    resetForm,
    resetFormConfirmation,
  } = useFormValidation();

  const onSubmit = async (e) => {
    e.preventDefault();
    const flag = await validateAllConfirmation();

    if (flag && confirmingTheAboveInfoAttributes.value) {
      // resetForm();
      // resetFormConfirmation();
      const newFormData = {
        ...formData,
        newPriceArchiver: Number(formData.newPriceArchiver),
        newPriceBackup: Number(formData.newPriceBackup),
        newPriceQbo: Number(formData.newPriceQbo === '' ? 0 : +formData.newPriceQbo),
        newPriceNfpArchiver: Number(
          formData.newPriceNfpArchiver === '' ? 0 : +formData.newPriceNfpArchiver
        ),
        newPriceNfpBackup: Number(
          formData.newPriceNfpBackup === '' ? 0 : +formData.newPriceNfpBackup
        ),
        sourceDistributorID: isEmpty(formData?.sourceDistributorID)
          ? 0
          : formData?.sourceDistributorID,
        resellerIDToBeTransferred: isEmpty(formData?.resellerIDToBeTransferred)
          ? 0
          : formData?.resellerIDToBeTransferred,
        targetDistributorID: isEmpty(formData?.targetDistributorID)
          ? 0
          : formData?.targetDistributorID,
      };

      handleSubmitTransferPartnerAsync(newFormData);
    }
  };

  return {
    supportTicketAttributes,
    escalationTicketAttributes,
    descriptionAttributes,
    transferTypeAttributes,
    resellerIDToBeTransferredAttributes,
    targetDistributorIDAttributes,
    sourceDistributorIDAttributes,
    effectiveTrasferDateAttributes,
    targetOwnerAttributes,
    targetChannelAttributes,
    newPriceArchiverAttributes,
    newPriceBackupAttributes,
    newPriceQboAttributes,
    newPriceNfpArchiverAttributes,
    newPriceNfpBackupAttributes,
    targetCurrencyAttributes,
    billingTypeAttributes,
    wireTransferAttributes,
    paypalTypeAttributes,
    stripeTypeAttributes,
    confirmingTheAboveInfoAttributes,
    formData,
    formDataConfirmation,
    validateAll,
    validateAllConfirmation,
    isValid,
    isValidConfirmation,
    resetForm,
    resetFormConfirmation,
    onSubmit,
  };
}
