import { userDiscrepancyReportAPI } from '@services/WebApiService';
import { createAsyncThunk } from '@reduxjs/toolkit';

const userDiscrepancyReportAsync = createAsyncThunk(
  'UserDiscrepancyReport/userDiscrepancyReport',
  async (paramsList) => {
    try {
      const data = await userDiscrepancyReportAPI(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = userDiscrepancyReportAsync;

const userDiscrepancyReportExtraReducers = {
  [pending]: (state) => {
    state.userDiscrepancyReportState.fetching = true;
  },
  [rejected]: (state) => {
    state.userDiscrepancyReportState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.userDiscrepancyReportState.fetching = false;

    if (action?.payload && !action?.payload?.error) {
      state.userDiscrepancyReportState.status = action?.payload?.status;
      state.userDiscrepancyReportState.message = action?.payload?.data?.message;
    }
    if (action?.payload?.status === 403) {
      state.userDiscrepancyReportState.message = 'You are not authorize to access this resource';
    }

    if (!action?.payload) {
      state.userDiscrepancyReportState.message =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.error) {
      const statusMessage = action?.payload?.error?.data;
      state.userDiscrepancyReportState.status = action?.payload?.error?.status;
      state.userDiscrepancyReportState.message = statusMessage?.title || statusMessage?.message;
      state.userDiscrepancyReportState.error = action.payload.error;
    } else {
      state.userDiscrepancyReportState.success = true;
      state.userDiscrepancyReportState.serviceType = action.payload.data.data.serviceTypeCode;
    }
  },
};

export { userDiscrepancyReportAsync, userDiscrepancyReportExtraReducers };
