import { createSlice } from '@reduxjs/toolkit';
import {
  exportMonthlyBackupReportAsync,
  exportMonthlyBackupReportReducers,
} from './serviceActions/exportMonthlyBackupReportAsync';

const initialState = {
  exportMonthlyBackupReportState: {
    fetching: false,
    monthlyBackupExportData: null,
    errorMessage: null,
    pagination: [],
    statusCode: null,
    forbiddenState: false,
    forbiddenErrorMessage: {},
    corsErrorMessage: null,
  },
};

const exportMonthlyBackupReportSlice = createSlice({
  name: 'ExportMonthlyBackupReport',
  initialState,
  reducers: {
    resetExportMonthlyBackupReportStatus(state) {
      state.exportMonthlyBackupReportState = initialState.exportMonthlyBackupReportState;
    },
  },
  extraReducers: {
    ...exportMonthlyBackupReportReducers,
  },
});

export { exportMonthlyBackupReportAsync };
export const { resetExportMonthlyBackupReportStatus } = exportMonthlyBackupReportSlice.actions;
export default exportMonthlyBackupReportSlice.reducer;
