import { createSlice } from '@reduxjs/toolkit';

import { runXeroSyncAsync, runXeroSyncExtraReducers } from './serviceActions/runXeroSyncAsync';

const initialState = {
  runXeroSyncState: {
    fetching: false,
    status: null,
    error: null,
    message: null,
    errorMessage: null,
    forbiddenState: false,
    forbiddenErrorMessage: null,
    runXeroSyncCorsErrorMessage: null,
    serviceType: null,
  },
};

const runXeroSyncSlice = createSlice({
  name: 'runXeroSync',
  initialState,
  reducers: {
    resetRunXeroSyncState(state) {
      state.runXeroSyncState = initialState.runXeroSyncState;
    },
  },
  extraReducers: {
    ...runXeroSyncExtraReducers,
  },
});

export { runXeroSyncAsync };

export const { resetRunXeroSyncState } = runXeroSyncSlice.actions;
export default runXeroSyncSlice.reducer;
