export default function useDataService() {
  const hexTenantInfoDetails = [
    {
      label: 'ID',
      properties: 'id',
      valType: 'int',
    },
    {
      label: 'GUID',
      properties: 'guid',
      valType: 'int',
    },
    {
      label: 'Partner ID',
      properties: 'partnerId',
      valType: 'int',
    },
    { label: 'Partner Name', properties: 'partnerName', valType: 'string' },
    {
      label: 'Email',
      properties: 'encryptedEmail',
      valType: 'string',
    },
    {
      label: 'Parent Name',
      properties: 'parent',
      valType: 'string',
    },
    {
      label: 'Organization Owner ID',
      properties: 'organizationOwnerId',
      valType: 'string',
    },
    {
      label: 'Connection Settings',
      properties: 'connectionSettings',
      valType: 'string',
    },
    {
      label: 'Powershell Username',
      properties: 'encryptedPowershellUsername',
      valType: 'string',
    },
    {
      label: 'Powershell Connection URI',
      properties: 'powershellConnectionUri',
      valType: 'string',
    },
    {
      label: 'Authentication Mecahism',
      properties: 'authenticationMechanism',
      valType: 'string',
    },
    {
      label: 'SSL',
      properties: 'enableSsl',
      valType: 'string',
    },
    {
      label: 'Created At',
      properties: 'createdAt',
      valType: 'string',
    },
    {
      label: 'Last Updated At',
      properties: 'updatedAt',
      valType: 'string',
    },
    {
      label: 'Exchange ORG ID',
      properties: 'exchangeOrgId',
      valType: 'string',
    },
    {
      label: 'Dist Group Name',
      properties: 'distGroupName',
      valType: 'string',
    },
    {
      label: 'Journal Rule Name',
      properties: 'journalRuleName',
      valType: 'string',
    },
    {
      label: 'Initial Setup Status',
      properties: 'status',
      valType: 'int',
    },
  ];
  return {
    hexTenantInfoDetails,
  };
}
