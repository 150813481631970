import WebApiRoutes from '@config/Routes/WebApiRoutes';
import ServiceClientFactory from '@libs/ServiceClient';

const webClientApi = (accessToken, reset) => {
  return new ServiceClientFactory({
    baseUrl: WebApiRoutes.getBaseUrl(),
    accessToken,
    reset,
  }).create();
};

const PartnerService = {
  getPartnerDetailAPI: (paramsList) => {
    const { accessToken, reset, id } = paramsList;

    return webClientApi(accessToken, reset).getRequest({
      url: WebApiRoutes.getPartnerDetailPath({ id }),
    });
  },
};

export default PartnerService;
