import { useDispatch } from 'react-redux';
import mailBoxSyncAsync from '@features/mailBox/serviceActions/mailBoxSyncAsync';

export default function useMailBoxSync() {
  const dispatch = useDispatch();

  const newSyncRequest = async (paramsList) => {
    await dispatch(mailBoxSyncAsync(paramsList));
  };

  const getMailBoxSyncRequestData = (data, setMailboxData) => {
    const modifiedData = data?.map((item) => {
      const payloadObj = JSON.parse(item.payload);
      const modifiedPayloadObj = {
        ...payloadObj,
        'executed-on': item['last-updated-on'],
        'status-description': item['status-description'],
      };
      return modifiedPayloadObj;
    });
    return setMailboxData(modifiedData);
  };

  return { newSyncRequest, getMailBoxSyncRequestData };
}
