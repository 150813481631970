import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputBase from '@mui/material/InputBase';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import ErrorIcon from '@mui/icons-material/Error';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

export default function QboTextField({
  id,
  label,
  required,
  name,
  disabled,
  errorMessage,
  placeholder,
  startIcon,
  endIcon,
  helperText,
  fullWidth,
  explicitStyleClass,
  withoutErrorIcon,
  isPasswordField,
  isEyeButton,
  isWidthSetExplicit,
  ...rest
}) {
  const inputAttributes = {};
  let error = false;

  const [isPasswordVisible, setPasswordVisible] = useState(false);

  if (name) inputAttributes.name = name;
  if (placeholder) inputAttributes.placeholder = placeholder;
  if (errorMessage) error = true;

  if (startIcon) {
    inputAttributes.startAdornment = <InputAdornment position="start">{startIcon}</InputAdornment>;
  }

  if (endIcon) {
    inputAttributes.endAdornment = <InputAdornment position="end">{endIcon}</InputAdornment>;
  }

  if (isPasswordField) {
    if (isPasswordVisible) {
      inputAttributes.type = 'text';
      inputAttributes.endAdornment = (
        <InputAdornment
          position="end"
          className="QboTextField__password-visible-icon"
          onClick={() => {
            setPasswordVisible(false);
          }}>
          {isEyeButton && <VisibilityOffOutlinedIcon />}
        </InputAdornment>
      );
    } else {
      inputAttributes.type = 'password';
      inputAttributes.endAdornment = (
        <InputAdornment
          position="end"
          className="QboTextField__password-visible-icon"
          onClick={() => {
            setPasswordVisible(true);
          }}>
          {isEyeButton && <RemoveRedEyeOutlinedIcon />}
        </InputAdornment>
      );
    }
  }

  if (rest?.endAdornment) {
    inputAttributes.endAdornment = rest?.endAdornment;
  }

  return (
    <FormControl
      className={`FormControl__default-wrapper ${
        fullWidth ? 'FormControl__full-width-wrapper' : ''
      } ${isWidthSetExplicit ? 'FormControl__width-wrapper' : ''} ${explicitStyleClass}`}
      disabled={disabled}
      error={error}>
      {label && (
        <label className="QboTextField__label" htmlFor={`#${id}`}>
          {label}
          {required && <span style={{ color: 'red' }}> *</span>}
        </label>
      )}

      <InputBase id={id} {...inputAttributes} {...rest} />

      {helperText && !errorMessage && (
        <span style={{ marginTop: '5px' }} className="QboTextField__helper-text">
          {helperText}
        </span>
      )}

      {errorMessage && (
        <span className="QboTextField__error-text">
          {!withoutErrorIcon && <ErrorIcon />}
          {` ${errorMessage}`}
        </span>
      )}
    </FormControl>
  );
}

QboTextField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  helperText: PropTypes.string,
  explicitStyleClass: PropTypes.string,
  fullWidth: PropTypes.bool,
  isWidthSetExplicit: PropTypes.bool,
  withoutErrorIcon: PropTypes.bool,
  isPasswordField: PropTypes.bool,
  isEyeButton: PropTypes.bool,
  required: PropTypes.bool,
};

QboTextField.defaultProps = {
  id: '',
  name: '',
  explicitStyleClass: '',
  label: null,
  disabled: false,
  errorMessage: null,
  placeholder: null,
  startIcon: null,
  endIcon: null,
  helperText: null,
  fullWidth: false,
  isWidthSetExplicit: false,
  withoutErrorIcon: false,
  isPasswordField: false,
  isEyeButton: true,
  required: false,
};
