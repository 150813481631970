import { constant } from '@config/BaseSetting';
import {
  exportOrganizationsAsync,
  resetExportOrganizationsState,
} from '@features/organizationList/exportOrganizationsSlice';
import { getOrganizationsListAsync } from '@features/organizationList/organizationsListSlice';
import useLocalStorage from '@hooks/useLocalStorage';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export default function useOrganizationListService(
  setAdvanceClick,
  advanceClick,
  setSelectedColumn,
  searchIDFieldValue,
  fullTextSearchValue,
  resellerID,
  pagination,
  adFilter,
  selectedColumn
) {
  const dispatch = useDispatch();
  const [accessToken] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const [fetchParams, setFetchParams] = useState();
  const emptyFilterState = [
    { field: 'filter_status', value: '' },
    { field: 'filter_autoLicense', value: '' },
    { field: 'filter_license', value: '' },
    { field: 'filter_partner', value: '' },
    { field: 'filter_plan', value: '' },
    { field: 'filter_country', value: '' },
    { field: 'filter_storageLocation', value: '' },
  ];

  const handleFetchOrganizationsListAsync = (flag, paginationFlag) => {
    const params = {
      searchById: flag ? 0 : searchIDFieldValue || 0,
      fullTextSearch: flag ? '' : fullTextSearchValue,
      pageNumber: paginationFlag ? 1 : pagination.pageIndex + 1,
      pageSize: paginationFlag ? 10 : pagination.pageSize,
      reseller: flag ? '' : resellerID,
      filter: flag ? emptyFilterState : adFilter,
      orderBy: flag ? 'OrganizationId' : selectedColumn || 'OrganizationId',
    };
    dispatch(
      getOrganizationsListAsync({
        accessToken,
        reset: true,
        ...params,
      })
    );
    setFetchParams(params);
  };

  const { getOrganizationsListState } = useSelector((state) => state.organizationsList);
  const { fetching } = getOrganizationsListState;

  const handleOnAdvanceSearchClick = () => {
    setAdvanceClick(!advanceClick);
  };

  const handleOnSearchClick = (e) => {
    e.preventDefault();
    if (!fetching) {
      handleFetchOrganizationsListAsync({});
    }
  };

  const handleOnSortByChange = (event) => {
    setSelectedColumn(String(event.target.value));
  };

  const matchingText = (setMatchText) => {
    let textData = 'Matching ';

    if (searchIDFieldValue || fullTextSearchValue) {
      if (searchIDFieldValue && fullTextSearchValue) {
        textData += ` id = '${searchIDFieldValue}' and email/name like '%${fullTextSearchValue}%', `;
      } else if (searchIDFieldValue) {
        textData += ` id = ${searchIDFieldValue},`;
      } else if (fullTextSearchValue) {
        textData += ` email/name like '%${fullTextSearchValue}%', `;
      }
    }
    adFilter.forEach((filter, index) => {
      if (filter.value) {
        if (filter.field === 'filter_partner') {
          textData += 'Partner Type = ';
        } else if (filter.field === 'filter_status') {
          textData += 'Status = ';
        } else if (filter.field === 'filter_plan') {
          textData += 'Plan = ';
        } else if (filter.field === 'filter_autoLicense') {
          textData += 'Auto License = ';
        } else if (filter.field === 'filter_license') {
          textData += 'License = ';
        } else if (filter.field === 'filter_country') {
          textData += 'Country = ';
        } else if (filter.field === 'filter_storageLocation') {
          textData += 'Storage Location = ';
        }

        textData += filter.value;
        textData += ', ';
      }
    });

    textData = textData.replace(/,\s*$/, '');

    if (textData === 'Matching ') return '';

    setMatchText(textData);

    return textData;
  };

  const handleExport = () => {
    dispatch(
      exportOrganizationsAsync({
        accessToken,
        reset: true,
        ...fetchParams,
      })
    );
  };

  const handleResetExport = () => {
    dispatch(resetExportOrganizationsState());
  };

  return {
    fetchParams,
    handleOnAdvanceSearchClick,
    handleOnSearchClick,
    handleOnSortByChange,
    handleFetchOrganizationsListAsync,
    handleExport,
    handleResetExport,
    matchingText,
  };
}
