import React from 'react';
import PropTypes from 'prop-types';
import { QboButton, QboCard, QboAlert, QboTypography, QboNoStatus } from '@ui/Components';
import QboTitleWithCollapsableButton from '@ui/Components/QboTitleWithCollapsableButton';
import QboCardSingleList from '@ui/Components/QboCardSingleList';
import { Skeleton } from '@mui/material';
import useManageLog from './pageHooks/useManageLog';

export default function ManageLogPage({ id, type, orgOwnerId }) {
  const { partnerDetailState, openSearchAttributes, description, hasOpenSearchLog, onOpenNewUrl } =
    useManageLog({ logType: type, id, orgOwnerId });
  const { fetching } = partnerDetailState;

  if (fetching) {
    return (
      <div style={{ display: 'contents' }}>
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="70%" />
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="70%" />
      </div>
    );
  }

  return hasOpenSearchLog() ? (
    <>
      <QboAlert variant="outlined" type="info">
        <QboTypography noWrap={false}>
          Accessing the Open Search Dashboard requires an active VPN connection. Please ensure that
          your VPN is set up and connected before attempting to utilize the dashboard.
        </QboTypography>
      </QboAlert>

      <QboCard
        className="QboCard__wrapper_search_text_highlight"
        sx={{
          scrollbarWidth: 'none !important',
          overflowY: 'scroll !important',
          height: '65vh',
        }}>
        <QboCard sx={{ marginBottom: 2 }}>
          <QboTitleWithCollapsableButton
            collapseVisibility="hidden"
            isCollapseButtonEnabled={false}
            title="Open Search Dashboard"
            variant="h5"
            flagType="notificationSettings"
            open={false}
            isEditEnable={false}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          />

          {Object.keys(openSearchAttributes()).map((key) => {
            if (openSearchAttributes()[key]) {
              return (
                <QboCardSingleList
                  key={key}
                  leftContent={
                    <QboTypography fontWeight="bold">{`${key} log`.toUpperCase()}</QboTypography>
                  }
                  subHeadingText={description(key)}
                  rightContent={
                    <QboButton
                      variant="contained"
                      onClick={() => {
                        onOpenNewUrl(openSearchAttributes()[key]);
                      }}>
                      Go to The Dashboard
                    </QboButton>
                  }
                />
              );
            }

            return null;
          })}
        </QboCard>
      </QboCard>
    </>
  ) : (
    <QboNoStatus title="The log is not available yet in selected region." />
  );
}

ManageLogPage.propTypes = {
  id: PropTypes.number,
  type: PropTypes.string,
  orgOwnerId: PropTypes.number,
};

ManageLogPage.defaultProps = {
  id: null,
  type: null,
  orgOwnerId: null,
};
