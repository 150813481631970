import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { QboAlert, QboCard, QboModal } from '@ui/Components';
import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import TransferSubresellerSection from '@ui/Sections/TransferSubresellerPage/TransferSubresellerSection';
import TransferSubresellerFormSection from '@ui/Sections/TransferSubresellerPage/TransferSubresellerFormSection';
import SystemStatusSection from '@ui/Sections/SystemStatusPage/SystemStatusSection';
import AlertPageSection from '@ui/Sections/Shared/AlertPageSection';
import StepperBlock from '@ui/Blocks/Shared/StepperBlock';
import SettingsIcon from '@mui/icons-material/Settings';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ConfirmationBlock from '@ui/Blocks/Shared/ConfirmationBlock';
import TransferSubresellerFormPage from '@ui/Sections/TransferSubresellerPage/TransferSubresellerFormPage';
import useTransferSubreseller from './pageHooks/useTransferSubreseller';

const steps = ['1', '2', '3'];

const icons = {
  1: <SettingsIcon />,
  2: <VideoLabelIcon />,
  3: <CheckCircleOutlineOutlinedIcon />,
};

export default function TransferSubresellerPage() {
  const { t } = useTranslation();
  const { systemStatusCorsErrorMessage } = useSelector((state) => state.SystemStatus);
  const { getTransferSubresellerState } = useSelector((state) => state.getTransferSubresellerState);
  const { transferSubresellerCorsErrorMessage } = getTransferSubresellerState;

  const {
    supportTicketAttributes,
    escalationTicketAttributes,
    descriptionAttributes,
    sourceSubresellerIdAttributes,
    targetResellerIdAttributes,
    onSubmit,
    onBack,
    activeStep,
    isStepOptional,
    isStepSkipped,
    transferSubresellerData,
    onTransfer,
    loading,
    showModal,
    onOpenModal,
    onCloseModal,
    onOpenConfirmation,
    collection,
    isLoadingSystemStatus,
  } = useTransferSubreseller();

  return (
    <>
      <HeaderPageBlock>{t('transfer_subreseller_page.header.text')}</HeaderPageBlock>
      {transferSubresellerCorsErrorMessage && (
        <QboAlert type="error" style={{ fontSize: '1.15em' }}>
          {transferSubresellerCorsErrorMessage}
        </QboAlert>
      )}
      {/* <AlertPageSection /> */}
      <QboCard noPadding isMainWrapper>
        <QboModal
          className="TransferSubscriptionPage_default-modal"
          onClose={onCloseModal}
          open={showModal}
          title={t('transfer_subreseller_page.modal.confirmation.title_text')}
          showCloseIcon
          preventOutsideClick>
          <AlertPageSection />
          <StepperBlock
            steps={steps}
            icons={icons}
            hideLabel
            activeStepAlt={activeStep}
            isStepOptionalAlt={isStepOptional}
            isStepSkippedAlt={isStepSkipped}>
            <TransferSubresellerFormSection
              supportTicketAttributes={supportTicketAttributes}
              escalationTicketAttributes={escalationTicketAttributes}
              descriptionAttributes={descriptionAttributes}
              sourceSubresellerIdAttributes={sourceSubresellerIdAttributes}
              targetResellerIdAttributes={targetResellerIdAttributes}
              onSubmit={onSubmit}
            />
            <TransferSubresellerSection
              transferSubresellerData={transferSubresellerData}
              onBack={onBack}
              onTransfer={onOpenConfirmation}
              loading={loading}
            />
            <ConfirmationBlock onBack={onBack} onNext={onTransfer} loading={loading}>
              {t('transfer_subreseller_page.modal.confirmation.content_text')}
            </ConfirmationBlock>
          </StepperBlock>
        </QboModal>
        <TransferSubresellerFormPage onCreateTicket={onOpenModal} />
        {/* <SystemStatusSection
          collection={collection}
          onCreateTicket={onOpenModal}
          loading={isLoadingSystemStatus}
        /> */}
      </QboCard>
    </>
  );
}
