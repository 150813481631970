import { createSlice } from '@reduxjs/toolkit';

import {
  checkXeroSyncRunningAsync,
  checkXeroSyncRunningExtraReducers,
} from './serviceActions/checkXeroSyncRunningAsync';

const initialState = {
  checkXeroSyncRunningState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    isRunning: null,
    forbiddenState: false,
    forbiddenErrorMessage: null,
    corsErrorMessage: null,
  },
};

const checkXeroSyncRunningSlice = createSlice({
  name: 'CheckXeroSyncRunning',
  initialState,
  reducers: {
    resetCheckXeroSyncRunningState(state) {
      state.checkXeroSyncRunningState = initialState.checkXeroSyncRunningState;
    },
    resetCheckXeroSyncRunningStatusCodeState(state) {
      state.checkXeroSyncRunningState.statusCode =
        initialState.checkXeroSyncRunningState.statusCode;
    },
  },
  extraReducers: {
    ...checkXeroSyncRunningExtraReducers,
  },
});

export { checkXeroSyncRunningAsync };
export const { resetCheckXeroSyncRunningState, resetCheckXeroSyncRunningStatusCodeState } =
  checkXeroSyncRunningSlice.actions;
export default checkXeroSyncRunningSlice.reducer;
