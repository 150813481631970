import { createSlice } from '@reduxjs/toolkit';
import {
  removeDataIngestionJobAsync,
  getRemoveDataIngestionJobsExtraReducers,
} from './serviceActions/removeDataIngestionJobAsync';

const initialState = {
  getRemoveDataIngestionJobsState: {
    submitting: false,
    success: false,
    error: null,
    removeDataIngestionJobsResult: null,
    statusCode: null,
    errorMessage: null,
  },
};

const removeIngestionJobSlice = createSlice({
  name: 'removeIngestionJob',
  initialState,
  reducers: {
    resetRemoveIngestionJobState(state) {
      state.getRemoveDataIngestionJobsState = initialState.getRemoveDataIngestionJobsState;
    },
  },
  extraReducers: {
    ...getRemoveDataIngestionJobsExtraReducers,
  },
});

export { removeDataIngestionJobAsync };
export const { resetRemoveIngestionJobState } = removeIngestionJobSlice.actions;
export default removeIngestionJobSlice.reducer;
