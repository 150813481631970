import { createSlice } from '@reduxjs/toolkit';

import {
  getPartnersFilterListAsync,
  getPartnersFilterListExtraReducers,
} from './serviceActions/getPartnersFilterListAsync';

const initialState = {
  getPartnersFilterListState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    paginationList: null,
    partnersFilterList: [],
    forbiddenState: false,
    forbiddenErrorMessage: null,
    partnersFilterListCorsErrorMessage: null,
  },
};

const partnersFilterListSlice = createSlice({
  name: 'PartnersFilterList',
  initialState,
  reducers: {
    resetPartnersFilterListState(state) {
      state.getPartnersFilterListState = initialState.getPartnersFilterListState;
    },
    resetPartnersFilterListStatusCodeState(state) {
      state.getPartnersFilterListState.statusCode =
        initialState.getPartnersFilterListState.statusCode;
    },
  },
  extraReducers: {
    ...getPartnersFilterListExtraReducers,
  },
});

export { getPartnersFilterListAsync };
export const { resetPartnersFilterListState, resetPartnersFilterListStatusCodeState } =
  partnersFilterListSlice.actions;
export default partnersFilterListSlice.reducer;
