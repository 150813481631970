import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { MsalProvider } from '@azure/msal-react';
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import ServiceClientInterceptors from '@config/ServiceClientInterceptors';
import store from '@store';
import DefaultApplication from '@layouts/DefaultApplication';
import BaseSetting, { pubClientApplication } from '@config/BaseSetting';
import I18n from '@config/I18n';
import reportWebVitals from './reportWebVitals';
import './index.css';

const pca = new PublicClientApplication(pubClientApplication);

pca.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    pca.setActiveAccount(event.payload.account);
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));

const qboI18n = new I18n(BaseSetting.defaultLanguage, BaseSetting.resourcesLocales);
qboI18n.setupLocales();

root.render(
  <MsalProvider instance={pca}>
    <Provider store={store}>
      <BrowserRouter>
        <ServiceClientInterceptors>
          <DefaultApplication />
        </ServiceClientInterceptors>
      </BrowserRouter>
    </Provider>
  </MsalProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
