import React from 'react';
import { QboModal, ITBox, QboFormGroup, QboTextField } from '@ui/Components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

export default function RequestModalTool({
  children,
  closeSetupModal,
  showRequestModal,
  topBarElement,
  title,
  form,
  onValidateData,
  submitState,
  buttonDisabled,
}) {
  const { t } = useTranslation();
  const isAstericRequired = true;

  const closeModal = () => {
    closeSetupModal();
    form.resetForm();
  };

  if (Object.keys(form).length === 0) return null;

  return (
    <QboModal
      onClose={closeModal}
      open={showRequestModal}
      title={title}
      showCloseIcon
      preventOutsideClick>
      <ITBox className="TransferSubscriptionFormPage__wrapper" direction="column">
        <QboFormGroup
          buttonDisabled={buttonDisabled}
          loadingButton={submitState.fetching}
          onSubmit={onValidateData}
          withGenerateAndResetButton
          handleOnResetButton={closeModal}
          secondButtonLabel="Cancel"
          firstButtonLabel="Submit">
          {topBarElement}
          {children}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <QboTextField
                id="supportTicket"
                required={isAstericRequired}
                placeholder="Support Ticket"
                label="Support Ticket"
                fullWidth
                value={form.attributes.supportTicketAttributes.value}
                errorMessage={form.attributes.supportTicketAttributes.errorMessage}
                inputProps={{ maxLength: 225 }}
                onChange={(e) => {
                  form.attributes.supportTicketAttributes.handleChange(e.currentTarget.value);
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <QboTextField
                id="escalationTicket"
                placeholder="Escalation Ticket"
                label="Escalation Ticket"
                fullWidth
                value={form.attributes.escalationTicketAttributes.value}
                onChange={(e) =>
                  form.attributes.escalationTicketAttributes.handleChange(e.currentTarget.value)
                }
                inputProps={{ maxLength: 225 }}
              />
            </Grid>
          </Grid>

          <QboTextField
            id="description"
            placeholder="Description"
            label="Description"
            fullWidth
            value={form.attributes.descriptionAttributes.value}
            onChange={(e) =>
              form.attributes.descriptionAttributes.handleChange(e.currentTarget.value)
            }
            inputProps={{ maxLength: 225 }}
          />
        </QboFormGroup>
      </ITBox>
    </QboModal>
  );
}

RequestModalTool.propTypes = {
  closeSetupModal: PropTypes.func,
  showRequestModal: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  topBarElement: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  form: PropTypes.object,
  onValidateData: PropTypes.func,
  submitState: PropTypes.object,
  buttonDisabled: PropTypes.bool,
};

RequestModalTool.defaultProps = {
  closeSetupModal: () => {},
  showRequestModal: false,
  title: '',
  children: '',
  topBarElement: '',
  form: {},
  onValidateData: () => {},
  submitState: {},
  buttonDisabled: false,
};
