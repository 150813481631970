import { createAsyncThunk } from '@reduxjs/toolkit';
import { validateTransferPartnerAPI } from '@services/WebApiService';

const validateTransferPartnerAsync = createAsyncThunk(
  'TransferPartner/validateTransferPartnerForm',
  async (paramsList) => {
    try {
      const data = await validateTransferPartnerAPI(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = validateTransferPartnerAsync;

const validateTransferPartnerFormExtraReducers = {
  [pending]: (state) => {
    state.validateTransferPartnerForm.submitting = true;
  },
  [rejected]: (state) => {
    state.validateTransferPartnerForm.submitting = false;
  },
  [fulfilled]: (state, action) => {
    state.validateTransferPartnerForm.submitting = false;

    if (action?.payload?.status) {
      state.validateTransferPartnerForm.statusCode = action?.payload?.status;
    }

    if (action?.payload?.error) {
      state.validateTransferPartnerForm.errorMessage = action?.payload?.error?.data?.message;
    }

    if (action?.payload?.status === 403) {
      state.validateTransferPartnerForm.errorMessage =
        'You are not authorize to access this resource';
    }

    if (action?.payload?.error?.status === 404) {
      state.validateTransferPartnerForm.errorMessage = 'Invalid Endpoint';
    }

    if (!action?.payload && !action?.payload?.error) {
      state.validateTransferPartnerForm.errorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.data) {
      state.validateTransferPartnerForm.validatedData = action.payload.data;
      state.validateTransferPartnerForm.success = true;
    }
  },
};

export { validateTransferPartnerAsync, validateTransferPartnerFormExtraReducers };
