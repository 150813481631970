import { createSlice } from '@reduxjs/toolkit';
import {
  submitMailboxBulkPurgeFormAsync,
  submitMailboxBulkPurgeFormExtraReducers,
} from './serviceActions/submitBulkPureRequestAsync';

const initialState = {
  submitBulkPurgeForm: {
    submitting: false,
    success: false,
    error: null,
    statusCode: null,
    message: null,
  },
};

const mailboxBulkPurgeSlice = createSlice({
  name: 'BulkPurgeRequest',
  initialState,
  reducers: {
    resetBulkPurgeFormSubmit(state) {
      state.submitBulkPurgeForm = initialState.submitBulkPurgeForm;
    },
  },
  extraReducers: {
    ...submitMailboxBulkPurgeFormExtraReducers,
  },
});

export { submitMailboxBulkPurgeFormAsync };
export const { resetBulkPurgeFormSubmit } = mailboxBulkPurgeSlice.actions;
export default mailboxBulkPurgeSlice.reducer;
