import { createAsyncThunk } from '@reduxjs/toolkit';
import { getToolRequestDetailsApi } from '@services/WebApiService';

const toolsRequestDetailsAsync = createAsyncThunk(
  'ToolsRequestHistory/toolsRequestDetails',
  async (paramsList) => {
    try {
      const data = await getToolRequestDetailsApi(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = toolsRequestDetailsAsync;

const toolsRequestDetailsReducers = {
  [pending]: (state) => {
    state.toolsRequestDetailsState.fetching = true;
  },
  [rejected]: (state) => {
    state.toolsRequestDetailsState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.toolsRequestDetailsState.fetching = false;

    if (action?.payload?.error) {
      state.toolsRequestDetailsState.errorMessage = action.payload.error?.data.message;
      state.toolsRequestDetailsState.statusCode = action.payload.error?.status;

      if (action?.payload?.error?.status === 403) {
        state.toolsRequestDetailsState.forbiddenState = true;
        state.toolsRequestDetailsState.forbiddenErrorMessage = {
          heading: 'Forbidden Error',
          caption: 'You are not authorize to access this resource',
        };
      }
    }

    if (!action?.payload?.error && !action?.payload?.data) {
      state.toolsRequestDetailsState.errorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.data?.data) {
      state.toolsRequestDetailsState.forbiddenState = false;
      state.toolsRequestDetailsState.forbiddenErrorMessage = {};
      state.toolsRequestDetailsState.statusCode = action.payload?.status;
      state.toolsRequestDetailsState.data = action?.payload?.data?.data;
    }
  },
};

export { toolsRequestDetailsAsync, toolsRequestDetailsReducers };
