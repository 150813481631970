import { useDispatch } from 'react-redux';
import { constant } from '@config/BaseSetting';
import useLocalStorage from '@hooks/useLocalStorage';
import { getTransferSubscriptionAsync } from '@features/transferSubscriptionService/getTransferSubscriptionSlice';

export default function useTransferSubscriptionService() {
  const dispatch = useDispatch();
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);

  const handleTransferSubscriptionAsync = (pageIndex, pageSize) => {
    dispatch(
      getTransferSubscriptionAsync({
        accessToken: accessTokenSet,
        reset: true,
        pageNumber: pageIndex,
        pageSize,
      })
    );
  };

  const processDataTransferSubscription = (data, setTransferData) => {
    const modifiedData = data?.map((item) => {
      const payloadObj = JSON.parse(item.payload);
      const modifiedPayloadObj = {
        id: item?.id,
        'status-description': item['status-description'],
        status: item.status,
        description: payloadObj.description,
        'reseller-request-id': payloadObj['change-reseller-request-id'],
        'support-ticket': payloadObj['support-ticket'],
        'escalation-ticket': payloadObj['escalation-ticket'],
        'executed-on': item['executed-on'],
      };
      return modifiedPayloadObj;
    });
    return setTransferData(modifiedData);
  };

  return { handleTransferSubscriptionAsync, processDataTransferSubscription };
}
