import { t } from 'i18next';

const useAlertMessage = () => {
  const konstMessage = {
    // replicate alert
    SUCCESS_REPLICATE: 'SUCCESS_REPLICATE',
    ERROR_REPLICATE: 'ERROR_REPLICATE',

    // transfer subscription alert
    SUCCESS_TRANSFER_SUBSCRIPTION: 'SUCCESS_TRANSFER_SUBSCRIPTION',
    ERROR_TRANSFER_SUBSCRIPTION: 'ERROR_TRANSFER_SUBSCRIPTION',

    // transfer subreseller alert
    SUCCESS_TRANSFER_SUBRESELLER: 'SUCCESS_TRANSFER_SUBRESELLER',
    ERROR_TRANSFER_SUBRESELLER: 'ERROR_TRANSFER_SUBRESELLER',
  };

  const success = 'success';
  const error = 'error';

  const getSuccessMessage = (konst, payload, fallbackMessage) => {
    const defaultPayload = { type: success };

    switch (konst) {
      case konstMessage.SUCCESS_TRANSFER_SUBSCRIPTION:
        return {
          ...defaultPayload,
          message: t('transfer_subscription_page.alert.success', payload),
        };
      case konstMessage.SUCCESS_TRANSFER_SUBRESELLER:
        return {
          ...defaultPayload,
          message: t('transfer_subreseller_page.alert.success', payload),
        };
      default:
        return {
          ...defaultPayload,
          message: fallbackMessage,
          heading: `${konst}: ${t('alert.success_heading')}`,
        };
    }
  };

  const getErrorMessage = (konst, payload, fallbackMessage) => {
    const defaultPayload = { type: error };

    switch (konst) {
      case konstMessage.ERROR_TRANSFER_SUBSCRIPTION:
        return {
          ...defaultPayload,
          message: t('transfer_subscription_page.alert.error', payload),
        };
      case konstMessage.ERROR_TRANSFER_SUBRESELLER:
        return {
          ...defaultPayload,
          message: t('transfer_subreseller_page.alert.error', payload),
        };
      default:
        return {
          ...defaultPayload,
          message: fallbackMessage,
          heading: `${konst}: ${t('alert.error_heading')}`,
        };
    }
  };

  return { getSuccessMessage, getErrorMessage, konstMessage };
};

export default useAlertMessage;
