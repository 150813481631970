import { createSlice } from '@reduxjs/toolkit';

import {
  getIngestionsQueuedAsync,
  getIngestionsQueuedExtraReducers,
} from './serviceActions/fetchIngestionsQueuedAsync';

const initialState = {
  getIngestionQueuedState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    paginationList: null,
    ingestionList: [],
    forbiddenState: false,
    forbiddenErrorMessage: null,
    ingestionCorsErrorMessage: null,
  },
};

const getIngestionQueuedSlice = createSlice({
  name: 'IngestionList',
  initialState,
  reducers: {
    resetIngestionSubmit(state) {
      state.getIngestionQueuedState = initialState.getIngestionQueuedState;
    },
  },
  extraReducers: {
    ...getIngestionsQueuedExtraReducers,
  },
});

export { getIngestionsQueuedAsync };
export const { resetIngestionQueuedSubmit } = getIngestionQueuedSlice.actions;
export default getIngestionQueuedSlice.reducer;
