import { useDispatch, useSelector } from 'react-redux';
import { constant } from '@config/BaseSetting';
import useLocalStorage from '@hooks/useLocalStorage';
import {
  getMailBoxChangeAsync,
  resetMailChangeRequestStatus,
} from '@features/mailBox/getMailBoxChangeRequestSlice';
import { getMailBoxSyncAsync, resetMailSyncStatus } from '@features/mailBox/getMailBoxSyncSlice';
import useMailBoxChange from './useMailBoxChange';
import useMailBoxSync from './useMailBoxSync';

export default function useMailBoxServices() {
  const dispatch = useDispatch();
  const { getMailBoxRequestData } = useMailBoxChange();
  const { getMailBoxSyncRequestData } = useMailBoxSync();
  const { mailChangeRequestState } = useSelector((state) => state.mailRequest);
  const { mailSyncRequestState } = useSelector((state) => state.syncRequest);
  const [accessTokenSet, setAccessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);

  const mailBoxServices = (setMailboxData, setPagesSet, columns = []) => {
    if (mailChangeRequestState?.data?.length > 0) {
      setPagesSet(mailChangeRequestState?.pagination);
      getMailBoxRequestData(mailChangeRequestState?.data, setMailboxData, columns);
    }
  };

  const mailBoxSyncServices = (setMailboxSyncData, setPagesSet) => {
    if (mailSyncRequestState?.data?.length > 0) {
      setPagesSet(mailSyncRequestState?.pagination);
      getMailBoxSyncRequestData(mailSyncRequestState?.data, setMailboxSyncData);
    }
  };

  const mailBoxSyncApiHandler = (pageParams) => {
    if (!mailSyncRequestState?.fetching) {
      dispatch(resetMailChangeRequestStatus());
      dispatch(
        getMailBoxSyncAsync({ accessToken: accessTokenSet, reset: true, pageNumber: pageParams })
      );
    }
  };

  const mailBoxAPIHandler = (pageParams) => {
    if (!mailChangeRequestState?.fetching) {
      dispatch(resetMailSyncStatus());
      dispatch(
        getMailBoxChangeAsync({
          accessToken: accessTokenSet,
          reset: true,
          pageNumber: pageParams,
        })
      );
    }
  };

  return { mailBoxServices, mailBoxAPIHandler, mailBoxSyncServices, mailBoxSyncApiHandler };
}
