import { createAsyncThunk } from '@reduxjs/toolkit';
import { createBulkPurgeRequestAPI } from '@services/WebApiService';

const submitMailboxBulkPurgeFormAsync = createAsyncThunk(
  'MaliboxBulkPurge/submitBulkPurgeForm',
  async (paramsList) => {
    try {
      const data = await createBulkPurgeRequestAPI(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = submitMailboxBulkPurgeFormAsync;

const submitMailboxBulkPurgeFormExtraReducers = {
  [pending]: (state) => {
    state.submitBulkPurgeForm.submitting = true;
  },
  [rejected]: (state) => {
    state.submitBulkPurgeForm.submitting = false;
  },
  [fulfilled]: (state, action) => {
    state.submitBulkPurgeForm.submitting = false;

    if (action?.payload?.error) {
      state.submitBulkPurgeForm.message = action.payload.error?.data?.message;
      state.submitBulkPurgeForm.statusCode = action.payload.error?.status;
    }
    if (action?.payload?.error?.data?.title) {
      state.submitBulkPurgeForm.message = action?.payload?.error?.data?.title;
      state.submitBulkPurgeForm.statusCode = action?.payload?.error?.data?.status;
    }
    if (action?.payload && !action?.payload?.error) {
      state.submitBulkPurgeForm.message = action.payload?.data?.message;
      state.submitBulkPurgeForm.statusCode = action.payload?.status;
    }
  },
};

export { submitMailboxBulkPurgeFormAsync, submitMailboxBulkPurgeFormExtraReducers };
