import { createSlice } from '@reduxjs/toolkit';

import {
  getAutoDiscoveryAsync,
  getAutoDiscoveryExtraReducers,
} from './serviceActions/fetchAutoDiscoveryAsync';

const initialState = {
  getAutoDiscoveryState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    autoDiscoveryList: [],
    paginationList: {},
    forbiddenState: false,
    forbiddenErrorMessage: null,
    autoDiscoveryCorsErrorMessage: null,
  },
};

const getAutoDiscoverySlice = createSlice({
  name: 'AutoDiscovery',
  initialState,
  reducers: {
    resetAutoDiscoveryState(state) {
      state.getAutoDiscoveryState = initialState.getAutoDiscoveryState;
    },
  },
  extraReducers: {
    ...getAutoDiscoveryExtraReducers,
  },
});

export { getAutoDiscoveryAsync };
export const { resetAutoDiscoveryState, resetAutoDiscoveryList } = getAutoDiscoverySlice.actions;
export default getAutoDiscoverySlice.reducer;
