import * as React from 'react';
import PropTypes from 'prop-types';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

export default function QboBreadcrums({ sx, children, className }) {
  return (
    <Breadcrumbs
      sx={sx}
      separator={<KeyboardDoubleArrowRightIcon />}
      aria-label="breadcrumb"
      className={`QboBreadcrumbs__wrapper ${className}`}>
      {children}
    </Breadcrumbs>
  );
}

QboBreadcrums.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
  sx: PropTypes.object,
};

QboBreadcrums.defaultProps = {
  className: '',
  sx: {},
};
