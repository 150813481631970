import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { isEqual } from 'lodash';
import useLocalStorage from '@hooks/useLocalStorage';
import { constant } from '@config/BaseSetting';
import { mailBoxChangeAsync, resetMailChangeStatus } from '@features/mailBox/mailboxChangeSlice';
import { mailBoxSyncAsync, resetMailSyncStatus } from '@features/mailBox/mailBoxSyncSlice';
import useMailBoxChange from '@pages/sharedHooks/useMailBoxChange';
import useValidation from './useValidation';

function useForm(page) {
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const dispatch = useDispatch();
  const {
    supportTicketAttributes,
    escalationTicketAttributes,
    descriptionAttributes,
    organizationOwnerIdAttributes,
    currentEmailAttributes,
    newEmailAttributes,
    changeRequestTypeAttributes,
    resetForm,
    isValid,
    formData,
    errors,
    validateAll,
  } = useValidation();

  const previousPropsRef = useRef(formData);

  const { checkValueExistance } = useMailBoxChange();

  const callApiHandler = () => {
    if (isValid && checkValueExistance(formData)) {
      if (page === constant.MAILBOX_CHANGE_PAGE) {
        dispatch(resetMailSyncStatus());
        dispatch(
          mailBoxChangeAsync({ accessToken: accessTokenSet, reset: true, parameters: formData })
        );
      } else if (page === constant.MAILBOX_SYNC_PAGE) {
        const { 'change-request-type': changeRequestType, ...rest } = formData;
        dispatch(resetMailChangeStatus());
        dispatch(mailBoxSyncAsync({ accessToken: accessTokenSet, reset: true, parameters: rest }));
      }
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    validateAll();
    const previousProps = previousPropsRef.current;
    if (!isEqual(previousProps, formData)) {
      dispatch(resetMailChangeStatus());
      dispatch(resetMailSyncStatus());
      callApiHandler();
      previousPropsRef.current = formData;
    }
  };

  useEffect(() => {
    callApiHandler();
  }, [isValid]);

  return {
    supportTicketAttributes,
    escalationTicketAttributes,
    descriptionAttributes,
    organizationOwnerIdAttributes,
    currentEmailAttributes,
    newEmailAttributes,
    changeRequestTypeAttributes,
    isValid,
    resetForm,
    formData,
    onSubmit,
  };
}

export default useForm;
