import { createSlice } from '@reduxjs/toolkit';
import {
  getSeatCountChangeAsync,
  getSeatCountChangeReducers,
} from './serviceActions/getSeatCountChangeAsync';

const initialState = {
  getSeatCountChangeState: {
    fetching: false,
    success: false,
    data: [],
    error: null,
    errorMessage: null,
    pagination: [],
    forbiddenState: false,
    forbiddenErrorMessage: {},
    getSeatCountChangeCorsErrorMessage: null,
  },
};

const getSeatCountChangeSlice = createSlice({
  name: 'GetSeatCountChange',
  initialState,
  reducers: {
    resetSeatCountChangeStatus(state) {
      state.getSeatCountChangeState = initialState.getSeatCountChangeState;
    },
  },
  extraReducers: {
    ...getSeatCountChangeReducers,
  },
});

export { getSeatCountChangeAsync };
export const { resetSeatCountChangeStatus } = getSeatCountChangeSlice.actions;
export default getSeatCountChangeSlice.reducer;
