import { createSlice } from '@reduxjs/toolkit';

import {
  createTenantAuthErrorsAsync,
  createTenantAuthErrorsExtraReducers,
} from './serviceActions/createTenantAuthErrorsAsync';

const initialState = {
  tenantAuthErrorsState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    tenantAuthErrorsFormList: [],
    paginationList: {},
    message: null,
  },
};

const tenantAuthErrorsSlice = createSlice({
  name: 'TenantAuthErrors',
  initialState,
  reducers: {
    resetCreateTenantAuthErrorsState(state) {
      state.tenantAuthErrorsState = initialState.tenantAuthErrorsState;
    },
  },
  extraReducers: {
    ...createTenantAuthErrorsExtraReducers,
  },
});

export { createTenantAuthErrorsAsync };
export const { resetCreateTenantAuthErrorsState } = tenantAuthErrorsSlice.actions;
export default tenantAuthErrorsSlice.reducer;
