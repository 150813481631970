import { createSlice } from '@reduxjs/toolkit';

import {
  getPurgeGWSTenantsAsync,
  getPurgeGWSTenantsExtraReducers,
} from './serviceActions/fetchPurgeGWSTenantsAsync';

const initialState = {
  getPurgeGWSTenantsState: {
    fetching: false,
    status: null,
    purgeGWSTenantsList: [],
    errorMessage: null,
    paginations: [],
    forbiddenState: false,
    forbiddenErrorMessage: null,
    purgeGWSTenantsCorsErrorMessage: null,
  },
};

const purgeGWSTenantsSlice = createSlice({
  name: 'purgeGWSTenants',
  initialState,
  reducers: {
    resetPurgeGWSTenantsState(state) {
      state.getPurgeGWSTenantsState = initialState.getPurgeGWSTenantsState;
    },
  },
  extraReducers: {
    ...getPurgeGWSTenantsExtraReducers,
  },
});

export { getPurgeGWSTenantsAsync };

export const { resetPurgeGWSTenantsState } = purgeGWSTenantsSlice.actions;
export default purgeGWSTenantsSlice.reducer;
