import { createSlice } from '@reduxjs/toolkit';

import {
  tenantSizeReportAsync,
  tenantSizeReportExtraReducers,
} from './serviceActions/tenantSizeReportAsync';

const initialState = {
  tenantSizeReportState: {
    fetching: false,
    status: null,
    error: null,
    message: null,
    errorMessage: null,
    forbiddenState: false,
    forbiddenErrorMessage: null,
    tenantSizeReportCorsErrorMessage: null,
    serviceType: null,
  },
};

const tenantSizeReportSlice = createSlice({
  name: 'tenantSizeReport',
  initialState,
  reducers: {
    resetTenantSizeReportState(state) {
      state.tenantSizeReportState = initialState.tenantSizeReportState;
    },
  },
  extraReducers: {
    ...tenantSizeReportExtraReducers,
  },
});

export { tenantSizeReportAsync };

export const { resetTenantSizeReportState } = tenantSizeReportSlice.actions;
export default tenantSizeReportSlice.reducer;
