import { constant } from '@config/BaseSetting';
import { getGWSTenantDetailsInfoAsync } from '@features/gwsTenantDetailsInfo/gwsTenantDetailsInfoSlice';
import useLocalStorage from '@hooks/useLocalStorage';
import { useDispatch } from 'react-redux';

export default function useGWSTenantDetailInformationService(setGWSDetailsData) {
  const [accessToken] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);

  const dispatch = useDispatch();

  const handleFetchGWSTenantDetailInformationAsync = (gwsTenantCredentialID) => {
    dispatch(
      getGWSTenantDetailsInfoAsync({
        accessToken,
        reset: true,
        gwsTenantID: gwsTenantCredentialID,
      })
    );
  };

  const getGWSDetailsDataFromResponse = (data) => {
    const dataObject = {
      0: 'Not Ready',
      7: 'Completed',
      11: 'Error',
    };

    const modifiedData = data?.map((item) => {
      const responseArray = item?.response !== '' ? JSON.parse(item?.response) : [];

      const responseFilterCallback = (status) => {
        if (responseArray?.length > 0) {
          const userObject = responseArray.filter((user) => user.active === status);

          return userObject?.length;
        }

        return 0;
      };

      const modifiedPayloadObj = {
        ...item,
        appAuthStatus: dataObject[item?.appAuthStatus],
        availableUsersNumber: responseFilterCallback('available'),
        excludedUsersNumber: responseFilterCallback('blacklist'),
        addedUsersNumber: responseFilterCallback('active'),
        notAvailableUsersNumber: responseFilterCallback('not_available'),
        allUserNumbers: responseArray?.length || 0,
      };
      return modifiedPayloadObj;
    });
    return setGWSDetailsData(modifiedData);
  };

  return { handleFetchGWSTenantDetailInformationAsync, getGWSDetailsDataFromResponse };
}
