import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import QboSortByButton from '@ui/Components/QboSortByButton';
import useOrganizationFilterHook from '@pages/sharedHooks/useOrganizationFilterHook';

export default function OrganizationFilterSection(props) {
  const { partnerId, value, handleChange, label, inputSize, marginTopValue, marginRightValue } =
    props;
  const { organizationFilterState, resetOrgFilter, UpdateFilters, organizationList } =
    useOrganizationFilterHook(partnerId, value);

  useEffect(() => {
    handleChange('');
  }, [partnerId]);

  return (
    <QboSortByButton
      defaultValue="All"
      forceUpdate={organizationFilterState.forceUpdate}
      searchState={organizationFilterState.searchState}
      handleCloseClick={() => {
        resetOrgFilter();
        handleChange('');
      }}
      isSearchEnable={!false}
      handleOnSearchChange={(e) => {
        UpdateFilters(e.target.value, ['searchState']);
      }}
      handleChange={(e) => {
        UpdateFilters(e.target.value, ['selectedOrganization']);
        handleChange(e.target.value);
      }}
      marginRightValue={marginRightValue}
      marginTopValue={marginTopValue}
      value={organizationFilterState.selectedOrganization}
      buttonWidth={220}
      startAdornmentTextWidth={inputSize}
      startAdornmentText={label}
      menuItemList={organizationList}
    />
  );
}

OrganizationFilterSection.propTypes = {
  partnerId: PropTypes.number,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  label: PropTypes.string,
  inputSize: PropTypes.number,
  marginTopValue: PropTypes.number,
  marginRightValue: PropTypes.number,
};

OrganizationFilterSection.defaultProps = {
  partnerId: null,
  value: '',
  handleChange: () => {},
  label: 'Organizations',
  inputSize: 180,
  marginTopValue: undefined,
  marginRightValue: 2,
};
