import { createSlice } from '@reduxjs/toolkit';

import {
  createTransferSubscriptionInitiateAsync,
  createTransferSubscriptionInitiateExtraReducers,
} from './serviceActions/createTransferSubscriptionInitiateAsync';

const initialState = {
  transferSubscriptionInitiateState: {
    fetching: false,
    status: null,
    error: null,
    message: null,
  },
};

const createTransferSubscriptionInitiateSlice = createSlice({
  name: 'TransferSubscriptionInitiate',
  initialState,
  reducers: {
    resetCreateTransferSubscriptionInitiateState(state) {
      state.transferSubscriptionInitiateState = initialState.transferSubscriptionInitiateState;
    },
  },
  extraReducers: {
    ...createTransferSubscriptionInitiateExtraReducers,
  },
});

export { createTransferSubscriptionInitiateAsync };

export const { resetCreateTransferSubscriptionInitiateState } =
  createTransferSubscriptionInitiateSlice.actions;
export default createTransferSubscriptionInitiateSlice.reducer;
