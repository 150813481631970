import React, { useEffect, useState } from 'react';
import { Skeleton } from '@mui/material';
import { QboCard } from '@ui/Components';
import QboCardSingleList from '@ui/Components/QboCardSingleList';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import { useSelector } from 'react-redux';
import useDataService from './pagehooks/useDataService';
import useGWSTenantDetailInformationService from './pagehooks/useGWSTenantDetailInformationService';

export default function GWSTenantDetailsPage(props) {
  const { gwsTenantCredentialID } = props;
  const { getGWSTenantDetailsInfoState } = useSelector((state) => state.gwsTenantDetailsInfo);
  const { gwsTenantDetailsInformation, fetching } = getGWSTenantDetailsInfoState;
  const [gwsDetailsData, setGWSDetailsData] = useState([]);
  const { handleFetchGWSTenantDetailInformationAsync, getGWSDetailsDataFromResponse } =
    useGWSTenantDetailInformationService(setGWSDetailsData);
  const { gwsTenantInfoDetails } = useDataService();

  useEffect(() => {
    if (gwsTenantDetailsInformation.length === 0) {
      handleFetchGWSTenantDetailInformationAsync(gwsTenantCredentialID);
    }
  }, [gwsTenantCredentialID]);

  useEffect(() => {
    getGWSDetailsDataFromResponse(gwsTenantDetailsInformation);
  }, [gwsTenantDetailsInformation]);

  const valueHandler = (label, properties, valType) => {
    const responseData = gwsDetailsData[0];
    let value = ' ';

    if (responseData && Object.keys(responseData).length > 0) {
      value = responseData[properties] || '-';

      if (responseData[properties] === 0) {
        value = '0';
      }
    }

    return value;
  };

  const renderLoading = () => {
    return (
      <div style={{ display: 'contents' }}>
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="70%" />
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="70%" />
      </div>
    );
  };

  const componentHandler = () => {
    let content = null;

    if (fetching) {
      content = renderLoading();
    } else if (
      gwsTenantDetailsInformation[0] &&
      Object.keys(gwsTenantDetailsInformation[0]).length > 0
    ) {
      content = (
        <QboCard
          className="QboCard__wrapper_search_text_highlight"
          sx={{
            scrollbarWidth: 'none !important',
            overflowY: 'scroll !important',
            height: '65vh',
          }}>
          <QboCard sx={{ marginTop: 2, marginBottom: 2 }}>
            {gwsTenantInfoDetails?.map((notification) => (
              <QboCardSingleList
                key={notification.label}
                subHeadingText={notification?.subHeadingText}
                leftContent={notification.label}
                rightContent={valueHandler(
                  notification.label,
                  notification.properties,
                  notification.valType
                )}
              />
            ))}
          </QboCard>
        </QboCard>
      );
    } else if (!fetching && !gwsTenantDetailsInformation[0]) {
      content = <NoStatusSection />;
    }

    return content;
  };

  return componentHandler();
}
