import { createSlice } from '@reduxjs/toolkit';
import {
  validateTransferPartnerAsync,
  validateTransferPartnerFormExtraReducers,
} from './serviceActions/validateTransferPartnerAsync';

const initialState = {
  validateTransferPartnerForm: {
    submitting: false,
    success: false,
    validatedData: {},
    error: null,
    statusCode: null,
    errorMessage: null,
  },
};

const validateTransferPartnerSlice = createSlice({
  name: 'ValidateTransferPartnerForm',
  initialState,
  reducers: {
    resetValidateTransferPartnerFormSubmit(state) {
      state.validateTransferPartnerForm = initialState.validateTransferPartnerForm;
    },
  },
  extraReducers: {
    ...validateTransferPartnerFormExtraReducers,
  },
});

export { validateTransferPartnerAsync };
export const { resetValidateTransferPartnerFormSubmit } = validateTransferPartnerSlice.actions;
export default validateTransferPartnerSlice.reducer;
