import { useDispatch } from 'react-redux';
import BaseSetting, { constant } from '@config/BaseSetting';
import useLocalStorage from '@hooks/useLocalStorage';

export default function useLogoutAccount(instance) {
  const [value, setValues, deleteValue] = useLocalStorage(constant.REGIONS_LIST);
  const [selectRegion, setSelectRegion, deleteSelectRegion] = useLocalStorage(
    constant.SELECTED_REGIONS
  );

  const accountLogoutRedirect = async () => {
    try {
      deleteSelectRegion();
      deleteValue();
      localStorage.clear();
      await instance.logoutRedirect({
        postLogoutRedirectUri: `${BaseSetting.config.redirectUri}/login`,
      });
    } catch (error) {
      console.log('Sign-out canceled or encountered an error:', error);
    }
  };

  return { accountLogoutRedirect };
}
