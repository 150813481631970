export default function useDataService() {
  const m365UsersTenantHeaders = [
    { id: 1, label: 'M365 ID', columnName: 'id', sx: { paddingLeft: 2 } },
    {
      id: 2,
      label: 'Email',
      columnName: 'email',
      sx: { paddingLeft: 2 },
    },
    {
      id: 3,
      label: 'User Name',
      columnName: 'name',
      sx: { paddingLeft: 2 },
    },
    {
      id: 4,
      label: 'UPN',
      columnName: 'upn',
      sx: { paddingLeft: 2 },
    },
    {
      id: 5,
      label: 'Status',
      columnName: 'active',
      sx: { paddingLeft: 2 },
    },
    {
      id: 9,
      label: 'Mailbox',
      columnName: 'mailbox',
      sx: { paddingLeft: 2 },
    },
    {
      id: 10,
      label: 'Department',
      columnName: 'department',
      sx: { paddingLeft: 2 },
    },
    { id: 13, label: 'Has M365 License', columnName: 'licensed', sx: { paddingLeft: 2 } },
  ];

  const buttonStyle = {
    height: 36,
    backgroundColor: '#a5a5a5',
    textDecoration: 'none',
    color: 'black',
    '&:hover': {
      backgroundColor: '#a5a5a5',
    },
    textTransform: 'unset',
    fontSize: 14,
  };

  const licensedStatus = [
    { id: 1, value: 'true', title: 'True' },
    { id: 2, value: 'false', title: 'False' },
  ];

  const activeStatus = [
    { id: 1, value: 'active', title: 'Active' },
    { id: 2, value: 'reactivate', title: 'Re-activate' },
  ];

  const filterStates = {
    advanceClick: false,
    textFieldValue: '',
    searchFieldValueText: '',
    searchFieldValueID: '',
    selectedSortColumn: '',
    matchText: '',
    resetClicked: false,
  };

  const initialFilterState = [
    { field: 'filter_assignedLicenses', value: '' },
    { field: 'filter_licensed', value: '' },
    { field: 'filter_active', value: '' },
  ];

  return {
    m365UsersTenantHeaders,
    buttonStyle,
    licensedStatus,
    activeStatus,
    filterStates,
    initialFilterState,
  };
}
