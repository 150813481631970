import React, { useState } from 'react';
import { constant } from '@config/BaseSetting';
import { useLocalStorage } from '@hooks';

export default function useStepper(props = {}) {
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());

  const { optionalStep, steps, freezeSteps } = props;

  const isStepOptional = (step) => {
    return optionalStep?.includes(step);
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const funcNewSteps = () => {
    const result = [];
    steps?.map((x, index) => {
      const test = freezeSteps?.includes(index);
      if (test) {
        return result.push(result[result.length - 1]);
      }
      if (result.length) {
        return result.push(result[result.length - 1] + 1);
      }
      return result.push(index);
    });
    return result;
  };
  const newSteps = funcNewSteps();

  return {
    activeStep,
    isStepOptional,
    isStepSkipped,
    handleNext,
    handleBack,
    handleSkip,
    handleReset,
    newSteps,
  };
}
