import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { isEqual } from 'lodash';
import useLocalStorage from '@hooks/useLocalStorage';
import { constant } from '@config/BaseSetting';
import { mailBoxChangeAsync, resetMailChangeStatus } from '@features/mailBox/mailboxChangeSlice';
import { mailBoxSyncAsync, resetMailSyncStatus } from '@features/mailBox/mailBoxSyncSlice';
import useMailBoxChange from '@pages/sharedHooks/useMailBoxChange';
import useValidation from './useValidation';

function useForm(page) {
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const dispatch = useDispatch();
  const {
    supportTicketAttributes,
    escalationTicketAttributes,
    descriptionAttributes,
    organizationOwnerIdAttributes,
    currentEmailAttributes,
    newEmailAttributes,
    changeRequestTypeAttributes,
    prefixAttributes,
    mappingFileAttributes,
    emailListingAttributes,
    isBulkAttributes,
    resetForm,
    isValid,
    formData,
    errors,
    validateAll,
  } = useValidation();

  const previousPropsRef = useRef(formData);

  const callApiHandler = () => {
    if (isValid) {
      const isBulk = formData['is-bulk'] === '1';
      const parameters = {
        description: formData.description,
        'is-bulk': isBulk,
        'org-owner-id': formData['org-owner-id'],
        'support-ticket': formData['support-ticket'],
        'change-request-type': formData['change-request-type'],
        'escalation-ticket': formData['escalation-ticket'],
      };

      if (isBulk) {
        parameters['current-email'] = formData['email-listing'];
        parameters.prefix = formData.prefix;
      } else {
        parameters['current-email'] = formData['current-email'];
        parameters['new-email'] = formData['new-email'];
      }

      dispatch(resetMailSyncStatus());
      dispatch(mailBoxChangeAsync({ accessToken: accessTokenSet, reset: true, parameters }));
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    validateAll();
    const previousProps = previousPropsRef.current;
    const objectTheSame = !isEqual(previousProps, formData);

    if (objectTheSame) {
      dispatch(resetMailChangeStatus());
      dispatch(resetMailSyncStatus());
      callApiHandler();
      previousPropsRef.current = formData;
    }
  };

  useEffect(() => {
    callApiHandler();
  }, [isValid]);

  const selectTypeOptions = [
    { id: '0', label: 'Single Request' },
    { id: '1', label: 'Multiple Request' },
  ];

  return {
    supportTicketAttributes,
    escalationTicketAttributes,
    descriptionAttributes,
    organizationOwnerIdAttributes,
    currentEmailAttributes,
    newEmailAttributes,
    changeRequestTypeAttributes,
    isBulkAttributes,
    emailListingAttributes,
    prefixAttributes,
    mappingFileAttributes,
    isValid,
    resetForm,
    formData,
    onSubmit,
    selectTypeOptions,
  };
}

export default useForm;
