import { constant } from '@config/BaseSetting';
import { getSubResellerListAsync } from '@features/subReseller/getSubResellerListSlice';
import useLocalStorage from '@hooks/useLocalStorage';
import { useDispatch } from 'react-redux';
import { isEqual } from 'lodash';

export default function useSubResellerService(
  filterDataState,
  setFilterDataState,
  filterStates,
  pagination
) {
  const dispatch = useDispatch();
  const [accessToken] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);

  const handleGetSubResellerListAsync = (resellerID, flag = false) => {
    dispatch(
      getSubResellerListAsync({
        accessToken,
        reset: true,
        reseller: resellerID,
        pageNumber: pagination.pageIndex + 1,
        pageSize: pagination.pageSize,
        status: flag ? filterStates.selectStatus : filterDataState.selectStatus,
        integrationType: flag
          ? filterStates.integrationTypeState
          : filterDataState.integrationTypeState,
        salesOwner: flag ? filterStates.selectSalesOwner : filterDataState.selectSalesOwner,
        fullTextSearch: flag
          ? filterStates.searchFieldValueText
          : filterDataState.searchFieldValueText,
        searchById: flag ? filterStates.searchFieldValueID : filterDataState.searchFieldValueID,
        partnerType: flag ? filterStates.selected : filterDataState.selected,
        orderBy: flag ? filterStates.selectedColumn : filterDataState.selectedColumn,
      })
    );
  };

  const handleStateUpdate = (key, value) => {
    setFilterDataState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleOnTextFiledChange = (e) => {
    const input = e.target.value;
    setFilterDataState((prevState) => ({
      ...prevState,
      textFieldValue: input,
    }));
    if (/^\d+$/.test(input)) {
      setFilterDataState((prevState) => ({
        ...prevState,
        searchFieldValueText: input,
      }));
      setFilterDataState((prevState) => ({
        ...prevState,
        searchFieldValueID: input,
      }));
    } else {
      setFilterDataState((prevState) => ({
        ...prevState,
        searchFieldValueID: '',
      }));
      setFilterDataState((prevState) => ({
        ...prevState,
        searchFieldValueText: input,
      }));
    }
  };

  const handleOnResetButtonClick = async (resellerID) => {
    await setFilterDataState((prevState) => ({
      ...prevState,
      forceUpdate: filterStates.forceUpdate,
      searchState: filterStates.searchState,
      textFieldValue: filterStates.textFieldValue,
      searchFieldValueText: filterStates.searchFieldValueText,
      searchFieldValueID: filterStates.searchFieldValueID,
      selected: filterStates.selected,
      filterdState: filterStates.filterdState,
      selectStatus: filterStates.selectStatus,
      selectSalesOwner: filterStates.selectSalesOwner,
      integrationTypeState: filterStates.integrationTypeState,
      selectedColumn: filterStates.selectedColumn,
      matchText: filterStates.matchText,
      resetClicked: filterStates.resetClicked,
    }));

    handleGetSubResellerListAsync(resellerID, true);
  };

  const handleOnHideAdvanceClick = () => {
    setFilterDataState((prevState) => ({
      ...prevState,
      forceUpdate: filterStates.forceUpdate,
      searchState: filterStates.searchState,
      selected: filterStates.selected,
      filterdState: filterStates.filterdState,
      selectStatus: filterStates.selectStatus,
      selectSalesOwner: filterStates.selectSalesOwner,
      integrationTypeState: filterStates.integrationTypeState,
      selectedColumn: filterStates.selectedColumn,
    }));
  };

  const handleCheckBoxChange = (event, names) => {
    const { value } = event.target;

    if (value[value.length - 1] === 'all') {
      setFilterDataState((prevState) => ({
        ...prevState,
        selected: filterDataState.selected.length === names.length ? [] : names,
      }));

      return;
    }
    setFilterDataState((prevState) => ({
      ...prevState,
      selected: value,
    }));
  };

  const matchTextHandler = () => {
    const {
      searchFieldValueID,
      searchFieldValueText,
      selected,
      selectSalesOwner,
      selectStatus,
      integrationTypeState,
    } = filterDataState;

    let textData = 'Matching';

    if (searchFieldValueID) {
      if (textData !== 'Matching') {
        textData += ', ';
      }
      textData += ` ID = ${searchFieldValueID}`;
    }

    if (searchFieldValueText) {
      if (textData !== 'Matching') {
        textData += ', ';
      }
      textData += ` name/owner email like '%${searchFieldValueText}%' `;
    }

    if (selected && selected.length > 0) {
      if (textData !== 'Matching') {
        textData += ', ';
      }
      textData += ' Partner Type = ';
      textData += selected.reduce((acc, val, index) => {
        return acc + (index === 0 ? '' : ', ') + val;
      }, '');
    }

    if (selectSalesOwner) {
      if (textData !== 'Matching') {
        textData += ', ';
      }
      textData += ` Status = ${selectSalesOwner}`;
    }

    if (integrationTypeState) {
      if (textData !== 'Matching') {
        textData += ', ';
      }
      textData += ` Integration Type = ${integrationTypeState}`;
    }

    if (selectStatus) {
      if (textData !== 'Matching') {
        textData += ', ';
      }
      textData += ` Sales Owner = ${selectStatus}`;
    }

    if (textData === 'Matching') {
      textData = '';
    }

    setFilterDataState((prevState) => ({
      ...prevState,
      matchText: textData,
    }));
  };

  return {
    handleGetSubResellerListAsync,
    handleStateUpdate,
    handleOnTextFiledChange,
    handleOnResetButtonClick,
    handleCheckBoxChange,
    matchTextHandler,
    handleOnHideAdvanceClick,
  };
}
