/* eslint-disable import/prefer-default-export */
export const CREATE_SUBRESELLER_HEADERS = [
  { label: 'Email', columnName: 'email' },
  { label: 'First Name', columnName: 'first_name' },
  { label: 'Last Name', columnName: 'last_name' },
  { label: 'Organization Name', columnName: 'organization_name' },
  { label: 'Telephone', columnName: 'telephone' },
  { label: 'Billing Address', columnName: 'billing_address' },
  { label: 'Postal Code', columnName: 'postal_code' },
  { label: 'Archiver Plan', columnName: 'archiver_plan' },
  { label: 'Backup Plan', columnName: 'backup_plan' },
  { label: 'Personal Plan', columnName: 'personal_plan' },
  { label: 'Password', columnName: 'password' },
];
