import React, { useSelector, useDispatch } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import useLocalStorage from '@hooks/useLocalStorage';
import useStepper from '@pages/sharedHooks/useStepper';
import { constant } from '@config/BaseSetting';
import {
  resetGWSTenantDetail,
  gwsTenantDetailAsync,
} from '@features/gwsTenantDetail/gwsTenantDetailSlice';
import { getPurgeGWSTenantsAsync } from '@features/purgeGWSTenant/getPurgeGWSTenantsSlice';
import {
  purgeGWSTenantAsync,
  resetPurgeGWSTenantState,
} from '@features/purgeGWSTenant/purgeGWSTenantSlice';
import SettingsIcon from '@mui/icons-material/Settings';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import useForm from './useForm';

export default function usePurgeGWSTenant() {
  // Dependencies Hooks
  const form = useForm();
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const stepper = { ...useStepper() };
  const dispatch = useDispatch();

  // React Hooks
  const recordedListData = useRef();

  // Reducers State
  const { purgeGWSTenantState } = useSelector((state) => state.purgeGWSTenant);
  const { gwsTenantDetailState } = useSelector((state) => state.gwsTenantDetail);
  const { getPurgeGWSTenantsState } = useSelector((state) => state.getPurgeGWSTenants);

  // React State
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [messageDialog, setMessageDialog] = useState('');
  const [showMesageDialog, setShowMesageDialog] = useState(false);
  const [paginations, setPaginations] = useState({ pageIndex: 0, pageSize: 10 });

  // UI Related
  const tableColumns = [
    {
      id: 0,
      label: 'Request ID',
      columnName: 'id',
      align: 'center',
      sx: { paddingLeft: 2 },
      size: 190,
    },
    {
      id: 1,
      label: 'Organization Owner ID',
      columnName: 'org-owner-id',
      align: 'center',
      sx: { paddingLeft: 2 },
      size: 190,
    },
    { id: 2, label: 'Support Ticket', columnName: 'support-ticket', sx: { paddingLeft: 2 } },
    { id: 3, label: 'Escalation Ticket', columnName: 'escalation-ticket', sx: { paddingLeft: 2 } },
    { id: 4, label: 'Description', columnName: 'description', sx: { paddingLeft: 2 } },
    { id: 5, label: 'Credential ID', columnName: 'credential-id', sx: { paddingLeft: 2 } },
    { id: 6, label: 'Status', columnName: 'status-description', sx: { paddingLeft: 2 } },
    { id: 7, label: 'Executed On', columnName: 'executed-on', sx: { paddingLeft: 2 } },
  ];

  stepper.steps = ['1', '2'];
  stepper.stepIcons = {
    1: <SettingsIcon />,
    2: <VideoLabelIcon />,
  };

  // Events Actions
  const fetchListAsync = () => {
    recordedListData.current = getPurgeGWSTenantsState.purgeGWSTenantsList;

    if (!getPurgeGWSTenantsState.fetching) {
      const pageIndex = paginations.pageIndex || 0;
      const { pageSize } = paginations;

      dispatch(
        getPurgeGWSTenantsAsync({
          accessToken: accessTokenSet,
          reset: true,
          pageNumber: pageIndex + 1,
          pageSize,
        })
      );
    }
  };

  const handleGetTenantDetailAsync = (credentialId, organizationOwnerId) => {
    dispatch(
      gwsTenantDetailAsync({
        gwsssToken: accessTokenSet,
        parameters: { credentialId, organizationOwnerId },
      })
    );
  };

  const handleCreatePurgeRequestAsync = (parameters) => {
    dispatch(
      purgeGWSTenantAsync({
        accessToken: accessTokenSet,
        reset: true,
        parameters,
      })
    );
  };

  const onStepBack = () => {
    stepper.handleBack();
    dispatch(resetGWSTenantDetail());
  };

  const resetNewRequestModal = () => {
    onStepBack();
    form.resetForm();
  };

  const closeSetupModal = () => {
    resetNewRequestModal();
    setShowRequestModal(false);
    fetchListAsync();
  };

  const openSetupModal = () => {
    setShowRequestModal(true);
    stepper.handleReset();
    form.resetForm();
    dispatch(resetPurgeGWSTenantState());
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    handleCreatePurgeRequestAsync({
      'support-ticket': form.attributes.supportTicketAttributes.value,
      'credential-id': form.attributes.credentialIdAttributes.value,
      'escalation-ticket': form.attributes.escalationTicketAttributes.value,
      'org-owner-id': form.attributes.organizationOwnerIdAttributes.value,
      description: form.attributes.descriptionAttributes.value,
    });
  };

  const validateTenantInfo = () => {
    if (form.isValid && !gwsTenantDetailState.statusCode !== 200) {
      handleGetTenantDetailAsync(
        form.attributes.credentialIdAttributes.value,
        form.attributes.organizationOwnerIdAttributes.value
      );
    }
  };

  const onValidateData = (e) => {
    form.onValidateData(e);
    validateTenantInfo();
  };

  const onDisplayMessageModal = (message) => {
    setMessageDialog(message);
    setShowMesageDialog(true);
  };

  // useEffect Hoooks
  // callbacks against the pages

  // when form.isValid is trigerred
  useEffect(() => {
    validateTenantInfo();
  }, [form.isValid]);

  // when tenant info is found
  useEffect(() => {
    if (gwsTenantDetailState.statusCode === 200) stepper.handleNext();
  }, [gwsTenantDetailState.statusCode]);

  // when form submitted and get
  // success response
  useEffect(() => {
    if (purgeGWSTenantState.status === 200) closeSetupModal();
  }, [purgeGWSTenantState.status]);

  // when pagination is clicked
  useEffect(() => {
    fetchListAsync();
  }, [paginations.pageSize, paginations.pageIndex]);

  return {
    getPurgeGWSTenantsState,
    purgeGWSTenantState,
    tableColumns,
    form,
    stepper,
    showRequestModal,
    closeSetupModal,
    openSetupModal,
    onSubmitForm,
    onValidateData,
    onStepBack,
    pagination: {
      paginations,
      setPaginations,
      paginationState: getPurgeGWSTenantsState.paginations,
    },
    onDisplayMessageModal,
    fetchListAsync,
    gwsTenantDetailState,
    detailMessageDialog: {
      showMesageDialog,
      setShowMesageDialog,
      messageDialog,
    },
  };
}
