import { createAsyncThunk } from '@reduxjs/toolkit';
import { exportOrganizationsAPI } from '@services/WebApiService';

const exportOrganizationsAsync = createAsyncThunk(
  'OrganizationsList/exportOrganizations',
  async (paramsList) => {
    try {
      const data = await exportOrganizationsAPI(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = exportOrganizationsAsync;

const exportOrganizationsExtraReducers = {
  [pending]: (state) => {
    state.exportOrganizationsState.fetching = true;
  },
  [rejected]: (state) => {
    state.exportOrganizationsState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.exportOrganizationsState.fetching = false;

    if (action?.payload?.status === 403) {
      state.exportOrganizationsState.forbiddenState = true;
      state.exportOrganizationsState.forbiddenErrorMessage = {
        heading: 'Forbidden Error',
        caption: 'You are not authorize to access this resource',
      };
    }

    if (!action?.payload) {
      state.exportOrganizationsState.OrganizationsListCorsErrorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.data) {
      state.exportOrganizationsState.organizationExportData = action?.payload?.data;
      state.exportOrganizationsState.success = true;
      state.exportOrganizationsState.statusCode = action?.payload?.data['status-code'];
    }

    if (action?.payload?.error) {
      state.exportOrganizationsState.error = action.payload.error;
    }
  },
};

export { exportOrganizationsAsync, exportOrganizationsExtraReducers };
