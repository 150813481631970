import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSelectedResellerConfigsAPI } from '@services/WebApiService';

const getSelectedResellerConfigsAsync = createAsyncThunk(
  'SelectedResellerConfigs/getSelectedResellerConfigs',
  async (paramsList) => {
    try {
      const data = await getSelectedResellerConfigsAPI(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = getSelectedResellerConfigsAsync;

const getSelectedResellerConfigsReducers = {
  [pending]: (state) => {
    state.getSelectedResellerConfigsState.fetching = true;
  },
  [rejected]: (state) => {
    state.getSelectedResellerConfigsState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.getSelectedResellerConfigsState.fetching = false;

    if (action?.payload?.data?.message) {
      state.getSelectedResellerConfigsState.errorMessage = action?.payload?.data?.message;
    }

    if (action?.payload?.status === 403) {
      state.getSelectedResellerConfigsState.forbiddenState = true;
      state.getSelectedResellerConfigsState.forbiddenErrorMessage = {
        heading: 'Forbidden Error',
        caption: 'You are not authorize to access this resource',
      };
    }

    if (action?.payload?.data?.data) {
      state.getSelectedResellerConfigsState.forbiddenState = false;
      state.getSelectedResellerConfigsState.forbiddenErrorMessage = {};
      state.getSelectedResellerConfigsState.selectedResellerConfigdata =
        action?.payload?.data?.data;
      state.getSelectedResellerConfigsState.success = true;
    }
    if (action?.payload?.data?.pagination) {
      state.getSelectedResellerConfigsState.pagination = JSON.parse(
        action?.payload?.data?.pagination
      );
    }

    if (!action?.payload) {
      state.getSelectedResellerConfigsState.getSelectedResellerConfigsCorsErrorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.code) {
      state.getSelectedResellerConfigsState.error = action.payload.code;
    }
  },
};

export { getSelectedResellerConfigsAsync, getSelectedResellerConfigsReducers };

export default getSelectedResellerConfigsAsync;
