import WebTokensApiRoutes from '@config/Routes/WebTokensApiRoutes';
import ServiceClientFactory from '@libs/ServiceClient';

const webClientApi = (accessToken, reset = false) => {
  return new ServiceClientFactory({
    baseUrl: WebTokensApiRoutes.getBaseUrl(),
    accessToken,
    reset,
  }).create();
};

const ClientTokenService = {
  authenticateAPI: (paramsList) => {
    const { accessToken, reset } = paramsList;
    return webClientApi(accessToken, reset).postRequest({
      url: WebTokensApiRoutes.authenticationPath(),
    });
  },
  refreshTokenAPI: (paramsList) => {
    const { accessToken, reset } = paramsList;
    return webClientApi(accessToken, reset).postRequest({
      url: WebTokensApiRoutes.refreshTokenPath(),
    });
  },
  getRegionsAPI: (paramsList) => {
    const { accessToken, reset } = paramsList;
    return webClientApi(accessToken, reset).getRequest({
      url: WebTokensApiRoutes.getRegionsListPath(),
    });
  },
};

export default ClientTokenService;
