import { useDispatch } from 'react-redux';
import { constant } from '@config/BaseSetting';
import useLocalStorage from '@hooks/useLocalStorage';
import { getTransferSubscriptionInitiateAsync } from '@features/transferSubscriptionInitiatePageService/getTransferSubscriptionInitiateSlice';
import { createTransferSubscriptionInitiateAsync } from '@features/transferSubscriptionInitiatePageService/createTransferSubscriptionInitiateSlice';

export default function useTransferSubscriptionInitiate() {
  const dispatch = useDispatch();
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);

  const handleTransferSubscriptionInitiateAsync = (pageIndex, pageSize) => {
    dispatch(
      getTransferSubscriptionInitiateAsync({
        accessToken: accessTokenSet,
        reset: true,
        pageNumber: pageIndex,
        pageSize,
      })
    );
  };

  const handleCreateTransferSubscriptionInitiate = (parameters) => {
    dispatch(
      createTransferSubscriptionInitiateAsync({
        accessToken: accessTokenSet,
        reset: true,
        parameters,
      })
    );
  };

  const checkValueExistance = (formSchema, formData) => {
    const valueExist = (value) => value.length > 0;
    const requiredField = Object.keys(formSchema.fields);
    const isValuesPresent = requiredField.map((item) => formData[item]).every(valueExist);

    return isValuesPresent;
  };

  const processDataTransferSubscriptionInitiate = (data, setInitiateListState) => {
    const modifiedData = data?.map((item) => {
      const payloadObj = JSON.parse(item.payload);
      const modifiedPayloadObj = {
        id: item.id,
        'request-id': payloadObj['request-id'],
        'org-owner-id': payloadObj['org-owner-id'],
        'status-description': item['status-description'],
        status: item.status,
        description: payloadObj.description,
        'target-partner-id': payloadObj['target-partner-id'],
        'support-ticket': payloadObj['support-ticket'],
        'escalation-ticket': payloadObj['escalation-ticket'],
        'executed-on': item['executed-on'],
      };
      return modifiedPayloadObj;
    });
    return setInitiateListState(modifiedData);
  };

  return {
    handleTransferSubscriptionInitiateAsync,
    processDataTransferSubscriptionInitiate,
    handleCreateTransferSubscriptionInitiate,
    checkValueExistance,
  };
}
