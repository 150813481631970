import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPartnersListAPI } from '@services/WebApiService';

const getPartnersListAsync = createAsyncThunk(
  'partnersList/getPartnersLists',
  async (paramsList) => {
    try {
      const data = await getPartnersListAPI(paramsList);
      return data;
    } catch (error) {
      return error.response;
    }
  }
);

const { pending, fulfilled, rejected } = getPartnersListAsync;

const getPartnersListExtraReducers = {
  [pending]: (state) => {
    state.getPartnersListState.fetching = true;
  },
  [rejected]: (state) => {
    state.getPartnersListState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.getPartnersListState.fetching = false;

    if (action?.payload?.status === 403) {
      state.getPartnersListState.forbiddenState = true;
      state.getPartnersListState.forbiddenErrorMessage = {
        heading: 'Forbidden Error',
        caption: 'You are not authorize to access this resource',
      };
    }

    if (!action?.payload) {
      state.getPartnersListState.partnersListCorsErrorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.data?.data) {
      state.getPartnersListState.partnersList = action?.payload?.data?.data;
      state.getPartnersListState.paginationList = JSON.parse(action?.payload?.data?.pagination);
      state.getPartnersListState.success = true;
      state.getPartnersListState.statusCode = action?.payload?.data['status-code'];
    }

    if (action?.payload?.error) {
      state.getPartnersListState.error = action.payload.error;
    }
  },
};

export { getPartnersListAsync, getPartnersListExtraReducers };
