import React from 'react';
import { Box, Dialog } from '@mui/material';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { QboCard, QboButton, QboAvatar, QboSpinnerProgress } from '@ui/Components';
import MicrosoftIcon from '@images/generals/MicrosoftIcon.svg';
import { InteractionStatus } from '@azure/msal-browser';
import { useLocation } from 'react-router-dom';

export default function LoginPage() {
  const { state } = useLocation();
  const { instance, inProgress, accounts } = useMsal();
  const logginProcessStatus = ['startup', 'handleRedirect'];
  const handleSignIn = () => {
    if (inProgress === InteractionStatus.None) {
      instance.loginRedirect({
        scopes: ['user.read'],
      });
    }
  };
  const open = true;

  return (
    (accounts.length === 0 || state?.userNameError) &&
    (!logginProcessStatus.includes(inProgress) ? (
      <QboCard noPadding isMainWrapper>
        <Box className="LoginPage__wrapper">
          <QboButton
            variant="contained"
            onClick={handleSignIn}
            color="info"
            startIcon={
              <QboAvatar className="LoginPage__icon" src={MicrosoftIcon} variant="square" />
            }>
            Sign in with Microsoft Account
          </QboButton>
        </Box>
      </QboCard>
    ) : (
      <Dialog
        open={open}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            overflow: 'hidden',
          },
        }}
        BackdropProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        }}>
        <QboSpinnerProgress className="DefaultApplication__not-ready-loading" fullWrapper />
      </Dialog>
    ))
  );
}
