/* eslint-disable max-classes-per-file */
import { openNewUrl } from '@utilities';
import useRegionsList from '@pages/sharedHooks/useRegionsList';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { partnerDetailAsync } from '@features/partnerDetail/partnerDetailSlice';

class ManageLog {
  constructor(selectedRegion, id) {
    this.selectedRegion = selectedRegion;
    this.id = id;
  }

  static get openSearchAttributes() {
    throw Error('Function has not been implemented yet');
  }
}

class ResellerManageLog extends ManageLog {
  get openSearchAttributes() {
    return JSON.parse(this.selectedRegion.resellerOpenSearchConfig);
  }
}

class OrganizationManageLog extends ManageLog {
  get openSearchAttributes() {
    return JSON.parse(this.selectedRegion.organizationOpenSearchConfig);
  }
}

export const resellerLogType = 'RESELLER_LOG_TYPE';
export const organizationLogType = 'ORGANIZATION_LOG_TYPE';

export default function useManageLog({ logType, id, orgOwnerId = null }) {
  const dispatch = useDispatch();
  const { partnerDetailState } = useSelector((state) => state.partnerDetail);
  const { getSelectedRegionObject } = useRegionsList();

  useEffect(() => {
    if (logType === resellerLogType) dispatch(partnerDetailAsync({ id }));
  }, []);

  const getManageLog = () => {
    if (logType === resellerLogType) return new ResellerManageLog(getSelectedRegionObject(), id);
    if (logType === organizationLogType)
      return new OrganizationManageLog(getSelectedRegionObject(), id);

    throw Error('Type is not defined yet.');
  };

  const openSearchAttributes = () => {
    try {
      return getManageLog().openSearchAttributes;
    } catch (err) {
      console.info(err);
      return {};
    }
  };

  const hasOpenSearchLog = () => {
    // the config has not been loaded yet
    if (Object.keys(openSearchAttributes()).length === 0) return false;

    // there is no true flag
    // for openSearchConfig, eg: { eup: "URL", backup: null }
    if (
      Object.keys(openSearchAttributes()).length > 0 &&
      !Object.values(openSearchAttributes()).find((i) => i !== null)
    ) {
      return false;
    }

    return true;
  };

  const description = (log) => {
    switch (log) {
      case 'api':
        return 'The log related to the API request on the NPP';
      case 'eup':
        return 'The log related to the Activity Log, API Log, and etc on the EUP';
      case 'backup':
        return 'The log related to the Backup activity';
      default:
        return null;
    }
  };

  const onOpenNewUrl = (url = '') => {
    let gsubUrl = url;

    // replace {{PARTNER_ID}}
    gsubUrl = gsubUrl.replaceAll('{{PARTNER_ID}}', id);
    // replace {{ORGANIZATION_ID}}
    gsubUrl = gsubUrl.replaceAll('{{ORGANIZATION_ID}}', id);
    // replace {{ORGANIZATION_OWNER_ID}}
    gsubUrl = gsubUrl.replaceAll('{{ORGANIZATION_OWNER_ID}}', orgOwnerId);
    // replace {{SUB_TOKEN}}
    gsubUrl = gsubUrl.replaceAll('{{SUB_TOKEN}}', partnerDetailState?.data?.subAuthenticationToken);

    openNewUrl(gsubUrl);
  };

  return {
    openSearchAttributes,
    hasOpenSearchLog,
    description,
    onOpenNewUrl,
    partnerDetailState,
    typeOptions: {
      resellerLogType,
      organizationLogType,
    },
  };
}
