import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { setUserSignedIn } from '@features/auth/authSlice';
import { dashboardPath } from '@config/Routes/WebClientRoutes';

export default function useDrawerRealmInfo() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { accounts, inProgress } = useMsal();
  const a = inProgress;
  const setSignedIn = (isAuthenticated) => {
    dispatch(setUserSignedIn(isAuthenticated));
  };

  return {
    setSignedIn,
  };
}
