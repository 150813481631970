import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import {
  QboAdvanceTable,
  QboAlert,
  QboCard,
  QboFormGroup,
  QboModal,
  QboTextField,
  QboTypography,
} from '@ui/Components';

import QboSortByButton from '@ui/Components/QboSortByButton';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import QboCardSingleList from '@ui/Components/QboCardSingleList';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import RequestModalTool from '@ui/Sections/TenantPage/RequestModalTool';
import useFeatureFlag from '@hooks/useFeatureFlag';
import TenantSizeReportConfirmation from '@ui/Sections/TenantPage/TenantSizeReportConfirmation';
import useDataService from './pageHooks/useDataService';
import useM365UsersTenantService from './pageHooks/useM365UsersTenantService';

export default function M365UsersTenantPage(props) {
  const { featureFlags, hasPermission } = useFeatureFlag();
  const { m365TenantCredentialID } = props;
  const { t } = useTranslation();
  const { getM365UsersTenantState } = useSelector((state) => state.m365UsersTenant);
  const { m365UsersTenantInformation, fetching, paginationList } = getM365UsersTenantState;
  const [filterdData, setFilterdData] = useState([]);
  const [open, setOpen] = useState(false);
  const [moduleData, setModuleData] = useState({});
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const paginationProp = useRef(pagination);

  const {
    m365UsersTenantHeaders,
    buttonStyle,
    licensedStatus,
    activeStatus,
    filterStates,
    initialFilterState,
  } = useDataService();

  const [filterDataState, setFilterDataState] = useState(filterStates);

  const [adFilter, setAdFilter] = useState(initialFilterState);

  const {
    handleFetchM365UsersTenantAsync,
    handleOnTextFiledChange,
    handleOnResetButtonClick,
    handleStateUpdate,
    handleOnHideAdvanceClick,
    handleUpdateFilters,
    matchTextHandler,
    convertResponseData,
    clicableColumnsList,
    handleOnClose,
    menuActions,
    hasMenuActions,
    requestTenantSizeReportModal,
    setRequestTenantSizeReportModal,
    selectedCredential,
    selectedTenant,
  } = useM365UsersTenantService(
    filterDataState,
    setFilterDataState,
    filterStates,
    pagination,
    adFilter,
    setAdFilter,
    initialFilterState,
    m365TenantCredentialID,
    setPagination,
    setOpen,
    setModuleData
  );

  const handleOnSearchClick = (e) => {
    e.preventDefault();
    matchTextHandler();
    handleFetchM365UsersTenantAsync(false);
    setPagination({ pageIndex: 0, pageSize: 10 });
  };

  useEffect(() => {
    if (m365UsersTenantInformation?.length === 0) {
      handleFetchM365UsersTenantAsync(false);
    }
  }, []);

  useEffect(() => {
    setFilterdData(convertResponseData(m365UsersTenantInformation));
  }, [m365UsersTenantInformation]);

  useEffect(() => {
    const paginationPropList = paginationProp.current;
    if (!isEqual(paginationPropList, pagination)) {
      paginationProp.current = pagination;
      handleFetchM365UsersTenantAsync(false);
    }
  }, [pagination]);

  return (
    <>
      <QboModal
        title="Assigned Licenses :"
        classNameStyle={
          moduleData?.assignedLicenses?.length === 0
            ? 'Qbo_module_style_on_click_with_no_records'
            : 'Qbo_module_style_on_click'
        }
        open={open}
        onClose={handleOnClose}
        showCloseIcon>
        {moduleData?.assignedLicenses?.length > 0 &&
          moduleData?.assignedLicenses?.map((license, index) => (
            <>
              {license.skuId && (
                <QboCardSingleList
                  leftContent={`SkuId ${index + 1}`}
                  rightContent={license.skuId}
                />
              )}
              {license.disabledPlans?.map(
                (plan, planIndex) =>
                  plan && (
                    <QboCardSingleList
                      key={plan}
                      leftContent={`Disabled Plan ${planIndex + 1}`}
                      rightContent={plan}
                    />
                  )
              )}
              <hr />
            </>
          ))}
        {moduleData?.assignedLicenses?.length === 0 && (
          <QboTypography style={{ fontWeight: 500 }}>No records available</QboTypography>
        )}
      </QboModal>
      <QboCard isMainWrapper>
        <QboFormGroup
          onSubmit={(e) => handleOnSearchClick(e)}
          buttonPostion="start"
          buttonStyle={buttonStyle}
          handleOnResetButton={() => {
            handleOnResetButtonClick();
          }}
          firstButtonLabel={t('partner_details_page.button.search')}
          withGenerateAndResetButton
          buttonLabel="Validate Transfer">
          <div style={{ display: 'flex' }}>
            <QboTextField
              id="fullTextSearch"
              explicitStyleClass="FormControl__width-wrapper_40"
              value={filterDataState.textFieldValue}
              isWidthSetExplicit
              placeholder="User Name/Email/UPN/M365 ID"
              inputProps={{ maxLength: 225 }}
              onChange={(e) => {
                handleOnTextFiledChange(e);
              }}
            />
          </div>

          <div style={{ marginTop: 8 }}>
            <Button
              disableRipple
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
                textTransform: 'none',
              }}
              onClick={() => {
                handleStateUpdate('advanceClick', !filterDataState.advanceClick);
                handleOnHideAdvanceClick();
              }}>
              {filterDataState.advanceClick
                ? t('partner_details_page.button.hide_advance_search')
                : t('partner_details_page.button.show_advance_search')}
            </Button>
          </div>
          {filterDataState.advanceClick && (
            <div>
              <QboSortByButton
                defaultValue="All"
                marginTopValue={2}
                marginRightValue={2}
                value={adFilter.find((item) => item.field === 'filter_licensed').value}
                buttonWidth={220}
                startAdornmentTextWidth={190}
                startAdornmentText="Has M365 License :"
                handleChange={(e) => handleUpdateFilters('filter_licensed', e.target.value)}
                menuItemList={licensedStatus}
              />
              <QboSortByButton
                defaultValue="All"
                marginTopValue={2}
                marginRightValue={2}
                value={adFilter.find((item) => item.field === 'filter_active').value}
                buttonWidth={220}
                startAdornmentTextWidth={60}
                startAdornmentText="Status :"
                handleChange={(e) => handleUpdateFilters('filter_active', e.target.value)}
                menuItemList={activeStatus}
              />
            </div>
          )}
        </QboFormGroup>
      </QboCard>

      <QboCard noPadding isMainWrapper>
        <hr />
        <QboTypography
          sx={{
            marginLeft: 2,
            marginBottom: 3,
            fontSize: 18,
            color: '#000000DE',
            fontWeight: 600,
          }}>
          M365 Users Tenant List
        </QboTypography>
        {!filterDataState.resetClicked && filterDataState.matchText ? (
          <QboTypography
            sx={{
              marginLeft: 2,
              marginTop: 2,
              marginBottom: 3,
              fontSize: '15px !important',
              color: '#42526e',
              fontWeight: 600,
              overflow: 'hidden',
              whiteSpace: 'pre-wrap',
            }}>
            {!filterDataState.resetClicked && filterDataState.matchText}
          </QboTypography>
        ) : null}

        {(filterdData.length > 0 || fetching) && (
          <QboAdvanceTable
            enableColumnClickable={!false}
            columnsClickableList={clicableColumnsList}
            isLoading={fetching}
            enableTopToolBar={false}
            headers={m365UsersTenantHeaders}
            rows={filterdData}
            rowsCount={Number(paginationList?.TotalCount || 0)}
            pagination={pagination}
            setPagination={setPagination}
            renderRowActionMenuItems={menuActions}
            enableRowActions={hasMenuActions}
          />
        )}

        {filterdData.length === 0 && !fetching && <NoStatusSection />}
      </QboCard>

      <RequestModalTool
        key={featureFlags.TenantSizeReport}
        serviceType={featureFlags.TenantSizeReport}
        selectedCredential={selectedCredential}
        selectedTenant={selectedTenant}
        showRequestModal={requestTenantSizeReportModal}
        title={t('tenant_page.tenant_size_report.title')}
        closeSetupModal={() => {
          setRequestTenantSizeReportModal(false);
        }}
        topBarElement={
          <QboAlert
            variant="outlined"
            type="info"
            style={{
              border: '1px solid #ebebeb',
              background: '#f3f3f3',
              fontSize: '10px !important',
              marginTop: '20px',
            }}>
            <QboTypography
              noWrap={false}
              style={{ fontSize: '13px', color: '#767676', whiteSpace: 'pre-line' }}>
              {t('tenant_page.tenant_size_report.information')}
            </QboTypography>
          </QboAlert>
        }>
        <TenantSizeReportConfirmation
          selectedCredential={selectedCredential}
          selectedTenant={selectedTenant}
        />
      </RequestModalTool>
    </>
  );
}

M365UsersTenantPage.propTypes = {
  m365TenantCredentialID: PropTypes.number,
};

M365UsersTenantPage.defaultProps = {
  m365TenantCredentialID: null,
};
