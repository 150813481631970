import { useState } from 'react';
import * as yup from 'yup';

const resellerIdName = 'resellerId';

const InitialForm = {
  [resellerIdName]: '',
};

export default function useFormValidation() {
  const [isValid, setIsValid] = useState(false);
  const [errors, setErrors] = useState('');
  const [formData, setFormData] = useState(InitialForm);

  const formSchema = yup.object().shape({
    [resellerIdName]: yup
      .number()
      .typeError('Reseller Id is a required field and must be a number type')
      .required(),
  });

  const validate = async (value) => {
    // eslint-disable-next-line func-names
    await formSchema.validate(value, { abortEarly: false }).catch(function (err) {
      const errorLogs = err.inner.reduce((acc, error) => {
        return {
          ...acc,
          [error.path]: error.message,
        };
      }, {});
      setErrors(errorLogs);
      return errorLogs;
    });
    const validation = await formSchema.isValid(value);
    if (validation) {
      setErrors({});
    }
    setIsValid(validation);
  };

  const validateAll = () => {
    validate(formData);
  };

  const updateFormData = (newData) => {
    setFormData((prev) => {
      return { ...prev, ...newData };
    });
  };

  const resellerIdAttributes = {
    handleChange: async (value) => {
      updateFormData({ [resellerIdName]: value });
    },
    errorMessage: errors[resellerIdName],
    value: formData[resellerIdName],
  };

  const resetForm = () => {
    setFormData(InitialForm);
    setErrors({});
    setIsValid(false);
  };

  return {
    formData,
    resellerIdAttributes,
    validateAll,
    isValid,
    resetForm,
  };
}
