import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IconButton, Button } from '@mui/material';
import { isEmpty } from 'lodash';
import RefreshIcon from '@mui/icons-material/Refresh';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  ITBox,
  QboButton,
  QboModal,
  QboCard,
  QboAlert,
  QboAdvanceTable,
  QboTypography,
  QboFormGroup,
  QboTextField,
} from '@ui/Components';
import QboSortByButton from '@ui/Components/QboSortByButton';
import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import DataIngestionFormSection from '@ui/Sections/DataIngestionPage/DataIngestionFormSection';
import { resetIngestionFormSubmit } from '@features/ingestion/ingestionSlice';
import { setPaginationState } from '@features/application/applicationSlice';
import { dataIngestionListPath } from '@config/Routes/WebClientRoutes';
import useLocalStorage from '@hooks/useLocalStorage';
import BaseSetting, { constant } from '@config/BaseSetting';
import { resetRemoveIngestionJobState } from '@features/ingestion/removeIngestionJobSlice';
import useIngestionList from './pageHooks/useIngestionList';
import useForm from './pageHooks/useForm';
import useDataService from './pageHooks/useDataService';

export default function DataIngestion() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [ingestionListData, setIngestionListData] = useState([]);
  const [regionValue] = useLocalStorage(constant.REGIONS_LIST);
  const [storageFilter, setStorageFilter] = useState('');
  const [ingestionFilter, setIngestionFilter] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [advanceClick, setAdvanceClick] = useState(false);
  const [searchFieldValue, setSearchFieldValue] = useState('');
  const [searchByID, setSearchByID] = useState('');
  const [resetClicked, setResetClicked] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [error, setError] = useState('');
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [adFilter, setAdFilter] = useState([
    { field: 'filter_status', value: '' },
    { field: 'filter_ingestionType', value: '' },
    { field: 'filter_storageType', value: '' },
  ]);

  const resetParams = [
    { field: 'filter_status', value: '' },
    { field: 'filter_ingestionType', value: '' },
    { field: 'filter_storageType', value: '' },
  ];

  const paramsObject = {
    filterName: 'Status',
    filterKey: 'status',
    filterValue: 'Failed',
  };

  const selectObject = {
    selectFilterName: 'Status',
    selectFilterKey: 'status',
    selectFilterValue: 'Job Created',
  };

  const statusObject = {
    checkboxFilterName: 'Status',
    checkboxFilterKey: 'status',
    checkboxFilterValue: 'Job Created with Failures',
  };

  const { getIngestionDataState } = useSelector((state) => state.ingestion);
  const { paginationState } = useSelector((state) => state.application);
  const { getRemoveDataIngestionJobsState } = useSelector(
    (stateList) => stateList.removeIngestionJobState
  );
  const {
    ingestionList,
    fetching,
    paginationList,
    success,
    forbiddenState,
    forbiddenErrorMessage,
    ingestionCorsErrorMessage,
  } = getIngestionDataState;

  const { fetching: removeIngestionJobFetchingState, statusCode: status } =
    getRemoveDataIngestionJobsState;

  const { storageTypeList, ingestionTypeList, statusList, buttonStyle } = useDataService();

  const jobItemsStatus = useSelector(({ ingestionsQueuedState, ingestionsInProgressState }) => ({
    getIngestionQueuedState: ingestionsQueuedState.getIngestionQueuedState,
    getIngestionInProgressState: ingestionsInProgressState.getIngestionInProgressState,
  }));

  const filterResetHandler = () => {
    setAdFilter([
      { field: 'filter_status', value: '' },
      { field: 'filter_ingestionType', value: '' },
      { field: 'filter_storageType', value: '' },
    ]);
  };

  const {
    handleFetchIngestionList,
    getDataFromResponse,
    handleOnAdvanceSearchClick,
    handleOnTextFiledChange,
    handleRemoveDataIngestionJobAsync,
  } = useIngestionList(setAdvanceClick, advanceClick, setSearchByID);
  const [noStatusFlag, setNoStatusFlag] = useState(false);

  useEffect(() => {
    if (!advanceClick) {
      filterResetHandler();
    }
  }, [advanceClick]);

  const fetchApiHandler = (searchID, paginationData, filters) => {
    handleFetchIngestionList(searchID, paginationData, filters);
  };

  const handleOnSearchClick = (e) => {
    setResetClicked(false);
    e.preventDefault();
    fetchApiHandler(
      searchByID,
      {
        pageIndex: 0,
        pageSize: 10,
      },
      adFilter
    );
  };

  const handleOnResetButtonClick = () => {
    setResetClicked(true);
    setStorageFilter('');
    setIngestionFilter('');
    setSelectedStatus('');
    setSearchByID('');
    filterResetHandler();
    fetchApiHandler(searchByID, { pageIndex: 0, pageSize: 10 }, resetParams);
  };

  useEffect(() => {
    setRowSelection({});
    getDataFromResponse(ingestionList, setIngestionListData);
  }, [ingestionList]);

  const { submitIngestionForm } = useSelector((state) => state.ingestionForm);
  const { statusCode } = submitIngestionForm;

  const fetchHandler = () => {
    if (!isEmpty(paginationState?.dataIngetionPagination)) {
      const data = paginationState?.dataIngetionPagination;
      handleFetchIngestionList(searchByID, data, adFilter);
      dispatch(setPaginationState({}));
    } else {
      handleFetchIngestionList(searchByID, pagination, adFilter);
    }
  };

  useEffect(() => {
    if (!fetching) {
      fetchHandler();
    }
  }, [pagination.pageIndex, pagination.pageSize]);

  const handelOnRefresh = () => {
    fetchHandler();
  };

  useEffect(() => {
    if (status === 200) {
      dispatch(resetRemoveIngestionJobState());
      fetchHandler();
    }
    if (status === 404) {
      dispatch(resetRemoveIngestionJobState());
      setOpen(true);
    }
  }, [status]);

  const {
    organizationOwnerIdAttributes,
    ingestionTypeAttributes,
    catchAllEmailAccountAttributes,
    storageTypeAttributes,
    mappingFileAttributes,
    ftpUrlAttributes,
    ftpUserNameAttributes,
    ftpPasswordAttributes,
    zipFilePasswordAttributes,
    resetForm,
    supportTicketAttributes,
    descriptionAttributes,
    escalationTicketAttributes,
    s3bucketnameAttributes,
    s3KeyAttributes,
    folderAttributes,
    retainFolderStructureAttributes,
    journalIdAttributes,
    s3StorageRegionAttributes,
    onSubmit,
    formData,
  } = useForm();

  const handleUpdateFilters = (label, val) => {
    const newList = adFilter.map((ele) => {
      if (ele.field === label) {
        return { ...ele, value: val };
      }
      return ele;
    });
    setAdFilter([...newList]);
  };

  useEffect(() => {
    if (statusCode === 200 || statusCode === 403) {
      resetForm();
    }
  }, [statusCode]);

  useEffect(() => {
    if (!success || ingestionListData?.length === 0) {
      setNoStatusFlag(true);
    } else if (success && ingestionListData?.length !== 0) {
      setNoStatusFlag(false);
    }
  }, [success, ingestionListData]);

  const onClickRowFirstIcon = (row) => {
    dispatch(
      setPaginationState({ pageIndex: pagination.pageIndex, pageSize: pagination.pageSize })
    );
    navigate(dataIngestionListPath, { state: { row: row?.id } });
  };

  useEffect(() => {
    if (!isEmpty(paginationState?.dataIngetionPagination)) {
      setPagination(paginationState?.dataIngetionPagination);
    }
  }, [pagination]);

  const iconData = [
    {
      icon: <OpenInNewIcon />,
      tooltipTitle: 'Ingestion List Page',
      style: { color: 'rgb(0, 3, 9)' },
      onClick: onClickRowFirstIcon,
      disableOnStatus: false,
      tooltipPlacement: 'left',
    },
  ];

  const enableEditing = {
    isEdit: true,
  };

  const handleOnRemoveJobs = () => {
    if (isEmpty(rowSelection)) {
      setError('No items selected');
      setOpen(true);
    }

    if (!removeIngestionJobFetchingState && !isEmpty(rowSelection)) {
      handleRemoveDataIngestionJobAsync({
        selectedJobs: Object.keys(rowSelection),
      });
    }
  };

  return (
    <>
      <HeaderPageBlock>{t('data_ingestion_page.header.text')}</HeaderPageBlock>

      {ingestionCorsErrorMessage && (
        <QboAlert type="error" style={{ fontSize: '1.15em' }}>
          {ingestionCorsErrorMessage}
        </QboAlert>
      )}

      {!fetching && (
        <>
          {/* <QboCard
            style={{
              display: 'flex',
              alignItems: 'center',
              height: 120,
              marginBottom: 10,
            }}>
            {[
              { label: 'Queued', state: jobItemsStatus.getIngestionQueuedState },
              { label: 'In Progress', state: jobItemsStatus.getIngestionInProgressState },
            ].map(({ label, state }) => (
              <div
                key={label}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginLeft: 75,
                  marginRight: 75,
                }}>
                <div style={{ padding: 10, fontSize: 20 }}>{label}</div>
                <div style={{ padding: 10, fontSize: 50 }}>
                  {state?.paginationList?.TotalCount || 0}
                </div>
              </div>
            ))}
          </QboCard> */}
          <QboCard isMainWrapper>
            <QboFormGroup
              onSubmit={(e) => handleOnSearchClick(e)}
              buttonPostion="start"
              buttonStyle={buttonStyle}
              handleOnResetButton={handleOnResetButtonClick}
              firstButtonLabel={t('partner_details_page.button.search')}
              withGenerateAndResetButton
              buttonLabel="Validate Transfer">
              <QboTextField
                id="fullTextSearch"
                value={searchByID}
                isWidthSetExplicit
                placeholder="Job Id/Owner Id"
                inputProps={{ maxLength: 225 }}
                onChange={(e) => {
                  handleOnTextFiledChange(e);
                }}
              />
              <div style={{ marginTop: 8 }}>
                <Button
                  disableRipple
                  style={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    textTransform: 'none',
                  }}
                  onClick={() => {
                    handleOnAdvanceSearchClick();
                  }}>
                  {advanceClick
                    ? t('partner_details_page.button.hide_advance_search')
                    : t('partner_details_page.button.show_advance_search')}
                </Button>
              </div>
              {advanceClick && (
                <div>
                  <QboSortByButton
                    defaultValue="All"
                    marginTopValue={2}
                    marginRightValue={2}
                    value={selectedStatus}
                    buttonWidth={220}
                    startAdornmentTextWidth={70}
                    startAdornmentText="Status:"
                    handleChange={(e) => {
                      setSelectedStatus(e.target.value);
                      handleUpdateFilters('filter_status', e.target.value);
                    }}
                    menuItemList={statusList}
                  />
                  <QboSortByButton
                    defaultValue="All"
                    marginRightValue={2}
                    marginTopValue={2}
                    value={storageFilter}
                    buttonWidth={220}
                    startAdornmentTextWidth={140}
                    startAdornmentText="Storage Type:"
                    handleChange={(e) => {
                      setStorageFilter(e.target.value);
                      handleUpdateFilters('filter_storageType', e.target.value);
                    }}
                    menuItemList={storageTypeList}
                  />
                  <QboSortByButton
                    defaultValue="All"
                    marginRightValue={2}
                    marginTopValue={2}
                    value={ingestionFilter}
                    buttonWidth={220}
                    startAdornmentTextWidth={160}
                    startAdornmentText="Ingestion Type:"
                    handleChange={(e) => {
                      setIngestionFilter(e.target.value);
                      handleUpdateFilters('filter_ingestionType', e.target.value);
                    }}
                    menuItemList={ingestionTypeList}
                  />
                </div>
              )}
            </QboFormGroup>
          </QboCard>
          <QboCard>
            {!forbiddenState && (
              <ITBox className="SystemStatusSection__wrapper" direction="column">
                <ITBox className="SystemStatusSection__create-button">
                  <IconButton
                    sx={{ position: 'relative', right: '0.7rem' }}
                    onClick={handelOnRefresh}>
                    <RefreshIcon sx={{ width: '2rem', height: '2rem' }} />
                  </IconButton>
                  &nbsp;
                  <QboButton variant="contained" onClick={() => setShowModal(true)}>
                    {t('mailbox_change.new_request')}
                  </QboButton>
                  &nbsp;&nbsp;
                  <QboButton variant="contained" onClick={handleOnRemoveJobs}>
                    {t('mailbox_change.remove_ingestion_job')}
                  </QboButton>
                </ITBox>
              </ITBox>
            )}

            <QboCard noPadding isMainWrapper>
              {((success && ingestionListData?.length > 0 && !forbiddenState && regionValue) ||
                fetching) && (
                <QboAdvanceTable
                  filterKey={paramsObject.filterKey}
                  filterValue={paramsObject.filterValue}
                  isCheckBoxFilterEnable={!false}
                  rowSelection={rowSelection}
                  setRowSelection={setRowSelection}
                  enableRowSelection={!false}
                  enableSorting
                  iconData={iconData}
                  rows={ingestionListData}
                  headers={BaseSetting.dataIngestionListHeaders}
                  isLoading={fetching}
                  rowsCount={paginationList?.TotalCount}
                  pagination={pagination}
                  setPagination={setPagination}
                  enableEditing={enableEditing}
                  frozenColumns={[
                    'mrt-row-actions',
                    'mrt-row-select',
                    'ingestion-id',
                    'description',
                    'account-id',
                  ]}
                  selectFilterKey={selectObject.selectFilterKey}
                  selectFilterValue={selectObject.selectFilterValue}
                  checkboxFilterKey={statusObject.checkboxFilterKey}
                  checkboxFilterValue={statusObject.checkboxFilterValue}
                />
              )}
              {(noStatusFlag || !regionValue || forbiddenState) && !fetching && (
                <NoStatusSection forbiddenErrorMessage={forbiddenErrorMessage} />
              )}
            </QboCard>
            <QboModal
              onClose={() => {
                setShowModal(false);
                dispatch(resetIngestionFormSubmit());
                resetForm();
                fetchHandler();
              }}
              open={showModal}
              title={t('data_ingestion_page.modal.confirmation.title_text')}
              showCloseIcon
              classNameStyle="DataIngestion_modal"
              preventOutsideClick>
              <DataIngestionFormSection
                organizationOwnerIdAttributes={organizationOwnerIdAttributes}
                catchAllEmailAccountAttributes={catchAllEmailAccountAttributes}
                ingestionTypeAttributes={ingestionTypeAttributes}
                storageTypeAttributes={storageTypeAttributes}
                mappingFileAttributes={mappingFileAttributes}
                ftpUrlAttributes={ftpUrlAttributes}
                ftpUserNameAttributes={ftpUserNameAttributes}
                ftpPasswordAttributes={ftpPasswordAttributes}
                s3bucketnameAttributes={s3bucketnameAttributes}
                s3KeyAttributes={s3KeyAttributes}
                folderAttributes={folderAttributes}
                supportTicketAttributes={supportTicketAttributes}
                escalationTicketAttributes={escalationTicketAttributes}
                descriptionAttributes={descriptionAttributes}
                zipFilePasswordAttributes={zipFilePasswordAttributes}
                retainFolderStructureAttributes={retainFolderStructureAttributes}
                s3StorageRegionAttributes={s3StorageRegionAttributes}
                onSubmit={onSubmit}
                formData={formData}
              />
            </QboModal>
          </QboCard>
        </>
      )}
    </>
  );
}
