import useValidation from './useValidation';

function useForm() {
  const {
    supportTicketAttributes,
    escalationTicketAttributes,
    descriptionAttributes,
    organizationOwnerIdAttributes,
    credentialIdAttributes,
    resetForm,
    isValid,
    validateAll,
  } = useValidation();

  const attributes = {
    supportTicketAttributes,
    escalationTicketAttributes,
    descriptionAttributes,
    organizationOwnerIdAttributes,
    credentialIdAttributes,
  };

  const onValidateData = (e) => {
    e.preventDefault();
    validateAll();
  };

  return {
    attributes,
    isValid,
    resetForm,
    onValidateData,
  };
}

export default useForm;
