import { createSlice } from '@reduxjs/toolkit';

import {
  getOrganizationFilterListAsync,
  getOrganizationFilterListExtraReducers,
} from './serviceActions/getOrganizationFilterListAsync';

const initialState = {
  getOrganizationFilterListState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    organizationFilterList: [],
  },
};

const organizationFilterListSlice = createSlice({
  name: 'OrganizationFilterList',
  initialState,
  reducers: {
    resetOrganizationFilterListState(state) {
      state.getOrganizationFilterListState = initialState.getOrganizationFilterListState;
    },
  },
  extraReducers: {
    ...getOrganizationFilterListExtraReducers,
  },
});

export { getOrganizationFilterListAsync };
export const { resetOrganizationFilterListState, resetOrganizationFilterListStatusCodeState } =
  organizationFilterListSlice.actions;
export default organizationFilterListSlice.reducer;
