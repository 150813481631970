import { createSlice } from '@reduxjs/toolkit';
import {
  getIngestionFailedEmlItemAsync,
  getIngestionFailedEmlItemExtraReducers,
} from './serviceActions/fetchIngestionFailedEmlAsync';

const initialState = {
  getIngestionFailedEmlItemState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    errorMessage: null,
    forbiddenState: false,
    forbiddenErrorMessage: null,
    ingestionFailedEmlItemCorsErrorMessage: null,
    ingestionFailedEmlItem: [],
    paginationList: {},
  },
};

const ingestionFailedEmlSlice = createSlice({
  name: 'IngestionFailedEml',
  initialState,
  reducers: {
    resetIngestionFailedEmlState(state) {
      state.getIngestionFailedEmlItemState = initialState.getIngestionFailedEmlItemState;
    },
  },
  extraReducers: {
    ...getIngestionFailedEmlItemExtraReducers,
  },
});

export { getIngestionFailedEmlItemAsync };
export const { resetIngestionFailedEmlState } = ingestionFailedEmlSlice.actions;
export default ingestionFailedEmlSlice.reducer;
