import {
  hexTenantDetailPath,
  organizationInformationPath,
  resellersListPath,
} from '@config/Routes/WebClientRoutes';

export default function useDataService() {
  const hexTenantHeaders = [
    {
      label: 'ID',
      columnName: 'id',
      sx: { paddingLeft: 2 },
      size: 50,
    },
    {
      label: 'GUID',
      columnName: 'guid',
      sx: { paddingLeft: 2 },
    },
    {
      label: 'Email',
      columnName: 'encryptedEmail',
      sx: { paddingLeft: 2 },
    },
    {
      label: 'Partner Name',
      columnName: 'tenantPartnerName',
      sx: { paddingLeft: 2 },
    },
    {
      label: 'Connection Settings',
      columnName: 'connectionSettings',
      sx: { paddingLeft: 2 },
    },
    {
      label: 'Organization Name',
      columnName: 'orgName',
      sx: { paddingLeft: 2 },
    },
    {
      label: 'Parent Name',
      columnName: 'tenantParent',
      sx: { paddingLeft: 2 },
    },
    {
      label: 'Powershell Username',
      columnName: 'encryptedPowershellUsername',
      sx: { paddingLeft: 2 },
    },
    {
      label: 'Powershell Connection URI',
      columnName: 'powershellConnectionUri',
      sx: { paddingLeft: 2 },
    },
    {
      label: 'Authentication Mecahism',
      columnName: 'authenticationMechanism',
      sx: { paddingLeft: 2 },
      size: 100,
    },
    {
      label: 'SSL',
      columnName: 'enableSsl',
      sx: { paddingLeft: 2 },
      size: 80,
    },
    {
      label: 'Created At',
      columnName: 'createdAt',
      sx: { paddingLeft: 2 },
    },
    {
      label: 'Last Updated At',
      columnName: 'updatedAt',
      sx: { paddingLeft: 2 },
    },
    {
      label: 'Organization Owner ID',
      columnName: 'organizationOwnerId',
      sx: { paddingLeft: 2 },
      size: 80,
    },
    {
      label: 'Exchange ORG ID',
      columnName: 'exchangeOrgId',
      sx: { paddingLeft: 2 },
      size: 100,
    },
    {
      label: 'Dist Group Name',
      columnName: 'distGroupName',
      sx: { paddingLeft: 2 },
      size: 100,
    },
    {
      label: 'Journal Rule Name',
      columnName: 'journalRuleName',
      sx: { paddingLeft: 2 },
      size: 100,
    },
    {
      label: 'Initial Setup Status',
      columnName: 'status',
      sx: { paddingLeft: 2 },
      size: 140,
    },
  ];
  const buttonStyle = {
    height: 36,
    backgroundColor: '#a5a5a5',
    textDecoration: 'none',
    color: 'black',
    '&:hover': {
      backgroundColor: '#a5a5a5',
    },
    textTransform: 'unset',
    fontSize: 14,
  };
  const sslData = [
    { id: 1, value: 'true', title: 'True' },
    { id: 2, value: 'false', title: 'False' },
  ];
  const initialSetupStatus = [
    { id: 1, value: 'Not Ready', title: 'Not Ready' },
    { id: 2, value: 'In Progress', title: 'In Progress' },
    { id: 3, value: 'Completed', title: 'Completed' },
    { id: 4, value: 'Error', title: 'Error' },
  ];
  const sortColumnNames = [{ id: 1, value: 'id', title: 'Credential ID' }];

  const clickableColumn = [
    {
      id: 1,
      column: 'guid',
      path: hexTenantDetailPath,
    },
    {
      id: 2,
      column: 'tenantPartnerName',
      path: resellersListPath,
    },
    {
      id: 3,
      column: 'orgName',
      path: organizationInformationPath,
      tab: 0,
    },
    {
      id: 4,
      column: 'tenantParent',
      path: resellersListPath,
    },
  ];

  const initialState = {
    searchTerm: '',
    searchTermOrganization: '',
    searchTermParent: '',
    searchState: '',
    searchStateOrganization: '',
    searchStateParent: '',
    forceUpdate: false,
    forceUpdateOrganization: false,
    forceUpdateParent: false,
    selectedPartner: '',
    selectedOrganization: '',
    selectedParent: '',
  };

  const filterStates = {
    advanceClick: false,
    textFieldValue: '',
    searchFieldValueText: '',
    searchFieldValueID: '',
    selectedSortColumn: '',
    matchText: '',
    resetClicked: false,
  };

  const initialFilterState = [
    { field: 'filter_enable_ssl', value: '' },
    { field: 'filter_selected_partner', value: '' },
    { field: 'filter_selected_organization', value: '' },
    { field: 'filter_selected_parent', value: '' },
  ];

  return {
    hexTenantHeaders,
    buttonStyle,
    sslData,
    initialSetupStatus,
    sortColumnNames,
    clickableColumn,
    initialState,
    filterStates,
    initialFilterState,
  };
}
