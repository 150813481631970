import { createAsyncThunk } from '@reduxjs/toolkit';
import { getM365TenantAPI } from '@services/WebApiService';

const getM365TenantAsync = createAsyncThunk('M365Tenant/getM365Tenant', async (paramsList) => {
  try {
    const data = await getM365TenantAPI(paramsList);
    return data;
  } catch (error) {
    return { error: error.response };
  }
});

const { pending, fulfilled, rejected } = getM365TenantAsync;

const getM365TenantReducers = {
  [pending]: (state) => {
    state.getM365TenantState.fetching = true;
  },
  [rejected]: (state) => {
    state.getM365TenantState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.getM365TenantState.fetching = false;

    if (action?.payload?.status === 403) {
      state.getM365TenantState.forbiddenState = true;
      state.getM365TenantState.forbiddenErrorMessage = {
        heading: 'Forbidden Error',
        caption: 'You are not authorize to access this resource',
      };
    }

    if (!action?.payload) {
      state.getM365TenantState.m365TenantCorsErrorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.data?.data) {
      state.getM365TenantState.m365TenantInformation = action?.payload?.data?.data;
      state.getM365TenantState.paginationList = JSON.parse(action?.payload?.data?.pagination);
      state.getM365TenantState.success = true;
      state.getM365TenantState.statusCode = action?.payload?.data['status-code'];
    }

    if (action?.payload?.error) {
      state.getM365TenantState.error = action.payload.error;
    }
  },
};

export { getM365TenantAsync, getM365TenantReducers };
