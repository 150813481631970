import { createAsyncThunk } from '@reduxjs/toolkit';
import { getBulkCreateOrganizationItemsApi } from '@services/WebApiService';

const getBulkCreateOrganizationItemsAsync = createAsyncThunk(
  'GetBulkCreateOrganization/getBulkCreateOrganizationItems',
  async (paramsList) => {
    try {
      const data = await getBulkCreateOrganizationItemsApi(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = getBulkCreateOrganizationItemsAsync;

const getBulkCreateOrganizationItemsExtraReducers = {
  [pending]: (state) => {
    state.getBulkCreateOrganizationItemsState.fetching = true;
  },
  [rejected]: (state) => {
    state.getBulkCreateOrganizationItemsState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.getBulkCreateOrganizationItemsState.fetching = false;

    if (action?.payload && !action?.payload?.error) {
      state.getBulkCreateOrganizationItemsState.statusCode = action?.payload?.status;
      state.getBulkCreateOrganizationItemsState.errorMessage = action?.payload?.data?.message;
    }

    if (action?.payload?.status === 403) {
      state.getBulkCreateOrganizationItemsState.forbiddenErrorMessage =
        'You are not authorize to access this resource';
    }

    if (!action?.payload?.data && !action.payload?.error) {
      state.getBulkCreateOrganizationItemsState.corsErrorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.data?.data) {
      state.getBulkCreateOrganizationItemsState.forbiddenState = false;
      state.getBulkCreateOrganizationItemsState.forbiddenErrorMessage = {};
      state.getBulkCreateOrganizationItemsState.statusCode = action.payload?.status;
      state.getBulkCreateOrganizationItemsState.list = action?.payload?.data?.data;

      if (action?.payload?.data?.pagination) {
        state.getBulkCreateOrganizationItemsState.pagination = JSON.parse(
          action?.payload?.data?.pagination
        );
      }
    }
  },
};

export { getBulkCreateOrganizationItemsAsync, getBulkCreateOrganizationItemsExtraReducers };
