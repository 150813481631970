import { useDispatch } from 'react-redux';

import useLocalStorage from '@hooks/useLocalStorage';
import { constant } from '@config/BaseSetting';
import { getSelectedResellerConfigsAsync } from '@features/resellerConfigs/getSelectedResellerConfigsSlice';
import { getResellerNotificationsConfigAsync } from '@features/resellerConfigs/getResellerNotificationsConfigSlice';

export default function useResellerService() {
  const [accessToken] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const dispatch = useDispatch();
  const handleGetSelectedResellerConfigsAsync = (resellerID) => {
    dispatch(
      getSelectedResellerConfigsAsync({
        accessToken,
        reset: true,
        reseller: resellerID,
      })
    );
  };

  const handleGetResellerNotificationsConfigAsync = (resellerID) => {
    dispatch(
      getResellerNotificationsConfigAsync({
        accessToken,
        reset: true,
        reseller: resellerID,
      })
    );
  };

  return { handleGetSelectedResellerConfigsAsync, handleGetResellerNotificationsConfigAsync };
}
