import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Drawer as MuiDrawer, IconButton, Divider } from '@mui/material';
import {
  KeyboardDoubleArrowLeft as KeyboardDoubleArrowLeftIcon,
  KeyboardDoubleArrowRight as KeyboardDoubleArrowRightIcon,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import DrawerMenuBlock from '@ui/Blocks/Shared/DrawerBlocks/MenuBlock';
import { QboTypography } from '@ui/Components';
import useLocalStorage from '@hooks/useLocalStorage';
import { constant } from '@config/BaseSetting';

export default function DrawerSection({
  drawerWidth,
  drawerOpen,
  handleDrawerOpen,
  handleDrawerClose,
}) {
  const { t } = useTranslation();
  const [activeMenu, setActivePageMenu] = useLocalStorage(constant.MENUS_FLAG_STATE);
  const [flagState, setFlagState] = useState({
    partnerManagement: null,
    orgManagement: null,
    partnersManagement: null,
    tenantManagement: null,
    transferManagement: null,
    subscriptionManagement: null,
    purgeManagement: null,
    reportsManagement: null,
    toolsManagement: null,
    billingManagement: null,
    dataIngestionManagement: null,
    dashboardManagement: null,
    systemStatusManagement: null,
  });

  const allNull = Object.values(flagState ?? {}).every((value) => value === null);
  if (!allNull) {
    setActivePageMenu(JSON.stringify(flagState));
  }

  const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });

  const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    })
  );

  return (
    <div className={`Drawer__default Drawer__default-${drawerOpen ? 'open' : 'close'}`}>
      <Drawer variant="permanent" open={drawerOpen}>
        <DrawerHeader className="Drawer__header">
          <IconButton
            className="Drawer__collapse-icon"
            onClick={drawerOpen ? handleDrawerClose : handleDrawerOpen}>
            {drawerOpen ? (
              <KeyboardDoubleArrowLeftIcon className="Icon__default" />
            ) : (
              <KeyboardDoubleArrowRightIcon className="Icon__default" />
            )}
          </IconButton>
          {drawerOpen && (
            <QboTypography className="Drawer__collapse-title" sx={{ color: '#009fdf !important' }}>
              {t('drawer.header.title_internal')}{' '}
              <span style={{ color: '#002855' }}>&nbsp;{t('drawer.header.title_tool')}</span>
            </QboTypography>
          )}
        </DrawerHeader>
        <Divider />
        <DrawerMenuBlock
          drawerOpen={drawerOpen}
          flagState={flagState}
          setFlagState={setFlagState}
        />
      </Drawer>
    </div>
  );
}

DrawerSection.propTypes = {
  drawerOpen: PropTypes.bool,
  handleDrawerOpen: PropTypes.func,
  handleDrawerClose: PropTypes.func,
  drawerWidth: PropTypes.number,
};

DrawerSection.defaultProps = {
  drawerOpen: true,
  handleDrawerOpen: () => {},
  handleDrawerClose: () => {},
  drawerWidth: 240,
};
