import { createAsyncThunk } from '@reduxjs/toolkit';
import { createTenantAuthErrorsAPI } from '@services/WebApiService';

const createTenantAuthErrorsAsync = createAsyncThunk(
  'TenantAuthErrors/createTenantAuthErrors',
  async (paramsList) => {
    try {
      const data = await createTenantAuthErrorsAPI(paramsList);
      return data;
    } catch (error) {
      return error.response;
    }
  }
);

const { pending, fulfilled, rejected } = createTenantAuthErrorsAsync;

const createTenantAuthErrorsExtraReducers = {
  [pending]: (state) => {
    state.tenantAuthErrorsState.fetching = true;
  },
  [rejected]: (state) => {
    state.tenantAuthErrorsState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.tenantAuthErrorsState.fetching = false;

    if (action?.payload?.status) {
      state.tenantAuthErrorsState.statusCode = action?.payload?.status;
    }

    if (action?.payload?.data) {
      state.tenantAuthErrorsState.statusCode = action?.payload?.status;
      state.tenantAuthErrorsState.tenantAuthErrorsFormList = action?.payload?.data?.data;
      state.tenantAuthErrorsState.message = action?.payload?.data?.message;
    }

    if (action?.payload?.status === 403) {
      state.tenantAuthErrorsState.message = 'You are not authorize to access this resource';
    }

    if (!action?.payload) {
      state.tenantAuthErrorsState.message = 'Something went wrong while processing your request.';
    }

    if (action?.payload?.data?.title) {
      state.tenantAuthErrorsState.statusCode = action?.payload?.data.status;
      state.tenantAuthErrorsState.message = action?.payload?.data?.title;
    }

    if (action?.payload?.error) {
      state.tenantAuthErrorsState.error = action.payload.error;
    } else {
      state.tenantAuthErrorsState.success = true;
    }
  },
};

export { createTenantAuthErrorsAsync, createTenantAuthErrorsExtraReducers };
