import React from 'react';
import { useTranslation } from 'react-i18next';

import StepperBlock from '@ui/Blocks/Shared/StepperBlock';
import { QboModal, QboAlert, QboCard, QboAdvanceTable, QboTypography } from '@ui/Components';
import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import NewRequestButtonSection from '@ui/Sections/Shared/NewRequestButtonSection';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import RequestFormSection from '@ui/Sections/PurgeM365TenantPage/RequestFormSection';
import RequestConfirmSection from '@ui/Sections/PurgeM365TenantPage/RequestConfirmSection';

import usePurgeM365Tenant from './pageHooks/usePurgeM365Tenant';

export default function PurgeM365TenantPage() {
  // Hooks
  const { t } = useTranslation();

  // Related to Page Hooks
  const {
    getPurgeM365TenantsState,
    purgeM365TenantState,
    tableColumns,
    form,
    closeSetupModal,
    openSetupModal,
    showRequestModal,
    stepper,
    onSubmitForm,
    onStepBack,
    pagination,
    onDisplayMessageModal,
    fetchListAsync,
    m365TenantDetailState,
    onValidateData,
    detailMessageDialog,
  } = usePurgeM365Tenant();

  const {
    purgeM365TenantsCorsErrorMessage,
    fetching,
    status,
    purgeM365TenantsList,
    forbiddenState,
    forbiddenErrorMessage,
  } = getPurgeM365TenantsState;

  const { status: createStatusResponse, message: createMessage } = purgeM365TenantState;

  return (
    <>
      {/* Detail Message */}
      <QboModal
        classNameStyle="Qbo_model_error_message"
        title="Detail Message"
        showCloseIcon
        open={detailMessageDialog.showMesageDialog}
        onClose={() => detailMessageDialog.setShowMesageDialog(false)}>
        <QboTypography wrap={!false}>{detailMessageDialog.messageDialog}</QboTypography>
      </QboModal>

      {/* Header */}
      <HeaderPageBlock>{t('purge_m365_tenant.header.text')}</HeaderPageBlock>

      {/* Alert */}
      {(purgeM365TenantsCorsErrorMessage || createStatusResponse === 200) && (
        <QboAlert
          type={createStatusResponse === 200 ? 'success' : 'error'}
          style={{ fontSize: '1.15em' }}>
          {purgeM365TenantsCorsErrorMessage ||
            (createStatusResponse === 200 && 'Successfully submitted')}
        </QboAlert>
      )}

      {/* Main Section */}
      <QboCard>
        {/* New Request Button Section */}
        <NewRequestButtonSection
          title={t('purge_m365_tenant.new_request')}
          show={!forbiddenState}
          onClickRefresh={fetchListAsync}
          onClickNew={openSetupModal}
        />

        {/* Entries Section */}
        <QboCard noPadding isMainWrapper>
          <>
            {(purgeM365TenantsList.length > 0 || fetching) && (
              <QboAdvanceTable
                enableTopToolBar={false}
                enableSorting={false}
                rows={purgeM365TenantsList}
                isLoading={fetching}
                pagination={pagination.paginations}
                setPagination={pagination.setPaginations}
                headers={tableColumns}
                rowsCount={pagination?.paginationState?.TotalCount}
                isEdit={false}
                enableColumnClickable={!false}
              />
            )}
            {purgeM365TenantsList.length === 0 && !fetching && (
              <NoStatusSection forbiddenErrorMessage={forbiddenErrorMessage} />
            )}
          </>
        </QboCard>
      </QboCard>

      {/* New Requestion Section */}
      <QboModal
        onClose={closeSetupModal}
        open={showRequestModal}
        title={t('purge_m365_tenant.modal.title_text')}
        showCloseIcon
        preventOutsideClick>
        <StepperBlock
          steps={stepper.steps}
          icons={stepper.stepIcons}
          hideLabel
          activeStepAlt={stepper.activeStep}
          isStepOptionalAlt={stepper.isStepOptional}
          isStepSkippedAlt={stepper.isStepOptional}>
          <RequestFormSection
            supportTicketAttributes={form.attributes.supportTicketAttributes}
            escalationTicketAttributes={form.attributes.escalationTicketAttributes}
            organizationOwnerIdAttributes={form.attributes.organizationOwnerIdAttributes}
            credentialIdAttributes={form.attributes.credentialIdAttributes}
            descriptionAttributes={form.attributes.descriptionAttributes}
            onSubmit={onValidateData}
            resetForm={form.resetForm}
            formData={form.attributes}
            isValid={form.isValid}
            detailState={m365TenantDetailState}
            requestState={purgeM365TenantState}
          />
          <RequestConfirmSection
            onSubmit={onSubmitForm}
            formData={form.attributes}
            onBack={onStepBack}
            onCloseModal={closeSetupModal}
            detailState={m365TenantDetailState}
            requestState={purgeM365TenantState}
          />
        </StepperBlock>
      </QboModal>
    </>
  );
}
