import { ITBox, QboAdvanceTable, QboCard, QboTypography } from '@ui/Components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { constant } from '@config/BaseSetting';
import useLocalStorage from '@hooks/useLocalStorage';
import { useDispatch, useSelector } from 'react-redux';
import { getBulkCreateOrganizationItemsAsync } from '@features/bulkCreateOrganization/getBulkCreateOrganizationItemsSlice';
import { BULK_CREATE_ORGANIZATION_ITEMS_HEADERS } from './constants/toolsRequestHistoryConstants';

const initialPagination = { pageIndex: 0, pageSize: 10 };

export default function BulkCreateOrganizationItems({ jobId }) {
  const { t } = useTranslation();
  const [accessToken] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const [pagination, setPagination] = useState(initialPagination);
  const dispatch = useDispatch();
  const { getBulkCreateOrganizationItemsState } = useSelector(
    (state) => state.getBulkCreateOrganizationItems
  );

  useEffect(() => {
    dispatch(
      getBulkCreateOrganizationItemsAsync({
        accessToken,
        reset: true,
        pageNumber: pagination.pageIndex + 1,
        pageSize: pagination.pageSize,
        id: jobId,
      })
    );
  }, [pagination, jobId]);

  return (
    <QboCard>
      <ITBox className="SystemStatusSection__wrapper" direction="row">
        <QboTypography style={{ margin: 16, fontSize: '2rem' }}>
          {t('tools_request_status.bulk_create_organization.title', { jobId })}
        </QboTypography>
      </ITBox>

      <QboAdvanceTable
        isLoading={getBulkCreateOrganizationItemsState.fetching}
        enableTopToolBar={false}
        headers={BULK_CREATE_ORGANIZATION_ITEMS_HEADERS}
        rows={getBulkCreateOrganizationItemsState.list}
        pagination={pagination}
        setPagination={setPagination}
        rowsCount={getBulkCreateOrganizationItemsState.pagination?.TotalCount}
      />
    </QboCard>
  );
}

BulkCreateOrganizationItems.propTypes = {
  jobId: PropTypes.number,
};

BulkCreateOrganizationItems.defaultProps = {
  jobId: null,
};
