import { createAsyncThunk } from '@reduxjs/toolkit';
import { constant } from '@config/BaseSetting';
import useLocalStorage from '@hooks/useLocalStorage';
import { getRegionsAPI } from '@services/WebApiService';

const getRegionsListAsync = createAsyncThunk('Regions/getRegions', async (paramsList) => {
  try {
    const data = await getRegionsAPI(paramsList);
    return data;
  } catch (error) {
    return error;
  }
});

const [value, setValues, deleteValue] = useLocalStorage(constant.REGIONS_LIST);

const { pending, fulfilled, rejected } = getRegionsListAsync;

const getRegionsExtraReducers = {
  [pending]: (state) => {
    state.getRegions.fetching = true;
  },
  [rejected]: (state) => {
    state.getRegions.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.getRegions.fetching = false;

    if (action?.payload?.code) {
      state.getRegions.regionErrorMessage = 'Internal Server Error';
      state.getRegions.error = action.payload.code;
    } else if (action?.payload?.data?.data) {
      state.getRegions.regionErrorMessage = null;
      setValues(JSON.stringify(action?.payload?.data?.data));
      state.getRegions.data = action?.payload?.data?.data;
    } else {
      state.getRegions.success = true;
    }
  },
};

export { getRegionsListAsync, getRegionsExtraReducers };

export default getRegionsListAsync;
