import { createSlice } from '@reduxjs/toolkit';
import { mailboxChangeReducers, mailBoxChangeAsync } from './serviceActions/mailBoxChangeAsync';

const initialState = {
  mailChangeState: {
    fetching: false,
    success: false,
    error: null,
    errorMessage: null,
    statusCode: null,
  },
};

const mailChangeSlice = createSlice({
  name: 'MailChange',
  initialState,
  reducers: {
    resetMailChangeStatus(state) {
      state.mailChangeState = initialState.mailChangeState;
    },
    resetStatusCodeMailChange(state) {
      state.mailChangeState.stausCode = null;
      state.mailChangeState.errorMessage = null;
    },
  },
  extraReducers: {
    ...mailboxChangeReducers,
  },
});

export { mailBoxChangeAsync };
export const { resetMailChangeStatus, resetStatusCodeMailChange } = mailChangeSlice.actions;
export default mailChangeSlice.reducer;
