import React, { useEffect, useState } from 'react';
import { QboCard } from '@ui/Components';
import { useSelector } from 'react-redux';
import { Skeleton } from '@mui/material';
import PropTypes from 'prop-types';
import QboTitleWithCollapsableButton from '@ui/Components/QboTitleWithCollapsableButton';
import QboCardSingleList from '@ui/Components/QboCardSingleList';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import useOrganizationInformationService from './pageHooks/useOrganizationInformationService';
import useDataService from './pageHooks/useDataService';

export default function OrganizationInformationPage(props) {
  const { resellerID } = props;
  const { getOrganizationInformationState } = useSelector((state) => state.organizationInformation);
  const { organizationInformation, fetching } = getOrganizationInformationState;
  const { handleFetchOrganizationInformationAsync } = useOrganizationInformationService();
  const { organizationDetails } = useDataService();

  useEffect(() => {
    if (organizationInformation.length === 0) {
      handleFetchOrganizationInformationAsync(resellerID);
    }
  }, []);

  const valueHandler = (label, properties, valType) => {
    const responseData = organizationInformation[0];
    let value = ' ';

    if (responseData && Object.keys(responseData).length > 0) {
      if (label === 'API Authentication Token') {
        value = responseData[properties]?.substring(0, 10) || '-';
      } else {
        value = responseData[properties] || '-';
      }
    }

    return value;
  };

  const renderLoading = () => {
    return (
      <div style={{ display: 'contents' }}>
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="70%" />
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="70%" />
      </div>
    );
  };

  const componentHandler = () => {
    let content = null;
    if (fetching) {
      content = renderLoading();
    } else if (organizationInformation[0] && Object.keys(organizationInformation[0]).length > 0) {
      content = (
        <QboCard
          className="QboCard__wrapper_search_text_highlight"
          sx={{
            scrollbarWidth: 'none !important',
            overflowY: 'scroll !important',
            height: '65vh',
          }}>
          <QboCard sx={{ marginTop: 2, marginBottom: 2 }}>
            {organizationDetails?.map((notification) => (
              <QboCardSingleList
                key={notification.label}
                subHeadingText={notification?.subHeadingText}
                leftContent={notification.label}
                rightContent={valueHandler(
                  notification.label,
                  notification.properties,
                  notification.valType
                )}
              />
            ))}
          </QboCard>
        </QboCard>
      );
    } else if (!fetching && !organizationInformation[0]) {
      content = <NoStatusSection />;
    }

    return content;
  };

  return componentHandler();
}

OrganizationInformationPage.propTypes = {
  resellerID: PropTypes.number,
};

OrganizationInformationPage.defaultProps = {
  resellerID: null,
};
