import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Skeleton } from '@mui/material';

import { QboCard } from '@ui/Components';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import QboTitleWithCollapsableButton from '@ui/Components/QboTitleWithCollapsableButton';
import QboCardSingleList from '@ui/Components/QboCardSingleList';
import useResellerService from './pageHooks/useResellerService';
import useResellerLabelDataService from './pageHooks/useResellerLabelDataService';

export default function ResellerNotificationsListPage(props) {
  const { resellerID } = props;
  const { handleGetResellerNotificationsConfigAsync } = useResellerService();
  const { getResellerNotificationsConfigState } = useSelector(
    (state) => state.resellerNotificationsConfigState
  );
  const styleObj = { display: 'flex', justifyContent: 'space-between' };

  const {
    notificationsSettingsABackupNotification,
    notificationsSettingsABackupNotificationAccountAdded,
    notificationsSettingsABackupNotificationAutoDiscover,
    notificationsSettingsCredentialsNotification,
    notificationsSettingsUserManagementNotification,
    notificationsSettingsUserManagementNotificationDeactivatedAccount,
    notificationsSettingsReporting,
    notificationsSettingsOrganizationManagementNotification,
    notificationsSettingsNFRConvertedToPaid,
    notificationsSettingsNFRExpiringOrganisation,
    notificationsSettingsNFRNewOrganisation,
    notificationsSettingsNFR,
    notificationsSettingsOrgOwner,
    notificationsSettingsAccountingBackupNotification,
    notificationsSettingsBackupAD,
    notificationsSettingsBackupError,
    notificationsSettingsMailboxDeactivated,
    notificationsSettingsOutOfCapacityPersonalPlan,
    notificationsSettingsSuccessfulBackup,
    notificationsSettingsComplianceNotification,
    notificationsSettingsAccounting,
    notificationsSettingsGeneral,
    notificationsSettingsGeneralErrorSummary,
    notificationsSettingsGeneralMimeConversionError,
    notificationsSettingsUserManagementNotificationSubscriptionChange,
    notificationsSettingsBackupReachingLimit,
    notificationSettingsDownloadNotificationDownloadReady,
  } = useResellerLabelDataService();

  const [flagsList, setFlagsList] = useState({
    notificationSettings: true,
    nfr: false,
    convertedToPaid: false,
    orgOwner: false,
    accounting: false,
    accountAdded: false,
    complianceNotification: false,
    credentialsNotification: false,
    downloadNotification: false,
    downloadReady: false,
    addBackupNotification: false,
    addGroupDeactivation: false,
    backupNotification: false,
    outOfCapacityPersonalPlan: false,
    mailboxDeactivated: false,
    successfulBackup: false,
    backupError: false,
    backupReachingLimit: false,
    general: false,
    errorSummary: false,
    mimeConversionError: false,
    newOrganisation: false,
    expiringOrganisation: false,
    subscriptionChange: false,
    organizationManagementNotification: false,
    reporting: false,
    userManagementNotification: false,
  });

  const { fetching, resellerNotificationsConfigData } = getResellerNotificationsConfigState;

  useEffect(() => {
    if (resellerID && !Object.keys(resellerNotificationsConfigData).length > 0) {
      handleGetResellerNotificationsConfigAsync(resellerID);
    }
  }, [resellerID]);

  const handleOnIconButtonClick = (e, flagType) => {
    setFlagsList((prevState) => ({
      ...prevState,
      [flagType]: !prevState[flagType],
    }));
  };

  const valueHandler = (label, value, valType = '') => {
    const frequencyMap = {
      d: 'Daily',
      w: 'Weekly',
      m: 'Monthly',
    };

    const result =
      Object.keys(resellerNotificationsConfigData).length > 0 &&
      resellerNotificationsConfigData[value];
    if (valType === 'bool' && result === null) {
      return 'false';
    }
    if (result === null) {
      return '-';
    }
    if (label === 'Notify Frequency' && frequencyMap[result]) {
      return frequencyMap[result];
    }
    return `${result}`;
  };

  const renderLoading = () => {
    return (
      <div style={{ display: 'contents' }}>
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="70%" />
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="70%" />
      </div>
    );
  };

  return (
    <>
      {!fetching && Object.keys(resellerNotificationsConfigData).length > 0 && (
        <QboCard
          className="QboCard__wrapper_search_text_highlight"
          sx={{
            scrollbarWidth: 'none !important',
            overflowY: 'scroll !important',
            height: '65vh',
          }}>
          <QboCard sx={{ marginBottom: 2 }}>
            <QboTitleWithCollapsableButton
              collapseVisibility="hidden"
              isCollapseButtonEnabled={false}
              title="Notification Settings"
              variant="h5"
              flagType="notificationSettings"
              open={flagsList.notificationSettings}
              isEditEnable={false}
              style={styleObj}
              handleOnIconButtonClick={(e) => {
                handleOnIconButtonClick(e, 'notificationSettings');
              }}
            />
            {flagsList.notificationSettings && (
              <>
                {/* {notificationsSettings.map((notification) => (
                  <QboCardSingleList
                    subHeadingText={notification?.subHeadingText}
                    leftContent={notification.label}
                    rightContent={valueHandler(
                      notification.label,
                      notification.properties,
                      notification.valType
                    )}
                  />
                ))} */}
                <QboTitleWithCollapsableButton
                  title="NFR"
                  flagType="nfr"
                  open={flagsList.nfr}
                  isEditEnable={false}
                  style={{ marginTop: 2, ...styleObj }}
                  handleOnIconButtonClick={(e) => {
                    handleOnIconButtonClick(e, 'nfr');
                  }}
                />
                {Boolean(flagsList.nfr) && (
                  <>
                    {notificationsSettingsNFR?.map((notification) => (
                      <QboCardSingleList
                        subHeadingText={notification?.subHeadingText}
                        leftContent={notification?.label}
                        rightContent={valueHandler(
                          notification?.label,
                          notification?.properties,
                          notification?.valType
                        )}
                      />
                    ))}
                    <QboCard sx={{ marginLeft: 2 }}>
                      <QboTitleWithCollapsableButton
                        marginRightIconButton="4.5%"
                        title=" Converted to Paid"
                        flagType="convertedToPaid"
                        open={flagsList.convertedToPaid}
                        isEditEnable={false}
                        style={{ marginTop: 2, ...styleObj }}
                        handleOnIconButtonClick={(e) => {
                          handleOnIconButtonClick(e, 'convertedToPaid');
                        }}
                      />
                      {Boolean(flagsList.convertedToPaid) &&
                        notificationsSettingsNFRConvertedToPaid.map((notification) => (
                          <QboCardSingleList
                            subHeadingText={notification?.subHeadingText}
                            leftContent={notification.label}
                            rightContent={valueHandler(
                              notification.label,
                              notification.properties,
                              notification.valType
                            )}
                          />
                        ))}
                      <QboTitleWithCollapsableButton
                        marginRightIconButton="4.5%"
                        title="Expiring Organisation"
                        flagType="expiringOrganisation"
                        open={flagsList.expiringOrganisation}
                        isEditEnable={false}
                        style={{ marginTop: 2, ...styleObj }}
                        handleOnIconButtonClick={(e) => {
                          handleOnIconButtonClick(e, 'expiringOrganisation');
                        }}
                      />
                      {Boolean(flagsList.expiringOrganisation) &&
                        notificationsSettingsNFRExpiringOrganisation.map((notification) => (
                          <QboCardSingleList
                            subHeadingText={notification?.subHeadingText}
                            leftContent={notification.label}
                            rightContent={valueHandler(
                              notification.label,
                              notification.properties,
                              notification.valType
                            )}
                          />
                        ))}
                      <QboTitleWithCollapsableButton
                        marginRightIconButton="4.5%"
                        title="New Organisation"
                        flagType="newOrganisation"
                        open={flagsList.newOrganisation}
                        isEditEnable={false}
                        style={{ marginTop: 2, ...styleObj }}
                        handleOnIconButtonClick={(e) => {
                          handleOnIconButtonClick(e, 'newOrganisation');
                        }}
                      />
                      {Boolean(flagsList.newOrganisation) &&
                        notificationsSettingsNFRNewOrganisation.map((notification) => (
                          <QboCardSingleList
                            subHeadingText={notification?.subHeadingText}
                            leftContent={notification.label}
                            rightContent={valueHandler(
                              notification.label,
                              notification.properties,
                              notification.valType
                            )}
                          />
                        ))}
                    </QboCard>
                  </>
                )}
                <QboTitleWithCollapsableButton
                  title="Org Owner"
                  flagType="orgOwner"
                  open={flagsList.orgOwner}
                  isEditEnable={false}
                  style={{ marginTop: flagsList.nfr ? 4 : 2, ...styleObj }}
                  handleOnIconButtonClick={(e) => {
                    handleOnIconButtonClick(e, 'orgOwner');
                  }}
                />
                {Boolean(flagsList.orgOwner) &&
                  notificationsSettingsOrgOwner.map((notification) => (
                    <QboCardSingleList
                      subHeadingText={notification?.subHeadingText}
                      leftContent={notification.label}
                      rightContent={valueHandler(
                        notification.label,
                        notification.properties,
                        notification.valType
                      )}
                    />
                  ))}
                <QboTitleWithCollapsableButton
                  title="Accounting Backup Notification"
                  flagType="accounting"
                  open={flagsList.accounting}
                  isEditEnable={false}
                  style={{ marginTop: 2, ...styleObj }}
                  handleOnIconButtonClick={(e) => {
                    handleOnIconButtonClick(e, 'accounting');
                  }}
                />
                {Boolean(flagsList.accounting) &&
                  notificationsSettingsAccounting.map((notification) => (
                    <QboCardSingleList
                      subHeadingText={notification?.subHeadingText}
                      leftContent={notification.label}
                      rightContent={valueHandler(
                        notification.label,
                        notification.properties,
                        notification.valType
                      )}
                    />
                  ))}

                <QboTitleWithCollapsableButton
                  title="Add Backup Notification"
                  flagType="addBackupNotification"
                  open={flagsList.addBackupNotification}
                  isEditEnable={false}
                  style={{ marginTop: 2, ...styleObj }}
                  handleOnIconButtonClick={(e) => {
                    handleOnIconButtonClick(e, 'addBackupNotification');
                  }}
                />
                {Boolean(flagsList.addBackupNotification) && (
                  <>
                    {notificationsSettingsABackupNotification.map((notification) => (
                      <QboCardSingleList
                        subHeadingText={notification?.subHeadingText}
                        leftContent={notification.label}
                        rightContent={valueHandler(
                          notification.label,
                          notification.properties,
                          notification.valType
                        )}
                      />
                    ))}

                    <QboCard sx={{ marginLeft: 2 }}>
                      <QboTitleWithCollapsableButton
                        marginRightIconButton="4.5%"
                        title="Account Added"
                        flagType="accountAdded"
                        open={flagsList.accountAdded}
                        isEditEnable={false}
                        style={{ marginTop: 2, ...styleObj }}
                        handleOnIconButtonClick={(e) => {
                          handleOnIconButtonClick(e, 'accountAdded');
                        }}
                      />
                      {Boolean(flagsList.accountAdded) &&
                        notificationsSettingsABackupNotificationAccountAdded.map((notification) => (
                          <QboCardSingleList
                            subHeadingText={notification?.subHeadingText}
                            leftContent={notification.label}
                            rightContent={valueHandler(
                              notification.label,
                              notification.properties,
                              notification.valType
                            )}
                          />
                        ))}
                      <QboTitleWithCollapsableButton
                        marginRightIconButton="4.5%"
                        title="Auto Discover"
                        flagType="autoDiscover"
                        open={flagsList.autoDiscover}
                        isEditEnable={false}
                        style={{ marginTop: 2, ...styleObj }}
                        handleOnIconButtonClick={(e) => {
                          handleOnIconButtonClick(e, 'autoDiscover');
                        }}
                      />
                      {Boolean(flagsList.autoDiscover) &&
                        notificationsSettingsABackupNotificationAutoDiscover.map((notification) => (
                          <QboCardSingleList
                            subHeadingText={notification?.subHeadingText}
                            leftContent={notification.label}
                            rightContent={valueHandler(
                              notification.label,
                              notification.properties,
                              notification.valType
                            )}
                          />
                        ))}
                    </QboCard>
                  </>
                )}

                <QboTitleWithCollapsableButton
                  title="Backup Notification"
                  flagType="backupNotification"
                  open={flagsList.backupNotification}
                  isEditEnable={false}
                  style={{ marginTop: flagsList.addBackupNotification ? 4 : 2, ...styleObj }}
                  handleOnIconButtonClick={(e) => {
                    handleOnIconButtonClick(e, 'backupNotification');
                  }}
                />
                {Boolean(flagsList.backupNotification) && (
                  <QboCard sx={{ marginLeft: 2 }}>
                    <QboTitleWithCollapsableButton
                      marginRightIconButton="4.5%"
                      title="AD Group Deactivation"
                      flagType="addGroupDeactivation"
                      open={flagsList.addGroupDeactivation}
                      isEditEnable={false}
                      style={{ marginTop: 2, ...styleObj }}
                      handleOnIconButtonClick={(e) => {
                        handleOnIconButtonClick(e, 'addGroupDeactivation');
                      }}
                    />
                    {Boolean(flagsList.addGroupDeactivation) &&
                      notificationsSettingsBackupAD.map((notification) => (
                        <QboCardSingleList
                          subHeadingText={notification?.subHeadingText}
                          leftContent={notification.label}
                          rightContent={valueHandler(
                            notification.label,
                            notification.properties,
                            notification.valType
                          )}
                        />
                      ))}
                    <QboTitleWithCollapsableButton
                      marginRightIconButton="4.5%"
                      title="Mailbox Deactivated"
                      flagType="mailboxDeactivated"
                      open={flagsList.mailboxDeactivated}
                      isEditEnable={false}
                      style={{ marginTop: 2, ...styleObj }}
                      handleOnIconButtonClick={(e) => {
                        handleOnIconButtonClick(e, 'mailboxDeactivated');
                      }}
                    />
                    {Boolean(flagsList.mailboxDeactivated) &&
                      notificationsSettingsMailboxDeactivated.map((notification) => (
                        <QboCardSingleList
                          subHeadingText={notification?.subHeadingText}
                          leftContent={notification.label}
                          rightContent={valueHandler(
                            notification.label,
                            notification.properties,
                            notification.valType
                          )}
                        />
                      ))}
                    <QboTitleWithCollapsableButton
                      marginRightIconButton="4.5%"
                      title="Out of Capacity(Personal Plan)"
                      flagType="outOfCapacityPersonalPlan"
                      open={flagsList.outOfCapacityPersonalPlan}
                      isEditEnable={false}
                      style={{ marginTop: 2, ...styleObj }}
                      handleOnIconButtonClick={(e) => {
                        handleOnIconButtonClick(e, 'outOfCapacityPersonalPlan');
                      }}
                    />
                    {Boolean(flagsList.outOfCapacityPersonalPlan) &&
                      notificationsSettingsOutOfCapacityPersonalPlan.map((notification) => (
                        <QboCardSingleList
                          subHeadingText={notification?.subHeadingText}
                          leftContent={notification.label}
                          rightContent={valueHandler(
                            notification.label,
                            notification.properties,
                            notification.valType
                          )}
                        />
                      ))}
                    <QboTitleWithCollapsableButton
                      marginRightIconButton="4.5%"
                      title="Successful Backup"
                      flagType="successfulBackup"
                      open={flagsList.successfulBackup}
                      isEditEnable={false}
                      style={{ marginTop: 2, ...styleObj }}
                      handleOnIconButtonClick={(e) => {
                        handleOnIconButtonClick(e, 'successfulBackup');
                      }}
                    />
                    {Boolean(flagsList.successfulBackup) &&
                      notificationsSettingsSuccessfulBackup.map((notification) => (
                        <QboCardSingleList
                          subHeadingText={notification?.subHeadingText}
                          leftContent={notification.label}
                          rightContent={valueHandler(
                            notification.label,
                            notification.properties,
                            notification.valType
                          )}
                        />
                      ))}
                    <QboTitleWithCollapsableButton
                      marginRightIconButton="4.5%"
                      title="Backup Error"
                      flagType="backupError"
                      open={flagsList.backupError}
                      isEditEnable={false}
                      style={{ marginTop: 2, ...styleObj }}
                      handleOnIconButtonClick={(e) => {
                        handleOnIconButtonClick(e, 'backupError');
                      }}
                    />
                    {Boolean(flagsList.backupError) &&
                      notificationsSettingsBackupError.map((notification) => (
                        <QboCardSingleList
                          subHeadingText={notification?.subHeadingText}
                          leftContent={notification.label}
                          rightContent={valueHandler(
                            notification.label,
                            notification.properties,
                            notification.valType
                          )}
                        />
                      ))}
                    <QboTitleWithCollapsableButton
                      marginRightIconButton="4.5%"
                      title="Backup Reaching Limit"
                      flagType="backupReachingLimit"
                      open={flagsList.backupReachingLimit}
                      isEditEnable={false}
                      style={{ marginTop: 2, ...styleObj }}
                      handleOnIconButtonClick={(e) => {
                        handleOnIconButtonClick(e, 'backupReachingLimit');
                      }}
                    />
                    {Boolean(flagsList.backupReachingLimit) &&
                      notificationsSettingsBackupReachingLimit.map((notification) => (
                        <QboCardSingleList
                          subHeadingText={notification?.subHeadingText}
                          leftContent={notification.label}
                          rightContent={valueHandler(
                            notification.label,
                            notification.properties,
                            notification.valType
                          )}
                        />
                      ))}
                  </QboCard>
                )}
                <QboTitleWithCollapsableButton
                  title="Compliance Notification"
                  flagType="complianceNotification"
                  open={flagsList.complianceNotification}
                  isEditEnable={false}
                  style={{ marginTop: flagsList.backupNotification ? 4 : 2, ...styleObj }}
                  handleOnIconButtonClick={(e) => {
                    handleOnIconButtonClick(e, 'complianceNotification');
                  }}
                />
                {Boolean(flagsList.complianceNotification) &&
                  notificationsSettingsComplianceNotification.map((notification) => (
                    <QboCardSingleList
                      subHeadingText={notification?.subHeadingText}
                      leftContent={notification.label}
                      rightContent={valueHandler(
                        notification.label,
                        notification.properties,
                        notification.valType
                      )}
                    />
                  ))}
                <QboTitleWithCollapsableButton
                  title="Credentials Notification"
                  flagType="credentialsNotification"
                  open={flagsList.credentialsNotification}
                  isEditEnable={false}
                  style={{ marginTop: flagsList.backupNotification ? 4 : 2, ...styleObj }}
                  handleOnIconButtonClick={(e) => {
                    handleOnIconButtonClick(e, 'credentialsNotification');
                  }}
                />
                {Boolean(flagsList.credentialsNotification) &&
                  notificationsSettingsCredentialsNotification.map((notification) => (
                    <QboCardSingleList
                      subHeadingText={notification?.subHeadingText}
                      leftContent={notification.label}
                      rightContent={valueHandler(
                        notification.label,
                        notification.properties,
                        notification.valType
                      )}
                    />
                  ))}
                <QboTitleWithCollapsableButton
                  title="Download Notification"
                  flagType="downloadNotification"
                  open={flagsList.downloadNotification}
                  isEditEnable={false}
                  style={{ marginTop: flagsList.backupNotification ? 4 : 2, ...styleObj }}
                  handleOnIconButtonClick={(e) => {
                    handleOnIconButtonClick(e, 'downloadNotification');
                  }}
                />
                {Boolean(flagsList.downloadNotification) && (
                  <QboCard sx={{ marginLeft: 2 }}>
                    <QboTitleWithCollapsableButton
                      marginRightIconButton="4.5%"
                      title="Download Ready"
                      flagType="downloadReady"
                      open={flagsList.downloadReady}
                      isEditEnable={false}
                      style={{ marginTop: flagsList.backupNotification ? 4 : 2, ...styleObj }}
                      handleOnIconButtonClick={(e) => {
                        handleOnIconButtonClick(e, 'downloadReady');
                      }}
                    />
                    {Boolean(flagsList.downloadReady) &&
                      notificationSettingsDownloadNotificationDownloadReady.map((notification) => (
                        <QboCardSingleList
                          subHeadingText={notification?.subHeadingText}
                          leftContent={notification.label}
                          rightContent={valueHandler(
                            notification.label,
                            notification.properties,
                            notification.valType
                          )}
                        />
                      ))}
                  </QboCard>
                )}
                <QboTitleWithCollapsableButton
                  title="General"
                  flagType="general"
                  open={flagsList.general}
                  isEditEnable={false}
                  style={{ marginTop: flagsList.downloadNotification ? 4 : 2, ...styleObj }}
                  handleOnIconButtonClick={(e) => {
                    handleOnIconButtonClick(e, 'general');
                  }}
                />
                {Boolean(flagsList.general) && (
                  <>
                    {notificationsSettingsGeneral.map((notification) => (
                      <QboCardSingleList
                        subHeadingText={notification?.subHeadingText}
                        leftContent={notification.label}
                        rightContent={valueHandler(
                          notification.label,
                          notification.properties,
                          notification.valType
                        )}
                      />
                    ))}
                    <QboCard sx={{ marginLeft: 2 }}>
                      <QboTitleWithCollapsableButton
                        marginRightIconButton="4.5%"
                        title="Error Summary"
                        flagType="errorSummary"
                        open={flagsList.errorSummary}
                        isEditEnable={false}
                        style={{ marginTop: 2, ...styleObj }}
                        handleOnIconButtonClick={(e) => {
                          handleOnIconButtonClick(e, 'errorSummary');
                        }}
                      />
                      {Boolean(flagsList.errorSummary) &&
                        notificationsSettingsGeneralErrorSummary.map((notification) => (
                          <QboCardSingleList
                            subHeadingText={notification?.subHeadingText}
                            leftContent={notification.label}
                            rightContent={valueHandler(
                              notification.label,
                              notification.properties,
                              notification.valType
                            )}
                          />
                        ))}
                      <QboTitleWithCollapsableButton
                        marginRightIconButton="4.5%"
                        title="Mime Conversion Error"
                        flagType="mimeConversionError"
                        open={flagsList.mimeConversionError}
                        isEditEnable={false}
                        style={{ marginTop: 2, ...styleObj }}
                        handleOnIconButtonClick={(e) => {
                          handleOnIconButtonClick(e, 'mimeConversionError');
                        }}
                      />
                      {Boolean(flagsList.mimeConversionError) &&
                        notificationsSettingsGeneralMimeConversionError.map((notification) => (
                          <QboCardSingleList
                            subHeadingText={notification?.subHeadingText}
                            leftContent={notification.label}
                            rightContent={valueHandler(
                              notification.label,
                              notification.properties,
                              notification.valType
                            )}
                          />
                        ))}
                    </QboCard>
                  </>
                )}
                <QboTitleWithCollapsableButton
                  title="Organization Management Notification"
                  flagType="organizationManagementNotification"
                  open={flagsList.organizationManagementNotification}
                  isEditEnable={false}
                  style={{ marginTop: flagsList.general ? 4 : 2, ...styleObj }}
                  handleOnIconButtonClick={(e) => {
                    handleOnIconButtonClick(e, 'organizationManagementNotification');
                  }}
                />
                {Boolean(flagsList.organizationManagementNotification) &&
                  notificationsSettingsOrganizationManagementNotification.map((notification) => (
                    <QboCardSingleList
                      subHeadingText={notification?.subHeadingText}
                      leftContent={notification.label}
                      rightContent={valueHandler(
                        notification.label,
                        notification.properties,
                        notification.valType
                      )}
                    />
                  ))}
                <QboTitleWithCollapsableButton
                  title="Reporting"
                  flagType="reporting"
                  open={flagsList.reporting}
                  isEditEnable={false}
                  style={{ marginTop: flagsList.general ? 4 : 2, ...styleObj }}
                  handleOnIconButtonClick={(e) => {
                    handleOnIconButtonClick(e, 'reporting');
                  }}
                />
                {Boolean(flagsList.reporting) &&
                  notificationsSettingsReporting.map((notification) => (
                    <QboCardSingleList
                      subHeadingText={notification?.subHeadingText}
                      leftContent={notification.label}
                      rightContent={valueHandler(
                        notification.label,
                        notification.properties,
                        notification.valType
                      )}
                    />
                  ))}
                <QboTitleWithCollapsableButton
                  title="User Management Notification"
                  flagType="userManagementNotification"
                  open={flagsList.userManagementNotification}
                  isEditEnable={false}
                  style={{ marginTop: flagsList.general ? 4 : 2, ...styleObj }}
                  handleOnIconButtonClick={(e) => {
                    handleOnIconButtonClick(e, 'userManagementNotification');
                  }}
                />
                {Boolean(flagsList.userManagementNotification) && (
                  <>
                    {notificationsSettingsUserManagementNotification.map((notification) => (
                      <QboCardSingleList
                        subHeadingText={notification?.subHeadingText}
                        leftContent={notification.label}
                        rightContent={valueHandler(
                          notification.label,
                          notification.properties,
                          notification.valType
                        )}
                      />
                    ))}
                    <QboCard sx={{ marginLeft: 2 }}>
                      <QboTitleWithCollapsableButton
                        marginRightIconButton="4.5%"
                        title="Deactivated Account"
                        flagType="deactivatedAccount"
                        open={flagsList.deactivatedAccount}
                        isEditEnable={false}
                        style={{ marginTop: flagsList.general ? 4 : 2, ...styleObj }}
                        handleOnIconButtonClick={(e) => {
                          handleOnIconButtonClick(e, 'deactivatedAccount');
                        }}
                      />
                      {Boolean(flagsList.deactivatedAccount) &&
                        notificationsSettingsUserManagementNotificationDeactivatedAccount.map(
                          (notification) => (
                            <QboCardSingleList
                              subHeadingText={notification?.subHeadingText}
                              leftContent={notification.label}
                              rightContent={valueHandler(
                                notification.label,
                                notification.properties,
                                notification.valType
                              )}
                            />
                          )
                        )}
                      <QboTitleWithCollapsableButton
                        marginRightIconButton="4.5%"
                        title="Subscription Change"
                        flagType="subscriptionChange"
                        open={flagsList.subscriptionChange}
                        isEditEnable={false}
                        style={{ marginTop: flagsList.general ? 4 : 2, ...styleObj }}
                        handleOnIconButtonClick={(e) => {
                          handleOnIconButtonClick(e, 'subscriptionChange');
                        }}
                      />
                      {Boolean(flagsList.subscriptionChange) &&
                        notificationsSettingsUserManagementNotificationSubscriptionChange.map(
                          (notification) => (
                            <QboCardSingleList
                              subHeadingText={notification?.subHeadingText}
                              leftContent={notification.label}
                              rightContent={valueHandler(
                                notification.label,
                                notification.properties,
                                notification.valType
                              )}
                            />
                          )
                        )}
                    </QboCard>
                  </>
                )}
              </>
            )}
          </QboCard>
        </QboCard>
      )}
      {fetching && renderLoading()}
      {!fetching && Object.keys(resellerNotificationsConfigData).length === 0 && (
        <NoStatusSection />
      )}
    </>
  );
}

ResellerNotificationsListPage.propTypes = {
  resellerID: PropTypes.number,
};

ResellerNotificationsListPage.defaultProps = {
  resellerID: null,
};
