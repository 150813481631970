import { createSlice } from '@reduxjs/toolkit';
import {
  getBulkCreateSubResellerTemplateAsync,
  getBulkCreateSubResellerTemplateExtraReducers,
} from './serviceActions/getBulkCreateSubResellerTemplateAsync';

const initialState = {
  getBulkCreateSubResellerTemplateState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    exportData: null,
    forbiddenState: false,
    forbiddenErrorMessage: null,
    corsErrorMessage: null,
  },
};

const getBulkCreateSubResellerTemplateTenantSlice = createSlice({
  name: 'getBulkCreateSubResellerTemplate',
  initialState,
  reducers: {
    resetGetBulkCreateSubResellerTemplateState(state) {
      state.getBulkCreateSubResellerTemplateState =
        initialState.getBulkCreateSubResellerTemplateState;
    },
  },
  extraReducers: {
    ...getBulkCreateSubResellerTemplateExtraReducers,
  },
});

export { getBulkCreateSubResellerTemplateAsync };
export const { resetGetBulkCreateSubResellerTemplateState } =
  getBulkCreateSubResellerTemplateTenantSlice.actions;
export default getBulkCreateSubResellerTemplateTenantSlice.reducer;
