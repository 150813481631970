import { createSlice } from '@reduxjs/toolkit';

import {
  getInvoicesListAsync,
  getInvoicesListExtraReducers,
} from './serviceActions/getInvoicesListAsync';

const initialState = {
  getInvoicesListState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    pagination: null,
    list: [],
    forbiddenState: false,
    forbiddenErrorMessage: null,
    corsErrorMessage: null,
  },
};

const getInvoicesListSlice = createSlice({
  name: 'InvoicesList',
  initialState,
  reducers: {
    resetInvoicesListState(state) {
      state.getInvoicesListState = initialState.getInvoicesListState;
    },
    resetInvoicesListStatusCodeState(state) {
      state.getInvoicesListState.statusCode = initialState.getInvoicesListState.statusCode;
    },
  },
  extraReducers: {
    ...getInvoicesListExtraReducers,
  },
});

export { getInvoicesListAsync };
export const { resetInvoicesListState, resetInvoicesListStatusCodeState } =
  getInvoicesListSlice.actions;
export default getInvoicesListSlice.reducer;
