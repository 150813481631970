import { createSlice } from '@reduxjs/toolkit';
import {
  toolsRequestHistoryAsync,
  toolsRequestHistoryReducers,
} from './serviceActions/toolsRequestHistoryAsync';

const initialState = {
  toolsRequestHistoryState: {
    fetching: false,
    data: [],
    errorMessage: null,
    pagination: [],
    statusCode: null,
    forbiddenState: false,
    forbiddenErrorMessage: {},
    corsErrorMessage: null,
  },
};

const toolsRequestHistorySlice = createSlice({
  name: 'ToolsRequestHistory',
  initialState,
  reducers: {
    resetToolsRequestHistoryState(state) {
      state.toolsRequestHistoryState = initialState.toolsRequestHistoryState;
    },
    resetToolsRequestHistoryData(state) {
      state.toolsRequestHistoryState.data = initialState.toolsRequestHistoryState.data;
    },
    resetToolsRequestHistoryError(state) {
      state.toolsRequestHistoryState.errorMessage =
        initialState.toolsRequestHistoryState.errorMessage;
    },
  },
  extraReducers: {
    ...toolsRequestHistoryReducers,
  },
});

export { toolsRequestHistoryAsync };
export const {
  resetToolsRequestHistoryState,
  resetToolsRequestHistoryData,
  resetToolsRequestHistoryError,
} = toolsRequestHistorySlice.actions;
export default toolsRequestHistorySlice.reducer;
