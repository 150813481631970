import WebApiRoutes from '@config/Routes/WebApiRoutes';
import ServiceClientFactory from '@libs/ServiceClient';

const webClientApi = (accessToken) => {
  return new ServiceClientFactory({
    baseUrl: 'https://jsonplaceholder.typicode.com/',
    accessToken,
  }).create();
};

const FormService = {
  submitFormApi: (payload = {}) => {
    const parameters = { ...payload };
    return webClientApi().postRequest({
      url: WebApiRoutes.submitFormPath(),
      parameters,
    });
  },
};

export default FormService;
