import { createSlice } from '@reduxjs/toolkit';

import {
  getDataIngestionAsync,
  getDataIngestionExtraReducers,
} from './serviceActions/fetchIngestionsListAsync';

const initialState = {
  getIngestionDataState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    paginationList: null,
    ingestionList: [],
    forbiddenState: false,
    forbiddenErrorMessage: null,
    ingestionCorsErrorMessage: null,
  },
};

const getIngestionSlice = createSlice({
  name: 'IngestionList',
  initialState,
  reducers: {
    resetIngestionSubmit(state) {
      state.getIngestionDataState = initialState.getIngestionDataState;
    },
  },
  extraReducers: {
    ...getDataIngestionExtraReducers,
  },
});

export { getDataIngestionAsync };
export const { resetIngestionSubmit } = getIngestionSlice.actions;
export default getIngestionSlice.reducer;
