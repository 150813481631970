import WebApiRoutes from '@config/Routes/WebApiRoutes';
import ServiceClientFactory from '@libs/ServiceClient';

const webClientApi = (accessToken) => {
  return new ServiceClientFactory({ baseUrl: WebApiRoutes.getBaseUrl(), accessToken }).create();
};

const TransferSubresellerService = {
  transferSubresellerValidateApi: (paramsList = {}) => {
    const { accessToken, reset, parameters } = paramsList;
    return webClientApi(accessToken, reset).getRequest({
      url: WebApiRoutes.transferSubresellerValidatePath(parameters),
    });
  },
  transferSubresellerApi: (paramsList = {}) => {
    const { accessToken, reset, parameters } = paramsList;
    return webClientApi(accessToken, reset).postRequest({
      url: WebApiRoutes.transferSubresellerPath(),
      parameters,
    });
  },
};

export default TransferSubresellerService;
