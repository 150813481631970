import { createSlice } from '@reduxjs/toolkit';

import {
  getTransferSubresellerAsync,
  getTransferSubresellerExtraReducers,
} from './serviceActions/fetchTransferSubresellerAsync';

const initialState = {
  getTransferSubresellerState: {
    fetching: false,
    status: null,
    transferSubresellerList: [],
    errorMessage: null,
    paginations: [],
    forbiddenState: false,
    forbiddenErrorMessage: null,
    transferSubresellerCorsErrorMessage: null,
  },
};

const getTransferSubresellerSlice = createSlice({
  name: 'transferSubreseller',
  initialState,
  reducers: {
    resetTransferSubresellerState(state) {
      state.getTransferSubresellerState = initialState.getTransferSubresellerState;
    },
  },
  extraReducers: {
    ...getTransferSubresellerExtraReducers,
  },
});

export { getTransferSubresellerAsync };

export const { resetTransferSubresellerState } = getTransferSubresellerSlice.actions;
export default getTransferSubresellerSlice.reducer;
