import { createSlice } from '@reduxjs/toolkit';
import {
  submitDataIngestionJobFormAsync,
  submitDataIngestionJobFormExtraReducers,
} from './serviceActions/submitIngestionJobFormAsync';

const initialState = {
  submitIngestionJobForm: {
    submitting: false,
    success: false,
    error: null,
    statusCode: null,
    errorMessage: null,
  },
};

const ingestionJobSlice = createSlice({
  name: 'IngestionJobForm',
  initialState,
  reducers: {
    resetIngestionJobFormSubmit(state) {
      state.submitIngestionJobForm = initialState.submitIngestionJobForm;
    },
  },
  extraReducers: {
    ...submitDataIngestionJobFormExtraReducers,
  },
});

export { submitDataIngestionJobFormAsync };
export const { resetIngestionJobFormSubmit } = ingestionJobSlice.actions;
export default ingestionJobSlice.reducer;
