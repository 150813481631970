import { useDispatch } from 'react-redux';
import { getDataIngestionAsync } from '@features/ingestion/serviceActions/fetchIngestionsListAsync';
import { getIngestionsQueuedAsync } from '@features/ingestion/serviceActions/fetchIngestionsQueuedAsync';
import { getIngestionsInProgressAsync } from '@features/ingestion/serviceActions/fetchIngestionsInProgressAsync';
import useLocalStorage from '@hooks/useLocalStorage';
import { constant } from '@config/BaseSetting';
import { removeDataIngestionJobAsync } from '@features/ingestion/serviceActions/removeDataIngestionJobAsync';

export default function useIngestionList(setAdvanceClick, advanceClick, setSearchByID) {
  const dispatch = useDispatch();
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);

  const ingestionType = {
    0: 'Non Archiver',
    1: 'Archiver',
  };

  const storageType = {
    1101: 'FTP',
    1102: 'S3TYPE',
    1103: 'PROSERV',
    1104: 'PROSERV S3',
  };

  const statusMap = {
    PartiallyCompleted: 'Completed with errors',
    Success: 'Completed',
    JobInProgress: 'In Progress',
    JobCreated: 'Job Created',
    QueueingInProgress: 'Queueing in Progress',
    JobCreatedwithFailures: 'Job Created with Failures',
  };

  const handleFetchIngestionList = (searchID, paginationData, filters) => {
    dispatch(
      getDataIngestionAsync({
        accessToken: accessTokenSet,
        reset: true,
        searchById: searchID || 0,
        pageNumber: paginationData.pageIndex + 1,
        pageSize: paginationData.pageSize,
        filter: filters,
      })
    );
    // dispatch(getIngestionsQueuedAsync({ accessToken: accessTokenSet, reset: true }));
    // dispatch(getIngestionsInProgressAsync({ accessToken: accessTokenSet, reset: true }));
  };

  const handleOnClose = () => {
    // setSelected([]);
    // setAge('');
  };

  const handleOnAdvanceSearchClick = () => {
    setAdvanceClick(!advanceClick);

    if (!advanceClick) {
      handleOnClose();
    }
  };

  const handleOnTextFiledChange = (e) => {
    const input = e.target.value;
    setSearchByID(input);
  };

  const getDataFromResponse = (data, setIngestionLIstData) => {
    const modifiedData = data?.map((item) => {
      let payloadObj = JSON.parse(item.payload);
      payloadObj = payloadObj.JobPayload || payloadObj;
      const modifiedPayloadObj = {
        ...payloadObj,
        isLink: true,
        id: item?.id,
        'ingestion-id': item?.id,
        'created-on': item['created-on'],
        status: statusMap[item?.status] || item?.status,
        'storage-type': storageType[payloadObj['storage-type']],
        queued: item?.queued,
        success: item?.success,
        failed: item?.failed,
        'in-progress': item['in-progress'],
        description: payloadObj?.Description,
        'ingestion-type': ingestionType[payloadObj['ingestion-type']] || 'Non Archiver',
        'download-failed': item['download-failed'],
      };
      return modifiedPayloadObj;
    });
    return setIngestionLIstData(modifiedData);
  };

  const handleRemoveDataIngestionJobAsync = (paramsList) => {
    const { selectedJobs } = paramsList;
    dispatch(
      removeDataIngestionJobAsync({
        accessToken: accessTokenSet,
        reset: true,
        selectedJobs,
      })
    );
  };

  return {
    handleFetchIngestionList,
    getDataFromResponse,
    handleOnAdvanceSearchClick,
    handleOnTextFiledChange,
    handleRemoveDataIngestionJobAsync,
  };
}
