const { createSlice } = require('@reduxjs/toolkit');
const {
  validateToggleEnforceSSOExtraReducers,
  validateToggleEnforceSSOAsync,
} = require('./serviceActions/validateToggleEnforceSSOAsync');

const initialState = {
  validateToggleEnforceSSOState: {
    fetching: false,
    status: null,
    error: null,
    message: null,
    errorMessage: null,
    forbiddenState: false,
    forbiddenErrorMessage: null,
    corsErrorMessage: null,
    validationResult: null,
    serviceType: null,
  },
};

const validateToggleEnforceSSOSlice = createSlice({
  name: 'validateToggleEnforceSSO',
  initialState,
  reducers: {
    resetValidateToggleEnforceSSOState(state) {
      state.validateToggleEnforceSSOState = initialState.validateToggleEnforceSSOState;
    },
  },
  extraReducers: {
    ...validateToggleEnforceSSOExtraReducers,
  },
});

export { validateToggleEnforceSSOAsync };
export const { resetValidateToggleEnforceSSOState } = validateToggleEnforceSSOSlice.actions;
export default validateToggleEnforceSSOSlice.reducer;
