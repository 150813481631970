import { createSlice } from '@reduxjs/toolkit';
import {
  fetchMailboxBulkPurgeListAsync,
  mailboxBulkPurgeListReducers,
} from './serviceActions/fetchMailboxBulkPurgeListAsync';

const initialState = {
  mailboxBulkPurgeEachListState: {
    fetching: false,
    bulkPurgeListData: [],
    errorMessage: null,
    pagination: [],
    statusCode: null,
    forbiddenState: false,
    forbiddenErrorMessage: {},
    bulkPurgeCorsErrorMessage: null,
  },
};

const mailboxBulkPurgeEachListSlice = createSlice({
  name: 'MailboxBulkPurgeList',
  initialState,
  reducers: {
    resetMailboxBulkPurgeEachListStatus(state) {
      state.mailboxBulkPurgeEachListState = initialState.mailboxBulkPurgeEachListState;
    },
    resetMailboxBulkPurgeEachListStatusData(state) {
      state.mailboxBulkPurgeEachListState.bulkPurgeListData =
        initialState.mailboxBulkPurgeEachListState.bulkPurgeListData;
    },
  },
  extraReducers: {
    ...mailboxBulkPurgeListReducers,
  },
});

export { fetchMailboxBulkPurgeListAsync };
export const { resetMailboxBulkPurgeEachListStatus, resetMailboxBulkPurgeEachListStatusData } =
  mailboxBulkPurgeEachListSlice.actions;
export default mailboxBulkPurgeEachListSlice.reducer;
