import { createSlice } from '@reduxjs/toolkit';

import {
  getOrganizationInformationAsync,
  getOrganizationInformationReducers,
} from './serviceActions/getOrganizationInformationAsync';

const initialState = {
  getOrganizationInformationState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    paginationList: null,
    organizationInformation: [],
    forbiddenState: false,
    forbiddenErrorMessage: null,
    organizationInformationCorsErrorMessage: null,
  },
};

const organizationInformationSlice = createSlice({
  name: 'organizationInformation',
  initialState,
  reducers: {
    resetOrganizationInformationState(state) {
      state.getOrganizationInformationState = initialState.getOrganizationInformationState;
    },
    resetOrganizationInformationStatusCodeState(state) {
      state.getOrganizationInformationState.statusCode =
        initialState.getOrganizationInformationState.statusCode;
    },
  },
  extraReducers: {
    ...getOrganizationInformationReducers,
  },
});

export { getOrganizationInformationAsync };
export const { resetOrganizationInformationState, resetOrganizationInformationStatusCodeState } =
  organizationInformationSlice.actions;
export default organizationInformationSlice.reducer;
