import { createSlice } from '@reduxjs/toolkit';

import {
  userDiscrepancyReportAsync,
  userDiscrepancyReportExtraReducers,
} from './serviceActions/userDiscrepancyAsync';

const initialState = {
  userDiscrepancyReportState: {
    fetching: false,
    status: null,
    error: null,
    message: null,
    errorMessage: null,
    forbiddenState: false,
    forbiddenErrorMessage: null,
    userDiscrepancyReportCorsErrorMessage: null,
    serviceType: null,
  },
};

const userDiscrepancyReportSlice = createSlice({
  name: 'userDiscrepancyReport',
  initialState,
  reducers: {
    resetUserDiscrepancyReportState(state) {
      state.userDiscrepancyReportState = initialState.userDiscrepancyReportState;
    },
  },
  extraReducers: {
    ...userDiscrepancyReportExtraReducers,
  },
});

export { userDiscrepancyReportAsync };

export const { resetUserDiscrepancyReportState } = userDiscrepancyReportSlice.actions;
export default userDiscrepancyReportSlice.reducer;
