import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { Alert, IconButton, Snackbar, Stack } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import ImportExportIcon from '@mui/icons-material/ImportExport';

import {
  ITBox,
  QboAdvanceTable,
  QboBreadcrumbs,
  QboButton,
  QboCard,
  QboTypography,
  QboModal,
  QboSnackbar,
  QboAlert,
} from '@ui/Components';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import { dataIngestionPath, listReportsPagePath } from '@config/Routes/WebClientRoutes';
import DataIngestionJobFormSection from '@ui/Sections/DataIngestionPage/DataIngestionJobFormSection';
import { resetIngestionJobFormSubmit } from '@features/ingestion/ingestionJobSlice';
import IngestionListReportsPageSection from '@pages/ReportsPage/IngestionListReportsPageSection';
import { resetIngestionReRunState } from '@features/ingestion/ingestionReRunSlice';
import { resetRemoveIngestionState } from '@features/ingestion/removeIngestionSlice';
import FailedEmlIngestionReportsPage from '@pages/ReportsPage/FailedEmlIngestionReportsPage';
import BaseSetting from '@config/BaseSetting';
import useDetailsOfIngestionList from './pageHooks/useDetailsOfIngestionList';
import useForm from './pageHooks/useForm';

export default function DataIngestionListPage() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [detailsOfIngestionsList, setDetailsOfIngestionsList] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [noStatusFlag, setNoStatusFlag] = useState(false);
  const [ingestionId, setIngestiponId] = useState(0);
  const [openChild, setOpenChild] = useState(false);
  const [openChildEmlPage, setOpenChildEmlPage] = useState(false);
  const [failedEmlIngestionId, setFailedEmlIngestiponId] = useState(0);
  const [selectedValue, setSelectedValue] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [error, setError] = useState('');

  const paramsObject = {
    filterName: 'Status',
    filterKey: 'status',
    filterValue: 'Failed',
  };

  const selectObject = {
    selectFilterName: 'Status',
    selectFilterKey: 'status',
    selectFilterValue: 'DownloadFailed',
  };

  const {
    formData,
    organizationOwnerIdAttributes,
    mappingFileAttributes,
    oneItemStructureAttributes,
    multipleItemStructureAttributes,
    emailAccountStructureAttributes,
    fileNameStructureAttributes,
    validateAll,
    isValid,
    resetForm,
    onSubmit,
  } = useForm();

  const {
    handleFetchDetailsOfIngestionList,
    getFilterdDataFromResponse,
    handleIngestionJobReRunAsync,
    handleRemoveDataIngestionItemsAsync,
  } = useDetailsOfIngestionList();

  const { getDetailsOfIngestionDataState } = useSelector(
    (stateList) => stateList.detailsOfIngestions
  );

  const { getReRunDataIngestionListItemState } = useSelector(
    (stateList) => stateList.ingestionJobReRunState
  );

  const { getRemoveDataIngestionItemsState } = useSelector(
    (stateList) => stateList.removeIngestionState
  );

  const { fetching, detailsOfIngestionList, detailsOfPaginationList, success, corsErrorState } =
    getDetailsOfIngestionDataState;

  const { fetching: reRunFetchingState, statusCode } = getReRunDataIngestionListItemState;

  const { fetching: removeIngestionFetchingState, statusCode: status } =
    getRemoveDataIngestionItemsState;

  useEffect(() => {
    setRowSelection({});
    getFilterdDataFromResponse(detailsOfIngestionList, setDetailsOfIngestionsList);
  }, [detailsOfIngestionList]);

  const handleApiRequest = () => {
    if (!fetching) {
      handleFetchDetailsOfIngestionList(
        paramsObject.filterName,
        state?.row,
        pagination.pageIndex + 1,
        pagination.pageSize,
        selectedValue && selectedValue
      );
    }
  };

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    handleApiRequest();
  }, [state?.row, pagination, selectedValue]);

  const handleOnRefresh = () => {
    handleApiRequest();
  };

  const handleOnReRunFailedItems = () => {
    if (isEmpty(rowSelection)) {
      setError('No failed items selected');
      setOpen(true);
    }
    if (!reRunFetchingState && !isEmpty(rowSelection)) {
      handleIngestionJobReRunAsync({
        ingestionJobID: state?.row,
        selectedItems: Object.keys(rowSelection),
      });
    }
  };

  const handleOnRemoveItems = () => {
    if (isEmpty(rowSelection)) {
      setError('No items selected');
      setOpen(true);
    }

    if (!removeIngestionFetchingState && !isEmpty(rowSelection)) {
      handleRemoveDataIngestionItemsAsync({
        ingestionJobID: state?.row,
        selectedItems: Object.keys(rowSelection),
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (statusCode === 200) {
      dispatch(resetIngestionReRunState());
      handleApiRequest();
    }
    if (statusCode === 404) {
      dispatch(resetIngestionReRunState());
      setOpen(true);
    }
  }, [statusCode]);

  useEffect(() => {
    if (status === 200) {
      dispatch(resetRemoveIngestionState());
      handleApiRequest();
    }
    if (status === 404) {
      dispatch(resetRemoveIngestionState());
      setOpen(true);
    }
  }, [status]);

  useEffect(() => {
    if (success && detailsOfIngestionsList?.length === 0) {
      setNoStatusFlag(true);
    } else if (!success || detailsOfIngestionsList?.length !== 0) {
      setNoStatusFlag(false);
    }
  }, [success, detailsOfIngestionsList?.length]);

  const onClickRowFirstIcon = (row) => {
    setOpenChild(true);
    setIngestiponId(row?.id);
    // navigate(listReportsPagePath, { state: { row: state?.row, ingestion: row?.id } });
  };

  const onClickRowSecondIcon = (row) => {
    setFailedEmlIngestiponId(row?.id);
    setOpenChildEmlPage(true);
  };

  const iconData = [
    {
      icon: <ShowChartIcon />,
      tooltipTitle: 'Statistics Page',
      style: { color: 'rgb(0, 3, 9)' },
      onClick: onClickRowFirstIcon,
      disableOnStatus: false,
      tooltipPlacement: 'left',
    },
    {
      icon: <ImportExportIcon />,
      tooltipTitle: 'Failed EML Items',
      style: { color: 'rgb(0, 3, 9)' },
      onClick: onClickRowSecondIcon,
      disableOnStatus: true,
      tooltipPlacement: 'right',
      disabledValue: 'Failed',
    },
  ];

  const enableEditing = {
    isEdit: true,
    status: 'Failed',
  };

  const customActionsList = {
    labelSize: 'small',
    minWidth: 120,
    inputLabel: 'Status',
    selectedValue,
    selectLabel: 'Status',
    handleChange,
    dropdownListItem: [
      'Queued',
      'Success',
      'Failed',
      'DownloadSuccess',
      'DownloadFailed',
      'PartiallyCompleted',
      'IngestionInProgress',
      'DownloadInProgress',
    ],
    isCustomActionEnable: true,
  };

  const renderTheElement = () => {
    let content;

    if (openChild) {
      content = (
        <IngestionListReportsPageSection
          pagination={pagination}
          setPagination={setPagination}
          ingestionId={ingestionId}
          setOpenChild={setOpenChild}
        />
      );
    } else if (openChildEmlPage) {
      content = (
        <FailedEmlIngestionReportsPage
          pagination={pagination}
          setPagination={setPagination}
          failedEmlIngestionId={failedEmlIngestionId}
          setOpenChildEmlPage={setOpenChildEmlPage}
        />
      );
    } else {
      const snackbarSection = (
        <QboSnackbar
          open={open}
          message={isEmpty(error) ? 'No failed items found' : error}
          onClose={handleClose}
          type="error"
        />
      );

      const headerSection = (
        <>
          <QboTypography className="HeaderPageBlock__heading" variant="h4">
            {t('data_ingestion_list_page.header.text').replace('00', state?.row)}
          </QboTypography>
          <br />
        </>
      );

      const cardSection = (
        <>
          {corsErrorState && (
            <QboAlert type="error" style={{ fontSize: '1.15em' }}>
              {corsErrorState}
            </QboAlert>
          )}
          <QboCard>
            <ITBox className="SystemStatusSection__wrapper" direction="row">
              <span style={{ padding: '2.2rem 1rem 1.8rem 1rem' }}>
                {' '}
                <QboBreadcrumbs>
                  <Link
                    to={dataIngestionPath}
                    style={{ color: '#1c63d5', fontSize: '1.5rem', textDecoration: 'underline' }}>
                    {t('data_ingestion_list_page.data_ingestion')}
                  </Link>
                  <QboTypography color="text.primary" style={{ fontSize: '1.5rem' }}>
                    {t('data_ingestion_list_page.data_ingestion_list')}
                  </QboTypography>
                </QboBreadcrumbs>
              </span>
              <ITBox className="SystemStatusSection__create-button">
                <IconButton
                  sx={{ position: 'relative', right: '0.7rem' }}
                  onClick={handleOnRefresh}>
                  <RefreshIcon sx={{ width: '2rem', height: '2rem' }} />
                </IconButton>
                &nbsp;
                <QboButton variant="contained" onClick={() => setShowModal(true)}>
                  {t('data_ingestion_list_page.data_ingestion_job_button')}
                </QboButton>
                &nbsp;&nbsp;
                <QboButton variant="contained" onClick={handleOnReRunFailedItems}>
                  {t('data_ingestion_list_page.data_ingestion_re_run_job_button')}
                </QboButton>
                &nbsp;&nbsp;
                <QboButton variant="contained" onClick={handleOnRemoveItems}>
                  {t('data_ingestion_list_page.data_ingestion_remove_item_button')}
                </QboButton>
              </ITBox>
            </ITBox>
            <QboCard noPadding isMainWrapper>
              {(detailsOfIngestionsList?.length > 0 || fetching || selectedValue) && (
                <QboAdvanceTable
                  filterKey={paramsObject.filterKey}
                  filterValue={paramsObject.filterValue}
                  isCheckBoxFilterEnable={!false}
                  rowSelection={rowSelection}
                  setRowSelection={setRowSelection}
                  enableRowSelection={!false}
                  enablePaginationProp={detailsOfIngestionsList?.length ? !false : false}
                  enableSorting
                  iconData={iconData}
                  isLoading={fetching}
                  rows={detailsOfIngestionsList}
                  headers={BaseSetting.dataIngestionListItemHeaders}
                  enableEditing={enableEditing}
                  pagination={pagination}
                  setPagination={setPagination}
                  rowsCount={detailsOfPaginationList?.TotalCount}
                  customActionsList={customActionsList}
                  selectFilterKey={selectObject.selectFilterKey}
                  selectFilterValue={selectObject.selectFilterValue}
                />
              )}
              {((noStatusFlag && !selectedValue) || corsErrorState) && <NoStatusSection />}
            </QboCard>

            <QboModal
              onClose={() => {
                setShowModal(false);
                resetForm();
                dispatch(resetIngestionJobFormSubmit());
                handleApiRequest();
              }}
              open={showModal}
              title={t('data_ingestion_job_page.modal.confirmation.title_text')}
              showCloseIcon
              classNameStyle="DataIngestionJobForm_modal"
              preventOutsideClick>
              <DataIngestionJobFormSection
                formData={formData}
                organizationOwnerIdAttributes={organizationOwnerIdAttributes}
                mappingFileAttributes={mappingFileAttributes}
                oneItemStructureAttributes={oneItemStructureAttributes}
                multipleItemStructureAttributes={multipleItemStructureAttributes}
                emailAccountStructureAttributes={emailAccountStructureAttributes}
                fileNameStructureAttributes={fileNameStructureAttributes}
                validateAll={validateAll}
                isValid={isValid}
                ingestionID={state?.row}
                resetForm={resetForm}
                onSubmit={onSubmit}
              />
            </QboModal>
          </QboCard>
        </>
      );

      content = (
        <>
          {snackbarSection}
          {headerSection}
          {cardSection}
        </>
      );
    }
    return content;
  };

  return renderTheElement();
}
