import { createSlice } from '@reduxjs/toolkit';
import {
  toolsRequestDetailsAsync,
  toolsRequestDetailsReducers,
} from './serviceActions/toolsRequestDetailsAsync';

const initialState = {
  toolsRequestDetailsState: {
    fetching: false,
    data: {},
    errorMessage: null,
    statusCode: null,
    forbiddenState: false,
    forbiddenErrorMessage: {},
    corsErrorMessage: null,
  },
};

const toolsRequestDetailsSlice = createSlice({
  name: 'ToolsRequestDetails',
  initialState,
  reducers: {
    resetToolsRequestDetailsState(state) {
      state.toolsRequestDetailsState = initialState.toolsRequestDetailsState;
    },
  },
  extraReducers: {
    ...toolsRequestDetailsReducers,
  },
});

export { toolsRequestDetailsAsync, toolsRequestDetailsReducers };
export const { resetToolsRequestDetailsState } = toolsRequestDetailsSlice.actions;
export default toolsRequestDetailsSlice.reducer;
