import { createSlice } from '@reduxjs/toolkit';
import {
  fetchBackupStatusListAsync,
  getBackupStatusListExtraReducers,
} from './serviceActions/fetchBackupStatusListAsync';

const initialState = {
  backupStatusListState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    paginations: {},
    backupStatusList: [],
    forbiddenState: false,
    forbiddenErrorMessage: null,
    backupStatusListCorsErrorMessage: null,
  },
};

const backupStatusSlice = createSlice({
  name: 'backupStatusList',
  initialState,
  reducers: {
    resetBackupStatusListState(state) {
      state.backupStatusListState = initialState.backupStatusListState;
    },
  },
  extraReducers: {
    ...getBackupStatusListExtraReducers,
  },
});

export { fetchBackupStatusListAsync };
export const { resetBackupStatusListState } = backupStatusSlice.actions;
export default backupStatusSlice.reducer;
