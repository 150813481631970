import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPurgeSharedDriveAPI } from '@services/WebApiService';

const getPurgeSharedDriveChangeAsync = createAsyncThunk(
  'PurgeSharedDrive/getPurgeSharedDrive',
  async (paramsList) => {
    try {
      const data = await getPurgeSharedDriveAPI(paramsList);
      return data;
    } catch (error) {
      return error.response;
    }
  }
);

const { pending, fulfilled, rejected } = getPurgeSharedDriveChangeAsync;

const getPurgeSharedDriveReducers = {
  [pending]: (state) => {
    state.getPurgeSharedDriveState.fetching = true;
  },
  [rejected]: (state) => {
    state.getPurgeSharedDriveState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.getPurgeSharedDriveState.fetching = false;

    if (action?.payload?.data?.message) {
      state.getPurgeSharedDriveState.errorMessage = action?.payload?.data?.message;
    }

    if (action?.payload?.status === 403) {
      state.getPurgeSharedDriveState.forbiddenState = true;
      state.getPurgeSharedDriveState.forbiddenErrorMessage = {
        heading: 'Forbidden Error',
        caption: 'You are not authorize to access this resource',
      };
    }

    if (action?.payload?.data?.data) {
      state.getPurgeSharedDriveState.forbiddenState = false;
      state.getPurgeSharedDriveState.forbiddenErrorMessage = {};
      state.getPurgeSharedDriveState.data = action?.payload?.data?.data;
      state.getPurgeSharedDriveState.success = true;
    }
    if (action?.payload?.data?.pagination) {
      state.getPurgeSharedDriveState.pagination = JSON.parse(action?.payload?.data?.pagination);
    }

    if (!action?.payload) {
      state.getPurgeSharedDriveState.getPurgeSharedDriveCorsErrorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.code) {
      state.getPurgeSharedDriveState.error = action.payload.code;
    }
  },
};

export { getPurgeSharedDriveChangeAsync, getPurgeSharedDriveReducers };

export default getPurgeSharedDriveChangeAsync;
