import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import PropTypes from 'prop-types';

export default function QboRadioButton(props) {
  const { radioButtonData, value, setValue, error, setError } = props;

  const handleRadioChange = (event) => {
    setValue(event.target.value);
    setError(false);
  };

  return (
    <FormControl error={error} variant="standard">
      <RadioGroup name="select-job-type" value={value} onChange={handleRadioChange}>
        {radioButtonData &&
          radioButtonData?.map((btn) => (
            <FormControlLabel
              key={btn?.id}
              style={{
                fontWeight: '500 !important',
                fontSize: '1.4rem !important',
                color: '#42526e !important',
              }}
              value={btn?.value}
              control={<Radio />}
              label={btn?.radioLabel}
            />
          ))}
      </RadioGroup>
    </FormControl>
  );
}

QboRadioButton.propTypes = {
  radioButtonData: PropTypes.array,
  value: PropTypes.string,
  setValue: PropTypes.func,
  error: PropTypes.bool,
  setError: PropTypes.func,
};

QboRadioButton.defaultProps = {
  radioButtonData: [],
  value: null,
  setValue: () => {},
  error: false,
  setError: () => {},
};
