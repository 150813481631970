import { createSlice } from '@reduxjs/toolkit';

import {
  runLicenseCheckerAsync,
  runLicenseCheckerExtraReducers,
} from './serviceActions/runLicenseCheckerAsync';

const initialState = {
  runLicenseCheckerState: {
    fetching: false,
    status: null,
    error: null,
    message: null,
    errorMessage: null,
    forbiddenState: false,
    forbiddenErrorMessage: null,
    runLicenseCheckerCorsErrorMessage: null,
    serviceType: null,
  },
};

const runLicenseCheckerSlice = createSlice({
  name: 'runLicenseChecker',
  initialState,
  reducers: {
    resetRunLicenseCheckerState(state) {
      state.runLicenseCheckerState = initialState.runLicenseCheckerState;
    },
  },
  extraReducers: {
    ...runLicenseCheckerExtraReducers,
  },
});

export { runLicenseCheckerAsync };

export const { resetRunLicenseCheckerState } = runLicenseCheckerSlice.actions;
export default runLicenseCheckerSlice.reducer;
