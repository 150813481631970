import { createSlice } from '@reduxjs/toolkit';

import {
  createExcludedMailboxesAsync,
  createExcludedMailboxesExtraReducers,
} from './serviceActions/submitExcludedMailboxesAsync';

const initialState = {
  excludedMailboxesState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    message: null,
    mailboxesList: [],
  },
};

const excludedMailboxesSlice = createSlice({
  name: 'CreateExcludedMailboxes',
  initialState,
  reducers: {
    resetCreateExcludedMailboxesState(state) {
      state.excludedMailboxesState = initialState.excludedMailboxesState;
    },
  },
  extraReducers: {
    ...createExcludedMailboxesExtraReducers,
  },
});

export { createExcludedMailboxesAsync };
export const { resetCreateExcludedMailboxesState } = excludedMailboxesSlice.actions;
export default excludedMailboxesSlice.reducer;
