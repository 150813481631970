import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { QboTypography } from '@ui/Components';
import { dataIngestionListPath } from '@config/Routes/WebClientRoutes';
import BaseSetting from '@config/BaseSetting';
import useIngestionReportsSection from './pageHooks/useIngestionReportsSection';
import ReportsPage from './ReportsPage';

function IngestionListReportsPageSection(props) {
  const { pagination, setPagination, ingestionId, setOpenChild } = props;
  const { state } = useLocation();
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [reportsData, setReportsData] = useState([]);
  const { handleFetchIngestionReportListItem, getConvertedDataFromResponse } =
    useIngestionReportsSection();
  const { getIngestionReportListItemState } = useSelector(
    (stateList) => stateList.ingestionReportListItem
  );

  const { fetching, reportsPaginationListItem, ingestionReportsListItem } =
    getIngestionReportListItemState;

  useEffect(() => {
    if (ingestionReportsListItem?.length) {
      getConvertedDataFromResponse(ingestionReportsListItem, setReportsData);
    }
  }, [ingestionReportsListItem]);

  const fetchHandler = () => {
    if (!fetching) {
      handleFetchIngestionReportListItem(ingestionId);
    }
  };

  const handleOnCloseClick = () => {
    setOpenChild(false);
    setPagination(pagination);
  };

  const populateTitle = () => {
    return (
      <QboTypography sx={{ color: '#42526E' }} className="HeaderPageBlock__heading" variant="h4">
        {t('reports_page.header.text')}({t('reports_page.header.ingestion_item_id')}
        {ingestionId})
      </QboTypography>
    );
  };

  useEffect(() => {
    if (ingestionId) {
      setShowModal(true);
    }
  }, [ingestionId]);

  useEffect(() => {
    fetchHandler();
  }, [ingestionId]);

  return (
    <ReportsPage
      isNoteShow={reportsData?.length > 0}
      noteMessage={t('reports_page.note_message')}
      classNameStyle={
        (!reportsData?.length && !fetching && 'DataIngestion_reports_modal_empty_data') ||
        (reportsData?.length && 'DataIngestion_reports_modal_min_data')
      }
      handleOnCloseClick={handleOnCloseClick}
      enableTopToolBar={false}
      enablePaginationProp={false}
      showModal={showModal}
      setShowModal={setShowModal}
      pageTitle={populateTitle()}
      reportsData={reportsData}
      fetching={fetching}
      headersList={BaseSetting.ingestionReportsListHeaders}
      paginationList={reportsPaginationListItem}
      ingestionID={state?.row}
      path={dataIngestionListPath}
    />
  );
}

IngestionListReportsPageSection.propTypes = {
  pagination: PropTypes.object,
  setPagination: PropTypes.func,
  setOpenChild: PropTypes.func,
  ingestionId: PropTypes.number,
};

IngestionListReportsPageSection.defaultProps = {
  pagination: {},
  setPagination: () => {},
  setOpenChild: () => {},
  ingestionId: 0,
};

export default IngestionListReportsPageSection;
