import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const MAX_ERRORS = 10;

export default function useCsvParser(expectedHeaders) {
  const [originalCsv, setOriginalCsv] = useState();
  const [result, setResult] = useState({ isValid: false });

  const { t } = useTranslation();

  const parseCsv = (content) => {
    const lines = content.split(/\n|\r|\r\n/);
    if (lines.length === 0) {
      setResult({ errors: [t('parse_csv.invalid_csv_format')], isValid: false });
      return;
    }

    // parse header
    const actualHeaders = lines[0].split(',');
    const missingHeaders = expectedHeaders.filter((h) => !actualHeaders.includes(h));
    const invalidHeaders = actualHeaders.filter((h) => !expectedHeaders.includes(h));
    const errors = [];
    if (missingHeaders.length > 0) {
      errors.push(t('parse_csv.missing_column', { columns: missingHeaders.join(', ') }));
    }
    if (invalidHeaders.length > 0) {
      errors.push(t('parse_csv.invalid_column', { columns: invalidHeaders.join(', ') }));
    }
    if (errors.length > 0) {
      setResult({ errors, isValid: false });
      return;
    }

    // parse lines
    const results = [];
    for (let i = 1; i < lines.length; i += 1) {
      if (lines[i].trim().length > 0) {
        const items = lines[i].split(',');

        if (items.length !== actualHeaders.length) {
          errors.push(t('parse_csv.invalid_number_of_items', { line: i }));
          if (errors.length >= MAX_ERRORS) {
            break;
          }
        }

        if (!errors || errors.length === 0) {
          const obj = {};
          for (let j = 0; j < items.length; j += 1) {
            obj[actualHeaders[j]] = items[j];
          }
          results.push(obj);
        }
      }
    }

    if (errors.length > 0) {
      setResult({ errors, isValid: false });
    } else if (results.length <= 0) {
      errors.push(t('parse_csv.empty'));
      setResult({ errors, isValid: false });
    } else {
      setOriginalCsv(content);
      setResult({ results, isValid: true });
    }
  };

  const readFileContent = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => resolve(e.target.result);
      reader.onerror = (e) => reject(e);
      reader.readAsText(file);
    });
  };

  const handleOnFileUpload = async (event) => {
    const file = event.target.files[0];

    if (file) {
      const content = await readFileContent(file);
      parseCsv(content);
    }
  };

  const reset = () => {
    setResult({ isValid: false });
    setOriginalCsv(null);
  };

  return {
    originalCsv,
    result,
    reset,
    handleOnFileUpload,
  };
}
