import React from 'react';
import PropTypes from 'prop-types';
import { ITBox, QboButton } from '@ui/Components';
import { IconButton } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

export default function NewRequestButtonSection({ title, show, onClickRefresh, onClickNew }) {
  if (!show) return null;

  return (
    <ITBox className="SystemStatusSection__wrapper" direction="column">
      {show && (
        <ITBox className="SystemStatusSection__create-button">
          <IconButton sx={{ position: 'relative', right: '0.7rem' }} onClick={onClickRefresh}>
            <RefreshIcon sx={{ width: '2rem', height: '2rem' }} />
          </IconButton>
          &nbsp;
          <QboButton variant="contained" onClick={onClickNew}>
            {title}
          </QboButton>
        </ITBox>
      )}
    </ITBox>
  );
}

NewRequestButtonSection.propTypes = {
  show: PropTypes.bool,
  onClickRefresh: PropTypes.func,
  onClickNew: PropTypes.func,
  title: PropTypes.string,
};

NewRequestButtonSection.defaultProps = {
  show: false,
  onClickRefresh: () => {},
  onClickNew: () => {},
  title: '',
};
