import { createSlice } from '@reduxjs/toolkit';
import {
  getMailBoxSyncAsync,
  getMailboxSyncReducers,
} from './serviceActions/mailBoxSyncRequestAsync';

const initialState = {
  mailSyncRequestState: {
    fetching: false,
    success: false,
    data: [],
    error: null,
    errorMessage: null,
    pagination: [],
    forbiddenState: false,
    forbiddenErrorMessage: {},
    mailSyncCorsErrorMessage: null,
  },
};

const mailSyncRequestSlice = createSlice({
  name: 'MailSync',
  initialState,
  reducers: {
    resetMailSyncStatus(state) {
      state.mailSyncRequestState = initialState.mailSyncRequestState;
    },
  },
  extraReducers: {
    ...getMailboxSyncReducers,
  },
});

export { getMailBoxSyncAsync };
export const { resetMailSyncStatus } = mailSyncRequestSlice.actions;
export default mailSyncRequestSlice.reducer;
