import { createAsyncThunk } from '@reduxjs/toolkit';
import { getDomainBackupsAPI } from '@services/WebApiService';

const getDomainBackupsAsync = createAsyncThunk(
  'DomainBackups/getDomainBackups',
  async (paramsList) => {
    try {
      const data = await getDomainBackupsAPI(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = getDomainBackupsAsync;

const getDomainBackupsExtraReducers = {
  [pending]: (state) => {
    state.getDomainBackupsState.fetching = true;
  },
  [rejected]: (state) => {
    state.getDomainBackupsState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.getDomainBackupsState.fetching = false;

    if (action?.payload?.error) {
      state.getDomainBackupsState.errorMessage = action.payload.error?.data.message;
      state.getDomainBackupsState.status = action.payload.error?.status;

      if (action?.payload?.error?.status === 403) {
        state.getDomainBackupsState.forbiddenState = true;
        state.getDomainBackupsState.forbiddenErrorMessage = {
          heading: 'Forbidden Error',
          caption: 'You are not authorize to access this resource',
        };
      }
    }

    if (!action?.payload?.error && !action?.payload?.data) {
      state.getDomainBackupsState.domainBackupsCorsErrorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.data?.data) {
      state.getDomainBackupsState.forbiddenState = false;
      state.getDomainBackupsState.forbiddenErrorMessage = {};
      state.getDomainBackupsState.status = action.payload?.status;
      state.getDomainBackupsState.domainBackupsList = action?.payload?.data?.data;

      if (action?.payload?.data?.pagination) {
        state.getDomainBackupsState.paginations = JSON.parse(action?.payload?.data?.pagination);
      }
    }
  },
};

export { getDomainBackupsAsync, getDomainBackupsExtraReducers };
