import { serviceTypesAsync, serviceTypesReducers } from './serviceActions/serviceTypesAsync';

const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
  serviceTypesState: {
    fetching: false,
    data: [],
    errorMessage: null,
    statusCode: null,
    forbiddenState: false,
    forbiddenErrorMessage: {},
    corsErrorMessage: null,
  },
};
const serviceTypesSlice = createSlice({
  name: 'ServiceTypes',
  initialState,
  reducers: {
    resetServiceTypesState(state) {
      state.serviceTypesState = initialState.serviceTypesState;
    },
  },
  extraReducers: {
    ...serviceTypesReducers,
  },
});

export { serviceTypesAsync };
export const { resetServiceTypesState } = serviceTypesSlice.actions;
export default serviceTypesSlice.reducer;
