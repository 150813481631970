import { useEffect } from 'react';

export default function useDownload(data, contentType, fileName, resetData, onSuccess, onError) {
  const startDownload = (downloadData) => {
    try {
      const encodedData = `data:${contentType},${encodeURIComponent(downloadData)}`;
      const link = document.createElement('a');
      link.href = encodedData;
      link.download = fileName;
      document?.body?.appendChild(link);
      link.click();
      document?.body?.removeChild(link);
      if (onSuccess) onSuccess();
    } catch (err) {
      if (onError) onError(err);
    }
  };

  useEffect(() => {
    if (data) {
      startDownload(data);
      if (resetData) resetData();
    }
  }, [data]);
}
