import { useDispatch } from 'react-redux';
import { constant } from '@config/BaseSetting';
import useLocalStorage from '@hooks/useLocalStorage';
import { getTransferSubresellerAsync } from '@features/transferSubresellerService/getTransferSubresellerSlice';

export default function useTransferSubresellerService() {
  const dispatch = useDispatch();
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);

  const handleTransferSubresellerAsync = (pageIndex, pageSize) => {
    dispatch(
      getTransferSubresellerAsync({
        accessToken: accessTokenSet,
        reset: true,
        pageNumber: pageIndex,
        pageSize,
      })
    );
  };

  const transferType = {
    1: 'Transfer sub-reseller between distributor',
    2: 'Transfer direct reseller to distributor as sub-reseller',
    3: 'Transfer sub-reseller from distributor to make direct-reseller',
  };

  const processDataTransferSubreseller = (data, setTransferData) => {
    const modifiedData = data?.map((item) => {
      const payloadObj = JSON.parse(item.payload);
      const modifiedPayloadObj = {
        id: item?.id,
        'status-description': item['status-description'],
        description: payloadObj.description,
        sourceDistributorID:
          payloadObj?.sourceDistributorID === 0 ? '' : payloadObj?.sourceDistributorID,
        targetDistributorID:
          payloadObj?.targetDistributorID === 0 ? '' : payloadObj?.targetDistributorID,
        resellerIDToBeTransferred:
          payloadObj?.resellerIDToBeTransferred === 0 ? '' : payloadObj?.resellerIDToBeTransferred,
        effectiveTrasferDate: payloadObj?.effectiveTrasferDate,
        targetCurrency: payloadObj?.targetCurrency,
        newPriceArchiver: payloadObj?.newPriceArchiver,
        newPriceBackup: payloadObj?.newPriceBackup,
        newPriceQbo: payloadObj?.newPriceQbo,
        newPriceNfpArchiver: payloadObj?.newPriceNfpArchiver,
        newPriceNfpBackup: payloadObj?.newPriceNfpBackup,
        transferType: transferType[payloadObj?.transferType],
        targetOwner: payloadObj?.targetOwner,
        targetChannel: payloadObj?.targetChannel,
        'support-ticket': payloadObj['support-ticket'],
        'escalation-ticket': payloadObj['escalation-ticket'],
        'executed-on': item['executed-on'],
      };
      return modifiedPayloadObj;
    });
    return setTransferData(modifiedData);
  };

  return { handleTransferSubresellerAsync, processDataTransferSubreseller };
}
