import { createSlice } from '@reduxjs/toolkit';

import {
  getTenantAuthErrorsAsync,
  getTenantAuthErrorsExtraReducers,
} from './serviceActions/fetchTenantAuthErrorsAsync';

const initialState = {
  getTenantAuthErrorsState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    tenantAUthErrorsList: [],
    paginationList: {},
    forbiddenState: false,
    forbiddenErrorMessage: null,
    tenantAUthErrorsCorsErrorMessage: null,
  },
};

const getTenantAuthErrorsSlice = createSlice({
  name: 'TenantAuthErrors',
  initialState,
  reducers: {
    resetTenantAuthErrorsState(state) {
      state.getTenantAuthErrorsState = initialState.getTenantAuthErrorsState;
    },
  },
  extraReducers: {
    ...getTenantAuthErrorsExtraReducers,
  },
});

export { getTenantAuthErrorsAsync };
export const { resetTenantAuthErrorsState, resetTenantAuthErrorsList } =
  getTenantAuthErrorsSlice.actions;
export default getTenantAuthErrorsSlice.reducer;
