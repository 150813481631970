import { createSlice } from '@reduxjs/toolkit';

import { getPlansListAsync, getPlansListExtraReducers } from './serviceActions/getPlansListAsync';

const initialState = {
  getPlansListState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    paginationList: null,
    plansList: [],
    forbiddenState: false,
    forbiddenErrorMessage: null,
    plansListCorsErrorMessage: null,
  },
};

const plansListSlice = createSlice({
  name: 'PlansList',
  initialState,
  reducers: {
    resetPlansListState(state) {
      state.getPlansListState = initialState.getPlansListState;
    },
    resetPlansListStatusCodeState(state) {
      state.getPlansListState.statusCode = initialState.getPlansListState.statusCode;
    },
  },
  extraReducers: {
    ...getPlansListExtraReducers,
  },
});

export { getPlansListAsync };
export const { resetPlansListState, resetPlansListStatusCodeState } = plansListSlice.actions;
export default plansListSlice.reducer;
