import { createSlice } from '@reduxjs/toolkit';

import {
  credentilasSPAinfoAsync,
  credentilasSPAinfoExtraReducers,
} from './serviceActions/credentilasSPAinfoAsync';

const initialState = {
  credentialsSPAinfoState: {
    validateErrorMessage: null,
    statusCode: null,
    submitting: false,
    success: false,
    error: null,
    data: {},
  },
};

const credentilasSPAinfoSlice = createSlice({
  name: 'credentialsSPAinfo',
  initialState,
  reducers: {
    resetCredentialsSPAinfoState(state) {
      state.credentialsSPAinfoState = initialState.credentialsSPAinfoState;
    },
  },
  extraReducers: {
    ...credentilasSPAinfoExtraReducers,
  },
});

export { credentilasSPAinfoAsync };

export const { resetCredentialsSPAinfoState } = credentilasSPAinfoSlice.actions;
export default credentilasSPAinfoSlice.reducer;
