import { useState } from 'react';
import * as yup from 'yup';

const organizationOwnerIdName = 'organizationOwnerId';
const storageTypeName = 'storageType';
const mappingFileName = 'mappingFile';
const zipFilePasswordName = 'zipFilePassword';
const ftpUrlName = 'ftpUrl';
const ftpUsernameName = 'ftpUsername';
const ftpPasswordName = 'ftpPassword';
const s3BucketnameName = 's3bucketname';
const s3KeyName = 's3Key';
const folderName = 'folder';
const supportTicketName = 'supportTicket';
const escalationTicketName = 'escalationTicket';
const ingestionTypeName = 'ingestionType';
const descriptionName = 'description';
const retainFolderStructureName = 'retainFolderStructure';
const catchAllEmailAccountName = 'catchAllEmailAccount';
const s3StorageRegion = 's3StorageRegion';

const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

const InitialForm = {
  [organizationOwnerIdName]: '',
  [storageTypeName]: 1101,
  [mappingFileName]: '',
  [zipFilePasswordName]: '',
  [ftpUrlName]: '',
  [ftpUsernameName]: '',
  [ftpPasswordName]: '',
  [s3BucketnameName]: '',
  [s3KeyName]: '',
  [folderName]: '',
  [supportTicketName]: '',
  [escalationTicketName]: '',
  [ingestionTypeName]: 0,
  [catchAllEmailAccountName]: '',
  [descriptionName]: '',
  [retainFolderStructureName]: true,
  [s3StorageRegion]: '',
};

export default function useFormValidation() {
  const [isValid, setIsValid] = useState(false);
  const [errors, setErrors] = useState('');
  const [formData, setFormData] = useState(InitialForm);
  const storageType = {
    FTP: 1101,
    S3TYPE: 1102,
    PROSERV: 1103,
    PROSERV_S3: 1104,
  };

  const ingestionType = {
    archiver: 1,
    nonArchiver: 0,
  };

  const formSchema = yup.object().shape({
    [ingestionTypeName]: yup.number(),
    [catchAllEmailAccountName]: yup.string().when(ingestionTypeName, {
      is: ingestionType.archiver,
      then: () =>
        yup
          .string()
          .matches(emailRegex, 'Invalid email format')
          .transform((value) => (value === '' ? null : value))
          .nullable(),
    }),

    [organizationOwnerIdName]: yup
      .number()
      .typeError('Organization Owner Id is a required field and must be a number type')
      .required(),
    [storageTypeName]: yup.number().required(),
    [mappingFileName]: yup
      .mixed()
      .required()
      .test(
        'isValidType',
        'Not a valid type',
        (value) => ['txt', 'csv'].indexOf(value?.name?.split('.')?.pop()) > -1
      ),
    [zipFilePasswordName]: yup.string(),
    [ftpUrlName]: yup.string().when(storageTypeName, {
      is: storageType.FTP,
      then: () => yup.string().required('FTP Url is required field'),
    }),
    [ftpUsernameName]: yup.string().when(storageTypeName, {
      is: storageType.FTP,
      then: () => yup.string().required('FTP Username is required field'),
    }),
    [ftpPasswordName]: yup.string().when(storageTypeName, {
      is: storageType.FTP,
      then: () => yup.string().required('FTP Password is required field'),
    }),
    [s3BucketnameName]: yup.string().when(storageTypeName, {
      is: (value) => {
        return [storageType.S3TYPE, storageType.PROSERV_S3].includes(value);
      },
      then: () => yup.string().required('S3 Bucket Name is required field'),
    }),
    [s3KeyName]: yup.string().when(storageTypeName, {
      is: (value) => {
        return [storageType.S3TYPE, storageType.PROSERV_S3].includes(value);
      },
      then: () => yup.string().required('S3 Key is required field'),
    }),
    [folderName]: yup.string().when(storageTypeName, {
      is: storageType.PROSERV,
      then: () => yup.string(),
    }),
    [folderName]: yup.string().when(storageTypeName, {
      is: storageType.PROSERV_S3,
      then: () => yup.string(),
    }),
    [supportTicketName]: yup.string(),
    [retainFolderStructureName]: yup.bool(),
    [s3StorageRegion]: yup.string(),
  });

  const validate = async (value) => {
    // eslint-disable-next-line func-names
    await formSchema.validate(value, { abortEarly: false }).catch(function (err) {
      const errorLogs = err.inner.reduce((acc, error) => {
        return {
          ...acc,
          [error.path]: error.message,
        };
      }, {});
      setErrors(errorLogs);
      return errorLogs;
    });
    const validation = await formSchema.isValid(value);
    if (validation) {
      setErrors({});
    }
    setIsValid(validation);
  };

  const validateAll = () => {
    validate(formData);
  };

  const updateFormData = (newData) => {
    setFormData((prev) => {
      return { ...prev, ...newData };
    });
  };

  const organizationOwnerIdAttributes = {
    handleChange: async (value) => {
      updateFormData({ [organizationOwnerIdName]: value });
    },
    errorMessage: errors[organizationOwnerIdName],
    value: formData[organizationOwnerIdName],
  };
  const storageTypeAttributes = {
    handleChange: async (value) => {
      updateFormData({ [storageTypeName]: value });
    },
    errorMessage: errors[storageTypeName],
    value: formData[storageTypeName],
  };
  const mappingFileAttributes = {
    handleChange: async (value) => {
      updateFormData({ [mappingFileName]: value });
    },
    errorMessage: errors[mappingFileName],
    value: formData[mappingFileName],
  };
  const zipFilePasswordAttributes = {
    handleChange: async (value) => {
      updateFormData({ [zipFilePasswordName]: value });
    },
    errorMessage: errors[zipFilePasswordName],
    value: formData[zipFilePasswordName],
  };
  const ftpUrlAttributes = {
    handleChange: async (value) => {
      updateFormData({ [ftpUrlName]: value });
    },
    errorMessage: errors[ftpUrlName],
    value: formData[ftpUrlName],
  };
  const ftpUserNameAttributes = {
    handleChange: async (value) => {
      updateFormData({ [ftpUsernameName]: value });
    },
    errorMessage: errors[ftpUsernameName],
    value: formData[ftpUsernameName],
  };
  const ftpPasswordAttributes = {
    handleChange: async (value) => {
      updateFormData({ [ftpPasswordName]: value });
    },
    errorMessage: errors[ftpPasswordName],
    value: formData[ftpPasswordName],
  };
  const s3bucketnameAttributes = {
    handleChange: async (value) => {
      updateFormData({ [s3BucketnameName]: value });
    },
    errorMessage: errors[s3BucketnameName],
    value: formData[s3BucketnameName],
  };
  const s3KeyAttributes = {
    handleChange: async (value) => {
      updateFormData({ [s3KeyName]: value });
    },
    errorMessage: errors[s3KeyName],
    value: formData[s3KeyName],
  };
  const folderAttributes = {
    handleChange: async (value) => {
      updateFormData({ [folderName]: value });
    },
    errorMessage: errors[folderName],
    value: formData[folderName],
  };
  const supportTicketAttributes = {
    handleChange: async (value) => {
      updateFormData({ [supportTicketName]: value });
    },
    errorMessage: errors[supportTicketName],
    value: formData[supportTicketName],
  };
  const escalationTicketAttributes = {
    handleChange: async (value) => {
      updateFormData({ [escalationTicketName]: value });
    },
    value: formData[escalationTicketName],
  };
  const descriptionAttributes = {
    handleChange: async (value) => {
      updateFormData({ [descriptionName]: value });
    },
    value: formData[descriptionName],
  };
  const retainFolderStructureAttributes = {
    handleChange: async (value) => {
      updateFormData({ [retainFolderStructureName]: value });
    },
    value: formData[retainFolderStructureName],
  };

  const ingestionTypeAttributes = {
    handleChange: async (value) => {
      updateFormData({ [ingestionTypeName]: value });
    },
    value: formData[ingestionTypeName],
  };

  const catchAllEmailAccountAttributes = {
    handleChange: async (value) => {
      updateFormData({ [catchAllEmailAccountName]: value });
    },
    errorMessage: errors[catchAllEmailAccountName],
    value: formData[catchAllEmailAccountName],
  };

  const s3StorageRegionAttributes = {
    handleChange: async (value) => {
      updateFormData({ [s3StorageRegion]: value });
    },
    errorMessage: errors[s3StorageRegion],
    value: formData[s3StorageRegion],
  };

  const resetForm = () => {
    setFormData(InitialForm);
    setErrors({});
    setIsValid(false);
  };

  return {
    formData,
    organizationOwnerIdAttributes,
    ingestionTypeAttributes,
    catchAllEmailAccountAttributes,
    storageTypeAttributes,
    mappingFileAttributes,
    s3bucketnameAttributes,
    s3KeyAttributes,
    folderAttributes,
    ftpUrlAttributes,
    ftpPasswordAttributes,
    ftpUserNameAttributes,
    supportTicketAttributes,
    descriptionAttributes,
    escalationTicketAttributes,
    zipFilePasswordAttributes,
    retainFolderStructureAttributes,
    s3StorageRegionAttributes,
    validateAll,
    isValid,
    resetForm,
  };
}
