import { createSlice } from '@reduxjs/toolkit';
import {
  removeDataIngestionItemsAsync,
  getRemoveDataIngestionItemsExtraReducers,
} from './serviceActions/removeDataIngestionItemAsync';

const initialState = {
  getRemoveDataIngestionItemsState: {
    submitting: false,
    success: false,
    error: null,
    removeIngestionListResult: null,
    statusCode: null,
    errorMessage: null,
  },
};

const removeIngestionSlice = createSlice({
  name: 'IngestionReRun',
  initialState,
  reducers: {
    resetRemoveIngestionState(state) {
      state.getRemoveDataIngestionItemsState = initialState.getRemoveDataIngestionItemsState;
    },
  },
  extraReducers: {
    ...getRemoveDataIngestionItemsExtraReducers,
  },
});

export { removeDataIngestionItemsAsync };
export const { resetRemoveIngestionState } = removeIngestionSlice.actions;
export default removeIngestionSlice.reducer;
