import BaseSetting from '@config/BaseSetting';
import Routes from './Routes';

class WebMailboxApiRoutes extends Routes {
  getSelectedRegion() {
    this.selectRegion = localStorage.getItem('INT_STORAGE_SELECTED_REGIONS');

    return this.selectRegion?.split(' ')[0];
  }

  createMailBoxPath(params = {}, format = '') {
    return this.createUrl(`/${this.getSelectedRegion()}/api/v1.0/mailbox-change`, params, format);
  }

  getMailBoxChangeRequestPath(params = {}, format = '') {
    return this.createUrl(`/${this.getSelectedRegion()}/api/v1.0/mailbox-change`, params, format);
  }

  createMailBoxSyncPath(params = {}, format = '') {
    return this.createUrl(`/${this.getSelectedRegion()}/api/v1.0/mailbox-sync`, params, format);
  }

  getMailBoxSyncPath(params = {}, format = '') {
    return this.createUrl(`/${this.getSelectedRegion()}/api/v1.0/mailbox-sync`, params, format);
  }

  getExcludedMailboxesPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/excluded-mailbox-requests`,
      params,
      format
    );
  }

  createExcludedMailboxesPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/excluded-mailboxes`,
      params,
      format
    );
  }

  getAutoDiscoveryPath(params = {}, format = '') {
    return this.createUrl(`/${this.getSelectedRegion()}/api/v1.0/autodiscovery`, params, format);
  }

  createAutoDiscoveryPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/autodiscovery-status`,
      params,
      format
    );
  }

  getDataIngestionPath(params = {}, format = '') {
    return this.createUrl(`/${this.getSelectedRegion()}/api/v1.0/ingestions`, params, format);
  }

  getDetailsOfDataIngestionPath(params = {}, format = '') {
    return this.createUrl(`/${this.getSelectedRegion()}/api/v1.0/ingestions`, params, format);
  }

  createDataIngestionPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/initiate-ingestion`,
      params,
      format
    );
  }

  getFeatureFlagPath(params = {}, format = '') {
    return this.createUrl(`/api/v1.0/feature-flags`, params, format);
  }

  getMailboxBulkPurgePath(params = {}, format = '') {
    return this.createUrl(`/${this.getSelectedRegion()}/api/v1.0/purge-mail`, params, format);
  }

  createBulkPurgeRequestPath(params = {}, format = '') {
    return this.createUrl(`/${this.getSelectedRegion()}/api/v1.0/purge-mail`, params, format);
  }

  getMailboxBulkPurgeListPath(params = {}, format = '') {
    return this.createUrl(`/${this.getSelectedRegion()}/api/v1.0/purge-mail`, params, format);
  }

  getBulkPurgeMailStatusPath(params = {}, format = '') {
    return this.createUrl(`/${this.getSelectedRegion()}/api/v1.0/purge-mail`, params, format);
  }

  getDomainBackupsListPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/initiate-domain-o365`,
      params,
      format
    );
  }

  createDomainBackupsPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/initiate-domain-o365`,
      params,
      format
    );
  }

  getTransferSubresellerListPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/transfer-subreseller`,
      params,
      format
    );
  }

  getTransferSubscriptionListPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/subscription-transfer`,
      params,
      format
    );
  }

  getTransferSubscriptionInitiateListPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/subscription-transfer/initiate-request`,
      params,
      format
    );
  }

  createTransferSubscriptionInitiateListPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/subscription-transfer/initiate-request`,
      params,
      format
    );
  }

  getIngestionReportListPath(params = {}, format = '') {
    return this.createUrl(`/${this.getSelectedRegion()}/api/v1.0/ingestions`, params, format);
  }

  getIngestionReportListItemPath(params = {}, format = '') {
    return this.createUrl(`/${this.getSelectedRegion()}/api/v1.0/ingestion-stats`, params, format);
  }

  createDataIngestionJobPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/initiate-ingestion`,
      params,
      format
    );
  }

  reRunDataIngestionListItemPath(params = {}, format = '') {
    return this.createUrl(`/${this.getSelectedRegion()}/api/v1.0/rerun-ingestion`, params, format);
  }

  getIngestionFailedEmlItemPath(params = {}, format = '') {
    return this.createUrl(`/${this.getSelectedRegion()}/api/v1.0/failure-report`, params, format);
  }

  getSeatUsageReportsPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/seatusage-details`,
      params,
      format
    );
  }

  getSeatUsageReportsSummaryPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/seatusage-summary`,
      params,
      format
    );
  }

  exportSeatUsageReportPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/seatusage-details-export`,
      params,
      format
    );
  }

  removeDataIngestionItemsPath(params = {}, format = '') {
    return this.createUrl(`/${this.getSelectedRegion()}/api/v1.0/remove-ingestion`, params, format);
  }

  deleteDataIngestionJobPath(params = {}, format = '') {
    return this.createUrl(`/${this.getSelectedRegion()}/api/v1.0/delete-ingestion`, params, format);
  }

  submitPurgeExternalAccountPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/purge-external-account`,
      params,
      format
    );
  }

  getPurgeExternalAccountPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/purge-external-account`,
      params,
      format
    );
  }

  submitPurgeSharedDrivePath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/purge-shared-drive`,
      params,
      format
    );
  }

  getPurgeSharedDrivePath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/purge-shared-drive`,
      params,
      format
    );
  }

  getTransferPartnerPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/initiate-ingestion`,
      params,
      format
    );
  }

  submitTransferPartnerPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/transfer-subreseller/transfer`,
      params,
      format
    );
  }

  validateTransferPartnerPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/transfer-subreseller/validate`,
      params,
      format
    );
  }

  getPartnersListPath(params = {}, format = '') {
    return this.createUrl(`/${this.getSelectedRegion()}/api/v1.0/partners`, params, format);
  }

  getOrganizationsListPath(params = {}, format = '') {
    return this.createUrl(`/${this.getSelectedRegion()}/api/v1.0/organizations`, params, format);
  }

  exportOrganizationPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/organization-export`,
      params,
      format
    );
  }

  getOrganizationInformationPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/organization-details`,
      params,
      format
    );
  }

  getM365TenantPath(params = {}, format = '') {
    return this.createUrl(`/${this.getSelectedRegion()}/api/v1.0/m365-tenant-info`, params, format);
  }

  exportM365TenantPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/m365-tenant-info-export`,
      params,
      format
    );
  }

  getM365TenantDetailsPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/m365-tenant-details-info`,
      params,
      format
    );
  }

  getM365UsersTenantPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/m365-users-tenant-info`,
      params,
      format
    );
  }

  getGWSTenantPath(params = {}, format = '') {
    return this.createUrl(`/${this.getSelectedRegion()}/api/v1.0/gws-tenant-info`, params, format);
  }

  getGWSTenantDetailsPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/gws-credentials/details`,
      params,
      format
    );
  }

  getHEXTenantDetailsPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/hex-tenant-details-info`,
      params,
      format
    );
  }

  getHEXTenantPath(params = {}, format = '') {
    return this.createUrl(`/${this.getSelectedRegion()}/api/v1.0/hex-tenant-info`, params, format);
  }

  getPlansListPath(params = {}, format = '') {
    return this.createUrl(`/${this.getSelectedRegion()}/api/v1.0/plans`, params, format);
  }

  submitSeatCountChangePath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/seat-count-change`,
      params,
      format
    );
  }

  getSelectedResellerConfigsPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/reseller_settings_configs`,
      params,
      format
    );
  }

  getResellerNotificationsConfigPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/reseller_notification_settings`,
      params,
      format
    );
  }

  getSeatCountChangePath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/seat-count-change`,
      params,
      format
    );
  }

  getUsersListPath(params = {}, format = '') {
    return this.createUrl(`/${this.getSelectedRegion()}/api/v1.0/users`, params, format);
  }

  getOrganizationUsersListPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/organization-users`,
      params,
      format
    );
  }

  getMonthlyBackupReportsPath(params = {}, format = '') {
    return this.createUrl(`/${this.getSelectedRegion()}/api/v1.0/backup-report`, params, format);
  }

  exportMonthlyBackupReportPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/backup-report-export`,
      params,
      format
    );
  }

  getTenantAuthErrorsPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/tenant-auth-errors`,
      params,
      format
    );
  }

  createTenantAuthErrorsPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/tenant-auth-errors`,
      params,
      format
    );
  }

  getIngestionsQueuedPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/ingestions/queued`,
      params,
      format
    );
  }

  getIngestionsInProgressPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/ingestions/in-progress`,
      params,
      format
    );
  }

  getPartnersFilterListPath(params = {}, format = '') {
    return this.createUrl(`/${this.getSelectedRegion()}/api/v1.0/partners-filter`, params, format);
  }

  getOrganizationFilterListPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/organization-filter`,
      params,
      format
    );
  }

  getParentFilterListPath(params = {}, format = '') {
    return this.createUrl(`/${this.getSelectedRegion()}/api/v1.0/parent-filter`, params, format);
  }

  getSwitchCredentialListPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/switch-credential`,
      params,
      format
    );
  }

  getSwitchCredentialStatusPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/switch-credential/:id`,
      params,
      format
    );
  }

  getSwitchCredentialItemsPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/switch-credential/:id/items`,
      params,
      format
    );
  }

  createSwitchCredentialPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/switch-credential`,
      params,
      format
    );
  }

  bulkCreateOrganizationPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/bulk-create-organization`,
      params,
      format
    );
  }

  getBulkCreateOrganizationTemplatePath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/bulk-create-organization/template`,
      params,
      format
    );
  }

  getBulkCreateOrganizationItemsPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/bulk-create-organization/:id/items`,
      params,
      format
    );
  }

  bulkCreateSubResellerPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/bulk-create-subreseller`,
      params,
      format
    );
  }

  getBulkCreateSubResellerTemplatePath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/bulk-create-subreseller/template`,
      params,
      format
    );
  }

  getBulkCreateSubResellerItemsPath(params = {}, format = '') {
    return this.createUrl(
      `/${this.getSelectedRegion()}/api/v1.0/bulk-create-subreseller/:id/items`,
      params,
      format
    );
  }
}

const ApiMailboxRoutes = new WebMailboxApiRoutes(BaseSetting.graphConfig.internalGateWayHost);

export const mailBoxChangeAPI = ApiMailboxRoutes.createMailBoxPath();
export const getMailBoxChangeAPI = ApiMailboxRoutes.getMailBoxChangeRequestPath();
export const mailBoxSyncAPI = ApiMailboxRoutes.createMailBoxSyncPath();
export const getMailBoxSyncAPI = ApiMailboxRoutes.getMailBoxSyncPath();
export const getExcludedMailboxesAPI = ApiMailboxRoutes.getExcludedMailboxesPath();
export const createExcludedMailboxesAPI = ApiMailboxRoutes.createExcludedMailboxesPath();
export const getAutoDiscoveryAPI = ApiMailboxRoutes.getAutoDiscoveryPath();
export const createAutoDiscoveryAPI = ApiMailboxRoutes.createAutoDiscoveryPath();
export const getDataIngestionAPI = ApiMailboxRoutes.getDataIngestionPath();
export const getDetailsOfDataIngestionAPI = ApiMailboxRoutes.getDetailsOfDataIngestionPath();
export const createDataIngestionAPI = ApiMailboxRoutes.createDataIngestionPath();
export const getFeatureFlagAPI = ApiMailboxRoutes.getFeatureFlagPath();
export const getMailboxBulkPurgeAPI = ApiMailboxRoutes.getMailboxBulkPurgePath();
export const createBulkPurgeRequestAPI = ApiMailboxRoutes.createBulkPurgeRequestPath();
export const getMailboxBulkPurgeListAPI = ApiMailboxRoutes.getMailboxBulkPurgeListPath();
export const getMailboxBulkPurgeStatusAPI = ApiMailboxRoutes.getBulkPurgeMailStatusPath();
export const getDomainBackupsAPI = ApiMailboxRoutes.getDomainBackupsListPath();
export const createDomainBackupsAPI = ApiMailboxRoutes.createDomainBackupsPath();
export const getTransferSubresellerAPI = ApiMailboxRoutes.getTransferSubresellerListPath();
export const getIngestionReportListAPI = ApiMailboxRoutes.getIngestionReportListPath();
export const getIngestionReportListItemAPI = ApiMailboxRoutes.getIngestionReportListItemPath();
export const createTransferSubscriptionInitiateListAPI =
  ApiMailboxRoutes.createTransferSubscriptionInitiateListPath();
export const createDataIngestionJobAPI = ApiMailboxRoutes.createDataIngestionJobPath();
export const reRunDataIngestionListItemAPI = ApiMailboxRoutes.reRunDataIngestionListItemPath();
export const getSeatUsageReportsAPI = ApiMailboxRoutes.getSeatUsageReportsPath();
export const getSeatUsageReportsSummaryAPI = ApiMailboxRoutes.getSeatUsageReportsSummaryPath();
export const exportSeatUsageReportAPI = ApiMailboxRoutes.exportSeatUsageReportPath();
export const removeDataIngestionItemsAPI = ApiMailboxRoutes.removeDataIngestionItemsPath();
export const submitPurgeExternalAccountAPI = ApiMailboxRoutes.submitPurgeExternalAccountPath();
export const getPurgeExternalAccountAPI = ApiMailboxRoutes.getPurgeExternalAccountPath();
export const submitPurgeSharedDriveAPI = ApiMailboxRoutes.submitPurgeSharedDrivePath();
export const getPurgeSharedDriveAPI = ApiMailboxRoutes.getPurgeSharedDrivePath();
export const getTransferPartnerAPI = ApiMailboxRoutes.getTransferPartnerPath();
export const submitTransferPartnerAPI = ApiMailboxRoutes.submitTransferPartnerPath();
export const validateTransferPartnerAPI = ApiMailboxRoutes.validateTransferPartnerPath();
export const getPartnersListAPI = ApiMailboxRoutes.getPartnersListPath();
export const getOrganizationsListAPI = ApiMailboxRoutes.getOrganizationsListPath();
export const getOrganizationInformationAPI = ApiMailboxRoutes.getOrganizationInformationPath();
export const getM365TenantAPI = ApiMailboxRoutes.getM365TenantPath();
export const exportM365TenantAPI = ApiMailboxRoutes.exportM365TenantPath();
export const getM365TenantDetailsAPI = ApiMailboxRoutes.getM365TenantDetailsPath();
export const getM365UsersTenantAPI = ApiMailboxRoutes.getM365UsersTenantPath();
export const getGWSTenantAPI = ApiMailboxRoutes.getGWSTenantPath();
export const getGWSTenantDetailsAPI = ApiMailboxRoutes.getGWSTenantDetailsPath();
export const getHEXTenantAPI = ApiMailboxRoutes.getHEXTenantPath();
export const getPlansListAPI = ApiMailboxRoutes.getPlansListPath();
export const submitSeatCountChangeAPI = ApiMailboxRoutes.submitSeatCountChangePath();
export const getSeatCountChangeAPI = ApiMailboxRoutes.getSeatCountChangePath();
export const getUsersListAPI = ApiMailboxRoutes.getUsersListPath();
export const getSelectedResellerConfigsAPI = ApiMailboxRoutes.getSelectedResellerConfigsPath();
export const getResellerNotificationsConfigAPI =
  ApiMailboxRoutes.getResellerNotificationsConfigPath();
export const getMonthlyBackupReportsPath = ApiMailboxRoutes.getMonthlyBackupReportsPath();
export const exportMonthlyBackupReportPath = ApiMailboxRoutes.exportMonthlyBackupReportPath();
export const getTenantAuthErrorsAPI = ApiMailboxRoutes.getTenantAuthErrorsPath();
export const createTenantAuthErrorsAPI = ApiMailboxRoutes.createTenantAuthErrorsPath();
export const getIngestionsQueuedPath = ApiMailboxRoutes.getIngestionsQueuedPath();
export const getIngestionsInProgressPath = ApiMailboxRoutes.getIngestionsInProgressPath();
export const getPartnersFilterListAPI = ApiMailboxRoutes.getPartnersFilterListPath();
export const getHEXTenantDetailsAPI = ApiMailboxRoutes.getHEXTenantDetailsPath();
export const getSwitchCredentialListPath = ApiMailboxRoutes.getSwitchCredentialListPath();
export const getSwitchCredentialStatusPath = ApiMailboxRoutes.getSwitchCredentialStatusPath();
export const getSwitchCredentialItemsPath = ApiMailboxRoutes.getSwitchCredentialItemsPath();
export const createSwitchCredentialPath = ApiMailboxRoutes.createSwitchCredentialPath();
export const bulkCreateOrganizationPath = ApiMailboxRoutes.bulkCreateOrganizationPath();
export const getBulkCreateOrganizationTemplatePath =
  ApiMailboxRoutes.getBulkCreateOrganizationTemplatePath();
export const getBulkCreateOrganizationItemsPath =
  ApiMailboxRoutes.getBulkCreateOrganizationItemsPath();
export const bulkCreateSubResellerPath = ApiMailboxRoutes.bulkCreateSubResellerPath();
export const getBulkCreateSubResellerTemplatePath =
  ApiMailboxRoutes.getBulkCreateSubResellerTemplatePath();
export const getBulkCreateSubResellerItemsPath =
  ApiMailboxRoutes.getBulkCreateSubResellerItemsPath();
export default ApiMailboxRoutes;
