import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ITBox, QboTextField, QboFormGroup, QboAlert } from '@ui/Components';

export default function SwitchSPATypeFormSection({
  supportTicketAttributes,
  escalationTicketAttributes,
  descriptionAttributes,
  credentialsIdAttributes,
  organizationOwnerIdAttributes,
  errorMessage,
  onSubmit,
}) {
  const { t } = useTranslation();
  const isAstericRequired = true;

  return (
    <ITBox className="SwitchSPATypeFormage__wrapper" direction="column">
      <QboFormGroup
        className="SwitchSPATypeFormPage__form-group"
        onSubmit={onSubmit}
        withSubmitButton
        title={t('switch_spa_page.form.title_text')}
        buttonLabel={t('switch_spa_page.form.submit_button_text')}>
        {errorMessage && (
          <QboAlert type="error" style={{ fontSize: '1.15em' }}>
            {errorMessage}
          </QboAlert>
        )}
        <QboTextField
          id="supportTicket"
          required={isAstericRequired}
          placeholder={t('switch_spa_page.form.support_ticket')}
          label={t('switch_spa_page.form.support_ticket')}
          fullWidth
          value={supportTicketAttributes.value}
          onChange={(e) => {
            supportTicketAttributes.handleChange(e.currentTarget.value);
          }}
          errorMessage={supportTicketAttributes.errorMessage}
          withoutErrorIcon
          inputProps={{ maxLength: 225 }}
        />
        <QboTextField
          id="escalationTicket"
          placeholder={t('switch_spa_page.form.escalation_ticket')}
          label={t('switch_spa_page.form.escalation_ticket')}
          fullWidth
          value={escalationTicketAttributes.value}
          onChange={(e) => {
            escalationTicketAttributes.handleChange(e.currentTarget.value);
          }}
          errorMessage={escalationTicketAttributes.errorMessage}
          withoutErrorIcon
          inputProps={{ maxLength: 225 }}
        />
        <QboTextField
          id="description"
          placeholder={t('switch_spa_page.form.description')}
          label={t('switch_spa_page.form.description')}
          fullWidth
          value={descriptionAttributes.value}
          onChange={(e) => {
            descriptionAttributes.handleChange(e.currentTarget.value);
          }}
          errorMessage={descriptionAttributes.errorMessage}
          withoutErrorIcon
          inputProps={{ maxLength: 225 }}
        />
        <QboTextField
          id="organizationOwnerId"
          required={isAstericRequired}
          placeholder={t('switch_spa_page.form.organization_owner_id')}
          label={t('switch_spa_page.form.organization_owner_id')}
          fullWidth
          value={organizationOwnerIdAttributes.value}
          onChange={(e) => {
            organizationOwnerIdAttributes.handleChange(e.currentTarget.value);
          }}
          errorMessage={organizationOwnerIdAttributes.errorMessage}
          withoutErrorIcon
          inputProps={{ maxLength: 225 }}
        />
        <QboTextField
          id="credentialsId"
          required={isAstericRequired}
          placeholder={t('switch_spa_page.form.credentials_id')}
          label={t('switch_spa_page.form.credentials_id')}
          fullWidth
          value={credentialsIdAttributes.value}
          onChange={(e) => {
            credentialsIdAttributes.handleChange(e.currentTarget.value);
          }}
          errorMessage={credentialsIdAttributes.errorMessage}
          withoutErrorIcon
          inputProps={{ maxLength: 225 }}
        />
      </QboFormGroup>
    </ITBox>
  );
}

SwitchSPATypeFormSection.propTypes = {
  supportTicketAttributes: PropTypes.object,
  escalationTicketAttributes: PropTypes.object,
  descriptionAttributes: PropTypes.object,
  credentialsIdAttributes: PropTypes.object,
  organizationOwnerIdAttributes: PropTypes.object,
  errorMessage: PropTypes.string,
  onSubmit: PropTypes.func,
};

SwitchSPATypeFormSection.defaultProps = {
  supportTicketAttributes: {},
  escalationTicketAttributes: {},
  descriptionAttributes: {},
  credentialsIdAttributes: {},
  organizationOwnerIdAttributes: {},
  errorMessage: '',
  onSubmit: {},
};
