import { useDispatch } from 'react-redux';
import { constant } from '@config/BaseSetting';
import useLocalStorage from '@hooks/useLocalStorage';
import { fetchSwitchCredentialStatusAsync } from '@features/switchCredential/getSwitchCredentialStatusSlice';
import { fetchSwitchCredentialItemsAsync } from '@features/switchCredential/getSwitchCredentialItemsSlice';

export default function useMailboxChangeDetail() {
  const dispatch = useDispatch();
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);

  const getListAsync = (pageSize, pageIndex, id) => {
    dispatch(
      fetchSwitchCredentialItemsAsync({
        accessToken: accessTokenSet,
        reset: true,
        id,
        pageSize,
        pageNumber: pageIndex,
      })
    );
  };

  const getStatusAsync = (id) => {
    dispatch(fetchSwitchCredentialStatusAsync({ accessToken: accessTokenSet, reset: true, id }));
  };

  return { getListAsync, getStatusAsync };
}
