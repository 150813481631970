import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';

import { ITBox, QboAlert, QboFormGroup, QboTextField } from '@ui/Components';
import { resetRecreateBackupAdminsState } from '@features/recreateBackupAdmins/recreateBackupAdminsSlice';

function RequestFormSection(props) {
  const {
    supportTicketAttributes,
    escalationTicketAttributes,
    organizationOwnerIdAttributes,
    credentialIdAttributes,
    descriptionAttributes,
    onSubmit,
    resetForm,
    formData,
    isValid,
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { m365TenantDetailState } = useSelector((state) => state.m365TenantDetail);
  const { statusCode: status, errorMessage: message } = m365TenantDetailState;
  const isAstericRequired = true;

  const previousPropsRef = useRef(formData);

  useEffect(() => {
    if (!isEqual(previousPropsRef.current, formData)) {
      dispatch(resetRecreateBackupAdminsState());
    }
  }, [formData]);

  return (
    <ITBox className="TransferSubscriptionFormPage__wrapper" direction="column">
      <QboFormGroup onSubmit={onSubmit} withSubmitButton>
        {m365TenantDetailState?.errorMessage && status !== 200 && (
          <QboAlert type="error" style={{ fontSize: '1.15em' }}>
            {message}
          </QboAlert>
        )}
        <QboTextField
          id="supportTicket"
          required={isAstericRequired}
          placeholder={t('recreate_backup_admin.labels.support_ticket')}
          label={t('recreate_backup_admin.labels.support_ticket')}
          fullWidth
          value={supportTicketAttributes.value}
          errorMessage={supportTicketAttributes.errorMessage}
          inputProps={{ maxLength: 225 }}
          onChange={(e) => {
            supportTicketAttributes.handleChange(e.currentTarget.value);
          }}
        />
        <QboTextField
          id="escalationTicket"
          placeholder={t('recreate_backup_admin.labels.escalation_ticket')}
          label={t('recreate_backup_admin.labels.escalation_ticket')}
          fullWidth
          value={escalationTicketAttributes.value}
          onChange={(e) => escalationTicketAttributes.handleChange(e.currentTarget.value)}
          inputProps={{ maxLength: 225 }}
        />
        <QboTextField
          id="description"
          placeholder={t('recreate_backup_admin.labels.description')}
          label={t('recreate_backup_admin.labels.description')}
          fullWidth
          value={descriptionAttributes.value}
          onChange={(e) => descriptionAttributes.handleChange(e.currentTarget.value)}
          inputProps={{ maxLength: 225 }}
        />
        <QboTextField
          id="organization-owner-id"
          required={isAstericRequired}
          placeholder={t('recreate_backup_admin.labels.organization_owner_id')}
          label={t('recreate_backup_admin.labels.organization_owner_id')}
          fullWidth
          value={organizationOwnerIdAttributes.value}
          errorMessage={organizationOwnerIdAttributes.errorMessage}
          onChange={(e) => organizationOwnerIdAttributes.handleChange(e.currentTarget.value)}
          inputProps={{ maxLength: 225 }}
          type="number"
        />
        <QboTextField
          id="credential-id"
          required={isAstericRequired}
          placeholder={t('recreate_backup_admin.labels.credential_id')}
          label={t('recreate_backup_admin.labels.credential_id')}
          fullWidth
          value={credentialIdAttributes.value}
          errorMessage={credentialIdAttributes.errorMessage}
          onChange={(e) => credentialIdAttributes.handleChange(e.currentTarget.value)}
          inputProps={{ maxLength: 225 }}
          type="number"
        />
      </QboFormGroup>
    </ITBox>
  );
}

RequestFormSection.propTypes = {
  supportTicketAttributes: PropTypes.object,
  escalationTicketAttributes: PropTypes.object,
  organizationOwnerIdAttributes: PropTypes.object,
  credentialIdAttributes: PropTypes.object,
  descriptionAttributes: PropTypes.object,
  resetForm: PropTypes.func,
  formData: PropTypes.object,
  isValid: PropTypes.bool,
  onSubmit: PropTypes.func,
};

RequestFormSection.defaultProps = {
  supportTicketAttributes: {},
  escalationTicketAttributes: {},
  organizationOwnerIdAttributes: {},
  credentialIdAttributes: {},
  descriptionAttributes: {},
  resetForm: () => {},
  formData: {},
  isValid: false,
  onSubmit: () => {},
};

export default RequestFormSection;
