import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchSystemStatusServiceApi } from '@services/WebApiService';

const fetchSystemStatusAysnc = createAsyncThunk(
  'SystemStatus/fetchSystemStatus',
  async (paramsList) => {
    try {
      const data = await fetchSystemStatusServiceApi(paramsList);
      return data;
    } catch (err) {
      return { error: err?.response };
    }
  }
);

const { pending, fulfilled, rejected } = fetchSystemStatusAysnc;

const fetchSystemStatusExtraReducers = {
  [pending]: (state) => {
    state.fetchSystemStatus = true;
  },
  [rejected]: (state) => {
    state.fetchSystemStatus = false;
  },
  [fulfilled]: (state, action) => {
    if (action.payload.error) {
      state.error = action.payload.error;
    }
    if (action?.payload?.data?.data) {
      state.isForbiddenOccurred = false;
      state.forbiddenErrorMessage = null;
      state.systemStatus.value = action?.payload?.data?.data;
    }

    if (action?.payload?.error?.status === 403) {
      state.isForbiddenOccurred = true;
      state.forbiddenErrorMessage = {
        heading: 'Forbidden Error',
        caption: 'You are not authorize to access this resource',
      };
    }

    if (!action.payload?.error && !action?.payload?.data?.data && action?.payload?.status !== 200) {
      state.systemStatusCorsErrorMessage = 'Something went wrong while processing your request.';
    }
    state.fetchSystemStatus = false;
  },
};

export { fetchSystemStatusAysnc, fetchSystemStatusExtraReducers };
